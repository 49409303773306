import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import SensitiveDataExposure from '../SensitiveDataExposure';
import i18n from '../../../localization/i18n';
import AvScanResults from '../AvScanResults';

import DynamicAnalysisDatatable from './DynamicAnalysisDatatable';
import SmaliAnalysisDatatable from './SmaliAnalysisDatatable';
import DopplerResults from './DopplerResults';
import VulnerableOSVersion from './VulnerableOSVersion';
import WorldAccessibleFile from './WorldAccessibleFiles';

class AnalysisDetails extends Component {
  render() {
    return (
      <>
        <Row>
          <Col>
            <SensitiveDataExposure platform="android" />
          </Col>
          <DopplerResults />
          <Col xs="2">
            <VulnerableOSVersion />
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <WorldAccessibleFile />
          </Col>
          <Col xs="6">
            <AvScanResults />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Card className="card-accent-primary">
              <CardHeader>{i18n.t('Coding Issues')}</CardHeader>
              <CardBody>
                <SmaliAnalysisDatatable />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Card className="card-accent-teal">
              <CardHeader>{i18n.t('Dynamic Analysis')}</CardHeader>
              <CardBody>
                <DynamicAnalysisDatatable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default AnalysisDetails;
