import React from 'react';
import { Card } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

const LDConditionalCard = ({ children, ...props }) => {
  const { mastV2 } = useFlags();

  return mastV2 ? <>{children}</> : <Card {...props}>{children}</Card>;
};
export const QConditionalCard = withLDConsumer()(LDConditionalCard);
