import React from 'react';
import { connect } from 'react-redux';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

const parseFrameworks = frameworks => {
  const parsedFrameworks = [];
  Object.keys(frameworks).forEach(framework => {
    parsedFrameworks.push({
      framework,
      description: frameworks[framework].description,
      version: frameworks[framework].version,
      requiresPermission: frameworks[framework].requires_permission,
    });
  });

  return parsedFrameworks;
};

const SystemFrameworksDatatable = ({
  results,
  filter = '',
  showFilter = true,
}) => {
  const { 'System Frameworks': frameworks } =
    results.analysis.analysis_details.static_analysis.libraries_used;

  return (
    <DataTable
      rowsPerPage={5}
      data={parseFrameworks(frameworks)}
      showFilter={showFilter}
      columns={[
        {
          keyName: 'framework',
          label: i18n.t('Framework'),
          width: '20%',
          filterable: true,
        },
        {
          keyName: 'version',
          label: i18n.t('Version'),
          width: '20%',
        },
        {
          keyName: 'description',
          label: i18n.t('Description'),
          filterable: true,
          component: ({ value }) => (
            <span>{value.replace(/<br \/>/g, '\n')}</span>
          ),
        },
      ]}
      filter={filter}
    />
  );
};

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(SystemFrameworksDatatable);
