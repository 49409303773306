import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import PermissionsRequestedDatatable from '../iOS/PermissionsRequestedDatatable';
import PermissionsRequestedTable from '../Android/PermissionsRequestedTable';
import TabsView from '../../TabsView';
import Findings from '../Findings';

class Consent extends Component {
  render() {
    const { platform, parsedIssues } = this.props;

    const tabs = [
      {
        title: 'Overview',
        tabContent: () => (
          <Row>
            <Col>
              <Findings
                findings={parsedIssues}
                platform={platform}
                category="privacy"
                showOwaspIcon={false}
              />
            </Col>
            <Col>
              <Findings
                findings={parsedIssues}
                platform={platform}
                category="hardware"
                showOwaspIcon={false}
              />
            </Col>
          </Row>
        ),
      },
      {
        title: 'Permissions Requested',
        tabContent: () =>
          platform === 'ios' ? (
            <PermissionsRequestedDatatable />
          ) : (
            <PermissionsRequestedTable />
          ),
      },
    ];
    return (
      <div>
        <Card className="card-accent-navy">
          <CardHeader>Consent</CardHeader>
          <CardBody>
            <div>
              &quot;Processing personal data is generally prohibited, unless it
              is expressly allowed by law, or the data subject has consented to
              the processing. While being one of the more well-known legal bases
              for processing personal data, consent is only one of six bases
              mentioned in the General Data Protection Regulation (GDPR). The
              others are: contract, legal obligations, vital interests of the
              data subject, public interest and legitimate interest as stated in
              Article 6(1) GDPR.&quot;
            </div>
            <div>
              <a href="https://gdpr-info.eu/issues/consent/" target="_blank">
                More Info
              </a>
            </div>
            <hr />
            <TabsView tabs={tabs} />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ appResults }) => ({
  parsedIssues: appResults.parsedIssues,
});

export default withRouter(connect(mapStateToProps)(Consent));
