import React from 'react';
import { useSelector } from 'react-redux';

import DataTable from '../../DataTable/DataTable';

export const LibrariesTable = () => {
  const libraries = useSelector(
    ({ appResults }) => appResults?.results?.libraries ?? [],
  );

  return (
    <>
      <DataTable
        data={libraries}
        rowsPerPage={5}
        filterable={false}
        columns={[
          {
            label: 'Library',
            component: ({ rowData }) => (
              <>
                <p>{rowData}</p>
              </>
            ),
          },
        ]}
      />
    </>
  );
};
