import React from 'react';
import { Badge } from 'reactstrap';

export default function FindingResult({ result }) {
  let badgeColor;

  switch (result) {
    case 'review':
      badgeColor = 'warning';
      break;
    case 'pass':
      badgeColor = 'success';
      break;
    case 'fail':
      badgeColor = 'danger';
      break;
    default:
      badgeColor = 'default';
      break;
  }

  return (
    <Badge color={badgeColor}>
      <span style={{ textTransform: 'capitalize' }}>{result}</span>
    </Badge>
  );
}
