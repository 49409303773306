import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QTableHead as MuiTableHead } from '@kw/quokka-ui';

const LDTableHead = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiTableHead {...props}>{children}</MuiTableHead>
  ) : (
    <thead {...props}>{children}</thead>
  );
};
export const QTableHead = withLDConsumer()(LDTableHead);
