import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import i18n from '../../../localization/i18n';
import { AppManifest } from '../AppManifest';

import PermissionsRequestedDatatable from './PermissionsRequestedDatatable';
import UrlConstantsDatatable from './UrlConstantsDatatable';
import SystemFrameworksDatatable from './SystemFrameworksDatatable';
import SystemLibrariesDatatable from './SystemLibrariesDatatable';
import CloudServicesList from './CloudServicesList';
import CertInfoTable from './CertInfoTable';

class Metadata extends Component {
  render() {
    return (
      <div>
        <Row>
          {/* Permission Requested */}
          <Col xs="6">
            <Card className="card-accent-teal">
              <CardHeader>{i18n.t('Permissions Requested')}</CardHeader>
              <CardBody>
                <PermissionsRequestedDatatable />
              </CardBody>
            </Card>
          </Col>
          <Col xs="6">
            <Card className="card-accent-info">
              <CardHeader>{i18n.t('URL Constants')}</CardHeader>
              <CardBody>
                <UrlConstantsDatatable platform="ios" />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <Card className="card-accent-primary">
              <CardHeader>{i18n.t('Cloud Services')}</CardHeader>
              <CardBody>
                <CloudServicesList />
              </CardBody>
            </Card>
          </Col>
          <Col xs="6">
            <Card className="card-accent-purple">
              <CardHeader>{i18n.t('System Libraries Used')}</CardHeader>
              <CardBody>
                <SystemLibrariesDatatable />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <Card className="cert-info-section card-accent-info">
              <CardHeader>{i18n.t('Certificate Information')}</CardHeader>
              <CardBody>
                <CertInfoTable />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <AppManifest />
          </Col>
        </Row>

        <Row>
          <Col xs="12">
            <Card className="card-accent-navy">
              <CardHeader>{i18n.t('System Frameworks Used')}</CardHeader>
              <CardBody>
                <SystemFrameworksDatatable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Metadata;
