import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  page: 1,
};

export { initialState };

export const analyzedFirmwareSlice = createSlice({
  name: 'analyzedFirmware',
  initialState,
  reducers: {
    changePage: (state, action) => ({
      ...state,
      page: action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const { changePage } = analyzedFirmwareSlice.actions;

export default analyzedFirmwareSlice.reducer;
