// Fills out days of the month that the API does not return because there are 0 scans for these days. Provides better UX
export default {
  fillEmptyDates(data, endDate, range, unit) {
    const datesArray = data.map(scan => scan.date);
    const countArray = data.map(scan => scan.count);

    const graphStartDate = endDate.subtract(range, unit);
    let currentDate = graphStartDate.format('YYYY-MM-DD');
    for (let i = 0; i <= range; i++) {
      if (!datesArray.includes(currentDate)) {
        datesArray.splice(i, 0, currentDate);
        countArray.splice(i, 0, 0);
      }
      currentDate = graphStartDate.add(1, unit).format('YYYY-MM-DD');
    }

    return { datesArray, countArray };
  },
};
