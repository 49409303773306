import React, { Component } from 'react';

import ConstantsMethodsPermissionTable from '../../../Android/ConstantsMethodsPermissionTable';
import PermissionsRequestedTable from '../../../Android/PermissionsRequestedTable';
import ExtraPermissionsTable from '../../../Android/ExtraPermissionsTable';
import TabsView from '../../../../TabsView';
import i18n from '../../../../../localization/i18n';

export default class HasExtraPermissions extends Component {
  render() {
    const tabs = [
      {
        title: i18n.t('Permission Requested'),
        tabContent: () => <PermissionsRequestedTable />,
      },
      {
        title: i18n.t('Extra Permissions'),
        tabContent: () => <ExtraPermissionsTable />,
      },
      {
        title: i18n.t('Constants & Methods of Permission'),
        tabContent: () => <ConstantsMethodsPermissionTable />,
      },
    ];
    return <TabsView tabs={tabs} />;
  }
}
