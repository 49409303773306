import React from 'react';
import { Button } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QIconButton as MuiIconButton } from '@kw/quokka-ui';

const LDIconButton = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiIconButton {...props}>{children}</MuiIconButton>
  ) : (
    <Button {...props}>{children}</Button>
  );
};

export const QIconButton = withLDConsumer()(LDIconButton);
