import React from 'react';

export default function Eula({ eula }) {
  return (
    <span>
      <strong>EULA: </strong>
      {eula || 'Not available'}
    </span>
  );
}
