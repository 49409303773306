import React from 'react';
import { CardText } from 'reactstrap';

import { Issues } from './Issues';

export const V7_2 = ({ platform, evidence }) => (
  <>
    <Issues evidence={evidence} issues={['DEBUGGABLE']} />
    <hr />
    {platform === 'android' ? (
      <>
        <CardText>
          <strong>Debuggable: </strong>
          {evidence.isDebuggable ? (
            <i className="fa-regular fa-circle-check danger-icon" />
          ) : (
            <i className="fa-regular fa-circle-xmark success-icon" />
          )}
        </CardText>
      </>
    ) : (
      <>
        <CardText>
          <strong>App Origin: </strong>
          {evidence.appOrigin}
        </CardText>
        <CardText>
          {evidence.appOrigin &&
            evidence.appOrigin.includes('In house developed') &&
            `App was not protected through App Store distribution and may be modified`}
        </CardText>
      </>
    )}
  </>
);
