import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { store } from '../store/store';
import {
  changeSortByColumn,
  changePlatformFilter,
  changePageSize,
  changeSubGroupFilter,
  changeSearchQuery,
  changePage,
  changeCurrentSearchQuery,
} from '../store/slices/summarizedAppsSlice';
import { fetchAnalyzedAppsCache } from '../store/sliceHelpers/analyzedAppsCacheSliceHelper';
import i18n from '../localization/i18n';
import { toastrHelper } from '../logic/toastrHelper';
import SummarizedAppsTable from '../components/SummarizedApps/SummarizedAppsTable';
import SummarizedAppsSettingsModal from '../components/SummarizedApps/SummarizedAppsSettingsModal';

class SummarizedApps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      pagination: {
        lastPage: 0,
        totalData: 0,
        dataPerPage: 0,
      },
      loading: true,

      // Confirmation modal
      showResubmitConfirmModal: false,
      showConfirmModal: false,
      appIdToResubmit: 0,
      appIdToRemove: 0,

      // Settings Modal
      showSettingsModal: false,
    };

    this.getApps = this.getApps.bind(this);
    this.changeSortOption = this.changeSortOption.bind(this);
    this.handlePlatformChange = this.handlePlatformChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.removeSearchQuery = this.removeSearchQuery.bind(this);
    this.resubmitApp = this.resubmitApp.bind(this);
    this.removeApp = this.removeApp.bind(this);
    this.toggleResubmitConfirmModal =
      this.toggleResubmitConfirmModal.bind(this);
    this.toggleConfirmModal = this.toggleConfirmModal.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubGroupFilter = this.handleSubGroupFilter.bind(this);
    this.toggleSettingsModal = this.toggleSettingsModal.bind(this);
    this.downloadCsv = this.downloadCsv.bind(this);
  }

  // Make sure that we wait until the component is actually loaded before modifying it's state
  async componentDidMount() {
    const { page } = this.props.summarizedApps;
    // Get the initial page of apps
    this.getApps(page);
  }

  // Handle the sorting option being changed
  changeSortOption(option) {
    console.log(option);
    this.props.changeSortByColumn(option);
    this.getApps();
  }

  // Handle a change in the platform filter option
  handlePlatformChange(option) {
    console.log('Handling platform change:', option);
    this.props.changePlatformFilter(option);
    this.getApps();
  }

  // Handle page size change
  handlePageSizeChange(option) {
    this.props.changePageSize(option);
    this.getApps();
  }

  handleSubGroupFilter(opt, reload = true) {
    // Remove "all" option if other options are selected
    let option;
    if (opt && opt.length > 0) {
      option = opt.filter(op => op.value !== 'all');
    } else {
      option = { value: 'all', label: 'All' };
    }

    this.props.changeSubGroupFilter(option);
    if (reload) {
      this.getApps();
    }
  }

  // Handles key press for the search query field, tries to get apps on the enter key
  handleKeyPress(event) {
    /* istanbul ignore else */
    if (event.charCode === 13) {
      event.preventDefault();
      this.getApps();
    }
  }

  // Remove the search query from the page and refresh the apps
  removeSearchQuery() {
    this.props.changeSearchQuery('');
    this.getApps();
  }

  toggleResubmitConfirmModal(appId = 0) {
    this.setState({
      appIdToResubmit: appId,
      showResubmitConfirmModal: !this.state.showResubmitConfirmModal,
    });
  }

  toggleConfirmModal(appId = 0) {
    this.setState({
      appIdToRemove: appId,
      showConfirmModal: !this.state.showConfirmModal,
    });
  }

  toggleSettingsModal() {
    this.setState({ showSettingsModal: !this.state.showSettingsModal });
  }

  async downloadCsv(isCompressed = false) {
    const { platformFilter, subGroupFilter, searchQuery, reviewStatusFilter } =
      store.getState().summarizedApps;

    toastrHelper.showSuccessToast(
      'Your request is being processed. Please look forward to an email with a link to download the requested file',
      null,
      this.props.flags?.mastV2,
    );
    await axios.post('csv-request', {
      pageType: 'analyzed',
      isCompressed,
      query: searchQuery,
      platforms: platformFilter?.value,
      reviewStatus: reviewStatusFilter?.value,
      subgroupIds: subGroupFilter?.value,
    });
  }

  async resubmitApp() {
    const { page } = this.props.summarizedApps;
    const { appIdToResubmit } = this.state;

    try {
      toastrHelper.showInfoToast(
        i18n.t('Resubmitting app...'),
        null,
        this.props.flags?.mastV2,
      );
      await axios.put(`analyzed-apps/${appIdToResubmit}/resubmit`);

      toastrHelper.showSuccessToast(
        i18n.t('Resubmitted app'),
        i18n.t('Success'),
        this.props.flags?.mastV2,
      );

      this.getApps(page);
    } catch (err) {
      toastrHelper.showErrorToast(
        `${i18n.t('Error resubmitting application')}: ${err.response.data.msg}`,
        i18n.t('Error'),
        this.props.flags?.mastV2,
      );
    }

    // By not providing this call with a parameter, the appIdToRemoval will auto be reset to 0
    this.toggleResubmitConfirmModal();
  }

  // Remove the application from the user's portal. Rely on the appIdToRemove that
  // should have been set in the toggleConfirmModal function called before this one
  async removeApp() {
    const { page } = this.props.summarizedApps;

    const response = await axios.delete(`emm/app/${this.state.appIdToRemove}`);
    if (response.status === 200) {
      toastrHelper.showSuccessToast(
        i18n.t('Removed app'),
        i18n.t('Success'),
        this.props.flags?.mastV2,
      );
      this.getApps(page);
    } else {
      console.log(response);
      toastrHelper.showErrorToast(
        `${i18n.t('Error removing application')}: ${response.data.msg}`,
        i18n.t('Error'),
        this.props.flags?.mastV2,
      );
    }

    // By not providing this call with a parameter, the appIdToRemoval will auto be reset to 0
    this.toggleConfirmModal();
  }

  /**
   * Make the request to the server to get the analyzed apps for the user
   *
   * @returns JSON data returned from server
   * @memberof Dashboard
   */
  async getApps(pageNum = 1, event = null) {
    if (event) event.preventDefault();

    this.props.changePage(pageNum);
    this.setState({ loading: true });

    // Use store.getState() here since we can't rely on props to be updated
    // by the time this function is called
    const {
      pageSize,
      platformFilter,
      subGroupFilter,
      sortBy,
      searchQuery,
      reviewStatusFilter,
    } = store.getState().summarizedApps;

    const subGroupFilterParam = [];

    // Extract subGroupFilter array which contains multiple options and make it become a string
    if (Array.isArray(subGroupFilter) && subGroupFilter.length > 0) {
      subGroupFilter.map(data => subGroupFilterParam.push(data.value));
    } else {
      subGroupFilterParam.push(subGroupFilter.value);
    }

    try {
      const appsUrl = `emm/app-summaries/${pageNum.toString()}`;
      const data = await this.props.fetchAnalyzedAppsCache(appsUrl, {
        query: encodeURIComponent(searchQuery),
        sortColumn: sortBy.value,
        platforms: platformFilter.value,
        pageSize: pageSize.value,
        reviewStatus: reviewStatusFilter.value,
        subGroupId: subGroupFilterParam.join(','),
      });

      const { apps, lastPage, total, perPage } = data.data;

      this.setState({
        data: apps,
        pagination: {
          lastPage,
          totalData: total,
          dataPerPage: perPage,
        },
      });
      this.props.changeCurrentSearchQuery(searchQuery);
    } catch (err) {
      console.log(err);
    }
    this.setState({ loading: false });
  }

  render() {
    const { pageSize, changeSearchQuery } = this.props;

    return (
      <div>
        <SummarizedAppsTable
          showResubmitConfirmModal={this.state.showResubmitConfirmModal}
          showConfirmModal={this.state.showConfirmModal}
          toggleResubmitConfirmModal={this.toggleResubmitConfirmModal}
          toggleConfirmModal={this.toggleConfirmModal}
          resubmitApp={this.resubmitApp}
          removeApp={this.removeApp}
          getApps={this.getApps}
          handleKeyPress={this.handleKeyPress}
          handleTextChange={e => {
            changeSearchQuery(e.target.value);
          }}
          removeSearchQuery={this.removeSearchQuery}
          changeSortOption={this.changeSortOption}
          pageSize={pageSize}
          handlePlatformChange={this.handlePlatformChange}
          handlePageSizeChange={this.handlePageSizeChange}
          handleSubGroupFilter={this.handleSubGroupFilter}
          loading={this.state.loading}
          pagination={this.state.pagination}
          data={this.state.data}
          toggleSettingsModal={this.toggleSettingsModal}
          downloadCsv={this.downloadCsv}
        />
        <SummarizedAppsSettingsModal
          isOpen={this.state.showSettingsModal}
          toggle={this.toggleSettingsModal}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ summarizedApps }) => {
  const {
    platformFilter,
    subGroupFilter,
    pageSize,
    searchQuery,
    currentSearchQuery,
    page,
    sortBy,
  } = summarizedApps;

  return {
    platformFilter,
    subGroupFilter,
    pageSize,
    page,
    searchQuery,
    currentSearchQuery,
    sortBy,
    summarizedApps,
  };
};

const mapDispatchToProps = {
  changeSortByColumn,
  changePlatformFilter,
  changePageSize,
  changeSubGroupFilter,
  changeSearchQuery,
  changePage,
  changeCurrentSearchQuery,
  fetchAnalyzedAppsCache,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(SummarizedApps));
