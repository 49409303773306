import React from 'react';
import { Container } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QContainer as MuiContainer } from '@kw/quokka-ui';

const LDContainer = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  const filteredProps = ['fluid'];

  const muiContainerProps = Object.keys(props).reduce((acc, key) => {
    if (!filteredProps.includes(key)) {
      acc[key] = props[key];
    }
    return acc;
  }, {});
  return mastV2 ? (
    <MuiContainer {...muiContainerProps}>{children}</MuiContainer>
  ) : (
    <Container {...props}>{children}</Container>
  );
};
export const QContainer = withLDConsumer()(LDContainer);
