import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';

import Findings from '../Findings';
import TabsView from '../../TabsView';

class RightToBeForgotten extends Component {
  render() {
    const { platform, parsedIssues } = this.props;

    const tabs = [
      {
        title: 'Overview',
        tabContent: () => (
          <Findings
            findings={parsedIssues}
            platform={platform}
            category="privacy"
            showOwaspIcon={false}
          />
        ),
      },
    ];

    return (
      <div>
        <Card className="card-accent-navy">
          <CardHeader>Right To Be Forgotten</CardHeader>
          <CardBody>
            <div>
              &quot;The right to be forgotten derives from the case Google Spain
              SL, Google Inc v Agencia Española de Protección de Datos, Mario
              Costeja González (2014). For the first time, the right to be
              forgotten is codified and to be found in the General Data
              Protection Regulation (GDPR) in addition to the right to
              erasure.&quot;
            </div>
            <div>
              <a
                href="https://gdpr-info.eu/issues/right-to-be-forgotten/"
                target="_blank"
              >
                More Info
              </a>
            </div>
            <hr />
            <TabsView tabs={tabs} />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ appResults }) => ({
  parsedIssues: appResults.parsedIssues,
});

export default withRouter(connect(mapStateToProps)(RightToBeForgotten));
