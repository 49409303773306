import React from 'react';
import { Modal } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QModal as MuiModal } from '@kw/quokka-ui';

const LDModal = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiModal {...props}>{children}</MuiModal>
  ) : (
    <Modal {...props}>{children}</Modal>
  );
};
export const QModal = withLDConsumer()(LDModal);
