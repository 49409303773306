import React, { Component } from 'react';

import { Harviewer } from '../../../../Harviewer/Harviewer';
import AdNetworksList from '../../../Android/AdNetworksList';
import TabsView from '../../../../TabsView';
import i18n from '../../../../../localization/i18n';

export default class AdNetworkEvidence extends Component {
  render() {
    const { platform } = this.props;
    const tabs = [
      {
        title: i18n.t('Ad Networks'),
        tabContent: () => <AdNetworksList platform={platform} />,
      },
      {
        title: i18n.t('Network Traffic'),
        tabContent: () => <Harviewer />,
      },
    ];
    return <TabsView tabs={tabs} />;
  }
}
