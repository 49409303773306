import React, { useEffect, useRef, useState } from 'react';
import { isRange } from 'range_check';
import axios from 'axios';
import { useLatest } from 'react-use';
import { useFlags } from 'launchdarkly-react-client-sdk';

import util from '../../../logic/util';
import { toastrHelper } from '../../../logic/toastrHelper';
import { QTypography } from '../../Q-Components/QTypography';
import { QButton } from '../../Q-Components/QButton';
import { QInput } from '../../Q-Components/QInput';
import { QIconButton } from '../../Q-Components/QIconButton';
import { QListGroup } from '../../Q-Components/QListGroup';
import { QListGroupItem } from '../../Q-Components/QListGroupItem';
import { QRow } from '../../Q-Components/QRow';
import { QCol } from '../../Q-Components/QCol';

export const addToIpsWhitelist = async (
  users,
  whiteListIps,
  mastV2 = false,
) => {
  try {
    await axios.put(`/group-admin/bulk-edit-api-ip-whitelist`, {
      users: users.map(user => ({ id: user.value })),
      whiteListIps,
    });

    toastrHelper.showSuccessToast(
      `Successfully updated users' whitelist`,
      'Success',
      mastV2,
    );
  } catch (err) {
    toastrHelper.showErrorToast(
      `Error updating users: ${err.response.data.message}`,
      'Error',
      mastV2,
    );
  }
};
export const useApiWhiteListIpCard = () => {
  const ipInputRef = useRef(null);

  const [newIp, setNewIp] = useState('');

  const [ips, setIps] = useState([]);
  const latestIps = useLatest(ips);
  const { mastV2 } = useFlags();
  // Add IP To whitelist
  const addIpToWhiteList = async () => {
    let error = false;
    const ipArray = newIp.replace(/\s+/g, '').split(',');

    ipArray.forEach(ipAddr => {
      if (ipAddr.includes('/')) {
        if (!isRange(ipAddr)) {
          toastrHelper.showErrorToast(
            `IP address is not a valid range: ${ipAddr}`,
            null,
            mastV2,
          );
          error = true;
        }
      } else {
        const { valid: ipValid, msg: ipValidMsg } = util.validateIp(ipAddr);
        if (!ipValid) {
          toastrHelper.showErrorToast(
            `IP address (${ipAddr}) not valid: ${ipValidMsg}`,
            null,
            mastV2,
          );
          error = true;
        }
      }
    });

    if (error) {
      return;
    }
    const newIps = [...ips, ...ipArray];

    // Remove duplicates if exists
    const newIpsSet = [...new Set(newIps)];
    setIps(newIpsSet);
    ipInputRef.current.focus();
    setNewIp('');
  };

  // retrieves latest value, for when we need to use value in callback
  const get = () => latestIps.current;
  const resetIps = () => setIps([]);
  // Delete Ip from list
  const deleteIpFromWhitelist = async whitelistIp => {
    const newIps = ips.filter(ip => ip !== whitelistIp);
    setIps(newIps);
  };

  return {
    addIpToWhiteList,
    deleteIpFromWhitelist,
    setNewIp,
    get,
    resetIps,
    addToIpsWhitelist,
    newIp,
    ipInputRef,
    ips,
  };
};

export default function ApiWhiteListIpCard(props) {
  const {
    setNewIp,
    newIp,
    ipInputRef,
    addIpToWhiteList,
    ips,
    deleteIpFromWhitelist,
    setConfirmationModal,
  } = props;

  useEffect(setConfirmationModal, []);
  return (
    <>
      <QTypography>
        <strong>API Key Allowlist IPs</strong>
      </QTypography>

      <QRow gap={1}>
        <QCol xs="6">
          <QTypography variant="caption">
            <small>
              You can provide either a single IP address or an IP address with a
              netmask such as <code>192.168.1.1/24</code>.
            </small>
          </QTypography>

          <QInput
            type="text"
            placeholder="IP address"
            data-testid="whitelist-ip-input"
            onChange={e => setNewIp(e.target.value)}
            value={newIp}
            innerRef={ipInputRef}
            inputRef={ipInputRef}
          />

          <QButton
            sx={{ mt: 2 }}
            variant="filled"
            className="btn-primary mt-2"
            onClick={addIpToWhiteList}
            data-testid="whitelist-add-ip-button"
          >
            <i className="fa-regular fa-circle-plus mr-2" /> Add
          </QButton>
        </QCol>

        <QCol xs="6">
          <QListGroup>
            {ips.map(ip => (
              <QListGroupItem
                key={ip}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '250px',
                }}
                divider
              >
                <QTypography>
                  <strong>IP:</strong> {ip}
                </QTypography>

                <QIconButton
                  className="btn-warning"
                  size="sm"
                  onClick={() => deleteIpFromWhitelist(ip)}
                >
                  <i className="fa-regular fa-trash pointer" />
                </QIconButton>
              </QListGroupItem>
            ))}
          </QListGroup>
        </QCol>
      </QRow>
    </>
  );
}
