import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QHeaderToolbar as MuiHeaderToolbar } from '@kw/quokka-ui';

const LDHeaderToolbar = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiHeaderToolbar {...props}>{children}</MuiHeaderToolbar>
  ) : (
    <header className="app-header navbar" {...props}>
      {children}
    </header>
  );
};
export const QHeaderToolbar = withLDConsumer()(LDHeaderToolbar);
