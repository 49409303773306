import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QSidebarItemIcon as MuiSidebarItemIcon } from '@kw/quokka-ui';

const LDSidebarItemIcon = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiSidebarItemIcon {...props}>{children}</MuiSidebarItemIcon>
  ) : (
    <i {...props} />
  );
};
export const QSidebarItemIcon = withLDConsumer()(LDSidebarItemIcon);
