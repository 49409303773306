import React from 'react';

import { QTable } from '../../../Q-Components/QTable';
import { QTableHead } from '../../../Q-Components/QTableHead';
import { QTableRow } from '../../../Q-Components/QTableRow';
import { QTableCell } from '../../../Q-Components/QTableCell';
import { QTableBody } from '../../../Q-Components/QTableBody';
import { QBadge } from '../../../Q-Components/QBadge';

export function AnalysisSteps({ analysisTracking, platform }) {
  return (
    <QTable>
      <QTableHead>
        <QTableRow>
          <QTableCell>Analysis Step</QTableCell>
          <QTableCell>Complete?</QTableCell>
        </QTableRow>
      </QTableHead>
      <QTableBody>
        {analysisTracking &&
          Object.entries(analysisTracking)
            .filter(([_, value]) => typeof value === 'boolean')
            .filter(([stepName]) => {
              console.log('Stepname: ', stepName);
              const androidIgnoreSteps = ['appDownload', 'appDecrypt'];
              const iosIgnoreSteps = [
                'miscAnalysis',
                'fpeAnalysis',
                'dopplerAnalysis',
                'libidAnalysis',
                'apkidAnalysis',
                'malifestAnalysis',
              ];

              if (platform === 'android') {
                return !androidIgnoreSteps.includes(stepName);
              }

              return !iosIgnoreSteps.includes(stepName);
            })
            .map(([key, value]) => (
              <QTableRow hoverDisabled>
                <QTableCell>{key}</QTableCell>
                <QTableCell>
                  {value ? (
                    <QBadge color="success" pill label="YES" variant="green" />
                  ) : (
                    <QBadge color="danger" pill label="No" variant="red" />
                  )}
                </QTableCell>
              </QTableRow>
            ))}
      </QTableBody>
    </QTable>
  );
}
