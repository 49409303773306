import React, { useState, useEffect } from 'react';
import { Fade, Jumbotron } from 'reactstrap';
import { useHistory, useLocation } from 'react-router';

import './Confirmation.scss';
import util from '../../logic/util';

export const Confirmation = () => {
  const history = useHistory();
  const location = useLocation();

  const [passwordSet] = useState(
    util.getQueryVariable('set_password', location.search),
  );

  useEffect(() => {
    if (passwordSet) {
      setTimeout(() => {
        history.replace('/login');
      }, 8000);
    }
  }, [passwordSet]);

  return (
    <Fade mountOnEnter unmountOnExit>
      <Jumbotron className="new-user-password__confirmation">
        <div className="new-user-password__confirmation__wrapper">
          <h4 className="display-3">New password saved!</h4>
          <hr />
          <p className="new-user-password__confirmation__wrapper__redirect">
            You will be automatically redirected to the login page in a few
            seconds.
          </p>
        </div>
      </Jumbotron>
    </Fade>
  );
};
