import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  watchlistPage: 1,
  userAccess: {},
  userConfig: {},
  // Portal Config
  portalConfig: { lang: { value: 'us', label: 'EN' } },
  mdmDeviceDetails: {},
  userNotifications: [],
  helpHtml: '',
  twoFactorAuthEnabled: false,
  isTrialUser: false,
  expiresOn: null,
  passwordChangeOpen: false,
  helpAsideOpen: false,
  simpleReportView: false,
  // Flags to check if the user has exceeded their max number of app submissions
  reachedSubmissionsMax: false,
  totalSubmissions: 0,
  license: {},

  reportTab: '0',
  showMap: false,
  riskLevelFilter: null,
  columnSettings: {
    dateCol: true,
    scoreCol: true,
    findingsCol: true,
    subGroupsCol: true,
    analysisTimeCol: false,
    submittingUserCol: false,
  },
  userSubGroups: [],
  groupAdminUsers: [],
  groupAdminSubGroups: [],
  groupAdminGroup: [],
  pageTitle: '',
  sidebarCollapsed: false,
  groupNotifications: [],
};

export { initialState };

export const emmAppSlice = createSlice({
  name: 'emmApp',
  initialState,
  reducers: {
    changeWatchlistPage: (state, action) => ({
      ...state,
      watchlistPage: action.payload,
    }),
    setUserAccessData: (state, action) => ({
      ...state,
      userAccess: action.payload,
    }),
    setUserConfigData: (state, action) => ({
      ...state,
      userConfig: action.payload,
    }),
    setPortalConfigData: (state, action) => ({
      ...state,
      portalConfig: action.payload,
    }),
    setMdmDeviceDetails: (state, action) => ({
      ...state,
      mdmDeviceDetails: action.payload,
    }),
    setUserNotifications: (state, action) => ({
      ...state,
      userNotifications: action.payload,
    }),
    setActiveUserNotification: (state, action) => ({
      ...state,
      ...{
        userNotifications: state.userNotifications.map(notification => {
          if (notification.uuid === action.payload.uuid) {
            return {
              ...notification,
              status: 'read',
            };
          }
          return {
            ...notification,
          };
        }),
      },
    }),
    deleteUserNotification: (state, action) => ({
      ...state,
      ...{
        userNotifications: state.userNotifications.filter(
          notification => notification.uuid !== action.payload.uuid,
        ),
      },
    }),
    setHelpHtml: (state, action) => ({
      ...state,
      helpHtml: action.payload,
    }),
    setTwoFactorAuthEnabled: (state, action) => ({
      ...state,
      twoFactorAuthEnabled: action.payload,
    }),
    setIsTrialUser: (state, action) => ({
      ...state,
      isTrialUser: action.payload,
    }),
    setExpiredDate: (state, action) => ({
      ...state,
      expiresOn: action.payload,
    }),
    setPasswordChangeOpen: (state, action) => ({
      ...state,
      passwordChangeOpen: action.payload,
    }),
    toggleHelpAsideOpen: state => ({
      ...state,
      helpAsideOpen: !state.helpAsideOpen,
    }),
    toggleSimpleReportView: state => ({
      ...state,
      simpleReportView: !state.simpleReportView,
    }),
    setMaxSubmissionsReached: (state, action) => ({
      ...state,
      reachedSubmissionsMax: action.payload,
    }),
    setTotalSubmissions: (state, action) => ({
      ...state,
      totalSubmissions: action.payload,
    }),

    setLicenseInfo: (state, action) => ({
      ...state,
      license: action.payload,
    }),
    setReportTab: (state, action) => ({
      ...state,
      reportTab: action.payload,
    }),
    setShowMap: (state, action) => ({
      ...state,
      showMap: action.payload,
    }),
    setRiskLevelFilter: (state, action) => ({
      ...state,
      riskLevelFilter: action.payload,
    }),
    setColumnSettings: (state, action) => ({
      ...state,
      columnSettings: action.payload,
    }),
    setUserSubGroups: (state, action) => ({
      ...state,
      userSubGroups: action.payload,
    }),
    setGroupAdminUsers: (state, action) => ({
      ...state,
      groupAdminUsers: action.payload,
    }),
    setGroupAdminSubGroups: (state, action) => ({
      ...state,
      groupAdminSubGroups: action.payload,
    }),

    setGroupAdminGroup: (state, action) => ({
      ...state,
      groupAdminGroup: action.payload,
    }),

    setPageTitle: (state, action) => ({
      ...state,
      pageTitle: action.payload,
    }),

    setSidebarCollapsed: (state, action) => ({
      ...state,
      sidebarCollapsed: action.payload,
    }),

    setGroupNotifications: (state, action) => ({
      ...state,
      groupNotifications: action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  changeWatchlistPage,
  setUserAccessData,
  setUserConfigData,
  setPortalConfigData,
  setMdmDeviceDetails,
  setUserNotifications,
  setActiveUserNotification,
  deleteUserNotification,
  setHelpHtml,
  setTwoFactorAuthEnabled,
  setIsTrialUser,
  setExpiredDate,
  setPasswordChangeOpen,
  toggleHelpAsideOpen,
  toggleSimpleReportView,
  setMaxSubmissionsReached,
  setTotalSubmissions,
  setLicenseInfo,
  setReportTab,
  setShowMap,
  setRiskLevelFilter,
  setColumnSettings,
  setUserSubGroups,
  setGroupAdminUsers,
  setGroupAdminSubGroups,
  setGroupAdminGroup,
  setPageTitle,
  setSidebarCollapsed,
  setGroupNotifications,
} = emmAppSlice.actions;

export default emmAppSlice.reducer;
