import React, { Component } from 'react';

import { Harviewer } from '../../../../Harviewer/Harviewer';
import SensitiveDataExposureDatatable from '../../../SensitiveDataExposureDatatable';
import TabsView from '../../../../TabsView';

export default class PiiExposure extends Component {
  render() {
    const tabs = [
      {
        title: 'Sensitive Data Exposure',
        tabContent: () => <SensitiveDataExposureDatatable platform="android" />,
      },
      {
        title: 'Network Traffic',
        tabContent: () => <Harviewer />,
      },
    ];
    return <TabsView tabs={tabs} />;
  }
}
