/* istanbul ignore file */
import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'chart.js/auto';
import { Chart } from 'chart.js';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { setDefaultLocale } from 'react-datepicker';
import { Provider } from 'react-redux';
import _ from 'lodash';
import { I18nextProvider } from 'react-i18next';
import NProgress from 'nprogress';
import toastr from 'toastr';
import moment from 'moment';
import { datadogRum } from '@datadog/browser-rum';
import { QThemeProvider } from '@kw/quokka-ui';

import i18n from './localization/i18n';
import Full from './containers/Full';
import Login from './views/Login';
import PasswordReset from './views/PasswordReset';
import AccountVerification from './views/AccountVerification';
import { SsoLoginCompletion } from './views/SsoLoginCompletion';
import { KryptowireSsoLogin } from './views/KryptowireSsoLogin';
import { store } from './store/store';
import { NewUserPassword } from './views/NewUserPassword';
import { CisaDisclaimer } from './components/Disclaimer';
import { withLDProviderUtil } from './logic/withLDProviderUtil';
import { getAssets } from './assets/assets';
import { ScrollToTop } from './components/ScrollToTop';

// Styles
import 'leaflet/dist/leaflet.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Font Awesome Icons Set
import '@fortawesome/fontawesome-pro/css/all.css';
// Slider styles
import 'rc-slider/assets/index.css';

import './scss/style.scss';
// NProgress css
import './scss/nprogress.scss';
// Leaflet CSS
import 'react-leaflet-markercluster/dist/styles.min.css';
// Flag CSS
import 'flag-icons/css/flag-icons.min.css';
// React-datepicker CSS
import 'react-datepicker/dist/react-datepicker.css';
// Chart plugin data label
import 'chartjs-plugin-datalabels';

// Set date format
import 'moment/min/locales.min';

const appliance = process.env.REACT_APP_APPLIANCE ?? 'default';

Chart.defaults.font.family = getAssets[appliance].fonts.join(',');

// Import custom style for appliance
switch (appliance) {
  case 'cisa':
    // eslint-disable-next-line no-unused-expressions
    import('./assets/CISA/cisa.scss');
    break;
  default:
}

const locale = window.navigator.language || window.navigator.userLanguage;
moment.locale(locale);
// Set the date format for the react date picker as well
setDefaultLocale(locale);
axios.defaults.baseURL =
  process.env.REACT_APP_API_BASE_URL || axios.defaults.baseURL;
// Disable console logs not in development
function noop() {}

if (process.env.NODE_ENV !== 'development') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

// Configuring session replay in datadog
if (process.env.NODE_ENV === 'production' && appliance === 'default') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    allowedTracingUrls: [process.env.REACT_APP_API_BASE_URL],
    service: process.env.REACT_APP_TITLE,
    env: process.env.NODE_ENV,
    version: process.env.REACT_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });
}

// Setup the official polyfill for the includes function on the String prototype
/* eslint-disable */
if (!String.prototype.includes) {
  String.prototype.includes = function (search, start) {
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}
/* eslint-enable */

// Configure NProgress
NProgress.configure({
  trickleSpeed: 200,
  // parent: '.nprogress-header',
  showSpinner: false,
});

// Configure toastr
toastr.options = {
  allowHtml: true,
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: true,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
  onclick: null,
  showDuration: '300',
  hideDuration: '1000',
  timeOut: '4000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'slideDown',
  hideMethod: 'slideUp',
};

// setLanguageFromState
const portalConfigStore = store.getState().emmApp.portalConfig;

if (_.get(portalConfigStore, 'lang')) {
  i18n.changeLanguage(portalConfigStore.lang.value);
} else {
  i18n.changeLanguage('us');
}

function App() {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <QThemeProvider
          customFonts={getAssets[appliance].fonts}
          customTheme={getAssets[appliance].theme}
          customPalette={getAssets[appliance].palette}
        >
          {appliance === 'cisa' && <CisaDisclaimer />}
          <Router>
            <ScrollToTop />
            <Switch>
              <Route
                path="/verify-account"
                name="VerifyAccount"
                component={AccountVerification}
              />
              <Route path="/login" name="Login" component={Login} />
              <Route
                path="/password-reset"
                name="PasswordReset"
                component={PasswordReset}
              />
              <Route
                path="/new-user-password"
                name="NewUserPassword"
                component={NewUserPassword}
              />
              <Route
                path="/sso-login-completion"
                name="SSO Login Completion"
                component={SsoLoginCompletion}
              />
              <Route
                path="/zendesk-sso-login"
                name="Kryptowire Zendesk SSO Login"
                render={props => (
                  <KryptowireSsoLogin serviceProvider="zendesk" {...props} />
                )}
              />
              <Route path="/" name="Home" component={Full} />
            </Switch>
          </Router>
        </QThemeProvider>
      </I18nextProvider>
    </Provider>
  );
}

let AppWithLDProvider = App;

if (process.env.REACT_APP_LAUNCH_DARKLY_ENABLED === 'true') {
  AppWithLDProvider = withLDProviderUtil(App);
}

ReactDOM.render(<AppWithLDProvider />, document.getElementById('root'));
