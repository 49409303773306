import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';

import DataTable from '../../DataTable/DataTable';

class HashOperationsDatatable extends Component {
  constructor() {
    super();

    this.state = {
      showDataDetailModal: false,
      dataDetail: {},
    };
  }

  render() {
    const { showDataDetailModal, dataDetail } = this.state;
    const { results } = this.props;
    const hashOperations =
      results.analysis.analysis_details.dynamic_analysis.hash_operations;

    return (
      <div>
        <Modal
          isOpen={showDataDetailModal}
          size="lg"
          toggle={() =>
            this.setState({ showDataDetailModal: !showDataDetailModal })
          }
        >
          <ModalHeader>Hash Operation Detail</ModalHeader>
          <ModalBody>
            <p className="bold">Full Data Input</p>
            <code>{dataDetail.data_input}</code>
            <hr />
            <p className="bold">Full Data Input (Base64)</p>
            <p>{dataDetail.dataIn}</p>
          </ModalBody>
        </Modal>

        <div>
          {/* Sometimes the hash operations results if it's empty is an object, not an array
                so we have to check for that too */}
          {!Array.isArray(hashOperations) || hashOperations.length === 0 ? (
            <span>No hash operations</span>
          ) : (
            <DataTable
              data={hashOperations}
              onRowClick={row =>
                this.setState({ showDataDetailModal: true, dataDetail: row })
              }
              columns={[
                {
                  keyName: 'Algorithm',
                  label: 'Algorithm',
                  sortable: true,
                  filterable: true,
                },
                {
                  keyName: 'data_input',
                  label: 'Input Data',
                  sortable: true,
                  filterable: true,
                  component: ({ value }) => {
                    if (value && value.length > 100) {
                      return (
                        <span>
                          {`${value.substring(0, 100)}...(truncated)`}
                        </span>
                      );
                    }
                    return <span>{value}</span>;
                  },
                },
                {
                  keyName: 'data_hash',
                  label: 'Output',
                  component: ({ value }) => <code>{value}</code>,
                  sortable: true,
                  filterable: true,
                },
              ]}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ appResults }) => ({
  results: appResults.results,
});

export default connect(mapStateToProps)(HashOperationsDatatable);
