import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import PermissionsRequestedDatatable from '../iOS/PermissionsRequestedDatatable';
import CryptoOperationsDatatable from '../iOS/CryptoOperationsDatatable';
import PermissionsRequestedTable from '../Android/PermissionsRequestedTable';
import TabsView from '../../TabsView';
import Findings from '../Findings';
import DynamicAnalysisDatatable from '../Android/DynamicAnalysisDatatable';

class Encryption extends Component {
  render() {
    const { platform, parsedIssues } = this.props;

    const tabs = [
      {
        title: 'Overview',
        tabContent: () => (
          <Row>
            <Col>
              <Findings
                findings={parsedIssues}
                platform={platform}
                category="security"
                showOwaspIcon={false}
              />
            </Col>
            <Col>
              <Findings
                findings={parsedIssues}
                platform={platform}
                category="privacy"
                showOwaspIcon={false}
              />
            </Col>
          </Row>
        ),
      },
      {
        title: 'Permissions Requested',
        tabContent: () =>
          platform === 'ios' ? (
            <PermissionsRequestedDatatable />
          ) : (
            <PermissionsRequestedTable />
          ),
      },
      {
        title: 'Cryptographic Operations',
        tabContent: () =>
          platform === 'ios' ? (
            <CryptoOperationsDatatable />
          ) : (
            <DynamicAnalysisDatatable onlySelectedCategory="Cryptography" />
          ),
      },
    ];
    return (
      <div>
        <Card className="card-accent-navy">
          <CardHeader>Encryption</CardHeader>
          <CardBody>
            <div>
              &quot;Companies can reduce the probability of a data breach and
              thus reduce the risk of fines in the future, if they chose to use
              encryption of personal data. The processing of personal data is
              naturally associated with a certain degree of risk. Especially
              nowadays, where cyber-attacks are nearly unavoidable for companies
              above a given size. Therefore, risk management plays an
              ever-larger role in IT security and data encryption is suited,
              among other means, for these companies.&quot;
            </div>

            <div>
              <a href="https://gdpr-info.eu/issues/encryption/" target="_blank">
                More Info
              </a>
            </div>
            <hr />
            <TabsView tabs={tabs} />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ appResults }) => ({
  parsedIssues: appResults.parsedIssues,
});

export default withRouter(connect(mapStateToProps)(Encryption));
