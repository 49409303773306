import React from 'react';
import { Badge } from 'reactstrap';
import { connect } from 'react-redux';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

const ConstantsMethodsPermissionTable = ({
  results,
  filter = '',
  showFilter,
}) => {
  const {
    constants_using_permissions: constantsUsingPermissions,
    methods_using_permissions: methodsUsingPermissions,
  } = results.analysis.analysis_details.static_analysis;

  console.log('ConstMethod showFilter', showFilter);

  return (
    <div>
      {/* Combined the methods and constants using permissions and also
              give them a type for the data in DataTable */}
      <DataTable
        data={constantsUsingPermissions

          .map(c => ({ ...c, type: 'Constant' }))
          .concat(methodsUsingPermissions.map(m => ({ ...m, type: 'Method' })))}
        columns={[
          {
            label: i18n.t('Type'),
            component: ({ rowData }) => (
              <Badge style={{ fontSize: '0.8rem' }} color="primary">
                {rowData.type}
              </Badge>
            ),
          },
          {
            label: i18n.t('Name'),
            keyName: 'name',
            filterable: true,
          },
          {
            label: i18n.t('Permission(s)'),
            component: ({ rowData }) => {
              if (rowData.required_permissions.modifier !== 'none') {
                return (
                  <span>
                    {rowData.required_permissions.permissions
                      .map(perm => Object.keys(perm)[0])
                      .join(` ${rowData.required_permissions.modifier} `)}
                  </span>
                );
              }
              return (
                <span>
                  {Object.keys(
                    rowData.required_permissions.permissions[0],
                  ).join('')}
                </span>
              );
            },
            filterable: true,
            filterFunc: (filterStr, item) =>
              item.required_permissions.permissions.some(perm =>
                Object.keys(perm)[0].toLowerCase().includes(filterStr),
              ),
          },
          {
            label: i18n.t('Location'),
            component: ({ rowData }) => (
              <div>
                {rowData.smali_file.file.includes('/smali/')
                  ? rowData.smali_file.file.split('/smali/')[1]
                  : rowData.smali_file.file}
              </div>
            ),
          },
        ]}
        filter={filter}
        showFilter={showFilter}
      />
    </div>
  );
};

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(ConstantsMethodsPermissionTable);
