import React from 'react';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';

import i18n from '../../../localization/i18n';

const CertInfoTable = ({ results }) => {
  const { certificate } = results;

  return (
    <div>
      {certificate ? (
        <Table size="sm">
          <tbody>
            <tr>
              <td>
                <p className="cert-info-title">{i18n.t('Name')}</p>
                <span className="cert-info-value">
                  {certificate.owner.name}
                </span>
              </td>
              <td>
                <p className="cert-info-title">{i18n.t('Certificate File')}</p>
                <span className="cert-info-value">
                  {certificate.file
                    ? certificate.file.split('/').slice(1).join('/')
                    : 'Not specified'}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <p className="cert-info-title">{i18n.t('Organization')}</p>
                <span className="cert-info-value">
                  {certificate.owner.organization
                    ? certificate.owner.organization
                    : 'Not specified'}
                </span>
              </td>
              <td>
                <p className="cert-info-title">{i18n.t('Location')}</p>
                <span className="cert-info-value">
                  {`${
                    certificate.owner.city
                      ? certificate.owner.city
                      : i18n.t('City not specified')
                  }, ${
                    certificate.owner.state
                      ? certificate.owner.state
                      : i18n.t('State not specified')
                  }, ${
                    certificate.owner.country
                      ? certificate.owner.country
                      : i18n.t('Country not specified')
                  }`}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <p className="cert-info-title">MD5</p>
                <span className="cert-info-value">
                  {certificate.fingerprints.md5
                    ? certificate.fingerprints.md5
                    : i18n.t('Not specified')}
                </span>
              </td>
              <td className="dont-break-out">
                <p className="cert-info-title">SHA1</p>
                <span className="cert-info-value">
                  {certificate.fingerprints.sha1
                    ? certificate.fingerprints.sha1
                    : i18n.t('Not specified')}
                </span>
              </td>
            </tr>

            <tr>
              <td className="dont-break-out">
                <p className="cert-info-title">SHA256</p>
                <span className="cert-info-value">
                  {certificate.fingerprints.sha256
                    ? certificate.fingerprints.sha256
                    : i18n.t('Not specified')}
                </span>
              </td>
              <td>
                <p className="cert-info-title">{i18n.t('Version')}</p>
                <span className="cert-info-value">
                  {certificate.fingerprints.version
                    ? certificate.fingerprints.version
                    : i18n.t('Not specified')}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <p className="cert-info-title">{i18n.t('Serial Number')}</p>
                <span className="cert-info-value">
                  {certificate.serial_number
                    ? certificate.serial_number
                    : i18n.t('Not specified')}
                </span>
              </td>
              <td>
                <p className="cert-info-title">{i18n.t('Valid')}</p>
                <span className="cert-info-value">
                  {certificate.valid.from && certificate.valid.until
                    ? `${certificate.valid.from} until ${certificate.valid.until}`
                    : i18n.t('Not specified')}
                </span>
              </td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <div>No certificate info found</div>
      )}
    </div>
  );
};

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(CertInfoTable);
