import React from 'react';
import { Row } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QHeaderTitleContainer as MuiHeaderTitleContainer } from '@kw/quokka-ui';

const LDHeaderTitleContainer = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiHeaderTitleContainer {...props}>{children}</MuiHeaderTitleContainer>
  ) : (
    <Row {...props}>{children}</Row>
  );
};
export const QHeaderTitleContainer = withLDConsumer()(LDHeaderTitleContainer);
