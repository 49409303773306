import React from 'react';
import { UncontrolledButtonDropdown } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QDropdown } from '@kw/quokka-ui';

const LDUncontrolledButtonDropdown = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <QDropdown {...props}>{children}</QDropdown>
  ) : (
    <UncontrolledButtonDropdown {...props}>
      {children}
    </UncontrolledButtonDropdown>
  );
};
export const QUncontrolledButtonDropdown = withLDConsumer()(
  LDUncontrolledButtonDropdown,
);
