import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';

import * as actions from '../../store/slices/emmAppSlice';
import Switch from '../Switch';
import { QSwitch } from '../Q-Components/QSwitch';
import { QSwitchControlLabel } from '../Q-Components/QSwitchControlLabel';
import { QModal } from '../Q-Components/QModal';
import { QModalContent } from '../Q-Components/QModalContent';
import { QModalTitle } from '../Q-Components/QModalTitle';
import { QModalActions } from '../Q-Components/QModalActions';
import { QButton } from '../Q-Components/QButton';
import { QTypography } from '../Q-Components/QTypography';
import { QRow } from '../Q-Components/QRow';

export const SummarizedAppsSettingsModal = ({ isOpen, toggle }) => {
  const columnSettings = useSelector(state => state.emmApp.columnSettings);
  const [dateCol, setDateCol] = useState(columnSettings.dateCol);
  const [scoreCol, setScoreCol] = useState(columnSettings.scoreCol);
  const [findingsCol, setFindingsCol] = useState(columnSettings.findingsCol);
  const dispatch = useDispatch();

  function handleSettings() {
    dispatch(
      actions.setColumnSettings({
        dateCol,
        scoreCol,
        findingsCol,
      }),
    );
    toggle();
  }

  const ModalTitle = () => (
    <QModalTitle onClose={toggle} closeIcon={<CloseIcon />}>
      Analyzed App Table Settings
    </QModalTitle>
  );

  const ModalContent = () => (
    <QModalContent>
      <QRow sx={{ marginTop: '24px', marginBottom: '24px' }}>
        <QTypography variant="bodyRegular" color="#000000">
          Columns Shown
        </QTypography>
      </QRow>
      <QRow>
        <QSwitchControlLabel
          label={
            <QTypography variant="bodyRegular" color="#000000">
              Last Submission
            </QTypography>
          }
          control={
            <QSwitch checked={dateCol} onChange={() => setDateCol(!dateCol)} />
          }
        />
      </QRow>
      <QRow>
        <QSwitchControlLabel
          label={
            <QTypography variant="bodyRegular" color="#000000">
              Threat Score(s)
            </QTypography>
          }
          control={
            <QSwitch
              checked={scoreCol}
              onChange={() => setScoreCol(!scoreCol)}
            />
          }
        />
      </QRow>
      <QRow>
        <QSwitchControlLabel
          label={
            <QTypography variant="bodyRegular" color="#000000">
              Number of Issues
            </QTypography>
          }
          control={
            <QSwitch
              checked={findingsCol}
              onChange={() => setFindingsCol(!findingsCol)}
            />
          }
        />
      </QRow>
    </QModalContent>
  );

  const ModalActions = () => (
    <QModalActions>
      <QButton color="secondary" variant="outlined" onClick={toggle}>
        Cancel
      </QButton>
      <QButton
        color="primary"
        variant="filled"
        onClick={() => handleSettings()}
      >
        Save
      </QButton>
    </QModalActions>
  );

  return (
    <QModal
      open={isOpen}
      isOpen={isOpen}
      toggle={toggle}
      title={<ModalTitle />}
      content={<ModalContent />}
      actions={<ModalActions />}
    >
      <ModalHeader>Analyzed App Table Settings</ModalHeader>

      <ModalBody>
        <h6 className="mb-3">Columns Shown</h6>
        <Switch
          onChange={() => setDateCol(!dateCol)}
          value={dateCol}
          label="Last Submission"
        />
        <Switch
          onChange={() => setScoreCol(!scoreCol)}
          value={scoreCol}
          label="Threat Score(s)"
        />
        <Switch
          onChange={() => setFindingsCol(!findingsCol)}
          value={findingsCol}
          label="Number of Issues"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button color="primary" onClick={() => handleSettings()}>
          Save
        </Button>
      </ModalFooter>
    </QModal>
  );
};

export default SummarizedAppsSettingsModal;
