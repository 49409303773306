import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Select from 'react-select';
import { useToggle } from 'react-use';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import produce from 'immer';
import _ from 'lodash';

import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QCardActions } from '../Q-Components/QCardActions';
import { QTypography } from '../Q-Components/QTypography';
import { QAutocomplete } from '../Q-Components/QAutocomplete';
import { QInput } from '../Q-Components/QInput';
import { QButton } from '../Q-Components/QButton';
import { setGroupAdminUsers as setStoreGroupAdminUsers } from '../../store/slices/emmAppSlice';
import { toastrHelper } from '../../logic/toastrHelper';
import Switch from '../Switch';
import { GroupSelection } from '../KAI/GroupSelection';

const useGroupAdminUsers = ({ kaiAccess = false, group = null }) => {
  let groupAdminUsers = useSelector(state =>
    kaiAccess ? state.kai.kaiUsers : state.emmApp.groupAdminUsers,
  );

  if (group) {
    groupAdminUsers = groupAdminUsers.filter(
      user => user.userGroup?.id === group.id,
    );
  }

  const dispatch = useDispatch();

  const setGroupAdminUsers = (
    userId,
    {
      showStandardIssues,
      showNiapSections,
      showOwaspMasvsSections,
      showNetworkTraffic,
      showPassedAnalysisItems,
      showGDPRScore,
    },
  ) => {
    const map = _.mapValues(
      {
        pdfShowStandardIssues: showStandardIssues,
        pdfShowNiapSections: showNiapSections,
        pdfShowOwaspSections: showOwaspMasvsSections,
        pdfShowNetworkTraffic: showNetworkTraffic,
        pdfShowPassedAnalysisItems: showPassedAnalysisItems,
        pdfShowGDPRScore: showGDPRScore,
      },
      value => String(Number(value)),
    );

    const newGroupAdminUsers = produce(groupAdminUsers, draft => {
      const user = draft.find(user => user.id === userId);

      for (const config of user.userConfig) {
        if (map[config.key]) {
          config.value = map[config.key];
        }
      }
    });

    dispatch(setStoreGroupAdminUsers(newGroupAdminUsers));
  };
  return [groupAdminUsers, setGroupAdminUsers];
};

const saveOptionalSections = (
  userId,
  {
    showStandardIssues,
    showNiapSections,
    showOwaspMasvsSections,
    showNetworkTraffic,
    showPassedAnalysisItems,
    showGDPRScore,
  },
) =>
  axios.put(`/group-admin/user/${userId}/optional-report-pdf-sections`, {
    pdfShowStandardIssues: showStandardIssues,
    pdfShowNiapSections: showNiapSections,
    pdfShowOwaspSections: showOwaspMasvsSections,
    pdfShowNetworkTraffic: showNetworkTraffic,
    pdfShowPassedAnalysisItems: showPassedAnalysisItems,
    pdfShowGDPRScore: showGDPRScore,
  });

const getOptionalSections = configs => {
  const optionalSections = {};

  for (const optionalPdfKey of [
    'pdfShowStandardIssues',
    'pdfShowNiapSections',
    'pdfShowOwaspSections',
    'pdfShowNetworkTraffic',
    'pdfShowPassedAnalysisItems',
    'pdfShowGDPRScore',
  ]) {
    optionalSections[optionalPdfKey] = configs.find(
      ({ key }) => key === optionalPdfKey,
    )?.value;

    optionalSections[optionalPdfKey] = Boolean(
      Number(optionalSections[optionalPdfKey]),
    );
  }
  return optionalSections;
};

export const PDFReportConfig = ({ kaiAccess = false }) => {
  const [group, setGroup] = useState();

  const { mastV2 } = useFlags();
  const [groupAdminUsers, setGroupAdminUsers] = useGroupAdminUsers({
    group,
    kaiAccess,
  });

  const userOptions = groupAdminUsers.map(user => ({
    value: user.id,
    label: user.email,
    configs: user.userConfig,
    access: user.userAccess,
  }));

  const [selectedUser, setSelectedUser] = useState({ configs: [] });

  const [
    [showStandardIssues, toggleShowStandardIssues],
    [showNiapSections, toggleNiapSections],
    [showOwaspMasvsSections, toggleOwaspSections],
    [showNetworkTraffic, toggleNetworkTraffic],
    [showPassedAnalysisItems, togglePassedAnalysisItems],
    [showGDPRScore, toggleGDPRScore],
  ] = [
    useToggle(),
    useToggle(),
    useToggle(),
    useToggle(),
    useToggle(),
    useToggle(),
  ];

  const setDefaultOptionalSections = () => {
    const optionalSections = getOptionalSections(selectedUser.configs);
    toggleShowStandardIssues(optionalSections.pdfShowStandardIssues);
    toggleNiapSections(optionalSections.pdfShowNiapSections);
    toggleOwaspSections(optionalSections.pdfShowOwaspSections);
    toggleNetworkTraffic(optionalSections.pdfShowNetworkTraffic);
    togglePassedAnalysisItems(optionalSections.pdfShowPassedAnalysisItems);
    toggleGDPRScore(optionalSections.pdfShowGDPRScore);
  };

  useEffect(setDefaultOptionalSections, [selectedUser.configs]);

  const submit = async () => {
    const result = await saveOptionalSections(selectedUser.value, {
      showStandardIssues,
      showNiapSections,
      showOwaspMasvsSections,
      showNetworkTraffic,
      showPassedAnalysisItems,
      showGDPRScore,
    });

    if (result.status === 200) {
      setGroupAdminUsers(Number(selectedUser.value), {
        showStandardIssues,
        showNiapSections,
        showOwaspMasvsSections,
        showNetworkTraffic,
        showPassedAnalysisItems,
        showGDPRScore,
      });

      toastrHelper.showSuccessToast(
        'Updated optional pdf report sections',
        null,
        mastV2,
      );
    } else {
      toastrHelper.showErrorToast(
        `Error updating optional pdf report sections: ${result.data.msg}`,
        'Error',
        mastV2,
      );
    }
  };

  const handleUserChange = (_, newUser) => {
    setSelectedUser(newUser ?? { configs: [] });
  };

  return (
    <QRow sx={{ paddingTop: '20px' }}>
      <QCol xs="12" flex="1">
        <QCard style={{ width: '42.5%' }} className="card-accent-primary">
          <QCardHeader
            className="flex justify-between align-center"
            title={
              <QTypography variant="h4Bold" color="#000000">
                PDF Report Sections
              </QTypography>
            }
            action={
              kaiAccess && (
                <GroupSelection
                  group={group}
                  setGroup={setGroup}
                  kaiAccess={kaiAccess}
                />
              )
            }
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span>
                <strong>PDF Report Sections</strong>
              </span>
            </div>
            {kaiAccess && (
              <GroupSelection
                group={group}
                setGroup={setGroup}
                kaiAccess={kaiAccess}
              />
            )}
          </QCardHeader>
          <QCardBody>
            <div className="d-flex flex-column">
              {!mastV2 ? (
                <>
                  {/* eslint-disable-next-line */}
                  <label htmlFor="react-select-4-input">
                    <strong>Select User</strong>
                  </label>

                  <Select
                    styles={{
                      container: cont => ({ ...cont, width: '50%' }),
                    }}
                    menuPosition="fixed"
                    label="User"
                    options={userOptions}
                    value={selectedUser}
                    onChange={setSelectedUser}
                  />
                </>
              ) : (
                <QAutocomplete
                  className="z-index-20"
                  label="User"
                  value={selectedUser?.label || null}
                  id="kai-aa-user-filter"
                  options={userOptions}
                  onChange={handleUserChange}
                  isClearable
                  styles={{
                    container: base => ({
                      ...base,
                      width: '100%',
                    }),
                  }}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      style={{
                        wordWrap: 'break-word',
                        display: 'block',
                      }}
                    >
                      {option.label}
                    </li>
                  )}
                  placeholder="Select User"
                  popupIcon={<ExpandMoreIcon fontSize="medium" />}
                  clearIcon={<ClearIcon fontSize="medium" />}
                  renderInput={params => (
                    <QInput
                      {...params}
                      outlinedWithTitle
                      placeholder="Select User"
                      variant="outlined"
                      style={{ textOverflow: 'ellipsis' }}
                      size="small"
                      InputProps={{
                        endAdornment: params.InputProps.endAdornment,
                        startAdornment: params.InputProps.startAdornment,
                        sx: {
                          '.MuiAutocomplete-endAdornment': {
                            position: 'absolute',
                          },
                        },
                        inputProps: params.inputProps,
                        ref: params.InputProps.ref,
                        style: {
                          fontSize: '14px',
                          fontWeight: '400',
                        },
                      }}
                    />
                  )}
                />
              )}

              <br />
              <Switch
                label="Standard Issues"
                onChange={toggleShowStandardIssues}
                value={showStandardIssues}
              />
              <Switch
                label="Niap Sections"
                onChange={toggleNiapSections}
                value={showNiapSections}
                disabled={!selectedUser.access?.niap}
              />
              <Switch
                label="OWASP MASVS Sections"
                onChange={toggleOwaspSections}
                value={showOwaspMasvsSections}
                disabled={!selectedUser.access?.owasp}
              />
              <Switch
                label="Network Traffic"
                onChange={toggleNetworkTraffic}
                value={showNetworkTraffic}
              />
              <Switch
                label="Passed Analysis Items"
                onChange={togglePassedAnalysisItems}
                value={showPassedAnalysisItems}
              />
              <Switch
                label="GDPR Score"
                onChange={toggleGDPRScore}
                value={showGDPRScore}
                disabled={!selectedUser.access?.gdpr}
              />
            </div>
          </QCardBody>
          <QCardActions
            disableSpacing
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <QButton
              disabled={!selectedUser.value}
              onClick={setDefaultOptionalSections}
              color="danger"
              variant="outlined"
            >
              Reset to default
            </QButton>
            <QButton
              disabled={!selectedUser.value}
              color="primary"
              onClick={submit}
              className="float-right"
              variant="filled"
            >
              Save
            </QButton>
          </QCardActions>
        </QCard>
      </QCol>
    </QRow>
  );
};
