import React from 'react';
import { ListGroupItem } from 'reactstrap';
import ListItem from '@mui/material/ListItem';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

const LDListGroupItem = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <ListItem {...props}>{children}</ListItem>
  ) : (
    <ListGroupItem {...props}>{children}</ListGroupItem>
  );
};

export const QListGroupItem = withLDConsumer()(LDListGroupItem);
