import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QSidebarItemText as MuiSidebarItemText } from '@kw/quokka-ui';

const LDSidebarItemText = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiSidebarItemText {...props}>{children}</MuiSidebarItemText>
  ) : (
    { children }
  );
};
export const QSidebarItemText = withLDConsumer()(LDSidebarItemText);
