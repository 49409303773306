import React from 'react';
import { NavbarToggler } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QHeaderIconButton as MuiHeaderToggler } from '@kw/quokka-ui';

const LDHeaderToggler = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiHeaderToggler {...props}>{children}</MuiHeaderToggler>
  ) : (
    <NavbarToggler {...props}>{children}</NavbarToggler>
  );
};
export const QHeaderToggler = withLDConsumer()(LDHeaderToggler);
