import React, { Component } from 'react';

import DynamicAnalysisDatatable from '../../../Android/DynamicAnalysisDatatable';
import ConstantsMethodsPermissionTable from '../../../Android/ConstantsMethodsPermissionTable';
import PermissionsRequestedTable from '../../../Android/PermissionsRequestedTable';
import TabsView from '../../../../TabsView';
import i18n from '../../../../../localization/i18n';

export default class CanCallNumber extends Component {
  render() {
    const tabs = [
      {
        title: i18n.t('Permission Requested'),
        tabContent: () => <PermissionsRequestedTable filter="CALL_PHONE" />,
      },
      {
        title: i18n.t('Constants & Methods of Permission'),
        tabContent: () => (
          <ConstantsMethodsPermissionTable
            showFilter={false}
            filter="CALL_PHONE"
          />
        ),
      },
      {
        title: i18n.t('Dynamic Analysis'),
        tabContent: () => (
          <DynamicAnalysisDatatable
            showFilter={false}
            onlySelectedCategory="Telephony Events"
          />
        ),
      },
    ];
    return <TabsView tabs={tabs} />;
  }
}
