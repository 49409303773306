import React, { Component } from 'react';
import axios from 'axios';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QButton } from '../Q-Components/QButton';
import { QTypography } from '../Q-Components/QTypography';
import HelpButton from '../HelpButton';
import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';

const Title = () => (
  <QTypography variant="h4Bold" color="#000000">
    {i18n.t('Side Loaded App Notifications')}
  </QTypography>
);
class MdmSideLoadedAppNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notificationsEnabled: false,
    };

    this.toggleNotificationEnabled = this.toggleNotificationEnabled.bind(this);
  }

  async componentDidMount() {
    const response = await axios.get(
      'mdm-integration/side-loaded-app-notifications',
    );
    console.log(response.data);

    this.setState({ notificationsEnabled: response.data.enabled === '1' });
  }

  async toggleNotificationEnabled() {
    this.setState(
      { notificationsEnabled: !this.state.notificationsEnabled },
      async () => {
        const { notificationsEnabled } = this.state;
        try {
          await axios.patch('mdm-integration/side-loaded-app-notifications', {
            enabled: notificationsEnabled,
          });
          toastrHelper.showSuccessToast(
            i18n.t('Successfully updating notification preference'),
            null,
            this.props.flags?.mastV2,
          );
        } catch (err) {
          console.log('Error setting side loaded app notifications:', err);
          toastrHelper.showErrorToast(
            i18n.t('Error updating notification preference'),
            null,
            this.props.flags?.mastV2,
          );
        }
      },
    );
  }

  render() {
    const { notificationsEnabled } = this.state;

    return (
      <QCard className="card-accent-primary">
        <QCardHeader
          title={<Title />}
          action={<HelpButton helpItem="sideLoadedAppNotifications" />}
        >
          <strong>
            <Title />
            <HelpButton helpItem="sideLoadedAppNotifications" />
          </strong>{' '}
        </QCardHeader>
        <QCardBody>
          <QButton
            variant={notificationsEnabled ? 'filled' : 'outlined'}
            color={notificationsEnabled ? 'primary' : 'secondary'}
            onClick={this.toggleNotificationEnabled}
          >
            {notificationsEnabled ? i18n.t('Enabled') : i18n.t('Disabled')}
          </QButton>
        </QCardBody>
      </QCard>
    );
  }
}

export default withLDConsumer()(MdmSideLoadedAppNotification);
