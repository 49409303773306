import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import i18n from '../../../localization/i18n';

class AdNetworksList extends Component {
  getAdNetworks(adNetworks, platform) {
    if (platform === 'android') {
      return adNetworks.map(result => result.network);
    }

    return adNetworks;
  }

  render() {
    const { platform, results } = this.props;

    const adNetworks = _.get(results, 'analysis.high_level_issues.ad_networks');
    if (!adNetworks) {
      return null;
    }

    const adNetworksNames = this.getAdNetworks(adNetworks, platform);

    return adNetworksNames.length === 0 ? (
      <span>{i18n.t('No ad networks detected')}</span>
    ) : (
      <ul>
        {adNetworksNames
          .filter(network => network)
          .map((network, index) => (
            <li key={index}>{network}</li>
          ))}
      </ul>
    );
  }
}

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(AdNetworksList);
