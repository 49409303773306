import React, { useEffect, useState } from 'react';
import NProgress from 'nprogress';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QInputGroup } from '../Q-Components/QInputGroup';
import { QButton } from '../Q-Components/QButton';
import { QInput } from '../Q-Components/QInput';
import { QDivider } from '../Q-Components/QDivider';
import { QInputGroupAddon } from '../Q-Components/QInputGroupAddon';
import PlatformSelect from '../PlatformSelect';
import HelpButton from '../HelpButton';
import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';

import StoreResults from './StoreResults';

const StoreSearch = props => {
  const userAccess = useSelector(state => state.emmApp.userAccess);
  const { mastV2 } = useFlags();
  let defaultPlatform = null;

  if (userAccess.android_analysis && userAccess.android_third_party) {
    defaultPlatform = { value: 'android', label: 'Android' };
  } else if (userAccess.ios_analysis) {
    defaultPlatform = { value: 'ios', label: 'iOS' };
  }

  const [storeQuery, setStoreQuery] = useState('');
  const [storeSearchButtonClass, setStoreSearchButtonClass] = useState(
    'fa-magnifying-glass',
  );
  const [storeSearchDisabled, setStoreSearchDisabled] = useState(false);
  const [showAppStoreResults, setShowAppStoreResults] = useState(false);
  const [appStoreData, setAppStoreData] = useState([]);
  const [showPlayStoreResults, setShowPlayStoreResults] = useState(false);
  const [playStoreData, setPlayStoreData] = useState({});
  const [countryStore] = useState({ value: '', label: '' });
  const [selectedPlatform, setSelectedPlatform] = useState(defaultPlatform);

  useEffect(() => {
    setShowAppStoreResults(false);
    setShowPlayStoreResults(false);
    setPlayStoreData({});
    setAppStoreData({});
  }, [props.platform]);

  const lowercasePlatform = platform => platform.toLowerCase();

  /**
   * Submit a store search based on the provided query
   *
   * @memberof StoreSearch
   */
  const performStoreSearch = async platform => {
    if (!platform) {
      return;
    }

    console.log(
      'Performing store search for platform',
      platform,
      ' query:',
      storeQuery,
    );

    // Verify we actually have a search term entered
    if (!storeQuery) {
      toastrHelper.showWarningToast(
        i18n.t('Please enter a search query'),
        i18n.t('Warning'),
        mastV2,
      );
      return;
    }

    NProgress.start();
    setStoreSearchDisabled(true);
    setStoreSearchButtonClass('fa-spin fa-spinner');

    let response;
    try {
      response = await axios.get(
        `emm/store-search/${lowercasePlatform(
          platform,
        )}?query=${storeQuery}&country=${countryStore.value}`,
      );
    } catch (err) {
      console.error('Error searching app market:', err);
      return;
    } finally {
      NProgress.done();
      setStoreSearchDisabled(false);
      setStoreSearchButtonClass('fa-magnifying-glass');
    }

    if (platform === 'Android') {
      console.log('Trying to set Android store search results');
      setPlayStoreData(response.data);
      setShowPlayStoreResults(true);
      setShowAppStoreResults(false);
    } else if (platform === 'iOS') {
      setAppStoreData(response.data);
      setShowAppStoreResults(true);
      setShowPlayStoreResults(false);
    }
  };

  const changePlatform = e => {
    if (e.target.value === 'android') {
      setSelectedPlatform({ value: 'android', label: 'Android' });
    } else if (e.target.value === 'ios') {
      setSelectedPlatform({ value: 'ios', label: 'iOS' });
    }
  };

  /**
   * Handle key presses while inside of the store search query so we can just hit
   * enter to perform the search
   *
   * @param {any} event
   * @memberof StoreSearch
   */
  const handleStoreSearchKeypress = event => {
    /* istanbul ignore else */
    if (event.charCode === 13) {
      event.preventDefault();
      performStoreSearch(selectedPlatform.label);
    }
  };

  if (!userAccess.android_third_party && !userAccess.ios_third_party) {
    return null;
  }

  return (
    <div className="store-search-submit">
      <QCard className="card-accent-success mt-3">
        <QCardHeader disableTypography title={i18n.t('Store query')}>
          <strong>{i18n.t('Store query')}</strong>
          &nbsp;
          <HelpButton helpItem="storeSearch" />
        </QCardHeader>
        <QDivider />
        <QCardBody>
          {mastV2 ? (
            <QRow columnGap="8px" style={{ alignItems: 'center' }}>
              <QCol style={{ width: '162px', height: '48px' }}>
                <PlatformSelect
                  mastV2={mastV2}
                  withIconV2
                  handlePlatformChange={e => {
                    changePlatform(e);
                    setShowAppStoreResults(false);
                    setShowPlayStoreResults(false);
                  }}
                  platformFilter={selectedPlatform}
                  optionFilter={option =>
                    option.value !== 'both' &&
                    ((userAccess.android_analysis &&
                      userAccess.android_third_party &&
                      option.value === 'android') ||
                      (userAccess.ios_analysis &&
                        userAccess.ios_third_party &&
                        option.value === 'ios'))
                  }
                  hideLabel
                />
              </QCol>
              <QCol flex="1">
                <QInput
                  size="small"
                  style={{ height: '32px', width: '100%' }}
                  placeholder={i18n.t('Store query')}
                  onChange={e => setStoreQuery(e.target.value)}
                  onKeyPress={e => handleStoreSearchKeypress(e)}
                  value={storeQuery}
                  InputProps={{
                    style: {
                      height: '32px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                    },
                    inputProps: {
                      style: {
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      },
                      'data-testid': 'store-search-input',
                    },
                  }}
                />
              </QCol>
              <QCol>
                <QButton
                  variant="filled"
                  data-testid="store-search-button"
                  color="primary"
                  disabled={storeSearchDisabled}
                  onClick={() => performStoreSearch(selectedPlatform.label)}
                  startIcon={
                    <i className={`fa-solid ${storeSearchButtonClass}`} />
                  }
                >
                  {i18n.t('Search')}
                </QButton>
              </QCol>
            </QRow>
          ) : (
            <QRow>
              <QCol>
                <QInputGroup>
                  <div style={{ width: '10rem' }}>
                    <PlatformSelect
                      handlePlatformChange={e => {
                        setSelectedPlatform(e);
                        setShowAppStoreResults(false);
                        setShowPlayStoreResults(false);
                      }}
                      platformFilter={selectedPlatform}
                      optionFilter={option =>
                        option.value !== 'both' &&
                        ((userAccess.android_analysis &&
                          userAccess.android_third_party &&
                          option.value === 'android') ||
                          (userAccess.ios_analysis &&
                            userAccess.ios_third_party &&
                            option.value === 'ios'))
                      }
                      hideLabel
                    />
                  </div>
                  <QInput
                    className="search-input"
                    data-testid="store-search-input"
                    type="text"
                    placeholder={i18n.t('Store query')}
                    onChange={e => setStoreQuery(e.target.value)}
                    onKeyPress={e => handleStoreSearchKeypress(e)}
                    value={storeQuery}
                  />
                  <QInputGroupAddon addonType="append">
                    {userAccess.ios_analysis && (
                      <QButton
                        data-testid="store-search-button"
                        color="primary"
                        disabled={storeSearchDisabled}
                        onClick={() =>
                          performStoreSearch(selectedPlatform.label)
                        }
                      >
                        <i className={`fa-solid ${storeSearchButtonClass}`} />
                        &nbsp; {i18n.t('Search')}
                      </QButton>
                    )}
                  </QInputGroupAddon>
                </QInputGroup>
              </QCol>
              <QCol xs="4" />
            </QRow>
          )}

          {/* Search results for each store */}
          {showPlayStoreResults && (
            <StoreResults
              results={playStoreData.results || []}
              platform="Android"
            />
          )}
          {showAppStoreResults && (
            <StoreResults results={appStoreData} platform="iOS" />
          )}
        </QCardBody>
      </QCard>
    </div>
  );
};

export default withRouter(StoreSearch);
