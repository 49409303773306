import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QTypography } from '../Q-Components/QTypography';
import { QButton } from '../Q-Components/QButton';
import { QSelect } from '../Q-Components/QSelect';
import { QCardActions } from '../Q-Components/QCardActions';
import { QForm } from '../Q-Components/QForm';
import Field from '../GroupAdmin/Report/Field';
import { toastrHelper } from '../../logic/toastrHelper';

export default function Report() {
  const { mastV2 } = useFlags();
  const platformOptions = [
    {
      value: 'android',
      label: 'Android',
    },
    {
      value: 'ios',
      label: 'iOS',
    },
  ];
  const [platform, setPlatform] = useState(platformOptions[0]);
  const [currentReportItem, setCurrentReportItem] = useState({});
  const [reportItems, setReportItems] = useState([]);

  const [description, setDescription] = useState('');
  const [threatProfile, setThreatProfile] = useState('');
  const [findingImpact, setFindingImpact] = useState('');
  const [remediation, setRemediation] = useState('');

  const [editableDescription, setEditableDescription] = useState(false);
  const [editableThreatProfile, setEditableThreatProfile] = useState(false);
  const [editableFindingImpact, setEditableFindingImpact] = useState(false);
  const [editableRemediation, setEditableRemediation] = useState(false);

  const [customId, setCustomId] = useState(null);

  const [customizedDescription, setCustomizedDescription] = useState(false);
  const [customizedThreatProfile, setCustomizedThreatProfile] = useState(false);
  const [customizedFindingImpact, setCustomizedFindingImpact] = useState(false);
  const [customizedRemediation, setCustomizedRemediation] = useState(false);

  const [oldDescription, setOldDescription] = useState('');
  const [oldThreatProfile, setOldThreatProfile] = useState('');
  const [oldFindingImpact, setOldFindingImpact] = useState('');
  const [oldRemediation, setOldRemediation] = useState('');

  const [pdfRegeneration, setPDFRegeneration] = useState(false);

  function handlePlatformChange(event, newPlatform) {
    setCurrentReportItem({});
    if (mastV2) {
      const newOption = platformOptions.find(
        option => option.value === newPlatform.props.value,
      );
      setPlatform(newOption);
    } else {
      setPlatform(event);
    }
  }

  // Resetting all input fields
  function resetAllInputFields() {
    setCurrentReportItem({});

    setCustomId(null);

    setDescription('');
    setThreatProfile('');
    setFindingImpact('');
    setRemediation('');

    setOldDescription('');
    setOldThreatProfile('');
    setOldRemediation('');
    setOldFindingImpact('');

    setCustomizedDescription(false);
    setCustomizedThreatProfile(false);
    setCustomizedFindingImpact(false);
    setCustomizedRemediation(false);
  }

  async function getReportItems() {
    const response = await axios.get(
      `api/config/report-items/${platform.value}`,
    );

    if (response.status !== 200) {
      console.log('Error getting report items');
    } else {
      setReportItems(
        response.data.map(item => ({
          value: item.id,
          label: item.positive_finding_text,

          customId: item.custom_report_item && item.custom_report_item.id,

          description: item.description,
          threatProfile: item.threat_profile,
          findingImpact: item.impact,
          remediation: item.remediation,

          customized_description: item.customized_description,
          customized_threat_profile: item.customized_threat_profile,
          customized_finding_impact: item.customized_finding_impact,
          customized_remediation: item.customized_remediation,
        })),
      );
    }
  }

  // Handle input
  function handleReportItemOptions(options) {
    setCurrentReportItem(options);

    setCustomId(options.customId);

    /* Making sure proper field value is updated
    If one field is not updated and not being edited, set current value 
    If a field is updated already, set old value to current value
    If one field is updated and others being edited, the others remain for being edited
    */
    if (description !== options.description && !editableDescription) {
      setDescription(options.description);
      setOldDescription(options.description);
    } else {
      setOldDescription(options.description);
    }

    if (threatProfile !== options.threatProfile && !editableThreatProfile) {
      setThreatProfile(options.threatProfile);
      setOldThreatProfile(options.threatProfile);
    } else {
      setOldThreatProfile(options.threatProfile);
    }
    if (findingImpact !== options.findingImpact && !editableFindingImpact) {
      setFindingImpact(options.findingImpact);
      setOldFindingImpact(options.findingImpact);
    } else {
      setOldFindingImpact(options.findingImpact);
    }
    if (remediation !== options.remediation && !editableRemediation) {
      setRemediation(options.remediation);
      setOldRemediation(options.remediation);
    } else {
      setOldRemediation(options.remediation);
    }

    setCustomizedDescription(options.customized_description);
    setCustomizedThreatProfile(options.customized_threat_profile);
    setCustomizedFindingImpact(options.customized_finding_impact);
    setCustomizedRemediation(options.customized_remediation);
  }

  async function saveTemplate(fieldToUpdate) {
    const reportItemId = currentReportItem.value;

    const response = await axios.put(
      `group-admin/config/custom-report-items/${reportItemId}`,
      {
        customId,
        fieldToUpdate,
        description,
        threatProfile,
        findingImpact,
        remediation,
      },
    );

    if (response.status !== 200) {
      toastrHelper.showErrorToast(response.data.message, 'Error', mastV2);
    } else {
      toastrHelper.showSuccessToast(
        'Successfully updated report item',
        'Success',
        mastV2,
      );
    }

    setPDFRegeneration(true);
    getReportItems();
  }

  async function resetReportItemField(fieldToReset) {
    const response = await axios.put(
      `group-admin/config/custom-report-items/reset/${customId}`,
      {
        fieldToReset,
      },
    );
    if (response.status !== 200) {
      toastrHelper.showErrorToast(response.data.message, 'Error', mastV2);
    } else {
      toastrHelper.showSuccessToast(
        'Successfully reset field',
        'Success',
        mastV2,
      );
    }

    setPDFRegeneration(true);
    getReportItems();
  }

  async function resetAllReportItemFields() {
    setEditableDescription(false);
    setEditableThreatProfile(false);
    setEditableFindingImpact(false);
    setEditableRemediation(false);
    const response = await axios.delete(
      `group-admin/config/custom-report-items/${customId}`,
    );
    if (response.status !== 200) {
      toastrHelper.showErrorToast(response.data.message, 'Error', mastV2);
    } else {
      toastrHelper.showSuccessToast(
        'Successfully reset all report item fields',
        'Success',
        mastV2,
      );
    }

    setPDFRegeneration(true);
    getReportItems();
  }

  async function regenerateRecentPDFReports() {
    try {
      const response = await axios.post(
        `group-admin/regenerate-recent-pdfs?platform=${platform.value}`,
      );
      if (response.status === 200) {
        toastrHelper.showSuccessToast(response.data, 'Success', mastV2);
      }
    } catch (err) {
      toastrHelper.showErrorToast(err.response.data.message, 'Error', mastV2);
    }

    setPDFRegeneration(false);
  }

  // fetch data again when changing platform
  useEffect(() => {
    getReportItems();
    resetAllInputFields();
  }, [platform]);

  useEffect(() => {
    // Update field again base on most recent fetched options
    for (let i = 0; i < reportItems.length; i++) {
      if (reportItems[i].value === currentReportItem.value) {
        handleReportItemOptions(reportItems[i]);
        break;
      }
    }
  }, [currentReportItem.value, handleReportItemOptions, reportItems]);

  return (
    <QRow>
      <QCol xs="12" flex="1">
        <QCard className="card-accent-primary">
          <QCardHeader
            title={
              <QTypography variant="h4Bold" color="#000000">
                Report Customization
              </QTypography>
            }
            action={
              <QButton
                disabled={!pdfRegeneration}
                variant="outlined"
                startIcon={
                  <ContentPasteOutlinedIcon
                    sx={
                      !pdfRegeneration
                        ? { color: '#DDDBDA', padding: '2px' }
                        : { padding: '2px' }
                    }
                  />
                }
                onClick={() => regenerateRecentPDFReports()}
              >
                Regnerate Recent {platform.label} PDF Reports
              </QButton>
            }
          >
            Report Customization{' '}
            <QButton
              disabled={!pdfRegeneration}
              size="sm"
              className="float-right"
              outline
              color="primary"
              onClick={() => regenerateRecentPDFReports()}
            >
              <span>
                <i className="fa-solid fa-file-pdf mr-2" />
                Regnerate Recent {platform.label} PDF Reports
              </span>
            </QButton>
          </QCardHeader>
          <QCardBody>
            <p>
              <QTypography variant="h5Regular">
                User can customize description, threat profiles, finding impact
                and remediation fields for report items
              </QTypography>
            </p>
            <QRow columnGap="20px">
              <QCol xs="2" flex="2">
                {mastV2 ? (
                  <QSelect
                    size="small"
                    options={platformOptions}
                    value={platform.value}
                    onChange={handlePlatformChange}
                    label="Platform"
                    selectIconProps={{ fontSize: 'medium' }}
                    formControlProps={{ sx: { width: '100%' } }}
                  />
                ) : (
                  <>
                    <Label>
                      <strong>Platform</strong>
                    </Label>
                    <Select
                      options={platformOptions}
                      value={platform}
                      onChange={option => setPlatform(option)}
                    />
                  </>
                )}
              </QCol>
              <QCol xs="3" flex="3">
                {mastV2 ? (
                  <QSelect
                    size="small"
                    options={reportItems}
                    value={currentReportItem?.value || null}
                    onChange={(_, newOption) =>
                      handleReportItemOptions(
                        reportItems.find(
                          option => option.value === newOption.props.value,
                        ),
                      )
                    }
                    label="Report Item"
                    selectIconProps={{ fontSize: 'medium' }}
                    formControlProps={{ sx: { width: '100%' } }}
                  />
                ) : (
                  <>
                    <Label>
                      <strong>Report Item</strong>
                    </Label>
                    <Select
                      value={
                        mastV2
                          ? currentReportItem?.value || null
                          : currentReportItem
                      }
                      options={reportItems}
                      onChange={handleReportItemOptions}
                    />
                  </>
                )}
              </QCol>
              <QCol xs="7" flex="7">
                <QForm>
                  <Field
                    label="Description"
                    fieldName="reportCustomDescription"
                    customized={customizedDescription}
                    resetReportItemField={resetReportItemField}
                    isEditing={editableDescription}
                    value={description}
                    oldValue={oldDescription}
                    setValue={setDescription}
                    setIsEditing={setEditableDescription}
                    currentItemId={currentReportItem.value}
                    onSave={saveTemplate}
                  />
                  <Field
                    label="Threat Profile"
                    fieldName="reportCustomThreatProfile"
                    customized={customizedThreatProfile}
                    resetReportItemField={resetReportItemField}
                    isEditing={editableThreatProfile}
                    value={threatProfile}
                    oldValue={oldThreatProfile}
                    setValue={setThreatProfile}
                    setIsEditing={setEditableThreatProfile}
                    currentItemId={currentReportItem.value}
                    onSave={saveTemplate}
                  />
                  <Field
                    label="Finding Impact"
                    fieldName="reportCustomFindingImpact"
                    customized={customizedFindingImpact}
                    resetReportItemField={resetReportItemField}
                    isEditing={editableFindingImpact}
                    value={findingImpact}
                    oldValue={oldFindingImpact}
                    setValue={setFindingImpact}
                    setIsEditing={setEditableFindingImpact}
                    currentItemId={currentReportItem.value}
                    onSave={saveTemplate}
                  />
                  <Field
                    label="Remediation"
                    fieldName="reportCustomRemediation"
                    customized={customizedRemediation}
                    resetReportItemField={resetReportItemField}
                    isEditing={editableRemediation}
                    value={remediation}
                    oldValue={oldRemediation}
                    setValue={setRemediation}
                    setIsEditing={setEditableRemediation}
                    currentItemId={currentReportItem.value}
                    onSave={saveTemplate}
                  />
                </QForm>
              </QCol>
            </QRow>
          </QCardBody>

          <QCardActions
            disableSpacing
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <QButton
              disabled={!customId}
              onClick={() => resetAllReportItemFields()}
              color="danger"
              variant="outlined"
            >
              Reset to default
            </QButton>
            <QButton
              disabled={
                !currentReportItem.value ||
                (description === oldDescription &&
                  threatProfile === oldThreatProfile &&
                  findingImpact === oldFindingImpact &&
                  remediation === oldRemediation)
              }
              className="float-right"
              variant="filled"
              color="primary"
              onClick={() => {
                saveTemplate('all');
                setEditableDescription(false);
                setEditableFindingImpact(false);
                setEditableThreatProfile(false);
                setEditableRemediation(false);
              }}
            >
              Save All
            </QButton>
          </QCardActions>
        </QCard>
      </QCol>
    </QRow>
  );
}
