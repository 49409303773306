import React from 'react';
import { Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import { changeReviewStatusFilter } from '../../store/slices/analyzedAppsSlice';
import i18n from '../../localization/i18n';
import { QSelect } from '../Q-Components/QSelect';

import { reviewStatusOptions } from './AnalyzedAppsFilterOptions';

function LDReviewStatusFilter({ getApps }) {
  const dispatch = useDispatch();
  const { mastV2 } = useFlags();

  const reviewStatusFilter = useSelector(
    state => state.analyzedApps.reviewStatusFilter,
  );

  // --//

  const tempReviewStatusFilterV2 = reviewStatusFilter?.value || null;
  const tempReviewStatusFilter = { ...reviewStatusFilter };
  tempReviewStatusFilter.label = i18n.t(tempReviewStatusFilter.label);

  const handleReviewStatusChange = (event, option) => {
    if (mastV2) {
      const newOption = reviewStatusOptions.find(
        item => item.value === option.props.value,
      );
      dispatch(changeReviewStatusFilter(newOption));
    } else {
      dispatch(changeReviewStatusFilter(event));
    }
    getApps();
  };

  return (
    <div>
      {!mastV2 && (
        <Label for="review-status-select" style={{ paddingTop: '5px' }}>
          <strong>{i18n.t('Review Status')}:</strong>
        </Label>
      )}
      <QSelect
        size="small"
        options={reviewStatusOptions}
        value={mastV2 ? tempReviewStatusFilterV2 : tempReviewStatusFilter}
        onChange={handleReviewStatusChange}
        label="Review Status"
        className={mastV2 ? '' : 'z-index-10'}
        selectIconProps={{ fontSize: 'medium' }}
        formControlProps={{ sx: { width: '100%' } }}
      />
    </div>
  );
}

const ReviewStatusFilter = withLDConsumer()(LDReviewStatusFilter);

export default ReviewStatusFilter;
