import React, { useState, useEffect } from 'react';
import { InputGroup, InputGroupAddon, Button, Input, Label } from 'reactstrap';
import axios from 'axios';
import moment from 'moment';
import { withLDConsumer, useFlags } from 'launchdarkly-react-client-sdk';
import CloseIcon from '@mui/icons-material/Close';

import { toastrHelper } from '../logic/toastrHelper';
import i18n from '../localization/i18n';

import { QCard } from './Q-Components/QCard';
import { QCardHeader } from './Q-Components/QCardHeader';
import { QCardBody } from './Q-Components/QCardBody';
import { QRow } from './Q-Components/QRow';
import { QCol } from './Q-Components/QCol';
import { QLabel } from './Q-Components/QLabel';
import { QInputGroup } from './Q-Components/QInputGroup';
import { QInput } from './Q-Components/QInput';
import { QInputGroupAddon } from './Q-Components/QInputGroupAddon';
import { QButton } from './Q-Components/QButton';
import { QModal } from './Q-Components/QModal';
import { QModalTitle } from './Q-Components/QModalTitle';
import { QModalContent } from './Q-Components/QModalContent';
import QDataTable from './DataTable/QDataTable';
import DataTable from './DataTable/DataTable';
import { QTable } from './Q-Components/QTable';
import { QTableHead } from './Q-Components/QTableHead';
import { QTableRow } from './Q-Components/QTableRow';
import { QTableCell } from './Q-Components/QTableCell';
import { QTableBody } from './Q-Components/QTableBody';
import { QTypography } from './Q-Components/QTypography';
import { QLink } from './Q-Components/QLink';

const LDWatchlistDeveloper = () => {
  const { mastV2 } = useFlags();

  const [developerUrl, setDeveloperUrl] = useState('');
  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  async function fetchWatchlistDeveloper() {
    try {
      const response = await axios.get('/watchlist-developer');

      setData(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  const handleRowClick = row => {
    setIsOpen(!isOpen);
    setDataDetail(row);
  };

  const validateDeveloperUrl = async developerUrl => {
    const developerUrlRegexes = [
      /https:\/\/apps\.apple\.com\/[a-z]{2}\/developer\/[a-z-]*\/id[0-9]+/gi,
    ];

    const validated = developerUrlRegexes.map(regex => {
      if (developerUrl.match(regex)) {
        return true;
      }
      return false;
    });

    if (validated.includes(true)) {
      try {
        await axios.post('/watchlist-developer', {
          developerUrl,
        });

        fetchWatchlistDeveloper();

        toastrHelper.showSuccessToast(
          'Successfully tracking developer',
          'Success',
          mastV2,
        );
      } catch (err) {
        toastrHelper.showErrorToast(
          'Error tracking developer',
          'Error',
          mastV2,
        );
      }

      setDeveloperUrl('');
    } else {
      toastrHelper.showErrorToast(
        'Invalid developer url format',
        'Error',
        mastV2,
      );
    }
  };

  useEffect(() => {
    fetchWatchlistDeveloper();
  }, []);

  return (
    <>
      <QModal
        open={isOpen}
        isOpen={isOpen}
        size="lg"
        toggle={() => setIsOpen(!isOpen)}
        title={
          <QModalTitle
            onClose={() => setIsOpen(!isOpen)}
            closeIcon={<CloseIcon />}
          >
            <QTypography variant="h5Regular">
              {i18n.t('Package Detail')}
            </QTypography>
          </QModalTitle>
        }
        content={
          <QModalContent>
            <QTable bordered>
              <QTableHead>
                <QTableRow>
                  <QTableCell>
                    <QTypography variant="bodyRegular">Package</QTypography>
                  </QTableCell>
                  <QTableCell>
                    <QTypography variant="bodyRegular">Version</QTypography>
                  </QTableCell>
                </QTableRow>
              </QTableHead>
              <QTableBody>
                {dataDetail.watchlistApps?.map(app => (
                  <QTableRow key={app.id}>
                    <QTableCell>
                      <QTypography variant="bodyRegular">
                        {app.package}
                      </QTypography>
                    </QTableCell>
                    <QTableCell>
                      <QTypography variant="bodyRegular">
                        {app.latestVersion}
                      </QTypography>
                    </QTableCell>
                  </QTableRow>
                ))}
              </QTableBody>
            </QTable>
          </QModalContent>
        }
      >
        <QModalTitle>
          <QTypography variant="bodyRegular">Package Detail</QTypography>
        </QModalTitle>
        <QModalContent>
          <QTable bordered>
            <QTableHead>
              <QTableRow>
                <QTableCell>
                  <QTypography variant="bodyRegular">Package</QTypography>
                </QTableCell>
                <QTableCell>
                  <QTypography variant="bodyRegular">Version</QTypography>
                </QTableCell>
              </QTableRow>
            </QTableHead>
            <QTableRow>
              {dataDetail.watchlistApps?.map(app => (
                <QTableRow key={app.id}>
                  <QTableCell>
                    <QTypography variant="bodyRegular">
                      {app.package}
                    </QTypography>
                  </QTableCell>
                  <QTableCell>
                    <QTypography variant="bodyRegular">
                      {app.latestVersion}
                    </QTypography>
                  </QTableCell>
                </QTableRow>
              ))}
            </QTableRow>
          </QTable>
        </QModalContent>
      </QModal>
      {mastV2 ? (
        <>
          <QCol
            xs={12}
            sx={{
              justifyContent: 'flex-end',
              display: 'flex',
            }}
          >
            <QButton
              size="sm"
              color="link"
              variant="outlined"
              startIcon={<i className="fa-regular fa-arrows-rotate" />}
              sx={{ justifyContent: 'flex-end' }}
              onClick={() => fetchWatchlistDeveloper()}
              style={{
                border: 'none',
                background: 'none',
                padding: '0px',
                minWidth: 'unset',
              }}
            />
          </QCol>
          <QLabel>
            <QTypography variant="bodyRegular">
              <strong>Developer URL</strong> (only Apple App store developers)
            </QTypography>
          </QLabel>
          <QInputGroup>
            <QRow sx={{ width: '100%' }}>
              <QCol sx={{ width: '90%' }}>
                <QInput
                  placeholder="https://apps.apple.com/us/developer/abc/id12345"
                  value={developerUrl}
                  onChange={e => setDeveloperUrl(e.target.value)}
                />
              </QCol>
              <QCol sx={{ width: '10%', paddingLeft: '6px' }}>
                <QInputGroupAddon addonType="append">
                  <QButton
                    variant="filled"
                    color="secondary"
                    onClick={() => validateDeveloperUrl(developerUrl)}
                    data-testid="validate-developer-url"
                  >
                    Add
                  </QButton>
                </QInputGroupAddon>
              </QCol>
            </QRow>
          </QInputGroup>
          {data && (
            <QDataTable
              showBottomPagination
              showPageSize
              data={data}
              onRowClick={row => handleRowClick(row)}
              columns={[
                {
                  label: 'Developer',
                  keyName: 'developerName',
                  filterable: true,
                },
                {
                  label: 'Developer URL',
                  keyName: 'developerUrl',
                  filterable: true,
                  component: ({ value }) => (
                    <QLink href={value} target="_blank">
                      {value}
                    </QLink>
                  ),
                },

                {
                  label: 'Tracking Since',
                  keyName: 'trackingSince',
                  component: ({ value }) => (
                    <span>{moment(value).format('L - LTS')}</span>
                  ),
                },
                {
                  label: 'Last Check At',
                  keyName: 'lastCheckedAt',
                  component: ({ value }) => (
                    <span>{moment(value).format('L - LTS')}</span>
                  ),
                },
              ]}
            />
          )}
        </>
      ) : (
        <QCard className="card-accent-teal">
          <QCardHeader>
            <div className="d-flex justify-between">
              <div>
                <QTypography variant="bodyRegular">
                  <strong>Watchlist Developers</strong>
                </QTypography>
              </div>
              <Button
                size="sm"
                color="link"
                className="fa-solid fa-arrows-rotate pointer black-icon"
                onClick={() => fetchWatchlistDeveloper()}
              />
            </div>
          </QCardHeader>
          <QCardBody>
            <QRow>
              <QCol xs="7">
                <Label>
                  <QTypography variant="bodyRegular">
                    <strong>Developer URL</strong> (only Apple App store
                    developers)
                  </QTypography>
                </Label>
                <InputGroup>
                  <Input
                    placeholder="https://apps.apple.com/us/developer/abc/id12345"
                    value={developerUrl}
                    onChange={e => setDeveloperUrl(e.target.value)}
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      color="secondary"
                      onClick={() => validateDeveloperUrl(developerUrl)}
                      data-testid="validate-developer-url"
                    >
                      <QTypography variant="bodyRegular">Add</QTypography>
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </QCol>
            </QRow>
            <QRow>
              {data && (
                <DataTable
                  data={data}
                  onRowClick={row => handleRowClick(row)}
                  columns={[
                    {
                      label: 'Developer',
                      keyName: 'developerName',
                      filterable: true,
                    },
                    {
                      label: 'Developer URL',
                      keyName: 'developerUrl',
                      filterable: true,
                      component: ({ value }) => (
                        <QLink href={value} target="_blank">
                          {value}
                        </QLink>
                      ),
                    },

                    {
                      label: 'Tracking Since',
                      keyName: 'trackingSince',
                      component: ({ value }) => (
                        <span>{moment(value).format('L - LTS')}</span>
                      ),
                    },
                    {
                      label: 'Last Check At',
                      keyName: 'lastCheckedAt',
                      component: ({ value }) => (
                        <span>{moment(value).format('L - LTS')}</span>
                      ),
                    },
                  ]}
                />
              )}
            </QRow>
          </QCardBody>
        </QCard>
      )}
    </>
  );
};

const WatchlistDeveloper = withLDConsumer()(LDWatchlistDeveloper);

export default WatchlistDeveloper;
