import React from 'react';
import { CardText } from 'reactstrap';

import DynamicAnalysisDatatable from '../../Android/DynamicAnalysisDatatable';
import FeaturesAndLibrariesTable from '../../Android/FeaturesAndLibrariesTable';
import { StaticAnalysisDatatable } from '../../Android/StaticAnalysisDatatable';
import { LibrariesTable } from '../../Android/LibrariesTable';
import { SdksTable } from '../../Android/SdksTable';
import DynamicallyLoadedLibrariesDatatable from '../../iOS/DynamicallyLoadedLibrariesDatatable';
import SystemLibrariesDatatable from '../../iOS/SystemLibrariesDatatable';
import SystemFrameworksDatatable from '../../iOS/SystemFrameworksDatatable';

export const V5_6 = ({ platform }) => (
  <>
    {platform === 'android' ? (
      <>
        <CardText>
          <strong>Dynamic Analysis</strong>
        </CardText>
        <DynamicAnalysisDatatable onlySelectedCategory="Libraries loaded" />
        <hr />

        <CardText>
          <strong>Static Analysis</strong>
        </CardText>
        <StaticAnalysisDatatable
          categoryFilter="methods"
          keyFilter="librariesLoaded"
        />
        <hr />

        <CardText>
          <strong>Metadata Analysis</strong>
        </CardText>
        <FeaturesAndLibrariesTable librariesOnly />
        <hr />

        <CardText>
          <strong>Libraries</strong>
        </CardText>
        <LibrariesTable />
        <hr />

        <CardText>
          <strong>Sdks</strong>
        </CardText>
        <SdksTable />
      </>
    ) : (
      <>
        <CardText>
          <strong>Dynamic Loaded Libraries</strong>
        </CardText>
        <DynamicallyLoadedLibrariesDatatable />
        <hr />

        <CardText>
          <strong>System Libraries</strong>
        </CardText>
        <SystemLibrariesDatatable />
        <hr />

        <CardText>
          <strong>System Frameworks </strong>
        </CardText>
        <SystemFrameworksDatatable />
      </>
    )}
  </>
);
