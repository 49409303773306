import React from 'react';
import { CardText } from 'reactstrap';

import { Components } from '../../Android/Components';
import DynamicAnalysisDatatable from '../../Android/DynamicAnalysisDatatable';
import { StaticAnalysisDatatable } from '../../Android/StaticAnalysisDatatable';
import { AppManifest } from '../../AppManifest';

export const V6_2 = ({ platform, evidence }) =>
  platform === 'android' ? (
    <>
      <Components />
      <hr />
      <CardText>
        <strong>Dynamic Analysis</strong>
      </CardText>
      <DynamicAnalysisDatatable
        onlySelectedCategory={[
          'Intents Created',
          'Intents Sent',
          'Broadcast Receivers',
        ]}
      />
      <hr />
      <CardText>
        <strong>Static Analysis</strong>
      </CardText>
      <StaticAnalysisDatatable categoryFilter="methods" keyFilter="intents" />
      <StaticAnalysisDatatable
        categoryFilter="methods"
        keyFilter="broadcastReceivers"
      />
    </>
  ) : (
    <>
      <CardText>
        <strong>App Manifest</strong>
      </CardText>
      <AppManifest />
      <hr />

      <CardText>
        <strong>Third Party Apps URL Schemes</strong>
      </CardText>
      <ul>
        {evidence.thirdPartyAppsUrlSchemes?.length > 0
          ? evidence.thirdPartyAppsUrlSchemes.map(app => <li>{app}</li>)
          : 'Not Available'}
      </ul>
    </>
  );
