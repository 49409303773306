import React from 'react';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';
import _ from 'lodash';

import { DopplerNextResults } from '../../../Android/DopplerNextResults';

const mapStateToProps = ({ appResults }) => ({
  results: appResults.results,
});

const DopplerResult = connect(mapStateToProps)(
  ({ results, impact, ruleIds = [] }) => {
    const dopplerResults = _.get(results, 'doppler');
    const isDopplerResultNotArray = !Array.isArray(dopplerResults);

    if (isDopplerResultNotArray) {
      return <DopplerNextResults filterRules={ruleIds} />;
    }

    const evidence = dopplerResults.find(
      ({ impact: itemImpact }) => impact === itemImpact,
    );

    return <DynamicTable table={evidence} />;
  },
);

export { DopplerResult };

// __helpers__
const intersperseDivider = children => {
  const tdTop = {
    style: {
      borderTop: 'none',
    },
  };

  return children.map((child, index) => (
    <tr>
      <td {...(index === 0 && tdTop)}>{child}</td>
    </tr>
  ));
};

const getCol = (col, key = '') => {
  // recursively traverse through doppler findings to map nested objects
  if (key === 'component') {
    const thTopStyles = {
      borderTop: 'none',
    };

    const intentFilters = col.intent_filters
      ? col.intent_filters[0].action
      : [];
    return (
      <>
        <tr>
          <th style={thTopStyles}>{col.name}</th>
        </tr>
        <tr>
          <td>{String(col.permission)}</td>
        </tr>
        {col.intent_filters &&
          Object.keys(intentFilters[0]).map(filter => (
            <>
              <tr>
                <th>{filter}</th>
              </tr>
              {intentFilters.map(filterHash => (
                <tr>
                  <td>{filterHash[filter]}</td>
                </tr>
              ))}
            </>
          ))}
      </>
    );
  }

  if (key === 'CWEs') {
    return intersperseDivider(
      col.map(link => (
        <a href={link} target="_blank">
          {link}
        </a>
      )),
    );
  }

  if (typeof col === 'object' && !Array.isArray(col) && col !== null) {
    return <DynamicTable table={col} />;
  }

  if (Array.isArray(col)) {
    return intersperseDivider(col.map(getCol));
  }

  if (typeof col === 'boolean') {
    return String(col);
  }

  return col;
};

const DynamicTable = ({ table }) => {
  const responsiveStyle = {
    display: 'block',
    width: '100%',
    overflowX: 'auto',
  };
  const tdStyles = {
    maxWidth: '600px',
    minWidth: '200px',
  };
  // https://www.codegrepper.com/code-examples/javascript/snake+case+to+title+case+javascript
  // edit: fixed some bugs, es6 fn
  const titleCase = sentence => {
    sentence = sentence.toLowerCase().split('_').filter(Boolean);
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }

    return sentence.join(' ');
  };

  const remapTable = table => {
    const blacklistedPathProps = [
      'call_logcat_paths',
      'path_to_MASTER_CLEAR',
      'path_to_broadcast_intent',
      'path',
    ];
    return _.omit(table, 'risk', ...blacklistedPathProps);
  };
  table = remapTable(table);

  return (
    <>
      <div style={responsiveStyle}>
        <Table>
          <thead>
            <tr>
              {Object.keys(table).map(header => (
                <th>{titleCase(header)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {Object.entries(table)
                .map(([key, val]) => getCol(val, key))
                .map(el => (
                  <td style={tdStyles}>{el}</td>
                ))}
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};
