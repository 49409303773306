import React, { useEffect } from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
} from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { RadialBarChart, RadialBar, PolarAngleAxis } from 'recharts';
import { useDispatch, connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QCard } from '../components/Q-Components/QCard';
import { QCardHeader } from '../components/Q-Components/QCardHeader';
import { QCardBody } from '../components/Q-Components/QCardBody';
import { QTypography } from '../components/Q-Components/QTypography';
import { QRow } from '../components/Q-Components/QRow';
import { QCol } from '../components/Q-Components/QCol';
import { QTable } from '../components/Q-Components/QTable';
import { QTableHead } from '../components/Q-Components/QTableHead';
import { QTableCell } from '../components/Q-Components/QTableCell';
import { QTableBody } from '../components/Q-Components/QTableBody';
import { QTableRow } from '../components/Q-Components/QTableRow';
import { QActionSelect } from '../components/Q-Components/QActionSelect';
import i18n from '../localization/i18n';
import { toastrHelper } from '../logic/toastrHelper';
import { changePage } from '../store/slices/analyzedFirmwareSlice';
import AsyncTablePagination from '../components/AsyncTablePagination';
import Loading from '../components/Loading';
import { fetchFirmwareCache } from '../store/sliceHelpers/firmwareCacheSliceHelper';
import auth from '../logic/auth';
import { invalidateFirmwareCache } from '../store/slices/cache/firmwareCacheSlice';
import { setPageTitle } from '../store/slices/emmAppSlice';

import SubmitFirmware from './SubmitFirmware';

let pageSize = { value: 10, label: '10' };

export const AnalyzedFirmware = ({ filters }) => {
  const { page } = filters;
  const [images, setImages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    lastPage: 1,
    totalData: 1,
    dataPerPage: pageSize.value,
  });
  const [pdfGenerationInProgress, setPdfGenerationInProgress] =
    React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { mastV2 } = useFlags();

  const pageSizeOptions = [
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];

  const getImages = async (pageNum = 1, event = null) => {
    setLoading(true);
    if (event) event.preventDefault();

    try {
      await dispatch(changePage(Number(pageNum)));
      const scansUrl = `${process.env.REACT_APP_FIRMWARE_SERVICE_API}/scans/pages/${pageNum}?query=&pageSize=${pageSize.value}`;
      const { data } = await dispatch(fetchFirmwareCache(scansUrl));
      setImages(data.results);
      setPagination({
        lastPage: Math.ceil(Number(data.total) / pageSize.value),
        totalData: data.total,
        dataPerPage: pageSize.value,
      });
      setLoading(false);
    } catch (err) {
      console.log('Error getting firmware images: ', err);
    }
  };

  const clearImages = async () => {
    await dispatch(invalidateFirmwareCache());
    await getImages();
  };

  const generatePdfReport = async imageId => {
    try {
      setPdfGenerationInProgress(true);
      await axios.post(
        `${
          process.env.REACT_APP_FIRMWARE_SERVICE_API
        }/pdf/request/${imageId}?token=${auth.getToken()}`,
      );
      toastrHelper.showSuccessToast(
        'Generating PDF report, this might take a few minutes, will email you when this is done or please check again in few minutes',
        null,
        mastV2,
      );
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error sending request to generate PDF report',
        null,
        mastV2,
      );
    }
  };

  useEffect(() => {
    getImages();
    dispatch(setPageTitle('Firmware Analysis'));
  }, []);

  const handlePageSizeChange = async (event = null) => {
    pageSize = event;
    await getImages();
  };

  const getFirmwareStatus = image => {
    let completedApps = 0;

    if (image.apps.length === 0) {
      return 0;
    }

    image.apps.forEach(app => {
      if (app.status === 2) {
        completedApps += 1;
      }
    });

    const completedPercentage = Math.floor(
      (completedApps / image.apps.length) * 100,
    );

    return completedPercentage;
  };

  const FirmwareStatusBadge = ({ image }) => {
    const completedPercentage = getFirmwareStatus(image);

    const data = [{ value: completedPercentage }];

    const circleSize = 40;

    return (
      <RadialBarChart
        width={circleSize}
        height={circleSize}
        cx={circleSize / 2}
        cy={circleSize / 2}
        innerRadius={22}
        outerRadius={18}
        barSize={3}
        data={data}
        startAngle={90}
        endAngle={-270}
      >
        <PolarAngleAxis
          type="number"
          domain={[0, 100]}
          angleAxisId={0}
          tick={false}
        />
        <RadialBar
          background
          clockWise
          dataKey="value"
          cornerRadius={circleSize / 2}
          fill="#82ca9d"
        />
        <text
          x={circleSize / 2}
          y={circleSize / 2}
          textAnchor="middle"
          dominantBaseline="middle"
          className="progress-label"
          style={{ fontSize: '8px' }}
        >
          {completedPercentage} %
        </text>
      </RadialBarChart>
    );
  };

  return (
    <>
      <div className="animated fadeIn">
        <SubmitFirmware handleSubmissionFinished={clearImages} />
        <QCard sx={{ marginTop: '20px' }}>
          <QCardHeader
            title={
              <QTypography variant="h4Medium" color="#000000">
                <strong>Analyzed Firmware</strong>
              </QTypography>
            }
          >
            <strong>Analyzed Firmware</strong>
          </QCardHeader>

          <QCardBody>
            {!mastV2 && (
              <QRow>
                <QCol xs="3">
                  <Label for="firmware-per-page-select">
                    <strong>{i18n.t('Firmware per page')}:</strong>
                  </Label>
                  <Select
                    value={pageSize}
                    options={pageSizeOptions}
                    onChange={e => handlePageSizeChange(e)}
                  />
                </QCol>
              </QRow>
            )}

            <div
              className={`analyzed-firmware-table-pagination ${
                !mastV2 && 'd-flex justify-end'
              }`}
            >
              {/* Table pagination */}
              <AsyncTablePagination
                pagination={pagination}
                page={page}
                getPage={getImages}
                handlePageSizeChange={e => handlePageSizeChange(e)}
                currentRows={images.length}
                showRowsPerPage
                pageSize={pageSize}
                pageSizeOptions={pageSizeOptions}
              />
            </div>

            {loading ? (
              <Loading />
            ) : (
              <QTable>
                <QTableHead>
                  <QTableRow>
                    <QTableCell>
                      <QTypography variant="bodyRegular">Filename</QTypography>
                    </QTableCell>
                    <QTableCell>
                      <QTypography variant="bodyRegular">Apps</QTypography>
                    </QTableCell>
                    <QTableCell>
                      <QTypography variant="bodyRegular">Status</QTypography>
                    </QTableCell>
                    <QTableCell>
                      <QTypography variant="bodyRegular">Submitted</QTypography>
                    </QTableCell>
                    <QTableCell>
                      <QTypography variant="bodyRegular">Actions</QTypography>
                    </QTableCell>
                  </QTableRow>
                </QTableHead>
                <QTableBody>
                  {images.map(image => (
                    <QTableRow key={image.id}>
                      <QTableCell>
                        <QTypography variant="bodyRegular">
                          {image.name}
                        </QTypography>
                      </QTableCell>
                      <QTableCell>
                        <QTypography variant="bodyRegular">
                          {image.apps.length}
                        </QTypography>
                      </QTableCell>
                      <QTableCell>
                        <FirmwareStatusBadge image={image} />
                      </QTableCell>
                      <QTableCell>
                        <QTypography variant="bodyRegular">
                          {moment(image.createdAt).format('L - LTS')}
                        </QTypography>
                      </QTableCell>
                      <QTableCell>
                        {mastV2 ? (
                          <QActionSelect
                            actionOptions={[
                              {
                                label: 'App List',
                                value: 'app-list',
                                icon: <i className="fa-solid fa-list" />,
                                link: `/analyzed-firmware/${image.id}/apps`,
                              },
                              {
                                label: 'Request Firmware Report',
                                value: 'regenerate-pdf',
                                icon: <i className="fa-solid fa-file" />,
                                onClick: () => generatePdfReport(image.id),
                                disabled: getFirmwareStatus(image) < 1,
                              },
                              {
                                label: 'Firmware Report',
                                value: 'firmware-report',
                                icon: <i className="fa-solid fa-file-pdf" />,
                                onClick: () =>
                                  window.open(
                                    `${
                                      process.env.REACT_APP_FIRMWARE_SERVICE_API
                                    }/pdf/${image.id}?token=${auth.getToken()}`,
                                    '_blank',
                                  ),
                                disabled:
                                  image.pdfStatus === 0 ||
                                  image.pdfStatus === 1 ||
                                  pdfGenerationInProgress,
                              },
                            ]}
                          />
                        ) : (
                          <UncontrolledButtonDropdown
                            className={`mx-1 ${
                              getFirmwareStatus(image) < 1 &&
                              'disabled-uncontrolled-button-dropdown'
                            }`}
                            title="Actions"
                            disabled={getFirmwareStatus(image) < 1}
                          >
                            <DropdownToggle
                              caret
                              className="toggledown-primary"
                            >
                              Actions
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem header>
                                Firmware Actions
                              </DropdownItem>
                              <DropdownItem
                                id="firmware-app-list"
                                title="App Lists"
                                onClick={() =>
                                  history.push(
                                    `/analyzed-firmware/${image.id}/apps`,
                                  )
                                }
                              >
                                <i className="fa-solid fa-list" />
                                <span>App List</span>
                              </DropdownItem>
                              <DropdownItem
                                id="request-firmware-report"
                                title="Request Firmware Report"
                                onClick={() => generatePdfReport(image.id)}
                                disabled={pdfGenerationInProgress}
                              >
                                <i className="fa-solid fa-file" />
                                <span>Request Firmware Report</span>
                              </DropdownItem>
                              <DropdownItem
                                id="firmware-report"
                                title="Firmware Report"
                                href={`${
                                  process.env.REACT_APP_FIRMWARE_SERVICE_API
                                }/pdf/${image.id}?token=${auth.getToken()}`}
                                target="_blank"
                                disabled={
                                  image.pdfStatus === 0 ||
                                  image.pdfStatus === 1 ||
                                  pdfGenerationInProgress
                                }
                              >
                                <i className="fa-solid fa-file-pdf" />
                                <span>Firmware Report</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        )}
                      </QTableCell>
                    </QTableRow>
                  ))}
                </QTableBody>
              </QTable>
            )}
            <div className="analyzed-firmware-table-pagination d-flex justify-end">
              {/* Table pagination */}
              <AsyncTablePagination
                pagination={pagination}
                page={page}
                getPage={getImages}
              />
            </div>
          </QCardBody>
        </QCard>
      </div>
    </>
  );
};

const mapStateToProps = ({ analyzedFirmware }) => ({
  filters: analyzedFirmware,
});

export default connect(mapStateToProps)(AnalyzedFirmware);
