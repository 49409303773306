import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const RemainingUsers = () => {
  const [remainingUsers, setRemainingUsers] = useState(0);
  const groupAdminUsers = useSelector(({ emmApp }) => emmApp.groupAdminUsers);
  const maxGroupUsers = useSelector(
    ({ emmApp }) => emmApp.license.maxGroupUsers,
  );

  useEffect(() => {
    const updateRemainingUsers = async () => {
      if (maxGroupUsers > 0) {
        setRemainingUsers(maxGroupUsers - groupAdminUsers.length);
      }
    };
    updateRemainingUsers();
  }, []);

  return (
    <>
      <p style={{ fontSize: '12px', fontStyle: 'italic', margin: 'auto' }}>
        Remaining Users: {remainingUsers}
      </p>
      <hr style={{ marginTop: '0.5rem' }} />
    </>
  );
};
