import React, { Component } from 'react';

import TabsView from '../../../../TabsView';
import PermissionsRequestedDatatable from '../../../iOS/PermissionsRequestedDatatable';
import SystemFrameworksDatatable from '../../../iOS/SystemFrameworksDatatable';
import SystemLibrariesDatatable from '../../../iOS/SystemLibrariesDatatable';
import i18n from '../../../../../localization/i18n';

export default class AccessCameraEvidence extends Component {
  render() {
    const tabs = [
      {
        title: i18n.t('Permissions Requested'),
        tabContent: () => <PermissionsRequestedDatatable filter="camera" />,
      },
      {
        title: i18n.t('Frameworks'),
        tabContent: () => (
          <SystemFrameworksDatatable filter="AVFoundation" showFilter={false} />
        ),
      },
      {
        title: i18n.t('Libraries'),
        tabContent: () => (
          <SystemLibrariesDatatable
            filter="AVFoundation.dylib"
            showFilter={false}
          />
        ),
      },
    ];
    return <TabsView tabs={tabs} />;
  }
}
