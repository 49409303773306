import React from 'react';
import _ from 'lodash';

import { SarifEvidence } from './SarifEvidence';

export function EvidenceV2({ issue }) {
  return (
    <>
      {!_.isEmpty(issue.evidences) && (
        <SarifEvidence
          evidences={issue.evidences}
          description={issue.description}
        />
      )}
    </>
  );
}
