import React, { Component } from 'react';
import { connect } from 'react-redux';

import { QContainer } from '../Q-Components/QContainer';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QTypography } from '../Q-Components/QTypography';
import i18n from '../../localization/i18n';

import ThreatScoreSection from './ThreatScoreSection';

class ThreatScoreItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      securityCardOpen: true,
      securityToggleButtonClass: 'fa-angle-up',

      privacyCardOpen: true,
      privacyToggleButtonClass: 'fa-angle-up',

      hardwareCardOpen: true,
      hardwareToggleButtonClass: 'fa-angle-up',
    };

    this.toggleCard = this.toggleCard.bind(this);
  }

  // Show or hide the card for a threat score section
  toggleCard(cardType) {
    if (cardType === 'security') {
      this.setState({
        securityCardOpen: !this.state.securityCardOpen,
        securityToggleButtonClass:
          this.state.securityToggleButtonClass === 'fa-angle-up'
            ? 'fa-angle-down'
            : 'fa-angle-up',
      });
    } else if (cardType === 'privacy') {
      this.setState({
        privacyCardOpen: !this.state.privacyCardOpen,
        privacyToggleButtonClass:
          this.state.privacyToggleButtonClass === 'fa-angle-up'
            ? 'fa-angle-down'
            : 'fa-angle-up',
      });
    } else if (cardType === 'hardware') {
      this.setState({
        hardwareCardOpen: !this.state.hardwareCardOpen,
        hardwareToggleButtonClass:
          this.state.hardwareToggleButtonClass === 'fa-angle-up'
            ? 'fa-angle-down'
            : 'fa-angle-up',
      });
    }
  }

  render() {
    const {
      securityCardOpen,
      securityToggleButtonClass,
      hardwareCardOpen,
      hardwareToggleButtonClass,
      privacyCardOpen,
      privacyToggleButtonClass,
    } = this.state;

    const {
      threatItems,
      platform,
      toggleThreatItemEnabled,
      changeThreatItemWeight,
      canCustomizeThreatScore,
    } = this.props;

    return (
      <div>
        <span
          className="d-flex justify-end"
          style={{ fontSize: 12, color: 'grey', padding: '22px' }}
        >
          {' '}
          <QTypography variant="bodyRegular">
            * Represents customized item
          </QTypography>
        </span>
        <QContainer
          fluid
          sx={{ padding: '0px', backgroundColor: '#FAFAFA', minWidth: '100%' }}
        >
          <QRow>
            <QCol xs="6" flex="1" sx={{ padding: '6px' }}>
              <ThreatScoreSection
                cardColor="warning-2"
                blockOpen={securityCardOpen}
                collapseButtonClass={securityToggleButtonClass}
                toggle={() => this.toggleCard('security')}
                headerText={i18n.t('Security')}
                threatItems={threatItems.filter(
                  item =>
                    item.category === 'security' && item.platform === platform,
                )}
                toggleThreatItemEnabled={toggleThreatItemEnabled}
                changeThreatItemWeight={changeThreatItemWeight}
                canCustomizeThreatScore={canCustomizeThreatScore}
              />
            </QCol>
            <QCol flex="1" sx={{ padding: '6px' }}>
              <ThreatScoreSection
                cardColor="pink"
                blockOpen={hardwareCardOpen}
                collapseButtonClass={hardwareToggleButtonClass}
                toggle={() => this.toggleCard('hardware')}
                headerText={i18n.t('Device Access')}
                threatItems={threatItems.filter(
                  item =>
                    item.category === 'hardware' && item.platform === platform,
                )}
                toggleThreatItemEnabled={toggleThreatItemEnabled}
                changeThreatItemWeight={changeThreatItemWeight}
                canCustomizeThreatScore={canCustomizeThreatScore}
              />
            </QCol>
          </QRow>
          <QRow>
            <QCol xs="6" flex="1" sx={{ padding: '6px' }}>
              <ThreatScoreSection
                cardColor="teal"
                blockOpen={privacyCardOpen}
                collapseButtonClass={privacyToggleButtonClass}
                toggle={() => this.toggleCard('privacy')}
                headerText={i18n.t('Privacy & Information Access')}
                threatItems={threatItems.filter(
                  item =>
                    item.category === 'privacy' && item.platform === platform,
                )}
                toggleThreatItemEnabled={toggleThreatItemEnabled}
                changeThreatItemWeight={changeThreatItemWeight}
                canCustomizeThreatScore={canCustomizeThreatScore}
              />
            </QCol>
            <QCol xs="6" flex="1" sx={{ padding: '6px' }} />
          </QRow>
        </QContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(ThreatScoreItems);
