import React from 'react';
import { QActionSelect } from '@kw/quokka-ui';

const QDownloadButton = ({ options, ...props }) => (
  <QActionSelect
    formControlProps={props}
    options={options}
    labelProps={{
      style: {
        transform: 'none',
        overflow: 'visible',
      },
    }}
    selectProps={{
      IconComponent: null,
      variant: 'filled',
      style: {
        transform: 'translate(-13px, -7px) scale(1)',
        background: 'none',
      },
    }}
    labelButtonProps={{
      startIcon: <i className="fa-solid fa-download" />,
      style: {
        padding: '0px',
        minWidth: 'unset',
      },
    }}
  />
);

export { QDownloadButton };
