import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QSwitch as MuiSwitch } from '@kw/quokka-ui';

import Switch from '../Switch';

const LDSwitch = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiSwitch {...props}>{children}</MuiSwitch>
  ) : (
    <Switch {...props}>{children}</Switch>
  );
};
export const QSwitch = withLDConsumer()(LDSwitch);
