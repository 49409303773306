import React, { Component } from 'react';

import DynamicAnalysisDatatable from '../../../Android/DynamicAnalysisDatatable';
import ConstantsMethodsPermissionTable from '../../../Android/ConstantsMethodsPermissionTable';
import PermissionsRequestedTable from '../../../Android/PermissionsRequestedTable';
import TabsView from '../../../../TabsView';
import i18n from '../../../../../localization/i18n';

export default class CanWriteExternalStorage extends Component {
  render() {
    const tabs = [
      {
        title: i18n.t('Permission Requested'),
        tabContent: () => (
          <PermissionsRequestedTable filter="WRITE_EXTERNAL_STORAGE" />
        ),
      },
      {
        title: i18n.t('Constants & Methods of Permission'),
        tabContent: () => (
          <ConstantsMethodsPermissionTable
            filter="WRITE_EXTERNAL_STORAGE"
            showFilter={false}
          />
        ),
      },
      {
        title: i18n.t('Dynamic Analysis'),
        tabContent: () => (
          <DynamicAnalysisDatatable
            onlySelectedCategory="Files Created"
            showFilter={false}
          />
        ),
      },
    ];
    return <TabsView tabs={tabs} />;
  }
}
