import React from 'react';
import { Col, Row, Card, CardHeader, CardBody } from 'reactstrap';

import i18n from '../../../localization/i18n';
import SensitiveDataExposure from '../SensitiveDataExposure';
import AvScanResults from '../AvScanResults';

import FileAccessDatatable from './FileAccessDatatable';
import DynamicallyLoadedLibrariesDatatable from './DynamicallyLoadedLibrariesDatatable';
import HashOperations from './HashOperations';
import CryptoOperations from './CryptoOperations';
import StaticAnalysisFunctions from './StaticAnalysisFunctions';
import KeyChainOperations from './KeyChainOperations';
import CertificatesAndKeys from './CertificatesAndKeys';

const AnalysisDetails = () => (
  <Row>
    <Col xs="6">
      <Card className="card-accent-pink">
        <CardHeader>{i18n.t('File Access')}</CardHeader>
        <CardBody>
          <FileAccessDatatable />
        </CardBody>
      </Card>
      <SensitiveDataExposure platform="ios" />
      <AvScanResults />
    </Col>
    <Col xs="6">
      <Card className="card-accent-danger">
        <CardHeader>
          {i18n.t('Dynamically Loaded Libraries & Frameworks')}
        </CardHeader>
        <CardBody>
          <DynamicallyLoadedLibrariesDatatable />
        </CardBody>
      </Card>
      <StaticAnalysisFunctions platform="ios" />
    </Col>

    <Col xs="12">
      <KeyChainOperations />
      <HashOperations />
      <CryptoOperations />
      <CertificatesAndKeys />
    </Col>
  </Row>
);

export default AnalysisDetails;
