import React, { Component } from 'react';

import DataTable from '../../../DataTable/DataTable';

export default class IosCryptoOps extends Component {
  render() {
    const { data } = this.props;
    console.log('iOS crypto ops data:', data);
    if (!data) {
      return <span>No data</span>;
    }
    return (
      <DataTable
        data={data}
        columns={[
          { label: 'Algorithm', keyName: 'Algorithm' },
          { label: 'Key (base64)', keyName: 'hardcoded_key' },
        ]}
      />
    );
  }
}
