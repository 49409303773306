import React, { useMemo } from 'react';
import owasp from 'owasp-password-strength-test';

// match owasp config in backend
owasp.config({
  allowPassphrases: true,
  maxLength: 128,
  minLength: 10,
  minPhraseLength: 20,
  minOptionalTestsToPass: 3,
});

export const usePassword = (password = '') => {
  const owaspTest = useMemo(() => owasp.test(password), [password]);
  const isPasswordShort = password.length < 10;
  const isPasswordWeak = !owaspTest.strong;
  const strength = isPasswordShort
    ? Math.floor(password.length / 4)
    : isPasswordWeak
    ? 3
    : 4;

  return {
    isPasswordShort,
    isPasswordWeak,
    strength,
    errors: owaspTest.errors,
    password,
    isStrong: !(isPasswordShort || isPasswordWeak),
  };
};

export const PasswordHint = ({
  password,
  isStrong,
  isPasswordWeak,
  errors,
  isPasswordShort,
}) => {
  const message = errors[0]?.replace(/\./g, '') ?? '';
  const appendString =
    message && message === 'The password must be at least 10 characters long'
      ? ''
      : ' or be ≥ 20 chars';
  return (
    password.length > 0 &&
    !isStrong && (
      <div
        role="alert"
        className={`form-hint ${
          isPasswordShort || isPasswordWeak ? 'error' : ''
        }`}
        style={{ display: 'inline' }}
      >
        {message + appendString}
      </div>
    )
  );
};
export const PasswordMeter = ({ password, strength }) => {
  const strengthClass = ['strength-meter', password.length > 0 ? '' : 'd-none']
    .join(' ')
    .trim();

  const wrapper = {
    display: 'flex',
    justifyContent: 'space-between',
  };

  return (
    <div style={wrapper}>
      <div className={strengthClass}>
        <div className="strength-meter-fill" data-strength={strength} />
      </div>
    </div>
  );
};
