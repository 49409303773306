import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QModal } from '../Q-Components/QModal';
import { QModalTitle } from '../Q-Components/QModalTitle';
import { QModalContent } from '../Q-Components/QModalContent';
import { QModalActions } from '../Q-Components/QModalActions';
import { QLabel } from '../Q-Components/QLabel';
import { QInput } from '../Q-Components/QInput';
import { QButton } from '../Q-Components/QButton';
import { QTypography } from '../Q-Components/QTypography';
import { invalidateAnalyzedAppsCache } from '../../store/slices/cache/analyzedAppsCacheSlice';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QCheckbox } from '../Q-Components/QCheckbox';

const Title = ({ toggle, customizedEid }) => (
  <QModalTitle onClose={toggle} closeIcon={<CloseIcon />}>
    <QTypography variant="h3Medium">
      Modify {customizedEid || 'External ID'}
    </QTypography>
  </QModalTitle>
);

const Body = ({
  mastV2,
  customizedEid,
  eid,
  setEid,
  applyAllVersions,
  setApplyAllVersions,
}) => (
  <QModalContent>
    <QCol>
      <QRow sx={{ width: '100%', mt: 1 }}>
        <QLabel htmlFor="eid-input">
          <QTypography variant="bodyRegular">
            New {customizedEid || 'EID'}:
          </QTypography>
        </QLabel>
      </QRow>
      <QRow sx={{ width: '100%', mt: 1 }}>
        <QInput
          id="eid-input"
          value={eid}
          onChange={e => setEid(e.target.value)}
          fullWidth
        />
      </QRow>
      <QRow sx={{ width: '100%', mt: 1 }}>
        <span
          style={
            !mastV2
              ? { position: 'relative', left: 20, top: 10 }
              : { display: 'flex', alignItems: 'center' }
          }
        >
          <QLabel checked>
            <QCheckbox
              size="small"
              id="apply-eid-to-all-version"
              checked={applyAllVersions}
              onChange={() => setApplyAllVersions(!applyAllVersions)}
            />{' '}
            <QTypography variant="bodyRegular">
              Apply EID to all versions?
            </QTypography>
          </QLabel>
        </span>
      </QRow>

      {applyAllVersions && (
        <QRow sx={{ width: '100%', mt: 1 }}>
          <QTypography variant="bodyRegular">
            This change will propagate to all versions of this application in
            your group.
          </QTypography>
        </QRow>
      )}
    </QCol>
  </QModalContent>
);

const Footer = ({ toggle, updateEid, mastV2 }) => (
  <QModalActions>
    <QButton variant="light" color="secondary" onClick={toggle}>
      <QTypography variant="bodyRegular">
        {!mastV2 && <i className="fa-regular fa-xmark mr-1" />}
        Cancel
      </QTypography>
    </QButton>
    <QButton variant="filled" color="primary" onClick={() => updateEid()}>
      <QTypography variant="bodyRegular" color="#FFFFFF">
        {!mastV2 && <i className="fa-regular fa-circle-check mr-1" />}
        Save
      </QTypography>
    </QButton>
  </QModalActions>
);
export const ModifyEidModal = ({
  isOpen,
  toggle,
  initEid,
  getApps,
  analyzedAppId,
}) => {
  const [eid, setEid] = useState(initEid);
  const [applyAllVersions, setApplyAllVersions] = useState(false);
  const { mastV2 } = useFlags();

  const customizedEid = useSelector(
    state => state.emmApp.userConfig.customized_eid,
  );
  const dispatch = useDispatch();
  const updateEid = async () => {
    await axios
      .patch(`analyzed-apps/${analyzedAppId}/eid`, { eid, applyAllVersions })
      .catch(err => {
        console.log('Error saving EID:', err);
      });
    dispatch(invalidateAnalyzedAppsCache());
    toggle();
    getApps();
  };

  return (
    <QModal
      open={isOpen}
      isOpen={isOpen}
      toggle={toggle}
      title={<Title toggle={toggle} customizedEid={customizedEid} />}
      content={
        <Body
          mastV2={mastV2}
          customizedEid={customizedEid}
          eid={eid}
          setEid={setEid}
          applyAllVersions={applyAllVersions}
          setApplyAllVersions={setApplyAllVersions}
        />
      }
      actions={<Footer updateEid={updateEid} toggle={toggle} mastV2={mastV2} />}
    >
      <Title toggle={toggle} customizedEid={customizedEid} />
      <Body
        mastV2={mastV2}
        customizedEid={customizedEid}
        eid={eid}
        setEid={setEid}
        applyAllVersions={applyAllVersions}
        setApplyAllVersions={setApplyAllVersions}
      />
      <Footer updateEid={updateEid} toggle={toggle} mastV2={mastV2} />
    </QModal>
  );
};
