/* istanbul ignore file */
import React, { Component } from 'react';
import {
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
  NavbarToggler,
  NavbarBrand,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import chineseLogo from '../../img/chinese.png';
import i18n from '../../localization/i18n';
import {
  toggleHelpAsideOpen,
  setPasswordChangeOpen,
  setPortalConfigData,
  setSidebarCollapsed,
} from '../../store/slices/emmAppSlice';
import auth from '../../logic/auth';
import { toastrHelper } from '../../logic/toastrHelper';
import { QHeader } from '../Q-Components/QHeader';
import { QButton } from '../Q-Components/QButton';
import { QHeaderToggler } from '../Q-Components/QHeaderToggler';
import { QHeaderTitleContainer } from '../Q-Components/QHeaderTitleContainer';
import { QHeaderToolbar } from '../Q-Components/QHeaderToolbar';
import { QDivider } from '../Q-Components/QDivider';
import { QProfileMenu } from '../Q-Components/QProfileMenu';
import { QProfileHeader } from '../Q-Components/QProfileHeader';
import { QProfileItem } from '../Q-Components/QProfileItem';
import { QLink } from '../Q-Components/QLink';
import util from '../../logic/util';
import { clearAllCache } from '../../store/slices/cache/clearAllCache';

import { PasswordChangeModal } from './PasswordChangeModal';

const options = [
  {
    value: 'us',
    label: 'EN',
  },
  {
    value: 'cn',
    label: 'CN',
  },
  {
    value: 'jp',
    label: 'JP',
  },
  {
    value: 'de',
    label: 'DE',
  },
  {
    value: 'vn',
    label: 'VN',
  },
];

const UserNotificationBadge = ({ userNotifications }) => {
  if (
    userNotifications.filter(notification => notification.status === 'unread')
      .length === 0
  ) {
    return null;
  }

  return (
    <span className="fa-stack">
      <i
        className="fa-solid fa-bell fa-stack-1x text-warning"
        style={{ fontSize: '1.4em' }}
      />
      <i
        className="fa-solid fa-exclamation fa-stack-1x"
        style={{ color: 'white', fontSize: '0.8em' }}
      />
    </span>
  );
};

export class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleLanguage = this.toggleLanguage.bind(this);
    this.state = {
      dropdownOpen: false,
      languageDropdownOpen: false,
      helpOpen: false,
    };

    this.options = {};

    this.togglePasswordChangeModal = this.togglePasswordChangeModal.bind(this);
    this.attemptPasswordChange = this.attemptPasswordChange.bind(this);
    this.asideToggle = this.asideToggle.bind(this);
    this.helpDropdownToggle = this.helpDropdownToggle.bind(this);
    this.sidebarMinimize = this.sidebarMinimize.bind(this);
    this.toggleHelp = this.toggleHelp.bind(this);
    this.checkWidth = this.checkWidth.bind(this);
  }

  checkWidth() {
    const match = window.matchMedia(`(max-width: 992px)`);
    if (match.matches) {
      this.props.setSidebarCollapsed(true);
    }
  }

  componentDidMount() {
    this.checkWidth(this.match);
    window.addEventListener('resize', this.checkWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkWidth);
  }

  async logout() {
    try {
      await axios.post('/emm/logout');
    } catch (err) {
      console.log(err);
    } finally {
      auth.logout();
      this.props.clearAllCache();
      this.props.history.push('/login');
    }
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  toggleHelp() {
    this.setState({
      helpOpen: !this.state.helpOpen,
    });
  }

  toggleLanguage() {
    this.setState({
      languageDropdownOpen: !this.state.languageDropdownOpen,
    });
  }

  /* istanbul ignore next */
  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  /* istanbul ignore next */
  sidebarMinimize(e) {
    e.preventDefault();
    this.props.setSidebarCollapsed(!this.props.sidebarCollapsed);
  }

  /* istanbul ignore next */
  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  /* istanbul ignore next */
  asideToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('aside-menu-hidden');
    this.props.toggleHelpAsideOpen();
  }

  helpDropdownToggle(e) {
    e.preventDefault();
    this.toggleHelp();
  }

  togglePasswordChangeModal() {
    const { passwordChangeOpen, setPasswordChangeOpen } = this.props;
    setPasswordChangeOpen(!passwordChangeOpen);
  }

  // Attempt a password change based on the provided values
  async attemptPasswordChange({ currentPassword, newPassword }) {
    try {
      await axios.put('emm/pass', {
        current: currentPassword,
        new: newPassword,
      });

      toastrHelper.showSuccessToast(
        i18n.t('Changed password successfully'),
        i18n.t('Success'),
        this.props.flags?.mastV2,
      );

      this.togglePasswordChangeModal();
    } catch (err) {
      toastrHelper.showErrorToast(
        `${i18n.t('Error changing password')}: <br> ${err.response.data.msg}`,
        i18n.t('Error'),
        this.props.flags?.mastV2,
      );
    }
  }

  changePage = page => {
    this.props.history.push(page);
    this.toggle();
  };

  // Change language
  changeLang = lang => {
    const { value } = lang;
    i18n.changeLanguage(value);
    this.props.setPortalConfigData({ lang });
    this.toggleLanguage();
  };

  render() {
    const { userNotifications, flags } = this.props;
    const lang = this.props.portalConfigData.lang
      ? this.props.portalConfigData.lang
      : options[0];

    const flagIcon =
      lang.value !== 'cn' ? (
        <span className={`fi fi-${lang.value}`} />
      ) : (
        <img src={chineseLogo} height="14" alt="Chinese" />
      );

    const arrowIcon = <i className="fa-light fa-angle-down" />;

    const openInNewTab = url => {
      if (url) {
        window.open(url, '_blank').focus();
      } else {
        toastrHelper.showErrorToast(
          'Invalid URL',
          i18n.t('Error'),
          this.props.flags?.mastV2,
        );
      }
    };

    return (
      <div>
        <PasswordChangeModal confirm={this.attemptPasswordChange} />

        {flags.mastV2 ? (
          // Sidebar was being covered by header in Safari when left was "auto"
          <QHeader style={{ left: '1px' }}>
            <QHeaderToolbar
              sx={{
                marginLeft: this.props.sidebarCollapsed ? '75px' : '240px',
              }}
            >
              <QHeaderTitleContainer title={this.props.title} />
              <QButton
                variant="ghost"
                startIcon={flagIcon}
                endIcon={arrowIcon}
                id="lang-menu"
                onClick={this.toggleLanguage}
              >
                {lang.label}
              </QButton>
              <QProfileMenu
                anchorEl={document.getElementById('lang-menu')}
                open={this.state.languageDropdownOpen}
                onClose={this.toggleLanguage}
                items={options.map(data => (
                  <QProfileItem
                    key={data.value}
                    onClick={() => this.changeLang(data)}
                    disableRipple
                  >
                    <div style={{ padding: '6px' }}>
                      <span>
                        {data.value !== 'cn' ? (
                          <span className={`fi fi-${data.value}`} />
                        ) : (
                          <img src={chineseLogo} height="14" alt="Chinese" />
                        )}
                      </span>
                      <span style={{ marginLeft: '16px' }}>{data.label} </span>
                    </div>
                  </QProfileItem>
                ))}
              />
              <QButton
                variant="ghost"
                startIcon={<i className="fa-solid fa-circle-user" />}
                endIcon={arrowIcon}
                onClick={this.toggle}
                id="user-menu"
              >
                {this.props.email}
              </QButton>
              <QProfileMenu
                header={<QProfileHeader primary={this.props.email} />}
                items={[
                  <QProfileItem
                    onClick={this.togglePasswordChangeModal}
                    disableRipple
                  >
                    {i18n.t('Change Password')}
                  </QProfileItem>,
                  <QProfileItem
                    onClick={() => this.changePage('/notifications')}
                    disableRipple
                  >
                    {i18n.t('Notifications')}
                  </QProfileItem>,
                  <QProfileItem
                    onClick={() => this.changePage('/csv-downloads')}
                    disableRipple
                  >
                    {i18n.t('CSV Downloads')}
                  </QProfileItem>,
                  <QProfileItem
                    id="settings-navbar-menu-item"
                    onClick={() => this.changePage('/settings/security')}
                    disableRipple
                  >
                    {i18n.t('Settings')}
                  </QProfileItem>,
                  <QDivider />,
                  <QProfileItem
                    id="logout-navbar-menu-item"
                    data-testid="test-logout-navbar-menu-item"
                    onClick={() => this.logout()}
                    disableRipple
                  >
                    {i18n.t('Logout')}
                  </QProfileItem>,
                ]}
                anchorEl={document.getElementById('user-menu')}
                open={this.state.dropdownOpen}
                onClose={this.toggle}
              />
              <QHeaderToggler
                id="help-menu"
                data-testid="help-menu"
                onClick={this.helpDropdownToggle}
              >
                <i className="fa-light fa-circle-question help-menu-toggle" />
              </QHeaderToggler>
              <QProfileMenu
                header={<QProfileHeader primary="Help" />}
                items={[
                  <QProfileItem
                    component={QLink}
                    onClick={() => {
                      openInNewTab(
                        util.documentationUrls[process.env.REACT_APP_APPLIANCE],
                      );
                    }}
                    disableRipple
                    target="_blank"
                  >
                    {i18n.t('Portal Documentation')}
                  </QProfileItem>,
                  (process.env.REACT_APP_APPLIANCE !== 'default' ||
                    process.env.NODE_ENV === 'test') && (
                    <QProfileItem
                      component={QLink}
                      onClick={() => {
                        openInNewTab(
                          'https://kryptowire.zendesk.com/hc/en-us/sections/360008708253-REST-API',
                        );
                      }}
                      disableRipple
                      target="_blank"
                    >
                      {i18n.t('REST API Documentation')}
                    </QProfileItem>
                  ),
                  <QProfileItem
                    component={QLink}
                    onClick={() => {
                      openInNewTab(
                        util.zendeskUrls[process.env.REACT_APP_APPLIANCE],
                      );
                    }}
                    disableRipple
                    target="_blank"
                  >
                    {i18n.t('Support Tickets')}
                  </QProfileItem>,
                  <QProfileItem
                    onClick={e => {
                      this.helpDropdownToggle(e);
                      this.props.restartTour();
                    }}
                    disableRipple
                  >
                    {i18n.t('Q-MAST Walkthrough')}
                  </QProfileItem>,
                ]}
                anchorEl={document.getElementById('help-menu')}
                open={this.state.helpOpen}
                onClose={this.helpDropdownToggle}
              />
            </QHeaderToolbar>
          </QHeader>
        ) : (
          <header className="app-header navbar">
            <NavbarToggler
              className="d-lg-none"
              onClick={this.mobileSidebarToggle}
            >
              &#9776;
            </NavbarToggler>
            <NavbarBrand href="#" />
            <NavbarToggler
              className="d-md-down-none mr-auto"
              onClick={this.sidebarMinimize}
            >
              &#9776;
            </NavbarToggler>

            <Nav className="ml-auto" navbar id="navbar-menu-dropdown">
              {/* Language selection */}
              <NavItem>
                <UncontrolledDropdown>
                  <DropdownToggle caret className="mr-3">
                    {lang.value !== 'cn' ? (
                      <span className={`fi fi-${lang.value}`} />
                    ) : (
                      <img src={chineseLogo} height="14" alt="Chinese" />
                    )}
                    <span style={{ marginLeft: 12 }}>{lang.label}</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    {options.map(data => (
                      <DropdownItem
                        key={data.value}
                        onClick={() => this.changeLang(data)}
                      >
                        {data.value !== 'cn' ? (
                          <span className={`fi fi-${data.value}`} />
                        ) : (
                          <img src={chineseLogo} height="14" alt="Chinese" />
                        )}
                        <span style={{ marginLeft: 12 }}>{data.label}</span>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavItem>

              {/* User menu dropdown */}
              <NavItem>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                  <DropdownToggle className="nav-link dropdown-toggle user-actions">
                    <i className="fa-solid fa-circle-user" />
                    &nbsp;
                    <span className="d-md-down-none">{this.props.email}</span>
                    <UserNotificationBadge
                      userNotifications={userNotifications}
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    right
                    className={this.state.dropdownOpen ? 'show' : ''}
                  >
                    <DropdownItem header tag="div" className="text-center">
                      <strong>{i18n.t('Account')}</strong>
                    </DropdownItem>
                    <DropdownItem onClick={this.togglePasswordChangeModal}>
                      <i className="fa-solid fa-lock" />{' '}
                      {i18n.t('Change Password')}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.props.history.push('/notifications')}
                    >
                      <i className="fa-solid fa-bell" />{' '}
                      {i18n.t('Notifications')}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.props.history.push('/csv-downloads')}
                    >
                      <i className="fa-solid fa-file-csv" />{' '}
                      {i18n.t('CSV Downloads')}
                    </DropdownItem>
                    <DropdownItem header tag="div" className="text-center">
                      <strong>{i18n.t('Settings')}</strong>
                    </DropdownItem>
                    <DropdownItem
                      id="settings-navbar-menu-item"
                      onClick={() =>
                        this.props.history.push('/settings/security')
                      }
                    >
                      <i className="fa-solid fa-wrench" /> {i18n.t('Settings')}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      id="logout-navbar-menu-item"
                      data-testid="test-logout-navbar-menu-item"
                      onClick={() => this.logout()}
                    >
                      <i className="fa-solid fa-lock" /> {i18n.t('Logout')}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              <NavItem>
                <NavbarToggler
                  className="d-md-down-none mr-auto"
                  onClick={this.asideToggle}
                >
                  <i className="fa-light fa-circle-question help-menu-toggle" />
                </NavbarToggler>
              </NavItem>
            </Nav>
          </header>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ emmApp }) => ({
  userNotifications: emmApp.userNotifications,
  passwordChangeOpen: emmApp.passwordChangeOpen,
  portalConfigData: emmApp.portalConfig,
  title: emmApp.pageTitle,
  sidebarCollapsed: emmApp.sidebarCollapsed,
});

const mapDispatchToProps = {
  toggleHelpAsideOpen,
  setPasswordChangeOpen,
  setPortalConfigData,
  setSidebarCollapsed,
  clearAllCache,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withLDConsumer()(Header)),
);
