import React from 'react';
import { Badge } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QChip as MuiChip } from '@kw/quokka-ui';

const LDChip = ({ children, color, variant, ...props }) => {
  const { mastV2 } = useFlags();
  if (mastV2) {
    if (props.label) {
      return (
        <MuiChip variant={variant} label={props.label} {...props}>
          {children}
        </MuiChip>
      );
    }
    return null;
  }
  return (
    <Badge color={color} {...props}>
      {children}
    </Badge>
  );
};

export const QChip = withLDConsumer()(LDChip);
