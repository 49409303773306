import React from 'react';
import { Badge } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QBadge as MuiBadge, QTypography } from '@kw/quokka-ui';

const LDBadge = ({ children, variant, label, color, ...props }) => {
  const { mastV2 } = useFlags();
  if (mastV2) {
    if (label) {
      const textColor = variant === 'yellow' ? 'black' : 'white';
      return (
        <MuiBadge
          variant={variant}
          label={
            <QTypography variant="smallBold" color={textColor}>
              {label}
            </QTypography>
          }
          {...props}
        >
          {children}
        </MuiBadge>
      );
    }
    return null;
  }
  return (
    <Badge color={color} {...props}>
      {label}
    </Badge>
  );
};
export const QBadge = withLDConsumer()(LDBadge);
