import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LaunchIcon from '@mui/icons-material/Launch';

import util from '../logic/util';

import { QCard } from './Q-Components/QCard';
import { QCardHeader } from './Q-Components/QCardHeader';
import { QCardBody } from './Q-Components/QCardBody';
import { QTypography } from './Q-Components/QTypography';
import { QButton } from './Q-Components/QButton';

const DocumentationCard = ({ docType, title, subtext, icon, bg }) => {
  const { mastV2 } = useFlags();
  const link =
    docType === 'portal'
      ? `${util.documentationUrls[process.env.REACT_APP_APPLIANCE]}`
      : 'https://kryptowire.zendesk.com/hc/en-us/sections/360008708253-REST-API';

  return (
    <div>
      {mastV2 && (
        <QCard style={{ width: '372px' }}>
          <QCardHeader
            title={
              <QTypography variant="h4Medium" color="#000000">
                {<i className={icon} style={{ marginRight: '8px' }} />} {title}{' '}
                Documentation
              </QTypography>
            }
          />
          <QCardBody
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '32px',
            }}
          >
            <div
              style={{
                lineHeight: '21px',
                height: '42px',
              }}
            >
              {subtext}
            </div>
            <QButton
              style={{ width: '60%', alignSelf: 'flex-end' }}
              variant="filled"
              href={link}
              endIcon={<LaunchIcon fontSize="small" />}
            >
              View Documentation
            </QButton>
          </QCardBody>
        </QCard>
      )}

      {!mastV2 && (
        <a style={{ textDecoration: 'none' }} href={link}>
          <QCard
            className={`${bg} text-white text-center pointer documentation-card`}
          >
            <QCardBody>
              <h4 className="text-uppercase no-underline">{title}</h4>
              <i className={`${icon} fa-4x`} />
              <p className="no-underline">{subtext}</p>
            </QCardBody>
          </QCard>
        </a>
      )}
    </div>
  );
};

export default DocumentationCard;
