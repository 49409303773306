import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';
import util from '../../../logic/util';

const UrlConstantsDatatable = ({ results, valueFilter }) => {
  const domains = _.get(results, 'analysis.high_level_issues.constants', {});
  const urls = _.get(
    results,
    'analysis.analysis_details.static_analysis.constants.urls',
    [],
  );

  const urlsWithDomains = urls
    .filter(url => !valueFilter || url.includes(valueFilter))
    .map(url => ({ url, domain: util.getUrlHost(url) }));

  // We want to merge urls with category and domain
  const urlsWithCategories = urlsWithDomains.reduce((res, item) => {
    if (!res[item.domain]) {
      res[item.domain] = {
        domain: item.domain,
        url: [item.url],
        category: domains[item.domain]?.Category,
      };
    } else {
      res[item.domain].url.push(item.url);
    }

    return res;
  }, {});

  const parsedUrls = _.values(urlsWithCategories);

  return (
    <DataTable
      data={parsedUrls}
      rowsPerPage={5}
      columns={[
        {
          keyName: 'domain',
          label: i18n.t('Domain'),
          filterable: true,
          sortable: true,
        },
        {
          keyName: 'url',
          label: i18n.t('URL'),
          filterable: true,
          sortable: true,
          component: ({ value }) => (
            <>
              {Array.isArray(value) ? (
                value.map(item => (
                  <p>
                    <a href={value} target="_blank">
                      {item}
                    </a>
                  </p>
                ))
              ) : (
                <>
                  <p>{value}</p>
                </>
              )}
            </>
          ),
        },
        {
          keyName: 'category',
          label: i18n.t('Category'),
          filterable: true,
          sortable: true,
        },
      ]}
    />
  );
};

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(UrlConstantsDatatable);
