import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';

import { QButton } from '../Q-Components/QButton';

export const QSubmitButton = ({ content, startIcon, ...props }) => {
  const { mastV2 } = useFlags();

  return mastV2 ? (
    <QButton startIcon={startIcon} {...props}>
      {content}
    </QButton>
  ) : (
    <QButton {...props}>
      {startIcon}
      &nbsp; {content}
    </QButton>
  );
};
