import React from 'react';
import { Nav } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QTabList as MuiTabList } from '@kw/quokka-ui';

const LDTabList = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  const filteredProps = ['tabs'];

  const muiTabListProps = Object.keys(props).reduce((acc, key) => {
    if (!filteredProps.includes(key)) {
      acc[key] = props[key];
    }
    return acc;
  }, {});

  return mastV2 ? (
    <MuiTabList {...muiTabListProps}>{children}</MuiTabList>
  ) : (
    <Nav {...props}>{children}</Nav>
  );
};
export const QTabList = withLDConsumer()(LDTabList);
