import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QSwitchControlLabel as MuiSwitchControlLabel } from '@kw/quokka-ui';

const LDSwitchControllerLabel = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiSwitchControlLabel {...props}>{children}</MuiSwitchControlLabel>
  ) : (
    <>{children}</>
  );
};
export const QSwitchControlLabel = withLDConsumer()(LDSwitchControllerLabel);
