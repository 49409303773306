import React from 'react';
import { CardImg } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QCardMedia as MuiCardImg } from '@kw/quokka-ui';

const LDCardImg = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiCardImg {...props}>{children}</MuiCardImg>
  ) : (
    <CardImg {...props}>{children}</CardImg>
  );
};
export const QCardImg = withLDConsumer()(LDCardImg);
