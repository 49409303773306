import { store } from '../store';

// set to only 20 mins to avoid stale data across different users
const onemin = 60000;
const CACHE_INTERVAL = 20 * onemin;

export const isValid = ({ time, setInvalid } = {}) => {
  // timed-cache algo reference https://dev.to/nosyminotaur/caching-network-requests-on-the-frontend-dmh
  const isNumber = typeof time === 'number';
  const dateTime = new Date(time);
  const isWithinCacheInterval =
    Date.now() - dateTime.getTime() < CACHE_INTERVAL;
  const isValidDate = dateTime.toString() !== 'Invalid Date';

  return !setInvalid && isNumber && isWithinCacheInterval && isValidDate;
};

export const getCacheValue = (key, prop) => {
  const cacheStore = store.getState().cache[prop][key];
  if (isValid(cacheStore)) {
    return cacheStore.value;
  }
  return undefined;
};
