import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Fade,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Button,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import i18n from '../../localization/i18n';
import util from '../../logic/util';
import { toastrHelper } from '../../logic/toastrHelper';

import { ErrorMessage } from './ErrorMessage';
import { usePassword } from './PasswordMeter';
import { PasswordField } from './PasswordField';

import './NewUserPassword.scss';

export const NewUserPasswordForm = () => {
  const { handleSubmit, register, errors, watch } = useForm();
  const passwordHook = usePassword(watch('password'));
  const location = useLocation();
  const history = useHistory();
  const { mastV2 } = useFlags();

  const onSubmit = handleSubmit(async data => {
    // Get token from URL
    const token = util.getQueryVariable('token', location.search);
    if (!token) {
      toastrHelper.showErrorToast(
        i18n.t('No token provided'),
        i18n.t('Error'),
        mastV2,
      );
      return;
    }

    let response;
    try {
      response = await axios.post(`emm/new-user-password?token=${token}`, {
        newPassword: data.password,
        newPasswordDuplicate: data['confirm-password'],
      });

      if (response.status === 200) {
        toastrHelper.showSuccessToast(
          i18n.t('Successfully saved password'),
          i18n.t('Success'),
          mastV2,
        );
        history.replace('/new-user-password/confirmation?set_password="true"');
      } else {
        toastrHelper.showErrorToast(
          `${i18n.t('Error saving password')}: ${response.data.msg}`,
          'Error',
          mastV2,
        );
      }
    } catch (err) {
      toastrHelper.showErrorToast(
        `${i18n.t('Error saving password')}: ${err.response.data.msg}`,
        i18n.t('Error'),
        mastV2,
      );
    }
  });

  return (
    <Fade mountOnEnter unmountOnExit>
      <Card className="new-user-password__card">
        <CardBody className="new-user-password__card-body">
          <CardTitle>
            <h1 className="new-user-password__title">
              {i18n.t('Setup Password')}
            </h1>
          </CardTitle>
          <Form id="new-user-password-form" className="new-user-password__form">
            {Object.values(errors).map(error => (
              <ErrorMessage key={error.message}>{error.message}</ErrorMessage>
            ))}
            <fieldset className="new-user-password__form__fieldset">
              <PasswordField
                innerRef={register({
                  required: 'Password is required',
                  minLength: 10,
                  validate: () => passwordHook.isStrong,
                })}
                label="New Password"
                id="password"
                passwordHook={passwordHook}
              />
              <FormGroup>
                <Label
                  htmlFor="confirm-password"
                  className="new-user-password__form__label"
                  hidden
                >
                  Confirm Password
                </Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <span className="input-group-text">
                      <i className="fa-solid fa-check" />
                    </span>
                  </InputGroupAddon>
                  <Input
                    id="confirm-password"
                    type="password"
                    name="confirm-password"
                    className="new-user-password__form__input"
                    placeholder="Confirm Password"
                    innerRef={register({
                      required: 'Password confirmation is required',

                      validate: val =>
                        val === watch('password') ||
                        'Password confirmation does not match',
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </fieldset>
          </Form>

          <Button
            onClick={async e => {
              e.preventDefault();
              await onSubmit();
            }}
            size="lg"
            color="primary"
            type="submit"
            className="new-user-password__submit-button"
          >
            <i className="fa-solid fa-lock" />
            &nbsp;{i18n.t('Save Password')}
          </Button>
        </CardBody>
      </Card>
      <div className="new-user-password__attribution">
        <strong>Quokka</strong> -{' '}
        {i18n.t('Mobile Application Security Testing')}
      </div>
    </Fade>
  );
};
