import React from 'react';
import { useSelector } from 'react-redux';
import { CardText } from 'reactstrap';

import DataTable from '../../../DataTable/DataTable';
import DynamicAnalysisDatatable from '../../Android/DynamicAnalysisDatatable';
import StaticAnalysisFunctionsDatatable from '../../iOS/StaticAnalysisFunctionsDatatable';
import MissingCompileProtectionEvidence from '../../SimpleReport/ReportFindingCard/IosEvidence/MissingCompileProtectionEvidence';
import NiapCard from '../../../../views/Reports/NiapDetails/NiapCard';

import { Issues } from './Issues';

export const V7_8 = ({ platform, evidence }) => {
  const niapResults = useSelector(({ appResults }) => appResults.niapResults);

  return (
    <>
      <Issues evidence={evidence} issues={['MISSING_COMPILE_PROTECTION']} />
      <hr />

      <CardText>
        <strong>NIAP Results</strong>
      </CardText>
      <NiapCard
        finding="FPT_AEX_EXT.1.1"
        platform={platform}
        // eslint-disable-next-line camelcase
        niapFindings={niapResults?.niap_sections}
        filter=""
      />

      <NiapCard
        finding="FPT_AEX_EXT.1.2"
        platform={platform}
        // eslint-disable-next-line camelcase
        niapFindings={niapResults?.niap_sections}
        filter=""
      />

      <hr />
      {platform === 'android' ? (
        <>
          <CardText>
            <strong>Stack Protection</strong>
          </CardText>
          {evidence.stackProtection && evidence.stackProtection.length > 0 ? (
            <DataTable
              data={evidence.stackProtection}
              columns={[
                {
                  label: 'Binary',
                  component: ({ rowData }) => <p>{rowData}</p>,
                },
              ]}
            />
          ) : (
            <CardText>
              All libraries packaged with the app have sufficient stack
              protection flags.
            </CardText>
          )}
          <hr />
          <CardText>
            <strong>Memory Access Protection</strong>
          </CardText>
          {evidence.mProtection && evidence.mProtection.length > 0 ? (
            <DataTable
              data={evidence.mProtection}
              columns={[
                {
                  label: 'Binary',
                  component: ({ rowData }) => <p>{rowData}</p>,
                },
              ]}
            />
          ) : (
            <CardText>
              No improper usage of memory access protection (mprotect) for
              memory mapping was found in the application.
            </CardText>
          )}

          <hr />
          <CardText>
            <strong>Dynamic Analysis</strong>
          </CardText>
          <DynamicAnalysisDatatable onlySelectedCategory={['Native Methods']} />
        </>
      ) : (
        <>
          <CardText>
            <strong>Compile Protection</strong>
          </CardText>
          <MissingCompileProtectionEvidence />
          <hr />
          <CardText>
            <strong>Static Analysis</strong>
          </CardText>
          <StaticAnalysisFunctionsDatatable />
        </>
      )}
    </>
  );
};
