import React from 'react';
import { connect } from 'react-redux';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

const FileAccessDatatable = ({ results }) => {
  const fileAccessData =
    results.analysis.analysis_details.dynamic_analysis.file_access_operations;

  return (
    <div>
      <DataTable
        rowsPerPage={5}
        data={fileAccessData}
        columns={[
          {
            keyName: 'mode',
            label: i18n.t('Mode'),
            sortable: true,
            filterable: true,
          },
          {
            keyName: 'filepath',
            label: i18n.t('File'),
            sortable: true,
            filterable: true,
          },
        ]}
      />
    </div>
  );
};

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(FileAccessDatatable);
