import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import DataTable from '../../../../DataTable/DataTable';
import i18n from '../../../../../localization/i18n';

const KeychainSecurityAccessibleEvidence = ({ results }) => {
  const keychainOperations = _.toArray(
    _.get(
      results,
      'analysis.analysis_details.dynamic_analysis.keychain_operations',
      [],
    ),
  );

  const accessibleKeychainSecurity = [];
  for (const operation of keychainOperations) {
    const { kSecAttrAccessible } = operation;
    if (
      kSecAttrAccessible &&
      (kSecAttrAccessible === 'kSecAttrAccessibleAlwaysThisDeviceOnly' ||
        !kSecAttrAccessible.endsWith('ThisDeviceOnly'))
    ) {
      accessibleKeychainSecurity.push({
        account: operation.kSecAttrAccount,
        service: operation.kSecAttrService,
        class: operation.kSecClass,
        method: operation.Method,
        accessibility: kSecAttrAccessible,
      });
    }
  }

  return (
    <div>
      <DataTable
        rowsPerPage={5}
        data={accessibleKeychainSecurity}
        filterable={false}
        columns={[
          {
            keyName: 'account',
            label: i18n.t('Account'),
          },
          {
            keyName: 'service',
            label: i18n.t('Service'),
          },
          {
            keyName: 'class',
            label: i18n.t('Class'),
          },
          {
            keyName: 'method',
            label: i18n.t('Method'),
          },
          {
            keyName: 'accessibility',
            label: i18n.t('Accessibility'),
          },
        ]}
      />
    </div>
  );
};

const mapStateToProps = ({ appResults }) => ({
  results: appResults.results,
});

export default connect(mapStateToProps)(KeychainSecurityAccessibleEvidence);
