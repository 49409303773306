import React, { Component } from 'react';

import TabsView from '../../../../TabsView';
import SystemFrameworksDatatable from '../../../iOS/SystemFrameworksDatatable';
import { Harviewer } from '../../../../Harviewer/Harviewer';
import i18n from '../../../../../localization/i18n';

export default class SocialNetworkEvidence extends Component {
  render() {
    const tabs = [
      {
        title: i18n.t('Frameworks'),
        tabContent: () => <SystemFrameworksDatatable filter="social" />,
      },
      {
        title: i18n.t('Network Traffic'),
        tabContent: () => <Harviewer />,
      },
    ];
    return <TabsView tabs={tabs} />;
  }
}
