import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchKaiGroups } from '../../../store/sliceHelpers/kaiSliceHelper';
import { GroupConfig } from '../../Settings/GroupAdmin/GroupConfig';

export function Groups() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchKaiGroups());
  }, []);

  return <GroupConfig kaiAccess />;
}
