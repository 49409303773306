import React, { Component } from 'react';

import DynamicAnalysisDatatable from '../../../Android/DynamicAnalysisDatatable';
import SmaliAnalysisDatatable from '../../../Android/SmaliAnalysisDatatable';
import TabsView from '../../../../TabsView';

export default class NonPrivateMode extends Component {
  render() {
    const tabs = [
      {
        title: 'Coding Issues',
        tabContent: () => <SmaliAnalysisDatatable filter="non_mode_private" />,
      },
      {
        title: 'Dynamic Analysis',
        tabContent: () => <DynamicAnalysisDatatable showFilter={false} />,
      },
    ];
    return <TabsView tabs={tabs} />;
  }
}
