import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Dropdown } from 'reactstrap';
import { QDropdown as OldMuiDropdown, QDropdownWrapper } from '@kw/quokka-ui';

export { QDropdownWrapper };

const LDDropdown = ({ children, dropdown = false, ...props }) => {
  const { mastV2 } = useFlags();
  if (mastV2) {
    return <OldMuiDropdown {...props}>{children}</OldMuiDropdown>;
  }
  if (dropdown) {
    return <Dropdown {...props}>{children}</Dropdown>;
  }
  return <>{children} </>;
};
export const QDropdown = withLDConsumer()(LDDropdown);
