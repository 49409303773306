import React, { Component } from 'react';
import NProgress from 'nprogress';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QTable } from '../Q-Components/QTable';
import { QTableHead } from '../Q-Components/QTableHead';
import { QTableRow } from '../Q-Components/QTableRow';
import { QTableBody } from '../Q-Components/QTableBody';
import { QTableCell } from '../Q-Components/QTableCell';
import AppSubmitConfirmModal from '../Modals/AppSubmitConfirmModal';
import { clearAppCredentials } from '../../store/slices/appSubmitSlice';
import { store } from '../../store/store';
import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';
import { invalidateAnalyzedAppsCache } from '../../store/slices/cache/analyzedAppsCacheSlice';
import { invalidateAnalyticsCache } from '../../store/slices/cache/analyticsCacheSlice';

import AppStoreApp from './AppStoreApp';
import PlayStoreApp from './PlayStoreApp';
/**
 * Handle a store submission
 * @param {*} pckg
 * @param {*} platform
 */
class StoreResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmModal: false,
      appToSubmit: {},
    };

    this.confirmStoreSubmission = this.confirmStoreSubmission.bind(this);
    this.toggleConfirmModal = this.toggleConfirmModal.bind(this);
    this.submitStoreApp = this.submitStoreApp.bind(this);
  }

  // Toggle confirm modal
  toggleConfirmModal() {
    this.setState({ showConfirmModal: !this.state.showConfirmModal });
  }

  // Handle store app submission confirmation from the user
  confirmStoreSubmission(name, pckg, icon) {
    // Setup the confirmation modal
    this.setState(
      {
        appToSubmit: {
          name,
          package: pckg,
          icon,
        },
      },
      this.toggleConfirmModal,
    );
  }

  // Now actually submit the store app for analysis
  async submitStoreApp(addToWatchlist, externalId, subgroupIds = null) {
    this.toggleConfirmModal();
    toastrHelper.showInfoToast(
      i18n.t('Submitting app for analysis...'),
      i18n.t('Info'),
      this.props.flags?.mastV2,
    );
    NProgress.start();

    const pckg = this.state.appToSubmit.package;

    const platform = this.props.platform.toLowerCase();
    try {
      console.log('Submitting app:', pckg, ' platform:', this.props.platform);
      const { appUsername, appPassword, appCustomCredential } =
        store.getState().appSubmit;

      const response = await axios.post('api/submit', {
        package: pckg,
        platform,
        addToWatchlist,
        customUsername: appUsername,
        customPassword: appPassword,
        customCredential: appCustomCredential,
        externalId,
        subgroupIds,
      });
      console.log(response);
      if (response.status === 200) {
        this.props.invalidateAnalyzedAppsCache();
        this.props.invalidateAnalyticsCache();
        toastrHelper.showSuccessToast(
          `Application ${pckg} submitted. ${response.data.msg}`,
          i18n.t('Success'),
          this.props.flags?.mastV2,
        );
      } else {
        toastrHelper.showErrorToast(
          `${i18n.t('Error submitting the application')}: ${response.data.msg}`,
          i18n.t('Error'),
          this.props.flags?.mastV2,
        );
      }
    } catch (err) {
      console.error(err);
      toastrHelper.showErrorToast(
        `${i18n.t('Error submitting the application')}: ${
          err.response.data.msg
        }`,
        i18n.t('Error'),
        this.props.flags?.mastV2,
      );
    } finally {
      this.props.clearAppCredentials();
      NProgress.done();
    }
  }

  render() {
    const { platform } = this.props;
    return (
      <div>
        {this.state.showConfirmModal && (
          <AppSubmitConfirmModal
            isOpen={this.state.showConfirmModal}
            toggle={this.toggleConfirmModal}
            canWatchlist
            confirm={this.submitStoreApp}
            app={this.state.appToSubmit}
          />
        )}
        <br />
        <QRow>
          <QCol flex="1">
            <QTable>
              <QTableHead>
                <QTableRow>
                  <QTableCell variant="head" width="25%">
                    {i18n.t('App')}
                  </QTableCell>
                  <QTableCell variant="head" width="15%">
                    {i18n.t('Developer')}
                  </QTableCell>
                  {/* Only show the last updated and price columns for iOS apps */}
                  {platform === 'iOS' && (
                    <QTableCell variant="head">
                      {i18n.t('Last Updated')}
                    </QTableCell>
                  )}
                  <QTableCell variant="head" width="45%">
                    {i18n.t('Description')}
                  </QTableCell>
                  <QTableCell variant="head" width="10%">
                    {i18n.t('Actions')}
                  </QTableCell>
                </QTableRow>
              </QTableHead>
              <QTableBody>
                {this.props.results.map((app, index) => {
                  if (platform === 'Android') {
                    return (
                      <PlayStoreApp
                        index={index}
                        key={index}
                        app={app}
                        submitApp={this.confirmStoreSubmission}
                      />
                    );
                  }

                  return (
                    <AppStoreApp
                      index={index}
                      key={index}
                      app={app}
                      submitApp={this.confirmStoreSubmission}
                    />
                  );
                })}
              </QTableBody>
            </QTable>
          </QCol>
        </QRow>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  clearAppCredentials,
  invalidateAnalyzedAppsCache() {
    dispatch(invalidateAnalyzedAppsCache());
  },
  invalidateAnalyticsCache() {
    dispatch(invalidateAnalyticsCache());
  },
});

export default withRouter(
  connect(null, mapDispatchToProps)(withLDConsumer()(StoreResults)),
);
