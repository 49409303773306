import React from 'react';
import { CardTitle } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QProfileHeader as MuiProfileHeader } from '@kw/quokka-ui';

const LDProfileHeader = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiProfileHeader {...props}>{children}</MuiProfileHeader>
  ) : (
    <CardTitle {...props}>{children}</CardTitle>
  );
};
export const QProfileHeader = withLDConsumer()(LDProfileHeader);
