import React, { Component } from 'react';
import { Alert, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

class CryptoOperationsDatatable extends Component {
  constructor() {
    super();

    this.state = {
      showDataDetailModal: false,
      dataDetail: {},
    };
  }

  render() {
    const { results, onlyHardCoded } = this.props;
    const { showDataDetailModal, dataDetail } = this.state;
    let cryptoOperations =
      results.analysis.analysis_details.dynamic_analysis
        .cryptographic_operations;

    if (!Array.isArray(cryptoOperations) || cryptoOperations.length === 0) {
      return <span>{i18n.t('No cryptographic operations')}</span>;
    }

    if (onlyHardCoded) {
      cryptoOperations = cryptoOperations.filter(
        operation => operation.hardcoded_key_found,
      );
    }

    return (
      <div>
        <Modal
          isOpen={showDataDetailModal}
          size="lg"
          toggle={() =>
            this.setState({ showDataDetailModal: !showDataDetailModal })
          }
        >
          <ModalHeader>{i18n.t('Cryptographic Operation Detail')}</ModalHeader>
          <ModalBody>
            <p className="bold">{i18n.t('Full Data Input')}</p>
            <p>{dataDetail.dataIn}</p>
            <hr />
            <p className="bold">{i18n.t('Full Data Output')}</p>
            <p>{dataDetail.dataOut}</p>
          </ModalBody>
        </Modal>

        <div>
          <DataTable
            data={cryptoOperations}
            onRowClick={row =>
              this.setState({ showDataDetailModal: true, dataDetail: row })
            }
            columns={[
              {
                keyName: 'Algorithm',
                label: i18n.t('Algorithm'),
                sortable: true,
                filterable: true,
              },
              {
                keyName: 'Operation',
                label: i18n.t('Operation'),
                sortable: true,
                filterable: true,
              },
              {
                keyName: 'Key',
                label: i18n.t('Cryptographic Key'),
                component: ({ value, rowData }) => {
                  if (rowData.hardcoded_key_found) {
                    return <Alert color="danger">{value}</Alert>;
                  }
                  return <code>{value}</code>;
                },
                sortable: true,
                filterable: true,
              },
              {
                keyName: 'IV',
                label: 'IV',
                sortable: true,
                filterable: true,
              },
              {
                keyName: 'dataIn',
                label: i18n.t('Data In'),
                filterable: true,
                width: '25%',
                component: ({ value }) => {
                  if (value.length > 50) {
                    return (
                      <span>{`${value.substring(0, 50)}...(truncated)`}</span>
                    );
                  }
                  return <span>{value}</span>;
                },
              },
              {
                keyName: 'dataOut',
                label: i18n.t('Data Out'),
                filterable: true,
                width: '25%',
                component: ({ value }) => {
                  if (value.length > 50) {
                    return (
                      <span>{`${value.substring(0, 50)}...(truncated)`}</span>
                    );
                  }
                  return <span>{value}</span>;
                },
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(CryptoOperationsDatatable);
