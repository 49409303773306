import React from 'react';
import { Row, Col, Table } from 'reactstrap';
import { connect } from 'react-redux';

import i18n from '../../../localization/i18n';

const FeaturesAndLibrariesTable = ({
  results,
  librariesOnly = false,
  featuresOnly = false,
}) => {
  const { features_used: features, libraries_used: libraries } =
    results.analysis.analysis_details.metadata_analysis;

  return (
    <Row>
      {!librariesOnly && (
        <Col>
          <Table size="sm">
            <thead>
              <tr>
                <th>
                  <small>{i18n.t('FEATURES')}</small>
                </th>
              </tr>
            </thead>
            <tbody>
              {features.length > 0 ? (
                features.map(feature => (
                  <tr>
                    <td>{feature.name ? feature.name : 'Unnamed'}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>
                    <span>{i18n.t('None declared')}</span>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      )}
      {!featuresOnly && (
        <Col>
          <Table size="sm">
            <thead>
              <tr>
                <th>
                  <small>{i18n.t('LIBRARIES')}</small>
                </th>
              </tr>
            </thead>
            <tbody>
              {libraries.length > 0 ? (
                libraries.map(library => (
                  <tr>
                    <td>{library.name ? library.name : 'Unnamed'}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>
                    <span>{i18n.t('None declared')}</span>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      )}
    </Row>
  );
};

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(FeaturesAndLibrariesTable);
