import React from 'react';
import { Row } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Box } from '@mui/system';

const LDRow = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  const filteredProps = ['className'];

  const muiRowProps = Object.keys(props).reduce((acc, key) => {
    if (!filteredProps.includes(key)) {
      acc[key] = props[key];
    }
    return acc;
  }, {});

  return mastV2 ? (
    <Box display="flex" {...muiRowProps}>
      {children}
    </Box>
  ) : (
    <Row {...props}>{children}</Row>
  );
};
export const QRow = withLDConsumer()(LDRow);
