import React from 'react';
import Select from 'react-select';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QDropdownSelect as MuiSelect } from '@kw/quokka-ui';
import { MultiSelect } from 'react-multi-select-component';

const LDDropdownSelect = ({ children, reactMultiSelect = false, ...props }) => {
  const handleFocus = event => {
    event.target.style.outline = 'none';
  };
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiSelect onFocus={handleFocus} {...props}>
      {children}
    </MuiSelect>
  ) : reactMultiSelect ? (
    <MultiSelect {...props}>{children}</MultiSelect>
  ) : (
    // Additional props to fix the issue of select menu overlapping other selects
    // Reference: https://github.com/JedWatson/react-select/issues/3030#issuecomment-420757206
    <Select
      menuPortalTarget={document.body}
      menuPosition="fixed"
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      {...props}
    >
      {children}
    </Select>
  );
};
export const QDropdownSelect = withLDConsumer()(LDDropdownSelect);
