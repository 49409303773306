import React from 'react';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';

import i18n from '../../../localization/i18n';

const getServiceIcon = service => {
  if (service === 'Google Drive') {
    return 'fa-brands fa-google';
  }
  if (service === 'Dropbox') {
    return 'fa-brands fa-dropbox';
  }
  if (service === 'Windows Live OneDrive') {
    return 'fa-brands fa-windows';
  }
  if (service === 'Box') {
    return 'fa-solid fa-stop';
  }
  return null;
};

const CloudServicesList = ({ results }) => {
  const cloudServices = results.analysis.high_level_issues.cloud_services;
  return cloudServices.length === 0 ? (
    <span>{i18n.t('No cloud services detected')}</span>
  ) : (
    <Table>
      <tbody>
        {cloudServices.map((service, index) => {
          const iconClass = getServiceIcon(service);
          return (
            <tr key={index}>
              <td>
                {iconClass && <i className={iconClass} />} {service}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(CloudServicesList);
