import React from 'react';
import { ModalHeader } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QModalTitle as MuiModalTitle } from '@kw/quokka-ui';

const LDModalTitle = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiModalTitle {...props}>{children}</MuiModalTitle>
  ) : (
    <ModalHeader {...props}>{children}</ModalHeader>
  );
};
export const QModalTitle = withLDConsumer()(LDModalTitle);
