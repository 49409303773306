import React, { useState } from 'react';

import { QModal } from './Q-Components/QModal';
import { QModalActions } from './Q-Components/QModalActions';
import { QModalContent } from './Q-Components/QModalContent';
import { QModalTitle } from './Q-Components/QModalTitle';
import { QButton } from './Q-Components/QButton';
import { QTypography } from './Q-Components/QTypography';

const Title = () => (
  <QModalTitle>
    <QTypography variant="h3Medium">Disclaimer</QTypography>
  </QModalTitle>
);

const Body = () => (
  <QModalContent>
    <p>By utilizing this system you acknowledge that:</p>
    <ol>
      <li>Users are accessing a U.S. Government information system;</li>
      <li>
        Information system usage may be monitored, recorded, and subject to
        audit;
      </li>
      <li>
        Unauthorized use of the information system is prohibited and subject to
        criminal and civil penalties; and Use of the information system
        indicates consent to monitoring and recording;
      </li>
    </ol>
  </QModalContent>
);

const Actions = ({ handleAcceptDisclaimer }) => (
  <QModalActions>
    <QButton
      color="primary"
      variant="filled"
      onClick={() => handleAcceptDisclaimer()}
    >
      Accept
    </QButton>
  </QModalActions>
);

export function CisaDisclaimer() {
  const [isOpen, setIsOpen] = useState(true);

  const handleAcceptDisclaimer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <QModal
      isOpen={isOpen}
      open={isOpen}
      title={<Title />}
      content={<Body />}
      actions={<Actions handleAcceptDisclaimer={handleAcceptDisclaimer} />}
    >
      <Title />
      <Body />
      <Actions handleAcceptDisclaimer={handleAcceptDisclaimer} />
    </QModal>
  );
}
