import React from 'react';
import {
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import _ from 'lodash';

import { byVersion } from './SummarizedAppInfo';

const setKeys =
  (x, y, label) =>
  ({ [x]: name, [y]: value }) => ({
    name,
    [label]: value,
  });

export const SparklineData = ({ x, y, label }, values, color) => ({
  values: values.map(setKeys(x, y, label)),
  color,
  label,
});

/**
 * Analyzed App component
 * @param {*} props
 */
export const ThreatScoreSparkline = ({ data }) => {
  let graphData = data.values.sort((a, b) => byVersion(a.name, b.name));

  graphData = _.chain(graphData)
    .groupBy('name')
    .mapValues(v => Object.assign(...v))
    .values()
    .slice(-10)
    .value();

  console.log('Graph data:', graphData);

  const mostRecentRiskScore = graphData[graphData.length - 1]['Risk Score'];
  const strokeColor =
    mostRecentRiskScore > 75.0
      ? '#dc3545'
      : mostRecentRiskScore > 35.0
      ? '#ffc107'
      : '#17a2b8';

  return (
    <ResponsiveContainer
      className="ml-auto mr-auto"
      aspect={2}
      maxHeight={70}
      width={graphData.length <= 3 ? 70 : '100%'}
    >
      <LineChart
        data={graphData}
        margin={{ top: 5, left: 6, right: 6, bottom: 5 }}
      >
        <Tooltip
          labelFormatter={label => `v${graphData[label].name}`}
          separator=" : "
          allowEscapeViewBox={{ x: true, y: true }}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <Line
          key={data.label}
          dot={false}
          dataKey={data.label}
          stroke={strokeColor}
          strokeWidth={2}
          connectNulls
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
