const auth = {
  user: {
    authenticated: false,
  },

  loggedIn() {
    if (localStorage.getItem('token')) {
      return true;
    }
    return false;
  },

  logout() {
    console.log('Logging user out!');
    localStorage.removeItem('token');
    auth.user.authenticated = false;
  },

  getToken() {
    return localStorage.getItem('token');
  },

  redirectToLogin(history) {
    const { origin, hash, href } = window.location;

    if (`${origin}/#/` === href) {
      history.push('/login');
    } else if (!href.includes('login')) {
      const path = hash ? hash.replace('#/', '') : '';
      history.push(`/login?redirect-path=${path}`);
    }
  },
};

export default auth;
