import get from 'lodash/get';

export default {
  getAppName: results => {
    if (get(results, 'market.app_name')) {
      return get(results, 'market.app_name');
    }
    if (get(results, 'analysis.analysis_details.metadata_analysis.appname')) {
      return get(
        results,
        'analysis.analysis_details.metadata_analysis.appname',
      );
    }
    let appName = 'App Name';

    results.analysis.high_level_issues.issues.forEach(issue => {
      if (issue.name === 'app_name') {
        appName = issue.value;
      }
    });

    return appName;
  },

  getAppPackageName: results => {
    let pckg = '';
    results.analysis.high_level_issues.issues.forEach(issue => {
      if (issue.name === 'package_name') pckg = issue.value;
    });

    return pckg;
  },

  getVersionName: results => {
    let version = '';
    if (get(results, 'market.pkg_ver')) {
      return get(results, 'market.pkg_ver');
    }

    results.analysis.high_level_issues.issues.forEach(issue => {
      if (issue.name === 'app_version') version = issue.value;
    });

    return version;
  },

  getMinSdk: results =>
    results.analysis.analysis_details.metadata_analysis.MinimumOSVersion,

  getPlatformVersion: results =>
    results.analysis.analysis_details.metadata_analysis.PlatformVersion,

  getHashes: results => {
    const hashes = {};
    results.analysis.high_level_issues.issues.forEach(issue => {
      if (issue.name === 'hash_SHA1') hashes.SHA1 = issue.value;
      else if (issue.name === 'hash_MD5') hashes.MD5 = issue.value;
    });

    return hashes;
  },

  getAppOrigin: results =>
    results.analysis.analysis_details.metadata_analysis.app_origin,

  getCompiler: results =>
    results.analysis.analysis_details.metadata_analysis.Compiler,

  getLastUpdated: results => {
    if (
      get(results, 'market.version_history') &&
      results.market.version_history.length > 0
    ) {
      return results.market.version_history[0].releaseDate;
    }

    return null;
  },

  getSubmissionTag: results => results.submissionTag,

  getSubmittedAt: results => results.submitted_at,

  getTestScript: results => results.appiumScript,
};
