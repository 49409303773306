import React from 'react';

import { IosSBOMTable } from './SBOM/IosSBOMTable';
import { AndroidSBOMTable } from './SBOM/AndroidSBOMTable';

export const SbomDetails = ({ platform, farmAppId }) =>
  platform === 'android' ? (
    <AndroidSBOMTable farmAppId={farmAppId} />
  ) : (
    <IosSBOMTable />
  );
