import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appUsername: '',
  appPassword: '',
  appCustomCredential: '',
};

export { initialState };

export const appSubmitSlice = createSlice({
  name: 'appSubmit',
  initialState,
  reducers: {
    setAppUsername: (state, action) => ({
      ...state,
      appUsername: action.payload,
    }),
    setAppPassword: (state, action) => ({
      ...state,
      appPassword: action.payload,
    }),
    setAppCustomCredential: (state, action) => ({
      ...state,
      appCustomCredential: action.payload,
    }),
    clearAppCredentials: state => ({
      ...state,
      appUsername: '',
      appPassword: '',
      appCustomCredential: '',
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  setAppUsername,
  setAppPassword,
  setAppCustomCredential,
  clearAppCredentials,
} = appSubmitSlice.actions;

export default appSubmitSlice.reducer;
