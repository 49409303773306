import React from 'react';
import { Nav } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QSidebarList as MuiSidebarList } from '@kw/quokka-ui';

const LDSidebarList = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiSidebarList {...props}>{children}</MuiSidebarList>
  ) : (
    <Nav {...props}>{children}</Nav>
  );
};
export const QSidebarList = withLDConsumer()(LDSidebarList);
