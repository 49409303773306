import React from 'react';

import i18n from '../../../../localization/i18n';
import { Harviewer } from '../../../Harviewer/Harviewer';
import HighRiskCountriesContactedDatatable from '../../HighRiskCountriesContactedDatatable';

// Shows both static and dynamic evidences
export const CountriesConnectionsEvidence = ({
  platform,
  foreignCountries = false,
}) => (
  <div style={{ padding: '15px' }}>
    <p>
      <strong>{i18n.t('Network Connections')}</strong>
      <br />
      <Harviewer />
    </p>
    <hr />
    <p>
      <strong>
        {i18n.t(
          foreignCountries
            ? 'Foreign Countries Contacted'
            : 'High Risk Countries Contacted',
        )}
      </strong>
      <br />
      <HighRiskCountriesContactedDatatable
        platform={platform}
        foreignCountries={foreignCountries}
      />
    </p>
  </div>
);
