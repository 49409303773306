import React from 'react';
import moment from 'moment';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import Grid from '@mui/material/Grid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

const QDateTime = ({ dateTime }) => {
  const { mastV2 } = useFlags();

  return mastV2 ? (
    <Grid container>
      <Grid container>
        <Grid item>
          <AccessTimeIcon
            color="primary"
            sx={{ width: '12px', height: '12px' }}
          />
          {` ${moment(dateTime).format('LTS')}`}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <CalendarTodayOutlinedIcon
            color="primary"
            sx={{ width: '12px', height: '12px' }}
          />
          {` ${moment(dateTime).format('L')}`}
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <>
      <div>
        <i className="fa-solid fa-clock" />
        {` ${moment(dateTime).format('LTS')}`}
      </div>
      <div>
        <i className="fa-solid fa-calendar" />
        {` ${moment(dateTime).format('L')}`}
      </div>
    </>
  );
};

export const DateTime = withLDConsumer()(QDateTime);
