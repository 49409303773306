import React from 'react';
import { Badge } from 'reactstrap';

export function RiskBadge({ risk }) {
  if (risk === 'high') {
    return (
      <Badge
        style={{ fontSize: 10 }}
        color="danger"
        className="threat-score-badge"
      >
        HIGH
      </Badge>
    );
  }
  if (risk === 'moderate') {
    return (
      <Badge
        style={{ fontSize: 10 }}
        color="warning"
        className="threat-score-badge"
      >
        MEDIUM
      </Badge>
    );
  }
  return (
    <Badge style={{ fontSize: 10 }} color="info" className="threat-score-badge">
      LOW
    </Badge>
  );
}
