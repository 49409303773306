import React, { Component } from 'react';

import DynamicAnalysisDatatable from '../../../Android/DynamicAnalysisDatatable';
import ConstantsMethodsPermissionTable from '../../../Android/ConstantsMethodsPermissionTable';
import PermissionsRequestedTable from '../../../Android/PermissionsRequestedTable';
import TabsView from '../../../../TabsView';
import i18n from '../../../../../localization/i18n';

export default class RecordsAudio extends Component {
  render() {
    const { staticAnalysis } = this.props;

    const tabs = [
      {
        title: i18n.t('Permission Requested'),
        tabContent: () => <PermissionsRequestedTable filter="RECORD_AUDIO" />,
      },
      {
        title: i18n.t('Constants & Methods of Permission'),
        tabContent: () => (
          <ConstantsMethodsPermissionTable
            showFilter={false}
            filter="RECORD_AUDIO"
          />
        ),
      },
      {
        title: i18n.t('Dynamic Analysis'),
        tabContent: () => (
          <DynamicAnalysisDatatable
            filter="audio"
            showFilter={false}
            onlySelectedCategory="Media Events"
          />
        ),
      },
    ];
    return <TabsView tabs={staticAnalysis ? [tabs[0], tabs[1]] : tabs} />;
  }
}
