import React, { useState } from 'react';
import axios from 'axios';
import NProgress from 'nprogress';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QSwitchControlLabel } from '../Q-Components/QSwitchControlLabel';
import { QSwitch } from '../Q-Components/QSwitch';
import { QTypography } from '../Q-Components/QTypography';
import HelpButton from '../HelpButton';
import ConfirmModal from '../Modals/ConfirmationModal';
import PlatformSelect from '../PlatformSelect';
import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';
import { invalidateAnalyzedAppsCache } from '../../store/slices/cache/analyzedAppsCacheSlice';
import { invalidateAnalyticsCache } from '../../store/slices/cache/analyticsCacheSlice';

import { QSubmitButton } from './QSubmitButton';

const BulkSubmit = () => {
  const dispatch = useDispatch();
  const { mastV2 } = useFlags();

  const userAccess = useSelector(state => state.emmApp.userAccess);
  const userConfig = useSelector(state => state.emmApp.userConfig);

  let defaultPlatform = null;

  if (userAccess.android_analysis) {
    defaultPlatform = { value: 'android', label: 'Android' };
  } else if (userAccess.ios_analysis) {
    defaultPlatform = { value: 'ios', label: 'iOS' };
  }

  const [appList, setAppList] = useState('');
  const [submitButtonClass, setSubmitButtonClass] = useState('fa-upload');
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [addToWatchlist, setAddToWatchList] = useState(
    userConfig.submit_default_watchlist || false,
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState(defaultPlatform);

  const toggleAddWatchlist = () => {
    setAddToWatchList(!addToWatchlist);
  };

  const toggleConfirmModal = () => {
    setShowConfirmModal(!showConfirmModal);
  };

  const confirmSubmit = () => {
    const sanitizedAppList = appList
      .split('\n')
      .filter(entry => !entry.match(/((\r\n|\n|\r)$)|(^(\r\n|\n|\r))|^\s*$/gm));

    // Check to see if the appList is empty
    if (sanitizedAppList.length === 0) {
      toastrHelper.showErrorToast(
        i18n.t('Please enter at least one application to submit'),
        i18n.t('Error'),
        mastV2,
      );
      return;
    }

    // Check to see if the app list is larger than the max we allow
    if (sanitizedAppList.length > 50) {
      toastrHelper.showErrorToast(
        i18n.t(
          'Bulk application submit has a limit of 50 applications at a time',
        ),
        i18n.t('Error'),
        mastV2,
      );
      return;
    }
    toggleConfirmModal();
  };

  const changePlatform = e => {
    if (e.target.value === 'android') {
      setSelectedPlatform({ value: 'android', label: 'Android' });
    } else if (e.target.value === 'ios') {
      setSelectedPlatform({ value: 'ios', label: 'iOS' });
    }
  };

  const bulkSubmit = async () => {
    toggleConfirmModal();
    NProgress.start();

    setSubmitButtonClass('fa-spin fa-spinner');
    setSubmitButtonDisabled(true);

    toastrHelper.showInfoToast(
      i18n.t('Submitting bulk list of applications...'),
      i18n.t('Info'),
      mastV2,
    );
    try {
      const response = await axios.post('api/bulk-submit', {
        appList,
        platform: selectedPlatform.value.toLowerCase(),
        addToWatchlist,
      });
      if (response.status !== 200) {
        toastrHelper.showErrorToast(
          i18n.t('Error submitting bulk application list'),
          i18n.t('Error'),
          mastV2,
        );
      } else {
        dispatch(invalidateAnalyzedAppsCache());
        dispatch(invalidateAnalyticsCache());
        toastrHelper.showSuccessToast(
          i18n.t('Successfully submitted app list for analysis'),
          i18n.t('Success'),
          mastV2,
        );
      }
      setAppList('');
      console.log(response);
    } catch (err) {
      toastrHelper.showErrorToast(
        i18n.t('Error submitting application list'),
        i18n.t('Error'),
        mastV2,
      );
      console.error(err);
    }

    setSubmitButtonClass('fa-upload');
    setSubmitButtonDisabled(false);
    NProgress.done();
  };

  return (
    <div>
      <ConfirmModal
        isOpen={showConfirmModal}
        toggle={toggleConfirmModal}
        confirmHeader={i18n.t('Bulk App Submit')}
        confirmBody={i18n.t(
          'Would you like to submit this list of applications to be analyzed?',
        )}
        confirm={bulkSubmit}
      />

      <QCard className="card-accent-warning-2">
        <QCardHeader
          title={
            <QTypography variant="h4Bold" color="#000000">
              {i18n.t('Bulk Submit')}
            </QTypography>
          }
          action={<HelpButton helpItem="bulkStoreSubmit" />}
        >
          <strong>{i18n.t('Bulk Submit')}</strong>
          &nbsp;
          <HelpButton helpItem="bulkStoreSubmit" />
        </QCardHeader>
        <QCardBody>
          <QRow>
            <QCol flex="1">
              <PlatformSelect
                mastV2={mastV2}
                withIconV2={mastV2}
                handlePlatformChange={e =>
                  mastV2 ? changePlatform(e) : setSelectedPlatform(e)
                }
                platformFilter={selectedPlatform}
                optionFilter={option =>
                  option.value !== 'both' &&
                  ((userAccess.android_analysis &&
                    option.value === 'android') ||
                    (userAccess.ios_analysis && option.value === 'ios'))
                }
                hideLabel
              />
            </QCol>
          </QRow>
          <textarea
            className="form-control mt-2"
            onChange={e => setAppList(e.target.value)}
            value={appList}
            rows="10"
          />
          <QRow columnGap="8px">
            <QCol>
              <QSubmitButton
                variant="filled"
                color="primary"
                id="btn-bulk-submit"
                disabled={submitButtonDisabled}
                onClick={confirmSubmit}
                content={i18n.t('Bulk Submit (limit 50)')}
                startIcon={<i className={`fa-solid ${submitButtonClass}`} />}
              />
            </QCol>
            <QCol>
              <QSwitchControlLabel
                style={{
                  marginTop: '13px',
                  padding: '0px 16px 0px 16px',
                }}
                label={
                  <QTypography variant="h6Bold" color="#000000">
                    Add to watchlist
                  </QTypography>
                }
                control={
                  <QSwitch
                    size="small"
                    checked={addToWatchlist}
                    onChange={() => toggleAddWatchlist()}
                  />
                }
              >
                <QSwitch
                  style={{ marginTop: 16 }}
                  onChange={toggleAddWatchlist}
                  value={addToWatchlist}
                  label={i18n.t('Add to watchlist')}
                />
              </QSwitchControlLabel>
            </QCol>
          </QRow>
        </QCardBody>
      </QCard>
    </div>
  );
};

export default BulkSubmit;
