import React, { useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import HelpButton from '../HelpButton';
import { sortIssuesByRisk } from '../../logic/generalReportUtil';
import i18n from '../../localization/i18n';

import { AppIssueStatusNote } from './Findings/AppIssueStatusNote';

function Findings({ findings, platform, showOwaspIcon = true, category }) {
  const [open, setOpen] = useState(false);
  const [appIssue, setAppIssue] = useState(null);
  const [appIssueName, setAppIssueName] = useState(null);

  function toggle() {
    setOpen(!open);
  }

  function modifyAppIssue(appIssue, issueName) {
    setAppIssue(appIssue);
    setAppIssueName(issueName);

    toggle();
  }

  const foundIssues = findings
    .filter(
      finding => finding.found && finding.app_issue.review_status !== 'passed',
    )
    .filter(issue => issue.category === category)
    .sort(sortIssuesByRisk);

  const passedIssues = findings
    .filter(finding => !finding.found)
    .filter(issue => issue.category === category)
    .sort(sortIssuesByRisk);

  // These issues the user has manually specified as they've passed
  const userPassedIssues = findings
    .filter(
      finding => finding.found && finding.app_issue.review_status === 'passed',
    )
    .filter(issue => issue.category === category)
    .sort(sortIssuesByRisk);

  return (
    <div>
      <AppIssueStatusNote
        appIssueName={appIssueName}
        appIssue={appIssue}
        open={open}
        toggle={toggle}
      />

      {foundIssues.map(finding => (
        <button
          key={JSON.stringify(finding.name)}
          type="button"
          className="button-like-div"
          onClick={() =>
            modifyAppIssue(finding.app_issue, finding.positive_finding_text)
          }
          aria-pressed="false"
        >
          <i
            className={`fa-solid fa-exclamation-triangle ${
              finding.risk === 'critical'
                ? 'critical-icon'
                : finding.risk === 'high'
                ? 'danger-icon'
                : finding.risk === 'medium'
                ? 'warning-icon'
                : 'info-icon'
            }`}
          />{' '}
          <span>{i18n.t(finding.positive_finding_text)}</span>
          {finding.app_issue && finding.app_issue.note && (
            <div className="pl-4">
              <i>Note: {finding.app_issue.note}</i>
            </div>
          )}
          <HelpButton helpItem={`${platform}-${finding.name[0]}`} />
          {finding.owasp.length > 0 && showOwaspIcon && (
            <span>
              <i
                className="fa-light fa-circle-info"
                id={`owasp-icon-${finding.name[0]}`}
              />
              <UncontrolledTooltip
                target={`owasp-icon-${finding.name[0]}`}
                className="animated fadeIn"
                placement="top"
              >
                OWASP {finding.owasp.map(reg => reg.item).join(', ')}
              </UncontrolledTooltip>
            </span>
          )}
        </button>
      ))}
      <hr />

      {passedIssues.map(finding => (
        <div key={JSON.stringify(finding.name)}>
          <i className="fa-solid fa-circle-check success-icon" />{' '}
          <span>{i18n.t(finding.negative_finding_text)}</span>{' '}
          <HelpButton helpItem={`${platform}-${finding.name[0]}`} />
          {finding.owasp.length > 0 && showOwaspIcon && (
            <span>
              <i
                className="fa-light fa-circle-info"
                id={`owasp-icon-${finding.name[0]}`}
              />
              <UncontrolledTooltip
                target={`owasp-icon-${finding.name[0]}`}
                placement="top"
                className="animated fadeIn"
              >
                OWASP {finding.owasp.map(reg => reg.item).join(', ')}{' '}
              </UncontrolledTooltip>
            </span>
          )}
        </div>
      ))}

      {userPassedIssues.length > 0 && (
        <>
          <hr />
          {userPassedIssues.map(finding => (
            <button
              key={JSON.stringify(finding.name)}
              type="button"
              className="button-like-div"
              onClick={() => {
                // Right now there's an issue with the inverse findings (encryption findings)
                // where if they're "detected" (a lack of presence of them) they won't have
                // an app issue to modify
                if (!finding.app_issue) {
                  return;
                }
                modifyAppIssue(
                  finding.app_issue,
                  finding.positive_finding_text,
                );
              }}
              aria-pressed="false"
            >
              <i
                className="fa-solid fa-circle-check primary-icon"
                title="User passed"
              />{' '}
              <span>{finding.positive_finding_text}</span>{' '}
              <HelpButton helpItem={`${platform}-${finding.name[0]}`} />
              {finding.owasp.length > 0 && showOwaspIcon && (
                <span>
                  <i
                    className="fa-light fa-circle-info"
                    id={`owasp-icon-${finding.name[0]}`}
                  />
                  <UncontrolledTooltip
                    target={`owasp-icon-${finding.name[0]}`}
                    placement="top"
                    className="animated fadeIn"
                  >
                    OWASP {finding.owasp.map(reg => reg.item).join(', ')}{' '}
                  </UncontrolledTooltip>
                </span>
              )}
              {finding.app_issue.note && (
                <div className="pl-4">
                  <i>Note: {finding.app_issue.note}</i>
                </div>
              )}
            </button>
          ))}
        </>
      )}
    </div>
  );
}

export default Findings;
