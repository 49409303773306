import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Collapse } from 'reactstrap';

import AppInfoTable from '../AppInfoTable';
import SystemLibrariesDatatable from '../SystemLibrariesDatatable';
import SystemFrameworksDatatable from '../SystemFrameworksDatatable';
import DynamicallyLoadedLibrariesDatatable from '../DynamicallyLoadedLibrariesDatatable';
import TabsView from '../../../TabsView';

export default class M1ios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      collapseButton: 'up',
    };

    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse() {
    this.setState({
      isOpen: !this.state.isOpen,
      collapseButton: this.state.collapseButton === 'up' ? 'down' : 'up',
    });
  }

  render() {
    const { owasp, owaspIssues } = this.props;
    const { isOpen, collapseButton } = this.state;

    const tabs = [
      {
        title: 'Application Information',
        tabContent: () => (
          <Row>
            <Col className="card-accent-warning-2 app-info-section">
              <AppInfoTable />
            </Col>
          </Row>
        ),
      },
      {
        title: 'System Libraries',
        tabContent: () => (
          <Row>
            <Col>
              <SystemLibrariesDatatable />
            </Col>
          </Row>
        ),
      },
      {
        title: 'System Frameworks',
        tabContent: () => (
          <Row>
            <Col>
              <SystemFrameworksDatatable />
            </Col>
          </Row>
        ),
      },
      {
        title: 'Dynamically Loaded Libraries',
        tabContent: () => (
          <Row>
            <Col>
              <DynamicallyLoadedLibrariesDatatable />
            </Col>
          </Row>
        ),
      },
    ];

    return (
      <div>
        <Card
          className={
            owaspIssues.length === 0
              ? 'card-accent-success'
              : 'card-accent-danger'
          }
        >
          <CardHeader onClick={this.toggleCollapse} className="pointer">
            <Row>
              <Col className="text-left">{owasp}</Col>
              <Col className="text-right">
                <i className={`fa-solid fa-lg fa-angle-${collapseButton}`} />
              </Col>
            </Row>
          </CardHeader>
          <Collapse isOpen={isOpen}>
            <CardBody>
              <Row>
                <Col xs="6">
                  <div>
                    This category covers misuse of a platform feature or failure
                    to use platform security controls. It might include Android
                    intents, platform permissions, misuse of TouchID, the
                    Keychain, or some other security control that is part of the
                    mobile operating system.
                  </div>
                  <div>
                    <a
                      href="https://owasp.org/www-project-mobile-top-10/2016-risks/m1-improper-platform-usage"
                      style={{ display: 'table-cell' }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      <em>More Info</em>{' '}
                    </a>
                  </div>
                </Col>
                {owaspIssues.length !== 0 ? (
                  <Col>
                    <strong>High level issues found: </strong>
                    <div>
                      {owaspIssues.map(issue => (
                        <div>
                          <i className="fa-solid fa-exclamation-triangle warning-icon" />{' '}
                          {issue}
                        </div>
                      ))}
                    </div>
                  </Col>
                ) : null}
              </Row>
              <hr />
              <strong>Additional Analysis Information:</strong>
              <TabsView tabs={tabs} />
            </CardBody>
          </Collapse>
        </Card>
      </div>
    );
  }
}
