import React from 'react';
import { CardText } from 'reactstrap';

import { Cookies } from '../../iOS/Cookies';
import DynamicAnalysisDatatable from '../../Android/DynamicAnalysisDatatable';
import { StaticAnalysisDatatable } from '../../Android/StaticAnalysisDatatable';
import SmaliAnalysisDatatable from '../../Android/SmaliAnalysisDatatable';
import FilesWrittenDatatable from '../../iOS/FilesWrittenDatatable';
import CryptoOperationsDatatable from '../../iOS/CryptoOperationsDatatable';
import { FunctionsDetectedDataTable } from '../../iOS/FunctionsDetectedDatatable';

import { Issues } from './Issues';

export const V2_14 = ({ platform, evidence }) => (
  <>
    <Issues
      evidence={evidence}
      issues={[
        'HARD_CODED_CREDENTIALS',
        'NO_DATA_AT_REST_ENCRYPTION',
        'USES_HARD_CODED_CREDENTIALS',
        'HARD_CODED_KEY',
        'HARD_CODED_IV',
      ]}
    />
    <hr />
    {platform === 'android' ? (
      <>
        <CardText>
          <strong>Hard Coded Credentials</strong>
        </CardText>
        <SmaliAnalysisDatatable filter="crypto_key" />
        <hr />
        <CardText>
          <strong>Dynamic Analysis</strong>
        </CardText>
        <DynamicAnalysisDatatable
          onlySelectedCategory={['Files Created', 'File Io', 'Files Accessed']}
          methodFilter={['OutputStream']}
        />
        <br />
        <DynamicAnalysisDatatable
          onlySelectedCategory={['Cryptography', 'Crypto', 'Crypto Events']}
        />
        <hr />
        <CardText>
          <strong>Static Analysis</strong>
        </CardText>
        <StaticAnalysisDatatable categoryFilter="methods" keyFilter="all" />
      </>
    ) : (
      <>
        <CardText>
          <strong>Cookies</strong>
        </CardText>
        <Cookies cookies={evidence.cookies} />
        <hr />
        <CardText>
          <strong>Files Written</strong>
        </CardText>
        <FilesWrittenDatatable />
        <hr />
        <CardText>
          <strong>Cryptographic Operations</strong>
        </CardText>
        <CryptoOperationsDatatable onlyHardCoded />
        <hr />
        <CardText>
          <strong>Functions Detected</strong>
        </CardText>
        <FunctionsDetectedDataTable />
      </>
    )}
  </>
);
