import React, { useState } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';

import NiapNav from '../../views/Reports/NiapDetails/NiapNav';
import NiapCard from '../../views/Reports/NiapDetails/NiapCard';

export default function NiapDetails(props) {
  const { niapResults, platform } = props;
  const [filter, setFilter] = useState('');

  const niapFindings = niapResults ? niapResults.niap_sections : null;

  const niapFindingKeys = [];
  const niapPass = [];

  const niapFail = [];
  const niapReview = [];

  const niapIssues = useSelector(state => state.appResults.niapAppIssues);

  if (!niapFindings) {
    return null;
  }

  // Create deep copy of niapFindings so we can mutate without affecting redux state of niapResults
  const newNiapFindings = JSON.parse(JSON.stringify(niapFindings));

  // Convert niapIssues array to object with key
  const niapIssuesObject = niapIssues.reduce((obj, item) => {
    obj[item.issue.replace('niap_', '')] = item;
    return obj;
  }, {});

  // Directly replace result with review status
  for (const f in newNiapFindings) {
    if (niapIssuesObject[f]) {
      if (niapIssuesObject[f].review_status) {
        if (niapIssuesObject[f].review_status !== 'default') {
          newNiapFindings[f].result = niapIssuesObject[f].review_status.replace(
            'ed',
            '',
          );
        }
        newNiapFindings[f].note = niapIssuesObject[f].note;
        newNiapFindings[f].flag = true;
      }
    }
  }

  for (const finding in newNiapFindings) {
    niapFindingKeys.push(finding);
    if (newNiapFindings[finding].result === 'pass') {
      niapPass.push(finding);
    }
    if (newNiapFindings[finding].result === 'fail') {
      niapFail.push(finding);
    }
    if (newNiapFindings[finding].result === 'review') {
      niapReview.push(finding);
    }
  }

  return (
    <div>
      <Row>
        <Col>
          <Card
            className="card-accent-danger pointer"
            onClick={() =>
              filter === 'fail' ? setFilter('') : setFilter('fail')
            }
            color={filter === 'fail' ? 'danger' : ''}
          >
            <CardBody>
              <Row>
                <h4>Fail</h4>
              </Row>
              <Row>
                <h4>{niapFail.length}</h4>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card
            className="card-accent-warning pointer"
            onClick={() =>
              filter === 'review' ? setFilter('') : setFilter('review')
            }
            color={filter === 'review' ? 'warning' : ''}
          >
            <CardBody>
              <Row>
                <h4>Review</h4>
              </Row>
              <Row>
                <h4>{niapReview.length}</h4>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card
            className="card-accent-success pointer"
            onClick={() =>
              filter === 'pass' ? setFilter('') : setFilter('pass')
            }
            color={filter === 'pass' ? 'success' : ''}
          >
            <CardBody>
              <Row>
                <h4>Pass</h4>
              </Row>
              <Row>
                <h4>{niapPass.length}</h4>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs="3">
          <NiapNav
            niapIds={niapFindingKeys}
            niapIssues={newNiapFindings}
            filter={filter}
          />
        </Col>
        <Col>
          {niapFindingKeys.map((finding, index) => (
            <NiapCard
              key={index}
              finding={finding}
              filter={filter}
              niapFindings={newNiapFindings}
              platform={platform}
            />
          ))}
        </Col>
      </Row>
    </div>
  );
}
