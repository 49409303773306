import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import Link from '@mui/material/Link';

const LDLink = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <Link
      underline="hover"
      color="primary"
      sx={{ fontWeight: 'bold' }}
      variant="body"
      {...props}
    >
      {children}
    </Link>
  ) : (
    <a {...props}>{children}</a>
  );
};
export const QLink = withLDConsumer()(LDLink);
