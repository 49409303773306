import React from 'react';
import { InputGroupAddon } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

const LDInputGroupAddon = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    children
  ) : (
    <InputGroupAddon {...props}>{children}</InputGroupAddon>
  );
};

export const QInputGroupAddon = withLDConsumer()(LDInputGroupAddon);
