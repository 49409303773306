import React from 'react';
import { Col } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Box } from '@mui/system';

const LDCol = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  const filteredProps = ['className'];

  const muiColProps = Object.keys(props).reduce((acc, key) => {
    if (!filteredProps.includes(key)) {
      acc[key] = props[key];
    }
    return acc;
  }, {});

  return mastV2 ? (
    <Box {...muiColProps}>{children}</Box>
  ) : (
    <Col {...props}>{children}</Col>
  );
};
export const QCol = withLDConsumer()(LDCol);
