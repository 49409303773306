import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QTable } from '../components/Q-Components/QTable';
import { QTableRow } from '../components/Q-Components/QTableRow';
import { QTableCell } from '../components/Q-Components/QTableCell';
import { QTableBody } from '../components/Q-Components/QTableBody';
import { QTableHead } from '../components/Q-Components/QTableHead';
import { QIconButton } from '../components/Q-Components/QIconButton';
import { QTypography } from '../components/Q-Components/QTypography';
import { QCard } from '../components/Q-Components/QCard';
import { QCardHeader } from '../components/Q-Components/QCardHeader';
import { QCardBody } from '../components/Q-Components/QCardBody';
import auth from '../logic/auth';
import { toastrHelper } from '../logic/toastrHelper';
import Loading from '../components/Loading';
import { setPageTitle } from '../store/slices/emmAppSlice';
import { DateTime } from '../components/DateTime';
import { QLink } from '../components/Q-Components/QLink';

export default function CsvDownloads() {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const { mastV2 } = useFlags();

  const getFiles = async (event = null) => {
    setLoading(true);
    if (event) event.preventDefault();

    try {
      const { data } = await axios.get('csv-request/recent');
      setFiles(data);
      setLoading(false);
    } catch (err) {
      console.log('Error getting recent CSV requests: ', err);
      toastrHelper.showErrorToast(
        'Error getting recent CSV requests.',
        null,
        mastV2,
      );
    }
  };

  useEffect(() => {
    getFiles();
    dispatch(setPageTitle('CSV Downloads'));
  }, []);

  return (
    <div className="animated fadeIn">
      <QCard>
        <QCardHeader
          title={
            <div className="d-flex justify-between">
              <QTypography variant="h4Bold">Recent CSV Downloads</QTypography>
              <QIconButton
                style={{
                  color: 'unset',
                  marginRight: '10px',
                }}
                size="small"
                color="link"
                className="fa-solid fa-arrows-rotate pointer"
                onClick={() => getFiles()}
              />
            </div>
          }
        >
          <div className="d-flex justify-between">
            <strong>Recent CSV Downloads</strong>
            <div className="d-flex justify-between">
              <QIconButton
                size="sm"
                color="link"
                className="fa-solid fa-arrows-rotate pointer black-icon"
                onClick={() => getFiles()}
              />
            </div>
          </div>
        </QCardHeader>

        <QCardBody>
          {loading ? (
            <Loading />
          ) : (
            <QTable>
              <QTableHead>
                <QTableRow>
                  <QTableCell>CSV Requested</QTableCell>
                  <QTableCell>Requested Date</QTableCell>
                  <QTableCell>Status</QTableCell>
                  <QTableCell>Actions</QTableCell>
                </QTableRow>
              </QTableHead>
              <QTableBody>
                {files.map(file => (
                  <QTableRow key={file.id}>
                    <QTableCell>{file.pageTypeString}</QTableCell>
                    <QTableCell>
                      {file.createdAt ? (
                        <DateTime dateTime={file.createdAt} />
                      ) : (
                        ''
                      )}
                    </QTableCell>
                    <QTableCell>{_.capitalize(file.status)}</QTableCell>
                    <QTableCell>
                      {file.status === 'completed' ? (
                        <QLink href={`${file.link}?auth=basic`} target="_blank">
                          <i className="primary-icon fa-solid fa-download" />{' '}
                          Download {file.isCompressed ? 'Zip' : 'CSV'}
                        </QLink>
                      ) : (
                        <div />
                      )}
                    </QTableCell>
                  </QTableRow>
                ))}
              </QTableBody>
            </QTable>
          )}
        </QCardBody>
      </QCard>
    </div>
  );
}
