import React, { Component } from 'react';
import axios from 'axios';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QTypography } from '../Q-Components/QTypography';
import { QButton } from '../Q-Components/QButton';
import { QCol } from '../Q-Components/QCol';
import { QRow } from '../Q-Components/QRow';
import { QInputGroup } from '../Q-Components/QInputGroup';
import { QInput } from '../Q-Components/QInput';
import { QInputGroupAddon } from '../Q-Components/QInputGroupAddon';
import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';

class APIKey extends Component {
  constructor(props) {
    super(props);

    this.state = {
      api: '',
      showKey: false,
    };

    this.toggleShow = this.toggleShow.bind(this);
    this.updateApiKey = this.updateApiKey.bind(this);
  }

  async componentWillMount() {
    const response = await axios.get('emm/api-key');
    this.setState({ api: response.data });
  }

  async toggleShow() {
    this.setState({
      showKey: !this.state.showKey,
    });
  }

  async updateApiKey() {
    try {
      await axios.put('emm/api-key', {
        apiKey: this.state.api,
      });
      const response = await axios.get('emm/api-key');
      this.setState({ api: response.data });
      toastrHelper.showSuccessToast(
        i18n.t('Successfully updated API Key'),
        null,
        this.props.flags?.mastV2,
      );
    } catch (err) {
      toastrHelper.showErrorToast(
        i18n.t('Error updating API key'),
        null,
        this.props.flags?.mastV2,
      );
    }
  }

  render() {
    const { api, showKey } = this.state;
    return (
      <div>
        <QCard className="card-accent-success">
          <QCardHeader
            title={
              <QTypography variant="h4Bold" color="#000000">
                {i18n.t('API Key')}
              </QTypography>
            }
          >
            <strong>{i18n.t('API Key')}</strong>
          </QCardHeader>
          <QCardBody>
            <QRow>
              <QCol xs="8" sx={{ width: '75%' }}>
                <QInputGroup>
                  <QInput
                    type={
                      api === '--DISABLED--'
                        ? 'text'
                        : showKey
                        ? 'text'
                        : 'password'
                    }
                    value={api}
                    sx={{ width: '95%' }}
                    disabled
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <QButton
                          variant="ghost"
                          addonType="append"
                          onClick={() => this.toggleShow()}
                        >
                          <i
                            className={
                              showKey
                                ? 'fa-solid fa-eye pointer'
                                : 'fa-solid fa-eye-slash pointer'
                            }
                          />
                        </QButton>
                      ),
                    }}
                  />
                  {!this.props.flags.mastV2 && (
                    <QInputGroupAddon addonType="append">
                      <QButton
                        variant="ghost"
                        addonType="append"
                        onClick={() => this.toggleShow()}
                      >
                        <i
                          className={
                            showKey
                              ? 'fa-solid fa-eye pointer'
                              : 'fa-solid fa-eye-slash pointer'
                          }
                        />
                      </QButton>
                    </QInputGroupAddon>
                  )}
                </QInputGroup>
              </QCol>

              <QCol
                style={{ textAlign: 'right' }}
                sx={{ marginRight: 'auto' }}
                xs="2"
              >
                <QButton
                  variant="filled"
                  color="primary"
                  onClick={() => this.updateApiKey()}
                  sx={{ width: 'max-content' }}
                >
                  <QTypography variant="bodySemiBold" color="#ffffff">
                    {i18n.t('Generate New API Key')}
                  </QTypography>
                </QButton>
              </QCol>
            </QRow>
          </QCardBody>
        </QCard>
      </div>
    );
  }
}

export default withLDConsumer()(APIKey);
