import React from 'react';

import { QChip } from './Q-Components/QChip';

export function SubGroup({ subGroup }) {
  return (
    <div>
      {subGroup.length > 0 &&
        subGroup.map(sub => (
          <QChip className="ml-1" key={sub.name} label={sub.name}>
            {sub.name}
          </QChip>
        ))}
    </div>
  );
}
