import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardHeader, CardBody, Badge } from 'reactstrap';

import DataTable from '../DataTable/DataTable';
import i18n from '../../localization/i18n';

export default function SSLPinning({ detectFilter }) {
  const sslPinningData = useSelector(
    ({ appResults }) => appResults.results.ssl_pinning,
  );

  let tlsConnections;

  if (sslPinningData) {
    tlsConnections = Object.values(sslPinningData.tls_connections || {});

    if (detectFilter) {
      detectFilter = detectFilter === 'yes';

      tlsConnections = tlsConnections.filter(
        connection => connection.pinning_detected === detectFilter,
      );
    }
  }

  return (
    <Card className="card-accent-danger">
      <CardHeader>SSL Pinning</CardHeader>
      <CardBody>
        {!sslPinningData || tlsConnections.length === 0 ? (
          <div>No SSL Connections</div>
        ) : (
          <DataTable
            data={tlsConnections}
            columns={[
              {
                label: i18n.t('Server'),
                keyName: 'server_address',
                filterable: true,
              },
              {
                label: i18n.t('SNI'),
                keyName: 'sni',
                filterable: true,
              },
              {
                label: i18n.t('Pinning Detected'),
                keyName: 'pinning_detected',
                component: ({ value }) => (
                  <span>
                    {value ? (
                      <Badge color="success">YES</Badge>
                    ) : (
                      <Badge color="danger">NO</Badge>
                    )}
                  </span>
                ),
              },
            ]}
          />
        )}
      </CardBody>
    </Card>
  );
}
