import React, { Component } from 'react';
import { Row, Col, Button, ButtonGroup, Badge } from 'reactstrap';
import { connect } from 'react-redux';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

import ApiCall from './ApiCall';
import MethodCallParams from './MethodCallParams';

const filterApiCall = (filterStr, call) =>
  call.method_name.toLowerCase().includes(filterStr) ||
  call.component.toLowerCase().includes(filterStr) ||
  call.smali_file.file.toLowerCase().includes(filterStr);

const getCategories = results => {
  const categories = ['All'];
  for (const result of results) {
    if (!categories.includes(result.category)) {
      categories.push(result.category);
    }
  }
  return categories;
};

class DynamicAnalysisDatatable extends Component {
  constructor(props) {
    super(props);
    const dynamicResults =
      props.results.analysis.analysis_details.dynamic_analysis;

    this.state = {
      selectedCategory: 'All',
      results: dynamicResults,
      filteredResults: dynamicResults,
      categories: getCategories(dynamicResults),
      filter: props.filter,
    };

    this.selectCategory = this.selectCategory.bind(this);
    this.filterParams = this.filterParams.bind(this);
  }

  filterParams(filterStr, call) {
    if (this.state.filter !== undefined) {
      // filterStr = this.state.filter
    }
    // console.log('filterStr: ', filterStr)
    // console.log('Call: ', call)
    return call.parameters.some(param =>
      param.toLowerCase().includes(filterStr),
    );
  }

  selectCategory(category) {
    let filteredResults;
    if (category === 'All') {
      filteredResults = this.state.results;
    } else {
      filteredResults = this.state.results.filter(
        result => result.category === category,
      );
    }

    this.setState({ selectedCategory: category, filteredResults });
  }

  render() {
    const { selectedCategory, filter, results } = this.state;
    const {
      showFilter = true,
      onlySelectedCategory = '',
      methodFilter = '',
      paramFilter = '',
    } = this.props;

    const { categories } = this.state;
    let { filteredResults } = this.state;

    if (onlySelectedCategory) {
      if (typeof onlySelectedCategory === 'string') {
        filteredResults = results.filter(result =>
          result.category
            .toLowerCase()
            .includes(onlySelectedCategory.toLowerCase()),
        );
      } else {
        const filteredCategories = onlySelectedCategory.map(c =>
          c.toLowerCase(),
        );

        filteredResults = results.filter(entry => {
          const categoryName = entry.category.toLowerCase();
          return !!filteredCategories.some(category =>
            categoryName.includes(category),
          );
        });
      }
    }

    if (paramFilter) {
      filteredResults = filteredResults.filter(
        entry =>
          !!entry.parameters.find(param =>
            param.toLowerCase().includes(paramFilter.toLowerCase()),
          ),
      );
    }

    if (methodFilter) {
      if (typeof methodFilter === 'string') {
        filteredResults = filteredResults.filter(entry =>
          entry.method_name.toLowerCase().includes(methodFilter.toLowerCase()),
        );
      } else {
        const filters = methodFilter.map(f => f.toLowerCase());
        filteredResults = filteredResults.filter(entry => {
          const methodName = entry.method_name.toLowerCase();
          return !!filters.some(filter => methodName.includes(filter));
        });
      }
    }

    return (
      <div>
        <Row>
          <Col>
            {!onlySelectedCategory ? (
              <ButtonGroup size="sm" className="flex-wrap">
                {showFilter &&
                  categories.map(category => (
                    <Button
                      key={category}
                      color="primary"
                      outline={category !== selectedCategory}
                      onClick={() => this.selectCategory(category)}
                      className="mt-2"
                    >
                      {i18n.t(category)}
                    </Button>
                  ))}
              </ButtonGroup>
            ) : (
              <div>
                <strong>{onlySelectedCategory.toString()}:</strong>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <DataTable
              data={filteredResults}
              columns={[
                {
                  label: i18n.t('Category'),
                  keyName: 'category',
                  component: ({ value }) => (
                    <Badge color="primary" style={{ fontSize: '0.8rem' }}>
                      {value}
                    </Badge>
                  ),
                  sortable: true,
                  filterable: true,
                  width: '10%',
                },
                {
                  label: i18n.t('API Call'),
                  component: ApiCall,
                  filterable: true,
                  filterFunc: filterApiCall,
                  width: '40%',
                },
                {
                  label: i18n.t('Parameters'),
                  component: MethodCallParams,
                  filterable: true,
                  filterFunc: this.filterParams,
                  width: '50%',
                },
              ]}
              filter={filter}
              showFilter={showFilter}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(DynamicAnalysisDatatable);
