import React from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import axios from 'axios';

import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import QDataTable from '../DataTable/QDataTable';
import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';

import { NewUser } from './GroupAdmin/NewUser';

export default function UserInfo({ canInviteUsers = false }) {
  const subGroups = useSelector(state => state.emmApp.userSubGroups);
  const { mastV2 } = useFlags();

  const createNewUser = async (email, _, subGroupId) => {
    try {
      const response = await axios.post(`/user-actions/invite-user`, {
        email,
        subGroupId,
      });

      // For appliances the user's generated password is returned to the client
      // since the appliance doesn't send out emails. We then display it to the group
      // admin to pass along to the newly created user
      if (response.data.generatedPassword) {
        // eslint-disable-next-line no-alert
        alert(`Generated password: ${response.data.generatedPassword}`);
      } else {
        toastrHelper.showSuccessToast(
          i18n.t(
            "New user created. An email has been sent to the new user's email address with their login information.",
          ),
          null,
          mastV2,
        );
      }
    } catch (err) {
      toastrHelper.showWarningToast(
        `${i18n.t('Error creating user')}: ${
          err.response.data.message || err.response.data.msg
        }`,
        null,
        mastV2,
      );
    }
  };

  return (
    <QRow columnGap="20px">
      <QCol xs="4">
        {subGroups.length !== 0 && (
          <QCard className="card-accent-primary">
            <QCardHeader title="Sub Groups">
              <strong>Sub Groups</strong>
            </QCardHeader>
            <QCardBody>
              <QDataTable
                striped
                filterable={false}
                data={subGroups}
                columns={[
                  {
                    label: 'ID',
                    keyName: 'id',
                    sortable: true,
                  },
                  {
                    label: i18n.t('Name'),
                    keyName: 'name',
                    filterable: true,
                    sortable: true,
                  },
                ]}
              />
            </QCardBody>
          </QCard>
        )}
      </QCol>
      <QCol xs="4">
        {canInviteUsers && (
          <NewUser
            createNewUser={createNewUser}
            kaiAccess={false}
            placement="right"
            userWithInvitePermission
          />
        )}
      </QCol>
    </QRow>
  );
}
