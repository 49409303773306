import React from 'react';

import util from '../../logic/util';
import {
  getThreatColor,
  getThreatQBadgeVariant,
} from '../../logic/generalReportUtil';
import i18n from '../../localization/i18n';
import { QListGroup } from '../Q-Components/QListGroup';
import { QListGroupItem } from '../Q-Components/QListGroupItem';
import { QBadge } from '../Q-Components/QBadge';

const VersionIssues = ({ issuesSelf, issuesOther }) => (
  <div>
    <QListGroup
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxHeight: '200px',
        overflow: 'scroll',
      }}
    >
      {issuesSelf.map(issue => {
        let found = false;
        for (let i = 0; i < issuesOther.length; i++) {
          if (
            issuesOther[i].positive_finding_text === issue.positive_finding_text
          ) {
            found = true;
            break;
          }
        }
        return (
          <QListGroupItem
            color={found ? '' : 'warning'}
            sx={found ? {} : { backgroundColor: '#FFB822' }}
          >
            <div>
              <QBadge
                color={getThreatColor(issue.risk)}
                variant={getThreatQBadgeVariant(issue.risk)}
                label={util.capitalize(i18n.t(issue.risk))}
              >
                {util.capitalize(i18n.t(issue.risk))}
              </QBadge>{' '}
              <span>{issue.positive_finding_text}</span>
            </div>
          </QListGroupItem>
        );
      })}
    </QListGroup>
  </div>
);

export default VersionIssues;
