import React, { useState } from 'react';
import _ from 'lodash';
import LazyLoad from 'react-lazyload';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { useFlags } from 'launchdarkly-react-client-sdk';

import util from '../../../../../logic/util';
import { QTableRow } from '../../../../Q-Components/QTableRow';
import { QTableCell } from '../../../../Q-Components/QTableCell';
import { QTypography } from '../../../../Q-Components/QTypography';
import { QButton } from '../../../../Q-Components/QButton';

import { CodeFlowsEvidenceModal } from './CodeFlowsEvidence';
import { StacksEvidenceModal } from './StacksEvidence';

const ViewMoreEvidenceButton = ({ mastV2, title, onClick }) =>
  mastV2 ? (
    <QButton
      variant="ghost"
      style={{
        paddingLeft: '10px',
        fontWeight: 700,
        fontSize: 13,
        color: '#487F87',
      }}
      onClick={onClick}
    >
      {title}
    </QButton>
  ) : (
    <span
      style={{
        color: '#487F87',
        cursor: 'pointer',
        fontWeight: 700,
        paddingLeft: '12px',
      }}
      onClick={onClick}
    >
      {title}
    </span>
  );

export function LocationEvidence({ location, codeFlows, stacks, step }) {
  const { mastV2 } = useFlags();
  const [showCodeFlowModal, setShowCodeFlowModal] = useState(false);
  const [showStackModal, setShowStackModal] = useState(false);

  const uri = _.get(location, 'physicalLocation.artifactLocation.uri', null);
  const address = _.get(
    location,
    'physicalLocation.address.fullyQualifiedName',
    null,
  );

  // location values for a text region
  const locationLine = _.get(location, 'physicalLocation.region.startLine', -1);
  const startLine =
    _.get(location, 'physicalLocation.contextRegion.startLine', null) ||
    locationLine;
  const endLine =
    _.get(location, 'physicalLocation.contextRegion.endLine', null) ||
    _.get(location, 'physicalLocation.region.endLine', -1);
  const lineCode =
    _.get(location, 'physicalLocation.contextRegion.snippet.text', null) ||
    _.get(location, 'physicalLocation.region.snippet.text', null);

  // location values for a binary region
  const byteOffset = _.get(
    location,
    'physicalLocation.region.byteOffset',
    null,
  );
  const startAddress = _.get(
    location,
    'physicalLocation.address.absoluteAddress',
    -1,
  ).toString(16);
  const binaryLineCode = _.get(
    location,
    'physicalLocation.region.snippet.rendered.text',
    null,
  );

  const blockCode = _.get(
    location,
    'physicalLocation.contextRegion.snippet.text',
    '',
  );
  const region = (lineCode || binaryLineCode) ?? blockCode;
  const code = uri ?? address ?? '';

  let formattedLocation;
  if (stacks && address) {
    formattedLocation = address;
  } else if (startLine !== -1 || lineCode) {
    // text region
    formattedLocation = `${code} at line: ${locationLine}`;
  } else if (byteOffset && (startAddress !== '-1' || binaryLineCode)) {
    // binary region always has byteOffset https://docs.oasis-open.org/sarif/sarif/v2.1.0/os/sarif-v2.1.0-os.html#_Toc34317696
    formattedLocation = `${code} at address: 0x${startAddress}`;
  } else {
    formattedLocation = `${code}`;
  }

  const locationTextColor = step ? '#757575' : '#000000';

  return (
    <>
      {codeFlows && (
        <CodeFlowsEvidenceModal
          isOpen={showCodeFlowModal}
          toggle={() => setShowCodeFlowModal(!showCodeFlowModal)}
          codeFlows={codeFlows}
          startLocation={formattedLocation}
        />
      )}

      {stacks && (
        <StacksEvidenceModal
          isOpen={showStackModal}
          toggle={() => setShowStackModal(!showStackModal)}
          stacks={stacks}
          startLocation={formattedLocation}
        />
      )}

      <QTableRow hoverDisabled>
        <QTableCell
          style={
            !mastV2
              ? { border: 'none', boxShadow: 'none' }
              : { backgroundColor: 'white' }
          }
        >
          <div style={mastV2 ? {} : { display: 'flex' }}>
            {step > 0 &&
              (mastV2 ? (
                <QTypography variant="h5Medium">{`Step ${step}  `}</QTypography>
              ) : (
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: '16px',
                    color: 'black',
                    marginRight: '10px',
                  }}
                >{`Step ${step}  `}</div>
              ))}
            <QTypography
              variant={step ? 'h6Medium' : 'bodyMedium'}
              style={{ color: locationTextColor }}
            >
              {formattedLocation}
            </QTypography>

            {codeFlows && (
              <ViewMoreEvidenceButton
                mastV2={mastV2}
                title="View Code Flows"
                onClick={() => setShowCodeFlowModal(true)}
              />
            )}

            {stacks && (
              <ViewMoreEvidenceButton
                mastV2={mastV2}
                title="View Stacks"
                onClick={() => setShowStackModal(true)}
              />
            )}
          </div>

          {location.message?.text && (
            <div>
              <QTypography variant="bodyRegular">
                {location.message.text}
              </QTypography>
            </div>
          )}
          {!step
            ? region && (
                <LazyLoad>
                  <SyntaxHighlighter
                    key={Math.random()}
                    data-testid="locations-evidence-code-test"
                    language={util.getLanguageFromFileName(code)}
                    wrapLines
                    wrapLongLines
                    startingLineNumber={startLine}
                    showLineNumbers={startLine > 0}
                    lineProps={lineNumber => {
                      const style = {
                        wordBreak: 'break-all',
                        whiteSpace: 'pre-wrap',
                      };
                      if (
                        locationLine === startLine + lineNumber - 1 &&
                        startLine !== endLine
                      ) {
                        style.backgroundColor = '#DDEFE9';
                      }
                      return { style };
                    }}
                  >
                    {region}
                  </SyntaxHighlighter>
                </LazyLoad>
              )
            : region && (
                <SyntaxHighlighter
                  key={Math.random()}
                  data-testid="locations-evidence-code-test"
                  language={util.getLanguageFromFileName(code)}
                  wrapLines
                  wrapLongLines
                  startingLineNumber={startLine}
                  showLineNumbers={startLine > 0}
                  lineProps={lineNumber => {
                    const style = {
                      wordBreak: 'break-all',
                      whiteSpace: 'pre-wrap',
                    };
                    if (
                      locationLine === startLine + lineNumber - 1 &&
                      startLine !== endLine
                    ) {
                      style.backgroundColor = '#DDEFE9';
                    }
                    return { style };
                  }}
                >
                  {region}
                </SyntaxHighlighter>
              )}
        </QTableCell>
      </QTableRow>

      {!mastV2 && (
        <hr
          style={{
            borderColor: '#E0E0E0',
            marginTop: '12px',
            marginBottom: '12px',
          }}
        />
      )}
    </>
  );
}
