import { createSlice } from '@reduxjs/toolkit';

import { jsonCache } from '../../cacheHelpers/utils';

const initialState = jsonCache.create({ sizeLimit: 7 });

export const firmwareCacheSlice = createSlice({
  name: 'firmwareCache',
  initialState,
  reducers: {
    invalidateFirmwareCache: state => jsonCache.setAllInvalid(state),
    cacheFirmware: (state, action) =>
      jsonCache.add(state, action.payload.key, action.payload.data),
  },
});

export const { invalidateFirmwareCache, cacheFirmware } =
  firmwareCacheSlice.actions;

export default firmwareCacheSlice.reducer;
