import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import MdmDevices from '../components/MdmIntegration/MdmDevices';
import { setPageTitle } from '../store/slices/emmAppSlice';

class MdmIntegration extends Component {
  async componentWillMount() {
    this.props.setPageTitle('MDM Integration');
  }

  render() {
    return (
      <Row>
        <Col xs="8">
          <MdmDevices />
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = {
  setPageTitle,
};

export default connect(null, mapDispatchToProps)(MdmIntegration);
