import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

export default class FindingsNav extends Component {
  render() {
    const { results } = this.props;
    return (
      <Card className="card-accent-secondary">
        <CardHeader>Analysis Findings</CardHeader>
        <CardBody style={{ padding: 0 }}>
          <ListGroup>
            {results.map(finding => {
              let color;
              if (finding.result === 'pass') color = 'success';
              else if (finding.result === 'fail') color = 'danger';
              else if (finding.result === 'review') color = 'warning';
              else color = '';

              return (
                <Link to={`#taiwan-${finding.id}`} key={finding.id}>
                  <ListGroupItem action color={color}>
                    <span>{finding.id}</span>
                  </ListGroupItem>
                </Link>
              );
            })}
          </ListGroup>
        </CardBody>
      </Card>
    );
  }
}
