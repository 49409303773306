import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Badge } from 'reactstrap';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

export const StaticAnalysisDatatable = ({
  categoryFilter,
  keyFilter,
  methodFilter = null,
}) => {
  const staticAnalysisData = useSelector(
    ({ appResults }) =>
      appResults.results.analysis.analysis_details.static_analysis,
  );

  if (!categoryFilter) {
    return null;
  }

  let data = _.get(staticAnalysisData, `${categoryFilter}.${keyFilter}`, []);

  switch (categoryFilter) {
    case 'methods':
      if (methodFilter) {
        data = data.filter(entry => entry.method.match(methodFilter));
      }

      return (
        <>
          <Badge color="light">{keyFilter}</Badge>
          <DataTable
            data={data}
            columns={[
              {
                label: i18n.t('Line Number'),
                keyName: 'line_num',
                sortable: true,
                filterable: true,
                width: '10%',
              },
              {
                label: i18n.t('Smali File'),
                keyName: 'smali_file',
                sortable: true,
                filterable: true,
                width: '10%',
              },
              {
                label: i18n.t('Method'),
                keyName: 'method',
                sortable: true,
                filterable: true,
                width: '10%',
              },
            ]}
          />
        </>
      );

    default:
      return <div>Not Available</div>;
  }
};
