import React from 'react';
import { CardText } from 'reactstrap';

import AndroidPermissionsRequestedDatatable from '../../Android/PermissionsRequestedTable';
import ExtraPermissionsTable from '../../Android/ExtraPermissionsTable';
import { MissingPermissionsTable } from '../../Android/MissingPermissionsTable';
import { NeededPermissionsTable } from '../../Android/NeededPermissionsTable';
import IosPermissionsRequestedDatatable from '../../iOS/PermissionsRequestedDatatable';

import { Issues } from './Issues';

export const V6_1 = ({ platform, evidence }) => (
  <>
    <Issues evidence={evidence} issues={['HAS_EXTRA_PERMISSIONS']} />
    <hr />

    {platform === 'android' ? (
      <>
        <CardText>
          <strong>Permissions Requested</strong>
        </CardText>
        <AndroidPermissionsRequestedDatatable />

        <hr />
        <CardText>
          <strong>Extra Permissions</strong>
        </CardText>
        <ExtraPermissionsTable />

        <hr />
        <CardText>
          <strong>Missing Permissions</strong>
        </CardText>
        <MissingPermissionsTable />

        <hr />
        <CardText>
          <strong>Permissions Needed</strong>
        </CardText>
        <NeededPermissionsTable />
      </>
    ) : (
      <>
        <IosPermissionsRequestedDatatable />
      </>
    )}
  </>
);
