export default {
  // Return the path from a URL
  getPath(url) {
    const path = url.split('/').slice(3).join('/');
    if (!path) return '/';
    return path;
  },

  // Return the formatted request time (either in ms or s)
  getRequestTime(timings) {
    // Check that timings are actually available for the entry
    if (!timings) {
      return 'Timing not available';
    }
    let milliseconds = 0;
    for (const time of Object.values(timings)) {
      milliseconds += time;
    }
    if (milliseconds >= 1000) {
      return `${Math.round(1000 / 1000, 2).toString()}s`;
    }
    return `${milliseconds.toString()}ms`;
  },
};
