import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QSidebar as MuiSidebar } from '@kw/quokka-ui';

const LDSidebar = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiSidebar {...props}>{children}</MuiSidebar>
  ) : (
    <div className="sidebar">
      <nav className="sidebar-nav kryptowire-sidenav">{children}</nav>
    </div>
  );
};
export const QSidebar = withLDConsumer()(LDSidebar);
