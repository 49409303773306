import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useLatest } from 'react-use';
import produce from 'immer';
import { toCamel } from 'snake-camel';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QCol } from '../../Q-Components/QCol';
import { QRow } from '../../Q-Components/QRow';
import { SwitchTriple } from '../../SwitchTriple';
import { toastrHelper } from '../../../logic/toastrHelper';
import {
  getPermissionsConfig,
  checkSpecialPermissionVisibility,
  checkFeatureFlagPermissionVisibility,
} from '../../Settings/GroupAdmin/PermissionsConfig';
// Save permission
export const savePermissions = async (users, permissions, mastV2 = false) => {
  const {
    isLocked,
    generalPermissions,
    analysisPermissions,
    reportsPermissions,
  } = permissions;

  const mappedPermissions = generalPermissions
    .concat(analysisPermissions, reportsPermissions)
    .filter(({ accessValue }) => accessValue !== null)
    .map(({ accessValue, accessName }) => ({
      accessValue,
      accessName: accessName
        .split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase(),
    }));

  try {
    await axios.put(`group-admin/bulk-edit-permissions`, {
      users: users.map(user => ({ id: user.value })),
      isLocked,
      permissions: mappedPermissions,
    });

    toastrHelper.showSuccessToast(
      "Successfully updated users' permissions",
      'Success',
      mastV2,
    );
  } catch (err) {
    toastrHelper.showErrorToast(
      `Error updating users: ${err.response.data.message}`,
      'Error',
      mastV2,
    );
  }
};

export const usePermissions = () => {
  const updateAccessValue = (permissons, name, accessValue) => {
    console.log(name, 'to', accessValue);
    return produce(permissons, draft => {
      draft.find(perm => perm.accessName === name).accessValue = accessValue;
    });
  };

  const [generalPermissions, setGeneralPermissions] = useState(
    getPermissionsConfig().general.map(([label, name]) => ({
      accessName: name,
      accessValue: null,
      label,
      setAccessValue: accessValue => {
        setGeneralPermissions(generalPermissions =>
          updateAccessValue(generalPermissions, name, accessValue),
        );
      },
    })),
  );

  const [analysisPermissions, setAnalysisPermissions] = useState(
    getPermissionsConfig().analysis.map(([label, name]) => ({
      accessName: name,
      accessValue: null,
      label,
      setAccessValue: accessValue => {
        setAnalysisPermissions(analysisPermissions =>
          updateAccessValue(analysisPermissions, name, accessValue),
        );
      },
    })),
  );

  const [reportsPermissions, setReportsPermissions] = useState(
    getPermissionsConfig().reports.map(([label, name]) => ({
      accessName: name,
      accessValue: null,
      label,
      setAccessValue: accessValue =>
        setReportsPermissions(reportsPermissions =>
          updateAccessValue(reportsPermissions, name, accessValue),
        ),
    })),
  );

  const [isLocked, setIsLocked] = useState(null);

  const latestPermissions = useLatest({
    isLocked,
    generalPermissions,
    analysisPermissions,
    reportsPermissions,
  });

  // retrieves latest value, for when we need to use value in callback
  const get = () => latestPermissions.current;

  const resetPermissions = () => {
    setIsLocked(null);
    generalPermissions.forEach(permission => {
      setGeneralPermissions(generalPermissions =>
        updateAccessValue(generalPermissions, permission.accessName, null),
      );
    });
    analysisPermissions.forEach(permission => {
      setAnalysisPermissions(analysisPermissions =>
        updateAccessValue(analysisPermissions, permission.accessName, null),
      );
    });
    reportsPermissions.forEach(permission => {
      setReportsPermissions(reportsPermissions =>
        updateAccessValue(reportsPermissions, permission.accessName, null),
      );
    });
  };

  return {
    isLocked,
    generalPermissions,
    analysisPermissions,
    reportsPermissions,
    get,
    setIsLocked,
    resetPermissions,
    savePermissions,
  };
};

export default function PermissionsCard({
  isLocked,
  generalPermissions,
  analysisPermissions,
  reportsPermissions,
  setConfirmationModal,
  setIsLocked,
  kaiAccess,
}) {
  const adminUserAccess = toCamel(
    useSelector(({ emmApp }) => emmApp.userAccess),
  );
  const flags = useFlags();
  const { mastV2 } = flags;

  useEffect(setConfirmationModal, []);

  return (
    <>
      <QRow
        className="justify-content-center"
        justifyContent="center"
        alignItems="center"
      >
        <h5 className="text-center">Permissions</h5>
      </QRow>
      <hr />
      <QRow justifyContent="center" alignItems="center">
        <QCol>
          <div className={mastV2 ? '' : 'd-flex flex-column bg-light p-4'}>
            <small className="text-muted text-uppercase font-weight-bold">
              General
            </small>
            <SwitchTriple
              middleButtonText="No Change"
              selectedValue={isLocked}
              label="Locked"
              onChange={setIsLocked}
            />
            {generalPermissions.map((permission, index) => {
              const { hidden } = checkSpecialPermissionVisibility({
                permissionName: permission.accessName,
                doesCurrentAdminHasPermission:
                  adminUserAccess[permission.accessName],
                kaiAccess,
              });

              if (hidden) {
                return null;
              }

              return (
                <SwitchTriple
                  key={index}
                  middleButtonText="No Change"
                  selectedValue={permission.accessValue}
                  label={permission.label}
                  onChange={permission.setAccessValue}
                />
              );
            })}
            <br />
            <small className="text-muted text-uppercase font-weight-bold">
              Analysis
            </small>
            {analysisPermissions.map((permission, index) => (
              <SwitchTriple
                key={index}
                middleButtonText="No Change"
                selectedValue={permission.accessValue}
                label={permission.label}
                onChange={permission.setAccessValue}
              />
            ))}
            <small className="text-muted text-uppercase font-weight-bold">
              Reports
            </small>
            {reportsPermissions.map((permission, index) => {
              const isVisible = checkFeatureFlagPermissionVisibility({
                permissionName: permission.accessName,
                flags,
              });

              if (!isVisible) {
                return null;
              }

              return (
                <SwitchTriple
                  key={index}
                  middleButtonText="No Change"
                  selectedValue={permission.accessValue}
                  label={permission.label}
                  onChange={permission.setAccessValue}
                />
              );
            })}
          </div>
        </QCol>
      </QRow>
    </>
  );
}
