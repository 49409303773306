import React, { Component } from 'react';
import moment from 'moment';
import { Card, CardHeader, CardBody } from 'reactstrap';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

export class Cookies extends Component {
  render() {
    const { cookies = [] } = this.props;

    return (
      <Card className="card-accent-info">
        <CardHeader>{i18n.t('Cookies')}</CardHeader>
        <CardBody>
          <DataTable
            data={cookies}
            columns={[
              { keyName: 'name', label: i18n.t('Name') },
              { keyName: 'domain', label: i18n.t('Domain') },
              { keyName: 'path', label: i18n.t('Path') },
              { keyName: 'value', label: 'Value', filterable: true },
              {
                label: i18n.t('Valid Dates'),
                component: ({ rowData }) => (
                  <div>
                    <div>
                      {i18n.t('Created')}:{' '}
                      {moment(rowData.creation_date).format('L - LTS')}
                    </div>
                    <div>
                      {i18n.t('Expires')}:{' '}
                      {moment(rowData.expiration_date).format('L - LTS')}
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </CardBody>
      </Card>
    );
  }
}
