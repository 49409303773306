import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';

import TabsView from '../../TabsView';
import Findings from '../Findings';

class PersonalData extends Component {
  render() {
    const { platform, parsedIssues } = this.props;

    const tabs = [
      {
        title: 'Overview',
        tabContent: () => (
          <Findings
            findings={parsedIssues}
            platform={platform}
            category="privacy"
            showOwaspIcon={false}
          />
        ),
      },
    ];
    return (
      <div>
        <Card className="card-accent-navy">
          <CardHeader>Personal Data</CardHeader>
          <CardBody>
            <div>
              &quot;The data subjects are identifiable if they can be directly
              or indirectly identified, especially by reference to an identifier
              such as a name, an identification number, location data, an online
              identifier or one of several special characteristics, which
              expresses the physical, physiological, genetic, mental,
              commercial, cultural or social identity of these natural persons.
              In practice, these also include all data which are or can be
              assigned to a person in any kind of way. For example, the
              telephone, credit card or personnel number of a person, account
              data, number plate, appearance, customer number or address are all
              personal data.&quot;
            </div>
            <div>
              <a
                href="https://gdpr-info.eu/issues/personal-data/"
                target="_blank"
              >
                More Info
              </a>
            </div>
            <hr />
            <TabsView tabs={tabs} />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ appResults }) => ({
  parsedIssues: appResults.parsedIssues,
});

export default withRouter(connect(mapStateToProps)(PersonalData));
