import React, { Component } from 'react';

import DynamicAnalysisDatatable from '../../../Android/DynamicAnalysisDatatable';
import PermissionsRequestedTable from '../../../Android/PermissionsRequestedTable';
import { Harviewer } from '../../../../Harviewer/Harviewer';
import TabsView from '../../../../TabsView';
import i18n from '../../../../../localization/i18n';

export default class CanDownloadWithoutNotification extends Component {
  render() {
    const tabs = [
      {
        title: i18n.t('Permission Requested'),
        tabContent: () => (
          <PermissionsRequestedTable filter="DOWNLOAD_WITHOUT_NOTIFICATION" />
        ),
      },
      {
        title: i18n.t('Dynamic Analysis'),
        tabContent: () => (
          <DynamicAnalysisDatatable
            onlySelectedCategory="Network events"
            showFilter={false}
          />
        ),
      },
      {
        title: i18n.t('Network Traffic'),
        tabContent: () => <Harviewer />,
      },
    ];
    return <TabsView tabs={tabs} />;
  }
}
