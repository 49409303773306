import React from 'react';
import { CardHeader } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QCardHeader as MuiCardHeader } from '@kw/quokka-ui';

const LDCardHeader = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiCardHeader {...props}>{children}</MuiCardHeader>
  ) : (
    <CardHeader {...props}>{children}</CardHeader>
  );
};
export const QCardHeader = withLDConsumer()(LDCardHeader);
