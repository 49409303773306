import React from 'react';
import { ButtonGroup } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import {
  QButtonGroup as MuiButtonGroup,
  QToggleButtonGroup as MuiToggleButtonGroup,
} from '@kw/quokka-ui';

const LDButtonGroup = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiButtonGroup {...props}>{children}</MuiButtonGroup>
  ) : (
    <ButtonGroup {...props}>{children}</ButtonGroup>
  );
};

const LDToggleButtonGroup = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiToggleButtonGroup {...props}>{children}</MuiToggleButtonGroup>
  ) : (
    <ButtonGroup {...props}>{children}</ButtonGroup>
  );
};

export const QButtonGroup = withLDConsumer()(LDButtonGroup);

export const QToggleButtonGroup = withLDConsumer()(LDToggleButtonGroup);
