import React from 'react';
import Select from 'react-select';
import { MultiSelect } from 'react-multi-select-component';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QSelect as MuiSelect } from '@kw/quokka-ui';

const LDSelect = ({ children, reactMultiSelect = false, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiSelect {...props}>{children}</MuiSelect>
  ) : reactMultiSelect ? (
    <MultiSelect {...props}>{children}</MultiSelect>
  ) : (
    // Additional props to fix the issue of select menu overlapping other selects
    // Reference: https://github.com/JedWatson/react-select/issues/3030#issuecomment-420757206
    <Select
      menuPortalTarget={document.body}
      menuPosition="fixed"
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      {...props}
    >
      {children}
    </Select>
  );
};
export const QSelect = withLDConsumer()(LDSelect);
