import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useFlags } from 'launchdarkly-react-client-sdk';

import i18n from '../../localization/i18n';
import Switch from '../Switch';
import { QButton } from '../Q-Components/QButton';
import { QModal } from '../Q-Components/QModal';
import { QModalTitle } from '../Q-Components/QModalTitle';
import { QModalContent } from '../Q-Components/QModalContent';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QSwitch } from '../Q-Components/QSwitch';
import { QInput } from '../Q-Components/QInput';
import { QLabel } from '../Q-Components/QLabel';
import { QTypography } from '../Q-Components/QTypography';
import { QModalActions } from '../Q-Components/QModalActions';
import { QSwitchControlLabel } from '../Q-Components/QSwitchControlLabel';
import { QSelect } from '../Q-Components/QSelect';
import { QDivider } from '../Q-Components/QDivider';

export default function ConfirmAppSubmitModal(props) {
  const { appiumScript, mastV2 } = useFlags();

  const [addToWatchlist, setAddToWatchList] = useState(false);
  const [externalId, setExternalId] = useState(null);
  const [selectedSubgroups, setSelectedSubgroups] = useState([]);
  const [scripts, setScriptFiles] = useState([]);
  const [scriptsFileName, setScriptsFileName] = useState('');

  const scriptFilesInputRef = useRef(null);

  const { userConfig, userSubGroups } = useSelector(state => state.emmApp);

  const userSubGroupsOptions = [];
  userSubGroups.forEach(subGroup => {
    userSubGroupsOptions.push({ value: subGroup.id, label: subGroup.name });
  });

  const processScriptFilesChange = event => {
    const scripts = Array.from(event.target.files);
    setScriptFiles(scripts);
    setScriptsFileName(scripts.map(script => script.name).join(','));
  };

  const watchlistToggle = () => {
    setAddToWatchList(!addToWatchlist);
  };

  const { isOpen, toggle, confirm, appInfo, canWatchlist, app, binarySubmit } =
    props;

  const {
    customized_eid: customizedEid,
    test_script_submission: testScriptSubmissionPermission,
  } = userConfig;

  return (
    <QModal
      open={isOpen}
      isOpen={isOpen}
      toggle={toggle}
      title={
        <QModalTitle onClose={toggle} closeIcon={<CloseIcon />}>
          <QTypography variant="h3Medium">
            {i18n.t('Confirm Submission')}
          </QTypography>
        </QModalTitle>
      }
      content={
        <QModalContent>
          <p>
            <QTypography variant="h5Regular">
              {' '}
              {i18n.t('Would you like to submit this application')}?
            </QTypography>
          </p>
          {app && (
            <QRow columnGap="8px">
              <QCol xs="2">
                <img src={app.icon} height="35px" alt="Icon" />
              </QCol>
              <QCol>
                <QRow>
                  <QTypography variant="h5Regular"> {app.name}</QTypography>
                </QRow>
                <QRow>
                  <i>
                    <QTypography variant="h5Regular">
                      {' '}
                      {app.package}
                    </QTypography>
                  </i>
                </QRow>
              </QCol>
            </QRow>
          )}
          {appInfo && <p>{appInfo}</p>}

          <QDivider
            sx={{
              borderColor: '#BDBDBD',
              marginTop: '24px',
              marginBottom: '24px',
            }}
          />

          <QRow style={{ marginBottom: '8px' }}>
            <QCol>
              <QTypography variant="h5Regular">
                {i18n.t('Submission Settings')}
              </QTypography>
            </QCol>
          </QRow>

          <QRow>
            {canWatchlist && (
              <QCol style={{ paddingLeft: '8px', paddingBottom: '16px' }}>
                <QSwitchControlLabel
                  label={
                    <QTypography variant="bodySemiBold" color="#000000">
                      Add to watchlist
                    </QTypography>
                  }
                  control={
                    <QSwitch
                      size="small"
                      checked={addToWatchlist}
                      onChange={() => watchlistToggle()}
                    />
                  }
                >
                  <QSwitch
                    onChange={watchlistToggle}
                    value={addToWatchlist}
                    label={i18n.t('Add to watchlist')}
                    id="add-to-watchlist"
                  />
                </QSwitchControlLabel>
              </QCol>
            )}
          </QRow>
          <QRow sx={{ width: '100%' }}>
            {userSubGroups.length > 0 && (
              <QCol sx={{ width: '50%' }}>
                <QTypography variant="h5Medium">
                  {i18n.t('Associated Subgroups')}
                </QTypography>
              </QCol>
            )}
            <QCol sx={{ width: '50%', paddingLeft: '16px' }}>
              <QTypography variant="h5Medium">
                {customizedEid || i18n.t('External ID')}
              </QTypography>
              <QTypography variant="bodyRegular"> (Optional)</QTypography>
            </QCol>
          </QRow>
          <QRow columnGap="16px" sx={{ width: '100%' }}>
            {userSubGroups.length > 0 && (
              <QCol sx={{ width: '50%' }}>
                <QSelect
                  multiple
                  options={userSubGroupsOptions}
                  value={selectedSubgroups}
                  onChange={event => {
                    const { value } = event.target;
                    setSelectedSubgroups(
                      userSubGroupsOptions
                        .filter(option => value.includes(option.value))
                        .map(option => option.value),
                    );
                  }}
                  formControlProps={{ sx: { width: '100%' } }}
                  selectProps={{
                    sx: { height: '32px', fontSize: '14px' },
                  }}
                  selectIconProps={{ fontSize: 'medium' }}
                />
              </QCol>
            )}
            <QCol sx={{ width: '50%' }} flex="1">
              <QInput
                size="small"
                variant="outlined"
                id="external-id-input"
                placeholder={customizedEid || i18n.t('External ID')}
                onChange={e => setExternalId(e.target.value)}
                value={externalId}
              />
            </QCol>
          </QRow>
          {appiumScript && testScriptSubmissionPermission && binarySubmit && (
            <>
              <QRow style={{ marginBottom: '8px', marginTop: '16px' }}>
                <QCol>
                  <QTypography variant="h5Regular">
                    {i18n.t('Test Script Submission')}
                  </QTypography>
                </QCol>
              </QRow>
              <QRow columnGap="8px">
                <QCol sx={{ width: '25%' }}>
                  <QButton
                    variant="filled"
                    onClick={() => {
                      document.getElementById('scripts-file-input').click();
                    }}
                    style={{
                      color: '#000000',
                      backgroundColor: '#E0E0E0',
                      fontSize: '13px',
                      fontWeight: 700,
                      width: '100%',
                    }}
                  >
                    {i18n.t('Choose File')}
                  </QButton>
                </QCol>
                <QCol sx={{ width: '75%' }}>
                  <QInput
                    inputProps={{
                      type: 'file',
                      accept: '.py',
                      name: 'scripts',
                      'data-testid': 'script-files-input',
                    }}
                    onChange={e => processScriptFilesChange(e)}
                    inputRef={scriptFilesInputRef}
                    id="scripts-file-input"
                    style={{ display: 'none' }}
                  />
                  <QInput
                    placeholder="No file chosen"
                    sx={{ width: '100%', maxHeight: '32px' }}
                    value={scriptsFileName}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </QCol>
              </QRow>
            </>
          )}
        </QModalContent>
      }
      actions={
        <QModalActions>
          <QButton
            variant="outlined"
            onClick={toggle}
            sx={{ minWidth: '100px', marginRight: '24px' }}
          >
            {i18n.t('Cancel')}
          </QButton>
          <QButton
            variant="filled"
            onClick={() => {
              confirm(
                addToWatchlist,
                externalId,
                selectedSubgroups.join(','),
                scripts,
              );
            }}
            sx={{ minWidth: '100px' }}
          >
            {i18n.t('Submit')}
          </QButton>
        </QModalActions>
      }
    >
      {!mastV2 && (
        <>
          <QModalTitle toggle={toggle}>
            {i18n.t('Confirm Submission')}
          </QModalTitle>
          <QModalContent>
            <p className="lead">
              {i18n.t('Would you like to submit this application')}?
            </p>
            {app && (
              <QRow>
                <QCol xs="2">
                  <img src={app.icon} height="35px" alt="Icon" />
                </QCol>
                <QCol>
                  <QRow>{app.name}</QRow>
                  <QRow>
                    <i>{app.package}</i>
                  </QRow>
                </QCol>
              </QRow>
            )}
            {appInfo && <p>{appInfo}</p>}

            <hr />

            <QRow>
              <QCol>
                <h6>{i18n.t('Submission Settings')}</h6>
              </QCol>
            </QRow>

            <QRow>
              {canWatchlist && (
                <QCol>
                  <Switch
                    onChange={watchlistToggle}
                    value={addToWatchlist}
                    label={i18n.t('Add to watchlist')}
                    id="add-to-watchlist"
                  />
                </QCol>
              )}
            </QRow>
            <QRow>
              {userSubGroups.length > 0 && (
                <QCol xs="6">
                  <QLabel htmlFor="subgroups-selection">
                    Associated Subgroups
                  </QLabel>
                  <QSelect
                    isMulti
                    size="small"
                    options={userSubGroups.map(subGroup => ({
                      value: subGroup.id,
                      label: subGroup.name,
                    }))}
                    value={selectedSubgroups}
                    onChange={options => {
                      setSelectedSubgroups(options);
                    }}
                    label="Sub Groups"
                    formControlProps={{ sx: { width: '180px' } }}
                    selectIconProps={{ fontSize: 'medium' }}
                  />
                </QCol>
              )}
              <QCol xs="6">
                <QLabel htmlFor="external-id-input">
                  {customizedEid || i18n.t('External ID')}
                </QLabel>
                <QInput
                  id="external-id-input"
                  placeholder={customizedEid || i18n.t('External ID')}
                  type="text"
                  onChange={e => setExternalId(e.target.value)}
                  value={externalId}
                />
                <div>
                  <small>
                    <i>{i18n.t('Optional')}</i>
                  </small>
                </div>
              </QCol>
            </QRow>
            {appiumScript && testScriptSubmissionPermission && binarySubmit && (
              <QRow>
                <QCol>
                  <QRow style={{ marginBottom: '8px' }}>
                    <QCol>
                      <QTypography variant="h6Medium">
                        {i18n.t('Test Script Submission')}
                      </QTypography>
                    </QCol>
                  </QRow>
                  <QInput
                    type="file"
                    className="form-control"
                    id="script-files-input"
                    data-testid="script-files-input"
                    accept={['.py']}
                    name="script-files"
                    onChange={e => processScriptFilesChange(e)}
                    innerRef={scriptFilesInputRef}
                  />
                </QCol>
              </QRow>
            )}
          </QModalContent>
          <QModalActions>
            <QButton variant="outlined" color="secondary" onClick={toggle}>
              {i18n.t('Cancel')}
            </QButton>
            <QButton
              className="confirm-btn"
              color="primary"
              onClick={() => {
                confirm(
                  addToWatchlist,
                  externalId,
                  selectedSubgroups.map(option => option.value).join(','),
                  scripts,
                );
              }}
            >
              {i18n.t('Submit')}
            </QButton>
          </QModalActions>
        </>
      )}
    </QModal>
  );
}
