import React from 'react';
import { Row, Col, Badge, Card, CardHeader, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

export const Component = ({ rowData: component, type }) => {
  // Isolate each potential part of the component individually for
  // ease of updating/readability
  const Label = () =>
    component.label !== 'null' && component.label.trim() ? (
      <div>
        <strong>Label:</strong> {component.label}
      </div>
    ) : null;

  const Exported = () =>
    component.exported !== 'null' &&
    (component.exported === 'true' ? (
      <Badge color="warning">Exported</Badge>
    ) : (
      <Badge color="success">Exported = False</Badge>
    ));

  const IntentFilters = () =>
    component.intent_filters && component.intent_filters.length > 0 ? (
      <div>
        <strong>Intent Filters: </strong>
        {component.intent_filters.join(', ')}
      </div>
    ) : null;

  const Permission = () =>
    component.permission !== 'null' && (
      <div>
        <strong>Permission: </strong>
        {component.permission}
      </div>
    );

  const Authorities = () =>
    component.authorities && component.authorities !== 'null' ? (
      <div>
        <strong>Authorities:</strong> {component.authorities}
      </div>
    ) : null;

  const GrantUriPermissions = () => (
    <div>
      <strong>GrantUriPermissions: </strong> {component.grantUriPermissions}
    </div>
  );

  const ReadPermission = () =>
    component.readPermission !== 'null' && (
      <div>
        <strong>Read Permission: </strong>
        {component.readPermission}
      </div>
    );

  const WritePermission = () =>
    component.writePermission !== 'null' && (
      <div>
        <strong>Write Permission: </strong>
        {component.writePermission}
      </div>
    );

  return (
    <div>
      <div>{component.name}</div>
      <Label />
      <Exported />
      <IntentFilters />
      <Permission />
      {/* Provider specific fields */}
      {type === 'provider' && (
        <div>
          <Authorities />
          <GrantUriPermissions />
          <ReadPermission />
          <WritePermission />
        </div>
      )}
    </div>
  );
};

const filterActivities = (filterStr, rowData) => {
  if (filterStr === 'label') {
    return rowData.label !== 'null' && rowData.label.trim();
  }
  if (filterStr === 'exported') {
    return rowData.exported !== 'null';
  }

  return (
    rowData.label.toLowerCase().includes(filterStr) ||
    rowData.name.toLowerCase().includes(filterStr)
  );
};

export const ActivitiesCard = ({ nameFilter }) => {
  const results = useSelector(({ appResults }) => appResults.results);
  const resultsCopy = _.cloneDeep(results);

  let { activities } = _.get(
    resultsCopy,
    'analysis.analysis_details.metadata_analysis',
  );

  if (activities) {
    activities = Object.entries(activities).map(ele => {
      const [name, data] = ele;
      data.name = name;
      return data;
    });

    if (nameFilter) {
      activities = activities.filter(activity =>
        activity.name.match(nameFilter),
      );
    }

    return (
      <Card className="card-accent-warning-2">
        <CardHeader>{i18n.t('Activities')}</CardHeader>
        <CardBody>
          <DataTable
            striped
            data={activities}
            columns={[
              {
                keyName: 'name',
                label: i18n.t('Activity'),
                component: Component,
                filterable: true,
                filterFunc: filterActivities,
              },
            ]}
          />
        </CardBody>
      </Card>
    );
  }
  return null;
};

export const ServicesCard = () => {
  const results = useSelector(({ appResults }) => appResults.results);
  const resultsCopy = _.cloneDeep(results);

  let { services } = _.get(
    resultsCopy,
    'analysis.analysis_details.metadata_analysis',
  );

  if (services) {
    services = Object.entries(services).map(ele => {
      const [name, data] = ele;
      data.name = name;
      return data;
    });

    return (
      <Card className="card-accent-warning-2">
        <CardHeader>{i18n.t('Services')}</CardHeader>
        <CardBody>
          <DataTable
            striped
            data={services}
            columns={[
              {
                keyName: 'name',
                label: i18n.t('Service'),
                component: Component,
                filterable: true,
                filterFunc: filterActivities,
              },
            ]}
          />
        </CardBody>
      </Card>
    );
  }
  return null;
};

export const ProvidersCard = () => {
  const results = useSelector(({ appResults }) => appResults.results);
  const resultsCopy = _.cloneDeep(results);

  let { providers } = _.get(
    resultsCopy,
    'analysis.analysis_details.metadata_analysis',
  );

  if (providers) {
    providers = Object.entries(providers).map(ele => {
      const [name, data] = ele;
      data.name = name;
      return data;
    });

    return (
      <Card className="card-accent-warning-2">
        <CardHeader>{i18n.t('Providers')}</CardHeader>
        <CardBody>
          <DataTable
            striped
            data={providers}
            columns={[
              {
                keyName: 'name',
                label: i18n.t('Provider'),
                component: ({ rowData }) => (
                  <Component rowData={rowData} type="provider" />
                ),
                filterable: true,
                filterFunc: filterActivities,
              },
            ]}
          />
        </CardBody>
      </Card>
    );
  }
  return null;
};

export const ReceiversCard = () => {
  const results = useSelector(({ appResults }) => appResults.results);
  const resultsCopy = _.cloneDeep(results);

  let { receivers } = _.get(
    resultsCopy,
    'analysis.analysis_details.metadata_analysis',
  );

  if (receivers) {
    receivers = Object.entries(receivers).map(ele => {
      const [name, data] = ele;
      data.name = name;
      return data;
    });

    return (
      <Card className="card-accent-warning-2">
        <CardHeader>{i18n.t('Receivers')}</CardHeader>
        <CardBody>
          <DataTable
            striped
            data={receivers}
            columns={[{ keyName: 'name', label: i18n.t('Receivers') }]}
          />
        </CardBody>
      </Card>
    );
  }
  return null;
};

export const Components = () => (
  <Row>
    <Col xs="6">
      <ActivitiesCard />
    </Col>
    <Col xs="6">
      <ServicesCard />
    </Col>
    <Col xs="6">
      <ProvidersCard />
    </Col>
    <Col xs="6">
      <ReceiversCard />
    </Col>
  </Row>
);
