import React, { useState } from 'react';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QDropdown } from './Q-Components/QDropdown';
import { QDropdownInputLabel } from './Q-Components/QDropdownInputLabel';
import { QDropdownSelect } from './Q-Components/QDropdownSelect';
import { QDropdownSelectItem } from './Q-Components/QDropdownSelectItem';
import { QFormGroup } from './Q-Components/QFormGroup';
import { QInput } from './Q-Components/QInput';

export const SarifToolBar = ({
  aggregatedResults,
  setFilteredResults,
  isMaliciousReport = false,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { mastV2 } = useFlags();
  const [riskLevel, setRiskLevel] = useState(null);
  const [riskLevelOpen, setRiskLevelOpen] = useState(false);
  const [category, setCategory] = useState(null);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [compliance, setCompliance] = useState(null);
  const [complianceOpen, setComplianceOpen] = useState(false);

  const getRisks = aggregatedResults => {
    const risks = [];
    for (let i = 0; i < aggregatedResults.length; i++) {
      const currentRisk = aggregatedResults[i].risk;
      if (currentRisk) {
        risks.push({
          label: currentRisk?.charAt(0).toUpperCase() + currentRisk?.slice(1),
          value: currentRisk,
        });
      }
    }
    return risks;
  };

  const riskLevelOptions = [
    { label: 'All', value: 'all' },
    ..._.uniqBy(getRisks(aggregatedResults), 'label'),
  ];

  const getCategories = aggregatedResults => {
    const catgeories = [];
    for (let i = 0; i < aggregatedResults.length; i++) {
      const currentCategory = aggregatedResults[i].category;
      if (currentCategory) {
        catgeories.push({
          label:
            currentCategory?.charAt(0).toUpperCase() +
            currentCategory?.slice(1),
          value: currentCategory,
        });
      }
    }
    return catgeories;
  };

  const categoryOptions = [
    { label: 'All', value: 'all' },
    ..._.uniqBy(getCategories(aggregatedResults), 'label'),
  ];

  const complianceOptions = [
    { label: 'All', value: 'all' },
    { label: 'OWASP', value: 'owasp' },
    { label: 'NIAP', value: 'niap' },
    { label: 'GDPR', value: 'gdpr' },
  ];

  const filterByRiskLevel = option => {
    if (option.value === 'all') {
      return setFilteredResults(aggregatedResults);
    }
    return setFilteredResults(
      aggregatedResults.filter(result => result.risk === option.value),
    );
  };

  const filterByCategory = option => {
    if (option.value === 'all') {
      return setFilteredResults(aggregatedResults);
    }

    return setFilteredResults(
      aggregatedResults.filter(result => result.category === option.value),
    );
  };

  const filterByCompliance = option => {
    if (option.value === 'all') {
      return setFilteredResults(aggregatedResults);
    }

    return setFilteredResults(
      aggregatedResults.filter(
        result =>
          result?.compliance &&
          result.compliance[option.value]?.some(
            item => item.fail || item.kind === 'fail',
          ),
      ),
    );
  };

  const handleSearch = e => {
    if (e.charCode === 13) {
      e.preventDefault();

      setFilteredResults(
        aggregatedResults.filter(result =>
          result.name[0].toLowerCase().includes(searchQuery.toLowerCase()),
        ),
      );

      setSearchQuery('');
    }
  };

  return (
    <>
      <div
        className="d-flex justify-content-start"
        style={{ columnGap: '8px' }}
      >
        <div style={{ width: '200px' }}>
          {mastV2 ? (
            <QDropdown
              style={{
                width: '100%',
                paddingTop: '10px',
                paddingLeft: '0px',
              }}
            >
              <QDropdownInputLabel
                disableAnimation
                shrink={false}
                style={{
                  transform: 'translate(8px, 16px) scale(1)',
                }}
              >
                {riskLevel?.label || 'Risk Level'}
              </QDropdownInputLabel>
              <QDropdownSelect
                variant="outlined"
                value={riskLevel}
                onChange={event => {
                  setRiskLevel(event.target.value);
                  filterByRiskLevel(event.target.value);
                }}
                renderValue={() => {}}
                onOpen={() => setRiskLevelOpen(true)}
                onClose={() => setRiskLevelOpen(false)}
                open={riskLevelOpen}
                style={{ paddingTop: '0px', paddingBottom: '4.5px' }}
                data-testid="risk-level-dropdown"
              >
                <QDropdownSelectItem value="" style={{ display: 'none' }} />
                {riskLevelOptions.map(option => (
                  <QDropdownSelectItem
                    value={option}
                    key={option}
                    className={
                      riskLevel && riskLevel.value === option.value
                        ? 'hover'
                        : ''
                    }
                    data-testid="risk-level-item"
                  >
                    {option.label}
                  </QDropdownSelectItem>
                ))}
              </QDropdownSelect>
            </QDropdown>
          ) : (
            <QDropdownSelect
              className="mr-2"
              id="risk-level-select"
              placeholder="Risk Level"
              options={riskLevelOptions}
              onChange={filterByRiskLevel}
            />
          )}
        </div>

        <div style={{ width: '200px' }}>
          {mastV2 ? (
            <QDropdown
              style={{
                width: '100%',
                paddingTop: '10px',
                paddingLeft: '0px',
              }}
            >
              <QDropdownInputLabel
                disableAnimation
                shrink={false}
                style={{
                  transform: 'translate(8px, 16px) scale(1)',
                }}
              >
                {category?.label || 'Category'}
              </QDropdownInputLabel>
              <QDropdownSelect
                variant="outlined"
                value={category}
                onChange={event => {
                  setCategory(event.target.value);
                  filterByCategory(event.target.value);
                }}
                renderValue={() => {}}
                onOpen={() => setCategoryOpen(true)}
                onClose={() => setCategoryOpen(false)}
                open={categoryOpen}
                style={{ paddingTop: '0px', paddingBottom: '4.5px' }}
                data-testid="category-dropdown"
              >
                <QDropdownSelectItem value="" style={{ display: 'none' }} />
                {categoryOptions.map(option => (
                  <QDropdownSelectItem
                    value={option}
                    key={option}
                    className={
                      category && category.value === option.value ? 'hover' : ''
                    }
                  >
                    {option.label}
                  </QDropdownSelectItem>
                ))}
              </QDropdownSelect>
            </QDropdown>
          ) : (
            <QDropdownSelect
              className="mr-2"
              id="category-select"
              placeholder="Category"
              options={categoryOptions}
              onChange={filterByCategory}
            />
          )}
        </div>

        {!isMaliciousReport && (
          <div style={{ width: '200px' }}>
            {mastV2 ? (
              <QDropdown
                style={{
                  width: '100%',
                  paddingTop: '10px',
                  paddingLeft: '0px',
                }}
              >
                <QDropdownInputLabel
                  disableAnimation
                  shrink={false}
                  style={{
                    transform: 'translate(8px, 16px) scale(1)',
                  }}
                >
                  {compliance?.label || 'Compliance'}
                </QDropdownInputLabel>
                <QDropdownSelect
                  variant="outlined"
                  value={compliance}
                  onChange={event => {
                    setCompliance(event.target.value);
                    filterByCompliance(event.target.value);
                  }}
                  renderValue={() => {}}
                  onOpen={() => setComplianceOpen(true)}
                  onClose={() => setComplianceOpen(false)}
                  open={complianceOpen}
                  style={{ paddingTop: '0px', paddingBottom: '4.5px' }}
                  data-testid="compliance-filter"
                >
                  <QDropdownSelectItem value="" style={{ display: 'none' }} />
                  {complianceOptions.map(option => (
                    <QDropdownSelectItem
                      value={option}
                      key={option}
                      className={
                        compliance && compliance.value === option.value
                          ? 'hover'
                          : ''
                      }
                    >
                      {option.label}
                    </QDropdownSelectItem>
                  ))}
                </QDropdownSelect>
              </QDropdown>
            ) : (
              <QDropdownSelect
                className="mr-2"
                id="compliance-select"
                placeholder="Compliance"
                options={complianceOptions}
                onChange={filterByCompliance}
              />
            )}
          </div>
        )}

        {!mastV2 && (
          <QFormGroup>
            <QInput
              name="ior-search"
              id="ior-search"
              placeholder="Search"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              onKeyPress={handleSearch}
              inputProps={{
                style: {
                  fontSize: '14px',
                },
              }}
            />
          </QFormGroup>
        )}
      </div>
      {mastV2 && (
        <div style={{ margin: '16px 8px 8px 0px' }}>
          <QFormGroup>
            <QInput
              name="ior-search"
              id="ior-search"
              placeholder="Search"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              onKeyPress={handleSearch}
              inputProps={{
                style: {
                  fontSize: '14px',
                },
              }}
            />
          </QFormGroup>
        </div>
      )}
    </>
  );
};
