import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import { QButton } from './QButton';

const LDMenuButtonWithIcon = ({ icon, label, disabled }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <QButton
      variant="ghost"
      startIcon={icon}
      style={{ justifyContent: 'flex-start' }}
      disabled={disabled}
    >
      {label}
    </QButton>
  ) : (
    <>
      {icon}
      <span>{label}</span>
    </>
  );
};
export const QMenuButtonWithIcon = withLDConsumer()(LDMenuButtonWithIcon);
