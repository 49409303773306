import React from 'react';
import { Tooltip } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QTooltip as MuiTooltip } from '@kw/quokka-ui';

const LDTooltip = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiTooltip {...props}>{children}</MuiTooltip>
  ) : (
    <Tooltip {...props}>{children}</Tooltip>
  );
};
export const QTooltip = withLDConsumer()(LDTooltip);
