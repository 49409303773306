import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { QTable } from '../../Q-Components/QTable';
import { QTableRow } from '../../Q-Components/QTableRow';
import { QTableCell } from '../../Q-Components/QTableCell';
import { QTableBody } from '../../Q-Components/QTableBody';
import { QTypography } from '../../Q-Components/QTypography';
import { QLink } from '../../Q-Components/QLink';
import i18n from '../../../localization/i18n';

const MarketInfoTable = ({ results }) => {
  const marketInfo = results.market || results.analysis.store_info;

  return (
    <div>
      {_.isEmpty(marketInfo) ? (
        <span>{i18n.t('Not available')}</span>
      ) : (
        <QTable size="sm">
          <QTableBody>
            <QTableRow>
              <QTableCell>
                <p className="market-info-title">
                  <QTypography variant="bodyMedium">
                    {i18n.t('Category')}
                  </QTypography>
                </p>
                <span className="market-info-value">
                  <QTypography variant="bodyRegular">
                    {marketInfo.category}
                  </QTypography>
                </span>
              </QTableCell>
              <QTableCell>
                <p className="market-info-title">
                  <QTypography variant="bodyMedium">
                    {i18n.t('Last Updated')}
                  </QTypography>
                </p>
                <span className="market-info-value">
                  <QTypography variant="bodyRegular">
                    {marketInfo.date_published}
                  </QTypography>
                </span>
              </QTableCell>
            </QTableRow>
            <QTableRow>
              <QTableCell>
                <p className="market-info-title">
                  <QTypography variant="bodyMedium">
                    {i18n.t('Downloads')}
                  </QTypography>
                </p>
                <span className="market-info-value">
                  <QTypography variant="bodyRegular">
                    {marketInfo.downloads}
                  </QTypography>
                </span>
              </QTableCell>
              <QTableCell>
                <p className="market-info-title">
                  <QTypography variant="bodyMedium">
                    {i18n.t('Developer')}
                  </QTypography>
                </p>
                <span className="market-info-value">
                  <QTypography variant="bodyRegular">
                    {marketInfo.developer}
                  </QTypography>
                </span>
              </QTableCell>
            </QTableRow>
            <QTableRow>
              <QTableCell>
                <p className="market-info-title">
                  <QTypography variant="bodyMedium">
                    {i18n.t('Developer Email')}
                  </QTypography>
                </p>
                <span className="market-info-value">
                  <QTypography variant="bodyRegular">
                    {marketInfo.email}
                  </QTypography>
                </span>
              </QTableCell>
              <QTableCell>
                <p className="market-info-title">
                  <QTypography variant="bodyMedium">
                    {i18n.t('Developer Website')}
                  </QTypography>
                </p>
                <span className="market-info-value">
                  {marketInfo.dev_website ? (
                    <QLink href={marketInfo.dev_website} target="_blank">
                      Website
                    </QLink>
                  ) : (
                    <span>
                      <QTypography variant="bodyRegular">
                        {i18n.t('No website provided')}
                      </QTypography>
                    </span>
                  )}
                </span>
              </QTableCell>
            </QTableRow>
            <QTableRow>
              <QTableCell colSpan="2">
                <p className="market-info-title">
                  <QTypography variant="bodyMedium">
                    {i18n.t('Privacy Policy')}
                  </QTypography>
                </p>
                <span className="market-info-value">
                  {marketInfo.privacy_policy ? (
                    <QLink href={marketInfo.privacy_policy} target="_blank">
                      {i18n.t('Policy')}
                    </QLink>
                  ) : (
                    <span>
                      <QTypography variant="bodyRegular">
                        {i18n.t('No website provided')}
                      </QTypography>
                    </span>
                  )}
                </span>
              </QTableCell>
            </QTableRow>
          </QTableBody>
        </QTable>
      )}
    </div>
  );
};

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(MarketInfoTable);
