import React from 'react';
import { useSelector } from 'react-redux';
import { Badge } from 'reactstrap';
import { isPlainObject } from 'lodash';

import DataTable from '../DataTable/DataTable';

export default function HighLevelIssues({ name, showFilter }) {
  const highLevelIssues =
    useSelector(
      ({ appResults }) => appResults.results.analysis.high_level_issues.issues,
    ) || [];

  const issue = highLevelIssues.filter(issue => issue.name === name);

  return issue.length > 0 ? (
    <DataTable
      data={issue}
      columns={[
        {
          label: 'Name',
          keyName: 'name',
          component: ({ value }) => (
            <Badge color="primary" style={{ fontSize: '0.8rem' }}>
              {value}
            </Badge>
          ),
        },
        {
          label: 'Methods',
          keyName: 'methods',
          component: ({ value }) =>
            isPlainObject(value) ? (
              Object.entries(value).map(([key, val]) => (
                <div>
                  <strong>{key}: </strong>
                  <span>{val}</span>
                </div>
              ))
            ) : (
              <p>{value}</p>
            ),
        },
      ]}
      showFilter={showFilter}
    />
  ) : (
    <p>Not Available</p>
  );
}
