import React from 'react';
import { Col } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { setReportTab, setShowMap } from '../../../store/slices/emmAppSlice';
import i18n from '../../../localization/i18n';

export default function ReportTabs() {
  const { reportTab, userAccess } = useSelector(state => state.emmApp);
  const dispatch = useDispatch();

  function setActiveTab(tab) {
    if (tab !== reportTab) {
      dispatch(setReportTab(tab));

      // There's a bug with rendering the Leaflet map in the network info
      // section if we try to show it before we enter that tab. By waiting
      // a little bit after we open that tab it seems to be fixed
      if (tab === '3' || tab === '7') {
        setTimeout(() => {
          dispatch(setShowMap(true));
        }, 100);
      }
    }
  }

  return (
    <div className="report-tab-row">
      <Col
        className={`overview report-tab-ios${
          reportTab === '0' ? ' report-active-tab' : ''
        }`}
        onClick={() => setActiveTab('0')}
      >
        {i18n.t('Overview')}
      </Col>
      <Col
        className={`report-tab-ios${
          reportTab === '1' ? ' report-active-tab' : ''
        }`}
        onClick={() => setActiveTab('1')}
      >
        {i18n.t('Findings')}
      </Col>
      <Col
        className={`report-tab-ios${
          reportTab === '2' ? ' report-active-tab' : ''
        }`}
        onClick={() => setActiveTab('2')}
      >
        {i18n.t('Metadata')}
      </Col>
      <Col
        className={`report-tab-ios${
          reportTab === '3' ? ' report-active-tab' : ''
        }`}
        onClick={() => setActiveTab('3')}
      >
        {i18n.t('Network Info')}
      </Col>
      <Col
        className={`report-tab-ios${
          reportTab === '5' ? ' report-active-tab' : ''
        }`}
        onClick={() => setActiveTab('5')}
      >
        {i18n.t('Analysis Details')}
      </Col>
      {!!userAccess.taiwan_results && (
        <Col
          className={`report-tab-android${
            reportTab === '6' ? ' report-active-tab' : ''
          }`}
          onClick={() => setActiveTab('6')}
        >
          {i18n.t('Taiwan Government Regulations')}
        </Col>
      )}
      {!!userAccess.owasp && (
        <Col
          className={`report-tab-ios${
            reportTab === '7' ? ' report-active-tab' : ''
          }`}
          onClick={() => setActiveTab('7')}
        >
          {i18n.t('OWASP Details')}
        </Col>
      )}
      {!!userAccess.gdpr && (
        <Col
          className={`report-tab-ios${
            reportTab === '8' ? ' report-active-tab' : ''
          }`}
          onClick={() => setActiveTab('8')}
        >
          {i18n.t('GDPR Details')}
        </Col>
      )}
      {!!userAccess.niap && (
        <Col
          className={`report-tab-ios${
            reportTab === '9' ? ' report-active-tab' : ''
          }`}
          onClick={() => setActiveTab('9')}
        >
          NIAP Details
        </Col>
      )}
      {!!userAccess.sbom && (
        <Col
          className={`report-tab-ios${
            reportTab === '10' ? ' report-active-tab' : ''
          }`}
          onClick={() => setActiveTab('10')}
        >
          SBOM Details
        </Col>
      )}
    </div>
  );
}
