import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { InputAdornment } from '@mui/material';

import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';
import { invalidateAnalyzedAppsCache } from '../../store/slices/cache/analyzedAppsCacheSlice';
import { QModal } from '../Q-Components/QModal';
import { QModalContent } from '../Q-Components/QModalContent';
import { QModalActions } from '../Q-Components/QModalActions';
import { QModalTitle } from '../Q-Components/QModalTitle';
import { QSelect } from '../Q-Components/QSelect';
import { QLabel } from '../Q-Components/QLabel';
import { QTypography } from '../Q-Components/QTypography';
import { QCol } from '../Q-Components/QCol';
import { QButton } from '../Q-Components/QButton';
import { QAutocomplete } from '../Q-Components/QAutocomplete';
import { QInput } from '../Q-Components/QInput';
import { QIconButton } from '../Q-Components/QIconButton';

export const ChangeOwnershipModal = ({
  isOpen,
  toggle,
  analyzedAppId,
  analyzedAppUserId,
  getApps,
}) => {
  const { userAccess, userSubGroups, groupAdminUsers } = useSelector(
    state => state.emmApp,
  );
  const dispatch = useDispatch();
  const [ownerGroups, setOwnerGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const { mastV2 } = useFlags();

  const changeAppsOwner = async () => {
    try {
      let newOwnerIds = '';

      const newUserId = currentUser.value
        ? currentUser.value
        : analyzedAppUserId;

      for (let i = 0, len = ownerGroups.length; i < len; i++) {
        if (mastV2) {
          newOwnerIds += `${ownerGroups[i]},`;
        } else newOwnerIds += `${ownerGroups[i].value},`;
      }

      await axios.put(`analyzed-apps/${analyzedAppId}/subgroups`, {
        newOwnersGroupIds: newOwnerIds,
      });

      if (userAccess.group_admin) {
        await axios.put(`analyzed-apps/${analyzedAppId}/submitter`, {
          newUserId,
        });
      }

      dispatch(invalidateAnalyzedAppsCache());

      toastrHelper.showSuccessToast(
        i18n.t('Changed app ownership successfully'),
        null,
        mastV2,
      );
    } catch (err) {
      console.log('Error changing app ownership: ', err);
      toastrHelper.showErrorToast(
        i18n.t('Error changing app ownership'),
        null,
        mastV2,
      );
    }

    toggle();
    getApps();
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        const usersBuffer = groupAdminUsers.map(res => {
          if (analyzedAppUserId === res.id) {
            setCurrentUser({ value: res.id, label: res.email });
          }
          return { value: res.id, label: res.email };
        });

        setUsers(usersBuffer);
      } catch (err) {
        console.log('Error setting user:', err);
      }
    };

    if (userAccess.group_admin) {
      getUsers();
    }
  }, [analyzedAppUserId, groupAdminUsers, userAccess.group_admin]);

  const changeOwnerGroups = event => {
    if (mastV2) {
      const {
        target: { value },
      } = event;

      setOwnerGroups(
        userSubGroups
          .filter(option => value.includes(option.id))
          .map(option => option.id),
      );
    } else {
      setOwnerGroups(event);
    }
  };

  const changeOwnerUser = (event, selectedUser) => {
    if (mastV2) {
      const {
        target: { value },
      } = event;

      setCurrentUser(selectedUser);
    } else {
      setCurrentUser(event);
    }
  };

  const Title = () => (
    <QModalTitle>{i18n.t('Change App Ownership')}</QModalTitle>
  );

  const Content = () => (
    <QModalContent>
      <QCol rowGap="25px" display="flex" flexDirection="column">
        <QCol rowGap="15px" display="flex" flexDirection="column">
          <QLabel>
            <QTypography variant="bodyMedium">
              {i18n.t(
                "Which group would you like to reassign the app's ownership to?",
              )}
            </QTypography>
          </QLabel>
          {ownerGroups.length === 0 &&
            (mastV2 ? (
              <QTypography variant="bodyRegular" sx={{ color: '#F44336' }}>
                Please select at least one sub group
              </QTypography>
            ) : (
              <p className="text-danger">
                Please select at least one sub group
              </p>
            ))}
          <QSelect
            label="Sub Group"
            multiple
            reactMultiSelect
            formControlProps={{ sx: { width: '100%' } }}
            options={userSubGroups.map(group => ({
              value: group.id,
              label: group.name,
            }))}
            value={ownerGroups}
            onChange={changeOwnerGroups}
            labelledBy="Select"
          />
        </QCol>
        {userAccess.group_admin && (
          <QCol rowGap="15px" display="flex" flexDirection="column">
            <QLabel>
              <QTypography variant="bodyMedium">
                {i18n.t(
                  "Which user would you like to reassign the app's ownership to?",
                )}
              </QTypography>
            </QLabel>
            <QAutocomplete
              sx={{ width: '100%' }}
              label="User"
              value={mastV2 ? currentUser?.label : currentUser}
              options={users}
              onChange={changeOwnerUser}
              forcePopupIcon={false}
              disableClearable
              renderInput={params => (
                <QInput
                  outlinedWithTitle
                  sx={{ width: '100%' }}
                  {...params}
                  label="User"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {params.InputProps.endAdornment}
                        <QIconButton
                          sx={{ top: '10px', right: '10px', color: '#757575' }}
                        >
                          <ExpandMoreIcon />
                        </QIconButton>
                      </InputAdornment>
                    ),
                    inputProps: params.inputProps,
                    ref: params.InputProps.ref,
                  }}
                />
              )}
            />
          </QCol>
        )}
      </QCol>
    </QModalContent>
  );

  const Actions = () => (
    <QModalActions>
      <QButton variant="outlined" color="secondary" onClick={toggle}>
        {!mastV2 && <i className="fa-solid fa-xmark" />}
        &nbsp;{i18n.t('Cancel')}
      </QButton>
      <QButton
        variant="filled"
        className="confirm-modal-confirm-btn"
        color="primary"
        onClick={changeAppsOwner}
        disabled={ownerGroups.length === 0}
      >
        {!mastV2 && <i className="fa-regular fa-circle-check" />}
        &nbsp;{i18n.t('Confirm')}
      </QButton>
    </QModalActions>
  );

  return (
    <QModal
      open={isOpen}
      isOpen={isOpen}
      toggle={toggle}
      title={<Title />}
      content={<Content />}
      actions={<Actions />}
    >
      <Title />
      <Content />
      <Actions />
    </QModal>
  );
};
