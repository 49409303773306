import React from 'react';
import { Line } from 'react-chartjs-2';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import i18n from '../../localization/i18n';
import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';

const LDApiRequestsByDay = ({ isLoading, apiRequests }) => {
  const { mastV2 } = useFlags();

  const v1BackgroundColor = 'rgba(255, 255, 255, 0.2)';

  // When removing the mastV2 flag, consolidate these to a single variable since border and background color are the same in the new design.
  const binaryBorderColor = mastV2 ? '#56B09366' : 'rgba(0,0,0, 0.5)';
  const binaryBackgroundColor = mastV2 ? binaryBorderColor : v1BackgroundColor;

  const storeBorderColor = mastV2 ? '#244C5A' : 'rgba(255,0,0, 0.5)';
  const storeBackgroundColor = mastV2 ? storeBorderColor : v1BackgroundColor;

  const resultBorderColor = mastV2 ? '#3B3E4099' : 'rgba(0,0,255, 0.5)';
  const resultBackgroundColor = mastV2 ? resultBorderColor : v1BackgroundColor;

  const chartData = {
    labels: apiRequests.map(item => item.day),
    datasets: [
      {
        borderColor: binaryBorderColor,
        backgroundColor: binaryBackgroundColor,
        label: 'Binary Submit',
        data: apiRequests.map(item => item.binarySubmissionCount),
      },
      {
        borderColor: storeBorderColor,
        backgroundColor: storeBackgroundColor,
        label: 'Store Submit',
        data: apiRequests.map(item => item.storeSubmissionCount),
      },
      {
        borderColor: resultBorderColor,
        backgroundColor: resultBackgroundColor,
        label: 'Get Result',
        data: apiRequests.map(item => item.getResultsCount),
      },
    ],
  };
  return (
    <QCard className="card-accent-secondary">
      <QCardHeader
        title={i18n.t('API Requests')}
        action={
          <span>
            {isLoading && <i className="fa-solid fa-spinner fa-spin" />}
          </span>
        }
      >
        <div className="d-flex justify-between">
          <strong>{i18n.t('API Requests')}</strong>
          <span>
            {isLoading && <i className="fa-solid fa-spinner fa-spin" />}
          </span>
        </div>
      </QCardHeader>
      <QCardBody>
        <Line
          data={chartData}
          height={350}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                ...(mastV2 && {
                  align: 'start',
                  labels: {
                    usePointStyle: 'true',
                    pointStyle: 'circle',
                  },
                }),
              },
              title: {
                display: true,
                text: 'API requests for app submissions and getting results over time',
                position: 'bottom',
                font: {
                  weight: 'normal',
                  style: 'italic',
                },
              },
            },
            elements: {
              line: {
                fill: 'false',
                ...(mastV2 && { tension: 0, borderWidth: 2 }),
              },
              point: {
                ...(mastV2 && { radius: 0, hitRadius: 10 }),
              },
            },
          }}
        />
      </QCardBody>
    </QCard>
  );
};

const ApiRequestsByDay = withLDConsumer()(LDApiRequestsByDay);

export default ApiRequestsByDay;
