import React, { Component } from 'react';

import PermissionsRequestedTable from '../../../Android/PermissionsRequestedTable';
import TabsView from '../../../../TabsView';

export default class HasInAppPurchases extends Component {
  render() {
    const tabs = [
      {
        title: 'Permission Requested',
        tabContent: () => <PermissionsRequestedTable filter="BILLING" />,
      },
    ];
    return <TabsView tabs={tabs} />;
  }
}
