import kaiService from '../../services/kai';
import { setKaiUsers, setKaiGroups } from '../slices/kaiSlice';

export const fetchKaiUsers = () => async dispatch => {
  const users = await kaiService.getKaiUsers();
  dispatch(setKaiUsers(users));
  return users;
};

export const fetchKaiGroups = () => async dispatch => {
  const groups = await kaiService.getKaiGroups();
  dispatch(setKaiGroups(groups));
  return groups;
};
