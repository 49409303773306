import React from 'react';
import { connect } from 'react-redux';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

const StaticAnalysisFunctionsDatatable = ({ results }) => {
  const staticFunctions =
    results.analysis.analysis_details.static_analysis.xrefs;

  if (!staticFunctions) {
    return null;
  }

  const staticFunctionsData = Object.entries(staticFunctions).map(
    ([key, value]) => ({
      method: key,
      apiUrl: value.api_url,
      details: Object.keys(value.for_binary) || [],
    }),
  );

  return (
    <div>
      {!staticFunctionsData || staticFunctionsData.length === 0 ? (
        <div>{i18n.t('No functions detected')}</div>
      ) : (
        <DataTable
          data={staticFunctionsData}
          columns={[
            {
              keyName: 'method',
              label: i18n.t('Method'),
              filterable: true,
              component: ({ rowData }) => (
                <a href={rowData.apiUrl} target="_blank">
                  {rowData.method}
                </a>
              ),
            },
            {
              keyName: 'details',
              label: i18n.t('Details'),
              component: ({ rowData }) =>
                rowData.details.map(data => <li>{data}</li>),
            },
          ]}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(StaticAnalysisFunctionsDatatable);
