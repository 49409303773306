import React from 'react';

import DynamicAnalysisDatatable from '../../Android/DynamicAnalysisDatatable';
import { NSUserDefaultsOperations } from '../../iOS/NSUserDefaultsOperations';

export const V2_3 = ({ platform }) =>
  platform === 'android' ? (
    <DynamicAnalysisDatatable
      onlySelectedCategory={['Logging', 'Reflection']}
    />
  ) : (
    <NSUserDefaultsOperations nameFilter="Logging" />
  );
