import React from 'react';
import { CardText } from 'reactstrap';

import { Harviewer } from '../../../Harviewer/Harviewer';

export const V4_7 = () => (
  <>
    <CardText>
      <strong>Network Traffic</strong>
    </CardText>
    <Harviewer />
  </>
);
