import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CloseIcon from '@mui/icons-material/Close';

import util from '../logic/util';

import { QModal } from './Q-Components/QModal';
import { QModalTitle } from './Q-Components/QModalTitle';
import { QModalContent } from './Q-Components/QModalContent';
import { QModalActions } from './Q-Components/QModalActions';
import { QButton } from './Q-Components/QButton';
import { QAlert } from './Q-Components/QAlert';
import { QRow } from './Q-Components/QRow';
import { QCol } from './Q-Components/QCol';
import { QTypography } from './Q-Components/QTypography';
import { QLink } from './Q-Components/QLink';

const Title = ({ mastV2, warning, expired, reachLimit, toggle }) => (
  <QModalTitle onClose={toggle} closeIcon={<CloseIcon />}>
    {!mastV2 && <i className="fa-solid fa-circle-info mr-2" />}

    <QTypography variant="h3Medium">
      {warning && !expired && !reachLimit && 'License Notice'}
      {expired && !reachLimit && 'License Expired'}
      {reachLimit && !expired && 'Limit Reached'}
      {reachLimit && expired && 'License Expired &amp; Limit Reached'}
    </QTypography>
  </QModalTitle>
);
const Body = ({
  warning,
  remainingApps,
  remainingDays,
  reachLimit,
  expired,
}) => (
  <QModalContent>
    {warning && remainingDays && !expired && !reachLimit && (
      <QTypography component="p" variant="body">
        <p>License expires in {Math.ceil(remainingDays)} days</p>
      </QTypography>
    )}
    {warning && remainingApps && !expired && !reachLimit && (
      <QTypography component="p" variant="body">
        <p>Unique apps remaining: {remainingApps}</p>
      </QTypography>
    )}
    {expired && <p>License Expired.</p>}
    {reachLimit && (
      <div>
        <QTypography component="p" variant="body">
          <p>
            Unique Apps Limit Reached: Your Quokka account is licensed by the
            amount of unique applications that you scan per year. This is
            tracked using the package name or bundleID of your applications
            (e.g. com.quokka.exampleapp). You may continue scanning your
            licensed applications but you will no longer be able to add new
            unique applications.
          </p>
        </QTypography>
        <QTypography component="p" variant="body">
          <p>
            In some cases you may use multiple different package names or
            bundleIDs for one unique application (e.g. com.quokka.exampleapp.dev
            &amp; com.quokka.exampleapp.prod are the development and production
            versions of the same app), if this applies to you please use the{' '}
            <QLink href={util.zendeskUrls[process.env.REACT_APP_APPLIANCE]}>
              Support Ticketing
            </QLink>{' '}
            function to alert our team of the App and package names or bundleIDs
            that apply to it. We will update your account to ensure you are not
            using multiple licenses for one unique app.
          </p>
        </QTypography>
        <QTypography component="p" variant="body">
          <p>
            If you believe you are receiving the message in error please contact
            the Quokka Support team through the Support Ticketing menu. If you’d
            like to purchase additional licenses, please reach out to your
            Quokka account representative or{' '}
            <a href="mailto:sales@kryptowire.com">sales@kryptowire.com</a>
          </p>
        </QTypography>
      </div>
    )}
  </QModalContent>
);
const Actions = ({ toggle }) => (
  <QModalActions>
    <QButton color="primary" variant="filled" onClick={() => toggle()}>
      Confirm
    </QButton>
  </QModalActions>
);

const LicenseAlert = ({ mastV2, message, severity = 'info' }) => {
  const icon =
    severity === 'warning' ? 'fa-rectangle-xmark' : 'fa-exclamation-circle';

  return (
    <QRow sx={{ mb: 2 }}>
      <QCol sx={{ width: '100%' }}>
        <QAlert variant="outlined" color={severity} severity={severity}>
          <QTypography component="h6" variant="h6Bold">
            {!mastV2 ? (
              <span>
                <h6>
                  <i className={`fa-solid ${icon} mr-2`} />
                  <strong>License Warning</strong>
                </h6>
              </span>
            ) : (
              'License Warning'
            )}
          </QTypography>
          <QTypography component="p" variant="h6">
            <p>{message}</p>
          </QTypography>
        </QAlert>
      </QCol>
    </QRow>
  );
};

export default function LicenseWarning() {
  const license = useSelector(state => state.emmApp.license);

  const [open, setOpen] = useState(false);

  const { mastV2 } = useFlags();

  const {
    expired,
    reachLimit,
    warning,
    remainingApps,
    remainingDays,
    maxGroupUsers,
  } = license;

  const groupAdminUsers = useSelector(({ emmApp }) => emmApp.groupAdminUsers);

  const currentUsers = groupAdminUsers.length;

  useEffect(() => {
    setOpen(warning);
  }, [license, warning]);

  if (!license) {
    return null;
  }

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div>
      <QModal
        isOpen={open}
        open={open}
        toggle={toggle}
        title={
          <Title
            mastV2={mastV2}
            warning={warning}
            expired={expired}
            reachLimit={reachLimit}
            toggle={toggle}
          />
        }
        content={
          <Body
            warning={warning}
            expired={expired}
            reachLimit={reachLimit}
            remainingApps={remainingApps}
            remainingDays={remainingDays}
          />
        }
        actions={<Actions toggle={toggle} />}
      >
        <Title
          mastV2={mastV2}
          warning={warning}
          expired={expired}
          reachLimit={reachLimit}
          toggle={toggle}
        />
        <Body
          warning={warning}
          expired={expired}
          reachLimit={reachLimit}
          remainingApps={remainingApps}
          remainingDays={remainingDays}
        />
        <Actions toggle={toggle} />
      </QModal>

      {expired && (
        <LicenseAlert
          mastV2={mastV2}
          message="License Expired"
          severity="warning"
        />
      )}

      {reachLimit && (
        <LicenseAlert
          mastV2={mastV2}
          message="Unique Apps Limit Reached"
          severity="warning"
        />
      )}

      {warning && !expired && !reachLimit && remainingDays < 30 && (
        <LicenseAlert
          mastV2={mastV2}
          message={`License expires in ${Math.ceil(remainingDays)} days`}
        />
      )}

      {warning && !expired && !reachLimit && remainingApps < 10 && (
        <LicenseAlert
          mastV2={mastV2}
          message={`Unique apps remaining: ${remainingApps}`}
        />
      )}

      {currentUsers >= maxGroupUsers && (
        <LicenseAlert
          mastV2={mastV2}
          message="Maximum number of users has been reached. If you’d like to add additional users you may remove existing user accounts or reach out to your Quokka contact to expand the number of user licenses."
        />
      )}
    </div>
  );
}
