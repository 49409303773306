import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import i18n from '../../localization/i18n';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QContainer } from '../Q-Components/QContainer';
import { QTable } from '../Q-Components/QTable';
import { QTableHead } from '../Q-Components/QTableHead';
import { QTableRow } from '../Q-Components/QTableRow';
import { QTableBody } from '../Q-Components/QTableBody';
import { QTableCell } from '../Q-Components/QTableCell';
import AsyncTablePagination from '../AsyncTablePagination';
import Loading from '../Loading';

import TableFilter from './TableFilter';

class QDataTable extends Component {
  constructor(props) {
    super(props);

    // Initialize the data to be displayed in the page
    const { rowsPerPage } = this.props;
    const currentData = props.data.slice(0, rowsPerPage);
    const maxPages = Math.ceil(props.data.length / rowsPerPage);

    // Check the columns provided by the parent and see if any of them are filterable
    const filterableKeys = this.props.columns
      .filter(column => column.filterable)
      .map(column => column.keyName);

    // Save all custom filter functions for columns
    const filterFunctions = {};
    this.props.columns
      .filter(column => column.filterFunc)
      .forEach(column => {
        filterFunctions[column.keyName] = column.filterFunc;
      });

    // Save all custom sort functions for columns
    const sortFunctions = {};
    this.props.columns
      .filter(column => column.sortFunc)
      .forEach(column => {
        sortFunctions[column.keyName] = column.sortFunc;
      });

    this.state = {
      page: 1,
      maxPages,
      rowsPerPage,

      filterableKeys,
      filterFunctions,

      currentData,
      filteredData: props.data,

      currentSortColumn: '',
      sortAscending: true,
      sortFunctions,
    };

    this.getPage = this.getPage.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.changeFilterFunc = this.changeFilterFunc.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
  }

  componentDidMount() {
    let { filter } = this.props;
    if (filter === undefined) {
      filter = '';
    }
    this.changeFilterFunc(filter.toLowerCase());
  }

  componentWillReceiveProps(nextProps) {
    // Set the data to whatever we receive in the props
    // This is used to handle cases where the data being passed
    // through props is modified by the parent component
    this.setState({ filteredData: nextProps.data }, () => {
      // Remove the current filter (if any) to get a clean state
      // after the parent data has been changed
      this.changeFilter({ target: { value: '' } });
    });
  }

  handlePageSizeChange(option) {
    this.setState(
      {
        rowsPerPage: option.value ? option.value : option,
      },
      () => {
        this.setState({
          currentData: this.state.filteredData.slice(0, this.state.rowsPerPage),
        });
        this.setState({
          maxPages: Math.ceil(
            this.state.filteredData.length / this.state.rowsPerPage,
          ),
        });
      },
    );
  }

  // Get the new proper page to display from the data
  getPage(pageNum, e) {
    if (e) e.preventDefault();
    // On a button click (when we have the 'e' object) and the page
    // is the same as our current page just return since we're already on that page
    if (e && pageNum === this.state.page) return;

    this.setState({
      currentData: this.state.filteredData.slice(
        (pageNum - 1) * this.state.rowsPerPage,
        pageNum * this.state.rowsPerPage,
      ),

      page: pageNum,
    });
  }

  // Change the table's filter
  changeFilter(e) {
    const filterStr = e.target.value.toLowerCase();

    this.changeFilterFunc(filterStr);
  }

  changeFilterFunc(filterStr) {
    // Filter the data based on the filterStr
    let newFilteredData;
    if (filterStr === '') {
      newFilteredData = this.props.data;
    } else {
      newFilteredData = this.props.data.filter(item => {
        for (const keyName of this.state.filterableKeys) {
          // Check if there's a custom filter function for the column
          /* istanbul ignore else */
          if (keyName in this.state.filterFunctions) {
            return this.state.filterFunctions[keyName](filterStr, item);
          }

          // Check if the item actually has the keyName. There can be
          // the case where the column doesn't have a value (is null)
          /* istanbul ignore else */
          if (
            item[keyName] &&
            item[keyName].toString().toLowerCase().includes(filterStr)
          ) {
            return true;
          }
        }

        return false;
      });
    }

    // Calculate the new max pages value
    let maxPages = Math.ceil(newFilteredData.length / this.state.rowsPerPage);
    if (maxPages === 0) maxPages = 1;

    // Check to see if the current page we're on is past the new max page limit
    // If so, just change the page to the new max pages and then get the pages
    // after the state change
    let newPage;
    if (this.state.page > maxPages) {
      newPage = maxPages;
    } else {
      newPage = this.state.page;
    }

    this.setState(
      {
        filteredData: newFilteredData,
        maxPages,
        page: newPage,
      },
      () => this.getPage(newPage),
    );
  }

  // Change the current column the table is sorted by and take
  // care of actually sorting the table data
  changeSorting(columnToSortBy) {
    // Check to see if we're already sorting by the selected column
    // If so, we'll switch the sort direction
    let newSortAscending = this.state.sortAscending;
    if (columnToSortBy === this.state.currentSortColumn) {
      newSortAscending = !this.state.sortAscending;
    }

    // Sort by the new column
    const newFilteredData = this.state.filteredData;

    this.setState(
      {
        currentSortColumn: columnToSortBy,
        filteredData: newFilteredData.slice().sort((a, b) => {
          // Check if there's a custom filter function for the column
          /* istanbul ignore else */
          if (columnToSortBy in this.state.sortFunctions) {
            return this.state.sortFunctions[columnToSortBy](a, b);
          }

          if (a[columnToSortBy] > b[columnToSortBy]) {
            if (newSortAscending) return 1;
            return -1;
          }
          if (a[columnToSortBy] < b[columnToSortBy]) {
            if (newSortAscending) return -1;
            return 1;
          }
          return 0;
        }),
        sortAscending: newSortAscending,
      },
      () => this.getPage(this.state.page),
    );
  }

  render() {
    const {
      columns,
      tableSize,
      filterEnterEvent,
      filterable,
      rowComponent,
      filter,
      showFilter = true,
      hideOverflow,
      showBottomPagination,
      isLoading,
    } = this.props;

    const { currentData, page, maxPages, rowsPerPage } = this.state;

    const pageSizeOptions = [
      { value: 10, label: '10' },
      { value: 15, label: '15' },
      { value: 25, label: '25' },
      { value: 50, label: '50' },
      { value: 100, label: '100' },
    ];

    return (
      <QContainer
        fluid
        style={{ padding: 4, background: 'none', minWidth: '100%' }}
      >
        <QRow sx={{ mb: '24px' }}>
          {filterable && showFilter && (
            <QCol xs="4">
              <TableFilter
                className="pull-left mt-3 mb-2"
                changeFilter={this.changeFilter}
                onKeyPress={filterEnterEvent ? this.handleKeyPress : undefined}
                defaultValue={filter}
              />
            </QCol>
          )}
        </QRow>
        {this.props.data.length !== 0 && (
          <AsyncTablePagination
            page={page}
            pagination={{
              totalData: this.props.data.length,
              lastPage: maxPages,
              dataPerPage: rowsPerPage,
            }}
            getPage={this.getPage}
            showRowsPerPage
            currentRows={currentData.length}
            handlePageSizeChange={this.handlePageSizeChange}
            pageSize={{ value: rowsPerPage, label: rowsPerPage.toString() }}
            pageSizeOptions={pageSizeOptions}
          />
        )}
        <QRow>
          <QCol flex="1">
            {isLoading ? (
              <Loading />
            ) : currentData.length === 0 ? (
              <div style={{ padding: 8 }}>{i18n.t('No data available')}</div>
            ) : (
              <QTable size={tableSize} striped={this.props.striped}>
                <QTableHead>
                  <QTableRow>
                    {columns.map(
                      column =>
                        column.label && (
                          <QTableCell
                            headerCell
                            variant="head"
                            key={JSON.stringify(column)}
                            onClick={
                              column.sortable
                                ? () => this.changeSorting(column.keyName)
                                : null
                            }
                            className={classNames(
                              column.sortable ? 'pointer' : '',
                              column.className,
                            )}
                            width={column.width ? column.width : null}
                          >
                            <span>
                              {`${column.label} `}

                              {column.tooltip && (
                                <>
                                  <i
                                    id={column.label}
                                    className="fa-solid fa-circle-question"
                                  />
                                  <UncontrolledTooltip
                                    placement="right"
                                    target={column.label}
                                  >
                                    {column.tooltip}
                                  </UncontrolledTooltip>
                                </>
                              )}
                            </span>
                            {/* If the column is being sorted by, display either /*}
                          {/* a ascending or descending icon */}
                            {this.state.currentSortColumn === column.keyName ? (
                              this.state.sortAscending ? (
                                <i className={this.props.sortAscIcon} />
                              ) : (
                                <i className={this.props.sortDescIcon} />
                              )
                            ) : null}
                          </QTableCell>
                        ),
                    )}
                  </QTableRow>
                </QTableHead>
                <QTableBody>
                  {currentData.map((row, rowIndex) => {
                    let key;
                    if (this.props.rowKey) {
                      key = this.props.rowKey(row);
                    } else {
                      key = rowIndex;
                    }

                    // Render the custom row component if provided
                    if (rowComponent) {
                      const CustomRowComponent = rowComponent;
                      return <CustomRowComponent key={rowIndex} row={row} />;
                    }

                    return (
                      <QTableRow
                        key={key}
                        onClick={
                          this.props.onRowClick
                            ? () => this.props.onRowClick(row)
                            : null
                        }
                        className={classNames(this.props.rowClassName, {
                          pointer: this.props.onRowClick,
                        })}
                      >
                        {columns.map(column => {
                          // Create the classNames for the column
                          const cNames = classNames({
                            [column.className]: column.className
                              ? column.className
                              : null,
                            pointer: column.onClick,
                            'dont-break-out': true,
                          });

                          // Check to see if there is a custom component for the column
                          if (column.component) {
                            const Custom = column.component;
                            // Check to see if we should pass the whole row as the value
                            let value;
                            if (!column.keyName) value = row;
                            else value = row[column.keyName];

                            return (
                              <QTableCell
                                key={JSON.stringify(column)}
                                onClick={
                                  column.onClick
                                    ? () => column.onClick(row)
                                    : null
                                }
                                className={cNames}
                                sx={{ color: '#757575', fontWeight: '600' }}
                              >
                                <div
                                  style={
                                    hideOverflow
                                      ? {
                                          height: '100px',
                                          overflow: 'hidden',
                                        }
                                      : {}
                                  }
                                >
                                  {column.columnLink ? (
                                    <Link
                                      to={column.columnLink(row)}
                                      style={{
                                        textDecoration: 'none',
                                        color: 'inherit',
                                      }}
                                    >
                                      <Custom
                                        rowData={row}
                                        value={value}
                                        style={{
                                          backgroundColor: '#000000',
                                        }}
                                      />
                                    </Link>
                                  ) : (
                                    <Custom
                                      rowData={row}
                                      value={value}
                                      style={{
                                        backgroundColor: '#000000',
                                      }}
                                    />
                                  )}
                                </div>
                              </QTableCell>
                            );
                          }
                          return Object.keys(column).length === 0 ? null : (
                            <QTableCell
                              key={JSON.stringify(column)}
                              onClick={
                                column.onClick
                                  ? () => column.onClick(row)
                                  : null
                              }
                              className={cNames}
                            >
                              <div
                                style={
                                  hideOverflow
                                    ? {
                                        height: '100px',
                                        overflow: 'hidden',
                                      }
                                    : {}
                                }
                              >
                                {column.columnLink ? (
                                  <Link
                                    to={column.columnLink(row)}
                                    style={{
                                      textDecoration: 'none',
                                      color: 'inherit',
                                    }}
                                  >
                                    {row[column.keyName]}
                                  </Link>
                                ) : (
                                  row[column.keyName]
                                )}
                              </div>
                            </QTableCell>
                          );
                        })}
                      </QTableRow>
                    );
                  })}
                </QTableBody>
              </QTable>
            )}
          </QCol>
        </QRow>
        {showBottomPagination && this.props.data.length !== 0 && (
          <AsyncTablePagination
            page={page}
            pagination={{
              totalData: this.props.data.length,
              lastPage: maxPages,
              dataPerPage: rowsPerPage,
            }}
            getPage={this.getPage}
            showRowsPerPage
            currentRows={currentData.length}
            handlePageSizeChange={this.handlePageSizeChange}
            pageSize={{ value: rowsPerPage, label: rowsPerPage.toString() }}
            pageSizeOptions={pageSizeOptions}
          />
        )}
      </QContainer>
    );
  }
}

QDataTable.defaultProps = {
  isLoading: false,
  tableSize: 'md',
  filterEnterEvent: false,
  sortAscIcon: 'fa-solid fa-arrow-up-long',
  sortDescIcon: 'fa-solid fa-arrow-down-long',
  filterable: true,
  rowsPerPage: 10,
  showPageSize: false,
  showBottomPagination: false,
};

export default QDataTable;
