import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import HelpButton from '../HelpButton';
import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QFormGroup } from '../Q-Components/QFormGroup';
import { QInput } from '../Q-Components/QInput';
import { QLabel } from '../Q-Components/QLabel';
import { QTypography } from '../Q-Components/QTypography';
import {
  setAppUsername,
  setAppPassword,
  setAppCustomCredential,
} from '../../store/slices/appSubmitSlice';

const useUpdateCustomCreds = () => {
  const dispatch = useDispatch();
  const updateUsername = username => {
    dispatch(setAppUsername(username));
  };

  const updatePassword = password => {
    dispatch(setAppPassword(password));
  };

  const updateCustomCredential = customCredential => {
    dispatch(setAppCustomCredential(customCredential));
  };

  return {
    updateUsername,
    updatePassword,
    updateCustomCredential,
  };
};

const CustomCredentials = ({
  appUsername,
  appPassword,
  appCustomCredential,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { updateUsername, updatePassword, updateCustomCredential } =
    useUpdateCustomCreds();
  const { mastV2 } = useFlags();

  return (
    <div>
      <QCard className="card-accent-danger">
        <QCardHeader
          title={
            <QTypography variant="h4Bold" color="#000000">
              User Credentials
            </QTypography>
          }
          action={<HelpButton helpItem="customUserCredentials" />}
        >
          <strong>User Credentials </strong>
          <HelpButton helpItem="customUserCredentials" />
        </QCardHeader>
        <QCardBody
          style={
            mastV2
              ? { display: 'flex', flexDirection: 'column', rowGap: '24px' }
              : {}
          }
        >
          <QFormGroup>
            {!mastV2 && (
              <QLabel for="custom-username-input">Username/Email</QLabel>
            )}
            <QInput
              label={
                <QTypography variant="h5Medium">Username/Email</QTypography>
              }
              id="custom-username-input"
              type="text"
              value={appUsername}
              onChange={e => updateUsername(e.target.value)}
              autoComplete="new-password"
              data-lpignore="true"
              data-testid="test-custom-username-input"
            />
          </QFormGroup>
          <QFormGroup>
            {!mastV2 && (
              <span>
                <QLabel for="custom-password-input">Password</QLabel>
                <i
                  className={
                    showPassword
                      ? 'fa-solid fa-eye pointer ml-2'
                      : 'fa-solid fa-eye-slash pointer ml-2'
                  }
                  onClick={() => setShowPassword(!showPassword)}
                />
              </span>
            )}
            <QInput
              label={
                <QTypography variant="h5Medium">
                  {' '}
                  Password
                  <i
                    className={
                      showPassword
                        ? 'fa-solid fa-eye pointer ml-2'
                        : 'fa-solid fa-eye-slash pointer ml-2'
                    }
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </QTypography>
              }
              id="custom-password-input"
              type={showPassword ? 'text' : 'password'}
              value={appPassword}
              onChange={e => updatePassword(e.target.value)}
              autoComplete="new-password"
              data-lpignore="true"
              data-testid="test-custom-password-input"
            />
          </QFormGroup>
          <QFormGroup>
            {!mastV2 && (
              <QLabel for="custom-credentials-input">Custom Credential</QLabel>
            )}
            <QInput
              label={
                <QTypography variant="h5Medium">Custom Credential</QTypography>
              }
              id="custom-credentials-input"
              type="text"
              value={appCustomCredential}
              onChange={e => updateCustomCredential(e.target.value)}
              autoComplete="new-password"
              data-lpignore="true"
              data-testid="test-custom-credentials-input"
            />
          </QFormGroup>
        </QCardBody>
      </QCard>
    </div>
  );
};

const mapStateToProps = state => ({
  appUsername: state.appSubmit.appUsername,
  appPassword: state.appSubmit.appPassword,
  appCustomCredential: state.appSubmit.appCustomCredential,
});

export default connect(mapStateToProps)(CustomCredentials);
