import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ExpandMore } from '@mui/icons-material';
import { withLDConsumer, useFlags } from 'launchdarkly-react-client-sdk';

import { ModifyEidModal } from '../AnalyzedApps/ModifyEidModal';
import i18n from '../../localization/i18n';
import { QUncontrolledButtonDropdown } from '../Q-Components/QUncontrolledButtonDropdown';
import { QButton } from '../Q-Components/QButton';
import { QDropdownSelectItem } from '../Q-Components/QDropdownSelectItem';
import { QDropdownToggle } from '../Q-Components/QDropdownToggle';
import { QDropdownMenu } from '../Q-Components/QDropdownMenu';
import { QMenuButtonWithIcon } from '../Q-Components/QMenuButtonWithIcon';
import { QActionSelect } from '../Q-Components/QActionSelect';

import BatchAppNote from './BatchAppNote';
import BatchChangeReviewStatus from './BatchChangeReviewStatus';

function SummarizedAppActions({
  app,
  setShowChangeOwnershipModal,
  getApps,
  analyzedAppIds,
  lastAnalyzedAppId,
}) {
  const { mastV2 } = useFlags();
  const { userAccess } = useSelector(state => state.emmApp);

  const [noteOpen, setNoteOpen] = useState(false);
  const [ownerOpen, setOwnerOpen] = useState(false);
  const [eidOpen, setEidOpen] = useState(false);
  const handleFocus = event => {
    event.target.style.background = 'none';
  };

  const appActionOptions = [
    ...(userAccess.change_sub_group_ownership_of_apps
      ? [
          {
            value: 'changeOwnership',
            label: i18n.t('Change App Ownership'),
            icon: <i className="fa-regular fa-user purple-icon" />,
            onClick: () => setShowChangeOwnershipModal(true),
          },
        ]
      : []),
    ...(userAccess.change_app_note
      ? [
          {
            value: 'appNote',
            label: i18n.t('App Note'),
            icon: <i className="fa-regular fa-sticky-note warning-icon" />,
            onClick: () => setNoteOpen(true),
          },
        ]
      : []),
    {
      value: 'changeReviewStatus',
      label: i18n.t('Change Review Status'),
      icon: (
        <i className="fa-regular fa-square-arrow-right fa-lg primary-icon" />
      ),
      onClick: () => setOwnerOpen(true),
    },
    ...(userAccess.change_app_external_id
      ? [
          {
            value: 'modifyEid',
            label: i18n.t('Modify EID'),
            icon: <i className="fa-regular fa-id-card success-icon" />,
            onClick: () => setEidOpen(true),
          },
        ]
      : []),
  ];

  return (
    <>
      <BatchAppNote
        note={app.app_note}
        analyzedAppIds={analyzedAppIds}
        lastAnalyzedAppId={lastAnalyzedAppId}
        getApps={getApps}
        toggle={() => setNoteOpen(!setNoteOpen)}
        open={noteOpen}
      />

      <BatchChangeReviewStatus
        analyzedAppIds={analyzedAppIds}
        reviewStatus={app.review_status}
        lastAnalyzedAppId={lastAnalyzedAppId}
        note={app.app_note}
        getApps={getApps}
        toggle={() => setOwnerOpen(!ownerOpen)}
        open={ownerOpen}
      />

      <ModifyEidModal
        isOpen={eidOpen}
        toggle={() => setEidOpen(!eidOpen)}
        initEid={app.analyzed_apps[0].external_id}
        getApps={getApps}
        analyzedAppId={app.analyzed_apps[0].id}
      />

      <div
        style={{ justifyContent: 'center', width: '100%' }}
        className="analyzed-apps-reports-actions"
      >
        {mastV2 ? (
          <QActionSelect actionOptions={appActionOptions} />
        ) : (
          <QUncontrolledButtonDropdown
            variant="filled"
            className="mx-1"
            title="Actions"
            style={{
              paddingTop: '0px',
              paddingBottom: '0px',
            }}
            sx={{ width: '120px' }}
          >
            <QDropdownToggle
              className="toggledown-primary"
              color="primary"
              caret
              size="sm"
              disableAnimation
              shrink={false}
              style={{
                transform: 'none',
                overflow: 'visible',
              }}
            >
              <QButton
                variant="outlined"
                endIcon={<ExpandMore />}
                className="button-toggledown-primary"
              >
                Actions
              </QButton>
            </QDropdownToggle>
            <QDropdownMenu
              variant="filled"
              IconComponent={null}
              value=""
              displayEmpty
              style={
                mastV2
                  ? {
                      paddingTop: '0px',
                      minWidth: 'unset',
                      background: 'none',
                    }
                  : {}
              }
              renderValue={() => {}}
              onFocus={handleFocus}
            >
              <QDropdownSelectItem value="" style={{ display: 'none' }} />

              {userAccess.change_sub_group_ownership_of_apps && (
                <QDropdownSelectItem
                  onClick={() => setShowChangeOwnershipModal(true)}
                >
                  <QMenuButtonWithIcon
                    icon={<i className="fa-regular fa-user purple-icon" />}
                    label={i18n.t('Change App Ownership')}
                  />
                </QDropdownSelectItem>
              )}

              {userAccess.change_app_note && (
                <QDropdownSelectItem
                  className="btn-app-note"
                  color="warning"
                  onClick={() => setNoteOpen(true)}
                  title="App Note"
                >
                  <QMenuButtonWithIcon
                    icon={
                      <i className="fa-regular fa-sticky-note warning-icon" />
                    }
                    label={i18n.t('App Note')}
                  />
                </QDropdownSelectItem>
              )}

              <QDropdownSelectItem
                title="Change app status"
                onClick={() => setOwnerOpen(true)}
              >
                <QMenuButtonWithIcon
                  icon={
                    <i className="fa-regular fa-square-arrow-right fa-lg primary-icon" />
                  }
                  label={i18n.t('Change Review Status')}
                />
              </QDropdownSelectItem>

              {userAccess.change_app_external_id && (
                <QDropdownSelectItem
                  title="Modify EID"
                  onClick={() => setEidOpen(true)}
                >
                  <i className="fa-regular fa-id-card success-icon" />
                  <span>Modify EID</span>
                </QDropdownSelectItem>
              )}
            </QDropdownMenu>
          </QUncontrolledButtonDropdown>
        )}
      </div>
    </>
  );
}

export default withLDConsumer()(SummarizedAppActions);
