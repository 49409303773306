import React, { Component } from 'react';
import moment from 'moment';

import { QRow } from '../../Q-Components/QRow';
import { QCol } from '../../Q-Components/QCol';
import { QTypography } from '../../Q-Components/QTypography';

export default class CurrentIntegrationDetails extends Component {
  render() {
    const { credentials } = this.props;

    if (!credentials.createdAt) {
      // If we don't have a created date, we know that no credentials have
      // been set yet.
      return (
        <span>
          <QTypography>No integration setup</QTypography>
        </span>
      );
    }

    let displayType;
    if (credentials.integration === 'mobileiron') {
      displayType = 'Mobile Iron';
    }
    if (credentials.integration === 'maas360') {
      displayType = 'MaaS360';
    }
    if (credentials.integration === 'airwatch') {
      displayType = 'AirWatch';
    }

    return (
      <div>
        <QRow>
          <QCol xs="5" width="100%">
            <strong>
              <QTypography variant="label">Credentials Set:</QTypography>{' '}
            </strong>
          </QCol>
          <QCol xs="4" width="100%">
            <QTypography variant="body">
              {moment(credentials.setDate).format('L - LTS')}
            </QTypography>
          </QCol>
        </QRow>
        <QRow>
          <QCol xs="5" width="100%">
            <strong>
              <QTypography variant="label">Integration:</QTypography>
            </strong>
          </QCol>
          <QCol xs="4" width="100%">
            <QTypography variant="body">{displayType}</QTypography>
          </QCol>
        </QRow>
        <QRow>
          <QCol xs="5" width="100%">
            <strong>
              <QTypography variant="label">Host:</QTypography>
            </strong>
          </QCol>
          <QCol xs="4" width="100%">
            <QTypography variant="body">{credentials.host}</QTypography>
          </QCol>
        </QRow>
        <QRow>
          <QCol xs="5" width="100%">
            <strong>
              <QTypography variant="label">Username:</QTypography>
            </strong>
          </QCol>
          <QCol xs="4" width="100%">
            <QTypography variant="body">{credentials.username}</QTypography>
          </QCol>
        </QRow>
      </div>
    );
  }
}
