import React, { Component } from 'react';
import classnames from 'classnames';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import DataTable from '../../../DataTable/DataTable';

import SensitiveDataExposureTable from './SensitiveDataExposureTable';

export default class SharingSensitiveData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
    };
  }

  setTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { activeTab } = this.state;
    const { data } = this.props;

    return (
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                this.setTab('1');
              }}
            >
              Permissions Requested
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                this.setTab('2');
              }}
            >
              Sensitive Information Exposure
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <DataTable
              data={data.permission_requested}
              columns={[
                {
                  label: 'Permission',
                  component: ({ rowData }) => <span>{rowData}</span>,
                },
              ]}
            />
          </TabPane>
          <TabPane tabId="2">
            <SensitiveDataExposureTable data={data} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
