import React from 'react';
import { Label } from 'reactstrap';

import i18n from '../localization/i18n';

import { testScriptFilterOptions } from './AnalyzedApps/AnalyzedAppsFilterOptions';
import { QSelect } from './Q-Components/QSelect';

const TestScriptFilterSelect = ({
  testScriptFilter,
  handleTestScriptFilterOptionChange,
  mastV2 = false,
}) => (
  <>
    {!mastV2 && (
      <Label for="test-script-filter-select" style={{ paddingTop: '5px' }}>
        <strong>{i18n.t('Test Script')}: </strong>
      </Label>
    )}
    <QSelect
      options={testScriptFilterOptions}
      value={testScriptFilter}
      onChange={handleTestScriptFilterOptionChange}
      label="Test Script"
      formControlProps={{ sx: { width: '100%' } }}
      selectIconProps={{ fontSize: 'medium' }}
    />
  </>
);

export default TestScriptFilterSelect;
