import React from 'react';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QDivider } from '../../../Q-Components/QDivider';
import { QBadge } from '../../../Q-Components/QBadge';
import { QCol } from '../../../Q-Components/QCol';
import { QRow } from '../../../Q-Components/QRow';
import { QTypography } from '../../../Q-Components/QTypography';

export const Regulations = ({ issue, isSarifReport }) => {
  const { mastV2 } = useFlags();

  function isNotEmpty(regulations) {
    return regulations && !_.isEmpty(regulations);
  }

  function ReviewStatusTag({ reg }) {
    const issueFound = issue.found || isSarifReport;
    return issueFound && reg.fail ? (
      <QBadge
        variant="red"
        label={
          <QTypography variant="smallRegular" style={{ color: '#000000' }}>
            Fail
          </QTypography>
        }
        color="danger"
        style={
          mastV2
            ? { backgroundColor: '#FF8A65', width: '72px', height: '24px' }
            : {}
        }
        pill
      >
        Fail
      </QBadge>
    ) : (
      <QBadge
        variant="warning"
        label={
          <QTypography variant="smallRegular" style={{ color: '#000000' }}>
            Review
          </QTypography>
        }
        color="warning"
        pill
        style={mastV2 ? { width: '72px', height: '24px' } : {}}
      >
        Review
      </QBadge>
    );
  }

  function RegulationsSection({ regulations, title }) {
    return isNotEmpty(regulations) ? (
      mastV2 ? (
        <QCol
          style={{
            marginLeft: '16px',
            marginBottom: '12px',
          }}
        >
          <QTypography
            variant="h6Bold"
            style={{
              color: '#000000',
            }}
          >
            {title}
          </QTypography>
          {regulations.map((reg, index) => (
            <QRow columnGap="12px" key={index} style={{ marginTop: '12px' }}>
              <ReviewStatusTag reg={reg} />
              <a href={reg.link} target="_blank">
                {reg.item}
              </a>
            </QRow>
          ))}
        </QCol>
      ) : (
        regulations.map((reg, index) => (
          <div key={index}>
            <ReviewStatusTag reg={reg} />
            <a href={reg.link} target="_blank" className="ml-1">
              {title}: {reg.item}
            </a>
          </div>
        ))
      )
    ) : (
      <></>
    );
  }

  return mastV2 ? (
    <>
      <RegulationsSection regulations={issue.owasp} title="OWASP" />
      {isNotEmpty(issue.owasp) && isNotEmpty(issue.niap) && (
        <QDivider
          style={{
            borderColor: '#E0E0E0',
            marginTop: '14px',
            marginBottom: '14px',
          }}
        />
      )}
      <RegulationsSection regulations={issue.niap} title="NIAP" />
      {isNotEmpty(issue.gdpr) &&
        (isNotEmpty(issue.niap) || isNotEmpty(issue.owasp)) && (
          <QDivider
            style={{
              borderColor: '#E0E0E0',
              marginTop: '14px',
              marginBottom: '14px',
            }}
          />
        )}
      <RegulationsSection regulations={issue.gdpr} title="GDPR" />
    </>
  ) : (
    <>
      <div className="report-regulation">
        {(!_.isEmpty(issue.owasp) ||
          !_.isEmpty(issue.niap) ||
          !_.isEmpty(issue.gdpr)) && <h6>Regulations</h6>}
        <RegulationsSection regulations={issue.owasp} title="OWASP" />
        <RegulationsSection regulations={issue.niap} title="NIAP" />
        <RegulationsSection regulations={issue.gdpr} title="GDPR" />
      </div>
    </>
  );
};
