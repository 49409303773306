import React from 'react';
import { Card } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QCard as MuiCard } from '@kw/quokka-ui';

const LDCard = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  const filteredProps = ['className'];

  const muiCardProps = Object.keys(props).reduce((acc, key) => {
    if (!filteredProps.includes(key)) {
      acc[key] = props[key];
    }
    return acc;
  }, {});

  return mastV2 ? (
    <MuiCard {...muiCardProps}>{children}</MuiCard>
  ) : (
    <Card {...props}>{children}</Card>
  );
};
export const QCard = withLDConsumer()(LDCard);
