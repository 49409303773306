import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

export default function WorldAccessibleFiles() {
  const worldAccessibleFiles = useSelector(
    ({ appResults }) => appResults.results.world_accessible_files,
  );

  return (
    <Card className="card-accent-danger">
      <CardHeader>World Accessible File</CardHeader>

      <CardBody>
        {worldAccessibleFiles ? (
          <DataTable
            filterable={false}
            data={worldAccessibleFiles}
            columns={[
              { label: i18n.t('File Path'), keyName: 'file_path' },
              { label: i18n.t('Type'), keyName: 'type' },
            ]}
          />
        ) : (
          <p>Not Available</p>
        )}
      </CardBody>
    </Card>
  );
}
