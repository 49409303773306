import React from 'react';
import { Radar } from 'react-chartjs-2';
import {
  Chart,
  RadialLinearScale,
  Tooltip,
  Legend,
  Filler,
  PointElement,
  LineElement,
} from 'chart.js';

Chart.register(
  RadialLinearScale,
  Tooltip,
  Legend,
  Filler,
  PointElement,
  LineElement,
);

export const QRadar = props => {
  const radarOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: context => context[0].label.replace(/\(.*\)/, ''),
          label: () => '',
          afterLabel: context => `${context.raw}`,
        },
      },
    },
    scales: {
      r: {
        min: -10,
        max: 100,
        ticks: {
          stepSize: 25,
          display: false,
        },
        grid: {
          circular: true,
        },
        angleLines: {
          display: true,
        },
        pointLabels: {
          font: {
            size: '20vw',
            weight: 'bold',
          },
          padding: 20,
        },
      },
    },
    elements: {
      line: {
        fill: true,
      },
      point: {
        radius: 5,
      },
    },
  };

  const radialGradientPlugin = {
    id: 'radialGradientPlugin',
    beforeDraw(chart) {
      const {
        ctx,
        scales: { r: scale },
      } = chart;

      const metadata = chart.getDatasetMeta(0);
      const { xCenter, yCenter, drawingArea: radius } = scale;

      const gradient = ctx.createRadialGradient(
        xCenter,
        yCenter,
        0,
        xCenter,
        yCenter,
        radius,
      );

      const {
        low = 0,
        medium = 0.4,
        high = 1,
      } = props?.gradientThreshold || {};

      gradient.addColorStop(low, 'rgba(33, 150, 243, 0.3)'); // Blue
      gradient.addColorStop(medium, 'rgba(255, 152, 0, 0.3)'); // Orange
      gradient.addColorStop(high, 'rgba(244, 67, 54, 0.3)'); // Red

      ctx.save();
      ctx.beginPath();
      const firstValue = metadata.data[0];
      ctx.moveTo(firstValue.x, firstValue.y);

      metadata.data.forEach(value => {
        const { x, y } = value;
        ctx.lineTo(x, y);
      });
      ctx.closePath();

      metadata.data.forEach(value => {
        const { x, y } = value;
        ctx.moveTo(x, y);
        ctx.arc(x, y, 5, 0, Math.PI * 2);
      });
      ctx.fillStyle = gradient;
      ctx.fill();
      ctx.restore();
    },
  };

  const radialGradientBackgroundPlugin = {
    id: 'radialGradientBackgroundPlugin',
    beforeDraw(chart) {
      const {
        ctx,
        scales: { r: scale },
      } = chart;
      const { xCenter, yCenter, drawingArea: radius } = scale;

      const areagradient = ctx.createRadialGradient(
        xCenter,
        yCenter,
        0,
        xCenter,
        yCenter,
        radius * 1.2,
      );

      ctx.save();
      ctx.arc(xCenter, yCenter, radius * 1.5, 0, Math.PI * 2);
      ctx.fillStyle = areagradient;
      ctx.fill();
      ctx.restore();
    },
  };

  const updatedProps = {
    ...props,
    data: {
      ...props?.data,
    },
    options: {
      ...radarOptions,
      ...props?.options,
    },
    plugins: [radialGradientBackgroundPlugin, radialGradientPlugin],
  };

  return <Radar {...updatedProps} />;
};
