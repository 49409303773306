import React, { Component } from 'react';
import { Table } from 'reactstrap';

import DataTable from '../../../DataTable/DataTable';

export default class IosNetworkArbitrary extends Component {
  render() {
    const { data } = this.props;
    const flags = Object.entries(data)
      .map(([key, value]) => {
        if (value === true) {
          return key;
        }
        return false;
      })
      .filter(flag => flag);

    return (
      <div>
        <strong>Networking Flags in plist:</strong>
        {flags.length > 0 ? (
          <ul>
            {flags.map(flag => (
              <li key={flag}>{flag}</li>
            ))}
          </ul>
        ) : (
          <div>None</div>
        )}

        {data.NSExceptionDomains && (
          <DataTable
            data={Object.entries(data.NSExceptionDomains).map(
              ([domain, data]) => ({
                domain,
                flags: data,
              }),
            )}
            columns={[
              { label: 'Domain', keyName: 'domain' },
              {
                label: 'Flags',
                keyName: 'flags',
                component: ({ value: flags }) => (
                  <Table size="sm">
                    <tbody>
                      {Object.entries(flags).map(([flagName, value]) => (
                        <tr key={flagName}>
                          <td>{flagName}</td>
                          <td>{value ? 'True' : 'False'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ),
              },
            ]}
          />
        )}
      </div>
    );
  }
}
