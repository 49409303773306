import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Map, TileLayer, Marker, Tooltip } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QTypography } from '../Q-Components/QTypography';
import QDataTable from '../DataTable/QDataTable';
import DataTable from '../DataTable/DataTable';
import { DateTime } from '../DateTime';
import i18n from '../../localization/i18n';

const LoginActivity = ({ loginMapZoom = 1 }) => {
  // eslint-disable-next-line
  let [loginActivity, setLoginActivity] = useState([]);
  const { mastV2 } = useFlags();
  const notAvailable = 'N/A';

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get('emm/login-activity');
      if (response) {
        setLoginActivity(response.data);
      }
    };

    getData();
  }, []);

  loginActivity = loginActivity.filter(marker => {
    if (
      Number.isNaN(parseFloat(marker.lat)) ||
      Number.isNaN(parseFloat(marker.long))
    ) {
      return false;
    }
    return true;
  });

  const LoginActivityDataTable = props =>
    mastV2 ? <QDataTable {...props} /> : <DataTable {...props} />;

  return (
    <QCard className="card-accent-primary">
      <QCardHeader
        title={
          <QTypography variant="h4Bold" color="#000000">
            {i18n.t('Login Activity')}
          </QTypography>
        }
      >
        <strong>{i18n.t('Login Activity')}</strong>
      </QCardHeader>
      <QCardBody>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <LoginActivityDataTable
              showPageSize
              data={loginActivity}
              columns={[
                {
                  label: 'Login Time',
                  component: ({ rowData }) => (
                    <DateTime dateTime={rowData.login_time} />
                  ),
                },
                {
                  label: 'IP',
                  component: ({ rowData }) => <span>{rowData.ip}</span>,
                },
                {
                  label: 'Location',
                  filterable: true,
                  filterFunc: (filterStr, item) => {
                    const region = item.region || notAvailable;
                    const city = item.city || notAvailable;
                    const country = item.country || notAvailable;
                    return (
                      region.toLowerCase().includes(filterStr) ||
                      city.toLowerCase().includes(filterStr) ||
                      country.toLowerCase().includes(filterStr)
                    );
                  },
                  component: ({ rowData }) => (
                    <span>
                      <span
                        style={{ width: '12px', height: '12px', margin: '5px' }}
                        className={`fi fi-${(
                          rowData.country || ''
                        ).toLowerCase()}`}
                      />
                      {rowData.city || notAvailable},{' '}
                      {rowData.region || notAvailable} -{' '}
                      {(rowData.country || notAvailable).toUpperCase()}
                    </span>
                  ),
                },
                {
                  label: 'Lat, Long',
                  component: ({ rowData }) => (
                    <span>
                      {rowData.lat}, {rowData.long}
                    </span>
                  ),
                },
              ]}
            />
          </div>
          <div style={{ width: '50%' }}>
            <Map
              zoom={loginMapZoom}
              maxZoom={18}
              style={{ height: '400px', margin: '0 auto' }}
              center={
                loginActivity[0] !== undefined
                  ? [
                      parseFloat(loginActivity[0].lat),
                      parseFloat(loginActivity[0].long),
                    ]
                  : [38.923209, -77.228429]
              }
              worldCopyJump
            >
              <TileLayer
                url={`https://api.maptiler.com/maps/e6e2a7b7-b610-436e-8bff-e5735a954716/{z}/{x}/{y}.png?key=${process.env.REACT_APP_MAPTILER_API_KEY}`}
                attribution='&copy; <a href="https://www.maptiler.com/copyright/">MapTiler</a> &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                tileSize={512}
                zoomOffset={-1}
              />
              <MarkerClusterGroup>
                {loginActivity.map(marker => (
                  <Marker
                    key={`${JSON.stringify(marker.login_time)}`}
                    position={[parseFloat(marker.lat), parseFloat(marker.long)]}
                  >
                    <Tooltip direction="top">
                      <div>
                        <div>{marker.ip}</div>
                        <div>
                          {marker.city || notAvailable}{' '}
                          {marker.region || notAvailable}{' '}
                          {(marker.country || notAvailable).toUpperCase()}
                        </div>
                        <div>{moment(marker.login_time).format('L - LTS')}</div>
                      </div>
                    </Tooltip>
                  </Marker>
                ))}
              </MarkerClusterGroup>
            </Map>
          </div>
        </div>
      </QCardBody>
    </QCard>
  );
};

export default LoginActivity;
