import React, { useState } from 'react';
import classnames from 'classnames';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import { QTabContext } from '../Q-Components/QTabContext';
import { QTabLink } from '../Q-Components/QTabLink';
import { QTabList } from '../Q-Components/QTabList';
import { QTabContent } from '../Q-Components/QTabContent';
import { QTabPanel } from '../Q-Components/QTabPanel';
import { QTab } from '../Q-Components/QTab';
import { QCard } from '../Q-Components/QCard';
import SummarizedApps from '../../views/SummarizedApps';
import AnalyzedApps from '../../views/AnalyzedApps';

function LDAnalyzedAppsNavTabs({ active, ...props }) {
  const [activeTab, setActiveTab] = useState(active);
  const { mastV2 } = useFlags();

  const handleChange = (event, newTab) => {
    if (mastV2) {
      setActiveTab(newTab);
    } else {
      setActiveTab(event);
    }
  };

  return (
    <QCard style={{ paddingRight: '0px', paddingLeft: '0px' }}>
      <QTabContext value={activeTab}>
        <QTabList tabs onChange={handleChange}>
          <QTab label="Summary View" value="1">
            <QTabLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => setActiveTab('1')}
            >
              Summary View
            </QTabLink>
          </QTab>
          <QTab label="List View" value="2">
            <QTabLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => setActiveTab('2')}
            >
              List View
            </QTabLink>
          </QTab>
        </QTabList>
        <QTabContent activeTab={activeTab}>
          <QTabPanel
            tabId="1"
            value="1"
            style={mastV2 ? { padding: '0px', width: '100%' } : {}}
          >
            <SummarizedApps />
          </QTabPanel>
          <QTabPanel
            tabId="2"
            value="2"
            style={mastV2 ? { padding: '0px', width: '100%' } : {}}
          >
            <AnalyzedApps {...props} />
          </QTabPanel>
        </QTabContent>
      </QTabContext>
    </QCard>
  );
}

export const AnalyzedAppsNavTabs = withLDConsumer()(LDAnalyzedAppsNavTabs);
