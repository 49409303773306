import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Progress } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { setRiskLevelFilter } from '../../../store/slices/emmAppSlice';
import util from '../../../logic/util';
import { getThreatColor } from '../../../logic/generalReportUtil';
import i18n from '../../../localization/i18n';

export default function SimpleReportSummary({ foundIssues }) {
  const dispatch = useDispatch();
  const riskLevels = ['critical', 'high', 'medium', 'low'];
  const results = {};
  const [filterSelection, setFilterSelection] = useState({});

  for (const level of riskLevels) {
    results[`${level}Total`] = foundIssues.filter(
      issue => issue.risk === level,
    ).length;
    results[`${level}Found`] = foundIssues.filter(
      issue =>
        issue.risk === level && issue.found && issue.review_status !== 'passed',
    ).length;

    results[`${level}Passed`] =
      results[`${level}Total`] - results[`${level}Found`];
  }

  const handleFilter = level => {
    if (filterSelection.level === level) {
      setFilterSelection({});
      dispatch(setRiskLevelFilter(null));
    } else {
      setFilterSelection({ level, isFiltered: true });
      dispatch(setRiskLevelFilter(level));
    }
  };

  return (
    <Row>
      {['critical', 'high', 'medium', 'low'].map(
        level =>
          // Only show the high level findings card if there are any items even
          // in that category. Mainly used for the critical risk level since
          // many customers might not even have any items in that level
          results[`${level}Total`] > 0 && (
            <Col key={level}>
              <Card
                className={`${level}-card-shadow`}
                style={
                  filterSelection.level === level
                    ? { backgroundColor: '#fbe8cd' }
                    : { backgroundColor: 'white' }
                }
              >
                <CardBody>
                  <h4 onClick={() => handleFilter(level)} className="pointer">
                    <span className={`text-${getThreatColor(level)}`}>
                      {util.capitalize(i18n.t(level))}
                    </span>{' '}
                    {i18n.t('Risk Findings')}
                  </h4>
                  <h6>
                    {results[`${level}Found`]} {i18n.t('finding(s)')}
                  </h6>
                  <Progress multi style={{ border: '1px solid transparent' }}>
                    <Progress
                      bar
                      color="danger"
                      value={
                        parseFloat(
                          (results[`${level}Found`] /
                            results[`${level}Total`]) *
                            100,
                        ) || 0
                      }
                    />

                    <Progress
                      bar
                      color="success"
                      value={
                        parseFloat(
                          (results[`${level}Passed`] /
                            results[`${level}Total`]) *
                            100,
                        ) || 0
                      }
                    />
                  </Progress>
                </CardBody>
              </Card>
            </Col>
          ),
      )}
    </Row>
  );
}
