import React from 'react';
import { CardText } from 'reactstrap';

// eslint-disable-next-line import/no-named-default
import { default as AndroidMarketInfoTable } from '../../Android/MarketInfoTable';
// eslint-disable-next-line import/no-named-default
import { default as IosMarketInfoTable } from '../../iOS/MarketInfoTable';

export const V1_11 = ({ platform, evidence }) => {
  if (evidence) {
    return <CardText>{evidence}</CardText>;
  }

  return platform === 'android' ? (
    <AndroidMarketInfoTable />
  ) : (
    <IosMarketInfoTable />
  );
};
