import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';

import i18n from '../../../localization/i18n';

export const MissingPermissionsTable = () => {
  const missingPermissions = useSelector(
    ({ appResults }) =>
      appResults.results.analysis.analysis_details.static_analysis
        .missing_permissions ?? {},
  );

  if (Object.keys(missingPermissions).length > 0) {
    return (
      <Table size="sm">
        <tbody>
          {Object.entries(missingPermissions).map(([perm, link]) => (
            <tr key={perm}>
              <td>
                <a className="no-link" href={link} target="_blank">
                  {perm}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
  return <span>{i18n.t('No missing permissions')}</span>;
};
