import React, { useState } from 'react';
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
} from 'reactstrap';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';
import { invalidateAnalyzedAppsCache } from '../../store/slices/cache/analyzedAppsCacheSlice';

const updateBatch = async (analyzedAppIds, newNote, removed) => {
  await axios.patch('app-note/batch', {
    analyzedAppIds,
    note: newNote,
    removed,
  });
};
const updateOne = async (analyzedAppId, newNote, removed) => {
  await axios.patch('app-note', {
    analyzedAppId,
    note: newNote,
    removed,
  });
};
export default function BatchAppNote({
  note,
  analyzedAppIds,
  lastAnalyzedAppId,
  getApps,
  open,
  toggle,
}) {
  const [noteText, setNoteText] = useState(note ? note.note : '');
  const [applyToAllVersions, setApplyToAllVersions] = useState(false);
  const dispatch = useDispatch();
  const { mastV2 } = useFlags();
  async function saveNote(newNote, removed) {
    try {
      if (applyToAllVersions) {
        await updateBatch(analyzedAppIds, newNote, removed);
      } else {
        await updateOne(lastAnalyzedAppId, newNote, removed);
      }
      dispatch(invalidateAnalyzedAppsCache());
    } catch (err) {
      console.log('Error patching app note:', err);
      toastrHelper.showErrorToast(
        i18n.t('Error saving app note'),
        null,
        mastV2,
      );
      return;
    }
    toggle();
    getApps();
  }

  return (
    <>
      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader>{i18n.t('App Note')}</ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            value={noteText}
            onChange={e => setNoteText(e.target.value)}
          />
          <span style={{ float: 'right' }}>
            <small>
              <i>{i18n.t('Minimum 10 characters')}</i>
            </small>
          </span>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                onChange={e => setApplyToAllVersions(!!e.target.checked)}
              />{' '}
              Apply to all versions (if unchecked latest will be updated)
            </Label>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            {i18n.t('Cancel')}
          </Button>
          <Button
            color="warning"
            onClick={() => {
              saveNote('', true);
            }}
          >
            {i18n.t('Remove')}
          </Button>
          <Button
            color="primary"
            onClick={() => saveNote(noteText, false)}
            disabled={noteText.length < 10}
          >
            {i18n.t('Save')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
