import React, { Component } from 'react';
import axios from 'axios';

import i18n from '../../localization/i18n';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';

import AnalyticsAlertThresholdCard from './AnalyticsSettings/AnalyticsAlertThresholdCard';

export default class AnalyticsSettings extends Component {
  constructor() {
    super();

    this.state = {
      alertThresholdData: null,
    };
  }

  async componentDidMount() {
    // Get the initial values for analytics thresholds
    const resp = await axios.get('analytics-alerts/thresholds');
    this.setState({ alertThresholdData: resp.data });
  }

  render() {
    const { alertThresholdData } = this.state;

    const analyticsItemsThresholdCard = {
      average_analysis_time: {
        name: 'Average Analysis Time Threshold',
        thresholdOptions: [
          {
            value: 75,
            label: `75${i18n.t(' minutes')}`,
            clearableValue: false,
          },
          {
            value: 90,
            label: `90${i18n.t(' minutes')}`,
            clearableValue: false,
          },
          {
            value: 105,
            label: `105${i18n.t(' minutes')}`,
            clearableValue: false,
          },
          {
            value: 120,
            label: `120${i18n.t(' minutes')}`,
            clearableValue: false,
          },
        ],
      },
      median_queue_time: {
        name: 'Median Queue Time Threshold',
        thresholdOptions: [
          {
            value: 10,
            label: `10${i18n.t(' minutes')}`,
            clearableValue: false,
          },
          {
            value: 20,
            label: `20${i18n.t(' minutes')}`,
            clearableValue: false,
          },
          {
            value: 30,
            label: `30${i18n.t(' minutes')}`,
            clearableValue: false,
          },
          {
            value: 40,
            label: `40${i18n.t(' minutes')}`,
            clearableValue: false,
          },
        ],
      },
      total_resource_usage: {
        name: 'Total Resource Usage Threshold',
        thresholdOptions: [
          { value: 50, label: '50 %', clearableValue: false },
          { value: 75, label: '75 %', clearableValue: false },
          { value: 90, label: '90 %', clearableValue: false },
        ],
      },
      analysis_failure_rate: {
        name: 'Analysis Failure Rate Threshold',
        thresholdOptions: [
          { value: 5, label: '5 %', clearableValue: false },
          { value: 8, label: '8 %', clearableValue: false },
          { value: 10, label: '10 %', clearableValue: false },
        ],
      },
    };
    return (
      alertThresholdData && (
        <>
          <QRow columnGap="20px">
            <QCol flex="1" xs="4">
              <AnalyticsAlertThresholdCard
                analyticsItem="average_analysis_time"
                thresholdSettings={alertThresholdData.find(
                  alertThreshold =>
                    alertThreshold.analytics_item === 'average_analysis_time',
                )}
                settingsInfo={analyticsItemsThresholdCard.average_analysis_time}
                dimensions="minutes"
              />
            </QCol>
            <QCol flex="1" xs="4">
              <AnalyticsAlertThresholdCard
                analyticsItem="median_queue_time"
                thresholdSettings={alertThresholdData.find(
                  alertThreshold =>
                    alertThreshold.analytics_item === 'median_queue_time',
                )}
                settingsInfo={analyticsItemsThresholdCard.median_queue_time}
                dimensions="minutes"
              />
            </QCol>
            <QCol flex="1" xs="4">
              <AnalyticsAlertThresholdCard
                analyticsItem="total_resource_usage"
                thresholdSettings={alertThresholdData.find(
                  alertThreshold =>
                    alertThreshold.analytics_item === 'total_resource_usage',
                )}
                settingsInfo={analyticsItemsThresholdCard.total_resource_usage}
                dimensions="%"
              />
            </QCol>
          </QRow>
          <QRow sx={{ marginTop: '20px' }} columnGap="20px">
            <QCol flex="1" xs="4">
              <AnalyticsAlertThresholdCard
                analyticsItem="analysis_failure_rate"
                thresholdSettings={alertThresholdData.find(
                  alertThreshold =>
                    alertThreshold.analytics_item === 'analysis_failure_rate',
                )}
                settingsInfo={analyticsItemsThresholdCard.analysis_failure_rate}
                dimensions="%"
              />
            </QCol>
            <QCol flex="1" xs="4" />
            <QCol flex="1" xs="4" />
          </QRow>
        </>
      )
    );
  }
}
