import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import IosCloudServicesTable from '../iOS/CloudServicesTable';
import SystemLibrariesDatatable from '../iOS/SystemLibrariesDatatable';
import FileAccessDatatable from '../iOS/FileAccessDatatable';
import SensitiveDataExposureDatatable from '../SensitiveDataExposureDatatable';
import DynamicallyLoadedLibrariesDatatable from '../iOS/DynamicallyLoadedLibrariesDatatable';
import HashOperationsDatatable from '../iOS/HashOperationsDatatable';
import DynamicAnalysisDatatable from '../Android/DynamicAnalysisDatatable';
import AndroidCloudServicesTable from '../Android/CloudServicesTable';
import TabsView from '../../TabsView';
import Findings from '../Findings';
import { Harviewer } from '../../Harviewer/Harviewer';

class Processing extends Component {
  render() {
    const { platform, parsedIssues } = this.props;

    const tabs = [
      {
        title: 'Overview',
        tabContent: () => (
          <Row>
            <Col>
              <Findings
                findings={parsedIssues}
                platform={platform}
                category="security"
                showOwaspIcon={false}
              />
            </Col>
          </Row>
        ),
      },
      {
        title: 'Cloud Services',
        tabContent: () =>
          platform === 'ios' ? (
            <IosCloudServicesTable />
          ) : (
            <AndroidCloudServicesTable />
          ),
      },
      {
        title: 'System Libraries Used',
        tabContent: () =>
          platform === 'ios' ? <SystemLibrariesDatatable /> : null,
      },
      {
        title: 'Network Traffic',
        tabContent: () => <Harviewer />,
      },
      {
        title: 'File Access',
        tabContent: () =>
          platform === 'ios' ? (
            <FileAccessDatatable />
          ) : (
            <DynamicAnalysisDatatable onlySelectedCategory="Files Accessed" />
          ),
      },
      {
        title: 'Dynamically Loaded Libraries',
        tabContent: () =>
          platform === 'ios' ? (
            <DynamicallyLoadedLibrariesDatatable />
          ) : (
            <DynamicAnalysisDatatable onlySelectedCategory="Libraries loaded" />
          ),
      },
      {
        title: 'Sensitive Data Exposure',
        tabContent: () => (
          <SensitiveDataExposureDatatable platform={platform} />
        ),
      },
      {
        title: 'Cryptographic Operations',
        tabContent: () =>
          platform === 'ios' ? <HashOperationsDatatable /> : null,
      },
    ];

    return (
      <div>
        <Card className="card-accent-navy">
          <CardHeader>Processing</CardHeader>
          <CardBody>
            <div>
              &quot;The General Data Protection Regulation (GDPR) offers a
              uniform, Europe-wide possibility for so-called ‘commissioned data
              processing’, which is the gathering, processing or use of personal
              data by a processor in accordance with the instructions of the
              controller based on a contract.&quot;
            </div>
            <div>
              <a href="https://gdpr-info.eu/issues/processing/" target="_blank">
                More Info
              </a>
            </div>
            <hr />
            <TabsView tabs={tabs} />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ appResults }) => ({
  parsedIssues: appResults.parsedIssues,
});

export default withRouter(connect(mapStateToProps)(Processing));
