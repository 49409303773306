import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import moment from 'moment';
import axios from 'axios';

const DemoAlerts = ({
  userAccess,
  expiresOn,
  totalSubmissions,
  userConfig,
}) => {
  const [visible, setVisible] = React.useState(true);
  const onDismiss = async () => {
    setVisible(false);

    const response = await axios.put('emm/config', {
      config_key: 'trial_banner_dismissed',
      value: 1,
    });

    if (response.status === 200) {
      console.log('Trial banner updated');
    } else {
      console.error(response);
    }
  };

  const remainingDays = moment(expiresOn).diff(moment(), 'days');
  const remainingApps = userAccess.max_submissions - totalSubmissions;

  const shouldShowBanner = () => {
    // If user has unlimited app submissions they are not on a demo account
    if (userAccess.max_submissions === 0 || !userAccess.max_submissions) {
      return false;
    }

    // Forcibly show banner regardless of dismissal status if user is near end of trial
    if (
      userConfig.trial_banner_dismissed &&
      (remainingDays <= 7 || remainingApps < 1)
    ) {
      return true;
    }

    // Hide previously dismissed banner
    if (userConfig.trial_banner_dismissed) {
      return false;
    }

    return true;
  };

  if (!shouldShowBanner()) {
    return null;
  }

  const DemoMessage = () => {
    let message = '';

    if (remainingDays >= 1 && remainingApps >= 0) {
      message = `You have ${remainingDays} day${
        remainingDays > 1 ? 's' : ''
      } left in your trial. `;

      if (remainingApps >= 1) {
        message += `Your trial license allows for ${remainingApps} additional app${
          remainingApps > 1 ? 's' : ''
        } to be submitted for analysis.`;
      } else {
        message += `You have submitted the maximum number of apps for analysis as a part of your trial license.`;
      }
    }

    return message;
  };

  return (
    <>
      {expiresOn && (
        <Alert color="info" fade isOpen={visible} toggle={onDismiss}>
          <DemoMessage />
          <br />
          If you need any assistance or would like to talk to someone from
          Quokka, please{' '}
          <a
            href="mailto: mast.trials@kryptowire.com?subject=Kryptowire Q-MAST Trial"
            target="_blank"
            style={{ color: 'inherit', textDecoration: 'underline' }}
          >
            contact us
          </a>{' '}
          and we’ll be happy to help!
        </Alert>
      )}
    </>
  );
};

const mapStateToProps = ({ emmApp }) => ({
  reachedSubmissionsMax: emmApp.reachedSubmissionsMax,
  totalSubmissions: emmApp.totalSubmissions,
  userAccess: emmApp.userAccess,
  expiresOn: emmApp.expiresOn,
  userConfig: emmApp.userConfig,
});

export default connect(mapStateToProps)(DemoAlerts);
