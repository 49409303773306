import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import TabsView from '../TabsView';

import M1ios from './iOS/OwaspFindings/M1ios';
import M2ios from './iOS/OwaspFindings/M2ios';
import M3ios from './iOS/OwaspFindings/M3ios';
import M4ios from './iOS/OwaspFindings/M4ios';
import M5ios from './iOS/OwaspFindings/M5ios';
import M6ios from './iOS/OwaspFindings/M6ios';
import M7ios from './iOS/OwaspFindings/M7ios';
import M8ios from './iOS/OwaspFindings/M8ios';
import M9ios from './iOS/OwaspFindings/M9ios';
import M10ios from './iOS/OwaspFindings/M10ios';
import M1android from './Android/OwaspFindings/M1android';
import M2android from './Android/OwaspFindings/M2android';
import M3android from './Android/OwaspFindings/M3android';
import M4android from './Android/OwaspFindings/M4android';
import M5android from './Android/OwaspFindings/M5android';
import M6android from './Android/OwaspFindings/M6android';
import M7android from './Android/OwaspFindings/M7android';
import M8android from './Android/OwaspFindings/M8android';
import M9android from './Android/OwaspFindings/M9android';
import M10android from './Android/OwaspFindings/M10android';
import { OwaspMSTG } from './OWASP/OwaspMSTG';

class OwaspDetails extends Component {
  parseAndroidMarkers(latLongData, whoisInfo) {
    let totalLat = 0;
    let totalLong = 0;

    const markers = Object.entries(latLongData)
      .map(([host, latLong]) => {
        if (!latLong.lat || !latLong.long) return null;

        totalLat += latLong.lat;
        totalLong += latLong.long;

        return {
          host,
          latLong: [latLong.lat, latLong.long],
          ip: whoisInfo[host] ? whoisInfo[host].ip : null,
          country: whoisInfo[host] ? whoisInfo[host].country : null,
        };
      })
      .filter(marker => marker);

    return { markers, totalLat, totalLong };
  }

  parseIosMarkers(latLongData) {
    if (!latLongData) {
      return {};
    }

    let totalLat = 0;
    let totalLong = 0;

    // Combine the sections of URL constants and dynamic connections
    const totalConnections = [
      ...Object.values(latLongData.url_constants),
      ...Object.values(latLongData.connections),
    ];

    const markers = totalConnections
      .map(connection => {
        if (!connection.latitude || !connection.longitude) return null;

        totalLat += connection.latitude;
        totalLong += connection.longitude;

        return {
          host: connection.destination
            ? connection.destination
            : connection.uri,
          latLong: [connection.latitude, connection.longitude],
          ip: connection.ip_address ? connection.ip_address : null,
          country: connection.country.geolocation_data
            ? connection.geolocation_data.country_name
            : connection.country
            ? connection.country
            : null,
        };
      })
      .filter(connection => connection); // Filter out any values that are null

    return { markers, totalLat, totalLong };
  }

  render() {
    const { platform, showMap, results, parsedIssues, owaspMSTGResults } =
      this.props;

    if (!showMap) return null;

    // Array for each OWASP Issue
    const owaspIssuesM1 = parsedIssues
      .filter(
        issue =>
          issue.owasp.some(
            owaspItem => owaspItem.item === 'M1: Improper Platform Usage',
          ) && issue.found === true,
      )
      .map(issue => issue.positive_finding_text);
    const owaspIssuesM2 = parsedIssues
      .filter(
        issue =>
          issue.owasp.some(
            owaspItem => owaspItem.item === 'M2: Insecure Data Storage',
          ) && issue.found === true,
      )
      .map(issue => issue.positive_finding_text);
    const owaspIssuesM3 = parsedIssues
      .filter(
        issue =>
          issue.owasp.some(
            owaspItem => owaspItem.item === 'M3: Insecure Communication',
          ) && issue.found === true,
      )
      .map(issue => issue.positive_finding_text);
    const owaspIssuesM4 = parsedIssues
      .filter(
        issue =>
          issue.owasp.some(
            owaspItem => owaspItem.item === 'M4: Insecure Authentication',
          ) && issue.found === true,
      )
      .map(issue => issue.positive_finding_text);
    const owaspIssuesM5 = parsedIssues
      .filter(
        issue =>
          issue.owasp.some(
            owaspItem => owaspItem.item === 'M5: Insufficient Cryptography',
          ) && issue.found === true,
      )
      .map(issue => issue.positive_finding_text);
    const owaspIssuesM6 = parsedIssues
      .filter(
        issue =>
          issue.owasp.some(
            owaspItem => owaspItem.item === 'M6: Insecure Authorization',
          ) && issue.found === true,
      )
      .map(issue => issue.positive_finding_text);
    const owaspIssuesM7 = parsedIssues
      .filter(
        issue =>
          issue.owasp.some(
            owaspItem => owaspItem.item === 'M7: Client Code Quality',
          ) && issue.found === true,
      )
      .map(issue => issue.positive_finding_text);
    const owaspIssuesM8 = parsedIssues
      .filter(
        issue =>
          issue.owasp.some(
            owaspItem => owaspItem.item === 'M8: Code Tampering',
          ) && issue.found === true,
      )
      .map(issue => issue.positive_finding_text);
    const owaspIssuesM9 = parsedIssues
      .filter(
        issue =>
          issue.owasp.some(
            owaspItem => owaspItem.item === 'M9: Reverse Engineering',
          ) && issue.found === true,
      )
      .map(issue => issue.positive_finding_text);
    const owaspIssuesM10 = parsedIssues
      .filter(
        issue =>
          issue.owasp.some(
            owaspItem => owaspItem.item === 'M10: Extraneous Functionality',
          ) && issue.found === true,
      )
      .map(issue => issue.positive_finding_text);

    // CountriesContacted parameters
    const latLongData =
      platform === 'android'
        ? results.analysis.high_level_issues.lat_long_data
        : results.analysis.high_level_issues.geolocation_info;
    const foreignConnections =
      results.analysis.high_level_issues.foreign_connections;

    // Network Map parameters
    const whoisInfo =
      platform === 'android'
        ? results.analysis.high_level_issues.whois_info
        : null;
    const { markers, totalLat, totalLong } =
      platform === 'android'
        ? this.parseAndroidMarkers(latLongData, whoisInfo)
        : this.parseIosMarkers(latLongData);
    let numEntries;
    let mapCenter;
    if (markers) {
      numEntries = markers.length;
      mapCenter = [totalLat / numEntries, totalLong / numEntries];
    }

    const tabs = [
      {
        title: 'OWASP Top 10',
        tabContent: () =>
          platform === 'ios' ? (
            <div>
              {owaspIssuesM1.length !== 0 ? (
                <M1ios
                  owasp="M1: Improper Platform Usage"
                  owaspIssues={owaspIssuesM1}
                />
              ) : null}
              {owaspIssuesM2.length !== 0 ? (
                <M2ios
                  owasp="M2: Insecure Data Storage"
                  owaspIssues={owaspIssuesM2}
                />
              ) : null}
              {owaspIssuesM3.length !== 0 ? (
                <M3ios
                  owasp="M3: Insecure Communication"
                  owaspIssues={owaspIssuesM3}
                  markers={markers}
                  showMap={showMap}
                  mapCenter={mapCenter}
                  latLongData={latLongData.connections}
                  foreignConnections={foreignConnections}
                  platform={platform}
                />
              ) : null}
              {owaspIssuesM4.length !== 0 ? (
                <M4ios
                  owasp="M4: Insecure Authentication"
                  owaspIssues={owaspIssuesM4}
                />
              ) : null}
              {owaspIssuesM5.length !== 0 ? (
                <M5ios
                  owasp="M5: Insufficient Cryptography"
                  owaspIssues={owaspIssuesM5}
                />
              ) : null}
              {owaspIssuesM6.length !== 0 ? (
                <M6ios
                  owasp="M6: Insecure Authorization"
                  owaspIssues={owaspIssuesM6}
                />
              ) : null}
              {owaspIssuesM7.length !== 0 ? (
                <M7ios
                  owasp="M7: Client Code Quality"
                  owaspIssues={owaspIssuesM7}
                />
              ) : null}
              {owaspIssuesM8.length !== 0 ? (
                <M8ios owasp="M8: Code Tampering" owaspIssues={owaspIssuesM8} />
              ) : null}
              {owaspIssuesM9.length !== 0 ? (
                <M9ios
                  owasp="M9: Reverse Engineering"
                  owaspIssues={owaspIssuesM9}
                />
              ) : null}
              {owaspIssuesM10.length !== 0 ? (
                <M10ios
                  owasp="M10: Extraneous Functionality"
                  owaspIssues={owaspIssuesM10}
                />
              ) : null}
              {owaspIssuesM1.length === 0 ? (
                <M1ios
                  owasp="M1: Improper Platform Usage"
                  owaspIssues={owaspIssuesM1}
                />
              ) : null}
              {owaspIssuesM2.length === 0 ? (
                <M2ios
                  owasp="M2: Insecure Data Storage"
                  owaspIssues={owaspIssuesM2}
                />
              ) : null}
              {owaspIssuesM3.length === 0 ? (
                <M3ios
                  owasp="M3: Insecure Communication"
                  owaspIssues={owaspIssuesM3}
                  markers={markers}
                  showMap={showMap}
                  mapCenter={mapCenter}
                  latLongData={latLongData.connections}
                  foreignConnections={foreignConnections}
                  platform={platform}
                />
              ) : null}
              {owaspIssuesM4.length === 0 ? (
                <M4ios
                  owasp="M4: Insecure Authentication"
                  owaspIssues={owaspIssuesM4}
                />
              ) : null}
              {owaspIssuesM5.length === 0 ? (
                <M5ios
                  owasp="M5: Insufficient Cryptography"
                  owaspIssues={owaspIssuesM5}
                />
              ) : null}
              {owaspIssuesM6.length === 0 ? (
                <M6ios
                  owasp="M6: Insecure Authorization"
                  owaspIssues={owaspIssuesM6}
                />
              ) : null}
              {owaspIssuesM7.length === 0 ? (
                <M7ios
                  owasp="M7: Client Code Quality"
                  owaspIssues={owaspIssuesM7}
                />
              ) : null}
              {owaspIssuesM8.length === 0 ? (
                <M8ios owasp="M8: Code Tampering" owaspIssues={owaspIssuesM8} />
              ) : null}
              {owaspIssuesM9.length === 0 ? (
                <M9ios
                  owasp="M9: Reverse Engineering"
                  owaspIssues={owaspIssuesM9}
                />
              ) : null}
              {owaspIssuesM10.length === 0 ? (
                <M10ios
                  owasp="M10: Extraneous Functionality"
                  owaspIssues={owaspIssuesM10}
                />
              ) : null}
            </div>
          ) : (
            <div>
              {owaspIssuesM1.length !== 0 ? (
                <M1android
                  owasp="M1: Improper Platform Usage"
                  owaspIssues={owaspIssuesM1}
                />
              ) : null}
              {owaspIssuesM2.length !== 0 ? (
                <M2android
                  owasp="M2: Insecure Data Storage"
                  owaspIssues={owaspIssuesM2}
                />
              ) : null}
              {owaspIssuesM3.length !== 0 ? (
                <M3android
                  owasp="M3: Insecure Communication"
                  owaspIssues={owaspIssuesM3}
                  markers={markers}
                  showMap={showMap}
                  mapCenter={mapCenter}
                  latLongData={latLongData.connections}
                  foreignConnections={foreignConnections}
                  platform={platform}
                />
              ) : null}
              {owaspIssuesM4.length !== 0 ? (
                <M4android
                  owasp="M4: Insecure Authentication"
                  owaspIssues={owaspIssuesM4}
                />
              ) : null}
              {owaspIssuesM5.length !== 0 ? (
                <M5android
                  owasp="M5: Insufficient Cryptography"
                  owaspIssues={owaspIssuesM5}
                />
              ) : null}
              {owaspIssuesM6.length !== 0 ? (
                <M6android
                  owasp="M6: Insecure Authorization"
                  owaspIssues={owaspIssuesM6}
                />
              ) : null}
              {owaspIssuesM7.length !== 0 ? (
                <M7android
                  owasp="M7: Client Code Quality"
                  owaspIssues={owaspIssuesM7}
                />
              ) : null}
              {owaspIssuesM8.length !== 0 ? (
                <M8android
                  owasp="M8: Code Tampering"
                  owaspIssues={owaspIssuesM8}
                />
              ) : null}
              {owaspIssuesM9.length !== 0 ? (
                <M9android
                  owasp="M9: Reverse Engineering"
                  owaspIssues={owaspIssuesM9}
                />
              ) : null}
              {owaspIssuesM10.length !== 0 ? (
                <M10android
                  owasp="M10: Extraneous Functionality"
                  owaspIssues={owaspIssuesM10}
                />
              ) : null}
              {owaspIssuesM1.length === 0 ? (
                <M1android
                  owasp="M1: Improper Platform Usage"
                  owaspIssues={owaspIssuesM1}
                />
              ) : null}
              {owaspIssuesM2.length === 0 ? (
                <M2android
                  owasp="M2: Insecure Data Storage"
                  owaspIssues={owaspIssuesM2}
                />
              ) : null}
              {owaspIssuesM3.length === 0 ? (
                <M3android
                  owasp="M3: Insecure Communication"
                  owaspIssues={owaspIssuesM3}
                  markers={markers}
                  showMap={showMap}
                  mapCenter={mapCenter}
                  latLongData={latLongData.connections}
                  foreignConnections={foreignConnections}
                  platform={platform}
                />
              ) : null}
              {owaspIssuesM4.length === 0 ? (
                <M4android
                  owasp="M4: Insecure Authentication"
                  owaspIssues={owaspIssuesM4}
                />
              ) : null}
              {owaspIssuesM5.length === 0 ? (
                <M5android
                  owasp="M5: Insufficient Cryptography"
                  owaspIssues={owaspIssuesM5}
                />
              ) : null}
              {owaspIssuesM6.length === 0 ? (
                <M6android
                  owasp="M6: Insecure Authorization"
                  owaspIssues={owaspIssuesM6}
                />
              ) : null}
              {owaspIssuesM7.length === 0 ? (
                <M7android
                  owasp="M7: Client Code Quality"
                  owaspIssues={owaspIssuesM7}
                />
              ) : null}
              {owaspIssuesM8.length === 0 ? (
                <M8android
                  owasp="M8: Code Tampering"
                  owaspIssues={owaspIssuesM8}
                />
              ) : null}
              {owaspIssuesM9.length === 0 ? (
                <M9android
                  owasp="M9: Reverse Engineering"
                  owaspIssues={owaspIssuesM9}
                />
              ) : null}
              {owaspIssuesM10.length === 0 ? (
                <M10android
                  owasp="M10: Extraneous Functionality"
                  owaspIssues={owaspIssuesM10}
                />
              ) : null}
            </div>
          ),
      },
      {
        title: 'OWASP MASVS',
        tabContent: () => <OwaspMSTG platform={platform} />,
        disabled: !owaspMSTGResults || owaspMSTGResults.length === 0,
      },
    ];

    return <TabsView tabs={tabs} />;
  }
}

const mapStateToProps = ({ appResults }) => ({
  parsedIssues: appResults.parsedIssues,
  results: appResults.results,
  owaspMSTGResults: appResults.owaspMSTGResults,
});

export default withRouter(connect(mapStateToProps)(OwaspDetails));
