import _ from 'lodash';

export default {
  getAppName: results => {
    if (_.get(results, 'market.title')) {
      return _.get(results, 'market.title');
    }
    if (
      _.get(results, 'analysis.analysis_details.metadata_analysis.app_name')
    ) {
      return _.get(
        results,
        'analysis.analysis_details.metadata_analysis.app_name',
      );
    }
    return 'App Name';
  },

  getAppPackageName: results =>
    results.analysis.analysis_details.metadata_analysis.apk_info.package,

  getVersionName: results =>
    results.analysis.analysis_details.metadata_analysis.apk_info.version_name,

  getVersionCode: results =>
    results.analysis.analysis_details.metadata_analysis.apk_info.version_code,

  getMinSdk: results =>
    results.analysis.analysis_details.metadata_analysis.sdk_info.min_sdk,

  getTargetSdk: results =>
    results.analysis.analysis_details.metadata_analysis.sdk_info.target_sdk,

  getHashes: results =>
    results.analysis.analysis_details.metadata_analysis.hashes,

  getFileSize: results =>
    results.analysis.analysis_details.metadata_analysis.file_size,

  getSubmissionTag: results => results.submissionTag,

  getSubmittedAt: results => results.submitted_at,

  getTestScript: results => results.appiumScript,
};
