import React from 'react';

export default function ApiCall({ rowData: call }) {
  return (
    <div>
      {/* Method call */}
      <div>
        <a href={call.api_link} target="_blank" className="no-link">
          {call.method_name}
        </a>
      </div>
      <hr style={{ marginTop: '5px', marginBottom: '8px' }} />
      {/* Component */}
      {call.component && (
        <div>
          <strong>Component: </strong>
          {call.component}
        </div>
      )}
      {/* File */}
      <div>
        <strong>File: </strong>
        {call.smali_file.file.includes('/smali/')
          ? call.smali_file.file.split('/smali/')[1]
          : call.smali_file.file}
      </div>
      {/* Line number */}
      <div>
        <strong>Line: </strong>
        {call.smali_file.line_num !== 'p1' &&
        call.smali_file.line_num !== '-1' ? (
          <span>{call.smali_file.line_num}</span>
        ) : (
          <span>N/A</span>
        )}
      </div>
    </div>
  );
}
