import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Doughnut } from 'react-chartjs-2';

import { store } from '../../store/store';
import i18n from '../../localization/i18n';

const parseUrlInfo = () => {
  const { results } = store.getState().appResults;
  const {
    constants,
    dynamic_urls: dynamicUrls,
    gsb_results: gsbResults,
  } = results.analysis.high_level_issues;

  // Just combine the two objects to make it easier to parse
  const urls = { ...constants, ...dynamicUrls };
  const categoryData = {};
  for (const url in urls) {
    const item = urls[url];

    let category = item.Category;
    if (!category) {
      category = 'Uncategorized';
    }

    if (!(category in categoryData)) categoryData[category] = 0;
    categoryData[category] += 1;
  }

  // Parse out the category data so we can have the info in the form of two lists:
  // labels for each part of the pie chart, and another list for the data representing
  // the number of URLs for each category
  const categoryLabels = [];
  const categoryNumUrls = [];

  // Keep track of total numbers of URLs that were scanned. This will be needed while
  // rendering the GSB results to know how many total urls we have
  let totalUrls = 0;

  for (const label in categoryData) {
    const numUrls = categoryData[label];
    categoryLabels.push(i18n.t(label));
    categoryNumUrls.push(numUrls);
    totalUrls += numUrls;
  }

  // # Get the GSB results
  const gsbLabels = {};
  const gsbNumUrls = {};

  gsbLabels.clean = i18n.t('Clean');
  gsbLabels.match = i18n.t('Malware');

  gsbNumUrls.clean = totalUrls - gsbResults.matches.length;
  gsbNumUrls.match = gsbResults.matches.length;

  const categoriesData = {
    labels: categoryLabels,
    datasets: [
      {
        label: 'URL Categories',
        data: categoryNumUrls,
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(150, 94, 145, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(150, 94, 145, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
      },
    ],
  };

  const gsbData = {
    labels: [gsbLabels.clean, gsbLabels.match],
    datasets: [
      {
        label: 'Google Safe Browsing Results',
        data: [gsbNumUrls.clean, gsbNumUrls.match],
        backgroundColor: ['rgba(63, 168, 90, 0.2)', 'rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgba(63, 168, 90, 1)', 'rgba(255,99,132,1)'],
      },
    ],
  };

  return { categoriesData, gsbData };
};

class UrlScanningData extends Component {
  render() {
    const { platform } = this.props;
    const { gsbData, categoriesData } = parseUrlInfo(platform);
    return (
      <div>
        <Row>
          <Col xs="6">
            <Doughnut data={categoriesData} />
          </Col>
          <Col xs="6">
            <Doughnut data={gsbData} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default UrlScanningData;
