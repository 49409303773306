import React from 'react';
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  Badge,
  Table,
  CardHeader,
  CardBody,
} from 'reactstrap';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import XMLViewer from 'react-xml-viewer';

import DataTable from '../../../components/DataTable/DataTable';
import ApiCall from '../../../components/Reports/Android/ApiCall';
import MethodCallParams from '../../../components/Reports/Android/MethodCallParams';
import CertInfoTable from '../../../components/Reports/Android/CertInfoTable';
import { TreeList } from '../TreeList';

import { PiiTable } from './PiiTable';
import { DynamicTable } from './DynamicTable';

const splitProps = obj =>
  Object.entries(obj).map(([key, value]) => ({ [key]: value }));

export default function NiapSectionsData({ niapFindings, finding, platform }) {
  const { traffic } = useSelector(state => state.appResults);

  if (platform === 'android') {
    switch (finding) {
      case 'FCS_HTTPS_EXT.1.1': {
        const trafficEntries = (traffic?.log?.entries ?? [])
          .filter(entry => entry.request.url.startsWith('http://'))
          .map(entry => entry.request.url);

        return (
          <>
            <strong>Insecure URLs:</strong>
            <ListGroup flush>
              {trafficEntries.map(traffic => (
                <ListGroupItem>{traffic}</ListGroupItem>
              ))}
            </ListGroup>
          </>
        );
      }

      case 'FCS_RBG_EXT.1.1':
        return (
          <>
            <div>
              <strong>Methods:</strong>
            </div>
            <DataTable
              data={niapFindings[finding].data.methods}
              columns={[
                {
                  keyName: 'method_name',
                  component: ApiCall,
                  label: 'API Call',
                },
                {
                  label: 'Parameters',
                  component: MethodCallParams,
                },
              ]}
            />
          </>
        );

      case 'FCS_STO_EXT.1.1':
        return (
          <>
            <div>
              <strong>Methods:</strong>
            </div>
            <DataTable
              data={niapFindings[finding].data.methods}
              columns={[
                {
                  keyName: 'method_name',
                  component: ApiCall,
                  label: 'API Call',
                },
                {
                  label: 'Parameters',
                  component: MethodCallParams,
                },
              ]}
            />
            <Row>
              <Col xs="2">
                <strong>Smali:</strong>
              </Col>
              <Col>{niapFindings[finding].data.smali}</Col>
            </Row>
          </>
        );

      case 'FCS_TLSC_EXT.1.3':
        return (
          <>
            <div>
              <strong>Methods:</strong>
            </div>
            <DataTable
              data={niapFindings[finding].data.review}
              columns={[
                {
                  keyName: 'class_with_implementation',
                  label: 'Class',
                },
                {
                  keyName: 'file',
                  label: 'File',
                },
              ]}
            />
          </>
        );

      case 'FDP_DAR_EXT.1.1':
        return (
          <>
            <strong>Methods:</strong>
            <DataTable
              data={niapFindings[finding].data.methods}
              columns={[
                {
                  keyName: 'method_name',
                  component: ApiCall,
                  label: 'API Call',
                },
                {
                  label: 'Parameters',
                  component: MethodCallParams,
                },
              ]}
            />
          </>
        );

      case 'FDP_DEC_EXT.1.1':
        return (
          <DataTable
            data={niapFindings[finding].data.permissions}
            columns={[
              {
                keyName: 'name',
                label: 'Name',
                filterable: true,
                component: ({ rowData }) => (
                  <a href={rowData.link} target="_blank">
                    {rowData.name}
                  </a>
                ),
              },
            ]}
          />
        );

      case 'FDP_DEC_EXT.1.2':
        return (
          <DataTable
            data={niapFindings[finding].data.permissions}
            columns={[
              {
                keyName: 'name',
                label: 'Name',
                filterable: true,
                component: ({ rowData }) => (
                  <a href={rowData.link} target="_blank">
                    {rowData.name}
                  </a>
                ),
              },
            ]}
          />
        );

      case 'FDP_NET_EXT.1.1':
        return (
          <>
            {niapFindings[finding].data.ports ? (
              <Row className="text-center">
                <Col>
                  <strong>UDP:</strong>
                </Col>
                <Col>{niapFindings[finding].data.ports.udp}</Col>
                <Col>
                  <strong>TCP:</strong>
                </Col>
                <Col>{niapFindings[finding].data.ports.tcp}</Col>
              </Row>
            ) : null}
            <hr />
            <Row className="text-center">
              <Col>
                <strong>Internet:</strong>
              </Col>
              <Col>{niapFindings[finding].data.internet}</Col>
            </Row>
          </>
        );

      case 'FMT_CFG_EXT.1.1':
        return (
          <>
            <div>
              <strong>Methods:</strong>
            </div>
            <DataTable
              data={niapFindings[finding].data.review}
              columns={[
                {
                  keyName: 'class_with_implementation',
                  label: 'Class',
                },
                {
                  keyName: 'file',
                  label: 'File',
                },
              ]}
            />
          </>
        );

      case 'FMT_CFG_EXT.1.2':
        return (
          <>
            <div>
              <strong>Methods:</strong>
            </div>
            <DataTable
              data={niapFindings[finding].data.methods}
              columns={[
                {
                  keyName: 'method_name',
                  component: ApiCall,
                  label: 'API Call',
                },
                {
                  label: 'Parameters',
                  component: MethodCallParams,
                },
              ]}
            />
          </>
        );

      case 'FMT_MEC_EXT.1.1':
        const { new_pref_files: newPrefFiles = [], differences = [] } =
          niapFindings[finding].data;
        return (
          <ListGroup>
            {newPrefFiles.map(key => {
              const list = differences
                .filter(difference => difference[key])
                .map(difference => difference[key]);

              return (
                <ListGroupItem style={{ display: 'flex' }}>
                  <h5>
                    <Badge color="light">{key}:</Badge>
                  </h5>{' '}
                  &nbsp;
                  <div>
                    {list.map(item => (
                      <div>{item}</div>
                    ))}
                  </div>
                </ListGroupItem>
              );
            })}
          </ListGroup>
        );

      case 'FMT_SMF.1.1': {
        const { pii } = niapFindings[finding].data;
        return (
          <Card title="PII:">
            <PiiTable data={pii} />
          </Card>
        );
      }

      case 'FPR_ANO_EXT.1.1': {
        const { pii } = niapFindings[finding].data;

        return (
          <Card title="PII:">
            <PiiTable data={pii} />
          </Card>
        );
      }
      case 'FPT_AEX_EXT.1.1': {
        const { mmap } = niapFindings[finding].data;

        return (
          <Row>
            <strong>mMap:</strong>
            <DynamicTable data={mmap} />
          </Row>
        );
      }
      case 'FPT_AEX_EXT.1.2': {
        const { mprotect = [], mmap = [] } = niapFindings[finding].data;

        const hasMprotect = mprotect.length !== 0;
        const hasMmap = mmap.length !== 0;

        return (
          <Row>
            {hasMprotect && (
              <Col span={10}>
                <strong>mprotect</strong>
                <ListGroup>
                  {mprotect.map(item => (
                    <ListGroupItem>{item}</ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
            )}
            &nbsp;&nbsp;&nbsp;
            {hasMmap && (
              <Col span={10}>
                <strong>mmap</strong>
                <ListGroup>
                  {mmap.map(item => (
                    <ListGroupItem>{item}</ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
            )}
          </Row>
        );
      }
      case 'FPT_AEX_EXT.1.3':
        return (
          // No data, always passes
          <></>
        );

      case 'FPT_AEX_EXT.1.4': {
        const { data = [] } = niapFindings[finding];
        return (
          <Row>
            <Col>
              <strong>Data:</strong>
              <br />
              <ListGroup>
                {data.map(item => (
                  <ListGroupItem>{item}</ListGroupItem>
                ))}
              </ListGroup>
            </Col>
          </Row>
        );
      }
      case 'FPT_AEX_EXT.1.5': {
        const { data = [] } = niapFindings[finding];

        return (
          <Row>
            <Col>
              <strong>Data:</strong>
              <ListGroup>
                {data.map(item => (
                  <ListGroupItem>{item}</ListGroupItem>
                ))}
              </ListGroup>
            </Col>
          </Row>
        );
      }
      case 'FPT_LIB_EXT.1.1':
        return (
          <>
            <Row className="text-center">
              <Col xs="2">
                <strong>Libraries:</strong>
              </Col>
              <Col>
                {niapFindings[finding].data.libraries.map(library => (
                  <p key={library}>{library}</p>
                ))}
              </Col>
            </Row>
            <hr />
            <Row className="text-center">
              <Col xs="2">
                <strong>SDK:</strong>
              </Col>
              <Col>
                {niapFindings[finding].data.sdks
                  ? Object.keys(niapFindings[finding].data.sdks).map(key => (
                      <p key={key}>{key}</p>
                    ))
                  : 'Not Available'}
              </Col>
            </Row>
          </>
        );

      case 'FPT_TUD_EXT.1.2':
        return (
          <Row className="text-center">
            <Col xs="2">
              <strong>Data:</strong>
            </Col>
          </Row>
        );

      case 'FPT_TUD_EXT.1.3':
        return (
          <>
            <strong>Methods:</strong>
            <DataTable
              data={niapFindings[finding].data.methods}
              columns={[
                {
                  keyName: 'method_name',
                  component: ApiCall,
                  label: 'API Call',
                },
                {
                  label: 'Parameters',
                  component: MethodCallParams,
                },
              ]}
            />
          </>
        );

      case 'FPT_TUD_EXT.1.4': {
        const {
          changed_new: changedNew = [],
          changed_old: changedOld = [],
          added = [],
          same = [],
          removed = [],
        } = niapFindings[finding].data;

        return (
          <>
            <Row>
              {changedNew.length > 0 && (
                <Col span={10}>
                  <ListGroup>
                    <h5>Changed New</h5>
                    {changedNew.map(data => (
                      <ListGroupItem>{data}</ListGroupItem>
                    ))}
                  </ListGroup>
                </Col>
              )}
              {changedOld.length > 0 && (
                <Col span={10}>
                  <ListGroup>
                    <h5>Changed Old</h5>
                    {changedOld.map(data => (
                      <ListGroupItem>{data}</ListGroupItem>
                    ))}
                  </ListGroup>
                </Col>
              )}
            </Row>
            <br />
            <Row>
              {added.length > 0 && (
                <Col span={10}>
                  <ListGroup>
                    <h5>Added</h5>
                    {added.map(data => (
                      <ListGroupItem>{data}</ListGroupItem>
                    ))}
                  </ListGroup>
                </Col>
              )}
              {same.length > 0 && (
                <Col span={10}>
                  <ListGroup>
                    <h5>Same</h5>
                    {same.map(data => (
                      <ListGroupItem>{data}</ListGroupItem>
                    ))}
                  </ListGroup>
                </Col>
              )}
            </Row>
            {removed.length > 0 && (
              <ListGroup>
                <h5>Removed</h5>
                {removed.map(data => (
                  <ListGroupItem>{data}</ListGroupItem>
                ))}
              </ListGroup>
            )}
          </>
        );
      }

      case 'FPT_TUD_EXT.1.5':
        return (
          <>
            <Row className="text-center">
              <Col xs="2">
                <strong>Version Name:</strong>
              </Col>
              <Col>{niapFindings[finding].data.version_name}</Col>
            </Row>
            <Row className="text-center">
              <Col xs="2">
                <strong>Version Code:</strong>
              </Col>
              <Col>{niapFindings[finding].data.version_code}</Col>
            </Row>
          </>
        );

      case 'FPT_TUD_EXT.1.6':
        return <CertInfoTable />;

      case 'FTP_DIT_EXT.1.1':
        return (
          <Row className="text-center">
            <Col>
              <strong>Internet:</strong>
            </Col>
            <Col>{niapFindings[finding].data.internet}</Col>
          </Row>
        );

      default:
        return <h1>{finding}</h1>;
    }
  } else {
    switch (finding) {
      case 'FCS_RBG_EXT.1.1':
        return (
          <div>
            <div>
              <strong>Methods:</strong>
            </div>
            <ListGroup>
              {niapFindings[finding].data.methods.map((method, index) => (
                <ListGroupItem key={index}>{method}</ListGroupItem>
              ))}
            </ListGroup>
          </div>
        );

      case 'FDP_DEC_EXT.1.1':
        return (
          <DataTable
            data={niapFindings[finding].data.permissions}
            columns={[
              {
                keyName: 'link',
                label: 'Link',
                filterable: true,
              },
              {
                keyName: 'name',
                label: 'Name',
                filterable: true,
              },
            ]}
          />
        );

      case 'FDP_DEC_EXT.1.2':
        return (
          <DataTable
            data={niapFindings[finding].data.permissions}
            columns={[
              {
                keyName: 'link',
                label: 'Link',
                filterable: true,
              },
              {
                keyName: 'name',
                label: 'Name',
                filterable: true,
              },
            ]}
          />
        );

      case 'FDP_NET_EXT.1.1':
        return (
          <Row className="text-center">
            <Col>
              <strong>Internet:</strong>
            </Col>
            <Col>{niapFindings[finding].data.internet}</Col>
          </Row>
        );

      case 'FPR_ANO_EXT.1.1': {
        const { pii } = niapFindings[finding].data;
        return (
          <Card title="PII:">
            <PiiTable data={pii} />
          </Card>
        );
      }
      case 'FMT_SMF.1.1': {
        const { pii } = niapFindings[finding].data;
        return (
          <Card title="PII:">
            <PiiTable data={pii} />
          </Card>
        );
      }
      case 'FPT_AEX_EXT.1.1': {
        const { calls } = niapFindings[finding].data;

        return (
          <Card title="Calls:">
            <DynamicTable data={calls} />
          </Card>
        );
      }
      case 'FPT_AEX_EXT.1.2': {
        const { calls } = niapFindings[finding].data;

        return (
          <Card title="Calls:">
            <DynamicTable data={calls} />
          </Card>
        );
      }

      case 'FPT_AEX_EXT.1.4':
        // no results for ios since all writes are done to sandbox
        // https://www.niap-ccevs.org/Documents_and_Guidance/view_td.cfm?TD=0238
        return null;

      case 'FMT_MEC_EXT.1.1':
        return (
          <DataTable
            data={niapFindings[finding].data.nsuserdefaults_operations}
            columns={[
              {
                keyName: 'name',
                label: 'Name',
                filterable: true,
              },
              {
                keyName: 'operation',
                label: 'Operation',
                filterable: true,
              },
              {
                keyName: 'value',
                label: 'Value',
                filterable: true,
                component: ({ value }) => <div>{JSON.stringify(value)}</div>,
              },
            ]}
          />
        );

      case 'FMT_CFG_EXT.1.1': {
        const { data = [] } = niapFindings[finding];

        return (
          <Card>
            <CardHeader>Data:</CardHeader>
            <CardBody>
              <ListGroup>
                {data.map(datum => (
                  <ListGroupItem
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    {Object.entries(datum).map(([key, item]) => (
                      <div style={{ maxWidth: '100px', display: 'flex' }}>
                        <h5>
                          <Badge color="light">{key}:</Badge>
                        </h5>{' '}
                        &nbsp; <div>{JSON.stringify(item)}</div>
                      </div>
                    ))}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </CardBody>
          </Card>
        );
      }
      case 'FPT_LIB_EXT.1.1': {
        const { data = {} } = niapFindings[finding];

        const libraries = _.zip(
          splitProps(_.get(data, `libraries["Dynamic Libraries"]`, [])),
          splitProps(_.get(data, `libraries["System Frameworks"]`, [])),
          splitProps(_.get(data, `libraries["System Libraries"]`, [])),
        ).map(([dynamicLibraries, systemFrameworks, systemLibraries]) => ({
          dynamicLibraries,
          systemFrameworks,
          systemLibraries,
        }));

        const dynLibraries = _.zip(
          _.get(data, `["dyn-libraries"].__EXECUTABLE__`, []),
          _.get(data, `["dyn-libraries"].__SYSTEM__`, []),
        ).map(([executable = {}, system = {}]) => ({
          __EXECUTABLE__: executable,
          __SYSTEM__: system,
        }));

        return (
          <>
            <Table>
              <thead>
                <tr>
                  <th rowSpan="1" colSpan="3" style={{ textAlign: 'center' }}>
                    Libraries
                  </th>
                </tr>
                <tr>
                  <th colSpan="1">Dynamic Libraries</th>
                  <th colSpan="1">System Frameworks</th>
                  <th colSpan="1">System Libraries</th>
                </tr>
              </thead>
              <tbody>
                {libraries.map(
                  ({ dynamicLibraries, systemFrameworks, systemLibraries }) => (
                    <tr>
                      <td>
                        <TreeList values={dynamicLibraries} />
                      </td>
                      <td style={{ maxWidth: '300px' }}>
                        <TreeList values={systemFrameworks} />
                      </td>
                      <td>
                        <TreeList values={systemLibraries} />
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </Table>
            <Table>
              <thead>
                <tr>
                  <th rowSpan="1" colSpan="2" style={{ textAlign: 'center' }}>
                    Dynamic Libraries
                  </th>
                </tr>
                <tr>
                  <th colSpan="1">__EXECUTABLE__</th>
                  <th colSpan="1">__SYSTEM__</th>
                </tr>
              </thead>
              <tbody>
                {dynLibraries.map(({ __EXECUTABLE__, __SYSTEM__ }) => (
                  <tr>
                    <td style={{ maxWidth: '100px' }}>
                      <TreeList values={__EXECUTABLE__} />
                    </td>
                    <td style={{ maxWidth: '100px' }}>
                      <TreeList values={__SYSTEM__} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        );
      }
      case 'FTP_DIT_EXT.1.1': {
        const { data = {} } = niapFindings[finding];

        const getAllBools = obj =>
          Object.entries(obj).filter(
            ([_, value]) => typeof value === 'boolean',
          );
        return (
          <>
            {getAllBools(data).map(([key, bool]) => (
              <div style={{ maxWidth: '100px', display: 'flex' }}>
                <h5>
                  <Badge color="light">{key}:</Badge>
                </h5>{' '}
                &nbsp; <div>{JSON.stringify(bool)}</div>
              </div>
            ))}

            <Row>
              <Col>
                <Card>
                  <CardHeader>NSAppTransportSecurity</CardHeader>
                  <CardBody>
                    {getAllBools(data.NSAppTransportSecurity || {}).map(
                      ([key, bool]) => (
                        <div style={{ maxWidth: '100px', display: 'flex' }}>
                          <h5>
                            <Badge color="light">{key}:</Badge>
                          </h5>{' '}
                          &nbsp; <div>{JSON.stringify(bool)}</div>
                        </div>
                      ),
                    )}
                    <h6 orientation="left">NSExceptionDomains</h6>
                    {_.chunk(
                      Object.entries(
                        _.get(
                          data,
                          `NSAppTransportSecurity.NSExceptionDomains`,
                          {},
                        ),
                      ),
                      2,
                    ).map(apps => (
                      <Row>
                        {apps.map(([key, app]) => (
                          <Col span={12}>
                            <Card>
                              <CardHeader>{key}</CardHeader>
                              <CardBody>
                                {Object.entries(app).map(([key, bool]) => (
                                  <p style={{ display: 'flex' }}>
                                    <h5>
                                      <Badge color="light">{key}:</Badge>
                                    </h5>
                                    &nbsp;<div>{JSON.stringify(bool)}</div>
                                  </p>
                                ))}
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    ))}
                  </CardBody>
                </Card>
              </Col>
              <Card title="NSAppTransportSecurity_xml">
                <XMLViewer xml={data.NSAppTransportSecurity_xml} />
              </Card>
            </Row>
          </>
        );
      }
      case 'FPT_TUD_EXT.1.2':
        return (
          <Row className="text-center">
            <Col xs="2">
              <strong>Result:</strong>
            </Col>
            <Col>{niapFindings[finding].data.result}</Col>
          </Row>
        );

      case 'FPT_TUD_EXT.1.3':
        // eslint-disable-next-line
        const { data = [] } = niapFindings[finding];

        return (
          <Table>
            <thead>
              <tr>
                <th>Mode</th>
                <th>Filepath</th>
              </tr>
            </thead>
            <tbody>
              {data.map(({ mode, filepath }) => (
                <tr>
                  <td>{mode}</td>
                  <td>{filepath}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        );

      case 'FPT_TUD_EXT.1.4':
        return (
          <DataTable
            data={niapFindings[finding].data}
            columns={[
              {
                keyName: 'executable',
                label: 'executable',
                filterable: true,
              },
              {
                keyName: 'changes_detected',
                label: 'Changes Detected',
                filterable: true,
              },
            ]}
          />
        );

      case 'FPT_TUD_EXT.1.5':
        return (
          <>
            <Row className="text-center">
              <Col xs="2">
                <strong>Long:</strong>
              </Col>
              <Col>{niapFindings[finding].data.long}</Col>
            </Row>
            <Row className="text-center">
              <Col xs="2">
                <strong>Short:</strong>
              </Col>
              <Col>{niapFindings[finding].data.short}</Col>
            </Row>
          </>
        );

      case 'FPT_TUD_EXT.1.6':
        return (
          <>
            <Row className="text-center">
              <Col xs="3">
                <strong>Code Signature:</strong>
              </Col>
            </Row>
            <Row>
              <Col xs="1" />
              <Col xs="3">
                <strong>App Name:</strong>
              </Col>
              <Col>
                {niapFindings[finding].data.codesignature_details.appname}
              </Col>
            </Row>
            <Row>
              <Col xs="1" />
              <Col xs="3">
                <strong>Compiler:</strong>
              </Col>
              <Col>
                {niapFindings[finding].data.codesignature_details.Compiler}
              </Col>
            </Row>
            <Row>
              <Col xs="1" />
              <Col xs="3">
                <strong>Minimum OS Version:</strong>
              </Col>
              <Col>
                {
                  niapFindings[finding].data.codesignature_details
                    .MinimumOSVersion
                }
              </Col>
            </Row>
            <Row>
              <Col xs="1" />
              <Col xs="3">
                <strong>Platform:</strong>
              </Col>
              <Col>
                {niapFindings[finding].data.codesignature_details.PlatformName}
              </Col>
            </Row>
            <Row>
              <Col xs="1" />
              <Col xs="3">
                <strong>Platform Version:</strong>
              </Col>
              <Col>
                {
                  niapFindings[finding].data.codesignature_details
                    .PlatformVersion
                }
              </Col>
            </Row>
            <Row>
              <Col xs="1" />
              <Col xs="3">
                <strong>Provisioning Profile App ID:</strong>
              </Col>
              <Col>
                {niapFindings[finding].data.codesignature_details
                  .provisioning_profile_app_id ?? 'Not Available'}
              </Col>
            </Row>
            <Row>
              <Col xs="1" />
              <Col xs="3">
                <strong>Developer:</strong>
              </Col>
              <Col>
                {niapFindings[finding].data.codesignature_details.developer ??
                  'Not Available'}
              </Col>
            </Row>
            <Row>
              <Col xs="1" />
              <Col xs="3">
                <strong>Creation Date:</strong>
              </Col>
              <Col>
                {niapFindings[finding].data.codesignature_details
                  .creation_date ?? 'Not Available'}
              </Col>
            </Row>
            <Row>
              <Col xs="1" />
              <Col xs="3">
                <strong>Expiration Date:</strong>
              </Col>
              <Col>
                {niapFindings[finding].data.codesignature_details
                  .expiration_date ?? 'Not Available'}
              </Col>
            </Row>
            <Row>
              <Col xs="1" />
              <Col xs="3">
                <strong>Authority:</strong>
              </Col>
              <Col>
                {niapFindings[finding].data.codesignature_details.authority ??
                  'Not Available'}
              </Col>
            </Row>
            <Row>
              <Col xs="1" />
              <Col xs="3">
                <strong>Application Identifier:</strong>
              </Col>
              <Col>
                {niapFindings[finding].data.codesignature_details
                  .application_identifier ?? 'Not Available'}
              </Col>
            </Row>
            <Row>
              <Col xs="1" />
              <Col xs="3">
                <strong>Team ID:</strong>
              </Col>
              <Col>
                {niapFindings[finding].data.codesignature_details.team_id ??
                  'Not Available'}
              </Col>
            </Row>
          </>
        );

      case 'FCS_CKM_EXT.1': {
        const { calls } = niapFindings[finding].data;

        return (
          <Card title="Calls:">
            <DynamicTable data={calls} />
          </Card>
        );
      }
      case 'FCS_CKM.2': {
        const { ciphers } = niapFindings[finding].data;

        return (
          <>
            <Row xs="2">
              <strong>Ciphers:</strong>
            </Row>
            <Row>
              <ListGroup>
                {ciphers.map(cipher => (
                  <ListGroupItem>{cipher}</ListGroupItem>
                ))}
              </ListGroup>
            </Row>
          </>
        );
      }

      case 'FPT_AEX_EXT.1.3':
        return (
          <Row className="text-center">
            <Col xs="2">
              <strong>Result:</strong>
            </Col>
            <Col>{niapFindings[finding].data.result}</Col>
          </Row>
        );

      case 'FCS_CKM.1.1': {
        const { data = [] } = niapFindings[finding];
        return (
          <Row className="text-center">
            <Col>
              <strong>Data:</strong>
              {!_.isEmpty(data) ? (
                <DynamicTable data={data} />
              ) : (
                <p>No operations found</p>
              )}
            </Col>
          </Row>
        );
      }
      case 'FCS_COP.1(1)': {
        const { encryption_ops: encryptionOps } = niapFindings[finding].data;

        return (
          <Row>
            <Col>
              <strong>Encryption Ops:</strong>
              <DynamicTable data={encryptionOps} />
            </Col>
          </Row>
        );
      }
      case 'FCS_COP.1(2)': {
        const { hash_ops: hashOps } = niapFindings[finding].data;

        return (
          <Row>
            <Col>
              <strong>Hash Ops:</strong>
              <DynamicTable data={hashOps} />
            </Col>
          </Row>
        );
      }
      case 'FCS_COP.1(3)': {
        const { calls } = niapFindings[finding].data;

        return (
          <Row>
            <Col>
              <strong>Calls:</strong>
              <DynamicTable data={calls} />
            </Col>
          </Row>
        );
      }
      case 'FCS_HTTPS_EXT.1.1': {
        const trafficEntries = (traffic?.log?.entries ?? [])
          .filter(entry => entry.request.url.startsWith('http://'))
          .map(entry => entry.request.url);

        return (
          <>
            <strong>Insecure URLs:</strong>
            <ListGroup flush>
              {trafficEntries.map(traffic => (
                <ListGroupItem>{traffic}</ListGroupItem>
              ))}
            </ListGroup>
          </>
        );
      }

      default:
        return <h1>{finding}</h1>;
    }
  }
}
