import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QContainer } from '../Q-Components/QContainer';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';

export default function ApprovalApp({ app }) {
  const { mastV2 } = useFlags();

  let farmAppName;

  try {
    farmAppName = app.name ? decodeURIComponent(app.name) : '';
  } catch (error) {
    farmAppName = app.name || '';
  }

  return (
    <QContainer
      fluid
      style={mastV2 ? { padding: '0px', background: 'none' } : {}}
    >
      <QRow
        sx={{
          columnGap: '16px',
        }}
      >
        <QCol flex="1" xs="2" className="text-right">
          <QRow>
            {app.iconUrl ? (
              <img
                src={app.iconUrl.trim()}
                className="app-icon"
                alt="App Icon"
                style={{ width: 'unset' }}
              />
            ) : app.iconBase64 ? (
              <img
                alt="Icon"
                src={`data:image/png;base64,${app.iconBase64}`}
                height="30px"
                style={{ width: 'unset' }}
              />
            ) : app.platform === 'android' ? (
              <i className="fa-brands fa-android fa-2x" />
            ) : (
              <i className="fa-brands fa-apple fa-2x" />
            )}
          </QRow>
        </QCol>

        <QCol flex="5" xs="10" className="text-left">
          <QRow>
            <strong>{farmAppName}</strong>
          </QRow>
          <QRow style={mastV2 ? { color: '#757575', fontWeight: '600' } : {}}>
            {app.package}
          </QRow>
          {mastV2 ? null : <QRow>{app.platform}</QRow>}
          <QRow>
            {app.iconUrl &&
              (app.platform === 'android' ? (
                <i
                  data-testid="app-approval-icon-test"
                  className="fa-brands fa-android mt-1"
                />
              ) : (
                <i className="fa-brands fa-apple mt-1" />
              ))}
          </QRow>
        </QCol>
      </QRow>
    </QContainer>
  );
}
