import React from 'react';
import { Table } from 'reactstrap';

import util from '../../../logic/util';
import { TreeList } from '../TreeList';

/**
 * Table for rendering PII evidence for niap issues
 *
 * @param {object} props
 * @param {Array<{ context: string; type: string; value: string; }>} props.data array to render
 *
 */
export const PiiTable = ({ data = [] }) => {
  if (data.length === 0) {
    return null;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Context</th>
          <th>Type</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index} style={{ wordBreak: 'break-all' }}>
            <td style={{ maxWidth: '500px' }}>{row.context}</td>
            <td style={{ maxWidth: '500px' }}>{row.type}</td>
            <td style={{ maxWidth: '500px' }}>
              {util.isJson(row.value) ? (
                <TreeList values={JSON.parse(row.value)} />
              ) : (
                row.value
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
