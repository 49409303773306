import React, { Component } from 'react';

import DynamicAnalysisDatatable from '../../../Android/DynamicAnalysisDatatable';
import SmaliAnalysisDatatable from '../../../Android/SmaliAnalysisDatatable';
import TabsView from '../../../../TabsView';
import i18n from '../../../../../localization/i18n';

export default class AcceptsAllCerts extends Component {
  render() {
    const tabs = [
      {
        title: i18n.t('Coding Issues'),
        tabContent: () => (
          <SmaliAnalysisDatatable filter="accepts_all_ssl_certs" />
        ),
      },
      {
        title: i18n.t('Dynamic Analysis'),
        tabContent: () => (
          <DynamicAnalysisDatatable showFilter={false} filter="ALLOW_ALL" />
        ),
      },
    ];
    return <TabsView tabs={tabs} />;
  }
}
