import React from 'react';
import { Bar } from 'react-chartjs-2';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import i18n from '../../localization/i18n';
import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { toastrHelper } from '../../logic/toastrHelper';

const LDTopCwes = ({ platform, isLoading, header, cweData, appType }) => {
  const { mastV2 } = useFlags();

  let borderColor;
  let backgroundColor;
  if (platform === 'android') {
    borderColor = mastV2 ? '#AED581' : '#388e3c';
    backgroundColor = mastV2 ? '#AED581' : '#388e3c';
  } else {
    borderColor = mastV2 ? '#64B5F6' : '#1565c0';
    backgroundColor = mastV2 ? '#64B5F6' : '#1565c0';
  }

  const chartData = {
    labels: cweData
      // Return an array of the issue names
      .map(item => item.cwe),
    datasets: [
      {
        borderColor,
        backgroundColor,
        data: cweData.map(item => item.cwe_count),
      },
    ],
  };

  const copyString = `${chartData.labels.join(
    ',',
  )}\n${chartData.datasets[0].data.join(',')}`;

  return (
    <QCard className="card-accent-secondary">
      <QCardHeader
        title={i18n.t(header)}
        action={
          <span>
            <CopyToClipboard
              text={copyString}
              onCopy={() =>
                toastrHelper.showSuccessToast(
                  'Copied results to clipboard',
                  null,
                  mastV2,
                )
              }
            >
              <i className="fa-solid fa-paste pointer" />
            </CopyToClipboard>
            {isLoading && <i className="fa-solid fa-spinner fa-spin" />}
          </span>
        }
      >
        <div className="d-flex justify-between">
          <strong>{i18n.t(header)}</strong>
          <span>
            <CopyToClipboard
              text={copyString}
              onCopy={() =>
                toastrHelper.showSuccessToast(
                  'Copied results to clipboard',
                  null,
                  mastV2,
                )
              }
            >
              <i className="fa-solid fa-paste pointer" />
            </CopyToClipboard>
            {isLoading && <i className="fa-solid fa-spinner fa-spin" />}
          </span>
        </div>
      </QCardHeader>
      <QCardBody>
        <Bar
          data={chartData}
          height={200}
          options={{
            maintainAspectRatio: false,
            scales: {
              y: {
                ticks: {
                  autoSkip: false,
                },
              },
            },
            indexAxis: 'y',
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                display: context =>
                  context.dataset.data[context.dataIndex] >= 1,
                color: 'white',
                clamp: true,
              },
              title: {
                display: true,
                text: `Number of top CWEs of ${
                  platform === 'android' ? 'Android' : 'iOS'
                } ${appType ? `${appType}` : ''} apps`,
                position: 'bottom',
                font: {
                  weight: 'normal',
                  style: 'italic',
                },
              },
            },
          }}
          onElementsClick={item => {
            if (item[0]) {
              if (cweData[item[0]._index].cwe_link) {
                window.open(cweData[item[0]._index].cwe_link);
              }
            }
          }}
        />
      </QCardBody>
    </QCard>
  );
};

const TopCwes = withLDConsumer()(LDTopCwes);

export default TopCwes;
