import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Consent from './GDPR/Consent';
import Encryption from './GDPR/Encryption';
import PersonalData from './GDPR/PersonalData';
import PrivacyByDesign from './GDPR/PrivacyByDesign';
import PrivacyImpactAssessment from './GDPR/PrivacyImpactAssessment';
import Processing from './GDPR/Processing';
import RightOfAccess from './GDPR/RightOfAccess';
import RightToBeForgotten from './GDPR/RightToBeForgotten';
import RightToBeInformed from './GDPR/RightToBeInformed';

class GDPRreport extends Component {
  render() {
    const { showMap, platform } = this.props;
    return (
      <div>
        <Consent platform={platform} />
        <Encryption platform={platform} />
        <PersonalData platform={platform} />
        <PrivacyByDesign showMap={showMap} platform={platform} />
        <PrivacyImpactAssessment showMap={showMap} platform={platform} />
        <Processing platform={platform} />
        <RightOfAccess showMap={showMap} platform={platform} />
        <RightToBeForgotten platform={platform} />
        <RightToBeInformed platform={platform} />
      </div>
    );
  }
}

export default withRouter(GDPRreport);
