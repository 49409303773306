import React, { Component } from 'react';

import TabsView from '../../../../TabsView';
import PermissionsRequestedDatatable from '../../../iOS/PermissionsRequestedDatatable';
import SystemFrameworksDatatable from '../../../iOS/SystemFrameworksDatatable';
import i18n from '../../../../../localization/i18n';

export default class AccessPhotosEvidence extends Component {
  render() {
    const tabs = [
      {
        title: i18n.t('Permissions Requested'),
        tabContent: () => <PermissionsRequestedDatatable filter="photo" />,
      },
      {
        title: i18n.t('Frameworks'),
        tabContent: () => (
          <SystemFrameworksDatatable filter="photo" showFilter={false} />
        ),
      },
    ];
    return <TabsView tabs={tabs} />;
  }
}
