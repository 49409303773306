import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';

import i18n from '../../../localization/i18n';

class ExtraPermissionsTable extends Component {
  render() {
    const { results } = this.props;

    const { extra_permissions: extraPermissions } =
      results.analysis.analysis_details.static_analysis;
    if (Object.keys(extraPermissions).length > 0) {
      return (
        <Table size="sm">
          <tbody>
            {Object.entries(extraPermissions).map(([perm, link]) => (
              <tr key={perm}>
                <td>
                  <a className="no-link" href={link} target="_blank">
                    {perm}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    }
    return <span>{i18n.t('No extra permissions')}</span>;
  }
}

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(ExtraPermissionsTable);
