import React from 'react';

const isPrimitive = val =>
  typeof val === 'string' ||
  typeof val === 'boolean' ||
  typeof val === 'number';

/**
 * Takes object or array as a parameter and dynamically generates recursive list element,
 * it's useful for quickly protoyping and/or condensing a ui for deep JSONs. Recommended usage
 * is to render the top 1-3 levels of json using a Table/List and use TreeList for deeper children.
 *
 * ex.
 * <Table>
 *  <thead>
 *    // render first level of json as a table
 *    { Object.keys(data[0]).map( header => <th>{header}</th>) }
 *  </thead>
 *  <tbody>
 *   {data.map(datum =>
 *     <tr>{
 *       datum.map( cell =>
 *        <td>{ typeof cell === 'object'
 *            // Render deep object as TreeList
 *            ?  <TreeList values={cell} />
 *            : cell }
 *       </td>}
 *     </tr>)}
 *  </tbody>
 * </Table>
 *
 * @param { object } props
 * @param { object | any[] | null | undefined } props.values array/object to be rendered
 * @param { number } props.spaces amount of space of indent for list
 * @param { number } props.max  max number of properties to be rendered per level of JSON
 */
export const TreeList = ({ values, spaces = 0, max = Infinity }) => {
  const spaceWidth = 5 * spaces;
  if (!values) {
    return null;
  }

  if (Array.isArray(values)) {
    if (values.length === 0) {
      return null;
    }
    if (typeof values[0] === 'string') {
      return (
        <ul>
          {values.map(val => (
            <li>{val}</li>
          ))}
        </ul>
      );
    }
    const headers = Object.keys(values[0]);
    return (
      <div style={{ paddingLeft: `${spaceWidth}px` }}>
        {values.length > max ? <em>Displaying first {max} results</em> : null}
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(auto-fill, minmax(${
              60 / headers.length
            }%, 1fr))`,
            gridGap: '1rem',
          }}
        >
          {headers.map(header => (
            <strong style={{ textOverflow: 'ellipsis' }}>{header}</strong>
          ))}
        </div>

        {values.slice(0, max).map(item => (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(auto-fill, minmax(${
                60 / headers.length
              }%, 1fr))`,
              gridGap: '1rem',
            }}
          >
            {isPrimitive(item) ? (
              <div
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {JSON.stringify(item)}
              </div>
            ) : Array.isArray(item) ? (
              <TreeList values={item} spaces={spaces + 1} max={max} />
            ) : (
              Object.values(item).map(val => (
                <div>
                  {isPrimitive(val) ? (
                    <div
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {JSON.stringify(val)}
                    </div>
                  ) : (
                    <TreeList values={val} spaces={spaces + 1} max={max} />
                  )}
                </div>
              ))
            )}
          </div>
        ))}
      </div>
    );
  }
  values = Object.entries(values);
  return (
    <div style={{ paddingLeft: `${spaceWidth}px` }} className="break-all">
      {values.length > max ? <em>Displaying first {max} results</em> : null}
      {values.slice(0, max).map(([key, value]) => {
        if (typeof value === 'object') {
          return (
            <div>
              <strong>{key}</strong>:
              <TreeList values={value} spaces={spaces + 1} max={max} />
            </div>
          );
        }

        return (
          <div style={{ paddingLeft: `${spaceWidth}px` }}>
            <strong>{key}</strong>: {JSON.stringify(value)}
          </div>
        );
      })}
    </div>
  );
};
