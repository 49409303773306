import React from 'react';
import { CardText } from 'reactstrap';

import KeychainSecurityAccessibleEvidence from '../../SimpleReport/ReportFindingCard/IosEvidence/KeychainSecurityAccessibleEvidence';
import CanWriteExternalStorage from '../../SimpleReport/ReportFindingCard/AndroidEvidence/CanWriteExternalStorage';
import DynamicAnalysisDatatable from '../../Android/DynamicAnalysisDatatable';
import CryptoOperationsDatatable from '../../iOS/CryptoOperationsDatatable';

import { Issues } from './Issues';

export const V2_8 = ({ platform, evidence }) => (
  <>
    <Issues
      evidence={evidence}
      issues={[
        'CAN_WRITE_EXTERNAL_STORAGE',
        'KEYCHAIN_SECURITY_ACCESSIBLE',
        'ALLOWBACKUP',
      ]}
    />
    <hr />
    {platform === 'android' ? (
      <>
        <CardText>
          <strong>Dynamic Analysis</strong>
        </CardText>
        <DynamicAnalysisDatatable />
        <hr />
        <CanWriteExternalStorage />
      </>
    ) : (
      <>
        <CardText>
          <strong>Insufficient KeyChain Operations</strong>
        </CardText>
        <KeychainSecurityAccessibleEvidence />
        <hr />
        <CardText>
          <strong>Cryptographic Operations</strong>
        </CardText>
        <CryptoOperationsDatatable />
      </>
    )}
  </>
);
