import React from 'react';
import _ from 'lodash';

import DataTable from '../../../DataTable/DataTable';

export const V5_5 = ({ evidence }) => {
  const requests = _.get(evidence, 'requests', []);

  return (
    <>
      <DataTable
        data={requests}
        rowsPerPage={5}
        showFilter={false}
        columns={[
          {
            keyName: 'startedDateTime',
            label: 'Started Date Time',
          },
          {
            keyName: 'request',
            label: 'Request',
            component: ({ value }) => <p>{JSON.stringify(value)}</p>,
          },
          {
            keyName: 'response',
            label: 'Response',
            component: ({ value }) => <p>{JSON.stringify(value)}</p>,
          },
          {
            keyName: 'serverIPAddress',
            label: 'Server IP Address',
          },
          {
            keyName: 'cipher_name',
            label: 'Cipher Name',
          },
          {
            keyName: 'tls_version',
            label: 'Tls Version',
          },
          {
            keyName: 'server_cert',
            label: 'Server Cert',
          },
        ]}
      />
    </>
  );
};
