import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import PermissionsRequestedDatatable from '../iOS/PermissionsRequestedDatatable';
import HostsContactedMap from '../HostsContactedMap';
import CountriesContactedList from '../CountriesContactedList';
import FileAccessDatatable from '../iOS/FileAccessDatatable';
import DynamicallyLoadedLibrariesDatatable from '../iOS/DynamicallyLoadedLibrariesDatatable';
import SensitiveDataExposureDatatable from '../SensitiveDataExposureDatatable';
import PermissionsRequestedTable from '../Android/PermissionsRequestedTable';
import DynamicAnalysisDatatable from '../Android/DynamicAnalysisDatatable';
import TabsView from '../../TabsView';
import Findings from '../Findings';

class RightOfAccess extends Component {
  render() {
    const { showMap, platform, parsedIssues } = this.props;

    const tabs = [
      {
        title: 'Overview',
        tabContent: () => (
          <Row>
            <Col>
              <Findings
                findings={parsedIssues}
                platform={platform}
                category="privacy"
                showOwaspIcon={false}
              />
            </Col>
            <Col>
              <Findings
                findings={parsedIssues}
                platform={platform}
                category="hardware"
                showOwaspIcon={false}
              />
            </Col>
          </Row>
        ),
      },
      {
        title: 'Permissions Requested',
        tabContent: () =>
          platform === 'ios' ? (
            <PermissionsRequestedDatatable />
          ) : (
            <PermissionsRequestedTable />
          ),
      },
      {
        title: 'Network Map',
        tabContent: () => (
          <HostsContactedMap showMap={showMap} platform={platform} />
        ),
      },
      {
        title: 'Contries Contacted',
        tabContent: () => <CountriesContactedList platform={platform} />,
      },
      {
        title: 'File Access',
        tabContent: () =>
          platform === 'ios' ? (
            <FileAccessDatatable />
          ) : (
            <DynamicAnalysisDatatable onlySelectedCategory="Files Accessed" />
          ),
      },
      {
        title: 'Dynamically Loaded Libraries',
        tabContent: () =>
          platform === 'ios' ? (
            <DynamicallyLoadedLibrariesDatatable />
          ) : (
            <DynamicAnalysisDatatable onlySelectedCategory="Libraries loaded" />
          ),
      },
      {
        title: 'Sensitive Data Exposure',
        tabContent: () => (
          <SensitiveDataExposureDatatable platform={platform} />
        ),
      },
    ];

    return (
      <div>
        <Card className="card-accent-navy">
          <CardHeader>Right Of Access</CardHeader>
          <CardBody>
            <div>
              &quot;The right of access plays a central role in the General Data
              Protection Regulation (GDPR). On the one hand, because only the
              right of access allows the data subject to exercise further rights
              (such as rectification and erasure). On the other hand, because an
              omitted or incomplete disclosure is subject to fines.&quot;
            </div>
            <div>
              <a
                href="https://gdpr-info.eu/issues/right-of-access/"
                target="_blank"
              >
                More Info
              </a>
            </div>
            <hr />
            <TabsView tabs={tabs} />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ appResults }) => ({
  parsedIssues: appResults.parsedIssues,
});

export default withRouter(connect(mapStateToProps)(RightOfAccess));
