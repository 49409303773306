import React, { Component } from 'react';

import DataTable from '../../../DataTable/DataTable';

export default class HardCodedKeys extends Component {
  render() {
    const { data } = this.props;
    return (
      <DataTable
        data={data}
        columns={[
          {
            label: 'Type',
            keyName: 'type',
            component: ({ value }) => (
              <span style={{ textTransform: 'capitalize' }}>
                {value.split('_').join(' ')}
              </span>
            ),
          },
          {
            label: 'Key',
            keyName: 'value',
          },
        ]}
      />
    );
  }
}
