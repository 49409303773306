import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';

import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';
import { invalidateAnalyzedAppsCache } from '../../store/slices/cache/analyzedAppsCacheSlice';
import { QModal } from '../Q-Components/QModal';
import { QModalTitle } from '../Q-Components/QModalTitle';
import { QModalContent } from '../Q-Components/QModalContent';
import { QModalActions } from '../Q-Components/QModalActions';
import { QRow } from '../Q-Components/QRow';
import { QSelect } from '../Q-Components/QSelect';
import { QAutocomplete } from '../Q-Components/QAutocomplete';
import { QInput } from '../Q-Components/QInput';
import { QChip } from '../Q-Components/QChip';
import { QButton } from '../Q-Components/QButton';
import { QLabel } from '../Q-Components/QLabel';
import { QFormGroup } from '../Q-Components/QFormGroup';
import { QTypography } from '../Q-Components/QTypography';
import { QAppIcon } from '../Q-Components/QAppIcon';
import { fetchLabelOptions } from '../../store/sliceHelpers/analyzedAppsCacheSliceHelper';

export default function EditAppDetails({
  app,
  analyzedAppId,
  reviewStatus,
  note,
  appLabels = [],
  labelOptions = [],
  pageNum = 1,
  getApps,
  toggle,
  open,
}) {
  const { mastV2 } = useFlags();
  const customizedEid = useSelector(
    state => state.emmApp.userConfig.customized_eid,
  );
  const reviewStatusOptions = [
    { value: 'archived', label: i18n.t('Archived') },
    { value: 'approved', label: i18n.t('Approved') },
    { value: 'pending', label: i18n.t('Pending') },
  ];
  const [loading, setLoading] = useState(false);
  const [currentNote, setCurrentNote] = useState(note ? note.note : '');
  const [currentReviewStatus, setCurrentReviewStatus] = useState(
    reviewStatusOptions.find(option => option.value === reviewStatus),
  );
  const [currentLabels, setCurrentLabels] = useState([...appLabels]);
  const [updatedLabels, setUpdatedLabels] = useState([...appLabels]);
  const lang = useSelector(state => state.emmApp.portalConfig.lang);
  const dispatch = useDispatch();
  const noteRequired = useSelector(
    state => state.emmApp.userConfig.note_required_review_status,
  );

  let farmAppName;

  try {
    farmAppName = decodeURIComponent(app.farm_app.name);
  } catch (error) {
    farmAppName = app.farm_app.name;
  }

  const addLabel = async label =>
    axios.post(`api/labels/add-label`, {
      label,
      analyzedAppId,
    });

  const removeLabel = async label =>
    axios.delete(`api/labels/remove-label`, {
      data: {
        label,
        analyzedAppId,
      },
    });

  const updateNote = async note =>
    axios.patch('app-note', {
      analyzedAppId,
      note,
      removed: false,
    });

  const updateReviewStatus = async status =>
    axios.patch(`analyzed-apps/${analyzedAppId}/review-status`, {
      status,
    });

  useEffect(() => {
    setCurrentReviewStatus(
      reviewStatusOptions.find(option => option.value === reviewStatus),
    );
    // eslint-disable-next-line
  }, [lang]);

  const changeStatus = async () => {
    if ((note ? note.note : '') !== currentNote) {
      try {
        await updateNote(currentNote);
      } catch (err) {
        console.log('Error patching app note:', err);
        toastrHelper.showErrorToast(
          i18n.t('Error saving app note'),
          null,
          mastV2,
        );
      }
    }

    if (reviewStatus !== currentReviewStatus.value) {
      try {
        await updateReviewStatus(currentReviewStatus.value);

        toastrHelper.showSuccessToast(
          i18n.t('Updated app status'),
          null,
          mastV2,
        );
      } catch (err) {
        console.log('Error updating analyzed app status:', err);
        toastrHelper.showErrorToast(
          i18n.t('Error updating analyzed app status'),
          null,
          mastV2,
        );
      }
    }

    const removedLabels = currentLabels.filter(
      initialLabel =>
        !updatedLabels.some(
          updatedLabel => updatedLabel.value === initialLabel.value,
        ),
    );

    const addedLabels = updatedLabels.filter(
      updatedLabel =>
        !currentLabels.some(
          initialLabel => updatedLabel.value === initialLabel.value,
        ),
    );

    removedLabels.forEach(async label => {
      try {
        await removeLabel(label.value);
      } catch (err) {
        console.log('Error processing label:', label.value, err);
      }
    });

    addedLabels.forEach(async label => {
      try {
        await addLabel(label.value);
      } catch (err) {
        console.log('Error processing label:', label.value, err);
      }
    });

    setCurrentLabels([...updatedLabels]);

    await dispatch(invalidateAnalyzedAppsCache());
    await dispatch(fetchLabelOptions());

    await getApps(pageNum);

    setLoading(false);
    toggle();
  };

  const handleReviewStatusOptionChange = (event, newReviewStatus) => {
    if (mastV2) {
      const newOption = reviewStatusOptions.find(
        option => option.value === newReviewStatus.props.value,
      );
      setCurrentReviewStatus(newOption);
    } else {
      setCurrentReviewStatus(event);
    }
  };

  const handleNoteChange = e => {
    setCurrentNote(e.target.value);
  };

  const handleLabelsChange = (event, newLabelValues) => {
    if (mastV2) {
      if (
        newLabelValues.length > 0 &&
        newLabelValues[newLabelValues.length - 1].inputValue
      ) {
        const newLabel = {
          value: newLabelValues[newLabelValues.length - 1].inputValue,
          label: _.startCase(
            newLabelValues[newLabelValues.length - 1].inputValue,
          ),
        };
        newLabelValues.pop();
        newLabelValues.push(newLabel);
      }
      setUpdatedLabels([...newLabelValues]);
    } else {
      setUpdatedLabels([...event]);
    }
  };

  return (
    <QModal
      open={open}
      isOpen={open}
      toggle={toggle}
      title={mastV2 && <QModalTitle>{i18n.t('Edit App Details')}</QModalTitle>}
      content={
        mastV2 && (
          <QModalContent sx={{ padding: '16px' }}>
            <QRow sx={{ marginBottom: '20px' }}>
              <Grid container spacing={2}>
                <Grid item>
                  <QAppIcon
                    app={app}
                    platform={app.platform}
                    height="20px"
                    width="20px"
                    listView
                  />
                </Grid>

                <Grid item>
                  <Grid item>
                    <QTypography variant="bodyRegular" color="black">
                      {farmAppName}
                    </QTypography>
                  </Grid>
                  <Grid item>
                    <QTypography variant="h6Medium">
                      {app.farm_app.package}
                    </QTypography>
                  </Grid>
                  <Grid item>
                    <QTypography variant="h6Medium">
                      {app.farm_app.platform === 'android' ? (
                        <i className="fa-brands fa-android mt-1 black-icon" />
                      ) : (
                        <i className="fa-brands fa-apple mt-1 black-icon" />
                      )}
                      &nbsp;
                      {app.farm_app.version}
                    </QTypography>
                  </Grid>
                  {app.external_id ||
                    (app.externalId && (
                      <Grid item>
                        <QTypography variant="h6Medium">
                          {customizedEid || 'EID'}:{' '}
                          {app.external_id || app.externalId}
                        </QTypography>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </QRow>
            <QRow sx={{ marginBottom: '20px' }}>
              <QSelect
                formControlProps={{ sx: { width: '100%' } }}
                label={i18n.t('Review Status')}
                options={reviewStatusOptions}
                value={currentReviewStatus.value}
                onChange={handleReviewStatusOptionChange}
                selectProps={{
                  sx: { backgroundColor: 'white' },
                }}
              />
            </QRow>
            <QRow sx={{ marginBottom: '20px' }}>
              <textarea
                placeholder={
                  noteRequired
                    ? 'Note (Minimum 10 characters)'
                    : 'Note (Optional)'
                }
                className="form-control"
                onChange={handleNoteChange}
                value={currentNote}
                rows="4"
                style={{ borderRadius: '4px', borderColor: '#BDBDBD' }}
                data-testid="app-issue-note-input"
              />
            </QRow>
            <QRow sx={{ marginBottom: '20px' }}>
              <QAutocomplete
                sx={{ width: '100%' }}
                multiple
                freeSolo
                value={updatedLabels}
                options={labelOptions.filter(
                  option =>
                    !updatedLabels.some(label => label.value === option.value),
                )}
                onChange={handleLabelsChange}
                popupIcon={<ExpandMoreIcon fontSize="medium" />}
                clearIcon={<ClearIcon fontSize="medium" />}
                renderInput={params => (
                  <QInput
                    {...params}
                    outlinedWithTitle
                    label="Labels (Optional, Maximum of 3)"
                    style={{ textOverflow: 'ellipsis' }}
                    size="small"
                    InputProps={{
                      endAdornment: params.InputProps.endAdornment,
                      startAdornment: params.InputProps.startAdornment,
                      sx: {
                        '&.MuiOutlinedInput-root': {
                          display: 'flex',
                          alignItems: 'center',
                          paddingRight: '40px',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          position: 'absolute',
                          right: 8,
                          top: '50%',
                          transform: 'translateY(-50%)',
                        },
                        '& .MuiAutocomplete-endAdornment': {
                          position: 'absolute',
                          right: 8,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          display: 'flex',
                          alignItems: 'center',
                        },
                      },
                      inputProps: {
                        ...params.inputProps,
                        'data-testid': 'labels-input',
                      },
                      ref: params.InputProps.ref,
                      style: {
                        backgroundColor: 'white',
                      },
                    }}
                  />
                )}
                renderTags={(values, getTagProps) =>
                  values.map((option, index) => (
                    <QChip
                      label={
                        updatedLabels.find(
                          label => label.value === option.value,
                        )?.label
                      }
                      {...getTagProps({ index })}
                    />
                  ))
                }
                filterOptions={(options, params) => {
                  const filter = createFilterOptions();
                  const filtered = filter(options, params);
                  const { inputValue } = params;

                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    option => inputValue === option.value,
                  );
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      label: `Add "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                getOptionDisabled={option => updatedLabels.length === 3}
              />
            </QRow>
          </QModalContent>
        )
      }
      actions={
        mastV2 && (
          <QModalActions>
            <QButton
              variant="light"
              onClick={() => {
                toggle();
                setUpdatedLabels(currentLabels);
              }}
              disabled={loading}
            >
              {i18n.t('Cancel')}
            </QButton>
            <QButton
              variant="filled"
              onClick={changeStatus}
              disabled={
                loading ||
                (currentNote.length < 10 &&
                  noteRequired &&
                  ((note ? note.note : '') !== currentNote ||
                    reviewStatus !== currentReviewStatus.value))
              }
            >
              {i18n.t('Save')}
            </QButton>
          </QModalActions>
        )
      }
    >
      <QModalTitle>{i18n.t('Edit App Details')}</QModalTitle>
      <QModalContent>
        <QLabel for="app-issue-note-input">
          <strong>{i18n.t('Note')}:</strong>
        </QLabel>
        <QInput
          type="textarea"
          id="app-issue-note-input"
          data-testid="app-issue-note-input"
          value={currentNote}
          onChange={handleNoteChange}
        />

        <QButton
          color="warning"
          className="mt-2"
          onClick={() => setCurrentNote('')}
        >
          {i18n.t('Clear Note')}
        </QButton>
        <span style={{ float: 'right' }}>
          <small>
            {noteRequired ? (
              <i>{i18n.t('Minimum 10 characters')}</i>
            ) : (
              <i>{i18n.t('Optional')}</i>
            )}
          </small>
        </span>
        <hr />
        <QFormGroup>
          <QLabel for="review-status-input">
            <strong>{i18n.t('Review Status')}:</strong>
          </QLabel>
          <div data-testid="review-status-input">
            <QSelect
              options={reviewStatusOptions}
              value={currentReviewStatus}
              onChange={option => setCurrentReviewStatus(option)}
            />
          </div>
        </QFormGroup>
        <QFormGroup>
          <QLabel for="label-input">
            <strong>{i18n.t('Labels')}:</strong>
          </QLabel>
          <div data-testid="label-input">
            <CreatableSelect
              id="label-input"
              options={labelOptions}
              isMulti
              value={updatedLabels}
              onChange={options => {
                setUpdatedLabels([...options]);
              }}
              onCreateOption={newOption => {
                setUpdatedLabels([
                  ...updatedLabels,
                  { value: newOption, label: _.startCase(newOption) },
                ]);
              }}
              isOptionDisabled={option => updatedLabels.length >= 3}
            />
          </div>
          <span style={{ float: 'right' }}>
            <small>
              <i>{i18n.t('Optional, Maximum of 3')}</i>
            </small>
          </span>
        </QFormGroup>
      </QModalContent>
      <QModalActions>
        <QButton
          color="secondary"
          onClick={() => {
            toggle();
            setUpdatedLabels(currentLabels);
          }}
          disabled={loading}
        >
          {i18n.t('Cancel')}
        </QButton>
        <QButton
          color="primary"
          onClick={changeStatus}
          disabled={
            loading ||
            (currentNote.length < 10 &&
              noteRequired &&
              ((note ? note.note : '') !== currentNote ||
                reviewStatus !== currentReviewStatus.value))
          }
        >
          {i18n.t('Save')}
        </QButton>
      </QModalActions>
    </QModal>
  );
}
