import React from 'react';
import { CardBody } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QCardContent as MuiCardContent } from '@kw/quokka-ui';

const LDCardBody = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiCardContent {...props}>{children}</MuiCardContent>
  ) : (
    <CardBody {...props}>{children}</CardBody>
  );
};
export const QCardBody = withLDConsumer()(LDCardBody);
