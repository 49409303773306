// This file contains api operations for groupAdmin

import axios from 'axios';

const baseUrl = 'group-admin';

const getGroupAdminUsers = async config => {
  try {
    const users = await axios.get(`${baseUrl}/users`, config);
    return users.data;
  } catch (err) {
    return [];
  }
};

const getGroupAdminSubGroups = async () => {
  try {
    const subGroups = await axios.get(`${baseUrl}/sub-groups`);
    return subGroups.data;
  } catch (err) {
    return [];
  }
};

const getGroupAdminGroup = async () => {
  try {
    const group = await axios.get(`${baseUrl}/config`);
    return [group.data];
  } catch (err) {
    return [];
  }
};

export default {
  getGroupAdminUsers,
  getGroupAdminSubGroups,
  getGroupAdminGroup,
};
