import React from 'react';
import { TabPane } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QTabPanel as MuiTabPanel } from '@kw/quokka-ui';

const LDTabPanel = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  const filteredProps = ['tabId'];

  const muiTabPanelProps = Object.keys(props).reduce((acc, key) => {
    if (!filteredProps.includes(key)) {
      acc[key] = props[key];
    }
    return acc;
  }, {});
  return mastV2 ? (
    <MuiTabPanel {...muiTabPanelProps}>{children}</MuiTabPanel>
  ) : (
    <TabPane {...props}>{children}</TabPane>
  );
};
export const QTabPanel = withLDConsumer()(LDTabPanel);
