import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QSidebarSubHeader as MuiSidebarSubHeader } from '@kw/quokka-ui';

const LDSidebarSubHeader = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiSidebarSubHeader {...props}>{children}</MuiSidebarSubHeader>
  ) : (
    <li className="nav-title" {...props}>
      {children}
    </li>
  );
};
export const QSidebarSubHeader = withLDConsumer()(LDSidebarSubHeader);
