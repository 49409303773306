import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { AppVersion } from '../components/AppAllVersions/AppVersion';
import HelpButton from '../components/HelpButton';
import i18n from '../localization/i18n';
import Loading from '../components/Loading';
import AsyncTablePagination from '../components/AsyncTablePagination';
import { QCard } from '../components/Q-Components/QCard';
import { QCardHeader } from '../components/Q-Components/QCardHeader';
import { QCardBody } from '../components/Q-Components/QCardBody';
import { QRow } from '../components/Q-Components/QRow';
import { QCol } from '../components/Q-Components/QCol';
import { QButton } from '../components/Q-Components/QButton';
import { QTable } from '../components/Q-Components/QTable';
import { QTableBody } from '../components/Q-Components/QTableBody';
import { QTableCell } from '../components/Q-Components/QTableCell';
import { QTableHead } from '../components/Q-Components/QTableHead';
import { QTableRow } from '../components/Q-Components/QTableRow';
import { toastrHelper } from '../logic/toastrHelper';

export default function AppAllVersions() {
  const { analyzedAppId } = useParams();
  const history = useHistory();
  const [appVersions, setAppVersions] = useState([]);
  const [selectedApps, setSelectedApps] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 0,
    totalEntries: 0,
    entriesPerPage: 0,
  });
  const [page, setPage] = useState(1);
  const { mastV2 } = useFlags();

  const getAllVersions = async (pageNum = 1, event = null) => {
    if (event) event.preventDefault();

    try {
      const response = await axios.get(
        `/analyzed-apps/${analyzedAppId}/all-versions?page=${pageNum}`,
      );
      const allAnalyzedApps = response.data.allVersions;

      setPagination({
        lastPage: response.data.lastPage,
        totalEntries: response.data.total,
        entriesPerPage: response.data.perPage,
      });

      setPage(pageNum);

      await Promise.all(
        allAnalyzedApps.map(async app => {
          console.log('Parsing analyzed app:', app.id);

          try {
            const parsedIssuesResponse = await axios.get(
              `app-issues/parsed?uuid=${app.farm_app.uuid}`,
            );

            if (parsedIssuesResponse.data) {
              app.parsedIssues = parsedIssuesResponse.data.parsedAppIssues;
            }
          } catch (err) {
            app.parsedIssues = null;
            console.error(err);
          }
        }),
      );

      allAnalyzedApps.sort((a, b) =>
        b.farm_app.version.localeCompare(a.farm_app.version, undefined, {
          numeric: true,
        }),
      );

      let i;
      for (i = 0; i < allAnalyzedApps.length - 1; i++) {
        // Current version number of issues
        if (
          allAnalyzedApps[i].parsedIssues &&
          allAnalyzedApps[i + 1].parsedIssues
        ) {
          const currentVersionLowIssues = allAnalyzedApps[
            i
          ].parsedIssues.filter(
            issue => issue.found && issue.risk === 'low',
          ).length;
          const currentVersionMediumIssues = allAnalyzedApps[
            i
          ].parsedIssues.filter(
            issue => issue.found && issue.risk === 'medium',
          ).length;
          const currentVersionHighIssues = allAnalyzedApps[
            i
          ].parsedIssues.filter(
            issue => issue.found && issue.risk === 'high',
          ).length;
          const currentVersionCriticalIssues = allAnalyzedApps[
            i
          ].parsedIssues.filter(
            issue => issue.found && issue.risk === 'critical',
          ).length;

          // Previous version number of issues
          const previousVersionLowIssues = allAnalyzedApps[
            i + 1
          ].parsedIssues.filter(
            issue => issue.found && issue.risk === 'low',
          ).length;
          const previousVersionMediumIssues = allAnalyzedApps[
            i + 1
          ].parsedIssues.filter(
            issue => issue.found && issue.risk === 'medium',
          ).length;
          const previousVersionHighIssues = allAnalyzedApps[
            i + 1
          ].parsedIssues.filter(
            issue => issue.found && issue.risk === 'high',
          ).length;
          const previousVersionCriticalIssues = allAnalyzedApps[
            i + 1
          ].parsedIssues.filter(
            issue => issue.found && issue.risk === 'critical',
          ).length;

          // Get lowIssueDifferential
          if (currentVersionLowIssues > previousVersionLowIssues) {
            allAnalyzedApps[i].lowIssueDifferential = 1;
          } else if (currentVersionLowIssues < previousVersionLowIssues) {
            allAnalyzedApps[i].lowIssueDifferential = -1;
          } else {
            allAnalyzedApps[i].lowIssueDifferential = 0;
          }

          // Get mediumIssueDifferential
          if (currentVersionMediumIssues > previousVersionMediumIssues) {
            allAnalyzedApps[i].mediumIssueDifferential = 1;
          } else if (currentVersionMediumIssues < previousVersionMediumIssues) {
            allAnalyzedApps[i].mediumIssueDifferential = -1;
          } else {
            allAnalyzedApps[i].mediumIssueDifferential = 0;
          }

          // Get highIssueDifferential
          if (currentVersionHighIssues > previousVersionHighIssues) {
            allAnalyzedApps[i].highIssueDifferential = 1;
          } else if (currentVersionHighIssues < previousVersionHighIssues) {
            allAnalyzedApps[i].highIssueDifferential = -1;
          } else {
            allAnalyzedApps[i].highIssueDifferential = 0;
          }

          // Get criticalIssueDifferential
          if (currentVersionCriticalIssues > previousVersionCriticalIssues) {
            allAnalyzedApps[i].criticalIssueDifferential = 1;
          } else if (
            currentVersionCriticalIssues < previousVersionCriticalIssues
          ) {
            allAnalyzedApps[i].criticalIssueDifferential = -1;
          } else {
            allAnalyzedApps[i].criticalIssueDifferential = 0;
          }
        }
      }
      allAnalyzedApps[i].issueDifferential = 0;

      setAppVersions(allAnalyzedApps);
    } catch (err) {
      console.log('Error getting all versions of the app', err);
    }
  };

  useEffect(() => {
    getAllVersions();
  }, [analyzedAppId]);

  return (
    <div className="animated fadeIn">
      <QCard className="card-accent-info" id="analyzed-apps-card">
        <QCardHeader
          className="analyzed-apps-card-header"
          title={
            <QRow columnGap="15px">
              <QCol sx={{ alignContent: 'center' }}>
                {i18n.t('Analyzed App Versions')}
              </QCol>
              <QCol>
                <QButton
                  variant="filled"
                  onClick={() => {
                    if (selectedApps.length === 2) {
                      history.push(
                        `/analyzed-app/${selectedApps[0]}/compare-versions/${selectedApps[1]}`,
                      );
                    } else {
                      toastrHelper.showWarningToast(
                        i18n.t('Please select 2 app versions to compare'),
                        null,
                        mastV2,
                      );
                    }
                  }}
                >
                  {i18n.t('Compare Versions')}
                </QButton>
              </QCol>
            </QRow>
          }
        >
          <strong>{i18n.t('Analyzed App Versions')}</strong>{' '}
          <QButton
            className="pull-right"
            color="primary"
            style={{ marginTop: 1 }}
            onClick={() => {
              if (selectedApps.length === 2) {
                history.push(
                  `/analyzed-app/${selectedApps[0]}/compare-versions/${selectedApps[1]}`,
                );
              } else {
                toastrHelper.showWarningToast(
                  i18n.t('Please select 2 app versions to compare'),
                  null,
                  mastV2,
                );
              }
            }}
          >
            {i18n.t('Compare Versions')}
          </QButton>
        </QCardHeader>
        <QCardBody>
          <AsyncTablePagination
            pagination={pagination}
            getPage={getAllVersions}
            page={page}
          />
          {appVersions.length > 0 ? (
            <QTable>
              <QTableHead>
                <QTableRow>
                  <QTableCell width="15%" className="text-center">
                    {i18n.t('App')}&nbsp;
                    <HelpButton helpItem="appColumn" />
                  </QTableCell>
                  <QTableCell width="15%" className="text-center">
                    {i18n.t('Analysis Time')}&nbsp;
                    <HelpButton helpItem="analysisTime" />
                  </QTableCell>
                  <QTableCell width="15%" className="text-center">
                    {i18n.t('Date Submitted')}&nbsp;
                    <HelpButton helpItem="dateSubmitted" />
                  </QTableCell>
                  <QTableCell width="5%" className="text-center">
                    {i18n.t('Threat Score')}&nbsp;
                    <HelpButton helpItem="threatScore" />
                  </QTableCell>
                  <QTableCell width="30%" className="text-center">
                    {i18n.t('Important Risk Findings')}&nbsp;
                    <HelpButton helpItem="summaryResults" />
                  </QTableCell>
                  <QTableCell width="14%" className="text-center">
                    {i18n.t('Number of Issues')}&nbsp;
                    <HelpButton helpItem="summaryResults" />
                  </QTableCell>
                </QTableRow>
              </QTableHead>
              <QTableBody>
                {appVersions.map((app, index) => (
                  <AppVersion
                    app={app}
                    key={pagination.entriesPerPage * page + index}
                    selectedApps={selectedApps}
                    setSelectedApps={setSelectedApps}
                  />
                ))}
              </QTableBody>
            </QTable>
          ) : (
            <Loading />
          )}
        </QCardBody>
      </QCard>
    </div>
  );
}
