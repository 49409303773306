import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Label, Input } from 'reactstrap';

import { QSwitchControlLabel } from './Q-Components/QSwitchControlLabel';
import { QSwitch } from './Q-Components/QSwitch';

export default function Switch({ id, onChange, value, label, ...rest }) {
  const { mastV2 } = useFlags();
  return (
    <div {...rest}>
      {mastV2 && (
        <QSwitchControlLabel
          label={<strong>{label}</strong>}
          control={
            <QSwitch
              type="checkbox"
              className="switch-input"
              id={id}
              checked={value}
              onChange={onChange}
              value={value}
              data-testid={
                rest.datatestid ? rest.datatestid : 'test-switch-input'
              }
              disabled={rest.disabled}
              label={label}
            />
          }
        >
          <QSwitch
            type="checkbox"
            className="switch-input"
            id={id}
            checked={value}
            onChange={onChange}
            value={value}
            data-testid={
              rest.datatestid ? rest.datatestid : 'test-switch-input'
            }
            disabled={rest.disabled}
            label={label}
          />
        </QSwitchControlLabel>
      )}

      {!mastV2 && (
        <>
          <Label
            className={`switch switch-icon ${
              rest.disabled ? 'switch-light' : 'switch-secondary'
            }`}
          >
            <Input
              type="checkbox"
              className="switch-input"
              id={id}
              checked={value}
              onChange={onChange}
              value={value}
              data-testid={
                rest.datatestid ? rest.datatestid : 'test-switch-input'
              }
              disabled={rest.disabled}
            />
            <span
              className="switch-label"
              data-on={'\uf00c'}
              data-off={'\uf00d'}
            />
            <span className="switch-handle" />
          </Label>

          <span className="text-center ml-1">
            <strong>{label}</strong>
          </span>
        </>
      )}
    </div>
  );
}
