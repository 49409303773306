import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { GroupUsers } from '../../GroupAdmin/GroupUsers';
import { fetchKaiGroups } from '../../../store/sliceHelpers/kaiSliceHelper';

export function Users() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchKaiGroups());
  }, []);

  return (
    <>
      <GroupUsers kaiAccess />
    </>
  );
}
