import React from 'react';
import { CardText } from 'reactstrap';

import DynamicAnalysisDatatable from '../../Android/DynamicAnalysisDatatable';
import { StaticAnalysisDatatable } from '../../Android/StaticAnalysisDatatable';
import SmaliAnalysisDatatable from '../../Android/SmaliAnalysisDatatable';
import CryptoOperationsDatatable from '../../iOS/CryptoOperationsDatatable';
import { FunctionsDetectedDataTable } from '../../iOS/FunctionsDetectedDatatable';

import { Issues } from './Issues';

export const V1_8 = ({ platform, evidence }) => (
  <>
    <Issues
      evidence={evidence}
      issues={[
        'HARD_CODED_CREDENTIALS',
        'USES_HARD_CODED_CREDENTIALS',
        'HARD_CODED_KEY',
        'HARD_CODED_IV',
      ]}
    />
    <hr />
    {platform === 'android' ? (
      <>
        <CardText>
          <strong>Hard Coded Credentials</strong>
        </CardText>
        <SmaliAnalysisDatatable filter="crypto_key" />
        <hr />
        <CardText>
          <strong>Encryptions</strong>
        </CardText>
        <DynamicAnalysisDatatable
          onlySelectedCategory={['Cryptography', 'Crypto', 'Crypto Events']}
        />
        <hr />
        <CardText>
          <strong>Static Analysis</strong>
        </CardText>
        <StaticAnalysisDatatable categoryFilter="methods" keyFilter="all" />
      </>
    ) : (
      <>
        <CardText>
          <strong>Cryptographic Operations</strong>
        </CardText>
        <CryptoOperationsDatatable />
        <hr />
        <CardText>
          <strong>Functions Detected</strong>
        </CardText>
        <FunctionsDetectedDataTable />
      </>
    )}
  </>
);
