import React, { Component } from 'react';

import DataTable from '../../../DataTable/DataTable';
import TabsView from '../../../TabsView';

export default class PermissionsUsage extends Component {
  render() {
    const { data } = this.props;

    const tabs = [
      {
        title: 'Permission Requested',
        tabContent: () => (
          <DataTable
            data={data.permissions_requested}
            columns={[
              {
                label: 'Permission',
                component: ({ rowData }) => <span>{rowData}</span>,
              },
            ]}
          />
        ),
      },
      {
        title: 'Method Using Permissions',
        tabContent: () => (
          <DataTable
            data={data.methods_using_permissions}
            columns={[
              {
                label: 'Method Call',
                component: ({ rowData }) => <span>{rowData}</span>,
              },
            ]}
          />
        ),
      },
    ];

    return <TabsView tabs={tabs} />;
  }
}
