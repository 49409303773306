import axios from 'axios';

const baseUrl = 'adminV2';

const getKaiUsers = async config => {
  try {
    const users = await axios.get(`${baseUrl}/users`, config);
    return users.data;
  } catch (err) {
    return [];
  }
};

const getKaiGroups = async () => {
  try {
    const groups = await axios.get(`${baseUrl}/groups`);
    return groups.data;
  } catch (err) {
    return [];
  }
};

export default {
  getKaiUsers,
  getKaiGroups,
};
