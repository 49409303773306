import React from 'react';

export default function MethodCallParams({ rowData: call }) {
  const formatMethodParam = param => {
    if (param.startsWith('Return type:')) {
      const parts = param.split(', ');
      return parts.map((part, index) => (
        <React.Fragment key={index}>
          <strong>{part.split(':')[0]}: </strong>
          <span>{part.split(':').slice(1).join('').replace(/<br>/g, '')}</span>
          <br />
        </React.Fragment>
      ));
    }
    return (
      <>
        <strong>{param.split(':')[0]}: </strong>
        <span>{param.split(':').slice(1).join('').replace(/<br>/g, '')}</span>
      </>
    );
  };

  return (
    <div>
      {call.parameters.map(
        (param, index) =>
          param && <div key={index}>{formatMethodParam(param)}</div>,
      )}
    </div>
  );
}
