import React from 'react';
import { connect, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ThreatScore } from '../ThreatScore';
import { DateTime } from '../DateTime';
import { SubGroup } from '../SubGroup';
import i18n from '../../localization/i18n';
import { AppApprovalStatusBadge } from '../AppApporvalStatusBadge';
import { QButton } from '../Q-Components/QButton';
import { QBadge } from '../Q-Components/QBadge';
import { QChip } from '../Q-Components/QChip';
import { QTooltip } from '../Q-Components/QTooltip';
import { QTableRow } from '../Q-Components/QTableRow';
import { QTableCell } from '../Q-Components/QTableCell';
import { QCol } from '../Q-Components/QCol';

import { AnalyzedAppFindings } from './AnalyzedAppFindings';
import AppActions from './AppActions';
import AnalyzedAppInfo from './AnalyzedAppInfo';
import AppReports from './AppReports';
import { AnalysisTime } from './AnalysisTime';

/**
 * Analyzed App component
 * @param {*} props
 */
const AnalyzedApp = ({
  app,
  userAccess,
  isMdmApp,
  removeApp,
  getApps,
  resubmitApp,
  userHasSubGroup,
  labelOptions,
  pageNum,
}) => {
  const { mastV2 } = useFlags();

  const { columnSettings } = useSelector(state => state.emmApp);

  const cancelledStatus = 30;

  const completedOrPostProcessingStatuses = [2]; // Completed

  const processingStatuses = [0, 1, 5]; // Not submitted, processing, and resubmitted

  const analysisErrorStatus = (
    <>
      <QTooltip
        placement="right"
        target="analysisErrorStatus"
        title="Quokka has already been notified of the problem"
        enterDelay={400}
        enterNextDelay={400}
      >
        <div style={{ padding: '0px', width: '60px' }}>
          <QChip
            color="danger"
            pill
            id="analysisErrorStatus"
            label="Error"
            variant="secondary"
          >
            Error
          </QChip>
        </div>
      </QTooltip>
    </>
  );

  return (
    <QTableRow>
      {/* App data */}
      <QTableCell sx={{ verticalAlign: 'top' }}>
        <AnalyzedAppInfo
          app={app}
          isMdmApp={isMdmApp}
          userAccess={userAccess}
          completedOrPostProcessingStatuses={completedOrPostProcessingStatuses}
        />
      </QTableCell>

      {/* Sub Group */}
      {userHasSubGroup && columnSettings.subGroupsCol && (
        <QTableCell sx={{ verticalAlign: 'top' }}>
          <SubGroup subGroup={app.subgroup_info} />
        </QTableCell>
      )}

      {/* Analysis time */}
      {columnSettings.analysisTimeCol && (
        <QTableCell className="text-center" sx={{ verticalAlign: 'top' }}>
          <AnalysisTime
            analysisStart={app.analysis_start}
            analysisEnd={app.analysis_end}
            status={app.farm_app.status}
          />
        </QTableCell>
      )}

      {isMdmApp && (
        <QTableCell className="text-center" sx={{ verticalAlign: 'top' }}>
          <h6>
            <QBadge color="dark" label={app.devices_installed_on} pill />
          </h6>
        </QTableCell>
      )}

      {/* Date Submitted */}
      {columnSettings.dateCol && (
        <QTableCell className="text-center" sx={{ verticalAlign: 'top' }}>
          <DateTime dateTime={app.submitted_at} />
        </QTableCell>
      )}

      {/* Submitted By */}
      {columnSettings.submittingUserCol && (
        <QTableCell className="wrap-text" sx={{ verticalAlign: 'top' }}>
          {app.user?.email}
        </QTableCell>
      )}

      {/* Threat Score */}
      {columnSettings.scoreCol && (
        <QTableCell className="text-center" sx={{ verticalAlign: 'top' }}>
          {userAccess.can_view_reports ? (
            <Link
              to={
                userAccess.can_view_reports &&
                completedOrPostProcessingStatuses.includes(app.farm_app.status)
                  ? `/${app.farm_app.platform}-report/${
                      app.farm_app.uuid ? app.farm_app.uuid : app.farm_app.id
                    }`
                  : '#'
              }
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <ThreatScore
                threatScore={app.threat_score}
                status={app.farm_app.status}
                analysisApprovalStatus={app.approvalStatus}
              />
            </Link>
          ) : (
            <ThreatScore
              threatScore={app.threat_score}
              status={app.farm_app.status}
              analysisApprovalStatus={app.approvalStatus}
            />
          )}
        </QTableCell>
      )}

      {/* App Issues */}
      {columnSettings.findingsCol ? (
        ['pending', 'denied'].includes(app.approvalStatus) ? (
          <QTableCell className="text-left" sx={{ verticalAlign: 'top' }}>
            <AppApprovalStatusBadge status={app.approvalStatus} />
          </QTableCell>
        ) : processingStatuses.includes(app.farm_app.status) ? (
          <QTableCell className="text-center" sx={{ verticalAlign: 'top' }}>
            <i className="fa-solid fa-spinner fa-spin" />
          </QTableCell>
        ) : ![...completedOrPostProcessingStatuses, cancelledStatus].includes(
            app.farm_app.status,
          ) ? (
          <QTableCell className="text-center" sx={{ verticalAlign: 'top' }}>
            {analysisErrorStatus}
          </QTableCell>
        ) : app.farm_app.status === cancelledStatus ? (
          <QTableCell className="text-center">
            <QChip color="danger" pill variant="secondary" label="Cancelled">
              <i className="fa-solid fa-rectangle-xmark mr-1" />
              Cancelled
            </QChip>
          </QTableCell>
        ) : (
          <QTableCell sx={{ verticalAlign: 'top' }}>
            <AnalyzedAppFindings parsedIssues={app.parsed_issues} />{' '}
          </QTableCell>
        )
      ) : null}

      {/* Actions */}
      <QTableCell className="text-center" sx={{ verticalAlign: 'top' }}>
        {['pending', 'denied'].includes(app.approvalStatus) ? (
          <AppApprovalStatusBadge status={app.approvalStatus} />
        ) : app.farm_app.status !== 2 && app.farm_app.status !== 10 ? (
          app.farm_app.status === cancelledStatus &&
          (userAccess.group_admin || userAccess.user_id === app.user_id) ? (
            <QButton
              className={classnames('btn-resubmit-app btn-report', {
                'resubmit-flash': app.has_analysis_issue,
              })}
              onClick={() => resubmitApp(app.id)}
              id={`resubmit-btn-app-${app.id}`}
              title="Resubmit app for analysis"
            >
              <i className="fa-solid fa-rotate" />
            </QButton>
          ) : !processingStatuses.includes(app.farm_app.status) ? (
            userAccess.group_admin || userAccess.can_delete_apps ? (
              <QButton color="link" onClick={() => removeApp(app.id)}>
                <i className="fa-solid fa-lg fa-trash-can danger-icon" />
              </QButton>
            ) : (
              analysisErrorStatus
            )
          ) : (
            <i className="fa-solid fa-spinner fa-spin" />
          )
        ) : (
          <>
            {!mastV2 ? (
              <div style={{ columnGap: '10px', justifyContent: 'center' }}>
                {userAccess.can_view_reports && <AppReports app={app} />}

                <AppActions
                  app={app}
                  removeApp={removeApp}
                  getApps={getApps}
                  resubmitApp={resubmitApp}
                  labelOptions={labelOptions}
                  pageNum={pageNum}
                />
              </div>
            ) : (
              <div columnGap="10px" className="analyzed-apps-reports-actions">
                <QCol>
                  {userAccess.can_view_reports && <AppReports app={app} />}
                </QCol>
                <QCol>
                  <AppActions
                    app={app}
                    removeApp={removeApp}
                    getApps={getApps}
                    resubmitApp={resubmitApp}
                    labelOptions={labelOptions}
                    pageNum={pageNum}
                  />
                </QCol>
              </div>
            )}

            {app.app_note && app.app_note.note && (
              <div
                style={{
                  textAlign: 'center',
                  borderRadius: 4,
                  border: '1px solid #c4c4c4',
                }}
                className="p-2 mt-3"
              >
                <div>
                  <strong>{i18n.t('Note')}:</strong>
                  <br />
                  <span>{app.app_note.note}</span>
                </div>
              </div>
            )}
          </>
        )}
      </QTableCell>
    </QTableRow>
  );
};

const mapStateToProps = ({ emmApp }) => ({
  userAccess: emmApp.userAccess,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(AnalyzedApp);
