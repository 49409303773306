import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import toastr from 'toastr';

import { toastrHelper } from '../../logic/toastrHelper';
import { QButton } from '../Q-Components/QButton';
import { QModal } from '../Q-Components/QModal';
import { QModalTitle } from '../Q-Components/QModalTitle';
import { QModalContent } from '../Q-Components/QModalContent';
import { QTypography } from '../Q-Components/QTypography';
import { QModalActions } from '../Q-Components/QModalActions';

export const AddToWatchlistConfirmationModal = ({ isOpen, toggle, app }) => {
  const addToWatchlist = async app => {
    const { package: pckg, platform, version, name } = app.farm_app;
    const { id: analyzedAppId, subgroup_info: subgroupInfo } = app;

    try {
      await axios.post(`analyzed-apps/${analyzedAppId}/add-to-watchlist`, {
        package: pckg,
        latestVersion: version,
        platform,
        subGroupIds: subgroupInfo.join(','),
      });

      toastr.success(`Successfully added app: ${name} to watchlist`, 'Success');
    } catch (err) {
      toastrHelper.showErrorToast(
        `Error adding app: ${name} to watchlist: ${err.response?.data?.message}`,
        'Error',
      );
    } finally {
      toggle();
    }
  };

  const Title = () => (
    <QModalTitle onClose={toggle} closeIcon={<CloseIcon />}>
      <QTypography variant="h3Medium">Confirm Add To Watchlist</QTypography>
    </QModalTitle>
  );

  const Body = () => (
    <QModalContent>
      <QTypography variant="h5Regular">
        Are you sure to add this app: <strong>{app.farm_app.name}</strong> to
        watchlist? This action will automatically scan new versions
      </QTypography>
    </QModalContent>
  );

  const Footer = () => (
    <QModalActions>
      <QButton variant="outlined" color="secondary" onClick={toggle}>
        Cancel
      </QButton>

      <QButton
        variant="filled"
        color="primary"
        onClick={() => addToWatchlist(app)}
      >
        Confirm
      </QButton>
    </QModalActions>
  );

  return (
    <QModal
      open={isOpen}
      isOpen={isOpen}
      toggle={toggle}
      title={<Title />}
      content={<Body />}
      actions={<Footer />}
    >
      <>
        <Title />
        <Body />
        <Footer />
      </>
    </QModal>
  );
};
