import React from 'react';
import { Button, UncontrolledCollapse } from 'reactstrap';
import { useSelector } from 'react-redux';
import XMLViewer from 'react-xml-viewer';

import i18n from '../../../localization/i18n';

export default function AppTransportSecurity() {
  const niapResults = useSelector(state => state.appResults.niapResults);
  if (!niapResults) {
    return null;
  }

  const sections =
    niapResults.niap_sections ||
    (niapResults.niap && niapResults.niap.niap_sections);

  if (!sections) {
    return null;
  }

  const appTransportSecurity = sections['FTP_DIT_EXT.1.1'];

  return (
    <div>
      <div className="mb-3">
        {appTransportSecurity.data.NSAppTransportSecurityDisabled ? (
          <>
            <div style={{ fontSize: '1rem' }}>
              <i className="fa-regular fa-circle-xmark text-danger" />{' '}
              {i18n.t('NSAppTransportSecurity Disabled')}
            </div>
            <Button color="primary" id="toggler" className="mb-2 mt-2">
              {i18n.t('Show XML from Info.plist')}
            </Button>
            <UncontrolledCollapse toggler="#toggler">
              <XMLViewer
                xml={appTransportSecurity.data.NSAppTransportSecurity_xml}
              />
            </UncontrolledCollapse>
          </>
        ) : (
          <span>
            <i className="fa-regular fa-circle-check text-success" />{' '}
            {i18n.t('NSAppTransportSecurity Enabled')}
          </span>
        )}
      </div>
    </div>
  );
}
