import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QTable } from './Q-Components/QTable';
import { QTableRow } from './Q-Components/QTableRow';
import { QTableHead } from './Q-Components/QTableHead';
import { QTableCell } from './Q-Components/QTableCell';
import { QTableBody } from './Q-Components/QTableBody';
import { QInput } from './Q-Components/QInput';
import { QInputIconWrapper } from './Q-Components/QInputIconWrapper';
import { QIconButton } from './Q-Components/QIconButton';
import AsyncTablePagination from './AsyncTablePagination';
import { QSelect } from './Q-Components/QSelect';
import { QRow } from './Q-Components/QRow';
import { QCol } from './Q-Components/QCol';
import { QLabel } from './Q-Components/QLabel';

export const AsyncPaginatedTable = ({
  columns = [],
  fetchData = async () => {},
  filteredColumns = [],
}) => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 0,
    totalData: 0,
    dataPerPage: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({ value: 10, label: '10' });
  const pageSizeOptions = [
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState({});
  const { mastV2 } = useFlags();

  const convertFilterObject = obj =>
    Object.keys(obj).reduce((acc, key) => {
      acc[key] = obj[key]?.value ?? obj[key];
      return acc;
    }, {});

  const getData = async (pageNum = 1, event = null) => {
    if (event) event.preventDefault();
    let additionalParams = {};

    if (mastV2) {
      additionalParams = filters;
    } else {
      additionalParams = convertFilterObject(filters);
    }

    const params = {
      query,
      page: pageNum,
      pageSize: pageSize.value,
      ...additionalParams,
    };

    try {
      const data = await fetchData(params);
      setData(data.entries);
      setPagination({
        lastPage: data.lastPage,
        totalData: data.total,
        dataPerPage: data.perPage,
      });
      setPage(pageNum);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFilterChange = (key, event) => {
    let value;
    if (mastV2) {
      value = event.target.value;
    } else {
      value = event;
    }

    setFilters(prevFilters => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handlePageSizeChange = option => {
    setPageSize(option);
  };

  const handleSearchQuery = e => {
    setQuery(e.target.value);
  };

  const handleKeyPress = e => {
    if (e.charCode === 13) {
      e.preventDefault();
      getData(1);
    }
  };

  useEffect(() => {
    getData();
  }, [pageSize, filters, fetchData]);

  useEffect(() => {
    if (!query) {
      getData();
    }
  }, [query]);

  return (
    <>
      <QRow columnGap="8px">
        {filteredColumns.map(column => (
          <QCol xs={3}>
            {!mastV2 && (
              <QLabel for={`column-${column.key}-select`}>
                <strong>{column.label}</strong>
              </QLabel>
            )}
            <QSelect
              size="small"
              options={column.options}
              value={filters[column.key] || ''}
              onChange={e => handleFilterChange(column.key, e)}
              label={column.label}
              formControlProps={{ sx: { width: '180px' } }}
              selectIconProps={{ fontSize: 'medium' }}
            />
          </QCol>
        ))}
      </QRow>

      <QRow style={{ marginTop: '8px' }}>
        <QCol xs={3}>
          {!mastV2 && (
            <QLabel for="entry-query-input">
              <strong>Search:</strong>
            </QLabel>
          )}
          <QInput
            sx={{ width: '368px', height: '48px' }}
            variant="filled"
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <QInputIconWrapper position="start">
                  <SearchIcon />
                </QInputIconWrapper>
              ),
              endAdornment: query ? (
                <QIconButton
                  sx={{ color: '#6a6c6f' }}
                  size="small"
                  onClick={() => {
                    setQuery('');
                  }}
                >
                  <ClearIcon />
                </QIconButton>
              ) : undefined,
            }}
            value={query}
            onChange={e => {
              handleSearchQuery(e);
              setQuery(e.target.value);
            }}
            onKeyPress={e => handleKeyPress(e)}
          />
        </QCol>
      </QRow>

      <AsyncTablePagination
        pagination={pagination}
        getPage={getData}
        page={page}
        showRowsPerPage
        currentRows={data.length}
        handlePageSizeChange={handlePageSizeChange}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
      />
      <QTable>
        <QTableHead>
          <QTableRow>
            {columns.map((column, index) => (
              <QTableCell
                key={index}
                headerCell
                style={{ width: column.width }}
              >
                {column.label}
              </QTableCell>
            ))}
          </QTableRow>
        </QTableHead>
        <QTableBody>
          {data.map((row, rowIndex) => (
            <QTableRow key={rowIndex}>
              {columns.map((column, colIndex) => (
                <QTableCell key={colIndex}>
                  {column.component ? (
                    column.component({
                      value: column.keyName ? row[column.keyName] : row,
                      rowData: row,
                    })
                  ) : column.columnLink ? (
                    <Link to={column.columnLink(row)}>
                      {row[column.keyName]}
                    </Link>
                  ) : (
                    row[column.keyName]
                  )}
                </QTableCell>
              ))}
            </QTableRow>
          ))}
        </QTableBody>
      </QTable>
    </>
  );
};
