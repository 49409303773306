import React from 'react';
import { DropdownItem } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QMenuItem as MuiSelectItem } from '@kw/quokka-ui';

const LDDropdownSelectItem = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiSelectItem {...props}>{children}</MuiSelectItem>
  ) : (
    <DropdownItem {...props}>{children}</DropdownItem>
  );
};
export const QDropdownSelectItem = withLDConsumer()(LDDropdownSelectItem);
