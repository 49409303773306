import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, Trans } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CloseIcon from '@mui/icons-material/Close';

import i18n from '../../localization/i18n';
import { QTypography } from '../Q-Components/QTypography';
import { QIconButton } from '../Q-Components/QIconButton';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QButton } from '../Q-Components/QButton';
import { QContainer } from '../Q-Components/QContainer';

const asideToggle = e => {
  e.preventDefault();
  document.body.classList.toggle('aside-menu-hidden');
};

const Aside = ({ helpHtml, restartTour }) => {
  const { mastV2 } = useFlags();

  return (
    <aside className="aside-menu">
      <QContainer
        fluid
        className="portal-help-row"
        style={{ padding: '20px 20px 20px 20px' }}
      >
        <QRow
          sx={{ mt: 1 }}
          className="mt-2 text-right"
          justifyContent="flex-end"
        >
          <QCol>
            <QIconButton
              size="sm"
              color="secondary"
              className="toggle-aside-btn"
              onClick={asideToggle}
            >
              {mastV2 ? <CloseIcon /> : <i className="fa-regular fa-xmark" />}
            </QIconButton>
          </QCol>
        </QRow>
        <QRow sx={{ mt: 1 }} className="mt-2">
          <QCol>
            <QTypography>
              <h4>
                <i className="fa-light fa-circle-question" />
                &nbsp;{i18n.t('Help')}
              </h4>
            </QTypography>
          </QCol>
        </QRow>

        <QRow
          sx={{
            mt: 1,
          }}
          style={{
            minHeight: '70vh',
            maxHeight: '70vh',
            overflow: 'auto',
          }}
        >
          <QCol>
            <QTypography>
              {helpHtml ? (
                <Trans i18nKey={helpHtml} />
              ) : (
                <span>
                  {i18n.t(
                    'Please click on a help icon in the portal to display the associated help content.',
                  )}
                </span>
              )}
            </QTypography>
          </QCol>
        </QRow>

        {!mastV2 && (
          <QRow className="aside-bottom">
            <QCol className="text-center">
              <hr />
              <QButton
                color="success"
                className="tour-button align-bottom"
                onClick={restartTour}
              >
                <i className="fa-solid fa-rotate" />
                &nbsp;<Trans>Take the tour again</Trans>
              </QButton>
            </QCol>
          </QRow>
        )}
      </QContainer>
    </aside>
  );
};

const mapStateToProps = state => ({
  helpHtml: state.emmApp.helpHtml,
  lang: state.emmApp.portalConfig.lang,
});

export default withTranslation('translations')(connect(mapStateToProps)(Aside));
