import React from 'react';

export const ErrorMessage = ({ children }) => {
  const errorStyle = {
    color: 'red',
  };
  return (
    <div role="alert" style={errorStyle}>
      {children}
    </div>
  );
};
