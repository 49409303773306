import React from 'react';
import { CardText } from 'reactstrap';

import DynamicAnalysisDatatable from '../../Android/DynamicAnalysisDatatable';

import { Issues } from './Issues';

export const V7_4 = ({ evidence, platform }) => (
  <>
    <Issues evidence={evidence} issues={['DEBUGGABLE']} />
    <hr />
    <CardText>
      <strong>Debuggable: </strong>
      {evidence.isDebuggable ? (
        <i className="fa-regular fa-circle-check danger-icon" />
      ) : (
        <i className="fa-regular fa-circle-xmark success-icon" />
      )}
    </CardText>

    {platform === 'android' && (
      <DynamicAnalysisDatatable onlySelectedCategory={['Logging']} />
    )}
  </>
);
