import React from 'react';
import { ModalFooter } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QModalActions as MuiModalActions } from '@kw/quokka-ui';

const LDModalActions = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiModalActions {...props}>{children}</MuiModalActions>
  ) : (
    <ModalFooter {...props}>{children}</ModalFooter>
  );
};
export const QModalActions = withLDConsumer()(LDModalActions);
