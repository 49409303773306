import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { QButton } from '../../Q-Components/QButton';
import { QInput } from '../../Q-Components/QInput';
import { QLabel } from '../../Q-Components/QLabel';
import { QPopover } from '../../Q-Components/QPopover';
import { QPopoverBody } from '../../Q-Components/QPopoverBody';
import { QPopoverHeader } from '../../Q-Components/QPopoverHeader';
import { QTypography } from '../../Q-Components/QTypography';
import { QSelect } from '../../Q-Components/QSelect';
import { QModal } from '../../Q-Components/QModal';
import { QModalTitle } from '../../Q-Components/QModalTitle';
import { QModalActions } from '../../Q-Components/QModalActions';
import { QModalContent } from '../../Q-Components/QModalContent';
import { QCol } from '../../Q-Components/QCol';
import { QRow } from '../../Q-Components/QRow';

import { RemainingUsers } from './RemainingUsers';

const Title = () => (
  <QTypography variant="h3Medium">Create new user</QTypography>
);

const Body = ({
  mastV2,
  kaiAccess,
  email,
  group,
  subGroup,
  setEmail,
  groupsOptions,
  subGroupsOptions,
  handleChangeGroupOption,
  handleChangeSubGroupOption,
  userWithInvitePermission,
}) => (
  <>
    {!kaiAccess && <RemainingUsers />}
    <QCol>
      <QRow sx={{ width: '100%', mt: 1 }}>
        <QLabel htmlFor="new-group-user-email">
          <QTypography variant="h5Medium">Email</QTypography>
        </QLabel>
      </QRow>
      <QRow sx={{ width: '100%', mt: 1 }}>
        <QInput
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="Email"
          id="new-group-user-email"
          fullWidth
        />
      </QRow>

      {!userWithInvitePermission && (
        <>
          <QRow sx={{ width: '100%', mt: 1 }}>
            <QLabel htmlFor="group-select">
              <QTypography variant="h5Medium">Group</QTypography>
            </QLabel>
          </QRow>
          <QRow sx={{ width: '100%', mt: 1 }}>
            <div style={{ width: '100%', margin: '0 auto' }}>
              <QSelect
                id="group-select"
                options={groupsOptions}
                value={mastV2 ? group?.value : group}
                onChange={handleChangeGroupOption}
                isDisabled={!kaiAccess}
                selectProps={{ disabled: !kaiAccess }}
                formControlProps={{ sx: { width: '100%' } }}
              />
            </div>
          </QRow>
        </>
      )}

      <QRow sx={{ width: '100%', mt: 1 }}>
        <QLabel htmlFor="sub-group-select">
          <QTypography variant="h5Medium">Sub Group</QTypography>
        </QLabel>
      </QRow>
      <QRow sx={{ width: '100%', mt: 1 }}>
        <div style={{ width: '100%', margin: '0 auto' }}>
          <QSelect
            id="sub-group-select"
            options={subGroupsOptions}
            value={mastV2 ? subGroup?.value : subGroup}
            onChange={handleChangeSubGroupOption}
            formControlProps={{ sx: { width: '100%' } }}
          />
        </div>
      </QRow>
    </QCol>
  </>
);

const Actions = ({
  mastV2,
  onClose,
  createNewUser,
  email,
  group,
  subGroup,
  setEmail,
  setEditing,
  userWithInvitePermission,
}) => (
  <>
    {mastV2 && (
      <QButton variant="outlined" color="secondary" onClick={onClose}>
        Cancel
      </QButton>
    )}

    <QButton
      block
      variant="filled"
      onClick={() => {
        setEmail('');
        setEditing(false);
        createNewUser(
          email,
          group ? group.value : null,
          subGroup ? subGroup.value : null,
        );
      }}
      color="primary"
      // regular user won't have the group value set so only email is required
      disabled={userWithInvitePermission ? !email : !email || !group}
    >
      {!mastV2 && <i className="fa-regular fa-floppy-disk mr-1" />}Create
    </QButton>
  </>
);

export const NewUser = ({
  createNewUser,
  kaiAccess = false,
  placement = 'left', // placement of the popover
  userWithInvitePermission = false, // permission only for normal user who is not KAI or Group Admin
}) => {
  const [email, setEmail] = useState('');
  const [group, setGroup] = useState();
  const [subGroup, setSubGroup] = useState();
  const [editing, setEditing] = useState(false);
  const { mastV2 } = useFlags();

  // Pull list of groups from redux
  const groupAdminGroups = useSelector(state =>
    kaiAccess ? state.kai.kaiGroups : state.emmApp.groupAdminGroup,
  );

  const userSubGroups = useSelector(state => state.emmApp.userSubGroups);

  const groupsOptions = groupAdminGroups.map(group => ({
    value: group.id,
    label: group.name,
  }));

  const subGroupsOptions = [
    { value: null, label: 'None' },
    ...(userWithInvitePermission
      ? userSubGroups.map(subGroup => ({
          value: subGroup.id,
          label: subGroup.name,
        }))
      : _.flatten(
          groupAdminGroups
            .filter(adminGroup => adminGroup.id === group?.value)
            .map(adminGroup =>
              (adminGroup.sub_groups || adminGroup.subGroups).map(subGroup => ({
                value: subGroup.id,
                label: subGroup.name,
              })),
            ),
        )),
  ];

  useEffect(() => {
    setGroup(groupsOptions[0]);
  }, [groupsOptions.length > 0]);

  const handleChangeGroupOption = (event, newGroup) => {
    if (mastV2) {
      const newOption = groupsOptions.find(
        option => option.value === newGroup.props.value,
      );

      setGroup(newOption);
    } else {
      setGroup(event);
    }

    setSubGroup({ value: null, label: 'None' });
  };

  const handleChangeSubGroupOption = (event, newSubGroup) => {
    if (mastV2) {
      const newOption = subGroupsOptions.find(
        option => option.value === newSubGroup.props.value,
      );

      setSubGroup(newOption);
    } else {
      setSubGroup(event);
    }
  };

  const toggle = () => setEditing(!editing);

  return (
    <>
      {mastV2 ? (
        <div style={{ width: '130px' }}>
          <QModal
            isOpen={editing}
            open={editing}
            toggle={toggle}
            title={
              <QModalTitle onClose={toggle} closeIcon={<CloseIcon />}>
                <Title />
              </QModalTitle>
            }
            content={
              <QModalContent sx={{ padding: '16px 29px 29px 16px' }}>
                <Body
                  mastV2={mastV2}
                  kaiAccess={kaiAccess}
                  email={email}
                  group={group}
                  subGroup={subGroup}
                  setEmail={setEmail}
                  groupsOptions={groupsOptions}
                  subGroupsOptions={subGroupsOptions}
                  handleChangeGroupOption={handleChangeGroupOption}
                  handleChangeSubGroupOption={handleChangeSubGroupOption}
                  userWithInvitePermission={userWithInvitePermission}
                />
              </QModalContent>
            }
            actions={
              <QModalActions sx={{ padding: '0px 32px 16px 0px' }}>
                <Actions
                  onClose={toggle}
                  createNewUser={createNewUser}
                  email={email}
                  group={group}
                  subGroup={subGroup}
                  setEmail={setEmail}
                  setEditing={setEditing}
                  mastV2={mastV2}
                  userWithInvitePermission={userWithInvitePermission}
                />
              </QModalActions>
            }
          />

          <QButton
            size="sm"
            color="link"
            className="pointer black-icon"
            id="new-user-email"
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => setEditing(!editing)}
            style={{ fontWeight: '700', background: 'none' }}
          >
            New User
          </QButton>
        </div>
      ) : (
        <div>
          <QPopover
            placement={placement}
            isOpen={editing}
            target="new-user-email"
            toggle={() => setEditing(!editing)}
          >
            <QPopoverHeader>
              <Title />
            </QPopoverHeader>
            <QPopoverBody>
              <div>
                <Body
                  mastV2={mastV2}
                  kaiAccess={kaiAccess}
                  email={email}
                  group={group}
                  subGroup={subGroup}
                  setEmail={setEmail}
                  groupsOptions={groupsOptions}
                  subGroupsOptions={subGroupsOptions}
                  handleChangeGroupOption={handleChangeGroupOption}
                  handleChangeSubGroupOption={handleChangeSubGroupOption}
                  userWithInvitePermission={userWithInvitePermission}
                />
              </div>
              <div className="mt-2">
                <Actions
                  onClose={toggle}
                  createNewUser={createNewUser}
                  email={email}
                  group={group}
                  subGroup={subGroup}
                  setEmail={setEmail}
                  setEditing={setEditing}
                  mastV2={mastV2}
                  userWithInvitePermission={userWithInvitePermission}
                />
              </div>
            </QPopoverBody>
          </QPopover>
          <QButton
            size="sm"
            color="link"
            className="pointer black-icon"
            id="new-user-email"
            onClick={() => setEditing(!editing)}
          >
            <i className="fa-regular fa-circle-plus" /> New User
          </QButton>
        </div>
      )}
    </>
  );
};
