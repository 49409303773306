import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useSelector } from 'react-redux';
import axios from 'axios';

import i18n from '../../localization/i18n';
import { reportStyles } from '../JoyTourConfig';
import Tooltip from '../Tooltip';

export function ReportButton(props) {
  const history = useHistory();
  async function reportWalkthrough(e) {
    e.preventDefault();
    props.skipProps.onClick(e);

    const response = await axios.put('emm/config', {
      config_key: 'tour_taken',
      value: 0,
    });
    if (response.status !== 200) {
      console.error(response);
    }
    history.push('/dashboard');
    window.location.reload();
  }
  return (
    <Button onClick={reportWalkthrough} style={reportStyles.buttonSkip}>
      {' '}
      Back To Submit App
    </Button>
  );
}

export default function ReportTour() {
  const tourTaken = useSelector(
    ({ emmApp }) => emmApp.userConfig.report_tour_taken,
  );
  const [stepIndex, setStepIndex] = useState(0);
  const [isActive, setIsActive] = useState(!tourTaken);

  const tourSteps = [
    {
      title: i18n.t('Overview'),
      content: (
        <div>
          <p>
            You will start your review on the Overview tab. Here you will see
            the risk findings in order from Critical to Low risk.
          </p>
        </div>
      ),
      placement: 'right',
      target: '.overview',
      isFixed: true,
      disableBeacon: true,
    },
    {
      title: i18n.t('Report Finding Cards'),
      content: (
        <div>
          <p>
            Each finding is summarized in a card describing the core information
            required to understand, interpret, and fix the issue.
          </p>
        </div>
      ),
      placement: 'top',
      target: '.report-finding-card',
      isFixed: false,
    },
    {
      title: i18n.t('Tags'),
      content: (
        <div>
          <p>
            The tags for each finding will show if it is Exploitable, a mapping
            to the Common Vulnerability Scoring System (CVSS) score, and an
            applicable Common Weakness Enumeration (CWE) mapping.
          </p>
        </div>
      ),
      placement: 'left',
      target: '.report-tags',
      isFixed: false,
    },
    {
      title: i18n.t('Regulations'),
      content: (
        <div>
          <p>
            Each finding is also mapped to the most popular industry
            regulations, including the OWASP Mobile Top 10, NIAP, and GDPR.
          </p>
        </div>
      ),
      placement: 'left',
      target: '.report-regulation',
      isFixed: false,
    },
    {
      title: i18n.t('Issue'),
      content: (
        <div>
          <p>
            Issue cards are able to be expanded to view detailed information. To
            expand an Issue card, simply click on the issue card to view the
            full details.
          </p>
        </div>
      ),
      placement: 'bottom',
      target: '.report-issue-details',
      isFixed: false,
      spotlightClicks: true,
    },
    {
      title: i18n.t('Issue Details'),
      content: (
        <div>
          <p>
            In the expanded Issue card, you can view the Threat Details,
            Remediation Guidance, Findings Impact, and Evidence for why an Issue
            was triggered for this application.
          </p>
        </div>
      ),
      placement: 'bottom',
      target: '.report-issue-expand',
      isFixed: false,
    },
    {
      title: i18n.t('Additional Tabs'),
      content: (
        <div>
          <p>
            The report also contains additional tabs for reviewing detailed
            information on Metadata, Network Information, Analysis Details, and
            Full Regulatory reporting.
          </p>
        </div>
      ),
      placement: 'bottom',
      target: '.report-tab-row',
      isFixed: false,
    },
  ];

  function tooltipComponent(props) {
    const totalSteps = tourSteps.length;
    const reportButton = <ReportButton {...props} />;
    const tooltipComponent = (
      <Tooltip
        totalSteps={totalSteps}
        reportButton={reportButton}
        {...props}
        optionalStyle={reportStyles}
      />
    );
    return tooltipComponent;
  }

  async function joyrideCallback(data) {
    const { action, index, type, status, step } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setIsActive(false);
      setStepIndex(0);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      setStepIndex(stepIndex);
    }

    const collapseCard = document.querySelector('.report-issue-expand');
    if (step.target === '.report-issue-details' && type === 'step:after') {
      setIsActive(false);

      if (collapseCard) {
        collapseCard.className = 'report-issue-expand collapse show';
      }

      setTimeout(() => {
        setIsActive(true);
      }, 300);
    }

    if (data.type === 'tour:end') {
      console.log(
        'Setting the config value to say the report tour was already taken',
      );

      if (collapseCard) {
        collapseCard.className = 'report-issue-expand collapse';
      }

      const response = await axios.put('emm/config', {
        config_key: 'report_tour_taken',
        value: 1,
      });
      if (response.status === 200) {
        console.log('Tour config updated');
      } else {
        console.error(response);
      }
    }
  }

  return (
    <Joyride
      continuous
      run={isActive}
      steps={tourSteps}
      stepIndex={stepIndex}
      showProgress
      showSkipButton
      locale={{
        back: <span>{i18n.t('Back')}</span>,
        close: <span>{i18n.t('Close')}</span>,
        last: <span>{i18n.t('Last')}</span>,
        next: <span>{i18n.t('Next')}</span>,
        skip: <span>{i18n.t('Skip')}</span>,
      }}
      styles={reportStyles}
      callback={joyrideCallback}
      scrollOffset={75}
      tooltipComponent={tooltipComponent}
    />
  );
}
