import React from 'react';
import { Progress } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QLinearProgress as MuiLinearProgress } from '@kw/quokka-ui';

const LDLinearProgress = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiLinearProgress {...props}>{children}</MuiLinearProgress>
  ) : (
    <Progress {...props}>{children}</Progress>
  );
};
export const QLinearProgress = withLDConsumer()(LDLinearProgress);
