import React from 'react';
import { Navbar } from 'reactstrap';
import { Route } from 'react-router';

import { NewUserPasswordForm } from '../components/NewUserPassword/NewUserPasswordForm';
import { Confirmation } from '../components/NewUserPassword/Confirmation';

export const NewUserPassword = () => (
  <div className="new-user-password">
    <Navbar
      color="white"
      className="new-user-password__header-row justify-content-center"
    >
      <div className="banner-logo" />
    </Navbar>

    <main className="new-user-password__main">
      <Route exact path="/new-user-password" component={NewUserPasswordForm} />
      <Route path="/new-user-password/confirmation" component={Confirmation} />
    </main>
  </div>
);
