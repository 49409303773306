import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import ReactJson from 'react-json-view';
import { connect } from 'react-redux';

import DataTable from '../DataTable/DataTable';
import util from '../../logic/util';
import i18n from '../../localization/i18n';

class SensitiveDataExposure extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDataDetailModal: false,
      dataDetail: {},
    };
  }

  render() {
    const { showDataDetailModal, dataDetail } = this.state;
    const { results } = this.props;

    const dataExposure = results.analysis.high_level_issues.pii;

    return (
      <div>
        <Modal
          isOpen={showDataDetailModal}
          size="lg"
          toggle={() =>
            this.setState({ showDataDetailModal: !showDataDetailModal })
          }
        >
          <ModalHeader>{i18n.t('Sensitive Data Exposure Details')}</ModalHeader>
          <ModalBody>
            <p className="bold">{i18n.t('Type')}</p>
            <p>{dataDetail.type}</p>
            <hr />
            <p className="bold">{i18n.t('Full Value')}</p>
            {util.isJson(dataDetail.value) ? (
              <ReactJson
                src={JSON.parse(dataDetail.value)}
                displayDataTypes={false}
              />
            ) : (
              <p className="dont-break-out">{dataDetail.value}</p>
            )}
            <hr />
            <p className="bold">{i18n.t('Full Context')}</p>
            <p className="dont-break-out">{dataDetail.context}</p>
          </ModalBody>
        </Modal>

        <Card className="card-accent-warning-2">
          <CardHeader>{i18n.t('Sensitive Data Exposure')}</CardHeader>
          <CardBody>
            {dataExposure.length === 0 ? (
              <span>{i18n.t('No sensitive data exposure detected')}</span>
            ) : (
              <DataTable
                data={dataExposure}
                onRowClick={row =>
                  this.setState({ showDataDetailModal: true, dataDetail: row })
                }
                columns={[
                  {
                    keyName: 'type',
                    label: i18n.t('Type'),
                    sortable: true,
                    filterable: true,
                    width: '20%',
                  },
                  {
                    keyName: 'context',
                    label: i18n.t('Context'),
                    component: ({ value }) => {
                      if (value.length > 100) {
                        return (
                          <span>
                            {`${value.substring(0, 100)}...(truncated)`}
                          </span>
                        );
                      }
                      return <span>{value}</span>;
                    },
                    sortable: true,
                    filterable: true,
                  },
                ]}
              />
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(SensitiveDataExposure);
