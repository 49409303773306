import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { DropdownMenu } from 'reactstrap';

import { QDropdownSelect } from './QDropdownSelect';

const LDDropdownMenu = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <QDropdownSelect {...props}>{children}</QDropdownSelect>
  ) : (
    <DropdownMenu {...props}>{children}</DropdownMenu>
  );
};
export const QDropdownMenu = withLDConsumer()(LDDropdownMenu);
