import React, { Component } from 'react';

import DataTable from '../../../DataTable/DataTable';

export default class IosHardCodedKeys extends Component {
  render() {
    const { data } = this.props;
    return (
      <DataTable
        data={data.filter(entry => entry.hardcoded_key_found)}
        columns={[
          {
            label: 'Type',
            keyName: 'type',
            component: () => <span>Hard Coded Key</span>,
          },
          {
            label: 'Key',
            keyName: 'hardcoded_key',
          },
          {
            label: 'Key (base64)',
            keyName: 'Key',
          },
        ]}
      />
    );
  }
}
