import React from 'react';
import {
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';

import i18n from '../../localization/i18n';

const pageNumbers = (page, lastPage) => {
  const maxPages = 2;
  let from = page - maxPages;
  /* istanbul ignore else */
  if (from < 1) {
    from = 1;
  }

  let to = from + maxPages * 2; // eslint-disable-line
  /* istanbul ignore else */
  if (to >= lastPage) {
    to = lastPage;
  }

  const pagesArray = [];
  for (let i = from; i <= to; i++) {
    pagesArray.push(i);
  }
  return pagesArray;
};

const DataTablePagination = props => {
  const { className, page, maxPages, getPage } = props;
  return (
    <div className={className}>
      <Row>
        <Col className="text-center">
          <span>
            {i18n.t('Page')} {page} {i18n.t('of')} {maxPages}
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <Pagination>
            {/* First page */}
            {page > 1 && (
              <PaginationItem>
                <PaginationLink href="#" onClick={e => getPage(1, e)}>
                  {i18n.t('First')}
                </PaginationLink>
              </PaginationItem>
            )}

            {/* Previous page */}
            {page > 1 && (
              <PaginationItem>
                <PaginationLink
                  previous
                  href="#"
                  onClick={e => getPage(page - 1, e)}
                />
              </PaginationItem>
            )}

            {/* Individual page */}
            {pageNumbers(page, maxPages).map((pageNum, index) => (
              <PaginationItem key={index} active={pageNum === page}>
                <PaginationLink href="#" onClick={e => getPage(pageNum, e)}>
                  {pageNum}
                </PaginationLink>
              </PaginationItem>
            ))}

            {/* Next page */}
            {page < maxPages && (
              <PaginationItem>
                <PaginationLink
                  next
                  href="#"
                  onClick={e => getPage(page + 1, e)}
                />
              </PaginationItem>
            )}

            {/* Last page */}
            {page < maxPages && (
              <PaginationItem>
                <PaginationLink href="#" onClick={e => getPage(maxPages, e)}>
                  {i18n.t('Last')}
                </PaginationLink>
              </PaginationItem>
            )}
          </Pagination>
        </Col>
      </Row>
    </div>
  );
};

export default DataTablePagination;
