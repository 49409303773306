import React, { Component } from 'react';
import axios from 'axios';

import AsyncTablePagination from '../AsyncTablePagination';
import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QTypography } from '../Q-Components/QTypography';
import { QCardBody } from '../Q-Components/QCardBody';
import { QTable } from '../Q-Components/QTable';
import { QTableHead } from '../Q-Components/QTableHead';
import { QTableRow } from '../Q-Components/QTableRow';
import { QTableBody } from '../Q-Components/QTableBody';
import { QTableCell } from '../Q-Components/QTableCell';

import MdmDevice from './MdmDevice';

class MdmDevices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      page: 1,
      limit: 10,
      pagination: { lastPage: 0 },
    };

    this.getPage = this.getPage.bind(this);
  }

  async componentWillMount() {
    this.getPage();
  }

  async getPage(page = 1, e) {
    if (e) {
      e.preventDefault();
    }
    const { limit } = this.state;

    try {
      const response = await axios.get(
        `mdm-integration/devices?page=${page}&limit=${limit}`,
      );

      if (!response.data.devices) {
        throw new Error('No devices found in response');
      }

      this.setState({
        devices: response.data.devices,
        pagination: response.data.pagination,
        page,
      });
    } catch (err) {
      console.error('Error getting MDM devices:', err);
    }
  }

  render() {
    const { devices, page, pagination } = this.state;
    return (
      <QCard className="card-accent-navy">
        <QCardHeader
          title={<QTypography variant="h4Medium">MDM Devices</QTypography>}
        >
          <strong>MDM Devices</strong>
        </QCardHeader>
        <QCardBody>
          <AsyncTablePagination
            page={page}
            pagination={pagination}
            getPage={this.getPage}
          />

          <QTable>
            <QTableHead>
              <QTableRow>
                <QTableCell headerCell>Device ID</QTableCell>
                <QTableCell headerCell>User</QTableCell>
                <QTableCell headerCell>Device Name</QTableCell>
                <QTableCell headerCell>Last Seen</QTableCell>
                <QTableCell headerCell>Compliant</QTableCell>
                <QTableCell headerCell>Apps Installed</QTableCell>
              </QTableRow>
            </QTableHead>
            <QTableBody>
              {devices.map(device => (
                <MdmDevice key={device.kryptowireUuid} deviceInfo={device} />
              ))}
            </QTableBody>
          </QTable>
        </QCardBody>
      </QCard>
    );
  }
}

export default MdmDevices;
