import React from 'react';
import { FormGroup } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

const LDFormGroup = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? children : <FormGroup {...props}>{children}</FormGroup>;
};

export const QFormGroup = withLDConsumer()(LDFormGroup);
