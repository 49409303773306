import React from 'react';
import { CardText, Badge } from 'reactstrap';
import _ from 'lodash';

import CertInfoTable from '../../Android/CertInfoTable';
import AndroidMarketInfoTable from '../../Android/MarketInfoTable';
import CertificatesAndKeys from '../../iOS/CertificatesAndKeys';
import IosMarketInfoTable from '../../iOS/MarketInfoTable';

import { Issues } from './Issues';

export const V7_1 = ({ platform, evidence }) => (
  <>
    <Issues evidence={evidence} issues={['HAS_INVALID_CERTIFICATE']} />
    <hr />
    <CardText>
      {!_.isEmpty(evidence.market) ? (
        <Badge color="light">
          App may be distributed through Market with valid certificate and
          protection
        </Badge>
      ) : (
        <Badge color="warning">
          App may not be distributed through Market with valid certificate and
          protection
        </Badge>
      )}
    </CardText>
    {platform === 'android' ? (
      <>
        <CardText>
          <strong>Market Info</strong>
        </CardText>
        <AndroidMarketInfoTable />
        <hr />
        <CardText>
          <strong>Certificate</strong>
        </CardText>
        <CertInfoTable />
      </>
    ) : (
      <>
        <CardText>
          <strong>Market Info</strong>
        </CardText>
        <IosMarketInfoTable />
        <hr />
        <CardText>
          <strong>Certificate</strong>
        </CardText>
        <CertificatesAndKeys />
      </>
    )}
  </>
);
