import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import i18n from '../../../localization/i18n';
import { AppManifest } from '../AppManifest';

import CertInfoTable from './CertInfoTable';
import FeaturesAndLibrariesTable from './FeaturesAndLibrariesTable';
import { Components } from './Components';
import ConstantsDatatable from './ConstantsDatatable';

class Metadata extends Component {
  render() {
    return (
      <div>
        <Row>
          {/* Metadata constants */}
          <Col xs="6">
            <Card className="card-accent-teal">
              <CardHeader>{i18n.t('Constants')}</CardHeader>
              <CardBody>
                <ConstantsDatatable />
              </CardBody>
            </Card>
          </Col>
          <Col xs="6">
            <Card className="cert-info-section card-accent-info">
              <CardHeader>{i18n.t('Certificate Information')}</CardHeader>
              <CardBody>
                <CertInfoTable />
              </CardBody>
            </Card>
            <Card className="card-accent-purple">
              <CardHeader>{i18n.t('Features and Libraries')}</CardHeader>
              <CardBody>
                <FeaturesAndLibrariesTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <AppManifest />
          </Col>
        </Row>
        <Row>
          <Col>
            <Components />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Metadata;
