import React from 'react';
import { CardText } from 'reactstrap';

import AndroidPermissionsRequestedDataTable from '../../Android/PermissionsRequestedTable';
import IosPermissionsRequestedDatatable from '../../iOS/PermissionsRequestedDatatable';

export const V2_12 = ({ evidence, platform }) => (
  <CardText>
    <strong>Privacy Policy Link: </strong>
    <a href={evidence.privacyPolicyLink} target="_blank">
      {evidence.privacyPolicyLink}
    </a>
    <hr />
    <strong>Permissions Requested: </strong>
    {platform === 'android' ? (
      <AndroidPermissionsRequestedDataTable />
    ) : (
      <IosPermissionsRequestedDatatable />
    )}
  </CardText>
);
