import React, { useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFlags } from 'launchdarkly-react-client-sdk';

import HelpButton from '../../HelpButton';
import { toastrHelper } from '../../../logic/toastrHelper';
import {
  countriesDataOptions,
  getCountryNameFromCode,
} from '../../../logic/countryUtil';
import { QCard } from '../../Q-Components/QCard';
import { QCardHeader } from '../../Q-Components/QCardHeader';
import { QCardBody } from '../../Q-Components/QCardBody';
import { QAutocomplete } from '../../Q-Components/QAutocomplete';
import { QInput } from '../../Q-Components/QInput';
import { QTypography } from '../../Q-Components/QTypography';

const ForeignConnection = props => {
  const [homeCountry, setHomeCountry] = useState({
    value: props.initCountry,
    label: getCountryNameFromCode(props.initCountry),
  });
  const [countryInputValue, setCountryInputValue] = useState('');
  const { mastV2 } = useFlags();

  const setCountry = async country => {
    const response = await axios.patch(
      'analysis-customization/home-country-code',
      {
        countryCode: country.value,
      },
    );

    if (response.status === 200) {
      toastrHelper.showSuccessToast(
        `Set home country to: ${country.label}`,
        null,
        mastV2,
      );
    } else {
      toastrHelper.showErrorToast('Error setting home country', null, mastV2);
    }

    setHomeCountry(country);
  };

  return (
    <QCard className="card-accent-warning-2">
      <QCardHeader
        title={
          <strong>
            Foreign Connection <HelpButton helpItem="foreignConnection" />
          </strong>
        }
      >
        <strong>
          Foreign Connection <HelpButton helpItem="foreignConnection" />
        </strong>
      </QCardHeader>
      <QCardBody>
        {mastV2 ? (
          <QAutocomplete
            data-testid="home-country-list"
            noOptionsText="nothing found"
            value={homeCountry.value ? homeCountry : null}
            onChange={(event, newCountry) => {
              setCountry(newCountry);
            }}
            inputValue={countryInputValue}
            onInputChange={(event, newCountryInput) => {
              setCountryInputValue(newCountryInput);
            }}
            options={countriesDataOptions}
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            renderInput={params => (
              <QInput
                {...params}
                label={
                  <QTypography variant="h5Regular" color="#000000">
                    Home country:
                  </QTypography>
                }
                variant="standard"
                style={{ textOverflow: 'ellipsis' }}
                InputProps={{
                  endAdornment: params.InputProps.endAdornment,
                  sx: {
                    '.MuiAutocomplete-endAdornment': {
                      position: 'relative',
                    },
                  },
                  inputProps: params.inputProps,
                  ref: params.InputProps.ref,
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon htmlColor="#000000" />}
            clearIcon={<ExpandLessIcon htmlColor="#000000" />}
          />
        ) : (
          <>
            Home country:
            <Select
              value={homeCountry}
              onChange={setCountry}
              options={countriesDataOptions}
            />
          </>
        )}
      </QCardBody>
    </QCard>
  );
};

export default ForeignConnection;
