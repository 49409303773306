import React, { useState } from 'react';

import { QCard } from '../../Q-Components/QCard';
import { QCardHeader } from '../../Q-Components/QCardHeader';
import { QCardBody } from '../../Q-Components/QCardBody';
import { QTypography } from '../../Q-Components/QTypography';
import AppAnalysisApprovals from '../../AppApprovals/AppAnalysisApprovals';
import { GroupSelection } from '../GroupSelection';

export default function AppApprovals({ kaiAccess = false }) {
  const [group, setGroup] = useState();

  return (
    <>
      <QCard className="card-accent-primary">
        <QCardHeader
          className="flex justify-between align-center"
          title={
            <QTypography variant="h4Bold" color="#000000">
              App Approvals
            </QTypography>
          }
          action={
            kaiAccess && (
              <GroupSelection
                group={group}
                setGroup={setGroup}
                kaiAccess={kaiAccess}
              />
            )
          }
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>
              <strong>App Approvals</strong>
            </span>
          </div>
          {kaiAccess && (
            <GroupSelection
              group={group}
              setGroup={setGroup}
              kaiAccess={kaiAccess}
            />
          )}
        </QCardHeader>
        <QCardBody>
          {group && <AppAnalysisApprovals appApprovalEnabled group={group} />}
        </QCardBody>
      </QCard>
    </>
  );
}
