import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

import FindingResult from './FindingResult';
import AndroidFindingData from './AndroidFindingData';
import IosFindingData from './IosFindingData';

export default class TaiwanFinding extends Component {
  render() {
    const { finding, platform } = this.props;
    return (
      <Card className="card-accent-secondary">
        <CardHeader>
          {finding.id} - {finding.name}
        </CardHeader>
        <CardBody>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div dangerouslySetInnerHTML={{ __html: finding.activity }} />
              <div>
                <FindingResult result={finding.result} />
              </div>
            </div>

            {finding.activity !== finding.description && (
              <i dangerouslySetInnerHTML={{ __html: finding.description }} />
            )}

            <hr />

            {'review' in finding && (
              <>
                <h6>Review Activity</h6>
                {finding.review}
                <hr />
              </>
            )}

            <h6>Finding Data</h6>
            {platform === 'android' ? (
              <AndroidFindingData findingId={finding.id} data={finding.data} />
            ) : (
              <IosFindingData findingId={finding.id} data={finding.data} />
            )}
          </div>
        </CardBody>
      </Card>
    );
  }
}
