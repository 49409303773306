import React, { useEffect } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Route, useLocation, useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { QMenuItem } from '@kw/quokka-ui';

import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QTypography } from '../Q-Components/QTypography';
import { GroupUsers } from '../GroupAdmin/GroupUsers';
import { EditGroupUser } from '../GroupAdmin/EditGroupUser';

import { EditSubGroups } from './GroupAdmin/EditSubGroups';
import { GroupConfig } from './GroupAdmin/GroupConfig';
import BulkEditPermissions from './GroupAdmin/BulkEditPermissions';

export default function GroupAdmin() {
  const location = useLocation();
  const history = useHistory();
  const { mastV2 } = useFlags();

  useEffect(() => {
    if (location.pathname === '/settings/group-admin') {
      history.push('/settings/group-admin/users');
    }
  });

  return (
    <>
      <QRow sx={{ columnGap: '15px' }}>
        <QCol xs="2">
          {mastV2 ? (
            <>
              <QMenuItem
                href="#/settings/group-admin/users"
                className="nav-link"
                component="a"
              >
                <QTypography variant="bodySemiBold" color="primary">
                  Users
                </QTypography>
              </QMenuItem>
              <QMenuItem
                href="#/settings/group-admin/sub-groups"
                className="nav-link"
                component="a"
              >
                <QTypography variant="bodySemiBold" color="primary">
                  Sub groups
                </QTypography>
              </QMenuItem>

              <QMenuItem
                href="#/settings/group-admin/group-config"
                className="nav-link"
                component="a"
              >
                <QTypography variant="bodySemiBold" color="primary">
                  Group configuration
                </QTypography>
              </QMenuItem>
            </>
          ) : (
            <Nav vertical className="secondary-sidebar">
              <NavItem>
                <NavLink
                  href="#/settings/group-admin/users"
                  className="nav-link"
                >
                  <span>Users</span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="#/settings/group-admin/sub-groups"
                  className="nav-link"
                >
                  <span>Sub groups</span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="#/settings/group-admin/group-config"
                  className="nav-link"
                >
                  <span>Group configuration</span>
                </NavLink>
              </NavItem>
            </Nav>
          )}
        </QCol>
        <QCol xs="10" sx={{ width: '100%' }}>
          <Route
            path="/settings/group-admin/users"
            exact
            component={GroupUsers}
          />

          <Route
            path="/settings/group-admin/users/:userId"
            component={EditGroupUser}
          />

          <Route
            path="/settings/group-admin/sub-groups"
            component={EditSubGroups}
          />

          <Route
            path="/settings/group-admin/group-config"
            component={GroupConfig}
          />

          <Route
            path="/settings/group-admin/bulk-editing"
            component={BulkEditPermissions}
          />
        </QCol>
      </QRow>
    </>
  );
}
