import React, { Component } from 'react';
import {
  CardHeader,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import Select from 'react-select';
import classnames from 'classnames';
import Grid from '@mui/material/Grid';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { QTab } from '../components/Q-Components/QTab';
import { QTabList } from '../components/Q-Components/QTabList';
import { QTabContent } from '../components/Q-Components/QTabContent';
import { QTabPanel } from '../components/Q-Components/QTabPanel';
import { QCard } from '../components/Q-Components/QCard';
import { QCardBody } from '../components/Q-Components/QCardBody';
import { QConditionalCard } from '../components/Q-Components/QConditionalCard';
import { QDropdownSelectItem } from '../components/Q-Components/QDropdownSelectItem';
import { QButton } from '../components/Q-Components/QButton';
import { QDropdownMenu } from '../components/Q-Components/QDropdownMenu';
import { QMenuButtonWithIcon } from '../components/Q-Components/QMenuButtonWithIcon';
import { QRow } from '../components/Q-Components/QRow';
import { QCol } from '../components/Q-Components/QCol';
import { QTable } from '../components/Q-Components/QTable';
import { QTableHead } from '../components/Q-Components/QTableHead';
import { QTableBody } from '../components/Q-Components/QTableBody';
import { QTableCell } from '../components/Q-Components/QTableCell';
import { QTableRow } from '../components/Q-Components/QTableRow';
import { QSearchBar } from '../components/Q-Components/QSearchBar';
import { changeWatchlistPage, setPageTitle } from '../store/slices/emmAppSlice';
import {
  changeSearchQuery,
  changeCurrentSearchQuery,
} from '../store/slices/analyzedAppsSlice';
import HelpButton from '../components/HelpButton';
import WatchlistApp from '../components/WatchlistApps/WatchlistApp';
import ConfirmationModal from '../components/Modals/ConfirmationModal';
import AsyncTablePagination from '../components/AsyncTablePagination';
import StatCard from '../components/StatCard';
import Loading from '../components/Loading';
import { ModifyEidModal } from '../components/AnalyzedApps/ModifyEidModal';
import CheckAppVersionModal from '../components/Modals/CheckAppVersionModal';
import i18n from '../localization/i18n';
import { toastrHelper } from '../logic/toastrHelper';
import WatchlistDeveloper from '../components/WatchlistDeveloper';
import { QTabLink } from '../components/Q-Components/QTabLink';
import { QTabContext } from '../components/Q-Components/QTabContext';
import { QSelect } from '../components/Q-Components/QSelect';
import { QContainer } from '../components/Q-Components/QContainer';

class AppWatchlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      showData: [],
      loading: false,
      showUpdateSubgroupsConfirmModal: false,
      idToUpdate: 0,
      groups: [],
      ownerGroups: [],
      showDeleteConfirmModal: false,
      showUpdateEidConfirmModal: false,
      showCheckAppVersionModal: false,
      idToRemove: 0,
      externalIdToUpdate: 0,
      appIdToUpdate: 0,
      query: '',
      selectedApp: {},
      activeTab: '1',

      // Pagination
      pagination: {
        lastPage: 0,
        totalData: 0,
        dataPerPage: 0,
      },

      watchlistStats: {},

      sortByOptions: [
        { value: 'updatedAt', label: 'Last Updated' },
        { value: 'versionsAnalyzed', label: 'Number of Versions' },
        { value: 'trackingSince', label: 'Tracking Since' },
      ],
      currentSortBy: { value: 'updatedAt', label: 'Last Updated' },
      pageSizeOptions: [
        { value: 10, label: '10' },
        { value: 15, label: '15' },
        { value: 25, label: '25' },
        { value: 50, label: '50' },
        { value: 100, label: '100' },
      ],
      currentPageSize: { value: 10, label: '10' },
      groupOptions: [],
    };

    this.getWatchlistApps = this.getWatchlistApps.bind(this);
    this.deleteWatchlistApp = this.deleteWatchlistApp.bind(this);
    this.updateWatchlistAppSubgroups =
      this.updateWatchlistAppSubgroups.bind(this);
    this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
    this.toggleCheckAppVersionModal =
      this.toggleCheckAppVersionModal.bind(this);
    this.toggleUpdateEidConfirmModal =
      this.toggleUpdateEidConfirmModal.bind(this);
    this.confirmDeletion = this.confirmDeletion.bind(this);
    this.toggleUpdateSubgroupsConfirmModal =
      this.toggleUpdateSubgroupsConfirmModal.bind(this);
    this.confirmUpdate = this.confirmSubgroupsUpdate.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.searchApp = this.searchApp.bind(this);
    this.handlePageSize = this.handlePageSize.bind(this);
    this.removeSearchQuery = this.removeSearchQuery.bind(this);
    this.toggleActiveTab = this.toggleActiveTab.bind(this);
    this.processSubGroupFilter = this.processSubGroupFilter.bind(this);
    this.changeSubgroupFilter = this.changeSubgroupFilter.bind(this);
  }

  // Make sure that we wait until the component is actually loaded before modifying it's state
  componentDidMount() {
    // Get the initial page of apps
    this.getWatchlistApps(this.props.watchlistPage);

    // Get the stats for the watchlist
    axios
      .get('watchlist-app/stats')
      .then(response => {
        this.setState({ watchlistStats: response.data });
        return true;
      })
      .catch(err => {
        console.error(err);
      });

    this.props.setPageTitle('App Watchlist');
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentPageSize !== this.state.currentPageSize ||
      prevState.currentSortBy !== this.state.currentSortBy
    ) {
      this.getWatchlistApps(1);
    }
  }

  async getWatchlistApps(pageNum = 1, event = null) {
    if (event) event.preventDefault();

    this.setState({ loading: true });

    try {
      const { data } = await axios.get(
        `watchlist-app/pages/${pageNum}?query=${encodeURIComponent(
          this.state.query,
        )}&sortColumn=${this.state.currentSortBy.value}&pageSize=${
          this.state.currentPageSize.value
        }`,
      );

      this.props.changeWatchlistPage(pageNum);

      this.setState({
        data: data.apps,
        showData: data.apps,
        pagination: {
          page: pageNum,
          lastPage: data.lastPage,
          totalData: data.total,
          dataPerPage: data.perPage,
        },
        loading: false,
      });
    } catch (err) {
      console.error(err);
      toastrHelper.showErrorToast(
        i18n.t('Error getting watchlist apps'),
        i18n.t('Error'),
        this.props.flags.mastV2,
      );
    }
  }

  toggleActiveTab(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  handleChange = (event, newTab) => {
    this.setState({ activeTab: newTab });
  };

  toggleUpdateSubgroupsConfirmModal() {
    this.setState(
      {
        showUpdateSubgroupsConfirmModal:
          !this.state.showUpdateSubgroupsConfirmModal,
      },
      this.getUserSubgroups,
    );
  }

  toggleCheckAppVersionModal() {
    this.setState({
      showCheckAppVersionModal: !this.state.showCheckAppVersionModal,
    });
  }

  toggleUpdateEidConfirmModal() {
    this.setState({
      showUpdateEidConfirmModal: !this.state.showUpdateEidConfirmModal,
    });
  }

  searchApp(app) {
    this.props.changeSearchQuery(app.name);
    this.props.changeCurrentSearchQuery(app.name);
    this.props.history.push('/');
  }

  toggleDeleteConfirmModal() {
    this.setState({
      showDeleteConfirmModal: !this.state.showDeleteConfirmModal,
    });
  }

  async getUserSubgroups() {
    if (this.state.showUpdateSubgroupsConfirmModal) {
      // Get all subgroups the user is a member of

      const groupsBuffer = [];
      this.props.userSubGroups.forEach(entry => {
        groupsBuffer.push({
          id: entry.id,
          name: entry.name,
        });
      });
      this.setState({ groups: groupsBuffer }, this.getAppSubgroups);

      this.setState({
        groupOptions: groupsBuffer.map(group => ({
          value: group.id,
          label: group.name,
        })),
      });
    }
  }

  async getAppSubgroups() {
    const { data, groups, idToUpdate } = this.state;
    const app = data[data.findIndex(appData => appData.id === idToUpdate)];
    if (app.subgroupIds) {
      const subgroupIds = app.subgroupIds.split(',');
      const ownerGroupsBuffer = [];
      subgroupIds.forEach(subgroup => {
        if (groups.findIndex(group => group.id === Number(subgroup)) >= 0) {
          ownerGroupsBuffer.push({
            value: Number(subgroup),
            label:
              groups[groups.findIndex(group => group.id === Number(subgroup))]
                .name,
          });
        }
      });
      this.setState({ ownerGroups: ownerGroupsBuffer });
    }
  }

  confirmSubgroupsUpdate(app) {
    this.setState(
      { idToUpdate: app.id },
      this.toggleUpdateSubgroupsConfirmModal,
    );
  }

  confirmCheckAppVersion(app) {
    this.setState({ selectedApp: app });
    this.toggleCheckAppVersionModal();
  }

  confirmEidUpdate(app) {
    this.setState(
      {
        idToUpdate: app.id,
        externalIdToUpdate: app.externalId,
        appIdToUpdate: app.analyzedApp.id,
      },
      this.toggleUpdateEidConfirmModal,
    );
  }

  confirmDeletion(app) {
    this.setState({ idToRemove: app.id }, this.toggleDeleteConfirmModal);
  }

  async updateWatchlistAppSubgroups() {
    this.toggleUpdateSubgroupsConfirmModal();

    const { ownerGroups } = this.state;

    // Make the API call to update the watchlist app subgroups
    try {
      let newOwnerIds = '';
      if (ownerGroups.length > 0) {
        ownerGroups.forEach(group => {
          newOwnerIds += `${group.value},`;
        });
      }
      await axios.put(`watchlist-app/${this.state.idToUpdate}`, {
        subGroupIds: newOwnerIds,
      });

      toastrHelper.showSuccessToast(
        'Updated watchlist app subgroups',
        'Success',
        this.props.flags.mastV2,
      );
    } catch (err) {
      console.error(err);
      toastrHelper.showErrorToast(
        'Error updating watchlist app',
        'Error',
        this.props.flags.mastV2,
      );
    }

    this.getWatchlistApps(1);
    this.setState({ idToUpdate: 0 });
  }

  async deleteWatchlistApp() {
    console.log('Removing watchlist app id:', this.state.idToRemove);
    this.toggleDeleteConfirmModal();

    // Make the API call to remove the watchlist app
    try {
      await axios.delete(`watchlist-app/${this.state.idToRemove}`);

      toastrHelper.showSuccessToast(
        i18n.t('Removed watchlist app'),
        i18n.t('Success'),
        this.props.flags.mastV2,
      );
    } catch (err) {
      console.error(err);
      toastrHelper.showErrorToast(
        i18n.t('Error removing watchlist app'),
        i18n.t('Error'),
        this.props.flags.mastV2,
      );
    }

    this.getWatchlistApps(1);
    this.setState({ idToRemove: 0 });
  }

  handleKeyPress(e) {
    if (e.charCode === 13) {
      e.preventDefault();
      this.getWatchlistApps(1);
    }
  }

  handleSearchQuery(e) {
    const value = e.target.value.toString();
    this.setState({ query: value });
  }

  removeSearchQuery() {
    this.setState({ query: '' }, () => {
      this.getWatchlistApps(1);
    });
  }

  handlePageSize(event) {
    this.setState({
      currentPageSize: event,
    });
  }

  handleFocus(event) {
    event.target.style.background = 'none';
  }

  // For the MUI select to work we need to send subgroups as an array of subgroup values
  // Ex. subroupFilter = [{value: 'none', label: 'Non Associated'}, { value:1, label: "subgroup1" }]
  // should return  ['none', '1']
  processSubGroupFilter(subGroupFilter) {
    if (this.props.flags.mastV2) {
      if (Array.isArray(subGroupFilter)) {
        return subGroupFilter.map(subgroup => subgroup.value);
      }
      return [subGroupFilter.value];
    }
    return subGroupFilter;
  }

  changeSubgroupFilter(event) {
    const evaluatedSubgroups = [];
    if (this.props.flags.mastV2) {
      const {
        target: { value },
      } = event;

      if (
        value.length > 1 &&
        (value[value.length - 1] === 'all' ||
          value[value.length - 1].value === 'all')
      ) {
        this.setState({ ownerGroups: evaluatedSubgroups });
        return;
      }

      for (let i = 0; i < value.length; i++) {
        if (typeof value[i] === 'object' && value[i] !== null) {
          evaluatedSubgroups.push(value[i]);
        } else {
          evaluatedSubgroups.push(
            this.state.groupOptions.filter(sub => sub.value === value[i])[0],
          );
        }
      }
      this.setState({ ownerGroups: evaluatedSubgroups });
    } else {
      this.setState({ ownerGroups: event });
    }
  }

  async downloadCsv(compressed) {
    await axios.post('csv-request', {
      pageType: 'watchlist',
      isCompressed: compressed,
    });

    toastrHelper.showSuccessToast(
      'Your request is being processed. Please look forward to an email with a link to download the requested file',
      null,
      this.props.flags.mastV2,
    );
  }

  render() {
    const { watchlistPage } = this.props;
    const {
      sortByOptions,
      currentSortBy,
      pageSizeOptions,
      currentPageSize,
      externalIdToUpdate,
      appIdToUpdate,
      showUpdateEidConfirmModal,
      showUpdateSubgroupsConfirmModal,
      query,
      ownerGroups,
      activeTab,
      groupOptions,
    } = this.state;

    // Sync sort by & sort by options for language changing
    const tempSortByOptions = JSON.parse(JSON.stringify(sortByOptions)).map(
      option => {
        option.label = i18n.t(option.label);
        return option;
      },
    );
    const tempSortBy = { ...currentSortBy };
    const tempSortByV2 = currentSortBy?.value || null;
    tempSortBy.label = i18n.t(tempSortBy.label);

    const handleSortBy = (event, newValue) => {
      if (this.props.flags.mastV2) {
        const newOption = tempSortByOptions.find(
          option => option.value === newValue.props.value,
        );
        this.setState({ currentSortBy: newOption });
      } else {
        this.setState({ currentSortBy: event });
      }
    };

    return (
      <div className="animated fadeIn">
        {showUpdateEidConfirmModal && (
          <ModifyEidModal
            isOpen={showUpdateEidConfirmModal}
            toggle={this.toggleUpdateEidConfirmModal}
            getApps={this.getWatchlistApps}
            initEid={externalIdToUpdate}
            analyzedAppId={appIdToUpdate}
          />
        )}
        <ConfirmationModal
          isOpen={showUpdateSubgroupsConfirmModal}
          toggle={this.toggleUpdateSubgroupsConfirmModal}
          confirm={this.updateWatchlistAppSubgroups}
          confirmHeader="Watchlist Subgroups Update"
          confirmBody={
            <QContainer>
              <QSelect
                multiple
                isMulti
                value={this.processSubGroupFilter(ownerGroups)}
                options={groupOptions}
                onChange={this.changeSubgroupFilter}
                label="Subgroups"
                formControlProps={{ sx: { width: '100%' } }}
                selectIconProps={{ fontSize: 'medium' }}
              />
            </QContainer>
          }
        />
        <ConfirmationModal
          isOpen={this.state.showDeleteConfirmModal}
          toggle={this.toggleDeleteConfirmModal}
          confirm={this.deleteWatchlistApp}
          confirmHeader={i18n.t('Watchlist Removal')}
          confirmBody={i18n.t(
            'Are you sure you want to remove this application from the watchlist? It will no longer be tracked and new versions will not be automatically submitted to your portal',
          )}
        />
        <CheckAppVersionModal
          isOpen={this.state.showCheckAppVersionModal}
          toggle={this.toggleCheckAppVersionModal}
          appData={this.state.selectedApp}
          getWatchlistApps={this.getWatchlistApps}
        />
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={3}>
            <StatCard
              icon="fa-solid fa-binoculars"
              leadText={this.state.watchlistStats.totalApps}
              subText={i18n.t('Apps Being Monitored')}
              bgClass="bg-success"
            />
          </Grid>
          <Grid item xs={3}>
            <StatCard
              icon="fa-solid fa-asterisk"
              leadText={this.state.watchlistStats.totalUniqueApps}
              subText={i18n.t('Unique Apps Analyzed')}
              bgClass="bg-warning-2"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <QCard
              style={{
                paddingRight: '0px',
                paddingLeft: '0px',
              }}
            >
              <QTabContext value={activeTab}>
                <QTabList tabs onChange={this.handleChange}>
                  <QTab label="Apps Tracking" value="1" data-testid="apps-tab">
                    <QTabLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => this.toggleActiveTab('1')}
                    >
                      Apps Tracking
                    </QTabLink>
                  </QTab>
                  <QTab
                    label="Developers Tracking"
                    value="2"
                    data-testid="developers-tab"
                  >
                    <QTabLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => this.toggleActiveTab('2')}
                    >
                      Developers Tracking
                    </QTabLink>
                  </QTab>
                </QTabList>
                <QTabContent activeTab={activeTab}>
                  <QTabPanel
                    tabId="1"
                    value="1"
                    style={
                      this.props.flags.mastV2
                        ? { padding: '0px', width: '100%' }
                        : {}
                    }
                  >
                    <QConditionalCard
                      className="card-accent-teal"
                      style={{ paddingRight: '0px', paddingLeft: '0px' }}
                    >
                      {!this.props.flags.mastV2 && (
                        <CardHeader>
                          <div className="d-flex justify-between">
                            <div>
                              <strong>{i18n.t('Watchlist Apps')}</strong>{' '}
                              <HelpButton helpItem="watchlistApps" />
                            </div>
                            <div className="d-flex justify-between">
                              <Button
                                size="sm"
                                color="link"
                                className="fa-solid fa-arrows-rotate pointer black-icon"
                                onClick={() => this.getWatchlistApps(1)}
                              />

                              <UncontrolledDropdown>
                                <DropdownToggle size="sm" color="link">
                                  <i className="fa-solid fa-download black-icon" />
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={async () => {
                                      toastrHelper.showSuccessToast(
                                        'Your request is being processed. Please look forward to an email with a link to download the requested file',
                                        null,
                                        this.props.flags.mastV2,
                                      );
                                      await axios.post('csv-request', {
                                        pageType: 'watchlist',
                                        isCompressed: false,
                                      });
                                    }}
                                  >
                                    <i className="fa-solid fa-file-excel pointer success-icon" />{' '}
                                    CSV
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={async () => {
                                      toastrHelper.showSuccessToast(
                                        'Your request is being processed. Please look forward to an email with a link to download the requested file',
                                        null,
                                        this.props.flags.mastV2,
                                      );
                                      await axios.post('csv-request', {
                                        pageType: 'watchlist',
                                        isCompressed: true,
                                      });
                                    }}
                                  >
                                    <i className="fa-solid fa-file-zipper warning-icon" />{' '}
                                    ZIP
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        </CardHeader>
                      )}

                      {this.props.flags.mastV2 && (
                        <div>
                          <QRow sx={{ margin: '20px' }}>
                            <QCol sx={{ width: '15%' }}>
                              <QSelect
                                size="small"
                                options={tempSortByOptions}
                                value={tempSortByV2}
                                onChange={handleSortBy}
                                label="Sort by"
                                selectIconProps={{ fontSize: 'medium' }}
                                formControlProps={{ sx: { width: '100%' } }}
                              />
                            </QCol>

                            <QCol sx={{ width: '85%' }}>
                              <div
                                className="d-flex justify-end"
                                style={{ paddingTop: '10px' }}
                              >
                                <div
                                  className="d-flex justify-between"
                                  style={{ columnGap: '10px' }}
                                >
                                  <QButton
                                    variant="outlined"
                                    color="link"
                                    size="sm"
                                    startIcon={
                                      <i
                                        className="fa-solid fa-rotate pointer"
                                        style={{
                                          fontSize: '20px',
                                          fontWeight: '400',
                                        }}
                                      />
                                    }
                                    onClick={() => this.getWatchlistApps(1)}
                                    style={{
                                      border: 'none',
                                      background: 'none',
                                      padding: '0px',
                                      minWidth: 'unset',
                                    }}
                                  />

                                  <QDropdownMenu
                                    variant="filled"
                                    IconComponent={null}
                                    value=""
                                    displayEmpty
                                    style={{
                                      background: 'none',
                                      paddingTop: '0px',
                                      minWidth: 'unset',
                                    }}
                                    sx={{
                                      '& .MuiInputBase-input': {
                                        padding: '0px 0px 0px 6px !important',
                                      },
                                    }}
                                    renderValue={() => (
                                      <QButton
                                        variant="outlined"
                                        startIcon={
                                          <i className="fa-solid fa-download" />
                                        }
                                        style={{
                                          border: 'none',
                                          background: 'none',
                                          padding: '0px',
                                          minWidth: 'unset',
                                        }}
                                      />
                                    )}
                                    onFocus={this.handleFocus}
                                  >
                                    <QDropdownSelectItem
                                      value=""
                                      style={{ display: 'none' }}
                                    />
                                    <QDropdownSelectItem
                                      onClick={async () =>
                                        this.downloadCsv(false)
                                      }
                                    >
                                      <QMenuButtonWithIcon
                                        icon={
                                          <i className="fa-solid fa-file-excel pointer success-icon" />
                                        }
                                        label="CSV"
                                      />
                                    </QDropdownSelectItem>
                                    <QDropdownSelectItem
                                      onClick={async () =>
                                        this.downloadCsv(true)
                                      }
                                    >
                                      <QMenuButtonWithIcon
                                        icon={
                                          <i className="fa-solid fa-file-zipper warning-icon" />
                                        }
                                        label="ZIP"
                                      />
                                    </QDropdownSelectItem>
                                  </QDropdownMenu>
                                </div>
                              </div>
                            </QCol>
                          </QRow>
                          <QRow sx={{ mb: '12px', ml: '12px', mr: '12px' }}>
                            <QCol sx={{ width: '100%' }}>
                              <QSearchBar
                                searchValue={query}
                                onChange={e => this.handleSearchQuery(e)}
                                onRemove={this.removeSearchQuery}
                                onKeyPress={e => this.handleKeyPress(e)}
                              />
                            </QCol>
                          </QRow>
                        </div>
                      )}

                      <QCardBody>
                        {!this.props.flags.mastV2 && (
                          <QRow>
                            <QCol xs="9" className="text-left">
                              <QRow>
                                <QCol xs="3">
                                  <Label for="app-query-input">
                                    <strong>{i18n.t('Search')}:</strong>
                                    <HelpButton helpItem="appSearch" />
                                  </Label>
                                  <InputGroup>
                                    <Input
                                      type="text"
                                      value={query}
                                      onChange={e => this.handleSearchQuery(e)}
                                      onKeyPress={e => this.handleKeyPress(e)}
                                    />
                                    {query && (
                                      <InputGroupAddon
                                        className="pointer input-group-warning"
                                        addonType="append"
                                        onClick={() => this.removeSearchQuery()}
                                      >
                                        <span className="input-group-text">
                                          <i className="fa-solid fa-xmark" />
                                        </span>
                                      </InputGroupAddon>
                                    )}
                                  </InputGroup>
                                </QCol>
                                <QCol xs="3">
                                  <Label for="column-sort-select">
                                    <strong>{i18n.t('Sort by')}:</strong>
                                  </Label>
                                  <Select
                                    value={tempSortBy}
                                    options={tempSortByOptions}
                                    onChange={handleSortBy}
                                  />
                                </QCol>
                                <QCol xs="3">
                                  <Label for="apps-per-page-select">
                                    <strong>{i18n.t('Apps per page')}:</strong>
                                  </Label>
                                  <Select
                                    value={currentPageSize}
                                    options={pageSizeOptions}
                                    onChange={e => this.handlePageSize(e)}
                                  />
                                </QCol>
                              </QRow>
                            </QCol>
                          </QRow>
                        )}

                        <div
                          className={
                            !this.props.flags.mastV2 && 'd-flex justify-end'
                          }
                        >
                          <AsyncTablePagination
                            pagination={this.state.pagination}
                            getPage={this.getWatchlistApps}
                            page={watchlistPage}
                            showRowsPerPage
                            currentRows={this.state.data.length}
                            handlePageSizeChange={this.handlePageSize}
                            pageSize={currentPageSize}
                            pageSizeOptions={pageSizeOptions}
                          />
                        </div>
                        {this.state.loading ? (
                          <Loading />
                        ) : (
                          <div>
                            <QTable className="fixed-table">
                              <QTableHead>
                                <QTableRow>
                                  <QTableCell
                                    width="20%"
                                    className="text-center"
                                  >
                                    {i18n.t('App')}&nbsp;
                                    <HelpButton helpItem="appColumn" />
                                  </QTableCell>
                                  <QTableCell width="10%">
                                    {i18n.t('Latest Version')}&nbsp;
                                    <HelpButton helpItem="latestVersion" />
                                  </QTableCell>
                                  <QTableCell width="15%">
                                    {i18n.t('Last Submission')}&nbsp;
                                    <HelpButton helpItem="lastSubmitted" />
                                  </QTableCell>
                                  <QTableCell
                                    width="15%"
                                    className="text-center"
                                  >
                                    {i18n.t('Tracking Since')}&nbsp;
                                    <HelpButton helpItem="trackingSince" />
                                  </QTableCell>
                                  <QTableCell
                                    width="14%"
                                    className="text-center"
                                  >
                                    {i18n.t('Number of Versions')} &nbsp;
                                    <HelpButton helpItem="numVersions" />
                                  </QTableCell>
                                  <QTableCell
                                    width="14%"
                                    className="text-center"
                                  >
                                    Subgroup Owners&nbsp;
                                    <HelpButton helpItem="subgroupOwners" />
                                  </QTableCell>
                                  <QTableCell
                                    width="12%"
                                    className="text-center"
                                  >
                                    {i18n.t('Actions')}&nbsp;
                                    <HelpButton helpItem="watchlistActions" />
                                  </QTableCell>
                                </QTableRow>
                              </QTableHead>
                              <QTableBody>
                                {this.state.showData.map((app, index) => (
                                  <WatchlistApp
                                    app={app}
                                    key={index}
                                    searchApp={() => this.searchApp(app)}
                                    updateWatchlistAppSubgroups={() =>
                                      this.confirmSubgroupsUpdate(app)
                                    }
                                    deleteWatchlistApp={() =>
                                      this.confirmDeletion(app)
                                    }
                                    updateExternalId={() =>
                                      this.confirmEidUpdate(app)
                                    }
                                    checkVersion={() =>
                                      this.confirmCheckAppVersion(app)
                                    }
                                  />
                                ))}
                              </QTableBody>
                            </QTable>
                            <div
                              className={
                                !this.props.flags.mastV2 && 'd-flex justify-end'
                              }
                            >
                              <AsyncTablePagination
                                pagination={this.state.pagination}
                                getPage={this.getWatchlistApps}
                                page={watchlistPage}
                                showRowsPerPage
                                currentRows={this.state.data.length}
                                handlePageSizeChange={this.handlePageSize}
                                pageSize={currentPageSize}
                                pageSizeOptions={pageSizeOptions}
                              />
                            </div>
                          </div>
                        )}
                      </QCardBody>
                    </QConditionalCard>
                  </QTabPanel>
                  <QTabPanel
                    tabId="2"
                    value="2"
                    style={
                      this.props.flags.mastV2
                        ? { padding: '16px', width: '100%' }
                        : {}
                    }
                  >
                    <WatchlistDeveloper />
                  </QTabPanel>
                </QTabContent>
              </QTabContext>
            </QCard>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  watchlistPage: state.emmApp.watchlistPage,
  lang: state.emmApp.portalConfig.lang,
  userAccess: state.emmApp.userAccess,
  groupAdminSubGroups: state.emmApp.groupAdminSubGroups,
  userSubGroups: state.emmApp.userSubGroups,
});

const mapDispatchToProps = {
  changeWatchlistPage,
  changeSearchQuery,
  changeCurrentSearchQuery,
  setPageTitle,
};

const AppWatchlistWithLDConsumer = withLDConsumer()(AppWatchlist);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppWatchlistWithLDConsumer);
