import React, { useEffect, useMemo, useState } from 'react';
import { CardFooter, Button } from 'reactstrap';
import classnames from 'classnames';
import { NavLink, Route, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QCol } from '../../Q-Components/QCol';
import { QRow } from '../../Q-Components/QRow';
import { QButton } from '../../Q-Components/QButton';
import { QCard } from '../../Q-Components/QCard';
import { QCardHeader } from '../../Q-Components/QCardHeader';
import { QCardBody } from '../../Q-Components/QCardBody';
import { QTabContext } from '../../Q-Components/QTabContext';
import { QTabList } from '../../Q-Components/QTabList';
import { QTab } from '../../Q-Components/QTab';
import { QTabLink } from '../../Q-Components/QTabLink';
import PermissionsCard, {
  usePermissions,
  savePermissions,
} from '../../GroupAdmin/BulkEditPermission/PermissionsCard';
import ApiWhiteListIpCard, {
  useApiWhiteListIpCard,
  addToIpsWhitelist,
} from '../../GroupAdmin/BulkEditPermission/ApiWhiteListIpCard';
import SubGroupsAndUsersCard, {
  useBulkSelectSubGroups,
  useBulkSelectUsers,
  useBulkSelectGroup,
} from '../../GroupAdmin/BulkEditPermission/SubGroupsAndUsersCard';
import {
  AssignSubGroups,
  useAssignSubGroups,
  saveAssignSubGroups,
} from '../../GroupAdmin/BulkEditPermission/AssignSubgroups';
import ConfirmationModal, {
  useConfirmationModal,
} from '../../Modals/ConfirmationModal';

export default function BulkEditPermissions({ kaiAccess = false }) {
  const confirmModalHook = useConfirmationModal();
  const usersHook = useBulkSelectUsers({ kaiAccess });
  const assignSubGroupsHook = useAssignSubGroups();
  const subGroupsHook = useBulkSelectSubGroups(
    usersHook.setSelectedUsers,
    usersHook.usersOptions,
  );
  const groupHook = useBulkSelectGroup({
    kaiAccess,
    setSubGroups: subGroupsHook.setSubGroups,
    setSubGroupsOptions: subGroupsHook.setSubGroupsOptions,
    setUsersOptions: usersHook.setUsersOptions,
    users: usersHook.users,
    setSelectedUsers: usersHook.setSelectedUsers,
  });
  const permissionsHook = usePermissions();
  const whitelistIpsHook = useApiWhiteListIpCard();
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('0');
  const { mastV2 } = useFlags();

  const permissionPath = kaiAccess
    ? '/kai/bulk-editing/permissions'
    : '/settings/group-admin/bulk-editing/permissions';
  const whitelistIpPath = kaiAccess
    ? '/kai/bulk-editing/whitelist-ips'
    : '/settings/group-admin/bulk-editing/whitelist-ips';
  const assignSubGroupsPath = kaiAccess
    ? '/kai/bulk-editing/subgroup-assignments'
    : '/settings/group-admin/bulk-editing/subgroup-assignments';

  useEffect(() => {
    groupHook.getGroupInfo();
    usersHook.getUsersInfo();

    if (location.pathname === permissionPath) {
      setActiveTab('0');
    } else if (location.pathname === whitelistIpPath) {
      setActiveTab('1');
    } else if (location.pathname === assignSubGroupsPath) {
      setActiveTab('2');
    }
  }, []);

  const setConfirmation = confirmModalHook.setModalContent;

  const setPermissionsConfirmation = () =>
    setConfirmation({
      confirmHeader: 'Bulk Permission Assignment',
      confirmBody:
        'Are you sure you want to set these permissions for these users?',
      confirm: () =>
        savePermissions(usersHook.get(), permissionsHook.get(), mastV2),
    });

  const setWhitelistConfirmation = () =>
    setConfirmation({
      confirmHeader: 'Bulk Allowlist Ips',
      confirmBody: 'Are you sure you want to allowlist ips for these users?',
      confirm: () =>
        addToIpsWhitelist(usersHook.get(), whitelistIpsHook.get(), mastV2),
    });

  const setSubGroupAssignmentConfirmation = () =>
    setConfirmation({
      confirmHeader: 'Bulk Sub Groups Assignment',
      confirmBody:
        'Are you sure you want to assign sub groups for these users?',
      confirm: () =>
        saveAssignSubGroups(usersHook.get(), assignSubGroupsHook.get(), mastV2),
    });

  const horizontalDivider = (
    <div
      style={{
        width: '2px',
        color: '#333',
        borderLeft: '1px solid #e5e5e5',
      }}
    />
  );

  let saveText = 'Permissions';

  if (location.pathname === permissionPath) {
    saveText = 'Permissions';
  } else if (location.pathname === whitelistIpPath) {
    saveText = 'Allowlist IPs';
  } else if (location.pathname === assignSubGroupsPath) {
    saveText = 'Sub Groups';
  }

  const resetForm = useMemo(() => {
    switch (location.pathname) {
      case permissionPath:
        return permissionsHook.resetPermissions;
      case '/kai/bulk-editing/':
        return permissionsHook.resetPermissions;
      case '/settings/group-admin/bulk-editing':
        return permissionsHook.resetPermissions;
      case whitelistIpPath:
        return whitelistIpsHook.resetIps;
      case assignSubGroupsPath:
        return assignSubGroupsHook.resetSubGroups;
      default:
        return () => {};
    }
  }, [
    assignSubGroupsHook.resetSubGroups,
    location.pathname,
    permissionsHook.resetPermissions,
    whitelistIpsHook.resetIps,
  ]);

  return (
    <QCol lg="10" md="12" xs="12">
      <QCard className="card-accent-primary">
        <ConfirmationModal {...confirmModalHook} />
        <QCardHeader title="Bulk Editing Permissions">
          <strong>Bulk Editing Permissions</strong>
        </QCardHeader>
        <QCardBody>
          <QRow>
            <SubGroupsAndUsersCard
              {...usersHook}
              {...subGroupsHook}
              {...groupHook}
              kaiAccess={kaiAccess}
            />
            {horizontalDivider}
            <QCol xs="" sx={{ marginLeft: '16px' }}>
              <QTabContext value={activeTab}>
                <QTabList
                  tabs
                  style={{ marginBottom: '15px' }}
                  onChange={(event, newTab) => {
                    setActiveTab(newTab);
                  }}
                >
                  <QTab
                    label="Permissions"
                    value="0"
                    to={permissionPath}
                    component={NavLink}
                    style={{ textDecoration: 'none', color: 'black' }}
                    data-testid="permissions-tab-v2"
                  >
                    <QTabLink
                      className={classnames({
                        active: location.pathname === permissionPath,
                      })}
                      onClick={() => {
                        history.push(permissionPath);
                      }}
                      data-testid="permissions-tab-v1"
                    >
                      Permissions
                    </QTabLink>
                  </QTab>
                  <QTab
                    label="Allowlist Ips"
                    value="1"
                    to={whitelistIpPath}
                    component={NavLink}
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <QTabLink
                      className={classnames({
                        active: location.pathname === whitelistIpPath,
                      })}
                      onClick={() => {
                        history.push(whitelistIpPath);
                      }}
                    >
                      Allowlist Ips
                    </QTabLink>
                  </QTab>
                  <QTab
                    label="SubGroup Assignments"
                    value="2"
                    to={assignSubGroupsPath}
                    component={NavLink}
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <QTabLink
                      className={classnames({
                        active: location.pathname === assignSubGroupsPath,
                      })}
                      onClick={() => {
                        history.push(assignSubGroupsPath);
                      }}
                    >
                      SubGroup Assignments
                    </QTabLink>
                  </QTab>
                </QTabList>
              </QTabContext>
              <br />

              <Route
                path={[
                  '/kai/bulk-editing',
                  '/kai/bulk-editing/permissions',
                  '/settings/group-admin/bulk-editing/permissions',
                  '/settings/group-admin/bulk-editing',
                ]}
                exact
                render={routeProps => (
                  <PermissionsCard
                    {...routeProps}
                    {...permissionsHook}
                    kaiAccess={kaiAccess}
                    setConfirmationModal={setPermissionsConfirmation}
                  />
                )}
              />

              <Route
                path={[
                  '/kai/bulk-editing/whitelist-ips',
                  '/settings/group-admin/bulk-editing/whitelist-ips',
                ]}
                render={routeProps => (
                  <ApiWhiteListIpCard
                    {...routeProps}
                    {...whitelistIpsHook}
                    setConfirmationModal={setWhitelistConfirmation}
                  />
                )}
              />
              <Route
                path={[
                  '/kai/bulk-editing/subgroup-assignments',
                  '/settings/group-admin/bulk-editing/subgroup-assignments',
                ]}
                render={routeProps => (
                  <AssignSubGroups
                    {...routeProps}
                    subGroups={subGroupsHook.subGroups}
                    {...assignSubGroupsHook}
                    setConfirmationModal={setSubGroupAssignmentConfirmation}
                  />
                )}
              />
            </QCol>
          </QRow>
        </QCardBody>
        {mastV2 ? (
          <QRow justifyContent="flex-end">
            <QButton
              className="float-right mr-2"
              sx={{ marginRight: '8px' }}
              color="danger"
              variant="outlined"
              onClick={resetForm}
            >
              Reset Fields
              <i className="ml-2 fa-solid fa-arrows-rotate" />
            </QButton>

            <QButton
              className="float-right"
              color="primary"
              variant="filled"
              onClick={confirmModalHook.toggle}
              disabled={
                usersHook.users.length === 0 &&
                subGroupsHook.selectedSubGroups.length === 0
              }
            >
              Save {`${saveText}`}
              <i className="ml-2 fa-solid fa-floppy-disk" />
            </QButton>
          </QRow>
        ) : (
          <CardFooter>
            <Button
              className="float-right"
              color="primary"
              onClick={confirmModalHook.toggle}
              disabled={
                usersHook.users.length === 0 &&
                subGroupsHook.selectedSubGroups.length === 0
              }
            >
              Save {`${saveText}`}
              <i className="ml-2 fa-solid fa-floppy-disk" />
            </Button>
            <Button
              className="float-right mr-2"
              color="danger"
              onClick={resetForm}
            >
              Reset Fields
              <i className="ml-2 fa-solid fa-arrows-rotate" />
            </Button>
          </CardFooter>
        )}
      </QCard>
    </QCol>
  );
}
