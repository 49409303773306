import React from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

export default function Footer() {
  const location = useLocation();

  return (
    <footer className="app-footer">
      <a href="https://kryptowire.com">Quokka</a> &copy;{' '}
      {moment().format('YYYY')} Quokka.
      {process.env.REACT_APP_APPLIANCE === 'cisa' &&
        location.pathname.match(/(ios|android)-report/g) && (
          <div
            style={{ fontSize: '0.875rem', lineHeight: 1.15, padding: '1%' }}
          >
            <i>
              CISA’s analysis provided through its Mobile Application Vetting
              (MAV) service is for the purpose of identifying a limited range of
              threats and vulnerabilities on third party mobile and
              government-developed mobile applications (apps). There is no
              guarantee that CISA’s analysis will detect all or any threats or
              vulnerabilities. Additionally, reports produced through CISA’s MAV
              Service represent a snapshot of a single point in time. Results
              may vary at any juncture following the analysis, as cybersecurity
              vulnerabilities are constantly emerging. CISA recommends MAV users
              run analysis on mobile apps at a regular interval based on their
              risk management posture and internal policies. Reports produced
              through CISA’s MAV Service are marked For Official Use Only (FOUO)
              and due to licensing limitations, may not be distributed outside
              of the U.S. Government absent explicit written consent from the
              CISA MAV Program Office. All MAV information is provided free of
              any warranties.  MAV report recipients understand they are under
              no obligation to implement any changes to its information systems
              that CISA may recommend.{' '}
            </i>
          </div>
        )}
    </footer>
  );
}
