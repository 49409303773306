import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { datadogRum } from '@datadog/browser-rum';

export class ErrorHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  async componentDidCatch(error, info) {
    info.currentPathname = this.props.location.pathname;

    datadogRum.addError(error, {
      context: {
        errorInfo: info,
        path: info.currentPathname,
      },
    });

    this.setState({ hasError: true });

    const lastErrorSentTime = moment(localStorage.getItem('lastSentError'));
    console.log('Last error sent time:', lastErrorSentTime);
    const timeSinceLastErrorReport = moment().diff(
      lastErrorSentTime,
      'milliseconds',
    );

    if (timeSinceLastErrorReport < 30000) {
      console.log('Cool-down period still resetting, not sending error');
      return;
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log("Not sending error email since we're not in production");
      return;
    }

    // Only send the error once
    /* istanbul ignore next */
    localStorage.setItem('lastSentError', moment().format());
    try {
      await axios.post(`emm/error/${this.props.type}`, {
        error: { message: error.message, stack: error.stack },
        info,
      });
    } catch (err) {
      console.log('Error submitting portal error report:', err);
    }
  }

  render() {
    const { hasError } = this.state;
    const { type } = this.props;

    if (hasError && (type === 'androidReport' || type === 'iosReport')) {
      return (
        <div>
          <h3>Error with report generation</h3>
          <p>
            A report has been sent to Quokka about the error. No further action
            needs to be taken.
          </p>
        </div>
      );
    }
    if (hasError) {
      return (
        <div>
          <h3>Error with Q-MAST portal</h3>
          <p>
            A report has been sent to Quokka about the error. No further action
            needs to be taken.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorHandler);
