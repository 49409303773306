import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import translationEng from './locales/en/translations.json';
import translationVi from './locales/vi/translations.json';
import translationCn from './locales/cn/translations.json';
import translationJp from './locales/jp/translations.json';
import translationDe from './locales/de/translations.json';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: 'us',
    fallbackLng: 'us', // use en if detected lng is not available

    keySeparator: '>', // we do not use keys in form messages.welcome
    nsSeparator: '|',

    interpolation: {
      escapeValue: false, // react already safes from xss
      formatSeparator: ',',
    },
    react: {
      wait: true,
      defaultTransParent: 'div', // a valid react element - required before react 16
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: [
        'a',
        'div',
        'br',
        'strong',
        'i',
        'span',
        'p',
        'li',
        'ul',
      ],
    },

    resources: {
      us: {
        translations: translationEng,
      },
      vn: {
        translations: translationVi,
      },
      cn: {
        translations: translationCn,
      },
      jp: {
        translations: translationJp,
      },
      de: {
        translations: translationDe,
      },
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
  });

export default i18n;
