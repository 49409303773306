import React, { useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { QActionSelect } from '@kw/quokka-ui';

import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QButton } from '../Q-Components/QButton';
import { QButtonGroup } from '../Q-Components/QButtonGroup';
import { QDropdownToggle } from '../Q-Components/QDropdownToggle';
import { QTooltip } from '../Q-Components/QTooltip';
import { QUncontrolledDropdown } from '../Q-Components/QUncontrolledDropdown';
import { QDropdown } from '../Q-Components/QDropdown';
import { QDropdownSelectItem } from '../Q-Components/QDropdownSelectItem';
import { QDropdownMenu } from '../Q-Components/QDropdownMenu';
import { QTypography } from '../Q-Components/QTypography';
import i18n from '../../localization/i18n';
import { NewUser } from '../Settings/GroupAdmin/NewUser';
import { toastrHelper } from '../../logic/toastrHelper';
import { AsyncPaginatedTable } from '../AsyncPaginatedTable';
import kaiService from '../../services/kai';
import groupAdminService from '../../services/groupAdmin';

export function GroupUsers({ kaiAccess = false }) {
  const history = useHistory();
  const { mastV2 } = useFlags();

  const [csvTooltipOpen, setCsvTooltipOpen] = useState(false);

  const getUserData = async params => {
    if (kaiAccess) {
      return kaiService.getKaiUsers({ params });
    }
    return groupAdminService.getGroupAdminUsers({ params });
  };

  const createNewUser = async (email, groupId, subGroupId) => {
    try {
      const response = kaiAccess
        ? await axios.post(`adminV2/user`, {
            email,
            groupId,
            subGroupId,
          })
        : await axios.post(`group-admin/user`, {
            email,
            subGroupId,
          });

      // For appliances the user's generated password is returned to the client
      // since the appliance doesn't send out emails. We then display it to the group
      // admin to pass along to the newly created user
      if (response.data.generatedPassword) {
        // eslint-disable-next-line no-alert
        alert(`Generated password: ${response.data.generatedPassword}`);
      } else {
        toastrHelper.showSuccessToast(
          i18n.t(
            "New user created. An email has been sent to the new user's email address with their login information.",
          ),
          null,
          mastV2,
        );
      }
    } catch (err) {
      toastrHelper.showWarningToast(
        `${i18n.t('Error creating user')}: ${
          err.response.data.message || err.response.data.msg
        }`,
        null,
        mastV2,
      );
    }

    getUserData();
  };

  const toggleCsvTooltip = () => setCsvTooltipOpen(!csvTooltipOpen);

  const handleFocus = event => {
    event.target.style.background = 'none';
  };

  const getDownloadLink = isCompressed =>
    `${process.env.REACT_APP_API_BASE_URL}/${
      kaiAccess ? 'adminV2' : 'group-admin'
    }/users-csv?isCompressed=${isCompressed}&auth=basic`;

  const downloadOptions = [
    {
      value: 'csv',
      label: 'CSV',
      icon: <i className="fa-solid fa-file-excel pointer success-icon" />,
      // on click download csv
      onClick: () => {
        window.open(getDownloadLink(false), '_blank');
      },
    },
    {
      value: 'zip',
      label: 'ZIP',
      icon: <i className="fa-solid fa-file-zipper warning-icon" />,
      onClick: () => {
        window.open(getDownloadLink(true), '_blank');
      },
    },
  ];

  const columns = [
    ...(kaiAccess
      ? [
          {
            label: 'ID',
            keyName: 'id',
            filterable: true,
            width: '5%',
          },
        ]
      : []),
    {
      label: i18n.t('Email'),
      keyName: 'email',
      filterable: true,
      sortable: true,
      columnLink: rowData =>
        kaiAccess
          ? `/kai/users/${rowData.id}`
          : `/settings/group-admin/users/${rowData.id}`,
    },
    {
      label: 'Created At',
      keyName: 'createdAt',
      component: ({ value }) => {
        if (!value) {
          return <span>{i18n.t('Never')}</span>;
        }
        return <span>{moment(value).format('L - LTS')}</span>;
      },
    },
    {
      label: i18n.t('Last Login'),
      keyName: 'lastLogin',
      component: ({ value }) => {
        if (!value) {
          return <span>Never</span>;
        }
        return <span>{moment(value).format('L - LTS')}</span>;
      },
    },
    {
      label: i18n.t('Group'),
      keyName: 'userGroup',
      component: ({ value }) => {
        if (value) {
          return <span>{value.name}</span>;
        }
        return 'None';
      },
    },
    {
      label: i18n.t('Sub Groups'),
      keyName: 'subGroups',
      component: ({ value }) => (
        <span>{value.map(subGroup => subGroup.name).join(', ')}</span>
      ),
    },
    {
      label: '45 Day Absence',
      keyName: 'lastLogin',
      component: ({ value }) => {
        if (!value) {
          return <span>N/A</span>;
        }
        return (
          <span>
            {moment().diff(moment(value), 'days') > 45 ? (
              <i className="fa-solid fa-exclamation-circle fa-lg error-icon" />
            ) : (
              <i className="fa-solid fa-circle-check fa-lg success-icon" />
            )}
          </span>
        );
      },
    },
    {
      label: i18n.t('Apps Submitted'),
      keyName: 'appsSubmitted',
    },
    {
      label: 'Permissions',
      keyName: 'userAccess',
      component: ({ rowData: user }) => (
        <div>
          {user.locked ? (
            <i
              className="fa-regular fa-lock fa-lg danger-icon mr-1"
              title="Account locked"
            />
          ) : (
            <i
              className="fa-regular fa-lock fa-lg success-icon mr-1"
              title="Account not locked"
            />
          )}
          {user.userAccess.canViewReports ? (
            <i
              className="fa-regular fa-eye fa-lg success-icon mr-1"
              title="Can view reports"
            />
          ) : (
            <i
              className="fa-regular fa-eye fa-lg danger-icon mr-1"
              title="Cannot view reports"
            />
          )}
          {user.userAccess.canSubmit ? (
            <i
              className="fa-regular fa-upload fa-lg success-icon mr-1"
              title="Can submit applications"
            />
          ) : (
            <i
              className="fa-regular fa-upload fa-lg danger-icon mr-1"
              title="Cannot submit applications"
            />
          )}
          {user.apiKeyDisabled ? (
            <i
              className="fa-regular fa-key fa-lg danger-icon mr-1"
              title="API Key disabled"
            />
          ) : (
            <i
              className="fa-regular fa-key fa-lg success-icon mr-1"
              title="API Key enabled"
            />
          )}
          {user.userAccess.ssoLogin ? (
            <i
              className="fa-regular fa-user-shield fa-lg success-icon"
              title="SSO Login enabled"
            />
          ) : (
            <i
              className="fa-regular fa-user-shield fa-lg danger-icon"
              title="SSO Login disabled"
            />
          )}
        </div>
      ),
    },
    {
      label: 'Permissions Last Modified',
      keyName: 'permissionsLastModified',
      component: ({ value }) => {
        if (!value) {
          return <span>Never</span>;
        }
        return <span>{moment(value).format('L - LTS')}</span>;
      },
    },
  ];

  return (
    <QCard className="card-accent-primary">
      <QCardHeader
        disableTypography
        title={
          <QTypography variant="h4Bold" color="#000000">
            {kaiAccess
              ? i18n.t('Quokka Users Administration')
              : i18n.t('Group Administration')}
          </QTypography>
        }
        action={
          <QButtonGroup
            style={{
              width: '100%',
              gap: '16px',
              paddingRight: '16px',
            }}
          >
            <QButton
              variant="outlined"
              startIcon={
                <i
                  className="fa-solid fa-arrows-rotate ml-2 pointer"
                  style={{ fontSize: '20px', fontWeight: '400' }}
                />
              }
              onClick={() => getUserData()}
              style={{
                border: 'none',
                background: 'none',
                padding: '0px',
                minWidth: 'unset',
              }}
            />
            <NewUser createNewUser={createNewUser} kaiAccess={kaiAccess} />
            <QButton
              variant="outlined"
              onClick={() =>
                history.push(
                  kaiAccess
                    ? `/kai/bulk-editing`
                    : `/settings/group-admin/bulk-editing`,
                )
              }
              startIcon={
                <i
                  className="fa-solid fa-users-gear pointer"
                  style={{ fontSize: '20px', fontWeight: '400' }}
                />
              }
              style={{ fontWeight: '700', background: 'none' }}
            >
              Bulk Editing
            </QButton>
            <QTooltip
              placement="right"
              title="Download Group Users data as CSV or ZIP"
              enterDelay={400}
              enterNextDelay={400}
            >
              <div>
                {mastV2 ? (
                  <QActionSelect
                    formControlProps={{
                      style: {
                        paddingTop: '0px',
                        paddingLeft: '0px',
                      },
                      sx: { width: '130px', '& fieldset': { border: 'none' } },
                    }}
                    closedLabel="Download"
                    selectProps={{
                      IconComponent: null,
                      'data-testid': 'download-menu',
                    }}
                    labelProps={{
                      shrink: false,
                      style: {
                        overflow: 'visible',
                      },
                    }}
                    options={downloadOptions}
                    labelButtonProps={{
                      variant: 'outlined',
                      startIcon: (
                        <i
                          className="fa-solid fa-download"
                          style={{ fontSize: '20px', fontWeight: '400' }}
                        />
                      ),
                      style: {
                        fontWeight: '700',
                        background: 'none',
                        transform: 'translate(-12px, -14px) scale(1)',
                      },
                    }}
                  />
                ) : (
                  <QDropdown
                    variant="filled"
                    style={{
                      paddingTop: '0px',
                      paddingBottom: '0px',
                    }}
                    sx={{ minWidth: '120px' }}
                    dropdown
                  >
                    <QDropdownToggle
                      id="groupUsersCsv"
                      size="sm"
                      disableAnimation
                      shrink={false}
                      style={{
                        transform: 'none',
                        overflow: 'visible',
                      }}
                    >
                      <QButton
                        variant="outlined"
                        startIcon={
                          <i
                            className="fa-solid fa-download"
                            style={{ fontSize: '20px', fontWeight: '400' }}
                          />
                        }
                        style={{ fontWeight: '700', background: 'none' }}
                      >
                        Download
                      </QButton>
                    </QDropdownToggle>
                    <QDropdownMenu
                      variant="filled"
                      IconComponent={null}
                      value=""
                      displayEmpty
                      style={{
                        background: 'none',
                        paddingTop: '0px',
                        minWidth: 'unset',
                      }}
                      renderValue={() => {}}
                      onFocus={handleFocus}
                      data-testid="download-menu"
                    >
                      {/* First item is always selected in MUI by default so this is to get rid of that behavior. */}
                      <QDropdownSelectItem
                        value=""
                        style={{ display: 'none' }}
                      />
                      <QDropdownSelectItem value="csv">
                        <QButton
                          variant="ghost"
                          startIcon={
                            <i className="fa-solid fa-file-excel pointer success-icon" />
                          }
                          href={
                            kaiAccess
                              ? `${process.env.REACT_APP_API_BASE_URL}/adminV2/users-csv?isCompressed=false&auth=basic`
                              : `${process.env.REACT_APP_API_BASE_URL}/group-admin/users-csv?isCompressed=false&auth=basic`
                          }
                        >
                          CSV
                        </QButton>
                      </QDropdownSelectItem>
                      <QDropdownSelectItem value="zip">
                        <QButton
                          variant="ghost"
                          startIcon={
                            <i className="fa-solid fa-file-zipper warning-icon" />
                          }
                          href={
                            kaiAccess
                              ? `${process.env.REACT_APP_API_BASE_URL}/adminV2/users-csv?isCompressed=true&auth=basic`
                              : `${process.env.REACT_APP_API_BASE_URL}/group-admin/users-csv?isCompressed=true&auth=basic`
                          }
                        >
                          ZIP
                        </QButton>
                      </QDropdownSelectItem>
                    </QDropdownMenu>
                  </QDropdown>
                )}
              </div>
            </QTooltip>
          </QButtonGroup>
        }
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span>
            <strong>
              {kaiAccess
                ? i18n.t('Quokka Users Administration')
                : i18n.t('Group Administration')}
            </strong>
            <i
              className="fa-solid fa-arrows-rotate ml-2 pointer"
              onClick={() => getUserData()}
            />
          </span>

          <div className="d-flex">
            <NewUser createNewUser={createNewUser} kaiAccess={kaiAccess} />
            <QButton
              size="sm"
              color="link"
              id="bulkEditingPermission"
              className="pointer black-icon"
              onClick={() =>
                history.push(
                  kaiAccess
                    ? `/kai/bulk-editing`
                    : `/settings/group-admin/bulk-editing`,
                )
              }
            >
              {' '}
              <i className="fa-solid fa-users-gear pointer" /> Bulk Editing
            </QButton>
            <QUncontrolledDropdown>
              <QDropdownToggle
                id="groupUsersCsv"
                size="sm"
                color="link"
                className="black-icon"
              >
                <i className="fa-solid fa-download" /> Download
              </QDropdownToggle>
              <QDropdownMenu>
                <QDropdownSelectItem
                  href={
                    kaiAccess
                      ? `${process.env.REACT_APP_API_BASE_URL}/adminV2/users-csv?isCompressed=false&auth=basic`
                      : `${process.env.REACT_APP_API_BASE_URL}/group-admin/users-csv?isCompressed=false&auth=basic`
                  }
                  target="_blank"
                >
                  <i className="fa-solid fa-file-excel pointer success-icon" />{' '}
                  CSV
                </QDropdownSelectItem>
                <QDropdownSelectItem
                  href={
                    kaiAccess
                      ? `${process.env.REACT_APP_API_BASE_URL}/adminV2/users-csv?isCompressed=true&auth=basic`
                      : `${process.env.REACT_APP_API_BASE_URL}/group-admin/users-csv?isCompressed=true&auth=basic`
                  }
                  target="_blank"
                >
                  <i className="fa-solid fa-file-zipper warning-icon" /> ZIP
                </QDropdownSelectItem>
              </QDropdownMenu>
            </QUncontrolledDropdown>
            <QTooltip
              className="animated fadeIn"
              placement="right"
              isOpen={csvTooltipOpen}
              target="groupUsersCsv"
              toggle={toggleCsvTooltip}
              delay={{ show: 400, hide: 0 }}
            >
              Download Group Users data as CSV or ZIP
            </QTooltip>
          </div>
        </div>
      </QCardHeader>

      <QCardBody>
        <AsyncPaginatedTable fetchData={getUserData} columns={columns} />
      </QCardBody>
    </QCard>
  );
}
