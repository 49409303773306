import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  ListGroupItem,
  ListGroup,
} from 'reactstrap';
import _ from 'lodash';

import DataTable from '../../DataTable/DataTable';
import ApiCall from '../Android/ApiCall';
import MethodCallParams from '../Android/MethodCallParams';
import PermissionsRequestedDatatable from '../iOS/PermissionsRequestedDatatable';
import DynamicallyLoadedLibrariesDatatable from '../iOS/DynamicallyLoadedLibrariesDatatable';
import util from '../../../logic/util';
import MarketInfoTable from '../iOS/MarketInfoTable';
import CryptoOperationsDatatable from '../iOS/CryptoOperationsDatatable';

import DevInfo from './FindingData/DevInfo';
import Eula from './FindingData/Eula';
import NetworkEncryption from './FindingData/NetworkEncryption';
import SensitiveDataExposureTable from './FindingData/SensitiveDataExposureTable';
import HttpsCerts from './FindingData/HttpsCerts';
import FileAccess from './FindingData/FileAccess';
import IosNetworkArbitrary from './FindingData/IosNetworkArbitrary';
import IosCryptoOps from './FindingData/IosCryptoOps';
import IosHardCodedKeys from './FindingData/IosHardCodedKeys';

export default class IosFindingData extends Component {
  render() {
    const { findingId, data } = this.props;

    console.log('Rendering finding ID:', findingId);

    // All finding IDs that are simple data tables that show method calls
    const simpleDataTableFindingIds = ['4.1.2.3.9'];

    console.log('Rendering finding: ', findingId);

    return (
      <div>
        {findingId === '4.1.1.1.1' && <MarketInfoTable />}

        {findingId === '4.1.1.1.2' && <PermissionsRequestedDatatable />}

        {findingId === '4.1.1.3.1' && <DevInfo data={data} />}

        {findingId === '4.1.2.1.1' && <PermissionsRequestedDatatable />}

        {findingId === '4.1.2.1.2' && (
          <strong>Operating system enforces this behavior.</strong>
        )}

        {findingId === '4.1.2.3.1' && <Eula eula={data.eula} />}

        {simpleDataTableFindingIds.includes(findingId) && (
          <DataTable
            data={data}
            columns={[
              { label: 'API Call', component: ApiCall },
              {
                label: 'Parameters',
                component: MethodCallParams,
              },
            ]}
          />
        )}

        {findingId === '4.1.2.4.1(2)' && (
          <NetworkEncryption
            data={
              _.isEmpty(data)
                ? []
                : data.filter(
                    entry =>
                      entry.request && entry.request.url.includes('https'),
                  )
            }
          />
        )}

        {findingId === '4.1.2.4.1(3)' && <CryptoOperationsDatatable />}

        {findingId === '4.1.2.5.1' && <PermissionsRequestedDatatable />}

        {findingId === '4.1.2.5.2' && (
          <SensitiveDataExposureTable data={data} />
        )}

        {(findingId === '4.1.4.2.2' || findingId === '4.1.4.2.3') && (
          <HttpsCerts data={data} />
        )}

        {findingId === '4.1.5.1.1(1)' && <span>No malware detected</span>}

        {findingId === '4.2.2.1.2' && (
          <HttpsCerts
            data={data.network_traffic.filter(entry => entry.server_cert)}
          />
        )}

        {findingId === '4.1.2.3.8' && <IosHardCodedKeys data={data} />}

        {findingId === '4.1.2.3.2' && <PermissionsRequestedDatatable />}

        {findingId === '4.1.2.3.5' && <FileAccess data={data} platform="ios" />}

        {findingId === '4.1.2.5.3' && (
          <ul>
            {data.permissions.map(perm => (
              <li key={perm.name}>
                {util.capitalize(perm.name.split('_').join(' '))}
              </li>
            ))}
          </ul>
        )}

        {(findingId === '4.1.5.1.1(3)' || findingId === '4.1.5.3.1') && (
          <Card className="card-accent-danger">
            <CardHeader>Dynamically Loaded Libraries & Frameworks</CardHeader>
            <CardBody>
              <DynamicallyLoadedLibrariesDatatable />
            </CardBody>
          </Card>
        )}

        {findingId === '4.1.2.4.1(1)' && <IosNetworkArbitrary data={data} />}

        {findingId === '4.1.2.3.6(1)' && <IosCryptoOps data={data} />}

        {findingId === '4.1.2.3.6(2)' && (
          <div>
            <strong>Methods:</strong>
            {data.methods ? (
              <ListGroup>
                {data.methods.map(method => (
                  <ListGroupItem>{method}</ListGroupItem>
                ))}
              </ListGroup>
            ) : (
              <span>None detected</span>
            )}
          </div>
        )}

        {findingId === '4.1.2.3.6(3)' && (
          <div>
            <strong>Methods Found:</strong>
            {data.methods && data.methods.length > 0 ? (
              <ul>
                {data.methods.map((method, index) => (
                  <li key={index}>{method}</li>
                ))}
              </ul>
            ) : (
              <span>SecureRandom() method not found in dynamic analysis.</span>
            )}
          </div>
        )}

        {findingId === '4.1.4.2.1' && Array.isArray(data) && (
          <DataTable
            data={data}
            columns={[
              {
                keyName: 'Method',
                label: 'Method',
              },
              {
                label: 'Parameters',
                component: ({ rowData: params }) => (
                  <div>
                    {Object.entries(params).map(([key, value]) => {
                      if (key === 'Method') {
                        return null;
                      }

                      return (
                        <div key={key}>
                          <strong>{key}</strong>: {value.toString()}
                        </div>
                      );
                    })}
                  </div>
                ),
              },
            ]}
            rowsPerPage={5}
          />
        )}

        {findingId === '4.1.2.3.7' && (
          <span>Operation system enforces this behavior.</span>
        )}
      </div>
    );
  }
}
