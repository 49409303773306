import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QTableCell as MuiTableCell } from '@kw/quokka-ui';

const LDTableCell = ({ children, headerCell = false, ...props }) => {
  const { mastV2 } = useFlags();
  const filteredProps = ['className'];

  const muiTableCellProps = Object.keys(props).reduce((acc, key) => {
    if (!filteredProps.includes(key)) {
      acc[key] = props[key];
    }
    return acc;
  }, {});
  return mastV2 ? (
    <MuiTableCell {...muiTableCellProps}>{children}</MuiTableCell>
  ) : headerCell ? (
    <th {...props}>{children}</th>
  ) : (
    <td {...props}>{children}</td>
  );
};
export const QTableCell = withLDConsumer()(LDTableCell);
