import React from 'react';
import { Pie } from 'react-chartjs-2';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import i18n from '../../localization/i18n';
import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QDoughnut } from '../Q-Components/QDoughnut';

const LDUniqueAppsBySource = ({
  isLoading,
  header,
  binaryCount,
  storeCount,
}) => {
  const { mastV2 } = useFlags();

  const binaryColor = mastV2 ? '#56B09366' : 'rgba(0,0,0, 0.5)';
  const storeColor = mastV2 ? '#FFA38B' : 'rgba(255,0,0, 0.5)';

  const chartData = {
    datasets: [
      {
        data: [binaryCount, storeCount],
        backgroundColor: [binaryColor, storeColor],
      },
    ],
    labels: ['Binary', 'Store'],
  };

  return (
    <QCard className="card-accent-secondary">
      <QCardHeader
        title={i18n.t(header)}
        action={
          <span>
            {isLoading && <i className="fa-solid fa-spinner fa-spin" />}
          </span>
        }
      >
        <div className="d-flex justify-between">
          <strong>{i18n.t(header)}</strong>
          <span>
            {isLoading && <i className="fa-solid fa-spinner fa-spin" />}
          </span>
        </div>
      </QCardHeader>
      <QCardBody>
        {mastV2 ? (
          <QDoughnut
            data={chartData}
            height={300}
            options={{
              maintainAspectRatio: false,
              plugins: {
                datalabels: {
                  display: context =>
                    context.dataset.data[context.dataIndex] >= 1,
                  color: 'white',
                },
                title: {
                  display: true,
                  text: 'Number of unique apps that have been analyzed',
                  position: 'bottom',
                  font: {
                    weight: 'normal',
                    style: 'italic',
                  },
                },
              },
            }}
          />
        ) : (
          <Pie
            data={chartData}
            height={300}
            options={{
              maintainAspectRatio: false,
              plugins: {
                datalabels: {
                  display: context =>
                    context.dataset.data[context.dataIndex] >= 1,
                  color: 'white',
                },
                title: {
                  display: true,
                  text: 'Number of unique apps that have been analyzed',
                  position: 'bottom',
                  font: {
                    weight: 'normal',
                    style: 'italic',
                  },
                },
              },
            }}
          />
        )}
      </QCardBody>
    </QCard>
  );
};

const UniqueAppsBySource = withLDConsumer()(LDUniqueAppsBySource);

export default UniqueAppsBySource;
