import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import DataTable from '../DataTable/DataTable';
import i18n from '../../localization/i18n';
import { getCodeFromCountryName } from '../../logic/countryUtil';

class CountriesContactedList extends Component {
  getCountryCode(country) {
    if (country === 'Europe') {
      return 'eu';
    }
    const countryCode = getCodeFromCountryName(country);
    if (!countryCode) {
      return '';
    }

    return countryCode.toLowerCase();
  }

  render() {
    const { parsedAppIssues, platform, foreignCountries } = this.props;

    const connections = parsedAppIssues.find(
      issue =>
        (foreignCountries
          ? issue.reportIdentifier === 'POSSIBLE_FOREIGN_CONNECTION'
          : issue.reportIdentifier === 'HIGHRISK_COUNTRY_CONNECTION') &&
        issue.found === true,
    );

    const evidence = connections?.evidence || [];

    const uniqueEvidence = evidence.reduce(
      (acc, current) => {
        const currentEvidence = { ...current };

        if (platform === 'ios') {
          try {
            currentEvidence.domain = new URL(
              currentEvidence.url,
            ).hostname.replace('www', '');
          } catch (_) {
            currentEvidence.domain = '';
          }
          currentEvidence.ip = currentEvidence.ip_address;
        }

        const key = `${currentEvidence.domain}-${currentEvidence.ip}-${currentEvidence.country}`;
        if (!acc.set.has(key)) {
          acc.set.add(key);
          acc.unique.push(currentEvidence);
        }
        return acc;
      },
      { set: new Set(), unique: [] },
    ).unique;

    return uniqueEvidence?.length === 0 ? (
      <span>{i18n.t('No parsed evidence found.')}</span>
    ) : (
      <div>
        <DataTable
          data={uniqueEvidence}
          columns={[
            {
              keyName: 'domain',
              label: i18n.t('Domain'),
              sortable: true,
              filterable: true,
              width: '30%',
            },
            {
              keyName: 'ip',
              label: i18n.t('IP Address'),
              sortable: true,
              filterable: true,
              width: '30%',
            },
            {
              keyName: 'country',
              label: i18n.t('Country'),
              component: ({ value }) => (
                <div>
                  <span className={`fi fi-${this.getCountryCode(value)}`} />{' '}
                  {value}
                </div>
              ),
              sortable: true,
              filterable: true,
              width: '30%',
            },
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ appResults }) => ({
  parsedAppIssues: appResults.parsedIssues,
});

export default withRouter(connect(mapStateToProps)(CountriesContactedList));
