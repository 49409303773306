import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  threatScore: null,

  results: {},
  niapResults: {},
  reportItems: [],

  appIssues: [],
  niapAppIssues: [],
  owaspMasvsAppIssues: [],

  traffic: null,
  parsedIssues: [],

  owaspMSTGResults: [],
  sbomResults: [],

  availableResults: {},
};

export { initialState };

export const appResultsSlice = createSlice({
  name: 'appResults',
  initialState,
  reducers: {
    setThreatScore: (state, action) => ({
      ...state,
      threatScore: action.payload,
    }),
    setResults: (state, action) => ({
      ...state,
      results: action.payload,
    }),
    setNiapResults: (state, action) => ({
      ...state,
      niapResults: action.payload,
    }),
    setReportItems: (state, action) => ({
      ...state,
      reportItems: action.payload,
    }),
    setAppIssues: (state, action) => ({
      ...state,
      appIssues: action.payload,
    }),
    setNiapAppIssues: (state, action) => ({
      ...state,
      niapAppIssues: action.payload,
    }),
    setOwaspMasvsAppIssues: (state, action) => ({
      ...state,
      owaspMasvsAppIssues: action.payload,
    }),
    setTraffic: (state, action) => ({
      ...state,
      traffic: action.payload,
    }),
    setParsedAppIssues: (state, action) => ({
      ...state,
      parsedIssues: action.payload,
    }),

    setOwaspMSTGResults: (state, action) => ({
      ...state,
      owaspMSTGResults: action.payload,
    }),

    setSbomResults: (state, action) => ({
      ...state,
      sbomResults: action.payload,
    }),
    resetAppResultsState: () => initialState,

    setAvailableResults: (state, action) => ({
      ...state,
      availableResults: action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  setThreatScore,
  setResults,
  setNiapResults,
  setReportItems,
  setAppIssues,
  setNiapAppIssues,
  setOwaspMasvsAppIssues,
  setTraffic,
  setParsedAppIssues,
  setOwaspMSTGResults,
  setSbomResults,
  resetAppResultsState,
  setAvailableResults,
} = appResultsSlice.actions;

export default appResultsSlice.reducer;
