import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Banner } from './Banner/Banner';

export const GroupNotifications = () => {
  const groupNotifications = useSelector(
    state => state.emmApp.groupNotifications,
  );

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    setNotifications(groupNotifications);
  }, [groupNotifications]);

  const hideNotifications = id => {
    setNotifications(
      notifications.filter(notification => notification.id !== id),
    );
  };

  return (
    <>
      {notifications.length > 0 && (
        <div
          style={{
            maxHeight: '300px',
            overflowY: 'auto',
            overflowX: 'hidden',
            marginBottom: '1rem',
          }}
        >
          {notifications.map(({ id, userGroupNotification }) => {
            const {
              id: notificationId,
              severity,
              title,
              notification,
            } = userGroupNotification;

            return (
              <Banner
                key={notificationId}
                title={title}
                severity={severity}
                message={notification}
                onClose={() => hideNotifications(id)}
                isOpen={notifications.find(
                  notification => notification.id === id,
                )}
                toggle={() => hideNotifications(id)}
              />
            );
          })}
        </div>
      )}
      <hr />
    </>
  );
};
