import React, { Component } from 'react';
import { Col, Container, Row, Table, UncontrolledTooltip } from 'reactstrap';
import Select from 'react-select';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import i18n from '../../localization/i18n';
import Loading from '../Loading';

import Pagination from './Pagination';
import TableFilter from './TableFilter';

class DataTable extends Component {
  constructor(props) {
    super(props);

    // Initialize the data to be displayed in the page
    const { rowsPerPage } = this.props;
    const currentData = props.data.slice(0, rowsPerPage);
    const maxPages = Math.ceil(props.data.length / rowsPerPage);

    // Check the columns provided by the parent and see if any of them are filterable
    const filterableKeys = this.props.columns
      .filter(column => column.filterable)
      .map(column => column.keyName);

    // Save all custom filter functions for columns
    const filterFunctions = {};
    this.props.columns
      .filter(column => column.filterFunc)
      .forEach(column => {
        filterFunctions[column.keyName] = column.filterFunc;
      });

    // Save all custom sort functions for columns
    const sortFunctions = {};
    this.props.columns
      .filter(column => column.sortFunc)
      .forEach(column => {
        sortFunctions[column.keyName] = column.sortFunc;
      });

    this.state = {
      page: 1,
      maxPages,
      rowsPerPage,

      filterableKeys,
      filterFunctions,

      currentData,
      filteredData: props.data,

      currentSortColumn: '',
      sortAscending: true,
      sortFunctions,
    };

    this.getPage = this.getPage.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.changeFilterFunc = this.changeFilterFunc.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
  }

  componentDidMount() {
    let { filter } = this.props;
    if (filter === undefined) {
      filter = '';
    }
    this.changeFilterFunc(filter.toLowerCase());
  }

  componentWillReceiveProps(nextProps) {
    // Set the data to whatever we receive in the props
    // This is used to handle cases where the data being passed
    // through props is modified by the parent component
    this.setState({ filteredData: nextProps.data }, () => {
      // Remove the current filter (if any) to get a clean state
      // after the parent data has been changed
      this.changeFilter({ target: { value: '' } });
    });
  }

  handlePageSizeChange(option) {
    this.setState(
      {
        rowsPerPage: option.value,
      },
      () => {
        this.setState({
          currentData: this.state.filteredData.slice(0, this.state.rowsPerPage),
        });
        this.setState({
          maxPages: Math.ceil(
            this.state.filteredData.length / this.state.rowsPerPage,
          ),
        });
      },
    );
  }

  // Get the new proper page to display from the data
  getPage(pageNum, e) {
    if (e) e.preventDefault();
    // On a button click (when we have the 'e' object) and the page
    // is the same as our current page just return since we're already on that page
    if (e && pageNum === this.state.page) return;

    this.setState({
      currentData: this.state.filteredData.slice(
        (pageNum - 1) * this.state.rowsPerPage,
        pageNum * this.state.rowsPerPage,
      ),

      page: pageNum,
    });
  }

  // Change the table's filter
  changeFilter(e) {
    const filterStr = e.target.value.toLowerCase();

    this.changeFilterFunc(filterStr);
  }

  changeFilterFunc(filterStr) {
    // Filter the data based on the filterStr
    let newFilteredData;
    if (filterStr === '') {
      newFilteredData = this.props.data;
    } else {
      newFilteredData = this.props.data.filter(item => {
        for (const keyName of this.state.filterableKeys) {
          // Check if there's a custom filter function for the column
          /* istanbul ignore else */
          if (keyName in this.state.filterFunctions) {
            return this.state.filterFunctions[keyName](filterStr, item);
          }

          // Check if the item actually has the keyName. There can be
          // the case where the column doesn't have a value (is null)
          /* istanbul ignore else */
          if (
            item[keyName] &&
            item[keyName].toString().toLowerCase().includes(filterStr)
          ) {
            return true;
          }
        }

        return false;
      });
    }

    // Calculate the new max pages value
    let maxPages = Math.ceil(newFilteredData.length / this.state.rowsPerPage);
    if (maxPages === 0) maxPages = 1;

    // Check to see if the current page we're on is past the new max page limit
    // If so, just change the page to the new max pages and then get the pages
    // after the state change
    let newPage;
    if (this.state.page > maxPages) {
      newPage = maxPages;
    } else {
      newPage = this.state.page;
    }

    this.setState(
      {
        filteredData: newFilteredData,
        maxPages,
        page: newPage,
      },
      () => this.getPage(newPage),
    );
  }

  // Change the current column the table is sorted by and take
  // care of actually sorting the table data
  changeSorting(columnToSortBy) {
    // Check to see if we're already sorting by the selected column
    // If so, we'll switch the sort direction
    let newSortAscending = this.state.sortAscending;
    if (columnToSortBy === this.state.currentSortColumn) {
      newSortAscending = !this.state.sortAscending;
    }

    // Sort by the new column
    const newFilteredData = this.state.filteredData;

    this.setState(
      {
        currentSortColumn: columnToSortBy,
        filteredData: newFilteredData.slice().sort((a, b) => {
          // Check if there's a custom filter function for the column
          /* istanbul ignore else */
          if (columnToSortBy in this.state.sortFunctions) {
            return this.state.sortFunctions[columnToSortBy](a, b);
          }

          if (a[columnToSortBy] > b[columnToSortBy]) {
            if (newSortAscending) return 1;
            return -1;
          }
          if (a[columnToSortBy] < b[columnToSortBy]) {
            if (newSortAscending) return -1;
            return 1;
          }
          return 0;
        }),
        sortAscending: newSortAscending,
      },
      () => this.getPage(this.state.page),
    );
  }

  render() {
    const {
      columns,
      tableSize,
      filterEnterEvent,
      filterable,
      rowComponent,
      filter,
      showFilter = true,
      showPageSize,
      hideOverflow,
      isLoading,
    } = this.props;

    const { currentData, page, maxPages, rowsPerPage } = this.state;

    const pageSizeOptions = [
      { value: 10, label: '10' },
      { value: 15, label: '15' },
      { value: 25, label: '25' },
      { value: 50, label: '50' },
      { value: 100, label: '100' },
    ];

    return (
      <Container fluid style={{ padding: 4 }}>
        <Row>
          <Col xs="4">
            {filterable && showFilter && (
              <TableFilter
                className="pull-left mt-3 mb-2"
                changeFilter={this.changeFilter}
                onKeyPress={filterEnterEvent ? this.handleKeyPress : undefined}
                defaultValue={filter}
              />
            )}
          </Col>
          <Col xs="8" className="d-flex" style={{ justifyContent: 'flex-end' }}>
            {showPageSize && (
              <Row className="mr-2 mb-4">
                <Col>
                  Rows per page:
                  <Select
                    id="per-page-select"
                    value={{ value: rowsPerPage, label: rowsPerPage }}
                    options={pageSizeOptions}
                    onChange={this.handlePageSizeChange}
                    clearable={false}
                    isSearchable={false}
                    style={{ width: 100 }}
                  />
                </Col>
              </Row>
            )}
            {/* Don't show pagination if we only have one page */}
            {maxPages !== 1 && (
              <Pagination
                page={page}
                maxPages={maxPages}
                getPage={this.getPage}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {isLoading ? (
              <Loading />
            ) : currentData.length === 0 ? (
              <div style={{ padding: 8 }}>{i18n.t('No data available')}</div>
            ) : (
              <Table size={tableSize} striped={this.props.striped}>
                <thead>
                  <tr>
                    {columns.map(
                      column =>
                        column.label && (
                          <th
                            key={JSON.stringify(column)}
                            onClick={
                              column.sortable
                                ? () => this.changeSorting(column.keyName)
                                : null
                            }
                            className={classNames(
                              column.sortable ? 'pointer' : '',
                              column.className,
                            )}
                            width={column.width ? column.width : null}
                          >
                            <span>
                              {`${column.label} `}

                              {column.tooltip && (
                                <>
                                  <i
                                    id={column.label}
                                    className="fa-solid fa-circle-question"
                                  />
                                  <UncontrolledTooltip
                                    placement="right"
                                    target={column.label}
                                  >
                                    {column.tooltip}
                                  </UncontrolledTooltip>
                                </>
                              )}
                            </span>
                            {/* If the column is being sorted by, display either /*}
                          {/* a ascending or descending icon */}
                            {this.state.currentSortColumn === column.keyName ? (
                              this.state.sortAscending ? (
                                <i className={this.props.sortAscIcon} />
                              ) : (
                                <i className={this.props.sortDescIcon} />
                              )
                            ) : null}
                          </th>
                        ),
                    )}
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((row, rowIndex) => {
                    let key;
                    if (this.props.rowKey) {
                      key = this.props.rowKey(row);
                    } else {
                      key = rowIndex;
                    }

                    // Render the custom row component if provided
                    if (rowComponent) {
                      const CustomRowComponent = rowComponent;
                      return <CustomRowComponent key={rowIndex} row={row} />;
                    }

                    return (
                      <tr
                        key={key}
                        onClick={
                          this.props.onRowClick
                            ? () => this.props.onRowClick(row)
                            : null
                        }
                        className={classNames(this.props.rowClassName, {
                          pointer: this.props.onRowClick,
                        })}
                      >
                        {columns.map(column => {
                          // Create the classNames for the column
                          const cNames = classNames({
                            [column.className]: column.className
                              ? column.className
                              : null,
                            pointer: column.onClick,
                            'dont-break-out': true,
                          });

                          // Check to see if there is a custom component for the column
                          if (column.component) {
                            const Custom = column.component;
                            // Check to see if we should pass the whole row as the value
                            let value;
                            if (!column.keyName) value = row;
                            else value = row[column.keyName];

                            return (
                              <td
                                key={JSON.stringify(column)}
                                onClick={
                                  column.onClick
                                    ? () => column.onClick(row)
                                    : null
                                }
                                className={cNames}
                              >
                                <div
                                  style={
                                    hideOverflow
                                      ? {
                                          height: '100px',
                                          overflow: 'hidden',
                                        }
                                      : {}
                                  }
                                >
                                  {column.columnLink ? (
                                    <Link
                                      to={column.columnLink(row)}
                                      style={{
                                        textDecoration: 'none',
                                        color: 'inherit',
                                      }}
                                    >
                                      <Custom rowData={row} value={value} />
                                    </Link>
                                  ) : (
                                    <Custom rowData={row} value={value} />
                                  )}
                                </div>
                              </td>
                            );
                          }
                          return (
                            <td
                              key={JSON.stringify(column)}
                              onClick={
                                column.onClick
                                  ? () => column.onClick(row)
                                  : null
                              }
                              className={cNames}
                            >
                              <div
                                style={
                                  hideOverflow
                                    ? {
                                        height: '100px',
                                        overflow: 'hidden',
                                      }
                                    : {}
                                }
                              >
                                {column.columnLink ? (
                                  <Link
                                    to={column.columnLink(row)}
                                    style={{
                                      textDecoration: 'none',
                                      color: 'inherit',
                                    }}
                                  >
                                    {row[column.keyName]}
                                  </Link>
                                ) : (
                                  row[column.keyName]
                                )}
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

DataTable.defaultProps = {
  tableSize: 'md',
  filterEnterEvent: false,
  sortAscIcon: 'fa-solid fa-arrow-up-long',
  sortDescIcon: 'fa-solid fa-arrow-down-long',
  filterable: true,
  rowsPerPage: 10,
  showPageSize: false,
};

export default DataTable;
