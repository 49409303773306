import React from 'react';
import { Pagination } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QPagination as MuiPagination } from '@kw/quokka-ui';

const LDPagination = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiPagination {...props} />
  ) : (
    <Pagination {...props}>{children}</Pagination>
  );
};
export const QPagination = withLDConsumer()(LDPagination);
