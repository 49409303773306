import React, { Component } from 'react';
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import classnames from 'classnames';

const EntryDataRow = ({ name, value }) => (
  <Row>
    <Col
      xs="2"
      className="text-right"
      style={{ fontWeight: '600' }}
    >{`${name}: `}</Col>
    <Col className="dont-break-out">{value}</Col>
  </Row>
);

const Headers = ({ entry }) => (
  <div>
    {entry.request.headers && (
      <div>
        <Row style={{ borderBottom: '1px solid #eaeaea' }}>
          <Col>
            <strong>Request Headers</strong>
          </Col>
        </Row>
        <Row>
          <Col>
            {entry.request.headers.map(header => (
              <EntryDataRow
                key={header.name}
                name={header.name}
                value={header.value}
              />
            ))}
          </Col>
        </Row>
      </div>
    )}
    {entry.response.headers && (
      <div>
        <Row style={{ borderBottom: '1px solid #eaeaea' }}>
          <Col>
            <strong>Response Headers</strong>
          </Col>
        </Row>
        <Row>
          <Col>
            {entry.response.headers.map(header => (
              <EntryDataRow
                key={header.name}
                name={header.name}
                value={header.value}
              />
            ))}
          </Col>
        </Row>
      </div>
    )}
  </div>
);

const Params = ({ params }) => (
  <Row>
    <Col>
      {params.map(param => (
        <EntryDataRow key={param.name} name={param.name} value={param.value} />
      ))}
    </Col>
  </Row>
);

const Post = ({ postData }) => (
  <Row>
    <Col>
      {/* Mime Type */}
      <EntryDataRow name="Mime Type" value={postData.mimeType} />
      {/* POST text */}
      {postData.text && <EntryDataRow name="Text" value={postData.text} />}
      {/* POST JSON params */}
      {postData.params &&
        postData.params.map(param => (
          <Row key={param.name}>
            <Col
              xs="2"
              className="text-right"
              style={{ fontWeight: '600' }}
            >{`${param.name}: `}</Col>
            <Col className="dont-break-out">
              <Row>
                <Col>
                  <div>{param.value}</div>
                  {param.fileName && <div>Filename: {param.fileName}</div>}
                  {param.contentType && (
                    <div>Content Type: {param.contentType}</div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
    </Col>
  </Row>
);

const Response = ({ response }) => (
  <div>
    {/* Response size */}
    <EntryDataRow name="Size" value={response.content.size} />
    {/* Mime Type */}
    <EntryDataRow name="Mime Type" value={response.content.mimeType} />
    {/* Compression */}
    {response.content.compression && (
      <EntryDataRow name="Compression" value={response.content.compression} />
    )}
    {/* Encoding */}
    {response.content.encoding && (
      <EntryDataRow name="Encoding" value={response.content.encoding} />
    )}
    {/* Response text */}
    {response.content.text && (
      <EntryDataRow name="Text" value={response.content.text} />
    )}
  </div>
);

class EntryDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entry: props.entry,
      activeTab: '1',
    };

    this.setActiveTab = this.setActiveTab.bind(this);
  }

  setActiveTab(tab) {
    this.setState({ activeTab: tab });
  }

  render() {
    const { entry, activeTab } = this.state;
    const { index } = this.props;
    return (
      <div key="entry-details" className="animated fadeIn">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames('entry-details-tab', {
                'active-entry-details-tab': activeTab === '1',
              })}
              onClick={() => this.setActiveTab('1')}
            >
              Headers
            </NavLink>
          </NavItem>
          {entry.request.queryString.length > 0 && (
            <NavItem>
              <NavLink
                className={classnames('entry-details-tab', {
                  'active-entry-details-tab': activeTab === '2',
                })}
                onClick={() => this.setActiveTab('2')}
              >
                Params
              </NavLink>
            </NavItem>
          )}
          {entry.request.postData && (
            <NavItem>
              <NavLink
                className={classnames('entry-details-tab', {
                  'active-entry-details-tab': activeTab === '3',
                })}
                onClick={() => this.setActiveTab('3')}
              >
                Post
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              className={classnames('entry-details-tab', {
                'active-entry-details-tab': activeTab === '4',
              })}
              onClick={() => this.setActiveTab('4')}
            >
              Response
            </NavLink>
          </NavItem>
        </Nav>

        {/* Entry details tabs */}
        <TabContent
          activeTab={activeTab}
          style={{ backgroundColor: index % 2 !== 0 ? '#eaeaea' : 'inherit' }}
        >
          {/* Headers */}
          <TabPane tabId="1">
            <Headers entry={entry} />
          </TabPane>
          {/* Query string params */}
          {entry.request.queryString.length > 0 && (
            <TabPane tabId="2">
              <Params params={entry.request.queryString} />
            </TabPane>
          )}
          {/* POST data */}
          {entry.request.postData && (
            <TabPane tabId="3">
              <Post postData={entry.request.postData} />
            </TabPane>
          )}
          <TabPane tabId="4">
            <Response response={entry.response} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default EntryDetails;
