import React from 'react';
import { QTypography as MuiTypography } from '@kw/quokka-ui';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

const LDTypography = ({ children, ...props }) => {
  const { mastV2 } = useFlags();

  return mastV2 ? (
    <MuiTypography {...props}>{children}</MuiTypography>
  ) : (
    <>{children}</>
  );
};

export const QTypography = withLDConsumer()(LDTypography);
