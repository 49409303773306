import React from 'react';

import SSLPinning from '../../SSLPinning';

import { Issues } from './Issues';

export const V5_4 = ({ evidence }) => (
  <>
    <Issues evidence={evidence} issues={['NO_SSL_PINNING']} />
    <hr />
    <SSLPinning />
  </>
);
