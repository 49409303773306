import React from 'react';
import { Label } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

const LDLabel = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? <>{children}</> : <Label {...props}>{children}</Label>;
};

export const QLabel = withLDConsumer()(LDLabel);
