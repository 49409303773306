import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Grid from '@mui/material/Grid';

import { setResults } from '../../store/slices/appResultsSlice';
import { toastrHelper } from '../../logic/toastrHelper';
import { QContainer } from '../../components/Q-Components/QContainer';
import { AppSummary } from '../../components/Reports/AppSummary';
import { setPageTitle } from '../../store/slices/emmAppSlice';
import { QTypography } from '../../components/Q-Components/QTypography';
import { ProfileCategoriesBarChart } from '../../components/Reports/Malicious/ProfileCategoriesBarChart';
import { MaliciousScore } from '../../components/Reports/Malicious/MaliciousScore';
import { QRow } from '../../components/Q-Components/QRow';
import { QCol } from '../../components/Q-Components/QCol';
import { QCard } from '../../components/Q-Components/QCard';
import { QCardHeader } from '../../components/Q-Components/QCardHeader';
import { QDivider } from '../../components/Q-Components/QDivider';
import { QCardBody } from '../../components/Q-Components/QCardBody';
import { SarifToolBar } from '../../components/SarifToolBar';
import { QTable } from '../../components/Q-Components/QTable';
import { QTableBody } from '../../components/Q-Components/QTableBody';
import QReportFindingCard from '../../components/Reports/SimpleReport/QReportFindingCard';
import ReportFindingCard from '../../components/Reports/SimpleReport/ReportFindingCard';
import {
  getResults,
  getRules,
  aggregateResultsFromRuleId,
  getMaliciousThreatLevel,
  getMaliciousThreatTypes,
} from '../../logic/dopplerUtil';
import { IorsChartCard } from '../../components/Sarif/IorsChartCard';
import { ThreatTypeSpiderChart } from '../../components/Reports/Malicious/ThreatTypeSpiderChart';
import { PermissionsChartCard } from '../../components/Sarif/PermissionsChartCard';
import Loading from '../../components/Loading';

export function MaliciousReport() {
  const [jsonResults, setJsonResults] = useState();
  const [app, setApp] = useState();
  const [maliciousResult, setMaliciousResult] = useState({});
  const [maliciousBehaviors, setMaliciousBehaviors] = useState([]);
  const [maliciousPermissions, setMaliciousPermissions] = useState([]);
  const [filteredMaliciousBehaviors, setFilteredMaliciousBehaviors] = useState(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);

  const { mastV2, maliciousReportAndroid, maliciousReportIos, sarifReport } =
    useFlags();

  const dispatch = useDispatch();

  const { farmAppUuid, analyzedAppId } = useParams();

  const fetchJsonData = async () => {
    try {
      const { data } = await axios.get(`api/results/json?uuid=${farmAppUuid}`);
      dispatch(setResults(data));
      setJsonResults(data);
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error loading Json results data',
        'Error',
        mastV2,
      );
    }
  };

  const fetchSarifData = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `/api/results/sarif?uuid=${farmAppUuid}`,
      );

      const rules = getRules(data);
      const results = getResults(data);
      const aggregatedResults = aggregateResultsFromRuleId(rules, results);
      const maliciousResult = aggregatedResults.find(result =>
        result.ruleId.startsWith('malware/'),
      );

      const filteredDrivers = aggregatedResults.filter(
        result =>
          result.driverName === 'permoid' ||
          result.driverName === 'pulsar' ||
          result.driverName === 'pulsar-ios',
      );
      const maliciousBehaviors = filteredDrivers.filter(
        result => result.ruleId && result.ruleId.startsWith('profile'),
      );

      const maliciousPermissions = filteredDrivers.filter(
        result => result.ruleId && /-perm$/.test(result.ruleId),
      );

      setMaliciousResult(maliciousResult);
      setMaliciousBehaviors(maliciousBehaviors);
      setMaliciousPermissions(maliciousPermissions);
    } catch (err) {
      toastrHelper.showErrorToast('Error loading SARIF data', 'Error', mastV2);
    } finally {
      setIsLoading(false);
    }
  };

  const getApp = async () => {
    try {
      if (analyzedAppId) {
        const { data } = await axios.get(`/emm/app/${analyzedAppId}`);
        setApp(data);
      }
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error loading analyzed app data',
        'Error',
        mastV2,
      );
    }
  };

  useEffect(() => {
    setFilteredMaliciousBehaviors(maliciousBehaviors);
  }, [maliciousBehaviors]);

  useEffect(() => {
    fetchJsonData();
    fetchSarifData();
    getApp();
    dispatch(setPageTitle('Malicious Report'));
  }, []);

  const maliciousScore = getMaliciousThreatLevel(maliciousResult);
  const maliciousThreatTypes = getMaliciousThreatTypes(maliciousResult);

  const platform = app?.farm_app.platform;
  const maliciousReportAndroidVisibility =
    maliciousReportAndroid && platform === 'android';
  const maliciousReportIosVisibility = maliciousReportIos && platform === 'ios';
  const maliciousReportVisibility =
    sarifReport &&
    (maliciousReportIosVisibility || maliciousReportAndroidVisibility);

  return (
    <QContainer
      fluid
      sx={{
        maxWidth: '100% !important',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 2,
      }}
    >
      {maliciousReportVisibility ? (
        isLoading ? (
          <Loading />
        ) : (
          app &&
          jsonResults && (
            <>
              <QRow>
                <QCol flex={10} xs={10}>
                  <AppSummary app={app} />
                </QCol>
                <QCol xs={2}>
                  <MaliciousScore score={maliciousScore} />
                </QCol>
              </QRow>
              {/* I can not get the bar chart and the doughnut chart to render with the proper widths and heights in a QRow */}
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={7}>
                  <ThreatTypeSpiderChart results={maliciousThreatTypes} />
                </Grid>
                <Grid item xs={5}>
                  <PermissionsChartCard permissions={maliciousPermissions} />
                </Grid>
                <Grid item xs={7}>
                  <ProfileCategoriesBarChart
                    results={filteredMaliciousBehaviors}
                    title="Categories"
                    options={{ maintainAspectRatio: false }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <IorsChartCard
                    results={filteredMaliciousBehaviors}
                    title="Behaviors"
                  />
                </Grid>
              </Grid>
              {mastV2 ? (
                <QCard
                  style={{
                    padding: '0',
                    marginBottom: '18px',
                    boxShadow: 'none',
                    borderRadius: '0',
                  }}
                >
                  <QCardHeader disableTypography title="Behaviors" />
                  <QDivider />
                  <QCardBody sx={{ padding: '0px' }}>
                    <div style={{ padding: '16px' }}>
                      <SarifToolBar
                        aggregatedResults={maliciousBehaviors}
                        setFilteredResults={setFilteredMaliciousBehaviors}
                        isMaliciousReport
                      />
                    </div>
                    <QDivider sx={{ borderColor: '#E0E0E0' }} />
                    <QTable>
                      <QTableBody>
                        {filteredMaliciousBehaviors.map(result => (
                          <QReportFindingCard issue={result} v2 />
                        ))}
                      </QTableBody>
                    </QTable>
                  </QCardBody>
                </QCard>
              ) : (
                <>
                  <SarifToolBar
                    aggregatedResults={maliciousBehaviors}
                    setFilteredResults={setFilteredMaliciousBehaviors}
                    isMaliciousReport
                  />

                  {filteredMaliciousBehaviors.map(result => (
                    <ReportFindingCard issue={result} v2 />
                  ))}
                </>
              )}
            </>
          )
        )
      ) : (
        <QTypography>No permissions to view this page</QTypography>
      )}
    </QContainer>
  );
}
