import React from 'react';
import ReactDOMServer from 'react-dom/server';
import toastr from 'toastr';
import { QAlert } from '@kw/quokka-ui';

// Custom error toast that requires the user's action to be dismissed.
export const toastrHelper = {
  showWarningToast(message, title, mastV2 = false) {
    if (!mastV2) {
      toastr.warning(message, title);
      return;
    }

    const warningAlertComponent = (
      <QAlert
        variant="outlined"
        severity="warning"
        title={title}
        onClose={() => {}}
      >
        {message}
      </QAlert>
    );

    const warningAlertHtmlString = ReactDOMServer.renderToString(
      warningAlertComponent,
    );
    toastr.warning(warningAlertHtmlString, null, {
      closeButton: false,
      iconClass: 'toast-warning-v2',
    });
  },
  showErrorToast(message, title, mastV2 = false) {
    if (!mastV2) {
      toastr.error(message, title, { timeOut: 0, extendedTimeOut: 0 });
      return;
    }

    const errorAlertComponent = (
      <QAlert
        variant="outlined"
        severity="error"
        title={title}
        onClose={() => {}}
      >
        {message}
      </QAlert>
    );

    const errorAlertHtmlString =
      ReactDOMServer.renderToString(errorAlertComponent);
    toastr.error(errorAlertHtmlString, null, {
      timeOut: 0,
      extendedTimeOut: 0,
      closeButton: false,
      iconClass: 'toast-error-v2',
    });
  },
  showSuccessToast(message, title, mastV2 = false) {
    if (!mastV2) {
      toastr.success(message, title);
      return;
    }

    const successAlertComponent = (
      <QAlert
        variant="outlined"
        severity="success"
        title={title}
        onClose={() => {}}
      >
        {message}
      </QAlert>
    );

    const successAlertHtmlString = ReactDOMServer.renderToString(
      successAlertComponent,
    );
    toastr.success(successAlertHtmlString, null, {
      closeButton: false,
      iconClass: 'toast-success-v2',
    });
  },
  showInfoToast(message, title, mastV2 = false) {
    if (!mastV2) {
      toastr.info(message, title);
      return;
    }

    const infoAlertComponent = (
      <QAlert
        variant="outlined"
        severity="info"
        title={title}
        onClose={() => {}}
      >
        {message}
      </QAlert>
    );

    const infoAlertHtmlString =
      ReactDOMServer.renderToString(infoAlertComponent);
    toastr.info(infoAlertHtmlString, null, {
      closeButton: false,
      iconClass: 'toast-info-v2',
    });
  },
};
