import React from 'react';

import { QToggleButtonGroup } from './Q-Components/QButtonGroup';
import { QToggleButton } from './Q-Components/QToggleButton';

const createSwitches = (leftText, middleText, rightText) => [
  {
    text: leftText,
    value: true,
    style: {
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
    },
    color: 'success',
  },
  {
    text: middleText,
    value: null,
    color: 'secondary',
  },
  {
    text: rightText,
    value: false,
    style: {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    color: 'danger',
  },
];
/**
 * Triple switch, has true and false for yes and no like a normal toggle switch.
 * Has additional 'null' value for neither selection
 *
 * @param {object} props
 * @param {true | false | null} props.selected
 * @param {string} props.middleButtonText
 */
export const SwitchTriple = ({
  selectedValue,
  label,
  onChange,
  middleButtonText = 'N/A',
}) => {
  const switches = createSwitches('Yes', middleButtonText, 'No');

  return (
    <div className="d-flex justify-content-between m-1">
      <span className="d-block text-center ml-1 pt-1">
        <strong>{label}</strong>
      </span>

      <QToggleButtonGroup size="sm" value={`${selectedValue}`}>
        {switches.map(({ text, value, color, style }, index) => {
          const active = selectedValue === value;
          return (
            <QToggleButton
              key={index}
              color={color}
              style={style}
              value={`${value}`}
              onClick={() => onChange(value)}
              sx={!active ? { border: 'none' } : {}}
              className={active ? 'active' : 'disabled'}
              selectedColor="#487F87"
            >
              {text}
            </QToggleButton>
          );
        })}
      </QToggleButtonGroup>
    </div>
  );
};
