import React from 'react';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';
import _ from 'lodash';

import i18n from '../../../localization/i18n';

const CertInfoTable = ({ results }) => {
  const certificate = _.get(
    results,
    'analysis.analysis_details.metadata_analysis',
  );

  return (
    <div>
      {certificate ? (
        <Table size="sm">
          <tbody>
            <tr>
              <td>
                <p className="cert-info-title">{i18n.t('App Name')}</p>
                <span className="cert-info-value">
                  {certificate.appname ?? i18n.t('Not specified')}
                </span>
              </td>
              <td>
                <p className="cert-info-title">{i18n.t('Compiler')}</p>
                <span className="cert-info-value">
                  {certificate.Compiler ?? i18n.t('Not specified')}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <p className="cert-info-title">
                  {i18n.t('Minimum OS Version')}
                </p>
                <span className="cert-info-value">
                  {certificate.MinimumOSVersion ?? i18n.t('Not specified')}
                </span>
              </td>
              <td>
                <p className="cert-info-title">{i18n.t('Platform')}</p>
                <span className="cert-info-value">
                  {certificate.PlatformName ?? i18n.t('Not specified')}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <p className="cert-info-title">{i18n.t('Platform Version')}</p>
                <span className="cert-info-value">
                  {certificate.PlatformVersion ?? i18n.t('Not specified')}
                </span>
              </td>
              <td>
                <p className="cert-info-title">
                  {i18n.t('Provisioning Profile App ID')}
                </p>
                <span className="cert-info-value">
                  {certificate.provisioning_profile_app_id ??
                    i18n.t('Not specified')}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <p className="cert-info-title">{i18n.t('Developer')}</p>
                <span className="cert-info-value">
                  {certificate.developer ?? i18n.t('Not specified')}
                </span>
              </td>
              <td className="dont-break-out">
                <p className="cert-info-title">{i18n.t('Team ID')}</p>
                <span className="cert-info-value">
                  {certificate.team_id ?? i18n.t('Not specified')}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <p className="cert-info-title">{i18n.t('Authority')}</p>
                <span className="cert-info-value">
                  {certificate.authority ?? i18n.t('Not specified')}
                </span>
              </td>
              <td className="dont-break-out">
                <p className="cert-info-title">
                  {i18n.t('Application Identifier')}
                </p>
                <span className="cert-info-value">
                  {certificate.application_identifier ??
                    i18n.t('Not specified')}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <p className="cert-info-title">{i18n.t('Creation Date')}</p>
                <span className="cert-info-value">
                  {certificate.creation_date ?? i18n.t('Not specified')}
                </span>
              </td>
              <td>
                <p className="cert-info-title">{i18n.t('Expiration Date')}</p>
                <span className="cert-info-value">
                  {certificate.expiration_date ?? i18n.t('Not specified')}
                </span>
              </td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <div>No certificate info found</div>
      )}
    </div>
  );
};

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(CertInfoTable);
