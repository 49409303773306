import React, { Component } from 'react';
import { connect } from 'react-redux';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

class ConstantsDatatable extends Component {
  render() {
    const { results, typeFilter = null, valueFilter = null } = this.props;
    let { constants } = results.analysis.analysis_details.metadata_analysis;

    if (typeFilter) {
      constants = constants.filter(entry => entry.type.includes(typeFilter));
    }
    if (valueFilter) {
      constants = constants.filter(entry => entry.value.includes(valueFilter));
    }

    return (
      <div>
        {/* Metadata constants */}
        <DataTable
          data={constants}
          columns={[
            {
              label: i18n.t('Type'),
              keyName: 'type',
              filterable: true,
            },
            {
              label: i18n.t('Value'),
              keyName: 'value',
              filterable: true,
            },
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(ConstantsDatatable);
