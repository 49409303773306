import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import _ from 'lodash';

import analyzedAppsReducer from './slices/analyzedAppsSlice';
import summarizedAppsReducer from './slices/summarizedAppsSlice';
import emmAppReducer from './slices/emmAppSlice';
import kaiSliceReducer from './slices/kaiSlice';
import appResultsReducer from './slices/appResultsSlice';
import appSubmitReducer from './slices/appSubmitSlice';
import threatProfileReducer from './slices/threatProfileSlice';
import analyzedAppsCacheReducer from './slices/cache/analyzedAppsCacheSlice';
import firmwareCacheReducer from './slices/cache/firmwareCacheSlice';
import analyticsCacheReducer from './slices/cache/analyticsCacheSlice';
import analyzedFirmwareReducer from './slices/analyzedFirmwareSlice';
import { loadState, saveState } from './localStorage';

const emmApp = emmAppReducer;
const persistedState = loadState();

// Manually add in default values for pieces of the store that we've added. This is to prevent
// an error when users update the app, but load state from local storage that does not have
// the state fields that we've added. If that happens, there will be an error when that
// state value is attempted to be read in any part of the app.
if (persistedState && !persistedState.emmApp?.portalConfig) {
  if (!persistedState.emmApp) {
    persistedState.emmApp = {};
  }
  persistedState.emmApp.portalConfig = { lang: { value: 'us', label: 'EN' } };
}
if (persistedState && !persistedState.emmApp.columnSettings) {
  persistedState.emmApp.columnSettings = {
    dateCol: true,
    scoreCol: true,
    findingsCol: true,
    subGroupsCol: true,
    analysisTimeCol: false,
  };
}
if (persistedState && !persistedState.emmApp.groupAdminSubGroups) {
  persistedState.emmApp.groupAdminSubGroups = [];
}
if (persistedState && !persistedState.emmApp.license) {
  persistedState.emmApp.license = {};
}

console.log('Loading persisted state:', persistedState);

const cacheReducer = combineReducers({
  analyzedApps: analyzedAppsCacheReducer,
  analyzedFirmware: firmwareCacheReducer,
  analytics: analyticsCacheReducer,
  firmware: firmwareCacheReducer,
});
const reducer = combineReducers({
  analyzedApps: analyzedAppsReducer,
  analyzedFirmware: analyzedFirmwareReducer,
  emmApp,
  kai: kaiSliceReducer,
  appResults: appResultsReducer,
  appSubmit: appSubmitReducer,
  threatProfile: threatProfileReducer,
  summarizedApps: summarizedAppsReducer,
  cache: cacheReducer,
});

/* eslint-disable no-underscore-dangle */
export const store = configureStore({
  reducer,
  persistedState,
});

export { reducer };

/* eslint-enable */
store.subscribe(
  _.throttle(() => {
    saveState({
      emmApp: store.getState().emmApp,
    });
  }, 1000),
);
