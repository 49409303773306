import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { QActionSelect } from '../Q-Components/QActionSelect';

export const FirmwareAppActions = ({
  app,
  toggleSarifViewer,
  getFirmwareAppResultUrlBuilder,
}) => {
  const { mastV2 } = useFlags();

  return mastV2 ? (
    <QActionSelect
      label="View Reports"
      width="140px"
      actionOptions={[
        {
          icon: <i className="fa-solid fa-file-code" />,
          value: 'firmware-app-sarif',
          label: 'SARIF',
          onClick: () => toggleSarifViewer(app),
        },
        {
          value: 'firmware-mast-report',
          label: 'Quokka',
          icon: <i className="fa-solid fa-file-pdf fa-lg" />,
          onClick: () =>
            window.open(
              getFirmwareAppResultUrlBuilder({
                appId: app.id,
                type: 'pdf',
              }),
              '_blank',
            ),
        },
        {
          value: 'firmware-app-json',
          label: 'JSON',
          icon: (
            <i
              className="fa-regular fa-circle-info fa-lg"
              style={{ color: '#1976d2' }}
            />
          ),
          onClick: () =>
            window.open(
              getFirmwareAppResultUrlBuilder({
                appId: app.id,
                type: 'json',
              }),
              '_blank',
            ),
        },
        {
          value: 'firmware-app-manifest',
          label: 'App Manifest',
          icon: <i className="fa-regular fa-clipboard-list primary-icon" />,
          onClick: () =>
            window.open(
              getFirmwareAppResultUrlBuilder({
                appId: app.id,
                type: 'manifest',
              }),
              '_blank',
            ),
        },
      ]}
    />
  ) : (
    <UncontrolledButtonDropdown title="Actions">
      <DropdownToggle caret className="toggledown-primary">
        View Reports
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Reports {mastV2 && 'v2'}</DropdownItem>
        <DropdownItem
          id="firmware-app-sarif"
          title="Firmware App SARIF"
          onClick={() => toggleSarifViewer(app)}
        >
          <i className="fa-solid fa-file-code" />
          <span>SARIF</span>
        </DropdownItem>
        <DropdownItem
          id="firmware-mast-report"
          title="Firmware App MAST Report"
          href={getFirmwareAppResultUrlBuilder({
            appId: app.id,
            type: 'pdf',
          })}
          target="_blank"
        >
          <i className="fa-solid fa-file-pdf fa-lg" />
          <span>Quokka</span>
        </DropdownItem>
        <DropdownItem
          id="firmware-app-json"
          title="Firmware App JSON"
          href={getFirmwareAppResultUrlBuilder({
            appId: app.id,
            type: 'json',
          })}
          target="_blank"
        >
          <i
            className="fa-regular fa-circle-info fa-lg"
            style={{ color: '#1976d2' }}
          />
          <span>JSON</span>
        </DropdownItem>
        <DropdownItem
          id="firmware-app-manifest"
          title="Firmware App Manifest"
          href={getFirmwareAppResultUrlBuilder({
            appId: app.id,
            type: 'manifest',
          })}
          target="_blank"
        >
          <i className="fa-regular fa-clipboard-list primary-icon" />
          <span>App Manifest</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};
