import React from 'react';
import { Pie } from 'react-chartjs-2';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import i18n from '../../localization/i18n';
import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QDoughnut } from '../Q-Components/QDoughnut';

const LDAppsByPlatform = ({
  isLoading,
  source = null,
  header,
  android,
  ios,
}) => {
  const { mastV2 } = useFlags();

  const androidColor = mastV2 ? '#AED581' : '#388e3c';
  const iosColor = mastV2 ? '#64B5F6' : '#1565c0';

  const chartData = {
    datasets: [
      {
        data: [android, ios],
        backgroundColor: [androidColor, iosColor],
      },
    ],
    labels: ['Android', 'iOS'],
  };

  let description = `Total number of ${source} apps that have been analyzed`;
  if (source === null) {
    description = `Total number of apps that have been analyzed`;
  }

  return mastV2 ? (
    <QCard className="card-accent-secondary">
      <QCardHeader
        title={i18n.t(header)}
        action={
          <span>
            {isLoading && <i className="fa-solid fa-spinner fa-spin" />}
          </span>
        }
      />
      <QCardBody>
        <QDoughnut
          data={chartData}
          height={300}
          options={{
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: description,
                position: 'bottom',
                font: {
                  weight: 'normal',
                  style: 'italic',
                },
              },
            },
          }}
        />
      </QCardBody>
    </QCard>
  ) : (
    <QCard className="card-accent-secondary">
      <QCardHeader>
        <div className="d-flex justify-between">
          <strong>{i18n.t(header)}</strong>
          <span>
            {isLoading && <i className="fa-solid fa-spinner fa-spin" />}
          </span>
        </div>
      </QCardHeader>
      <QCardBody>
        <Pie
          data={chartData}
          height={300}
          options={{
            maintainAspectRatio: false,
            plugins: {
              datalabels: {
                display: context =>
                  context.dataset.data[context.dataIndex] >= 1,
                color: 'white',
              },
              title: {
                display: true,
                text: description,
                position: 'bottom',
                font: {
                  weight: 'normal',
                  style: 'italic',
                },
              },
            },
          }}
        />
      </QCardBody>
    </QCard>
  );
};

const AppsByPlatform = withLDConsumer()(LDAppsByPlatform);

export default AppsByPlatform;
