import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  CardHeader,
  Button,
} from 'reactstrap';

import DataTable from '../../DataTable/DataTable';
import util from '../../../logic/util';
import SbomVulnerabilitiesDataTable from '../Android/SbomVulnerabilitiesDataTable';

export const AndroidSBOMTable = ({ farmAppId }) => {
  const sbomResults = useSelector(
    ({ appResults }) => appResults.sbomResults ?? {},
  );

  const { components = [] } = sbomResults;

  const [data, setData] = useState(components);

  const getVulnerabilitiesDataOnly = (checked, componentsData) =>
    checked
      ? setData(
          componentsData.filter(({ properties = [] }) => {
            const { vulnerabilities = [] } = Object.assign({}, ...properties);

            return vulnerabilities.length > 0;
          }),
        )
      : setData(components);

  return (
    <>
      <Card className="card-accent-navy">
        <CardHeader>
          <div>
            <span>SBOM</span>
          </div>
        </CardHeader>
        <CardBody>
          <div className="text-right">
            <Button
              color="primary"
              outline
              onClick={() => util.getResults({ uuid: farmAppId }, 'sbom')}
            >
              <i className="fa-solid fa-download" /> Download SBOM Report
            </Button>
          </div>
          <div className="d-flex justify-content-end pt-5">
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  onChange={e =>
                    getVulnerabilitiesDataOnly(e.target.checked, data)
                  }
                />{' '}
                Has Vulnerabilities
              </Label>
            </FormGroup>
          </div>
          <DataTable
            data={data}
            columns={[
              {
                label: 'Name',
                keyName: 'name',
                filterable: true,
              },
              {
                label: 'Package URL',
                keyName: 'purl',
                filterable: true,
              },
              {
                label: 'Path',
                keyName: 'properties',
                component: ({ value }) => {
                  if (!value) {
                    return <p />;
                  }

                  const { path = [] } = Object.assign({}, ...value);

                  return <p>{path.join(', ')}</p>;
                },
              },
              {
                label: 'Vulnerabilities',
                keyName: 'properties',
                component: ({ value = [] }) => {
                  if (!value) {
                    return <p />;
                  }

                  const { vulnerabilities = [] } = Object.assign({}, ...value);

                  return vulnerabilities.map(vulnerability => (
                    <>
                      <a
                        className="mr-2"
                        href={`https://nvd.nist.gov/vuln/detail/${vulnerability}`}
                        target="_blank"
                      >
                        {vulnerability}
                      </a>
                    </>
                  ));
                },
              },
              {
                label: 'Confidence',
                keyName: 'properties',
                tooltip:
                  'Quality of the match on a particular library. Scored from 0-100. Higher scores indicate higher-quality matches, and un-obfuscated apps will produce more confident scores than obfuscated apps.',
                component: ({ value = [] }) => {
                  if (!value) {
                    return <p />;
                  }

                  const { confidence } = Object.assign({}, ...value);

                  return <p>{confidence}</p>;
                },
                width: '130px',
              },
            ]}
          />
        </CardBody>
      </Card>

      <Card className="card-accent-navy">
        <CardHeader>Vulnerabilities Identified</CardHeader>
        <CardBody>
          <SbomVulnerabilitiesDataTable />
        </CardBody>
      </Card>
    </>
  );
};
