import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';

export default function VulnerableOSVersion() {
  const minSDKVersion = useSelector(
    ({ appResults }) =>
      appResults.results.analysis.analysis_details.metadata_analysis.sdk_info
        .min_sdk,
  );
  return (
    <Card className="card-accent-danger">
      <CardHeader>Vulnerable Minimum OS Version</CardHeader>
      <CardBody>
        <i>
          An elevation of privilege vulnerability in the Android system which
          may affect any SDK Version lower than 24
        </i>
        <p>
          <a
            href="https://cve.mitre.org/cgi-bin/cvename.cgi?name=CVE-2017-13156"
            target="_blank"
          >
            CVE-2017-13156
          </a>
        </p>
        <hr />
        {parseFloat(minSDKVersion) < 24 ? (
          <span>
            Current Minimum SDK Version Supported:{' '}
            <strong>{minSDKVersion}</strong>
          </span>
        ) : (
          <p>No Issue Found</p>
        )}
      </CardBody>
    </Card>
  );
}
