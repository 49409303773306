import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QButton } from '../components/Q-Components/QButton';
import QTabsView from '../components/Q-Components/QTabsView';
import { QContainer } from '../components/Q-Components/QContainer';
import { QRow } from '../components/Q-Components/QRow';
import { QTypography } from '../components/Q-Components/QTypography';
import Loading from '../components/Loading';
import ConfirmationModal from '../components/Modals/ConfirmationModal';
import ThreatScoreItems from '../components/CustomizeThreat/ThreatScoreItems';
import i18n from '../localization/i18n';
import { toastrHelper } from '../logic/toastrHelper';
import { setPageTitle } from '../store/slices/emmAppSlice';

export default function CustomizeThreat() {
  const userAccess = useSelector(state => state.emmApp.userAccess);
  const dispatch = useDispatch();
  const { mastV2 } = useFlags();

  const [isLoading, setIsLoading] = useState(true);
  const [threatItems, setThreatItems] = useState();
  const [showRegenerateConfirmModal, setShowRegenerateConfirmModal] =
    useState(false);
  const [showResetConfirmModal, setShowResetConfirmModal] = useState(false);

  // Get the current configuration for the threat score of the user
  const getThreatScoreInfo = async () => {
    const infoResponse = (await axios.get('emm/threat-score-info')).data;

    setThreatItems(infoResponse.threatItems);
    setIsLoading(false);
  };

  useEffect(() => {
    getThreatScoreInfo();
    dispatch(setPageTitle('Customize Threat Score'));
  }, []);

  // Toggle if a threat item is enabled or not (if it'll be used in the score calculation)
  const toggleThreatItemEnabled = async (
    threatItemId,
    itemEnabled,
    itemRisk,
  ) => {
    console.log('Toggling threat item:', threatItemId);
    try {
      const response = await axios.patch(
        `emm/customize-threat/custom-report-items/${threatItemId}`,
        { enabled: !itemEnabled, riskLevel: itemRisk },
      );

      if (response.status === 200) {
        setThreatItems(
          threatItems.map(threatItem => {
            if (threatItem.id === threatItemId) {
              threatItem.enabled = !itemEnabled;
              return threatItem;
            }
            return threatItem;
          }),
        );

        toastrHelper.showSuccessToast(
          `${i18n.t('Successfully toggled threat item to')}: ${!itemEnabled}`,
          i18n.t('Success'),
          mastV2,
        );
        console.log('Toggle message: ', response.data.msg);
      } else {
        toastrHelper.showErrorToast(
          i18n.t('Error toggling threat item'),
          i18n.t('Error'),
          mastV2,
        );
        console.log('Error toggling threat item');
      }
    } catch (err) {
      toastrHelper.showErrorToast(
        i18n.t('Error toggling threat item'),
        i18n.t('Error'),
        mastV2,
      );
      console.log('Error toggling threat item:', err);
    }
  };

  // Change the weight of the threat score item to either 'low', 'medium', or 'high'
  const changeThreatItemWeight = async (
    threatItemId,
    itemEnabled,
    newWeight,
  ) => {
    console.log(
      'Changing threat item:',
      threatItemId,
      'to have risk:',
      newWeight,
    );
    try {
      const response = await axios.patch(
        `emm/customize-threat/custom-report-items/${threatItemId}`,
        { enabled: itemEnabled, riskLevel: newWeight },
      );

      if (response.status === 200) {
        setThreatItems(
          threatItems.map(threatItem => {
            if (threatItem.id === threatItemId) {
              threatItem.risk = newWeight;
              return threatItem;
            }
            return threatItem;
          }),
        );

        toastrHelper.showSuccessToast(
          `${i18n.t(
            'Successfully updated Threat item risk level to',
          )}: ${newWeight}`,
          i18n.t('Success'),
          mastV2,
        );
        console.log('Update message: ', response.data.msg);
      } else {
        toastrHelper.showErrorToast(
          i18n.t('Error updating threat item'),
          i18n.t('Error'),
          mastV2,
        );
        console.log('Error updating threat item');
      }
    } catch (err) {
      toastrHelper.showErrorToast(
        i18n.t('Error updating threat item'),
        i18n.t('Error'),
        mastV2,
      );
      console.log('Error updating threat item:', err);
    }
  };

  // Send command to regenerate threat score for users
  const regenerateThreatScores = async () => {
    setShowRegenerateConfirmModal(false);
    console.log('Regenerating threat scores...');

    const response = await axios.get('emm/recalculate-threat-scores');
    if (response.status !== 200) {
      toastrHelper.showErrorToast(
        i18n.t('Error recalculating threat scores'),
        i18n.t('Error'),
        mastV2,
      );
    } else {
      toastrHelper.showSuccessToast(
        i18n.t('Started recalculating threat scores'),
        i18n.t('Success'),
        mastV2,
      );
    }
  };

  // Send command to reset threat score for the group
  const resetThreatScores = async () => {
    setShowResetConfirmModal(false);
    console.log('Resetting threat score customization');

    const response = await axios.delete(
      'emm/customize-threat/custom-report-items',
    );
    if (response.status !== 200) {
      toastrHelper.showErrorToast(
        i18n.t('Error resetting threat score customization'),
        i18n.t('Error'),
        mastV2,
      );
    } else {
      setThreatItems(
        threatItems.map(threatItem => {
          threatItem.risk = threatItem.default_risk;
          threatItem.enabled = true;
          return threatItem;
        }),
      );
      toastrHelper.showSuccessToast(
        i18n.t('Resetting threat score customization successful'),
        i18n.t('Success'),
        mastV2,
      );
    }
  };

  const canCustomizeThreatScore =
    userAccess.group_admin || userAccess.can_customize_threat_score;

  const tabs = [
    {
      title: () => (
        <span>
          <QTypography variant="h5Medium">Android</QTypography>
        </span>
      ),
      tabContent: () => (
        <ThreatScoreItems
          threatItems={threatItems}
          platform="android"
          toggleThreatItemEnabled={toggleThreatItemEnabled}
          changeThreatItemWeight={changeThreatItemWeight}
          canCustomizeThreatScore={canCustomizeThreatScore}
        />
      ),
    },
    {
      title: () => (
        <span>
          <QTypography variant="h5Medium" textTransform="none">
            iOS
          </QTypography>
        </span>
      ),
      tabContent: () => (
        <ThreatScoreItems
          threatItems={threatItems}
          platform="ios"
          toggleThreatItemEnabled={toggleThreatItemEnabled}
          changeThreatItemWeight={changeThreatItemWeight}
          canCustomizeThreatScore={canCustomizeThreatScore}
        />
      ),
    },
  ];

  return (
    <div className="animated fadeIn">
      <ConfirmationModal
        isOpen={showRegenerateConfirmModal}
        toggle={() =>
          setShowRegenerateConfirmModal(!showRegenerateConfirmModal)
        }
        confirmHeader={i18n.t('Regenerate Threat Scores')}
        confirmBody={i18n.t(
          'Do you want to regenerate all threat scores for applications you have submitted? This will recalculate the scores for the apps based on your currently active threat profile.',
        )}
        confirm={regenerateThreatScores}
      />
      <ConfirmationModal
        isOpen={showResetConfirmModal}
        toggle={() => setShowResetConfirmModal(!showResetConfirmModal)}
        confirmHeader={i18n.t('Reset Threat Score Customization')}
        confirmBody={i18n.t(
          'Do you want to reset all threat score customization for the group?',
        )}
        confirm={resetThreatScores}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <QContainer fluid style={{ minWidth: '100%' }}>
          {canCustomizeThreatScore && (
            <QRow
              className="justify-end"
              sx={{ justifyContent: 'end', columnGap: '16px' }}
            >
              <QButton
                variant="outlined"
                className="float-right"
                color="warning"
                onClick={() => setShowResetConfirmModal(true)}
              >
                {i18n.t('Reset Customization')}
              </QButton>
              <QButton
                variant="filled"
                className="float-right"
                color="primary"
                onClick={() => setShowRegenerateConfirmModal(true)}
              >
                {i18n.t('Regenerate Scores')}
              </QButton>
            </QRow>
          )}
          <QTabsView tabs={tabs} />
        </QContainer>
      )}
    </div>
  );
}
