import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { PDFReportConfig } from '../../Settings/PDFReportConfig';
import { fetchKaiUsers } from '../../../store/sliceHelpers/kaiSliceHelper';

export const ReportSettings = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchKaiUsers());
  }, []);

  return <PDFReportConfig kaiAccess />;
};
