import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';

import Findings from '../Findings';
import TabsView from '../../TabsView';

class RightToBeInformed extends Component {
  render() {
    const { platform, parsedIssues } = this.props;

    const tabs = [
      {
        title: 'Overview',
        tabContent: () => (
          <Findings
            findings={parsedIssues}
            platform={platform}
            category="privacy"
            showOwaspIcon={false}
          />
        ),
      },
    ];

    return (
      <div>
        <Card className="card-accent-navy">
          <CardHeader>Right To Be Informed</CardHeader>
          <CardBody>
            <div>
              &quot;There is a need for transparency regarding the gathering and
              use of data in order to allow EU citizens to exercise their right
              to the protection of personal data. Therefore, the General Data
              Protection Regulation (GDPR) gives individuals a right to be
              informed about the collection and use of their personal data,
              which leads to a variety of information obligations by the
              controller. The law differentiates between two cases: On the one
              hand, if personal data is directly obtained from the data subject
              (Art. 13 of the GDPR) and, on the other hand, if this is not the
              case (Art. 14 of the GDPR).&quot;
            </div>
            <div>
              <a
                href="https://gdpr-info.eu/issues/right-to-be-informed/"
                target="_blank"
              >
                More Info
              </a>
            </div>
            <hr />
            <TabsView tabs={tabs} />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ appResults }) => ({
  parsedIssues: appResults.parsedIssues,
});

export default withRouter(connect(mapStateToProps)(RightToBeInformed));
