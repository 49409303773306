import React, { Component } from 'react';

import PermissionsRequestedTable from '../../../Android/PermissionsRequestedTable';
import TabsView from '../../../../TabsView';
import i18n from '../../../../../localization/i18n';

export default class CanKillBackground extends Component {
  render() {
    const tabs = [
      {
        title: i18n.t('Permission Requested'),
        tabContent: () => (
          <PermissionsRequestedTable filter="KILL_BACKGROUND_PROCESSES" />
        ),
      },
    ];
    return <TabsView tabs={tabs} />;
  }
}
