import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QTabContext as MuiTabContext } from '@kw/quokka-ui';

const LDTabContext = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiTabContext {...props}>{children}</MuiTabContext>
  ) : (
    <div>{children}</div>
  );
};
export const QTabContext = withLDConsumer()(LDTabContext);
