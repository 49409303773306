import React, { useState } from 'react';
import { Col, Card, CardHeader, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import DataTable from '../../DataTable/DataTable';
import { RiskBadge } from '../../RiskBadge';
import i18n from '../../../localization/i18n';
import {
  getRuleName,
  getSeverity,
  getResults,
} from '../../../logic/dopplerUtil';

import { DopplerNextDetailsModal } from './DopplerNextDetailsModal';

export function DopplerNextResults({ filterRules = [] }) {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const { doppler } = useSelector(({ appResults }) => appResults.results);

  const results = getResults(doppler);

  let filterResults = _.cloneDeep(results);

  if (filterRules.length > 0) {
    filterResults = filterResults.filter(({ ruleId }) =>
      filterRules.includes(ruleId),
    );
  }

  return (
    <Col>
      <DopplerNextDetailsModal
        isOpen={showDetailsModal}
        toggle={() => setShowDetailsModal(!showDetailsModal)}
        selectedItem={selectedItem}
        dopplerResults={doppler}
      />

      <Card className="card-accent-warning">
        <CardHeader>{i18n.t('Code Path Analysis')}</CardHeader>
        <CardBody>
          {doppler.length === 0 ? (
            <div>{i18n.t('No issues found')}</div>
          ) : (
            <DataTable
              data={filterResults}
              columns={[
                {
                  component: ({ rowData }) => (
                    <span>{getRuleName(doppler, rowData.ruleId)}</span>
                  ),
                  label: 'Violation Type',
                  width: '25%',
                },
                {
                  label: 'Risk',
                  width: '10%',
                  component: ({ rowData }) => (
                    <RiskBadge risk={getSeverity(rowData.properties.score)} />
                  ),
                  filterable: true,
                },
                {
                  label: 'Location',
                  component: ({ rowData }) => (
                    <code>
                      {_.get(
                        rowData,
                        'locations[0].physicalLocation.artifactLocation.uri',
                      )}
                    </code>
                  ),
                },
              ]}
              onRowClick={row => {
                setSelectedItem(row);
                setShowDetailsModal(true);
              }}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
}
