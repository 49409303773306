import React from 'react';
import { BarChart, Bar, Tooltip, ResponsiveContainer } from 'recharts';
import _ from 'lodash';

import { byVersion } from './SummarizedAppInfo';

/**
 *
 * @param {object} props
 * @param {{[version: string]: {high: number, medium: number, low: number}}}} props.data
 */
export default function RisksBarChart({ data = {} }) {
  const groupedData = Object.entries(data)
    .slice()
    .sort(([versionA], [versionB]) => byVersion(versionA, versionB))
    .map(([version, risks]) => ({
      name: version,
      ...risks,
    }))
    .slice(-10);

  const maxBarWidth = groupedData.length === 1 ? 25 : 20;
  return (
    <ResponsiveContainer
      className="ml-auto mr-auto"
      width={
        groupedData.length === 1
          ? 80
          : _.inRange(groupedData.length, 2, 4)
          ? groupedData.length * 50
          : '100%'
      }
      height={70}
    >
      <BarChart
        data={groupedData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <Tooltip
          allowEscapeViewBox={{ x: true, y: true }}
          labelFormatter={label => `v${groupedData[label].name}`}
        />

        <Bar
          isAnimationActive={false}
          maxBarSize={maxBarWidth}
          dataKey="low"
          stackId="a"
          fill="#17a2b8"
        />
        <Bar
          maxBarSize={maxBarWidth}
          isAnimationActive={false}
          dataKey="medium"
          stackId="a"
          fill="#ffc107"
        />
        <Bar
          maxBarSize={maxBarWidth}
          isAnimationActive={false}
          dataKey="high"
          stackId="a"
          fill="#dc3545"
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
