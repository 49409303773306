import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { QTable } from '../../Q-Components/QTable';
import { QTableRow } from '../../Q-Components/QTableRow';
import { QTableCell } from '../../Q-Components/QTableCell';
import { QTableBody } from '../../Q-Components/QTableBody';
import { QTypography } from '../../Q-Components/QTypography';
import reportUtil from '../../../logic/androidReportUtil';
import i18n from '../../../localization/i18n';
import { SubmissionTagBadge } from '../../SubmissionTagBadge';

const AppInfoTable = ({ results, flags, marketInfoExists }) => {
  const name = reportUtil.getAppName(results);
  const versionName = reportUtil.getVersionName(results);
  const versionCode = reportUtil.getVersionCode(results);
  const minSdk = reportUtil.getMinSdk(results);
  const targetSdk = reportUtil.getTargetSdk(results);
  const hashes = reportUtil.getHashes(results);
  const fileSize = reportUtil.getFileSize(results);
  const submissionTag = reportUtil.getSubmissionTag(results);
  const submittedAt = reportUtil.getSubmittedAt(results);
  const testScript = reportUtil.getTestScript(results);

  const appiumScriptEnabled = flags?.appiumScript;

  return (
    <div>
      <QTable size="sm">
        <QTableBody>
          <QTableRow hoverDisabled>
            <QTableCell sx={{ verticalAlign: 'top' }}>
              <p className="app-info-title">
                <QTypography variant="bodyMedium">{i18n.t('Name')}</QTypography>
              </p>{' '}
              <span className="app-info-value">
                <QTypography variant="bodyRegular">{name}</QTypography>
              </span>
            </QTableCell>
            <QTableCell sx={{ verticalAlign: 'top' }}>
              <p className="app-info-title">
                <QTypography variant="bodyMedium">
                  {i18n.t('Version')}
                </QTypography>
              </p>{' '}
              <span className="app-info-value">
                <QTypography variant="bodyRegular">{versionName}</QTypography>
              </span>
            </QTableCell>
            {!marketInfoExists && (
              <QTableCell sx={{ verticalAlign: 'top' }}>
                <p className="app-info-title">
                  <QTypography variant="bodyMedium">
                    {i18n.t('Submission Info')}
                  </QTypography>
                </p>
                <span className="app-info-value">
                  {' '}
                  <QTypography variant="bodyRegular">
                    {moment(submittedAt).format('L - LTS')}
                  </QTypography>
                </span>{' '}
                <span className="app-info-value">
                  <p>
                    <SubmissionTagBadge submissionTag={submissionTag} />
                  </p>
                </span>
              </QTableCell>
            )}
          </QTableRow>
          <QTableRow hoverDisabled>
            <QTableCell sx={{ verticalAlign: 'top' }}>
              <p className="app-info-title">
                <QTypography variant="bodyMedium">
                  {i18n.t('Version Code')}
                </QTypography>
              </p>{' '}
              <span className="app-info-value">
                <QTypography variant="bodyRegular">{versionCode}</QTypography>
              </span>
            </QTableCell>
            <QTableCell sx={{ verticalAlign: 'top' }}>
              <p className="app-info-title">
                <QTypography variant="bodyMedium">Min SDK</QTypography>
              </p>{' '}
              <span className="app-info-value">
                <QTypography variant="bodyRegular">{minSdk}</QTypography>
              </span>
            </QTableCell>
            {!marketInfoExists && (
              <QTableCell sx={{ verticalAlign: 'top' }}>
                <p className="app-info-title">
                  <QTypography variant="bodyMedium">SHA1</QTypography>
                </p>{' '}
                <span className="app-info-value">
                  <QTypography variant="bodyRegular">{hashes.SHA1}</QTypography>
                </span>
              </QTableCell>
            )}
          </QTableRow>
          <QTableRow hoverDisabled>
            <QTableCell sx={{ verticalAlign: 'top' }}>
              <p className="app-info-title">
                <QTypography variant="bodyMedium">Target SDK</QTypography>
              </p>{' '}
              <span className="app-info-value">
                <QTypography variant="bodyRegular">{targetSdk}</QTypography>
              </span>
            </QTableCell>
            <QTableCell sx={{ verticalAlign: 'top' }}>
              <p className="app-info-title">
                <QTypography variant="bodyMedium">
                  {i18n.t('File Size')}
                </QTypography>
              </p>
              <span className="app-info-value">
                <QTypography variant="bodyRegular">{`${fileSize.MB}MB`}</QTypography>
              </span>
            </QTableCell>
            {!marketInfoExists && (
              <QTableCell sx={{ verticalAlign: 'top' }}>
                <p className="app-info-title">
                  <QTypography variant="bodyMedium">MD5</QTypography>
                </p>{' '}
                <span className="app-info-value">
                  <QTypography variant="bodyRegular">{hashes.MD5}</QTypography>
                </span>
              </QTableCell>
            )}
          </QTableRow>
          {marketInfoExists && (
            <QTableRow hoverDisabled>
              <QTableCell colSpan={2}>
                <p className="app-info-title">
                  <QTypography variant="bodyMedium">
                    {i18n.t('Submission Info')}
                  </QTypography>
                </p>
                <span className="app-info-value">
                  {' '}
                  <QTypography variant="bodyRegular">
                    {moment(submittedAt).format('L - LTS')}
                  </QTypography>
                </span>{' '}
                <span className="app-info-value">
                  <p>
                    <SubmissionTagBadge submissionTag={submissionTag} />
                  </p>
                </span>
              </QTableCell>
            </QTableRow>
          )}
          {marketInfoExists && (
            <QTableRow hoverDisabled>
              <QTableCell colSpan={2}>
                <p className="app-info-title">
                  <QTypography variant="bodyMedium">SHA1</QTypography>
                </p>{' '}
                <span className="app-info-value">
                  <QTypography variant="bodyRegular">{hashes.SHA1}</QTypography>
                </span>
              </QTableCell>
            </QTableRow>
          )}
          {marketInfoExists && (
            <QTableRow hoverDisabled>
              <QTableCell colSpan={2}>
                <p className="app-info-title">
                  <QTypography variant="bodyMedium">MD5</QTypography>
                </p>{' '}
                <span className="app-info-value">
                  <QTypography variant="bodyRegular">{hashes.MD5}</QTypography>
                </span>
              </QTableCell>
            </QTableRow>
          )}
          {appiumScriptEnabled && testScript && (
            <QTableRow hoverDisabled>
              <QTableCell colSpan={marketInfoExists ? 2 : 3}>
                <p className="app-info-title">
                  <QTypography variant="bodyMedium">
                    {i18n.t('Test Script')}
                  </QTypography>
                </p>{' '}
                <span className="app-info-value">
                  <QTypography variant="bodyRegular">{testScript}</QTypography>
                </span>
              </QTableCell>
            </QTableRow>
          )}
        </QTableBody>
      </QTable>
    </div>
  );
};

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(withLDConsumer()(AppInfoTable));
