import React from 'react';

import { QFormGroup } from '../../Q-Components/QFormGroup';
import { QBadge } from '../../Q-Components/QBadge';
import { QTypography } from '../../Q-Components/QTypography';
import { QLabel } from '../../Q-Components/QLabel';

import EditableTextArea from './EditableTextArea';

export default function Fields({
  label,
  fieldName,
  customized,
  resetReportItemField,
  isEditing,
  value,
  oldValue,
  setValue,
  setIsEditing,
  currentItemId,
  onSave,
}) {
  return (
    <div>
      <QFormGroup>
        <div>
          <div style={{ marginBottom: '5px' }}>
            <QLabel>
              <strong>
                <QTypography variant="h5Medium">{label}</QTypography>
              </strong>
            </QLabel>
            {customized && (
              <span>
                <QBadge
                  variant="orange"
                  className="ml-2"
                  color="warning"
                  pill
                  label={
                    <QTypography variant="tinyMedium" color="#000000">
                      Customized
                    </QTypography>
                  }
                />
                <span className="ml-2">
                  <button
                    type="button"
                    className="button-like-span"
                    onClick={() => {
                      resetReportItemField(fieldName);
                      setIsEditing(false);
                    }}
                    aria-label="Reset report item field"
                  >
                    <i className="fa-solid fa-rotate pointer" />
                  </button>
                </span>
              </span>
            )}
          </div>
          <div style={{ marginBottom: '10px' }}>
            <EditableTextArea
              isEditing={isEditing}
              fieldName={fieldName}
              value={value}
              oldValue={oldValue}
              setValue={setValue}
              setIsEditing={setIsEditing}
              currentItemId={currentItemId}
              onSave={onSave}
            />
          </div>
        </div>
      </QFormGroup>
    </div>
  );
}
