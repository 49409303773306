import React from 'react';
import classnames from 'classnames';
import { Badge } from 'reactstrap';
import { useFlags } from 'launchdarkly-react-client-sdk';

import util from '../../../../logic/util';
import {
  getThreatIconClass,
  getThreatColor,
  getThreatQBadgeVariant,
} from '../../../../logic/generalReportUtil';
import i18n from '../../../../localization/i18n';
import { QBadge } from '../../../Q-Components/QBadge';
import { QTypography } from '../../../Q-Components/QTypography';

export default function RiskLevel({ risk }) {
  const { mastV2 } = useFlags();

  const informational = risk === 'informational';

  return mastV2 ? (
    <QBadge
      variant={informational ? undefined : getThreatQBadgeVariant(risk)}
      label={
        risk && !informational ? (
          `${risk}`
        ) : (
          <QTypography variant="bodySmall" style={{ color: '#000000' }}>
            Informational
          </QTypography>
        )
      }
      style={
        informational
          ? {
              height: '24px',
              backgroundColor: '#EEEEEE',
            }
          : {
              height: '24px',
            }
      }
    />
  ) : (
    <div className="d-flex flex-column align-items-center">
      <div>
        <i
          className={classnames(
            'fa-regular fa-exclamation-triangle fa-2x',
            getThreatIconClass(risk),
          )}
        />
      </div>
      <Badge
        style={{ minWidth: '65%' }}
        color={getThreatColor(risk)}
        className="mt-1"
      >
        {informational ? 'Info' : util.capitalize(i18n.t(risk))}
      </Badge>
    </div>
  );
}
