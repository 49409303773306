import React from 'react';
import { NavItem } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QTab as MuiTab } from '@kw/quokka-ui';

const LDTab = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  const handleButtonFocus = event => {
    event.target.style.outline = 'none';
  };

  // Alow overwriting default id
  const newProps = { ...props, id: props.cid || props.id };

  return mastV2 ? (
    <MuiTab onFocus={handleButtonFocus} {...newProps} />
  ) : (
    <NavItem {...props}>{children}</NavItem>
  );
};
export const QTab = withLDConsumer()(LDTab);
