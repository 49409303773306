import { options } from 'toastr';

const buttonBase = {
  height: '31px',
  width: '55px',
  borderRadius: '3px',
  backgroundColor: '#1059BF',
  alignItems: 'center',
  color: '#FFFFFF',
  border: 0,
};

export const styles = {
  options: {
    backgroundColor: '#F9F9F9',
    borderRadius: '0',
    textColor: '#121212',
    textAlign: 'center',
    width: '29rem',
    zIndex: 10000,
  },
  tooltip: {
    backgroundColor: '#F9F9F9',
    borderRadius: 3,
    color: '#212529',
    width: '372px',
  },
  tooltipHeader: {
    height: '37px',
    width: '100%',
    borderRadius: '2px 2px 0 0',
    boxShadow: '0 1px 0 0 rgba(0,0,0,0.05)',
    display: 'flex',
    alignItems: 'center',
  },
  tooltipLogo: {
    height: '30px',
    width: '28.4px',
    float: 'left',
    margin: '8.55px',
  },
  tooltipTitle: {
    height: '24px',
    width: '155.63px',
    color: options.textColor,
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '24px',
  },
  tooltipSteps: {
    height: '21px',
    width: '30px',
    color: options.textColor,
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '21px',
  },
  buttonClose: {
    height: '21px',
    width: '21px',
    backgroundColor: 'rgba(0,0,0.6,0)',
    marginLeft: '30px',
    color: '#121212',
    padding: 0,
    border: 0,
  },
  tooltipContent: {
    fontSize: 14,
    padding: '13px',
  },
  tooltipFooter: {
    alignItems: 'center',
    display: 'flow-root',
    justifyContent: 'flex-end',
    padding: '0 8px 8.3px 0',
  },
  buttonNext: {
    ...buttonBase,
    display: 'flex',
    float: 'right',
    marginLeft: '8px',
  },
  buttonBack: {
    ...buttonBase,
    backgroundColor: '#6C757D',
    display: 'flex',
    float: 'right',
  },
  buttonFlow: {
    ...buttonBase,
    width: '160px',
    height: '35px',
    backgroundColor: '#1059BF',
    margin: '8px',
  },
  androidIosFlow: {
    justifyContent: 'center',
    display: 'flex',
  },
  buttonSkip: {
    backgroundColor: 'rgba(0,0,0.6,0)',
    fontSize: 14,
    float: 'left',
    color: '#1059BF',
    border: 0,
  },
};

export const portalStyles = {
  ...styles,
  tooltipSteps: {
    ...styles.tooltipSteps,
    marginLeft: '70.19px',
  },
  tooltip: { ...styles.tooltip, maxWidth: '95%' },
};

export const reportStyles = {
  ...styles,
  tooltipSteps: {
    ...styles.tooltipSteps,
    marginLeft: '85.19px',
  },
  tooltip: { ...styles.tooltip, maxWidth: '100%' },
};
