import React, { Component } from 'react';
import Fuse from 'fuse.js';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Label, Input } from 'reactstrap';
import axios from 'axios';

import i18n from '../../localization/i18n';

import TrafficEntry from './TrafficEntry';

class HarViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: props.filter,
      mimeTypes: [],
    };

    this.changeSearchQuery = this.changeSearchQuery.bind(this);
    this.filterTraffic = this.filterTraffic.bind(this);
  }

  async componentDidMount() {
    if (this.props.onlyExecutableMimeTypes) {
      try {
        const mimeTypes = await axios.get(`executable-files/all`);

        this.setState({ mimeTypes: mimeTypes.data });
      } catch (err) {
        console.log('Error getting executable files MIME list:', err);
      }
    }
  }

  filterTraffic(traffic) {
    const { searchQuery, mimeTypes } = this.state;
    const { urlFilter } = this.props;

    let results = traffic;

    if (this.props.urlFilter) {
      if (typeof urlFilter === 'string') {
        results = results.filter(entry =>
          entry.request.url.includes(this.props.urlFilter),
        );
      } else {
        // Allow a function to be provided to filter the requests' URLs
        console.log('Running the filter through a function');
        results = results.filter(entry => urlFilter(entry.request.url));
      }
    }
    console.log(`results: ${results}`);
    if (this.props.onlyExecutableMimeTypes) {
      results = results.filter(entry => {
        const { mimeType } = entry.response.content;
        const { url } = entry.request;
        let i;
        for (i = 0; i < mimeTypes.length; i++) {
          if (mimeTypes[i].mime_type === mimeType) {
            if (mimeTypes[i].is_generic_mime) {
              if (url.includes(mimeTypes[i].file_extension)) {
                return true;
              }
            } else {
              return true;
            }
          }
        }
        return false;
      });
    }

    if (!searchQuery) {
      return results;
    }

    const options = {
      shouldSort: true,
      includeMatches: false,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        'request.url',
        'request.postData.text',
        'request.postData.params.value',
        'request.queryString.name',
        'request.queryString.value',
      ],
    };

    const fuse = new Fuse(traffic, options);
    results = fuse.search(searchQuery);

    return results;
  }

  changeSearchQuery(e) {
    this.setState({ searchQuery: e.target.value });
  }

  render() {
    const { searchQuery } = this.state;
    const { showFilter, traffic } = this.props;

    if (
      !traffic ||
      _.isEmpty(traffic) ||
      traffic.log.entries.length === 0 ||
      typeof traffic === 'string' // If there is an error in the JSON data, Axios will return the data as a string
    ) {
      return <span>{i18n.t('No Entries')}</span>;
    }

    const filteredTraffic = this.filterTraffic(traffic.log.entries);

    if (!traffic) {
      return <div />;
    }
    return (
      <>
        {/* Traffic search */}
        {showFilter && (
          <div style={{ width: 200, marginBottom: 10 }}>
            <Label for="traffic-search-input">
              <strong>{i18n.t('Search')}</strong>
            </Label>
            <Input
              type="text"
              id="traffic-search-input"
              onChange={this.changeSearchQuery}
              value={searchQuery}
            />
          </div>
        )}

        {/* Traffic Entries */}
        <div style={{ maxHeight: '400px', overflow: 'auto' }}>
          <div style={{ borderTop: '1px solid #eaeaea' }} />
          {filteredTraffic.map((entry, index) => (
            <TrafficEntry
              key={`${entry.startedDateTime}-${index}`}
              entry={entry}
              stripedBackground={index % 2 !== 0}
              index={index}
            />
          ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ appResults, emmApp }) => ({
  traffic: appResults.traffic,
  lang: emmApp.portalConfig.lang,
});

export const Harviewer = connect(mapStateToProps)(HarViewer);
