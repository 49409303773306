import React, { Component } from 'react';
import { InputGroupText } from 'reactstrap';
import axios from 'axios';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import CloseIcon from '@mui/icons-material/Close';

import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';
import { QModal } from '../Q-Components/QModal';
import { QModalTitle } from '../Q-Components/QModalTitle';
import { QModalContent } from '../Q-Components/QModalContent';
import { QModalActions } from '../Q-Components/QModalActions';
import { QButton } from '../Q-Components/QButton';
import { QTypography } from '../Q-Components/QTypography';
import { QInputGroup } from '../Q-Components/QInputGroup';
import { QInput } from '../Q-Components/QInput';
import { QInputGroupAddon } from '../Q-Components/QInputGroupAddon';

class TwoFactorAuthQrCodeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      twoFactorCode: '',
      verified: false,
    };

    this.verifyCode = this.verifyCode.bind(this);
  }

  async verifyCode() {
    const { twoFactorCode } = this.state;

    try {
      const response = await axios.get(`/2fa/verify?token=${twoFactorCode}`);
      if (response.status === 200) {
        toastrHelper.showSuccessToast(
          i18n.t(
            'Code verified successfully',
            'Success',
            this.props.flags?.mastV2,
          ),
        );
        this.setState({ verified: true });
      }
    } catch (err) {
      toastrHelper.showErrorToast(
        i18n.t('Error verifying code. Please try again or contact support.'),
        'Error',
        this.props.flags?.mastV2,
      );
    }
  }

  render() {
    const { twoFactorCode, verified } = this.state;
    const { isOpen, toggle, qrCodeBase64, onDone } = this.props;

    return (
      <QModal
        isOpen={isOpen}
        open={isOpen}
        toggle={toggle}
        backdrop={false}
        title={
          <QModalTitle onClose={toggle} closeIcon={<CloseIcon />}>
            <QTypography variant="h5Regular">
              <i className="fa-solid fa-qrcode" />{' '}
              {i18n.t('Two Factor Auth QR Code')}
            </QTypography>
          </QModalTitle>
        }
        content={
          <QModalContent>
            <p>
              {i18n.t(
                'Below is your two factor authentication QR code. Please scan it into your authentication application now. We recommend the Google Authenticator application.',
              )}
            </p>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img
                src={qrCodeBase64}
                alt={i18n.t(
                  'QR Code goes here, please contact support if you see this',
                )}
              />
            </div>

            <div>
              <p>
                {i18n.t(
                  'Once you have scanned the QR code in your authenticator app, you can enter in the code you see here to verify that everything is working properly.',
                )}
              </p>
              <p>
                {i18n.t(
                  'In order to enable two factor authentication, an initial token must be validated. ',
                )}
                <strong>{i18n.t('This step is required.')}</strong>
              </p>
            </div>
            {!verified && (
              <QInputGroup>
                <QInput
                  type="text"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <QButton
                        variant="filled"
                        addonType="append"
                        onClick={this.verifyCode}
                      >
                        {i18n.t('Verify')}
                      </QButton>
                    ),
                  }}
                  value={twoFactorCode}
                  onChange={e =>
                    this.setState({ twoFactorCode: e.target.value })
                  }
                />
              </QInputGroup>
            )}
          </QModalContent>
        }
        actions={
          <QModalActions>
            <QButton
              color="secondary"
              onClick={onDone}
              disabled={!verified}
              variant="filled"
            >
              <i className="fa-solid fa-check" />
              &nbsp;{i18n.t('Done')}
            </QButton>
          </QModalActions>
        }
      >
        {!this.props.flags?.mastV2 && (
          <>
            <QModalTitle toggle={toggle}>
              <i className="fa-solid fa-qrcode" />{' '}
              {i18n.t('Two Factor Auth QR Code')}
            </QModalTitle>
            <QModalContent>
              <p>
                {i18n.t(
                  'Below is your two factor authentication QR code. Please scan it into your authentication application now. We recommend the Google Authenticator application.',
                )}
              </p>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <img
                  src={qrCodeBase64}
                  alt={i18n.t(
                    'QR Code goes here, please contact support if you see this',
                  )}
                />
              </div>

              <div>
                <p>
                  {i18n.t(
                    'Once you have scanned the QR code in your authenticator app, you can enter in the code you see here to verify that everything is working properly.',
                  )}
                </p>
                <p>
                  {i18n.t(
                    'In order to enable two factor authentication, an initial token must be validated. ',
                  )}
                  <strong>{i18n.t('This step is required.')}</strong>
                </p>
              </div>
              {!verified && (
                <QInputGroup>
                  <QInput
                    value={twoFactorCode}
                    onChange={e =>
                      this.setState({ twoFactorCode: e.target.value })
                    }
                  />
                  <QInputGroupAddon
                    addonType="append"
                    className="pointer bg-info"
                    onClick={this.verifyCode}
                  >
                    <InputGroupText>{i18n.t('Verify')}</InputGroupText>
                  </QInputGroupAddon>
                </QInputGroup>
              )}
            </QModalContent>
            <QModalActions>
              <QButton
                color="secondary"
                onClick={onDone}
                disabled={!verified}
                variant="filled"
              >
                <i className="fa-solid fa-check" />
                &nbsp;{i18n.t('Done')}
              </QButton>
            </QModalActions>
          </>
        )}
      </QModal>
    );
  }
}

export default withLDConsumer()(TwoFactorAuthQrCodeModal);
