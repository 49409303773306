import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import PermissionsRequestedDatatable from '../iOS/PermissionsRequestedDatatable';
import UrlConstantsDatatable from '../iOS/UrlConstantsDatatable';
import IosCloudServicesTable from '../iOS/CloudServicesTable';
import SystemLibrariesDatatable from '../iOS/SystemLibrariesDatatable';
import HostsContactedMap from '../HostsContactedMap';
import UrlScanningData from '../UrlScanningData';
import CountriesContactedList from '../CountriesContactedList';
import FileAccessDatatable from '../iOS/FileAccessDatatable';
import SensitiveDataExposureDatatable from '../SensitiveDataExposureDatatable';
import PermissionsRequestedTable from '../Android/PermissionsRequestedTable';
import DynamicAnalysisDatatable from '../Android/DynamicAnalysisDatatable';
import AndroidCloudServicesTable from '../Android/CloudServicesTable';
import TabsView from '../../TabsView';
import Findings from '../Findings';
import { Harviewer } from '../../Harviewer/Harviewer';

class PrivacyByDesign extends Component {
  render() {
    const { showMap, platform, parsedIssues } = this.props;

    const tabs = [
      {
        title: 'Overview',
        tabContent: () => (
          <Row>
            <Col>
              <Findings
                findings={parsedIssues}
                platform={platform}
                category="privacy"
                showOwaspIcon={false}
              />
            </Col>
            <Col>
              <Findings
                findings={parsedIssues}
                category="hardware"
                showOwaspIcon={false}
              />
            </Col>
          </Row>
        ),
      },
      {
        title: 'Permissions Requested',
        tabContent: () =>
          platform === 'ios' ? (
            <PermissionsRequestedDatatable />
          ) : (
            <PermissionsRequestedTable />
          ),
      },
      {
        title: 'URL Constants',
        tabContent: () => <UrlConstantsDatatable platform={platform} />,
      },
      {
        title: 'Cloud Services',
        tabContent: () =>
          platform === 'ios' ? (
            <IosCloudServicesTable />
          ) : (
            <AndroidCloudServicesTable />
          ),
      },
      {
        title: 'System Libraries Used',
        tabContent: () =>
          platform === 'ios' ? (
            <SystemLibrariesDatatable />
          ) : (
            <DynamicAnalysisDatatable onlySelectedCategory="Libraries Loaded" />
          ),
      },
      {
        title: 'Network Map',
        tabContent: () => (
          <HostsContactedMap showMap={showMap} platform={platform} />
        ),
      },
      {
        title: 'URL Scanning',
        tabContent: () => <UrlScanningData platform={platform} />,
      },
      {
        title: 'Countries Contacted',
        tabContent: () => <CountriesContactedList platform={platform} />,
      },
      {
        title: 'Network Traffic',
        tabContent: () => <Harviewer />,
      },
      {
        title: 'File Access',
        tabContent: () =>
          platform === 'ios' ? (
            <FileAccessDatatable />
          ) : (
            <DynamicAnalysisDatatable onlySelectedCategory="Files Accessed" />
          ),
      },
      {
        title: 'Sensitive Data Exposure',
        tabContent: () => (
          <SensitiveDataExposureDatatable platform={platform} />
        ),
      },
    ];

    return (
      <div>
        <Card className="card-accent-navy">
          <CardHeader>Privacy By Design</CardHeader>
          <CardBody>
            <div>
              &quot;“Privacy by Design” and “Privacy by Default” have been
              frequently-discussed topics related to data protection. The first
              thoughts of “Privacy by Design” were expressed in the 1970s and
              were incorporated in the 1990s into the RL 95/46/EC data
              protection directive. According to recital 46 in this Directive,
              technical and organisational measures (TOM) must be taken already
              at the time of planning a processing system to protect data
              safety.&quot;
            </div>
            <div>
              <a
                href="https://gdpr-info.eu/issues/privacy-by-design/"
                target="_blank"
              >
                More Info
              </a>
            </div>
            <hr />

            <TabsView tabs={tabs} />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ appResults }) => ({
  parsedIssues: appResults.parsedIssues,
});

export default withRouter(connect(mapStateToProps)(PrivacyByDesign));
