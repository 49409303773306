import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { store } from '../store/store';
import {
  changeSearchQuery,
  changeCurrentSearchQuery,
  changeSortByColumn,
  changePage,
  changePlatformFilter,
  changePageSize,
  changeSubGroupFilter,
  changeSubmissionTagFilter,
  changeStatusFilter,
  changeStartDate,
  changeEndDate,
  changeTestScriptFilter,
  changeLabelsFilter,
} from '../store/slices/analyzedAppsSlice';
import AnalyzedAppsTable from '../components/AnalyzedApps/AnalyzedAppsTable';
import { AnalyzedAppsSettingsModal } from '../components/AnalyzedApps/AnalyzedAppsSettingsModal';
import i18n from '../localization/i18n';
import { toastrHelper } from '../logic/toastrHelper';
import { invalidateAnalyzedAppsCache } from '../store/slices/cache/analyzedAppsCacheSlice';
import { fetchAnalyzedAppsCache } from '../store/sliceHelpers/analyzedAppsCacheSliceHelper';
import { setPageTitle } from '../store/slices/emmAppSlice';

class AnalyzedApps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      pagination: {
        lastPage: 0,
        totalData: 0,
        dataPerPage: 0,
      },
      loading: true,

      // Confirmation modal
      showResubmitConfirmModal: false,
      showConfirmModal: false,
      appIdToResubmit: 0,
      appIdToRemove: 0,

      // Settings Modal
      showSettingsModal: false,
    };

    this.getApps = this.getApps.bind(this);
    this.changeSortOption = this.changeSortOption.bind(this);
    this.changeStatusOption = this.changeStatusOption.bind(this);
    this.handlePlatformChange = this.handlePlatformChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.removeSearchQuery = this.removeSearchQuery.bind(this);
    this.resubmitApp = this.resubmitApp.bind(this);
    this.removeApp = this.removeApp.bind(this);
    this.toggleResubmitConfirmModal =
      this.toggleResubmitConfirmModal.bind(this);
    this.toggleConfirmModal = this.toggleConfirmModal.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubGroupFilter = this.handleSubGroupFilter.bind(this);
    this.toggleSettingsModal = this.toggleSettingsModal.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handleSubmissionTagFilter = this.handleSubmissionTagFilter.bind(this);
    this.handleTestScriptFilterChange =
      this.handleTestScriptFilterChange.bind(this);
    this.handleLabelsFilterChange = this.handleLabelsFilterChange.bind(this);
    this.downloadCsv = this.downloadCsv.bind(this);
  }

  async componentWillMount() {
    this.props.setPageTitle('Analyzed Applications');
  }

  // Handle the status option being changed
  changeStatusOption(option) {
    this.props.changeStatusFilter(option);
    this.getApps();
  }

  // Handle the sorting option being changed
  changeSortOption(option) {
    this.props.changeSortByColumn(option);
    this.getApps();
  }

  // Handle a change in the platform filter option
  handlePlatformChange(option) {
    this.props.changePlatformFilter(option);
    this.getApps();
  }

  // Handle page size change
  handlePageSizeChange(option) {
    this.props.changePageSize(option);

    this.getApps();
  }

  handleSubGroupFilter(opt, reload = true) {
    // Remove "all" option if other options are selected
    let option;
    if (opt && opt.length > 0) {
      option = opt.filter(op => op.value !== 'all');
    } else {
      option = { value: 'all', label: 'All' };
    }
    this.props.changeSubGroupFilter(option);

    if (reload) {
      this.getApps();
    }
  }

  handleSubmissionTagFilter(opt) {
    // Remove "all" option if other options are selected
    let option;
    if (opt && opt.length > 0) {
      option = opt.filter(op => op.value !== 'all');
    } else {
      option = { value: 'all', label: 'All' };
    }

    this.props.changeSubmissionTagFilter(option);

    this.getApps();
  }

  // Handle a change in the test script filter option
  handleTestScriptFilterChange(option) {
    this.props.changeTestScriptFilter(option);
    this.getApps();
  }

  handleLabelsFilterChange(opt) {
    // Remove "all" option if other options are selected
    let option;
    if (opt && opt.length > 0) {
      option = opt.filter(op => op.value !== 'all');
    } else {
      option = { value: 'all', label: 'All' };
    }

    this.props.changeLabelsFilter(option);

    this.getApps();
  }

  // Handles key press for the search query field, tries to get apps on the enter key
  handleKeyPress(event) {
    /* istanbul ignore else */
    if (event.charCode === 13) {
      event.preventDefault();
      this.getApps();
    }
  }

  // Remove the search query from the page and refresh the apps
  removeSearchQuery() {
    this.props.changeSearchQuery('');
    this.getApps();
  }

  handleStartDate(option) {
    this.props.changeStartDate(option);
    this.getApps();
  }

  handleEndDate(option) {
    this.props.changeEndDate(option);
    this.getApps();
  }

  toggleResubmitConfirmModal(appId = 0) {
    this.setState({
      appIdToResubmit: appId,
      showResubmitConfirmModal: !this.state.showResubmitConfirmModal,
    });
  }

  toggleConfirmModal(appId = 0) {
    this.setState({
      appIdToRemove: appId,
      showConfirmModal: !this.state.showConfirmModal,
    });
  }

  toggleSettingsModal() {
    this.setState({ showSettingsModal: !this.state.showSettingsModal });
  }

  async resubmitApp() {
    const { page } = this.props.analyzedApps;
    const { appIdToResubmit } = this.state;

    try {
      toastrHelper.showInfoToast(
        i18n.t('Resubmitting app...'),
        null,
        this.props.flags?.mastV2,
      );

      // By not providing this call with a parameter, the appIdToRemoval will auto be reset to 0
      this.toggleResubmitConfirmModal();

      await axios.put(`analyzed-apps/${appIdToResubmit}/resubmit`);

      toastrHelper.showSuccessToast(
        i18n.t('Resubmitted app'),
        i18n.t('Success'),
        this.props.flags?.mastV2,
      );
      this.props.invalidateAnalyzedAppsCache();

      this.getApps(page);
    } catch (err) {
      toastrHelper.showErrorToast(
        `${i18n.t('Error resubmitting application')}: ${err.response.data.msg}`,
        i18n.t('Error'),
        this.props.flags?.mastV2,
      );
    }
  }

  // Remove the application from the user's portal. Rely on the appIdToRemove that
  // should have been set in the toggleConfirmModal function called before this one
  async removeApp() {
    const { page } = this.props.analyzedApps;

    const response = await axios.delete(`emm/app/${this.state.appIdToRemove}`);
    if (response.status === 200) {
      toastrHelper.showSuccessToast(
        i18n.t('Removed app'),
        i18n.t('Success'),
        this.props.flags?.mastV2,
      );
      this.props.invalidateAnalyzedAppsCache();
      this.getApps(page);
    } else {
      console.log(response);
      toastrHelper.showErrorToast(
        `${i18n.t('Error removing application')}: ${response.data.msg}`,
        i18n.t('Error'),
        this.props.flags?.mastV2,
      );
    }

    // By not providing this call with a parameter, the appIdToRemoval will auto be reset to 0
    this.toggleConfirmModal();
  }

  /**
   * Make the request to the server to get the analyzed apps for the user
   *
   * @returns JSON data returned from server
   * @memberof Dashboard
   */
  async getApps(pageNum = 1, event = null) {
    if (event) event.preventDefault();

    this.props.changePage(pageNum);
    this.setState({ loading: true });

    // Use store.getState() here since we can't rely on props to be updated
    // by the time this function is called
    const {
      pageSize,
      platformFilter,
      subGroupFilter,
      sortBy,
      searchQuery,
      reviewStatusFilter,
      startDate,
      endDate,
      submissionTagFilter,
      statusFilter,
      testScriptFilter,
      labelsFilter,
    } = store.getState().analyzedApps;

    const subGroupFilterParam = [];

    // Extract subGroupFilter array which contains multiple options and make it become a string
    if (Array.isArray(subGroupFilter) && subGroupFilter.length > 0) {
      subGroupFilter.map(data => subGroupFilterParam.push(data.value));
    } else {
      subGroupFilterParam.push(subGroupFilter.value);
    }

    const submissionTagFilterParam = [];

    if (Array.isArray(submissionTagFilter) && submissionTagFilter.length > 0) {
      submissionTagFilter.map(data =>
        submissionTagFilterParam.push(data.value),
      );
    } else {
      submissionTagFilterParam.push(submissionTagFilter.value);
    }

    const labelsFilterParam = [];

    if (Array.isArray(labelsFilter) && labelsFilter.length > 0) {
      labelsFilter.map(data => labelsFilterParam.push(data.value));
    } else {
      labelsFilterParam.push(labelsFilter.value);
    }

    // Construct analyzed app URL query params
    const searchParams = new URLSearchParams();
    searchParams.append('search', searchQuery);
    searchParams.append('sortColumn', sortBy.value);
    searchParams.append('platform', platformFilter.value);
    searchParams.append('pageSize', pageSize.value);
    searchParams.append('reviewStatus', reviewStatusFilter.value);
    searchParams.append('subGroupId', subGroupFilterParam.join(','));
    searchParams.append(
      'startDate',
      startDate ? moment(startDate).format('MM/DD/YYYY') : '',
    );
    searchParams.append(
      'endDate',
      endDate ? moment(endDate).format('MM/DD/YYYY') : '',
    );
    searchParams.append('page', pageNum);
    searchParams.append('submissionTags', submissionTagFilterParam.join(','));
    searchParams.append('status', statusFilter.value);
    searchParams.append('withTestScript', testScriptFilter.value);
    searchParams.append('labels', labelsFilterParam.join(','));

    this.props.history.push({ search: searchParams.toString() });

    try {
      const appsUrl = `emm/apps/${pageNum}`;
      const results = await this.props.fetchAnalyzedAppsCache(appsUrl, {
        query: encodeURIComponent(searchQuery),
        sortColumn: sortBy.value,
        platforms: platformFilter.value,
        pageSize: pageSize.value,
        reviewStatus: reviewStatusFilter.value,
        subGroupId: subGroupFilterParam.join(','),
        startDate: startDate ?? '',
        endDate: endDate ?? '',
        submissionTags: submissionTagFilterParam.join(','),
        status: statusFilter.value,
        withTestScript: testScriptFilter.value,
        labels: labelsFilterParam.join(','),
      });

      const { apps, total, perPage, lastPage } = results.data;

      this.setState({
        data: apps,
        pagination: {
          lastPage,
          totalData: total,
          dataPerPage: perPage,
        },
      });
      this.props.changeCurrentSearchQuery(searchQuery);
    } catch (err) {
      console.log(err);
    }
    this.setState({ loading: false });
  }

  async downloadCsv(isCompressed = false) {
    const {
      platformFilter,
      subGroupFilter,
      searchQuery,
      reviewStatusFilter,
      startDate,
      endDate,
      submissionTagFilter,
      statusFilter,
    } = store.getState().analyzedApps;

    toastrHelper.showSuccessToast(
      'Your request is being processed. Please look forward to an email with a link to download the requested file',
      null,
      this.props.flags?.mastV2,
    );
    const submissionTagFilterParam = [];

    if (Array.isArray(submissionTagFilter) && submissionTagFilter.length > 0) {
      submissionTagFilter.map(data =>
        submissionTagFilterParam.push(data.value),
      );
    } else {
      submissionTagFilterParam.push(submissionTagFilter.value);
    }

    await axios.post(`csv-request`, {
      pageType: 'analyzed',
      isCompressed,
      query: searchQuery,
      startDate,
      endDate,
      platforms: platformFilter?.value,
      reviewStatus: reviewStatusFilter?.value,
      submissionTags: submissionTagFilterParam.join(','),
      subgroupIds: subGroupFilter?.value,
      status: statusFilter?.value,
    });
  }

  render() {
    const { pageSize, changeSearchQuery } = this.props;

    return (
      <div>
        <AnalyzedAppsTable
          showResubmitConfirmModal={this.state.showResubmitConfirmModal}
          showConfirmModal={this.state.showConfirmModal}
          toggleResubmitConfirmModal={this.toggleResubmitConfirmModal}
          toggleConfirmModal={this.toggleConfirmModal}
          resubmitApp={this.resubmitApp}
          removeApp={this.removeApp}
          getApps={this.getApps}
          handleKeyPress={this.handleKeyPress}
          handleTextChange={e => {
            changeSearchQuery(e.target.value);
          }}
          removeSearchQuery={this.removeSearchQuery}
          changeSortOption={this.changeSortOption}
          changeStatusOption={this.changeStatusOption}
          pageSize={pageSize}
          handlePlatformChange={this.handlePlatformChange}
          handlePageSizeChange={this.handlePageSizeChange}
          handleSubGroupFilter={this.handleSubGroupFilter}
          loading={this.state.loading}
          pagination={this.state.pagination}
          data={this.state.data}
          toggleSettingsModal={this.toggleSettingsModal}
          handleStartDate={this.handleStartDate}
          handleEndDate={this.handleEndDate}
          handleSubmissionTagFilter={this.handleSubmissionTagFilter}
          downloadCsv={this.downloadCsv}
          handleTestScriptFilterChange={this.handleTestScriptFilterChange}
          handleLabelsFilterChange={this.handleLabelsFilterChange}
        />
        <AnalyzedAppsSettingsModal
          isOpen={this.state.showSettingsModal}
          toggle={this.toggleSettingsModal}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ analyzedApps }) => {
  const {
    platformFilter,
    subGroupFilter,
    pageSize,
    searchQuery,
    currentSearchQuery,
    page,
    sortBy,
    startDate,
    endDate,
    submissionTagFilter,
    statusFilter,
    testScriptFilter,
    labelsFilter,
  } = analyzedApps;

  return {
    platformFilter,
    subGroupFilter,
    pageSize,
    page,
    searchQuery,
    currentSearchQuery,
    sortBy,
    analyzedApps,
    startDate,
    endDate,
    submissionTagFilter,
    statusFilter,
    testScriptFilter,
    labelsFilter,
  };
};

const mapDispatchToProps = {
  changeSearchQuery,
  changeSortByColumn,
  changePlatformFilter,
  changePageSize,
  changePage,
  changeSubGroupFilter,
  changeSubmissionTagFilter,
  changeCurrentSearchQuery,
  fetchAnalyzedAppsCache,
  invalidateAnalyzedAppsCache,
  changeStartDate,
  changeEndDate,
  changeStatusFilter,
  changeTestScriptFilter,
  changeLabelsFilter,
  setPageTitle,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(AnalyzedApps));
