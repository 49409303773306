import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import DataTable from '../../DataTable/DataTable';

const maxNumberOfClassesToShow = 100;

export const SdksTable = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const processClasses = classes => {
    const numberOfClasses = classes.length;
    if (numberOfClasses > maxNumberOfClassesToShow) {
      return (
        <>
          <p>
            {showMore
              ? classes.join(', ')
              : classes.slice(0, maxNumberOfClassesToShow).join(', ')}

            <p style={{ color: '#265bb8', cursor: 'pointer' }}>
              <button
                type="button"
                className="button-like-paragraph"
                onClick={toggleShowMore}
              >
                {!showMore
                  ? `+${numberOfClasses - maxNumberOfClassesToShow} more`
                  : 'Show Less'}
              </button>
            </p>
          </p>
        </>
      );
    }
    return <p>{classes.join(', ')}</p>;
  };
  const sdks = useSelector(({ appResults }) => appResults?.results?.sdks ?? {});

  const transformedSdks = _.isObject(sdks)
    ? Object.entries(sdks).map(([key, value]) => ({
        sdk: key,
        class: value,
      }))
    : [];

  return (
    <>
      <DataTable
        data={transformedSdks}
        rowsPerPage={5}
        columns={[
          {
            keyName: 'sdk',
            label: 'SDK',
            filterable: true,
            sortable: true,
          },
          {
            keyName: 'class',
            label: 'Class',
            filterable: true,
            sortable: true,
            component: ({ value }) => (
              <>
                {Array.isArray(value) ? (
                  processClasses(value)
                ) : (
                  <>
                    <p>{value}</p>
                  </>
                )}
              </>
            ),
          },
        ]}
      />
    </>
  );
};
