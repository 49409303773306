import React, { Component } from 'react';
import { Col, Card, CardHeader, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import DataTable from '../../DataTable/DataTable';
import { RiskBadge } from '../../RiskBadge';
import i18n from '../../../localization/i18n';

import { DopplerDetailsModal } from './DopplerDetailsModal';
import { DopplerNextResults } from './DopplerNextResults';

class DopplerResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: null,
      showDetailsModal: false,
    };
  }

  render() {
    const { results } = this.props;
    const { showDetailsModal, selectedItem } = this.state;

    let dopplerResults;

    // Let the doppler results be passed in programmatically via react router
    if (_.get(this, 'props.location.state.dopplerResults')) {
      // Old versions of doppler results are in a different format and can be ignored now
      dopplerResults = this.props.location.state.dopplerResults.filter(
        finding => 'vulnerability' in finding,
      );
    } else if (results.doppler) {
      // Current versions of Doppler output in SARIF, which is not an array so if
      // we see that in the results instead we should render the updated component for those results
      if (!Array.isArray(results.doppler)) {
        console.log('Rendering doppler next');

        return <DopplerNextResults />;
      }

      // Old versions of doppler results are in a different format and can be ignored now
      dopplerResults = this.props.results.doppler.filter(
        finding => 'vulnerability' in finding,
      );
    } else {
      return null;
    }

    return (
      <Col>
        <DopplerDetailsModal
          isOpen={showDetailsModal}
          toggle={() => this.setState({ showDetailsModal: !showDetailsModal })}
          selectedItem={selectedItem}
        />

        <Card className="card-accent-warning">
          <CardHeader>{i18n.t('Code Path Analysis')}</CardHeader>
          <CardBody>
            {dopplerResults.length === 0 ? (
              <div>{i18n.t('No issues found')}</div>
            ) : (
              <DataTable
                data={dopplerResults}
                columns={[
                  {
                    component: ({ rowData }) => (
                      <span>{rowData.vulnerability.impact}</span>
                    ),
                    label: 'Violation Type',
                    width: '15%',
                  },
                  {
                    label: 'Risk',
                    width: '10%',
                    component: ({ rowData }) => (
                      <RiskBadge risk={rowData.vulnerability.risk} />
                    ),
                    filterable: true,
                  },
                  {
                    label: 'Component',
                    component: ({ rowData }) => (
                      <span>
                        {rowData.vulnerability.component
                          ? rowData.vulnerability.component.name
                          : 'Not available'}
                      </span>
                    ),
                  },
                ]}
                onRowClick={row =>
                  this.setState({ showDetailsModal: true, selectedItem: row })
                }
              />
            )}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default withRouter(connect(mapStateToProps)(DopplerResults));
