import { createSlice } from '@reduxjs/toolkit';

import { jsonCache } from '../../cacheHelpers/utils';

const initialState = jsonCache.create({ sizeLimit: 7 });

export const analyticsCacheSlice = createSlice({
  name: 'analyticsCache',
  initialState,
  reducers: {
    invalidateAnalyticsCache: state => jsonCache.setAllInvalid(state),
    cacheAnalytics: (state, action) =>
      jsonCache.add(state, action.payload.key, action.payload.data),
  },
});

export const { invalidateAnalyticsCache, cacheAnalytics } =
  analyticsCacheSlice.actions;

export default analyticsCacheSlice.reducer;
