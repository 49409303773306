import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { EditSubGroups } from '../../Settings/GroupAdmin/EditSubGroups';
import { fetchKaiGroups } from '../../../store/sliceHelpers/kaiSliceHelper';

export function SubGroups() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchKaiGroups());
  }, []);

  return <EditSubGroups kaiAccess />;
}
