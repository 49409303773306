import React from 'react';
import classnames from 'classnames';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import { QCard } from './Q-Components/QCard';
import { QCardBody } from './Q-Components/QCardBody';
import { QCardHeader } from './Q-Components/QCardHeader';

const LDStatCard = ({ ...props }) => {
  const { mastV2 } = useFlags();

  return mastV2 ? (
    <QCard>
      <QCardHeader title={props.subText} />
      <QCardBody>
        <div className="h4 mb-0">
          {props.isLoading ? (
            <i className="fa-solid fa-spinner fa-spin" />
          ) : (
            props.leadText
          )}
        </div>
      </QCardBody>
    </QCard>
  ) : (
    <QCard>
      <QCardBody>
        <div className="h1 text-right mb-2">
          <i className={classnames('icon', props.icon)} />
        </div>
        <div className="h4 mb-0">
          {props.isLoading ? (
            <i className="fa-solid fa-spinner fa-spin" />
          ) : (
            props.leadText
          )}
        </div>
        <small
          className={`${props.lowercase ?? 'text-uppercase'} font-weight-bold`}
        >
          {props.subText}
        </small>
        <div className="progress-xs mt-3 mb-0 progress">
          <div
            className={`progress-bar w-100 ${props.bgClass}`}
            role="progressbar"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
      </QCardBody>
    </QCard>
  );
};

const StatCard = withLDConsumer()(LDStatCard);

export default StatCard;
