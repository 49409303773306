import React from 'react';
import PropTypes from 'prop-types';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import i18n from '../../localization/i18n';
import { QInputGroup } from '../Q-Components/QInputGroup';
import { QInput } from '../Q-Components/QInput';
import { QInputIconWrapper } from '../Q-Components/QInputIconWrapper';

const TableFilter = props => (
  <QInputGroup>
    <QInput
      variant="filled"
      placeholder={i18n.t('Filter')}
      className={props.className}
      onChange={props.changeFilter}
      onKeyPress={props.onKeyPress}
      defaultValue={props.defaultValue}
      InputProps={{
        startAdornment: (
          <QInputIconWrapper position="start">
            <FilterAltOutlinedIcon />
          </QInputIconWrapper>
        ),
      }}
    />
  </QInputGroup>
);

TableFilter.propTypes = {
  changeFilter: PropTypes.func.isRequired,
};

export default TableFilter;
