import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  page: 1,
  pageSize: { value: 10, label: '10' },
  platformFilter: { value: 'both', label: 'Both' },
  subGroupFilter: { value: 'all', label: 'All' },
  submissionTagFilter: { value: 'all', label: 'All' },
  sortBy: { value: 'dateSubmitted', label: 'Date Submitted' },
  // Used for the tag next to the search query, only set when a query happens
  currentSearchQuery: '',
  searchQuery: '',
  reviewStatusFilter: {
    value: 'pending',
    label: 'Pending',
    iconClass: 'fa-regular fa-ellipsis text-info',
  },
  statusFilter: { value: 'all', label: 'All' },
  testScriptFilter: { value: 'both', label: 'Both' },
  labelsFilter: { value: 'all', label: 'All' },
  labelOptions: [{ value: 'all', label: 'All' }],
};

export { initialState };

export const analyzedAppsSlice = createSlice({
  name: 'analyzedApps',
  initialState,
  reducers: {
    changePage: (state, action) => ({
      ...state,
      page: action.payload,
    }),
    changePageSize: (state, action) => ({
      ...state,
      pageSize: action.payload,
    }),
    changePlatformFilter: (state, action) => ({
      ...state,
      platformFilter: action.payload,
    }),
    changeSubGroupFilter: (state, action) => ({
      ...state,
      subGroupFilter: action.payload,
    }),
    changeSubmissionTagFilter: (state, action) => ({
      ...state,
      submissionTagFilter: action.payload,
    }),
    changeSortByColumn: (state, action) => ({
      ...state,
      sortBy: action.payload,
    }),
    changeCurrentSearchQuery: (state, action) => ({
      ...state,
      currentSearchQuery: action.payload,
    }),
    changeSearchQuery: (state, action) => ({
      ...state,
      searchQuery: action.payload,
    }),
    changeReviewStatusFilter: (state, action) => ({
      ...state,
      reviewStatusFilter: action.payload,
    }),
    changeStartDate: (state, action) => ({
      ...state,
      startDate: action.payload,
    }),
    changeEndDate: (state, action) => ({
      ...state,
      endDate: action.payload,
    }),
    changeStatusFilter: (state, action) => ({
      ...state,
      statusFilter: action.payload,
    }),
    changeTestScriptFilter: (state, action) => ({
      ...state,
      testScriptFilter: action.payload,
    }),
    changeLabelsFilter: (state, action) => ({
      ...state,
      labelsFilter: action.payload,
    }),
    updateLabelOptions: (state, action) => ({
      ...state,
      labelOptions: action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  changePage,
  changePageSize,
  changePlatformFilter,
  changeSubGroupFilter,
  changeSortByColumn,
  changeSubmissionTagFilter,
  changeCurrentSearchQuery,
  changeSearchQuery,
  changeReviewStatusFilter,
  changeStartDate,
  changeEndDate,
  changeStatusFilter,
  changeTestScriptFilter,
  changeLabelsFilter,
  updateLabelOptions,
} = analyzedAppsSlice.actions;

export default analyzedAppsSlice.reducer;
