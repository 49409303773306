/* eslint-disable prefer-template */
import React from 'react';
import { connect } from 'react-redux';
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Row,
  Col,
} from 'reactstrap';
import _ from 'lodash';

import i18n from '../../../../../localization/i18n';

const MissingCompileProtectionEvidence = ({ results }) => {
  const compileProtectionResults = _.get(
    results,
    'analysis.analysis_details.static_analysis.compile_protection_result',
    [],
  );

  return (
    <div>
      <ListGroup
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          maxHeight: '200px',
          overflow: 'scroll',
        }}
      >
        <ListGroupItemHeading style={{ fontSize: '13px' }}>
          <Row>
            <Col xs="8" className="text-left">
              &nbsp; &nbsp; &nbsp; {i18n.t('Binary')}
            </Col>
            <Col xs="1" className="text-center">
              {i18n.t('ARC')}
            </Col>
            <Col xs="1" className="text-center">
              {i18n.t('ASLR')}
            </Col>
            <Col xs="2" className="text-center">
              {i18n.t('Stack Smacking')}
            </Col>
          </Row>
        </ListGroupItemHeading>
        {compileProtectionResults.map(result => (
          <ListGroupItem>
            <Row>
              <Col xs="8">{result.binary}</Col>
              <Col xs="1" className="text-center">
                {result.arc ? (
                  <i className="fa-regular fa-circle-check text-success" />
                ) : (
                  <i className="fa-regular fa-circle-xmark text-danger" />
                )}
              </Col>
              <Col xs="1" className="text-center">
                {result.aslr ? (
                  <i className="fa-regular fa-circle-check text-success" />
                ) : (
                  <i className="fa-regular fa-circle-xmark text-danger" />
                )}
              </Col>
              <Col xs="2" className="text-center">
                {result.stack_smashing_protection ? (
                  <i className="fa-regular fa-circle-check text-success" />
                ) : (
                  <i className="fa-regular fa-circle-xmark text-danger" />
                )}
              </Col>
            </Row>
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  );
};

const mapStateToProps = ({ appResults }) => ({
  results: appResults.results,
});

export default connect(mapStateToProps)(MissingCompileProtectionEvidence);
