import React from 'react';
import { useSelector } from 'react-redux';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

export const NSUserDefaultsOperations = ({ nameFilter }) => {
  const nsUserDefaultsOperationsData =
    useSelector(
      state =>
        state.appResults.results.analysis.analysis_details.dynamic_analysis
          .nsuserdefaults_operations,
    ) || [];

  let filteredData = nsUserDefaultsOperationsData;

  if (nameFilter) {
    filteredData =
      nsUserDefaultsOperationsData?.filter(operation =>
        operation?.name?.includes(nameFilter),
      ) ?? [];
  }

  return (
    <DataTable
      data={filteredData}
      columns={[
        {
          label: i18n.t('Name'),
          keyName: 'name',
          sortable: true,
          filterable: true,
          width: '10%',
        },
        {
          label: i18n.t('Operation'),
          keyName: 'operation',
          sortable: true,
          filterable: true,
          width: '10%',
        },
        {
          label: i18n.t('Value'),
          keyName: 'value',
          sortable: true,
          filterable: true,
          width: '10%',
        },
      ]}
    />
  );
};
