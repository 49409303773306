import React, { Component } from 'react';

import DynamicAnalysisDatatable from '../../../Android/DynamicAnalysisDatatable';
import { Harviewer } from '../../../../Harviewer/Harviewer';
import TabsView from '../../../../TabsView';
import HighLevelIssues from '../../../HighLevelIssueMethods';

export default class PasswordExposed extends Component {
  render() {
    const tabs = [
      {
        title: 'Network Traffic',
        tabContent: () => <Harviewer filter="pass" showFilter={false} />,
      },
      {
        title: 'Dynamic Analysis',
        tabContent: () => (
          <DynamicAnalysisDatatable filter="pass" showFilter={false} />
        ),
      },
      {
        title: 'Methods',
        tabContent: () => (
          <HighLevelIssues name="password_exposed" showFilter={false} />
        ),
      },
    ];
    return <TabsView tabs={tabs} />;
  }
}
