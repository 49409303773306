import React from 'react';
import { connect } from 'react-redux';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

const parseLibraries = libraries => {
  const parsedLibs = [];
  Object.keys(libraries).forEach(library => {
    parsedLibs.push({ library, version: libraries[library].version });
  });

  return parsedLibs;
};

const SystemLibrariesDatatable = ({
  results,
  filter = '',
  showFilter = true,
}) => {
  const { 'System Libraries': libraries } =
    results.analysis.analysis_details.static_analysis.libraries_used;
  return (
    <DataTable
      rowsPerPage={5}
      data={parseLibraries(libraries)}
      showFilter={showFilter}
      columns={[
        { keyName: 'library', label: i18n.t('Library'), filterable: true },
        { keyName: 'version', label: i18n.t('Version') },
      ]}
      filter={filter}
    />
  );
};

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(SystemLibrariesDatatable);
