import React from 'react';
import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap';

import i18n from '../../../localization/i18n';
import SystemLibrariesDatatable from '../iOS/SystemLibrariesDatatable';
import SystemFrameworksDatatable from '../iOS/SystemFrameworksDatatable';

export const IosSBOMTable = () => (
  <>
    <Row>
      <Col>
        <Card className="card-accent-navy">
          <CardHeader>{i18n.t('System Frameworks Used')}</CardHeader>
          <CardBody>
            <SystemFrameworksDatatable />
          </CardBody>
        </Card>
      </Col>
      <Col>
        <Card className="card-accent-navy">
          <CardHeader>{i18n.t('System Libraries Used')}</CardHeader>
          <CardBody>
            <SystemLibrariesDatatable />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </>
);
