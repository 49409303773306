import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QTypography } from '../Q-Components/QTypography';
import { QDoughnut } from '../Q-Components/QDoughnut';

export const IorsChartCard = ({ results, title = 'IORs' }) => {
  const { mastV2 } = useFlags();

  const getNumberOfIorsBasedOnRiskLevel = (results, level) =>
    results.reduce((acc, item) => (item.risk === level ? acc + 1 : acc), 0);

  const numberOfNoteIors = getNumberOfIorsBasedOnRiskLevel(results, 'note');

  const numberOfWarningIors = getNumberOfIorsBasedOnRiskLevel(
    results,
    'warning',
  );

  const numberOfErrorIors = getNumberOfIorsBasedOnRiskLevel(results, 'error');

  const chartData = {
    labels: [`Error`, `Warning`, `Note`],
    datasets: [
      {
        data: [numberOfErrorIors, numberOfWarningIors, numberOfNoteIors],
        backgroundColor: ['#F44336', '#FF9800', '#2196F3'],
      },
    ],
  };

  return (
    <QCard style={{ height: '100%' }}>
      <QCardHeader
        tag="h4"
        title={<QTypography variant="h5Medium">{title}</QTypography>}
        style={mastV2 ? {} : { backgroundColor: '#FFFFFF' }}
      >
        {title}
      </QCardHeader>
      <QCardBody
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '350px',
        }}
      >
        <QDoughnut data={chartData} options={{ maintainAspectRatio: false }} />
      </QCardBody>
    </QCard>
  );
};
