import React, { Component } from 'react';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { connect } from 'react-redux';

import i18n from '../../../localization/i18n';

class AnalyticsNetworks extends Component {
  getAnalyticsNetworks(issues) {
    for (const issue of issues) {
      if (issue.name === 'tracking_present') {
        return issue.description.split(' | ');
      }
    }
    return [];
  }

  render() {
    const { results } = this.props;

    const highLevelIssues = _.get(results, 'analysis.high_level_issues.issues');
    if (!highLevelIssues) {
      return null;
    }

    const analyticsNetworks = this.getAnalyticsNetworks(highLevelIssues);

    return (
      <Card className="card-accent-warning">
        <CardHeader>{i18n.t('Analytics Networks')}</CardHeader>
        <CardBody>
          {analyticsNetworks.length === 0 ? (
            <span>{i18n.t('No analytics networks detected')}</span>
          ) : (
            <ul>
              {analyticsNetworks
                .filter(network => network)
                .map((network, index) => (
                  <li key={index}>{network}</li>
                ))}
            </ul>
          )}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(AnalyticsNetworks);
