import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
// TODO: change the language depending on the user's language choice
countries.registerLocale(enLocale);

const allCountries = countries.getNames('en');

// Override some country names
allCountries.TW = 'Taiwan';
allCountries.CI = "Côte d'Ivoire";
allCountries.KR = 'Republic of Korea';
allCountries.NL = 'The Netherlands';
allCountries.PN = 'Pitcairn Islands';

export const getCodeFromCountryName = name =>
  countries.getAlpha2Code(name, 'en');

export const getCountryNameFromCode = code => allCountries[code];

export const countriesDataOptions = Object.keys(allCountries)
  .map(code => ({
    label: allCountries[code],
    value: code,
  }))
  .sort((a, b) => a.label.localeCompare(b.label));
