import React from 'react';

import DataTable from '../../../DataTable/DataTable';

export default function NetworkEncryption({ data }) {
  return (
    <DataTable
      data={data}
      columns={[
        { label: 'Domain', keyName: 'SNI' },
        { label: 'Cipher Name', keyName: 'cipher_name' },
        { label: 'TLS Version', keyName: 'tls_version' },
      ]}
    />
  );
}
