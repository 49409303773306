import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Add from '@mui/icons-material/Add';

import { QPopover } from '../../Q-Components/QPopover';
import { QPopoverBody } from '../../Q-Components/QPopoverBody';
import { QPopoverHeader } from '../../Q-Components/QPopoverHeader';
import { QLabel } from '../../Q-Components/QLabel';
import { QInput } from '../../Q-Components/QInput';
import { QButton } from '../../Q-Components/QButton';
import { QTypography } from '../../Q-Components/QTypography';
import { QCol } from '../../Q-Components/QCol';
import { QRow } from '../../Q-Components/QRow';
import { QModal } from '../../Q-Components/QModal';
import { QModalTitle } from '../../Q-Components/QModalTitle';
import { QModalContent } from '../../Q-Components/QModalContent';
import { QModalActions } from '../../Q-Components/QModalActions';

const Title = () => (
  <QTypography variant="h3Medium">Create New Group</QTypography>
);

const Body = ({ name, setName }) => (
  <>
    <QCol>
      <QRow>
        <QLabel htmlFor="create-new-group">
          <QTypography variant="h5Medium">Name</QTypography>
        </QLabel>
      </QRow>
      <QRow>
        <QInput
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="Group Name"
          id="create-new-group"
          fullWidth
        />
      </QRow>
    </QCol>
  </>
);

const Actions = ({
  mastV2,
  setName,
  setEditing,
  createNewGroup,
  name,
  toggle,
}) => (
  <>
    {mastV2 && (
      <QButton variant="outlined" color="secondary" onClick={toggle}>
        Cancel
      </QButton>
    )}
    <QButton
      className="mt-2"
      block
      onClick={() => {
        setName('');
        setEditing(false);
        createNewGroup(name);
      }}
      color="primary"
      disabled={!name}
      variant="filled"
    >
      {!mastV2 && <i className="fa-regular fa-floppy-disk mr-1" />}Create
    </QButton>
  </>
);

export const NewGroup = ({ createNewGroup }) => {
  const [name, setName] = useState('');
  const [editing, setEditing] = useState(false);
  const { mastV2 } = useFlags();

  const toggle = () => setEditing(!editing);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {mastV2 ? (
        <>
          <div style={{ width: '100px' }}>
            <QModal
              isOpen={editing}
              open={editing}
              toggle={toggle}
              title={
                <QModalTitle onClose={toggle} closeIcon={<CloseIcon />}>
                  <Title />
                </QModalTitle>
              }
              content={
                <QModalContent sx={{ padding: '16px 29px 29px 16px' }}>
                  <Body name={name} setName={setName} />
                </QModalContent>
              }
              actions={
                <QModalActions sx={{ padding: '0px 32px 16px 0px' }}>
                  <Actions
                    name={name}
                    setName={setName}
                    setEditing={setEditing}
                    createNewGroup={createNewGroup}
                    toggle={toggle}
                    mastV2={mastV2}
                  />
                </QModalActions>
              }
            />
            <QButton
              size="sm"
              color="link"
              id="create-new-group"
              variant="outlined"
              startIcon={<Add />}
              onClick={() => setEditing(!editing)}
              sx={{ minWidth: '140px' }}
            >
              New Group
            </QButton>
          </div>
        </>
      ) : (
        <>
          <QPopover
            placement="left"
            isOpen={editing}
            target="create-new-group"
            toggle={toggle}
          >
            <QPopoverHeader>
              <Title />
            </QPopoverHeader>
            <QPopoverBody>
              <div>
                <Body name={name} setName={setName} />
              </div>
              <div className="mt-2">
                <Actions
                  name={name}
                  setName={setName}
                  setEditing={setEditing}
                  createNewGroup={createNewGroup}
                  toggle={toggle}
                  mastV2={mastV2}
                />
              </div>
            </QPopoverBody>
          </QPopover>
          <QButton
            size="sm"
            color="link"
            className="pointer black-icon"
            id="create-new-group"
            variant="outlined"
            startIcon={<Add />}
            onClick={() => setEditing(!editing)}
          >
            {mastV2 ? <></> : <i className="fa-regular fa-circle-plus" />} New
            Group
          </QButton>
        </>
      )}
    </div>
  );
};
