import React from 'react';
import { CardText } from 'reactstrap';

import DynamicAnalysisDatatable from '../../Android/DynamicAnalysisDatatable';
import { StaticAnalysisDatatable } from '../../Android/StaticAnalysisDatatable';

export const V6_8 = ({ platform }) => (
  <>
    {platform === 'android' && (
      <>
        <CardText>
          <strong>Dynamic Analysis</strong>
        </CardText>
        <DynamicAnalysisDatatable
          methodFilter={['ObjectInputStream', 'readObject']}
        />
        <hr />
        <CardText>
          <strong>Storage IO</strong>
        </CardText>
        <StaticAnalysisDatatable
          categoryFilter="methods"
          keyFilter="storageIO"
          methodFilter={/(ObjectInputStream|readObject)/g}
        />
      </>
    )}
  </>
);
