import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { toCamel } from 'snake-camel';
import { useFlags } from 'launchdarkly-react-client-sdk';

import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';
import Switch from '../Switch';
import SubGroups from '../Settings/GroupAdmin/SubGroups';
import ConfirmationModal from '../Modals/ConfirmationModal';
import {
  getPermissionsConfig,
  checkSpecialPermissionVisibility,
  checkFeatureFlagPermissionVisibility,
} from '../Settings/GroupAdmin/PermissionsConfig';
import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QTypography } from '../Q-Components/QTypography';
import { QCardBody } from '../Q-Components/QCardBody';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QInput } from '../Q-Components/QInput';
import { QInputGroup } from '../Q-Components/QInputGroup';
import { QSwitch } from '../Q-Components/QSwitch';
import { QSwitchControlLabel } from '../Q-Components/QSwitchControlLabel';
import { QButton } from '../Q-Components/QButton';
import { QInputIconWrapper } from '../Q-Components/QInputIconWrapper';
import { QUncontrolledTooltip } from '../Q-Components/QUncontrolledTooltip';

import { WhitelistIps } from './WhitelistIps';

export function EditGroupUser({ kaiAccess = false }) {
  const { userId } = useParams();
  const [groupUser, setGroupUser] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const flags = useFlags();
  const { mastV2 } = flags;

  const { general } = getPermissionsConfig();
  const { analysis } = getPermissionsConfig();
  const { reports } = getPermissionsConfig();

  const isAdmin = groupUser ? groupUser?.userAccess?.groupAdmin : false;

  const adminUserAccess = toCamel(
    useSelector(({ emmApp }) => emmApp.userAccess),
  );

  const getUserData = async () => {
    try {
      const response = await axios.get(`group-admin/users/${userId}`);
      setGroupUser(toCamel(response.data.user));
    } catch (err) {
      console.log('Error getting group user data:', err);
    }
  };

  useEffect(() => {
    getUserData();
  }, [userId]);

  const locked = _.get(groupUser, 'locked') || false;

  const [showResendConfirm, setShowResendConfirm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showPermissionConfirm, setShowPermissionConfirm] = useState(false);
  const [access, setAccess] = useState(null);

  const setUserLocked = async isLocked => {
    try {
      await axios.put(`group-admin/user/${groupUser.id}/locked`, {
        isLocked,
      });
      toastrHelper.showSuccessToast(
        i18n.t(`Successfully ${isLocked ? 'locked' : 'unlocked'} user`),
        'Success',
        mastV2,
      );
    } catch (err) {
      toastrHelper.showWarningToast(
        i18n.t(`Error setting ${isLocked ? 'locked' : 'unlocked'} status`),
        'Error',
        mastV2,
      );
    }

    getUserData();
  };

  const setUserAccess = async (accessName, accessValue) => {
    try {
      // toSnake only converts full objects, won't convert a singular string like this
      const snakedAccessName = accessName
        .split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase();

      await axios.put(`group-admin/user/${groupUser.id}/access`, {
        accessName: snakedAccessName,
        accessValue,
      });
      toastrHelper.showSuccessToast(
        i18n.t('Successfully set user access'),
        'Success',
        mastV2,
      );
    } catch (err) {
      toastrHelper.showWarningToast(
        i18n.t('Error setting access'),
        'Error',
        mastV2,
      );
    }

    getUserData();
  };

  const updateApiKey = async () => {
    try {
      await axios.put(`group-admin/users/${userId}/new-api-key`);
      toastrHelper.showSuccessToast(
        'Successfully updated API Key',
        'Success',
        mastV2,
      );
    } catch (err) {
      toastrHelper.showWarningToast('Error updating API Key', 'Error', mastV2);
    }

    getUserData();
  };

  const resendInvite = async () => {
    try {
      await axios.get(`group-admin/user/${groupUser.id}/resend-invite`);
      toastrHelper.showSuccessToast(
        i18n.t('Successfully resent invite email'),
        'Success',
        mastV2,
      );
    } catch (err) {
      console.log('Error resending email:', err);
      toastrHelper.showErrorToast(
        i18n.t('Error resending invite email'),
        'Error',
        mastV2,
      );
    }

    getUserData();
  };

  const toggleApiKeyLock = async isDisabled => {
    try {
      await axios.put(`group-admin/user/${groupUser.id}/api-key-disabled`, {
        isDisabled,
      });
      toastrHelper.showSuccessToast(
        i18n.t('Successfully updated api locked status'),
        'Success',
        mastV2,
      );
    } catch (err) {
      toastrHelper.showWarningToast(
        'Error setting api locked status',
        'Error',
        mastV2,
      );
    }

    getUserData();
  };

  const isInactive = () => {
    const daysSinceLastLogin = groupUser.lastLogin
      ? moment().diff(moment(groupUser.lastLogin), 'days')
      : 100000;

    const daysSinceLastApiKeyUsage = groupUser.apiKeyLastUsed
      ? moment().diff(moment(groupUser.apiKeyLastUsed), 'days')
      : 100000;
    console.log('Days since last login:', daysSinceLastLogin);
    console.log('Days since last api key usage:', daysSinceLastApiKeyUsage);
    return daysSinceLastLogin > 90 && daysSinceLastApiKeyUsage > 90;
  };

  const permissionConfirmation = async (
    type,
    label,
    accessName,
    accessValue,
  ) => {
    setShowPermissionConfirm(true);
    setAccess({ type, label, accessName, accessValue });
  };

  const deletionConfirmation = async () => {
    try {
      await axios.delete(`group-admin/user/${groupUser.id}`);
      toastrHelper.showSuccessToast(
        'Successfully removing user',
        'Success',
        mastV2,
      );
      getUserData();
      if (location.pathname === `/settings/group-admin/users/${groupUser.id}`) {
        history.replace('/settings/group-admin/users');
      } else if (location.pathname === `/kai/users/${groupUser.id}`) {
        history.replace('/kai/users/');
      }
    } catch (err) {
      toastrHelper.showErrorToast('Error removing user', 'Error', mastV2);
    }
    setShowDeleteConfirm(false);
  };

  return (
    <QCard className="card-accent-primary">
      <ConfirmationModal
        isOpen={showResendConfirm}
        toggle={() => setShowResendConfirm(!showResendConfirm)}
        confirmHeader={i18n.t('Resend Invite')}
        confirmBody={i18n.t(
          'Do you want to resend this user their invite email?',
        )}
        confirm={() => {
          setShowResendConfirm(false);
          resendInvite();
        }}
      />
      {groupUser && (
        <ConfirmationModal
          isOpen={showDeleteConfirm}
          toggle={() => setShowDeleteConfirm(!showDeleteConfirm)}
          confirmHeader={i18n.t('Remove User')}
          confirmBody={i18n.t(
            `Are you sure to remove this user: ${groupUser.email}?`,
          )}
          confirm={() => deletionConfirmation()}
        />
      )}

      {access && (
        <ConfirmationModal
          isOpen={showPermissionConfirm}
          toggle={() => setShowPermissionConfirm(!showPermissionConfirm)}
          confirmHeader={i18n.t('Update Permission')}
          confirmBody={
            access.type === 'lock'
              ? i18n.t(
                  `Are you sure to ${
                    access.accessValue ? 'lock' : 'unlock'
                  } user`,
                )
              : i18n.t(
                  `Are you sure to ${
                    access.accessValue ? 'enable' : 'disable'
                  } ${access.label} permission for user?`,
                )
          }
          confirm={() => {
            setShowPermissionConfirm(false);
            return access.type === 'lock'
              ? setUserLocked(access.accessValue)
              : setUserAccess(access.accessName, access.accessValue);
          }}
        />
      )}

      <QCardHeader title={i18n.t('Edit User')}>
        <strong>{i18n.t('Edit User')}</strong>
      </QCardHeader>

      <QCardBody>
        {groupUser ? (
          <>
            <QRow>
              <QCol>
                <QTypography variant="h6">
                  {isInactive() && (
                    <>
                      <i
                        className="fa-solid fa-exclamation-circle fa-lg danger-icon mr-1"
                        id="inactive-icon"
                      />
                      <QUncontrolledTooltip
                        placement="top"
                        target="inactive-icon"
                        title="User has been inactive for over 45 days"
                      />
                    </>
                  )}
                  {groupUser.email}{' '}
                  {kaiAccess && (
                    <>
                      <strong>(ID: </strong>
                      {groupUser.id})
                    </>
                  )}
                </QTypography>
              </QCol>
            </QRow>
            <hr />
            <QRow gap={2}>
              <QCol width="50%">
                <QTypography>
                  <strong>API Key</strong>
                </QTypography>
                <QInputGroup>
                  <QInput
                    type="text"
                    value={
                      groupUser.apiKeyDisabled
                        ? '--DISABLED--'
                        : groupUser.apiKey
                    }
                    disabled
                    InputProps={{
                      endAdornment: (
                        <QInputIconWrapper position="end">
                          <QButton
                            variant="text"
                            color="warning"
                            onClick={() => {
                              toggleApiKeyLock(!groupUser.apiKeyDisabled);
                            }}
                            startIcon={
                              <i
                                className={`fa-solid ${
                                  groupUser.apiKeyDisabled
                                    ? 'fa-lock'
                                    : 'fa-lock-open'
                                }`}
                              />
                            }
                          >
                            {groupUser.apiKeyDisabled ? 'Enable' : 'Disable'}
                          </QButton>
                          <QButton
                            variant="filled"
                            color="primary"
                            onClick={async () => {
                              updateApiKey();
                            }}
                            disabled={groupUser.apiKeyDisabled}
                            startIcon={<i className="fa-regular fa-refresh" />}
                          >
                            Generate New API Key
                          </QButton>
                        </QInputIconWrapper>
                      ),
                    }}
                  />
                  {!mastV2 && (
                    <>
                      <QButton
                        variant="filled"
                        color="warning"
                        onClick={() => {
                          toggleApiKeyLock(!groupUser.apiKeyDisabled);
                        }}
                      >
                        <i
                          className={`fa-solid ${
                            groupUser.apiKeyDisabled
                              ? 'fa-lock'
                              : 'fa-lock-open'
                          }`}
                        />{' '}
                        {groupUser.apiKeyDisabled ? 'Enable' : 'Disable'}
                      </QButton>
                      <QButton
                        variant="filled"
                        color="primary"
                        onClick={async () => {
                          updateApiKey();
                        }}
                        disabled={groupUser.apiKeyDisabled}
                      >
                        <i className="fa-regular fa-refresh" /> Generate New API
                        Key
                      </QButton>
                    </>
                  )}
                </QInputGroup>
              </QCol>
              <QCol width="50%">
                <QRow>
                  <QTypography>
                    <strong>Last Used</strong>
                  </QTypography>
                </QRow>
                <QRow>
                  <QTypography>
                    {groupUser.apiKeyLastUsed
                      ? moment(groupUser.apiKeyLastUsed).format('L - LTS')
                      : 'N/A'}
                  </QTypography>
                </QRow>
              </QCol>
            </QRow>
            <QRow className="mt-2">
              <QCol xs="8">
                <WhitelistIps groupUser={groupUser} getUserData={getUserData} />
              </QCol>
            </QRow>

            <hr />

            <QRow gap={2}>
              <QCol width="50%">
                <QTypography>
                  <strong>{i18n.t('Access')}</strong>
                </QTypography>
                <hr />

                <QTypography className="text-muted text-uppercase font-weight-bold">
                  General
                </QTypography>

                {(kaiAccess || !isAdmin) && (
                  <QRow>
                    <QSwitchControlLabel
                      label={<QTypography fontWeight={700}>Locked</QTypography>}
                      control={
                        <QSwitch
                          checked={locked}
                          onChange={() =>
                            permissionConfirmation(
                              'lock',
                              'Locked',
                              null,
                              !locked,
                            )
                          }
                        />
                      }
                    >
                      <QCol>
                        <Switch
                          datatestid="Locked"
                          value={locked}
                          label="Locked"
                          onChange={() =>
                            permissionConfirmation(
                              'lock',
                              'Locked',
                              null,
                              !locked,
                            )
                          }
                        />
                      </QCol>
                    </QSwitchControlLabel>
                  </QRow>
                )}
                {general.map(([label, privilege]) => {
                  // eslint-disable-next-line
                  const value = groupUser?.userAccess?.[privilege] ?? false;

                  const { disabled, hidden } = checkSpecialPermissionVisibility(
                    {
                      permissionName: privilege,
                      doesTargetAdminHasPermission: isAdmin && value,
                      doesCurrentAdminHasPermission: adminUserAccess[privilege],
                      kaiAccess,
                    },
                  );

                  if (hidden) {
                    return null;
                  }

                  return (
                    <QRow>
                      <QSwitchControlLabel
                        label={
                          <QTypography fontWeight={700}>
                            {i18n.exists(label) ? i18n.t(label) : label}
                          </QTypography>
                        }
                        control={
                          <QSwitch
                            key={label}
                            checked={value}
                            onChange={() =>
                              permissionConfirmation(
                                'access',
                                label,
                                privilege,
                                !value,
                              )
                            }
                            disabled={disabled}
                          />
                        }
                      >
                        <QCol>
                          <Switch
                            datatestid={label}
                            key={label}
                            value={value}
                            label={label}
                            onChange={() =>
                              permissionConfirmation(
                                'access',
                                label,
                                privilege,
                                !value,
                              )
                            }
                          />
                        </QCol>
                      </QSwitchControlLabel>
                    </QRow>
                  );
                })}
                <QTypography className="text-muted text-uppercase font-weight-bold">
                  Analysis
                </QTypography>
                {analysis.map(([label, privilege]) => {
                  // eslint-disable-next-line
                  const value = groupUser?.userAccess?.[privilege] ?? false;
                  return (
                    <QRow>
                      <QSwitchControlLabel
                        label={
                          <QTypography fontWeight={700}>{label}</QTypography>
                        }
                        control={
                          <QSwitch
                            datatestid={label}
                            checked={value}
                            onChange={() =>
                              permissionConfirmation(
                                'access',
                                label,
                                privilege,
                                !value,
                              )
                            }
                          />
                        }
                      >
                        <QCol>
                          <Switch
                            datatestid={label}
                            key={label}
                            value={value}
                            label={label}
                            onChange={() =>
                              permissionConfirmation(
                                'access',
                                label,
                                privilege,
                                !value,
                              )
                            }
                          />
                        </QCol>
                      </QSwitchControlLabel>
                    </QRow>
                  );
                })}

                <QTypography className="text-muted text-uppercase font-weight-bold">
                  Reports
                </QTypography>
                {reports.map(([label, privilege]) => {
                  const isVisible = checkFeatureFlagPermissionVisibility({
                    permissionName: privilege,
                    flags,
                  });

                  if (!isVisible) {
                    return null;
                  }

                  // eslint-disable-next-line
                  const value = groupUser?.userAccess?.[privilege] ?? false;
                  return (
                    <QRow>
                      <QSwitchControlLabel
                        label={
                          <QTypography fontWeight={700}>{label}</QTypography>
                        }
                        control={
                          <QSwitch
                            checked={value}
                            key={label}
                            onChange={() =>
                              permissionConfirmation(
                                'access',
                                label,
                                privilege,
                                !value,
                              )
                            }
                          />
                        }
                      >
                        <QCol>
                          <Switch
                            key={label}
                            value={value}
                            label={label}
                            onChange={() =>
                              permissionConfirmation(
                                'access',
                                label,
                                privilege,
                                !value,
                              )
                            }
                          />
                        </QCol>
                      </QSwitchControlLabel>
                    </QRow>
                  );
                })}
              </QCol>

              <QCol width="50%">
                <SubGroups
                  groupUser={groupUser}
                  getUserData={getUserData}
                  kaiAccess={kaiAccess}
                />
              </QCol>
            </QRow>

            <hr />

            <QTypography>
              <strong>{i18n.t('Actions')}</strong>
            </QTypography>
            <QCol style={{ marginTop: 20 }}>
              <QRow>
                <QButton
                  variant="outlined"
                  color="primary"
                  onClick={() => setShowResendConfirm(true)}
                  disabled={!!groupUser.lastLogin}
                  style={{ width: '200px' }}
                >
                  <i className="fa-solid fa-arrows-rotate mr-2" />{' '}
                  {i18n.t('Resend invite')}
                </QButton>
              </QRow>
              <QRow>
                <QButton
                  data-testid="remove-user"
                  variant="filled"
                  color="danger"
                  onClick={() => setShowDeleteConfirm(true)}
                  disabled={isAdmin}
                  style={{ width: '200px', marginTop: 10 }}
                >
                  <i className="fa-solid fa-user-slash mr-2" />{' '}
                  {i18n.t('Remove User')}
                </QButton>
              </QRow>
            </QCol>

            <hr />
          </>
        ) : (
          <QTypography>
            {i18n.t('Please select a user from the table to the left.')}
          </QTypography>
        )}
      </QCardBody>
    </QCard>
  );
}
