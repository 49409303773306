import React from 'react';
import { ListGroup } from 'reactstrap';
import List from '@mui/material/List';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

const LDListGroup = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <List {...props}>{children}</List>
  ) : (
    <ListGroup {...props}>{children}</ListGroup>
  );
};

export const QListGroup = withLDConsumer()(LDListGroup);
