import React from 'react';

import { QBadge } from './Q-Components/QBadge';

export const ThreatScoreBadge = ({ threatScore }) => {
  let color;
  let variant;

  const threatScoreFloat = parseFloat(threatScore);

  if (threatScoreFloat > 75.0) {
    color = 'danger';
  } else if (threatScoreFloat > 35.0) {
    color = 'warning';
  } else {
    color = 'info';
  }

  if (threatScoreFloat >= 90.0) {
    variant = 'maroon';
  } else if (threatScoreFloat >= 75.0) {
    variant = 'red';
  } else if (threatScoreFloat >= 35.0) {
    variant = 'orange';
  } else {
    variant = 'blue';
  }

  return (
    <QBadge
      color={color}
      className="threat-score-badge"
      label={threatScore}
      variant={variant}
    >
      {threatScore}
    </QBadge>
  );
};
