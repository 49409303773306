import React, { Component } from 'react';
import {
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Harviewer } from '../Harviewer/Harviewer';
import i18n from '../../localization/i18n';
import util from '../../logic/util';

import CountriesContactedList from './CountriesContactedList';
import AdNetworksList from './Android/AdNetworksList';
import AnalyticsNetworks from './Android/AnalyticsNetworks';
import { Cookies } from './iOS/Cookies';
import HostsContactedMap from './HostsContactedMap';
import UrlScanningData from './UrlScanningData';
import AppTransportSecurity from './iOS/AppTransportSecurity';
import SSLPinning from './SSLPinning';

class NetworkInfo extends Component {
  parseAndroidMarkers(latLongData, whoisInfo) {
    let totalLat = 0;
    let totalLong = 0;

    const markers = Object.entries(latLongData)
      .map(([host, latLong]) => {
        if (!latLong.lat || !latLong.long) return null;

        totalLat += latLong.lat;
        totalLong += latLong.long;

        return {
          host,
          latLong: [latLong.lat, latLong.long],
          ip: whoisInfo[host] ? whoisInfo[host].ip : null,
          country: whoisInfo[host] ? whoisInfo[host].country : null,
        };
      })
      .filter(marker => marker);

    return { markers, totalLat, totalLong };
  }

  parseIosMarkers(latLongData) {
    if (!latLongData) {
      return {};
    }

    let totalLat = 0;
    let totalLong = 0;

    // Combine the sections of URL constants and dynamic connections
    const totalConnections = [
      ...Object.values(latLongData.url_constants),
      ...Object.values(latLongData.connections),
    ];

    const markers = totalConnections
      .map(connection => {
        if (!connection.latitude || !connection.longitude) return null;

        totalLat += connection.latitude;
        totalLong += connection.longitude;

        return {
          host: connection.destination
            ? connection.destination
            : connection.uri,
          latLong: [connection.latitude, connection.longitude],
          ip: connection.ip_address ? connection.ip_address : null,
          country: connection.country.geolocation_data
            ? connection.geolocation_data.country_name
            : connection.country
            ? connection.country
            : null,
        };
      })
      .filter(connection => connection); // Filter out any values that are null

    return { markers, totalLat, totalLong };
  }

  render() {
    const uuid = this.props.match.params.farmAppId;
    const { platform, showMap, results, niapResults, isPcapAvailable } =
      this.props;

    if (!showMap) return null;

    let cookies = null;
    if (platform === 'ios') {
      cookies = results.analysis.analysis_details.dynamic_analysis.cookies;
    }

    // Parse out the lat/long data from the analysis results
    // Also get the average lat/long to pick a reasonable center on the map
    const latLongData =
      platform === 'android'
        ? results.analysis.high_level_issues.lat_long_data
        : results.analysis.high_level_issues.geolocation_info;

    const whoisInfo =
      platform === 'android'
        ? results.analysis.high_level_issues.whois_info
        : null;

    const { markers, totalLat, totalLong } =
      platform === 'android'
        ? this.parseAndroidMarkers(latLongData, whoisInfo)
        : this.parseIosMarkers(latLongData);

    let numEntries;
    let mapCenter;
    if (markers) {
      numEntries = markers.length;
      mapCenter = [totalLat / numEntries, totalLong / numEntries];
    }

    return (
      <Row>
        <Col xs="5">
          {/* IP location map */}
          <Card className="card-accent-success">
            <CardHeader>{i18n.t('Network Map')}</CardHeader>
            <CardBody>
              <HostsContactedMap
                markers={markers}
                showMap={showMap}
                mapCenter={mapCenter}
              />
            </CardBody>
          </Card>

          {platform === 'ios' &&
            niapResults &&
            (niapResults.niap_sections ||
              (niapResults.niap && niapResults.niap.niap_sections)) && (
              <Card className="card-accent-primary">
                <CardHeader>{i18n.t('App Transport Security')}</CardHeader>
                <CardBody>
                  <AppTransportSecurity />
                </CardBody>
              </Card>
            )}
        </Col>

        {/* GSB Results */}
        <Col xs="7">
          <Row>
            <Col>
              <Card className="card-accent-warning-2">
                <CardHeader>{i18n.t('URL Scanning')}</CardHeader>
                <CardBody>
                  <UrlScanningData platform={platform} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="card-accent-warning">
                <CardHeader>
                  {i18n.t('Countries Contacted')}{' '}
                  <i
                    className="fa-solid fa-circle-info ml-1"
                    id="CountriesContactedTooltip"
                  />{' '}
                  <UncontrolledTooltip
                    placement="right"
                    target="CountriesContactedTooltip"
                  >
                    These countries were contacted by the application during
                    dynamic analysis
                  </UncontrolledTooltip>
                </CardHeader>
                <CardBody>
                  <CountriesContactedList platform={platform} />
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="card-accent-warning">
                <CardHeader>{i18n.t('Ad Networks')}</CardHeader>
                <CardBody>
                  <AdNetworksList platform={platform} />
                </CardBody>
              </Card>
            </Col>

            {platform === 'android' && (
              <Col>
                <AnalyticsNetworks />
              </Col>
            )}
          </Row>
        </Col>

        {cookies && (
          <Col xs="12">
            <Cookies cookies={cookies} />
          </Col>
        )}

        {/* SSL Pinning */}
        <Col xs="12">
          <SSLPinning />
        </Col>
        {/* HTTP/S Traffic */}
        <Col xs="12">
          <Card className="card-accent-pink">
            <CardHeader>{i18n.t('Network Traffic')}</CardHeader>
            <CardBody>
              <div className="text-right mb-3">
                <Button
                  color="primary"
                  outline
                  onClick={() => util.getResults({ uuid }, 'network_pcap')}
                  disabled={!isPcapAvailable}
                >
                  <i className="fa-regular fa-wifi" /> {i18n.t('Download PCAP')}
                </Button>
              </div>
              <Harviewer />
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  niapResults: appResults.niapResults,
  lang: emmApp.portalConfig.lang,
  isPcapAvailable: appResults.availableResults.pcap,
});

export default withRouter(connect(mapStateToProps)(NetworkInfo));
