import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import DOMPurify from 'dompurify';

import { QAlert } from '../Q-Components/QAlert';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QTypography } from '../Q-Components/QTypography';

export const Banner = ({
  title,
  severity = 'info',
  message,
  onClose,
  isOpen,
  toggle,
}) => {
  const { mastV2 } = useFlags();

  const renderMessage = text => {
    if (typeof text === 'string') {
      const sanitizedHTML = DOMPurify.sanitize(text);
      return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
    }

    return text;
  };

  const getAlertColor = severity => severity?.toLowerCase() || 'info';
  const getAlertIcon = {
    info: 'fa-circle-info',
    warning: 'fa-exclamation-circle',
    success: 'fa-circle-check',
    error: 'fa-ban-circle',
  };

  return (
    <QRow sx={{ mb: 2 }}>
      <QCol sx={{ width: '100%' }}>
        <QAlert
          variant="outlined"
          severity={getAlertColor(severity)}
          color={getAlertColor(severity)}
          onClose={onClose}
          isOpen={isOpen}
          toggle={toggle}
        >
          <QTypography component="h6" variant="h6Bold">
            {!mastV2 ? (
              <span>
                <h6>
                  <i
                    className={`fa-solid ${
                      getAlertIcon[getAlertColor(severity)]
                    } mr-2`}
                  />
                  <strong>{title}</strong>
                </h6>
              </span>
            ) : (
              title
            )}
          </QTypography>
          <QTypography component="p" variant="h6">
            <p>{renderMessage(message)}</p>
          </QTypography>
        </QAlert>
      </QCol>
    </QRow>
  );
};
