import groupAdminService from '../../services/groupAdmin';
import {
  setGroupAdminUsers,
  setGroupAdminSubGroups,
  setGroupAdminGroup,
} from '../slices/emmAppSlice';

export const fetchGroupAdminUsers = () => async dispatch => {
  const users = await groupAdminService.getGroupAdminUsers();
  dispatch(setGroupAdminUsers(users));
  return users;
};

export const fetchGroupAdminSubGroups = () => async dispatch => {
  const subGroups = await groupAdminService.getGroupAdminSubGroups();
  dispatch(setGroupAdminSubGroups(subGroups));
  return subGroups;
};

export const fetchGroupAdminGroup = () => async dispatch => {
  const group = await groupAdminService.getGroupAdminGroup();
  dispatch(setGroupAdminGroup(group));
  return group;
};
