import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { useSelector } from 'react-redux';

import DataTable from '../../DataTable/DataTable';

function KeyChainOperations() {
  const [showDataDetail, setShowDataDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState({});

  const keyChain = useSelector(
    state =>
      state.appResults.results.analysis.analysis_details.dynamic_analysis
        .keychain_operations,
  );

  function handleRowClick(value) {
    setShowDataDetail(true);
    setDataDetail(value);
    console.log(value);
  }

  return (
    <div>
      <Modal
        isOpen={showDataDetail}
        size="lg"
        toggle={() => setShowDataDetail(!showDataDetail)}
      >
        <ModalHeader>Key Chain Operations Detail</ModalHeader>
        <ModalBody>
          <p className="bold">Account Name</p>
          <p>{dataDetail.kSecAttrAccount}</p>
          <p className="bold">Service</p>
          <p>{dataDetail.kSecAttrService}</p>
          <p className="bold">Accessibility</p>
          {dataDetail.kSecAttrAccessible ? (
            <p>{dataDetail.kSecAttrAccessible}</p>
          ) : (
            <p>None</p>
          )}
          <p className="bold">Class</p>
          <p>{dataDetail.kSecClass}</p>
          <p className="bold">Match Limit</p>
          <p>{dataDetail.kSecMatchLimit}</p>
          <p className="bold">Return Attributes</p>
          {dataDetail.kSecReturnAttributes ? (
            <p>{dataDetail.kSecReturnAttributes.toString()}</p>
          ) : (
            <p>None</p>
          )}
          <p className="bold">Return Data</p>
          {dataDetail.kSecReturnData ? (
            <p>{dataDetail.kSecReturnData.toString()}</p>
          ) : (
            <p>None</p>
          )}
          <p className="bold">Data Value</p>
          {dataDetail.kSecValueData ? (
            <code className="dont-break-out">{dataDetail.kSecValueData}</code>
          ) : (
            <p>None</p>
          )}
        </ModalBody>
      </Modal>

      <Card className="card-accent-success">
        <CardHeader>Key Chain Operations</CardHeader>
        <CardBody>
          {!Array.isArray(keyChain) || keyChain.length === 0 ? (
            <span>No Key Chain Operations</span>
          ) : (
            <DataTable
              data={keyChain}
              onRowClick={row => {
                handleRowClick(row);
              }}
              columns={[
                {
                  keyName: 'Method',
                  label: 'Method',
                  sortable: true,
                  filterable: true,
                },
                {
                  keyName: 'kSecAttrAccount',
                  label: 'Account Name',
                  sortable: true,
                  filterable: true,
                },
                {
                  keyName: 'kSecAttrService',
                  label: 'Service',
                  sortable: true,
                  filterable: true,
                },
                {
                  keyName: 'kSecAttrAccessible',
                  label: 'Accessibility',
                  sortable: true,
                  filterable: true,
                },
                {
                  keyName: 'kSecValueData',
                  label: 'Data Value',
                  component: ({ value }) => {
                    if (value && value.length > 50) {
                      return (
                        <code>{`${value.substring(0, 50)}...(truncated)`}</code>
                      );
                    }
                    if (value) {
                      return <code>{value}</code>;
                    }
                    return '';
                  },
                  sortable: true,
                  filterable: true,
                },
              ]}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default KeyChainOperations;
