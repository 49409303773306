import React from 'react';
import { Alert } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QAlert as MuiAlert } from '@kw/quokka-ui';

const LDAlert = ({ ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiAlert {...props} />
  ) : (
    <Alert title={props.title} {...props}>
      {props.children}
    </Alert>
  );
};
export const QAlert = withLDConsumer()(LDAlert);
