import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import HostsContactedMap from '../HostsContactedMap';
import UrlScanningData from '../UrlScanningData';
import CountriesContactedList from '../CountriesContactedList';
import FileAccessDatatable from '../iOS/FileAccessDatatable';
import SensitiveDataExposureDatatable from '../SensitiveDataExposureDatatable';
import DynamicAnalysisDatatable from '../Android/DynamicAnalysisDatatable';
import TabsView from '../../TabsView';
import Findings from '../Findings';
import { Harviewer } from '../../Harviewer/Harviewer';

class PrivacyImpactAssessment extends Component {
  render() {
    const { showMap, platform, parsedIssues } = this.props;

    const tabs = [
      {
        title: 'Overview',
        tabContent: () => (
          <Row>
            <Col>
              <Findings
                findings={parsedIssues}
                platform={platform}
                category="privacy"
                showOwaspIcon={false}
              />
            </Col>
            <Col>
              <Findings
                findings={parsedIssues}
                platform={platform}
                category="hardware"
                showOwaspIcon={false}
              />
            </Col>
          </Row>
        ),
      },
      {
        title: 'Network Map',
        tabContent: () => (
          <HostsContactedMap showMap={showMap} platform={platform} />
        ),
      },
      {
        title: 'URL Scanning',
        tabContent: () => <UrlScanningData platform={platform} />,
      },
      {
        title: 'Contries Contacted',
        tabContent: () => <CountriesContactedList platform={platform} />,
      },
      {
        title: 'Network Traffic',
        tabContent: () => <Harviewer />,
      },
      {
        title: 'File Access',
        tabContent: () =>
          platform === 'ios' ? (
            <FileAccessDatatable />
          ) : (
            <DynamicAnalysisDatatable onlySelectedCategory="Files Accessed" />
          ),
      },
      {
        title: 'Sensitive Data Exposure',
        tabContent: () => (
          <SensitiveDataExposureDatatable platform={platform} />
        ),
      },
    ];

    return (
      <div>
        <Card className="card-accent-navy">
          <CardHeader>Privacy Impact Assessment</CardHeader>
          <CardBody>
            <div>
              &quot;The instrument for a privacy impact assessment (PIA) or data
              protection impact assessment (DPIA) was introduced with the
              General Data Protection Regulation (Art. 35 of the GDPR). This
              refers to the obligation of the controller to conduct an impact
              assessment and to document it before starting the intended data
              processing. One can bundle the assessment for several processing
              procedures.&quot;
            </div>
            <div>
              <a
                href="https://gdpr-info.eu/issues/privacy-impact-assessment/"
                target="_blank"
              >
                More Info
              </a>
            </div>
            <hr />

            <TabsView tabs={tabs} />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ appResults }) => ({
  parsedIssues: appResults.parsedIssues,
});

export default withRouter(connect(mapStateToProps)(PrivacyImpactAssessment));
