import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Collapse,
  CardTitle,
  CardText,
  CardSubtitle,
} from 'reactstrap';
import _ from 'lodash';

import { TreeList } from '../../../views/Reports/TreeList';
import EditAppIssueModal from '../../Modals/EditAppIssueModal';

import { V1_1 } from './Evidences/V1_1';
import { V1_2 } from './Evidences/V1_2';
import { V1_3 } from './Evidences/V1_3';
import { V1_5 } from './Evidences/V1_5';
import { V1_8 } from './Evidences/V1_8';
import { V1_9 } from './Evidences/V1_9';
import { V1_10 } from './Evidences/V1_10';
import { V1_11 } from './Evidences/V1_11';
import { V1_12 } from './Evidences/V1_12';
import { V2_1 } from './Evidences/V2_1';
import { V2_2 } from './Evidences/V2_2';
import { V2_3 } from './Evidences/V2_3';
import { V2_4 } from './Evidences/V2_4';
import { V2_6 } from './Evidences/V2_6';
import { V2_8 } from './Evidences/V2_8';
import { V2_10 } from './Evidences/V2_10';
import { V2_12 } from './Evidences/V2_12';
import { V2_13 } from './Evidences/V2_13';
import { V2_14 } from './Evidences/V2_14';
import { V3_1 } from './Evidences/V3_1';
import { V3_2 } from './Evidences/V3_2';
import { V3_4 } from './Evidences/V3_4';
import { V3_6 } from './Evidences/V3_6';
import { V4_1 } from './Evidences/V4_1';
import { V4_2 } from './Evidences/V4_2';
import { V4_3 } from './Evidences/V4_3';
import { V4_7 } from './Evidences/V4_7';
import { V4_8 } from './Evidences/V4_8';
import { V4_10 } from './Evidences/V4_10';
import { V5_1 } from './Evidences/V5_1';
import { V5_2 } from './Evidences/V5_2';
import { V5_3 } from './Evidences/V5_3';
import { V5_4 } from './Evidences/V5_4';
import { V5_5 } from './Evidences/V5_5';
import { V5_6 } from './Evidences/V5_6';
import { V6_1 } from './Evidences/V6_1';
import { V6_2 } from './Evidences/V6_2';
import { V6_3 } from './Evidences/V6_3';
import { V6_4 } from './Evidences/V6_4';
import { V6_5 } from './Evidences/V6_5';
import { V6_6 } from './Evidences/V6_6';
import { V6_7 } from './Evidences/V6_7';
import { V6_8 } from './Evidences/V6_8';
import { V6_10 } from './Evidences/V6_10';
import { V7_1 } from './Evidences/V7_1';
import { V7_2 } from './Evidences/V7_2';
import { V7_3 } from './Evidences/V7_3';
import { V7_4 } from './Evidences/V7_4';
import { V7_5 } from './Evidences/V7_5';
import { V7_8 } from './Evidences/V7_8';
import { V7_9 } from './Evidences/V7_9';
import { V8_1 } from './Evidences/V8_1';
import { V8_2 } from './Evidences/V8_2';
import { V8_6 } from './Evidences/V8_6';
import { V3_3 } from './Evidences/V3_3';
import { V3_5 } from './Evidences/V3_5';

export const EvidenceParsing = ({ evidence, control, platform }) => {
  switch (control) {
    case 'V1.1':
      return <V1_1 platform={platform} />;
    case 'V1.2':
      return <V1_2 evidence={evidence} platform={platform} />;
    case 'V1.3':
      return <V1_3 evidence={evidence} />;
    case 'V1.5':
      return <V1_5 platform={platform} />;
    case 'V1.8':
      return <V1_8 evidence={evidence} platform={platform} />;
    case 'V1.10':
      return <V1_10 evidence={evidence} />;
    case 'V1.9':
      return <V1_9 evidence={evidence} platform={platform} />;
    case 'V1.11':
      return <V1_11 evidence={evidence} platform={platform} />;
    case 'V1.12':
      return <V1_12 evidence={evidence} />;
    case 'V2.1':
      return <V2_1 evidence={evidence} platform={platform} />;
    case 'V2.2':
      return <V2_2 evidence={evidence} platform={platform} />;
    case 'V2.3':
      return <V2_3 platform={platform} />;
    case 'V2.4':
      return <V2_4 evidence={evidence} platform={platform} />;
    case 'V2.6':
      return <V2_6 platform={platform} evidence={evidence} />;
    case 'V2.8':
      return <V2_8 platform={platform} evidence={evidence} />;
    case 'V2.10':
      return <V2_10 platform={platform} evidence={evidence} />;
    case 'V2.12':
      return <V2_12 evidence={evidence} platform={platform} />;
    case 'V2.13':
      return <V2_13 evidence={evidence} platform={platform} />;
    case 'V2.14':
      return <V2_14 evidence={evidence} platform={platform} />;
    case 'V3.1':
      return <V3_1 evidence={evidence} platform={platform} />;
    case 'V3.2':
      return <V3_2 evidence={evidence} platform={platform} />;
    case 'V3.3':
      return <V3_3 evidence={evidence} platform={platform} />;
    case 'V3.4':
      return <V3_4 evidence={evidence} platform={platform} />;
    case 'V3.5':
      return <V3_5 evidence={evidence} platform={platform} />;
    case 'V3.6':
      return <V3_6 evidence={evidence} platform={platform} />;
    case 'V4.1':
      return <V4_1 evidence={evidence} platform={platform} />;
    case 'V4.2':
      return <V4_2 evidence={evidence} platform={platform} />;
    case 'V4.3':
      return <V4_3 evidence={evidence} platform={platform} />;
    case 'V4.7':
      return <V4_7 evidence={evidence} platform={platform} />;
    case 'V4.8':
      return <V4_8 evidence={evidence} platform={platform} />;
    case 'V4.10':
      return <V4_10 evidence={evidence} platform={platform} />;
    case 'V5.1':
      return <V5_1 evidence={evidence} platform={platform} />;
    case 'V5.2':
      return <V5_2 evidence={evidence} platform={platform} />;
    case 'V5.3':
      return <V5_3 evidence={evidence} platform={platform} />;
    case 'V5.4':
      return <V5_4 evidence={evidence} platform={platform} />;
    case 'V5.5':
      return <V5_5 evidence={evidence} platform={platform} />;
    case 'V5.6':
      return <V5_6 evidence={evidence} platform={platform} />;
    case 'V6.1':
      return <V6_1 evidence={evidence} platform={platform} />;
    case 'V6.2':
      return <V6_2 evidence={evidence} platform={platform} />;
    case 'V6.3':
      return <V6_3 evidence={evidence} platform={platform} />;
    case 'V6.4':
      return <V6_4 evidence={evidence} platform={platform} />;
    case 'V6.5':
      return <V6_5 evidence={evidence} platform={platform} />;
    case 'V6.6':
      return <V6_6 evidence={evidence} platform={platform} />;
    case 'V6.7':
      return <V6_7 evidence={evidence} platform={platform} />;
    case 'V6.10':
      return <V6_10 evidence={evidence} platform={platform} />;
    case 'V6.8':
      return <V6_8 evidence={evidence} platform={platform} />;
    case 'V7.2':
      return <V7_2 evidence={evidence} platform={platform} />;
    case 'V7.5':
      return <V7_5 evidence={evidence} platform={platform} />;
    case 'V7.1':
      return <V7_1 evidence={evidence} platform={platform} />;
    case 'V7.3':
      return <V7_3 evidence={evidence} platform={platform} />;
    case 'V7.4':
      return <V7_4 evidence={evidence} platform={platform} />;
    case 'V7.8':
      return <V7_8 evidence={evidence} platform={platform} />;
    case 'V7.9':
      return <V7_9 evidence={evidence} platform={platform} />;
    case 'V8.1':
      return <V8_1 evidence={evidence} platform={platform} />;
    case 'V8.2':
      return <V8_2 evidence={evidence} platform={platform} />;
    case 'V8.6':
      return <V8_6 evidence={evidence} platform={platform} />;
    default:
      if (_.isEmpty(evidence) || !evidence) {
        return null;
      }

      return typeof evidence === 'string' ? (
        <CardText>Evidence: {evidence} </CardText>
      ) : (
        <>
          <CardText>Evidence:</CardText>
          <TreeList values={evidence} />
        </>
      );
  }
};

export const ResultIcon = ({ result }) => {
  switch (result) {
    case 'review':
      return <i className="fa-solid fa-exclamation-circle text-warning mr-2" />;
    case 'pass':
      return <i className="fa-solid fa-circle-check text-success mr-2" />;
    case 'fail':
      return <i className="fa-solid fa-circle-xmark text-danger mr-2" />;
    case 'hide':
      return <i className="fa-solid fa-circle-info text-info mr-2" />;
    default:
      return <i className="fa-solid fa-circle-info mr-2" />;
  }
};

const ControlCard = ({
  control,
  mstgId,
  description,
  result,
  evidence,
  platform,
  customIssue,
  flag,
}) => {
  const [isOpen, setIsOpen] = useState({
    status: false,
    collapseButton: 'down',
  });

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const toggleEditAppIssueModal = () => {
    setIsOpenEditModal(!isOpenEditModal);
  };

  return (
    <>
      <EditAppIssueModal
        open={isOpenEditModal}
        toggle={toggleEditAppIssueModal}
        appIssue={customIssue}
        farmAppUuid={window.location.hash.split('/')[2]}
      />
      <Card>
        <CardHeader>
          <Row>
            <Col className="text-left">
              <ResultIcon result={result} /> {control}:{mstgId}
              {!_.isEmpty(customIssue) && (
                <span onClick={() => toggleEditAppIssueModal()}>
                  <i className="fa-regular fa-edit ml-2" />
                </span>
              )}
              {flag && (
                <span>
                  <i className="fa-solid fa-flag ml-2" />
                </span>
              )}
            </Col>
            <Col className="text-right">
              <span className="pointer">
                <button
                  type="button"
                  className="button-like-span"
                  onClick={() =>
                    setIsOpen({
                      status: !isOpen.status,
                      collapseButton:
                        isOpen.collapseButton === 'up' ? 'down' : 'up',
                    })
                  }
                  aria-pressed="false"
                >
                  <i
                    className={`fa-solid fa-lg fa-angle-${isOpen.collapseButton}`}
                  />
                </button>
              </span>
            </Col>
          </Row>
        </CardHeader>
        <Collapse isOpen={isOpen.status}>
          <CardBody>
            <CardTitle tag="h6">
              <div>
                <p>
                  <b>Requirement Description</b>
                </p>
                <p>{description}</p>
              </div>
            </CardTitle>
            {customIssue.note && (
              <CardSubtitle
                style={{
                  border: '2px solid #dedede',
                  borderRadius: '5px',
                  padding: '10px',
                  margin: '10px 0',
                }}
              >
                <i>Note: {customIssue.note}</i>
              </CardSubtitle>
            )}
            <EvidenceParsing
              evidence={evidence}
              control={control}
              platform={platform}
            />
          </CardBody>
        </Collapse>
      </Card>
    </>
  );
};

export default function RegulationCard({ data, platform }) {
  const [isOpen, setIsOpen] = useState({
    status: false,
    collapseButton: 'down',
  });

  return (
    <Card>
      <CardHeader
        onClick={() =>
          setIsOpen({
            status: !isOpen.status,
            collapseButton: isOpen.collapseButton === 'up' ? 'down' : 'up',
          })
        }
        className="pointer"
      >
        <Row>
          <Col className="text-left">
            {data.regulation}: {data.description}
          </Col>
          <Col className="text-right">
            <i className={`fa-solid fa-lg fa-angle-${isOpen.collapseButton}`} />
          </Col>
        </Row>
      </CardHeader>

      <Collapse isOpen={isOpen.status}>
        <CardBody>
          {data.controls.length === 0 ? (
            <CardSubtitle>No data found</CardSubtitle>
          ) : (
            data.controls.map(
              ({
                control,
                'MSTG-ID': mstgId,
                description,
                evidence,
                result,
                customIssue,
                flag,
              }) => (
                <ControlCard
                  control={control}
                  mstgId={mstgId}
                  description={description}
                  evidence={evidence}
                  result={result}
                  platform={platform}
                  customIssue={customIssue}
                  flag={flag}
                />
              ),
            )
          )}
        </CardBody>
      </Collapse>
    </Card>
  );
}
