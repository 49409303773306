import React from 'react';
import { Col, Row, Card, CardHeader, CardBody } from 'reactstrap';

import i18n from '../../../localization/i18n';

import ExtraPermissionsTable from './ExtraPermissionsTable';
import PermissionsRequestedTable from './PermissionsRequestedTable';
import ConstantsMethodsPermissionTable from './ConstantsMethodsPermissionTable';

const PermissionInfo = () => (
  <div>
    <Row>
      {/* Permissions Requested */}
      <Col xs="6">
        <Card
          className="card-accent-info"
          style={{ maxHeight: '500px', overflow: 'auto' }}
        >
          <CardHeader>{i18n.t('Permissions Requested')}</CardHeader>
          <CardBody>
            <PermissionsRequestedTable />
          </CardBody>
        </Card>
      </Col>

      {/* Extra Permissions */}
      <Col xs="6">
        <Card
          className="card-accent-danger"
          style={{ maxHeight: '500px', overflow: 'auto' }}
        >
          <CardHeader>{i18n.t('Extra Permissions')}</CardHeader>
          <CardBody>
            <ExtraPermissionsTable />
          </CardBody>
        </Card>
      </Col>

      {/* Code requiring permissions */}
      <Col xs="12">
        <Card className="card-accent-warning-2">
          <CardHeader>
            {i18n.t('Constants/Methods Requiring Permission')}
          </CardHeader>
          <CardBody>
            <ConstantsMethodsPermissionTable />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

export default PermissionInfo;
