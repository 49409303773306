import React from 'react';
import { CardText } from 'reactstrap';

import SensitiveDataExposureDatatable from '../../SensitiveDataExposureDatatable';

export const V1_12 = ({ evidence }) => (
  <>
    <CardText>
      <strong>Privacy Policy Link: </strong>
      <a href={evidence.privacyPolicyLink} target="_blank">
        {evidence.privacyPolicyLink}
      </a>
    </CardText>
    <SensitiveDataExposureDatatable />
  </>
);
