import React from 'react';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

const DynamicallyLoadedLibrariesDatatable = ({ results }) => {
  let dynamicallyLoadedLibraries =
    results.analysis.analysis_details.dynamic_analysis
      .dynamically_loaded_libraries_frameworks;

  // Also include the findings "Dynamic Libraries" from static analysis
  // since these will always be loaded at some point during the application's
  // execution.
  dynamicallyLoadedLibraries = dynamicallyLoadedLibraries.concat(
    Object.values(
      results.analysis.analysis_details.static_analysis.libraries_used[
        'Dynamic Libraries'
      ],
    ),
  );

  return (
    <div>
      {dynamicallyLoadedLibraries.length === 0 ? (
        <span>{i18n.t('No libraries loaded dynamically')}</span>
      ) : (
        <DataTable
          data={dynamicallyLoadedLibraries}
          columns={[
            {
              keyName: 'path',
              label: i18n.t('Library/Framework'),
              component: ({ value }) => (
                <span>
                  {value.includes('libswift') && (
                    <Badge color="info" pill>
                      1st Party Swift
                    </Badge>
                  )}{' '}
                  {value.split('/').slice(-1)[0]}
                </span>
              ),
              sortable: true,
              filterable: true,
            },
            {
              keyName: 'loaded_using',
              label: i18n.t('Loaded Using'),
              component: ({ value }) => (
                <span>{value || i18n.t('Unknown')}</span>
              ),
              sortable: true,
              filterable: true,
            },
          ]}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(DynamicallyLoadedLibrariesDatatable);
