import React, { useState } from 'react';
import axios from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AddIcon from '@mui/icons-material/Add';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

import { QCard } from '../../Q-Components/QCard';
import { QCardHeader } from '../../Q-Components/QCardHeader';
import { QCardBody } from '../../Q-Components/QCardBody';
import { QTypography } from '../../Q-Components/QTypography';
import { QLabel } from '../../Q-Components/QLabel';
import { QInput } from '../../Q-Components/QInput';
import { QButton } from '../../Q-Components/QButton';
import { QRow } from '../../Q-Components/QRow';
import { QListGroup } from '../../Q-Components/QListGroup';
import { QListGroupItem } from '../../Q-Components/QListGroupItem';
import { QIconButton } from '../../Q-Components/QIconButton';
import { QDropdown, QDropdownWrapper } from '../../Q-Components/QDropdown';
import { QDropdownInputLabel } from '../../Q-Components/QDropdownInputLabel';
import { QDropdownSelect } from '../../Q-Components/QDropdownSelect';
import { QDropdownSelectItem } from '../../Q-Components/QDropdownSelectItem';
import HelpButton from '../../HelpButton';
import i18n from '../../../localization/i18n';
import util from '../../../logic/util';
import { toastrHelper } from '../../../logic/toastrHelper';

export default function AnalyticsAlertThresholdCard({
  analyticsItem,
  thresholdSettings,
  settingsInfo,
  dimensions,
}) {
  let bufferThreshold;
  let bufferEmail;
  if (thresholdSettings) {
    bufferThreshold = thresholdSettings.threshold
      ? thresholdSettings.threshold
      : 0;
    bufferEmail = thresholdSettings.email ? thresholdSettings.email : '';
  } else {
    bufferThreshold = 0;
    bufferEmail = '';
  }

  const [threshold, setThreshold] = useState({
    value: bufferThreshold,
    label:
      bufferThreshold > 0
        ? `${bufferThreshold} ${dimensions}`
        : 'Threshold Disabled',
  });

  const [alertEmails, setAlertEmail] = useState(
    bufferEmail ? bufferEmail.split(',') : [],
  );
  const [inputEmails, setinputEmails] = useState('');
  const { mastV2 } = useFlags();
  const formatOptionLabel = ({ label }) => (
    <div
      style={
        mastV2
          ? { display: 'flex', columnGap: '8px', alignItems: 'center' }
          : {}
      }
    >
      <span>{label}</span>
    </div>
  );

  const changeThresholdOption = (event, newThreshold) => {
    if (mastV2) {
      setThreshold(
        settingsInfo.thresholdOptions.find(
          option => option.value === newThreshold.props.value,
        ),
      );
    } else {
      setThreshold(event);
    }
  };

  const saveSettings = async () => {
    console.log('Setting threshold to:', threshold.value, 'minutes');
    console.log('Email to:', alertEmails);

    if (alertEmails.length === 0) {
      toastrHelper.showWarningToast(
        'Please add at least one email address',
        'Warning',
        mastV2,
      );
      return;
    }

    try {
      const response = await axios.put('analytics-alerts', {
        analyticsItem,
        threshold: threshold.value,
        emails: alertEmails.join(','),
      });

      if (response.status === 200) {
        toastrHelper.showSuccessToast(
          i18n.t('Successfully set average analysis time threshold'),
          null,
          mastV2,
        );
      }
    } catch (err) {
      console.log('Error setting threshold:', err);
      toastrHelper.showErrorToast(
        i18n.t('Error setting threshold'),
        null,
        mastV2,
      );
    }
  };

  const disableThreshold = async () => {
    setThreshold({
      value: -1,
      label: 'Threshold Disabled',
    });
    try {
      const response = await axios.put('analytics-alerts', {
        analyticsItem,
        threshold: -1,
      });

      if (response.status === 200) {
        toastrHelper.showSuccessToast(
          i18n.t('Successfully disabled threshold'),
          null,
          mastV2,
        );
      }
    } catch (err) {
      console.log('Error disabling threshold:', err);
      toastrHelper.showErrorToast(
        i18n.t('Error disabling threshold'),
        null,
        mastV2,
      );
    }
  };

  const addAlertEmail = () => {
    const emails = inputEmails.replace(/\s+/g, '').split(',');
    const isValid = emails.map(email => util.isValidEmail(email));

    if (isValid.includes(false)) {
      toastrHelper.showErrorToast('Invalid Email', 'Error', mastV2);
      return;
    }
    setAlertEmail([
      ...new Set(alertEmails.concat(emails).filter(item => item)),
    ]);
  };
  const deleteAlertEmail = email => {
    setAlertEmail(alertEmails.filter(item => item !== email));
  };

  return (
    <QCard className="card-accent-warning-2">
      <QCardHeader
        title={
          <QTypography variant="h4Bold" color="#000000">
            {i18n.t(settingsInfo.name)}{' '}
          </QTypography>
        }
        action={<HelpButton helpItem="averageAnalysisTimeThreshold" />}
      >
        <strong>
          {i18n.t(settingsInfo.name)}{' '}
          <HelpButton helpItem="averageAnalysisTimeThreshold" />
        </strong>
      </QCardHeader>
      <QCardBody>
        {mastV2 ? (
          <QDropdownWrapper
            formControlProps={{
              style: {
                width: '100%',
                marginBottom: '16px',
              },
            }}
            options={settingsInfo.thresholdOptions}
            selectedValue={threshold}
            onChange={changeThresholdOption}
            closedLabel={threshold.label}
            variant="outlined"
            labelProps={{
              disableAnimation: true,
              shrink: false,
            }}
            id="threshold-select2"
          />
        ) : (
          <QDropdown style={{ width: '100%', marginBottom: '16px' }}>
            <QDropdownInputLabel disableAnimation shrink={false}>
              {threshold ? threshold.label : 'Threshold Disabled'}
            </QDropdownInputLabel>

            <QDropdownSelect
              id="threshold-select"
              placeholder="Threshold Disabled"
              value={threshold}
              options={settingsInfo.thresholdOptions}
              onChange={changeThresholdOption}
              clearable={false}
              isSearchable={false}
              formatOptionLabel={formatOptionLabel}
              label="Threshold Disabled"
              className={mastV2 ? '' : 'z-index-10'}
              renderValue={() => {}}
              sx={{
                borderStyle: 'solid',
                borderWidth: '1px',
                borderRadius: '4px',
                backgroundColor: 'white',
                borderColor: '#BDBDBD',
              }}
            >
              <QDropdownSelectItem value="" style={{ display: 'none' }} />
              {settingsInfo.thresholdOptions.map(option => (
                <QDropdownSelectItem
                  value={option}
                  key={option}
                  className={
                    option && option.label === threshold.value ? 'hover' : ''
                  }
                >
                  {option.label}{' '}
                </QDropdownSelectItem>
              ))}
            </QDropdownSelect>
          </QDropdown>
        )}
        <hr />
        <QLabel for="average-analysis-time-threshold-email-input">
          <QTypography variant="h5Medium" color="#000000">
            {i18n.t('Alert Emails')}{' '}
          </QTypography>
        </QLabel>
        <QInput
          type="text"
          id="threat-score-threshold-email-input"
          onChange={e => setinputEmails(e.target.value)}
          value={inputEmails}
          sx={{ marginBottom: '10px' }}
        />
        <QButton
          variant="filled"
          sx={{ marginBottom: '8px' }}
          className="btn-primary mt-2 "
          onClick={() => {
            addAlertEmail();
            setinputEmails('');
          }}
          startIcon={<AddIcon />}
        >
          {!mastV2 && <i className="fa-regular fa-circle-plus" />} Add
        </QButton>
        <hr />
        <QListGroup>
          {alertEmails.map(email => (
            <QListGroupItem
              key={email}
              sx={{
                borderStyle: 'solid',
                borderWidth: '1px',
                borderRadius: '4px',
                backgroundColor: 'white',
                margin: '8px',
                borderColor: '#BDBDBD',
              }}
              secondaryAction={
                <QIconButton
                  style={{
                    color: 'unset',
                  }}
                >
                  <IndeterminateCheckBoxOutlinedIcon
                    fontSize="large"
                    onClick={() => deleteAlertEmail(email)}
                  />
                </QIconButton>
              }
            >
              {email}{' '}
              {!mastV2 && (
                <i
                  className="fa-light fa-circle-minus pointer ml-2"
                  onClick={() => deleteAlertEmail(email)}
                />
              )}
            </QListGroupItem>
          ))}
        </QListGroup>

        <div
          style={
            !mastV2
              ? { display: 'flex', justifyContent: 'space-between' }
              : { textAlign: 'center' }
          }
        >
          <QRow columnGap="5px">
            <QButton
              variant="filled"
              color="primary"
              onClick={saveSettings}
              sx={{ marginRight: '16px' }}
              style={{ whiteSpace: 'nowrap' }}
            >
              {i18n.t('Save Settings')}
            </QButton>
            <QButton
              variant="outlined"
              color="warning"
              onClick={disableThreshold}
              style={{ whiteSpace: 'nowrap' }}
            >
              {i18n.t('Disable Threshold')}
            </QButton>
          </QRow>
        </div>
      </QCardBody>
    </QCard>
  );
}
