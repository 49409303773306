import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';

import VersionDetails from '../components/AppCompareVersions/VersionDetails';
import VersionIssues from '../components/AppCompareVersions/VersionIssues';
import VersionPermissions from '../components/AppCompareVersions/VersionPermissions';
import VersionLibraries from '../components/AppCompareVersions/VersionLibraries';
import VersionHosts from '../components/AppCompareVersions/VersionHosts';
import i18n from '../localization/i18n';
import Loading from '../components/Loading';
import { QCard } from '../components/Q-Components/QCard';
import { QCardHeader } from '../components/Q-Components/QCardHeader';
import { QCardBody } from '../components/Q-Components/QCardBody';
import { QRow } from '../components/Q-Components/QRow';
import { QCol } from '../components/Q-Components/QCol';

export default function AppCompareVersions() {
  const { analyzedAppId, comparisonAppId } = useParams();
  const [appsToBeCompared, setAppsToBeCompared] = useState([]);

  const getAppVersionsData = async () => {
    try {
      // Get app data for both versions
      const appRequests = [
        axios.get(`/emm/app/${analyzedAppId}`),
        axios.get(`/emm/app/${comparisonAppId}`),
      ];

      const [responseAppOne, responseAppTwo] = await Promise.all(appRequests);
      const appOne = responseAppOne.data;
      const appTwo = responseAppTwo.data;

      const resultsRequests = [
        axios
          .get(`app-issues/parsed?uuid=${appOne.farm_app.uuid}`)
          .catch(() => ({ data: { parsedAppIssues: [] } })),
        axios
          .get(`app-issues/parsed?uuid=${appTwo.farm_app.uuid}`)
          .catch(() => ({ data: { parsedAppIssues: [] } })),
        axios
          .get(`api/results/json?uuid=${appOne.farm_app.uuid}`)
          .catch(() => ({})),
        axios
          .get(`api/results/json?uuid=${appTwo.farm_app.uuid}`)
          .catch(() => ({})),
        axios
          .get(`api/results/traffic_portal?uuid=${appOne.farm_app.uuid}`)
          .catch(() => ({})),
        axios
          .get(`api/results/traffic_portal?uuid=${appTwo.farm_app.uuid}`)
          .catch(() => ({})),
      ];

      const [
        parsedIssueResponseAppOne,
        parsedIssuesResponseComparison,
        resultsAnalyzedApp,
        resultsComparisonApp,
        trafficAnalyzedApp,
        trafficComparisonApp,
      ] = await Promise.all(resultsRequests);

      // Get parsed issues for both versions
      appOne.parsedIssues = parsedIssueResponseAppOne.data.parsedAppIssues;
      appTwo.parsedIssues = parsedIssuesResponseComparison.data.parsedAppIssues;

      // Get analysis results for both versions
      appOne.results = resultsAnalyzedApp.data;
      appTwo.results = resultsComparisonApp.data;

      // Get network traffic for both versions
      appOne.traffic = trafficAnalyzedApp.data;
      appTwo.traffic = trafficComparisonApp.data;

      // Get Number of issues differential
      ['low', 'medium', 'high', 'critical'].forEach(threatLevel => {
        // Issues decreased
        if (
          appOne.parsedIssues.filter(
            issue => issue.found && issue.risk === threatLevel,
          ).length >
          appTwo.parsedIssues.filter(
            issue => issue.found && issue.risk === threatLevel,
          ).length
        ) {
          appOne[`${threatLevel}IssueDifferential`] = 1;
          appTwo[`${threatLevel}IssueDifferential`] = -1;
          // Issues increased
        } else if (
          appOne.parsedIssues.filter(
            issue => issue.found && issue.risk === threatLevel,
          ).length <
          appTwo.parsedIssues.filter(
            issue => issue.found && issue.risk === threatLevel,
          ).length
        ) {
          appOne[`${threatLevel}IssueDifferential`] = -1;
          appTwo[`${threatLevel}IssueDifferential`] = 1;
          // Same amount of issues
        } else {
          appOne[`${threatLevel}IssueDifferential`] = 0;
          appTwo[`${threatLevel}IssueDifferential`] = 0;
        }
      });

      const bufferAppsToBeCompared = [];
      bufferAppsToBeCompared.push(appOne);
      bufferAppsToBeCompared.push(appTwo);

      setAppsToBeCompared(bufferAppsToBeCompared);
    } catch (err) {
      console.log('Error getting data of the two versions of the app', err);
    }
  };

  useEffect(() => {
    getAppVersionsData();
  }, [analyzedAppId, comparisonAppId]);

  return (
    <div className="animated fadeIn">
      <QCard className="card-accent-info" id="version-comparison-card">
        <QCardHeader
          title={i18n.t('Version Comparison')}
          className="version-comparison-card-header"
        >
          <strong>{i18n.t('Version Comparison')}</strong>{' '}
        </QCardHeader>
        <QCardBody>
          <div>
            {appsToBeCompared.length > 0 ? (
              <div>
                <QRow
                  className="align-items-center"
                  style={{ height: '150px' }}
                >
                  <QCol xs="2" sx={{ flex: '2' }} />
                  <QCol
                    xs="5"
                    sx={{
                      flex: '5',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }}
                  >
                    <VersionDetails app={appsToBeCompared[0]} />
                  </QCol>
                  <QCol
                    xs="5"
                    sx={{
                      flex: '5',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }}
                  >
                    <VersionDetails app={appsToBeCompared[1]} />
                  </QCol>
                </QRow>
                <hr className="bold-line" />
                <QRow className="align-items-center">
                  <QCol xs="2" sx={{ flex: '2' }}>
                    <strong>{i18n.t('Number of Issues')}</strong>
                  </QCol>
                  <QCol
                    xs="5"
                    sx={{
                      flex: '5',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }}
                  >
                    <QRow>
                      <QCol className="text-left" sx={{ flex: '1' }}>
                        {i18n.t('Low: ')}
                        <br />
                        {i18n.t('Medium: ')}
                        <br />
                        {i18n.t('High: ')}
                        <br />
                        {i18n.t('Critical: ')}
                        <br />
                      </QCol>
                      <QCol sx={{ flex: '1' }}>
                        {appsToBeCompared[0].lowIssueDifferential === 1 ? (
                          <i className="fa fa-arrow-up-long danger-icon" />
                        ) : appsToBeCompared[0].lowIssueDifferential === -1 ? (
                          <i className="fa fa-arrow-down-long success-icon" />
                        ) : (
                          <i className="fa-regular fa-circle-minus black-icon" />
                        )}
                        &nbsp;
                        {
                          appsToBeCompared[0].parsedIssues.filter(
                            issue => issue.found && issue.risk === 'low',
                          ).length
                        }
                        <br />
                        {appsToBeCompared[0].mediumIssueDifferential === 1 ? (
                          <i className="fa fa-arrow-up-long danger-icon" />
                        ) : appsToBeCompared[0].mediumIssueDifferential ===
                          -1 ? (
                          <i className="fa fa-arrow-down-long success-icon" />
                        ) : (
                          <i className="fa-regular fa-circle-minus black-icon" />
                        )}
                        &nbsp;
                        {
                          appsToBeCompared[0].parsedIssues.filter(
                            issue => issue.found && issue.risk === 'medium',
                          ).length
                        }
                        <br />
                        {appsToBeCompared[0].highIssueDifferential === 1 ? (
                          <i className="fa fa-arrow-up-long danger-icon" />
                        ) : appsToBeCompared[0].highIssueDifferential === -1 ? (
                          <i className="fa fa-arrow-down-long success-icon" />
                        ) : (
                          <i className="fa-regular fa-circle-minus black-icon" />
                        )}
                        &nbsp;
                        {
                          appsToBeCompared[0].parsedIssues.filter(
                            issue => issue.found && issue.risk === 'high',
                          ).length
                        }
                        <br />
                        {appsToBeCompared[0].criticalIssueDifferential === 1 ? (
                          <i className="fa fa-arrow-up-long danger-icon" />
                        ) : appsToBeCompared[0].criticalIssueDifferential ===
                          -1 ? (
                          <i className="fa fa-arrow-down-long success-icon" />
                        ) : (
                          <i className="fa-regular fa-circle-minus black-icon" />
                        )}
                        &nbsp;
                        {
                          appsToBeCompared[0].parsedIssues.filter(
                            issue => issue.found && issue.risk === 'critical',
                          ).length
                        }
                      </QCol>
                    </QRow>
                  </QCol>
                  <QCol
                    xs="5"
                    sx={{
                      flex: '5',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }}
                  >
                    <QRow>
                      <QCol className="text-left" sx={{ flex: '1' }}>
                        {i18n.t('Low: ')}
                        <br />
                        {i18n.t('Medium: ')}
                        <br />
                        {i18n.t('High: ')}
                        <br />
                        {i18n.t('Critical: ')}
                        <br />
                      </QCol>
                      <QCol sx={{ flex: '1' }}>
                        {appsToBeCompared[1].lowIssueDifferential === 1 ? (
                          <i className="fa fa-arrow-up-long danger-icon" />
                        ) : appsToBeCompared[1].lowIssueDifferential === -1 ? (
                          <i className="fa fa-arrow-down-long success-icon" />
                        ) : (
                          <i className="fa-regular fa-circle-minus black-icon" />
                        )}
                        &nbsp;
                        {
                          appsToBeCompared[1].parsedIssues.filter(
                            issue => issue.found && issue.risk === 'low',
                          ).length
                        }
                        <br />
                        {appsToBeCompared[1].mediumIssueDifferential === 1 ? (
                          <i className="fa fa-arrow-up-long danger-icon" />
                        ) : appsToBeCompared[1].mediumIssueDifferential ===
                          -1 ? (
                          <i className="fa fa-arrow-down-long success-icon" />
                        ) : (
                          <i className="fa-regular fa-circle-minus black-icon" />
                        )}
                        &nbsp;
                        {
                          appsToBeCompared[1].parsedIssues.filter(
                            issue => issue.found && issue.risk === 'medium',
                          ).length
                        }
                        <br />
                        {appsToBeCompared[1].highIssueDifferential === 1 ? (
                          <i className="fa fa-arrow-up-long danger-icon" />
                        ) : appsToBeCompared[1].highIssueDifferential === -1 ? (
                          <i className="fa fa-arrow-down-long success-icon" />
                        ) : (
                          <i className="fa-regular fa-circle-minus black-icon" />
                        )}
                        &nbsp;
                        {
                          appsToBeCompared[1].parsedIssues.filter(
                            issue => issue.found && issue.risk === 'high',
                          ).length
                        }
                        <br />
                        {appsToBeCompared[1].criticalIssueDifferential === 1 ? (
                          <i className="fa fa-arrow-up-long danger-icon" />
                        ) : appsToBeCompared[1].criticalIssueDifferential ===
                          -1 ? (
                          <i className="fa fa-arrow-down-long success-icon" />
                        ) : (
                          <i className="fa-regular fa-circle-minus black-icon" />
                        )}
                        &nbsp;
                        {
                          appsToBeCompared[1].parsedIssues.filter(
                            issue => issue.found && issue.risk === 'critical',
                          ).length
                        }
                      </QCol>
                    </QRow>
                  </QCol>
                </QRow>
                <hr className="bold-line" />
                <QRow className="align-items-center">
                  <QCol xs="2" sx={{ flex: '2' }}>
                    <strong>{i18n.t('Issues')}</strong>
                  </QCol>
                  <QCol
                    xs="5"
                    sx={{
                      flex: '5',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }}
                  >
                    <VersionIssues
                      issuesSelf={appsToBeCompared[0].parsedIssues.filter(
                        issue => issue.found,
                      )}
                      issuesOther={appsToBeCompared[1].parsedIssues.filter(
                        issue => issue.found,
                      )}
                    />
                  </QCol>
                  <QCol
                    xs="5"
                    sx={{
                      flex: '5',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }}
                  >
                    <VersionIssues
                      issuesSelf={appsToBeCompared[1].parsedIssues.filter(
                        issue => issue.found,
                      )}
                      issuesOther={appsToBeCompared[0].parsedIssues.filter(
                        issue => issue.found,
                      )}
                    />
                  </QCol>
                </QRow>
                <hr className="bold-line" />
                <QRow className="align-items-center">
                  <QCol xs="2" sx={{ flex: '2' }}>
                    <strong>{i18n.t('Permissions Requested')}</strong>
                  </QCol>
                  <QCol
                    xs="5"
                    sx={{
                      flex: '5',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }}
                  >
                    <VersionPermissions
                      platform={appsToBeCompared[0].farm_app.platform}
                      permissionsSelf={_.get(
                        appsToBeCompared[0],
                        'results.analysis.analysis_details.static_analysis.permissions_requested',
                      )}
                      permissionsOther={_.get(
                        appsToBeCompared[1],
                        'results.analysis.analysis_details.static_analysis.permissions_requested',
                      )}
                    />
                  </QCol>
                  <QCol
                    xs="5"
                    sx={{
                      flex: '5',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }}
                  >
                    <VersionPermissions
                      platform={appsToBeCompared[1].farm_app.platform}
                      permissionsSelf={_.get(
                        appsToBeCompared[1],
                        'results.analysis.analysis_details.static_analysis.permissions_requested',
                      )}
                      permissionsOther={_.get(
                        appsToBeCompared[0],
                        'results.analysis.analysis_details.static_analysis.permissions_requested',
                      )}
                    />
                  </QCol>
                </QRow>
                <hr className="bold-line" />
                <QRow className="align-items-center">
                  <QCol xs="2" sx={{ flex: '2' }}>
                    <strong>{i18n.t('Libraries Loaded')}</strong>
                  </QCol>
                  <QCol
                    xs="5"
                    sx={{
                      flex: '5',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }}
                  >
                    <VersionLibraries
                      platform={appsToBeCompared[0].farm_app.platform}
                      librariesSelf={_.get(
                        appsToBeCompared[0],
                        'results.analysis.analysis_details',
                      )}
                      librariesOther={_.get(
                        appsToBeCompared[1],
                        'results.analysis.analysis_details',
                      )}
                    />
                  </QCol>
                  <QCol
                    xs="5"
                    sx={{
                      flex: '5',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }}
                  >
                    <VersionLibraries
                      platform={appsToBeCompared[1].farm_app.platform}
                      librariesSelf={_.get(
                        appsToBeCompared[1],
                        'results.analysis.analysis_details',
                      )}
                      librariesOther={_.get(
                        appsToBeCompared[0],
                        'results.analysis.analysis_details',
                      )}
                    />
                  </QCol>
                </QRow>
                <hr className="bold-line" />
                <QRow className="align-items-center">
                  <QCol xs="2" sx={{ flex: '2' }}>
                    <strong>{i18n.t('Network Map')}</strong>
                  </QCol>
                  <QCol
                    xs="5"
                    sx={{
                      flex: '5',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }}
                  >
                    <VersionHosts
                      trafficSelf={appsToBeCompared[0].traffic}
                      trafficOther={appsToBeCompared[1].traffic}
                    />
                  </QCol>
                  <QCol
                    xs="5"
                    sx={{
                      flex: '5',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }}
                  >
                    <VersionHosts
                      trafficSelf={appsToBeCompared[1].traffic}
                      trafficOther={appsToBeCompared[0].traffic}
                    />
                  </QCol>
                </QRow>
              </div>
            ) : (
              <Loading />
            )}
          </div>
        </QCardBody>
      </QCard>
    </div>
  );
}
