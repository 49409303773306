import React from 'react';
import { Button } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QToggleButton as MuiToggleButton } from '@kw/quokka-ui';
import { styled } from '@mui/system';

const LDToggleButton = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  const filteredProps = ['className', 'size', 'color'];
  const muiButtonProps = Object.keys(props).reduce((acc, key) => {
    if (!filteredProps.includes(key)) {
      acc[key] = props[key];
    }
    return acc;
  }, {});

  const MToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
    '&.Mui-selected, &.Mui-selected:hover': {
      border: '1px solid !important',
      borderColor: `${selectedColor} !important`,
      backgroundColor: 'white',
    },
    '&.Mui-disabled, &.Mui-disabled:hover': {
      border: 'none !important',
      color: '#BDBDBD',
    },
  }));

  return mastV2 ? (
    <MToggleButton {...muiButtonProps}>{children}</MToggleButton>
  ) : (
    <Button {...props}>{children}</Button>
  );
};

export const QToggleButton = withLDConsumer()(LDToggleButton);
