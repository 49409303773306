import React from 'react';
import { Pie } from 'react-chartjs-2';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QDoughnut } from '../Q-Components/QDoughnut';
import { toastrHelper } from '../../logic/toastrHelper';

const LDIssuesByRisk = ({ appIssuesByRisk, isLoading, header, appType }) => {
  const { mastV2 } = useFlags();

  const getRiskCounts = issuesByRisk => {
    const criticalEntry = issuesByRisk.find(entry => entry.risk === 'critical');
    const highEntry = issuesByRisk.find(entry => entry.risk === 'high');
    const mediumEntry = issuesByRisk.find(entry => entry.risk === 'medium');
    const lowEntry = issuesByRisk.find(entry => entry.risk === 'low');

    const criticalIssueCount = criticalEntry ? criticalEntry.count : 0;
    const highIssueCount = highEntry ? highEntry.count : 0;
    const mediumIssueCount = mediumEntry ? mediumEntry.count : 0;
    const lowIssueCount = lowEntry ? lowEntry.count : 0;

    return [
      criticalIssueCount,
      highIssueCount,
      mediumIssueCount,
      lowIssueCount,
    ];
  };

  const criticalColor = mastV2 ? '#5A0000' : '#5a0000';
  const highColor = mastV2 ? '#F44336' : '#d23430';
  const mediumColor = mastV2 ? '#FF9800' : '#f0ad4e';
  const lowColor = mastV2 ? '#2196F3' : '#17a2b8';

  const chartData = {
    labels: ['Critical', 'High', 'Medium', 'Low'],
    datasets: [
      {
        data: getRiskCounts(appIssuesByRisk),
        backgroundColor: [criticalColor, highColor, mediumColor, lowColor],
      },
    ],
  };

  // Data in string format to be copied to the clipboard
  const copyString = `${chartData.labels.join(
    ',',
  )}\n${chartData.datasets[0].data.join(',')}`;

  return mastV2 ? (
    <QCard className="card-accent-secondary">
      <QCardHeader
        title={header}
        action={
          <span>
            <CopyToClipboard
              text={copyString}
              onCopy={() =>
                toastrHelper.showSuccessToast(
                  'Copied results to clipboard',
                  null,
                  mastV2,
                )
              }
            >
              <i className="fa-solid fa-paste pointer" />
            </CopyToClipboard>
            {isLoading && <i className="fa-solid fa-spinner fa-spin" />}
          </span>
        }
      />
      <QCardBody>
        <QDoughnut
          data={chartData}
          height={300}
          options={{
            maintainAspectRatio: false,
            plugins: {
              datalabels: {
                display: context =>
                  context.dataset.data[context.dataIndex] >= 1,
                color: 'white',
              },
              title: {
                display: true,
                text: `Number of analysis issues of ${appType} apps categorized by risk level`,
                position: 'bottom',
                font: {
                  weight: 'normal',
                  style: 'italic',
                },
              },
            },
          }}
        />
      </QCardBody>
    </QCard>
  ) : (
    <QCard className="card-accent-secondary">
      <QCardHeader>
        <div className="d-flex justify-between">
          <strong>{header}</strong>
          <span>
            <CopyToClipboard
              text={copyString}
              onCopy={() =>
                toastrHelper.showSuccessToast(
                  'Copied results to clipboard',
                  null,
                  mastV2,
                )
              }
            >
              <i className="fa-solid fa-paste pointer" />
            </CopyToClipboard>
            {isLoading && <i className="fa-solid fa-spinner fa-spin" />}
          </span>
        </div>
      </QCardHeader>
      <QCardBody>
        <Pie
          data={chartData}
          height={300}
          options={{
            maintainAspectRatio: false,
            plugins: {
              datalabels: {
                display: context =>
                  context.dataset.data[context.dataIndex] >= 1,
                color: 'white',
              },
              title: {
                display: true,
                text: `Number of analysis issues of ${appType} apps categorized by risk level`,
                position: 'bottom',
                font: {
                  weight: 'normal',
                  style: 'italic',
                },
              },
            },
          }}
        />
      </QCardBody>
    </QCard>
  );
};

export const IssuesByRisk = withLDConsumer()(LDIssuesByRisk);
