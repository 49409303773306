import React from 'react';

import { QChip } from './Q-Components/QChip';

export function SubmissionTagBadge({ submissionTag }) {
  return (
    <QChip
      variant="primaryDark"
      color="primary"
      style={{ color: 'white', marginRight: '10px' }}
      label={submissionTag}
    >
      {submissionTag}
    </QChip>
  );
}
