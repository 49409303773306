import React from 'react';
import { CardText } from 'reactstrap';

import DataTable from '../../../DataTable/DataTable';
import StaticAnalysisFunctionsDatatable from '../../iOS/StaticAnalysisFunctionsDatatable';
import MissingCompileProtectionEvidence from '../../SimpleReport/ReportFindingCard/IosEvidence/MissingCompileProtectionEvidence';

export const V2_10 = ({ platform, evidence }) =>
  platform === 'android' ? (
    <>
      <CardText>
        <strong>Stack Protection</strong>
      </CardText>
      {evidence.stackProtection && evidence.stackProtection.length > 0 ? (
        <DataTable
          data={evidence.stackProtection}
          columns={[
            {
              label: 'Binary',
              component: ({ rowData }) => <p>{rowData}</p>,
            },
          ]}
        />
      ) : (
        <CardText>
          All libraries packaged with the app have sufficient stack protection
          flags.
        </CardText>
      )}
      <hr />
      <CardText>
        <strong>Memory Access Protection</strong>
      </CardText>
      {evidence.mProtection && evidence.mProtection.length > 0 ? (
        <DataTable
          data={evidence.mProtection}
          columns={[
            {
              label: 'Binary',
              component: ({ rowData }) => <p>{rowData}</p>,
            },
          ]}
        />
      ) : (
        <CardText>
          No improper usage of memory access protection (mprotect) for memory
          mapping was found in the application.
        </CardText>
      )}
    </>
  ) : (
    <>
      <CardText>
        <strong>Compile Protection</strong>
      </CardText>
      <MissingCompileProtectionEvidence />
      <hr />
      <CardText>
        <strong>Static Analysis</strong>
      </CardText>
      <StaticAnalysisFunctionsDatatable />
    </>
  );
