import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

export default function SbomVulnerabilitiesDataTable() {
  const sbomResults = useSelector(
    ({ appResults }) => appResults.sbomResults ?? {},
  );

  const { sbom: sbomAccess } = useSelector(({ emmApp }) => emmApp.userAccess);

  if (_.isEmpty(sbomResults)) {
    return (
      <div>
        {!sbomAccess ? (
          <p>
            Your user currently does not have permission to view SBOM analysis
            details. Contact your Administrator or
            <a href="mailto:sales@quokka.io"> sales@quokka.io</a> to view the
            full SBOM results and details on the CVEs detected.
          </p>
        ) : (
          <p> SBOM results is not available</p>
        )}
      </div>
    );
  }
  const vulnerabilities = _.get(sbomResults, 'vulnerabilities', {});

  return (
    <div>
      {vulnerabilities.length === 0 ? (
        <span>{i18n.t('No vulnerabilities were found')}</span>
      ) : (
        <DataTable
          filterable={false}
          data={vulnerabilities}
          columns={[
            {
              label: i18n.t('ID'),
              keyName: 'id',
              filterable: true,
            },
            {
              label: i18n.t('Description'),
              keyName: 'description',
              width: '40%',
            },
            {
              label: i18n.t('Source'),
              keyName: 'source',
              component: ({ value }) => <a href={value.url}>{value.name}</a>,
            },
            {
              label: i18n.t('Score'),
              keyName: 'ratings',
              component: ({ value }) => <>{value[0]?.score}</>,
            },
            {
              label: i18n.t('Severity'),
              keyName: 'ratings',
              component: ({ value }) => <>{value[0]?.severity}</>,
            },
            {
              label: i18n.t('Method'),
              keyName: 'ratings',
              component: ({ value }) => <>{value[0]?.method}</>,
            },
            {
              label: i18n.t('Vector'),
              keyName: 'ratings',
              component: ({ value }) => <>{value[0]?.vector}</>,
            },
          ]}
        />
      )}
    </div>
  );
}
