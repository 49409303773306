/* istanbul ignore file */
import React from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const LaunchDarklyIdentifier = ({ id, email, groupId, ldClient }) => {
  // Wait for LaunchDarkly to connect and provide client before attempting to identify the user.
  if (ldClient && ldClient.identify) {
    ldClient.identify({
      kind: 'user',
      email,
      key: id,
      name: email,
      groupId,
    });
  }

  return <></>;
};

export default withLDConsumer({ clientOnly: true })(LaunchDarklyIdentifier);
