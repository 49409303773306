import React from 'react';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';

const PermissionsRequestedTable = ({ results, filter = '' }) => {
  const { permissions_requested: requestedPerms } =
    results.analysis.analysis_details.static_analysis;

  return (
    <div>
      {/* Permissions Requested */}
      <Table size="sm">
        <tbody>
          {Object.entries(requestedPerms)
            .filter(([perm]) => perm.includes(filter))
            .map(([perm, link]) => (
              <tr key={perm}>
                <td>
                  <a className="no-link" href={link} target="_blank">
                    {perm}
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

const mapStateToProps = ({ appResults }) => ({
  results: appResults.results,
});

export default connect(mapStateToProps)(PermissionsRequestedTable);
