import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { setHelpHtml } from '../store/slices/emmAppSlice';

import helpHtml from './PortalHelp';

const HelpButton = ({ helpIsOpen, helpItem }) => {
  const useDispatchHelp = helpItem => {
    const dispatch = useDispatch();
    return () => dispatch(setHelpHtml(helpItem));
  };

  const setHelp = useDispatchHelp(helpItem);

  const getHelp = () => {
    console.log('getHelp:', helpItem);
    try {
      const res = helpHtml[helpItem];

      if (res) {
        setHelp();
        // Show the help section of the document if it's not already being shown
        if (document.body.classList.contains('aside-menu-hidden')) {
          document.body.classList.toggle('aside-menu-hidden');
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (!helpIsOpen) {
    return null;
  }

  return (
    <i
      className="fa-solid fa-circle-question pointer animated fadeIn"
      onClick={getHelp}
    />
  );
};

const mapStateToProps = ({ emmApp }) => ({
  helpIsOpen: emmApp.helpAsideOpen,
});

export default connect(mapStateToProps)(HelpButton);
