import React from 'react';
import { Button } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QButton as MuiButton } from '@kw/quokka-ui';

const LDButton = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  const filteredProps = ['className', 'size', 'color'];

  const muiButtonProps = Object.keys(props).reduce((acc, key) => {
    if (!filteredProps.includes(key)) {
      acc[key] = props[key];
    }
    return acc;
  }, {});

  return mastV2 ? (
    <MuiButton data-testid="mui-button" {...muiButtonProps}>
      {children}
    </MuiButton>
  ) : (
    <Button data-testid="bootstrap-button" {...props}>
      {children}
    </Button>
  );
};

export const QButton = withLDConsumer()(LDButton);
