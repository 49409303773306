import React from 'react';
import { CardText } from 'reactstrap';

import DynamicAnalysisDatatable from '../../Android/DynamicAnalysisDatatable';
import AndroidPermissionsRequestedTable from '../../Android/PermissionsRequestedTable';
import IOSPermissionsRequestedTable from '../../iOS/PermissionsRequestedDatatable';
import SystemFrameworksDatatable from '../../iOS/SystemFrameworksDatatable';

export const V4_8 = ({ platform }) => (
  <>
    {platform === 'android' ? (
      <>
        <CardText>
          <strong>Permissions Requested</strong>
        </CardText>
        <AndroidPermissionsRequestedTable filter="USE_BIOMETRIC" />
        <hr />
        <CardText>
          <strong>Dynamic Analysis</strong>
        </CardText>
        <DynamicAnalysisDatatable paramFilter="Biometric" />
      </>
    ) : (
      <>
        <CardText>
          <strong>Permissions Requested</strong>
        </CardText>
        <IOSPermissionsRequestedTable filter="Face ID" />
        <CardText>
          <strong>System Framework</strong>
        </CardText>
        <SystemFrameworksDatatable filter="LocalAuthentication.framework" />
      </>
    )}
  </>
);
