import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QTableBody as MuiTableBody } from '@kw/quokka-ui';

const LDTableBody = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiTableBody {...props}>{children}</MuiTableBody>
  ) : (
    <tbody {...props}>{children}</tbody>
  );
};
export const QTableBody = withLDConsumer()(LDTableBody);
