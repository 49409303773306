import React from 'react';
import { Input } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QCheckbox as MuiCheckbox } from '@kw/quokka-ui';

const LDCheckbox = ({ ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiCheckbox {...props} />
  ) : (
    <Input type="checkbox" {...props} />
  );
};
export const QCheckbox = withLDConsumer()(LDCheckbox);
