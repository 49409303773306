import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

class NiapNav extends Component {
  render() {
    const { niapIds, niapIssues, filter } = this.props;
    return (
      <Card className="card-accent-secondary">
        <CardHeader>NIAP Issues</CardHeader>
        <CardBody style={{ padding: 0 }}>
          <ListGroup>
            {niapIds.map((finding, index) => {
              let color;
              if (niapIssues[finding].result === 'pass') {
                color = 'success';
              } else if (niapIssues[finding].result === 'fail') {
                color = 'danger';
              } else if (niapIssues[finding].result === 'review') {
                color = 'warning';
              } else color = '';

              if (filter !== '' && niapIssues[finding].result === filter) {
                return (
                  <Link
                    key={index}
                    to={`#niap-${finding}`}
                    scroll={el => {
                      el.scrollIntoView(true);
                      window.scrollBy(0, -64);
                    }}
                  >
                    <ListGroupItem color={color}>
                      <span>{finding}</span>
                    </ListGroupItem>
                  </Link>
                );
              }
              return (
                <Link
                  key={index}
                  to={`#niap-${finding}`}
                  scroll={el => {
                    el.scrollIntoView(true);
                    window.scrollBy(0, -64);
                  }}
                >
                  <ListGroupItem color={color}>
                    <span>{finding}</span>
                  </ListGroupItem>
                </Link>
              );
            })}
          </ListGroup>
        </CardBody>
      </Card>
    );
  }
}

export default NiapNav;
