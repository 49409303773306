import React from 'react';
import _ from 'lodash';

import { QListGroup } from '../Q-Components/QListGroup';
import { QListGroupItem } from '../Q-Components/QListGroupItem';

const VersionHosts = ({ trafficSelf, trafficOther }) => {
  if (
    !_.get(trafficSelf, 'log.entries') ||
    !_.get(trafficOther, 'log.entries')
  ) {
    return <div>No hosts contacted</div>;
  }

  const hostsUrlOther = [];
  for (const entry of trafficOther.log.entries) {
    const domain = entry.request.url.substring(
      0,
      entry.request.url.indexOf('/', 8),
    );

    if (!hostsUrlOther.includes(domain)) {
      hostsUrlOther.push(domain);
    }
  }
  const hostsUrlSelf = [];
  for (const entry of trafficSelf.log.entries) {
    const domain = entry.request.url.substring(
      0,
      entry.request.url.indexOf('/', 8),
    );

    if (!hostsUrlSelf.includes(domain)) {
      hostsUrlSelf.push(domain);
    }
  }

  return (
    <div>
      <QListGroup
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          maxHeight: '200px',
          overflow: 'scroll',
        }}
      >
        {hostsUrlSelf.map(url => (
          <QListGroupItem
            color={hostsUrlOther.includes(url) ? '' : 'warning'}
            sx={
              hostsUrlOther.includes(url) ? {} : { backgroundColor: '#FFB822' }
            }
          >
            <div>{url}</div>
          </QListGroupItem>
        ))}
      </QListGroup>
    </div>
  );
};

export default VersionHosts;
