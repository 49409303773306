import React from 'react';

import SensitiveDataExposureDatatable from '../../SensitiveDataExposureDatatable';

import { Issues } from './Issues';

export const V2_4 = ({ evidence }) => (
  <>
    <Issues
      evidence={evidence}
      issues={['PII_EXPOSURE', 'PII_EXPOSURE_LOW_RISK']}
    />
    <hr />
    <SensitiveDataExposureDatatable />
  </>
);
