import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import FindingsNav from './TaiwanFindings/FindingsNav';
import TaiwanFinding from './TaiwanFindings/TaiwanFinding';

class TaiwanFindings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      results: null,
      loading: true,
      findingsNotAvailable: false,
    };
  }

  async componentDidMount() {
    const { farmAppId } = this.props.match.params;

    console.log('Getting Taiwan analysis results for app:', farmAppId);
    const response = await axios.get(`api/results/taiwan?uuid=${farmAppId}`);

    if (response.status === 200) {
      this.setState({
        results: response.data,
        loading: false,
      });
    } else {
      console.log('Error getting Taiwan results!');
      this.setState({ findingsNotAvailable: true, loading: false });
    }
  }

  // Will sort findings in order of fail -> review -> pass
  sortFindingByResult(a, b) {
    if (a.result === b.result) return 0;
    if (!a.result) return 1;
    if (!b.result) return -1;
    if (a.result === 'fail') return -1;
    if (a.result === 'review' && b.result === 'pass') return -1;
    if (a.result === 'review' && b.result === 'fail') return 1;
    if (a.result === 'pass') return 1;

    console.log('No case found:', a, b);
    return 0;
  }

  // Will sort findings in order of ID
  sortFindingById(v1, v2) {
    //  vnum stores each numeric part of version
    let vnum1 = 0;
    let vnum2 = 0;
    let i = 0;
    let j = 0;

    //  loop until both string are processed

    while (i < v1.length || j < v2.length) {
      //  storing numeric part of version 1 in vnum1
      while (i < v1.length && v1[i] !== '.') {
        vnum1 *= 10;
        vnum1 += v1[i] - '0';
        i++;
      }

      //  storing numeric part of version 2 in vnum2
      while (j < v2.length && v2[j] !== '.') {
        vnum2 *= 10;
        vnum2 += v2[j] - '0';
        j++;
      }

      if (vnum1 > vnum2) return 1;
      if (vnum2 > vnum1) return -1;

      //  if equal, reset variables and go for next numeric
      // part
      vnum1 = 0;
      vnum2 = 0;
      i++;
      j++;
    }
    return 0;
  }

  parseFindings(findings) {
    // Add the finding ID key to the finding JSON
    const flatFindings = Object.entries(findings).map(
      ([findingId, finding]) => ({
        ...finding,
        id: findingId,
      }),
    );

    // Filter out TBD findings
    const filteredFindings = flatFindings.filter(
      finding => finding.activity !== 'TBD',
    );

    return filteredFindings.sort(this.sortFindingById);
  }

  render() {
    const { results, findingsNotAvailable, loading } = this.state;
    const { platform } = this.props;
    if (loading) {
      return <div>Loading...</div>;
    }
    if (findingsNotAvailable) {
      return <div>Findings not available</div>;
    }

    const parsedFindings = this.parseFindings(results);

    return (
      <Row>
        <Col xs="2">
          <FindingsNav results={parsedFindings} />
        </Col>
        <Col xs="10">
          <Row>
            {parsedFindings.map(finding => (
              <Col xs="6" key={finding.id} id={`taiwan-${finding.id}`}>
                <TaiwanFinding finding={finding} platform={platform} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withRouter(TaiwanFindings);
