import React from 'react';

import { getAssets } from '../../assets/assets';
import { QLink } from '../Q-Components/QLink';

export const getBanners = () => {
  const appliance = process.env.REACT_APP_APPLIANCE || 'default';

  const applianceName = getAssets[appliance].name;
  const applianceDocumentationUrl = getAssets[appliance].documentationUrl;

  return {
    // This banner will be removed when we officially switch over
    newUIBanner: {
      true: {
        title: 'Introducing our new UI',
        message: (
          <>
            The {applianceName} Portal has a new look! Please visit our{' '}
            <QLink href={applianceDocumentationUrl} target="_blank">
              Zendesk Documentation
            </QLink>{' '}
            to learn more about the changes.
          </>
        ),
      },
      false: {
        title: 'Upcoming UI Refresh',
        message: (
          <>
            The {applianceName} Portal will be receiving a new look! Please
            visit our{' '}
            <QLink href={applianceDocumentationUrl} target="_blank">
              Zendesk Documentation
            </QLink>{' '}
            to learn more about the upcoming changes.
          </>
        ),
      },
    },
  };
};
