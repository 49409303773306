import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CloseIcon from '@mui/icons-material/Close';

import * as actions from '../../store/slices/emmAppSlice';
import { QModal } from '../Q-Components/QModal';
import { QModalTitle } from '../Q-Components/QModalTitle';
import { QModalContent } from '../Q-Components/QModalContent';
import { QModalActions } from '../Q-Components/QModalActions';
import { QButton } from '../Q-Components/QButton';
import { QSwitch } from '../Q-Components/QSwitch';
import { QSwitchControlLabel } from '../Q-Components/QSwitchControlLabel';
import { QTypography } from '../Q-Components/QTypography';
import { QRow } from '../Q-Components/QRow';

export const AnalyzedAppsSettingsModal = ({ isOpen, toggle }) => {
  const { mastV2 } = useFlags();
  const { columnSettings } = useSelector(state => state.emmApp);
  const [dateCol, setDateCol] = useState(columnSettings.dateCol);
  const [scoreCol, setScoreCol] = useState(columnSettings.scoreCol);
  const [findingsCol, setFindingsCol] = useState(columnSettings.findingsCol);
  const [subGroupsCol, setSubGroupsCol] = useState(columnSettings.subGroupsCol);
  const [analysisTimeCol, setAnalysisTimeCol] = useState(
    columnSettings.analysisTimeCol,
  );
  const [submittingUserCol, setSubmittingUserCol] = useState(
    columnSettings.submittingUserCol,
  );
  const { userSubGroups } = useSelector(state => state.emmApp);
  const { groupAdminGroup: groupConfig } = useSelector(state => state.emmApp);

  const dispatch = useDispatch();

  function handleSettings() {
    dispatch(
      actions.setColumnSettings({
        dateCol,
        scoreCol,
        findingsCol,
        subGroupsCol,
        analysisTimeCol,
        submittingUserCol,
      }),
    );
    toggle();
  }

  const ModalSwitch = ({ onChange, value, label }) =>
    mastV2 ? (
      <QRow>
        <QSwitchControlLabel
          label={
            <QTypography variant="bodyRegular" color="#000000">
              {label}
            </QTypography>
          }
          control={<QSwitch size="small" checked={value} onChange={onChange} />}
        />
      </QRow>
    ) : (
      <QSwitch onChange={onChange} value={value} label={label} />
    );
  const ModalTitle = () => (
    <QModalTitle onClose={toggle} closeIcon={<CloseIcon />}>
      Analyzed App Table Settings
    </QModalTitle>
  );
  const ModalContent = () => (
    <QModalContent>
      {mastV2 ? (
        <QRow sx={{ marginTop: '24px', marginBottom: '24px' }}>
          <QTypography variant="bodyRegular" color="#000000">
            Columns Shown
          </QTypography>
        </QRow>
      ) : (
        <h6 className="mb-3">Columns Shown</h6>
      )}
      <ModalSwitch
        onChange={() => setAnalysisTimeCol(!analysisTimeCol)}
        value={analysisTimeCol}
        label="Analysis Time"
      />
      <ModalSwitch
        onChange={() => setDateCol(!dateCol)}
        value={dateCol}
        label="Date Submitted"
      />
      <ModalSwitch
        onChange={() => setScoreCol(!scoreCol)}
        value={scoreCol}
        label="Threat Score"
      />
      <ModalSwitch
        onChange={() => setFindingsCol(!findingsCol)}
        value={findingsCol}
        label="Important Risk Findings"
      />
      {groupConfig.length > 0 && groupConfig[0].viewSubmittingUser && (
        <ModalSwitch
          onChange={() => setSubmittingUserCol(!submittingUserCol)}
          value={submittingUserCol}
          label="View Submitting User"
        />
      )}
      {userSubGroups.length > 0 && (
        <ModalSwitch
          onChange={() => setSubGroupsCol(!subGroupsCol)}
          value={subGroupsCol}
          label="Sub Groups"
        />
      )}
    </QModalContent>
  );

  const ModalActions = () => (
    <QModalActions>
      <QButton color="secondary" variant="outlined" onClick={toggle}>
        Cancel
      </QButton>
      <QButton
        color="primary"
        variant="filled"
        onClick={() => handleSettings()}
      >
        Save
      </QButton>
    </QModalActions>
  );
  return (
    <QModal
      open={isOpen}
      isOpen={isOpen}
      toggle={toggle}
      title={<ModalTitle />}
      content={<ModalContent />}
      actions={<ModalActions />}
    >
      <ModalTitle />
      <ModalContent />
      <ModalActions />
    </QModal>
  );
};

export default AnalyzedAppsSettingsModal;
