import React from 'react';
import { Table } from 'reactstrap';

export default function DevInfo({ data }) {
  const prettyPrint = value => value || 'Not Available';
  return (
    <Table>
      <tbody>
        <tr>
          <td>
            <strong>App URL:</strong> {data.app_url}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Developer Website:</strong> {prettyPrint(data.dev_web)}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Developer Email:</strong>{' '}
            {prettyPrint(data.developer_email)}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Developer Store:</strong> {prettyPrint(data.developer_link)}
          </td>
        </tr>
        <tr>
          <td>
            <strong>EULA:</strong> {prettyPrint(data.eula)}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Seller URL:</strong> {prettyPrint(data.seller_url)}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Seller Name:</strong> {prettyPrint(data.sellername)}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Support URL:</strong> {prettyPrint(data.support_url)}
          </td>
        </tr>
      </tbody>
    </Table>
  );
}
