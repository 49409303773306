import { useState, useEffect } from 'react';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // ignore write errors
  }
};

export function useLocalStorage(key, initialValue) {
  const PREFIX = 'quokka-';

  const prefixedKey = PREFIX + key;
  // key should expire after a day
  const expiry = 24 * 60 * 60 * 1000;

  const [value, _setValue] = useState(() => {
    const jsonValue = localStorage.getItem(prefixedKey);
    if (jsonValue != null) {
      const parsedJson = JSON.parse(jsonValue);
      if (parsedJson.timestamp + expiry > Date.now()) {
        return parsedJson.value;
      }
    }
    if (typeof initialValue === 'function') {
      return initialValue();
    }
    return initialValue;
  });

  useEffect(() => {
    localStorage.setItem(
      prefixedKey,
      JSON.stringify({ value, timestamp: Date.now() }),
    );
  }, [prefixedKey, value]);

  const setValue = val => {
    localStorage.setItem(
      prefixedKey,
      JSON.stringify({ value: val, timestamp: Date.now() }),
    );
    _setValue(val);
  };

  return [value, setValue];
}
