import React from 'react';
import { NavLink } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QSidebarItemButton as MuiSidebarItemButton } from '@kw/quokka-ui';

const LDSidebarItemButton = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiSidebarItemButton {...props}>{children}</MuiSidebarItemButton>
  ) : (
    <NavLink {...props}>{children}</NavLink>
  );
};
export const QSidebarItemButton = withLDConsumer()(LDSidebarItemButton);
