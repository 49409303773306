import React, { Component } from 'react';
import moment from 'moment';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

import DataTable from '../../../DataTable/DataTable';

export default class HttpsCerts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCert: '',
      showCertModal: false,
    };

    this.selectCert = this.selectCert.bind(this);
  }

  selectCert(cert) {
    this.setState({
      selectedCert: cert,
      showCertModal: true,
    });
  }

  render() {
    const { selectedCert, showCertModal } = this.state;
    const { data } = this.props;

    return (
      <div>
        <Modal
          isOpen={showCertModal}
          toggle={() => this.setState({ showCertModal: !showCertModal })}
          size="lg"
        >
          <ModalHeader>Server SSL Certificate</ModalHeader>
          <ModalBody>{selectedCert}</ModalBody>
        </Modal>
        <DataTable
          data={data}
          columns={[
            { label: 'Connection', keyName: 'connection' },
            { label: 'Cipher Name', keyName: 'cipher_name' },
            { label: 'TLS Version', keyName: 'tls_version' },
            {
              label: 'Certificate',
              keyName: 'server_cert',
              component: ({ value }) => (
                <Button onClick={() => this.selectCert(value)}>Show</Button>
              ),
            },
            {
              label: 'Issuer',
              keyName: 'issuer',
            },
            {
              label: 'Validation Date',
              keyName: 'server_cert_validationdate',
              component: ({ value }) => (
                <span>{moment(value).format('L - LTS')}</span>
              ),
            },
          ]}
        />
      </div>
    );
  }
}
