import React from 'react';
import { ModalBody } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QModalContent as MuiModalContent } from '@kw/quokka-ui';

const LDModalContent = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiModalContent {...props}>{children}</MuiModalContent>
  ) : (
    <ModalBody {...props}>{children}</ModalBody>
  );
};
export const QModalContent = withLDConsumer()(LDModalContent);
