import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Label } from 'reactstrap';

import { sortIssuesByRisk } from '../../logic/generalReportUtil';

import ReportFindingCard from './SimpleReport/ReportFindingCard';
import SimpleReportSummary from './SimpleReport/SimpleReportSummary';
import ReportTour from './ReportTour';

export default function SimpleReport({ platform }) {
  const { riskLevelFilter } = useSelector(state => state.emmApp);
  const { parsedIssues } = useSelector(state => state.appResults);

  const filterOptions = [
    {
      value: 'all',
      label: 'All',
    },
    {
      value: 'niap',
      label: 'NIAP',
    },
    {
      value: 'owasp',
      label: 'OWASP',
    },
    {
      value: 'gdpr',
      label: 'GDPR',
    },
  ];

  const [filterValue, setFilterValue] = useState(filterOptions[0]);
  const [filterParsedIssue, setFilterParsedIssue] = useState(parsedIssues);

  useEffect(() => {
    setFilterParsedIssue(parsedIssues);
  }, [parsedIssues]);

  function handleFilterInput(option) {
    setFilterValue(option);
    if (option.value !== 'all')
      setFilterParsedIssue(
        parsedIssues.filter(
          issue =>
            Array.isArray(issue[option.value]) &&
            issue[option.value].length > 0,
        ),
      );
    else {
      setFilterParsedIssue(parsedIssues);
    }
  }

  return (
    <div>
      <ReportTour />
      <div className="d-flex m-2" style={{ justifyContent: 'flex-end' }}>
        <div>
          <Label for="regulation-filter">
            <strong>Filter by regulation:</strong>
          </Label>
          <Select
            id="regulation-filter"
            value={filterValue}
            options={filterOptions}
            onChange={handleFilterInput}
            clearable={false}
            isSearchable={false}
          />
        </div>
      </div>

      <SimpleReportSummary foundIssues={filterParsedIssue} />

      {filterParsedIssue
        .filter(issue => issue.found && issue.review_status !== 'passed')
        .sort(sortIssuesByRisk)
        .filter(issue => {
          if (!riskLevelFilter) {
            return true;
          }
          return issue.risk === riskLevelFilter;
        })
        .map((issue, index) => (
          <ReportFindingCard key={index} platform={platform} issue={issue} />
        ))}
    </div>
  );
}
