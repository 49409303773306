import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Collapse } from 'reactstrap';
import { Collapse as MuiCollapse } from '@mui/material';

const LDCollapse = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiCollapse {...props}>{children}</MuiCollapse>
  ) : (
    <Collapse {...props}>{children}</Collapse>
  );
};
export const QCollapse = withLDConsumer()(LDCollapse);
