import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { connect } from 'react-redux';

import i18n from '../../../localization/i18n';

import StaticAnalysisFunctionsDatatable from './StaticAnalysisFunctionsDatatable';

const StaticAnalysisFunctions = ({ results }) => {
  const staticFunctions =
    results.analysis.analysis_details.static_analysis.xrefs;

  if (!staticFunctions) {
    return null;
  }

  return (
    <Card className="card-accent-info">
      <CardHeader>{i18n.t('Static Analysis Functions')}</CardHeader>
      <CardBody>
        <StaticAnalysisFunctionsDatatable />
      </CardBody>
    </Card>
  );
};

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(StaticAnalysisFunctions);
