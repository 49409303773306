import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import DataTable from '../../DataTable/DataTable';

export default function CertificatesAndKeys() {
  const [showDataDetail, setShowDataDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [keyType, setKeyType] = useState();

  const certificates = useSelector(state =>
    _.get(
      state,
      'appResults.results.analysis.analysis_details.static_analysis.packaged_certificates',
    ),
  );
  const privateKeys = useSelector(state =>
    _.get(
      state,
      'appResults.results.analysis.analysis_details.static_analysis.packaged_private_keys',
    ),
  );
  const publicKeys = useSelector(state =>
    _.get(
      state,
      'appResults.results.analysis.analysis_details.static_analysis.packaged_public_keys',
    ),
  );

  function handleRowClick(value, keyType) {
    setShowDataDetail(true);
    setDataDetail(value);
    setKeyType(keyType);
    console.log(value);
  }

  return (
    <div>
      <Modal
        isOpen={showDataDetail}
        size="lg"
        toggle={() => setShowDataDetail(!showDataDetail)}
      >
        <ModalHeader>Package Detail</ModalHeader>
        <ModalBody>
          <p>
            <span className="bold">File </span>
            <span>{dataDetail.File}</span>
          </p>
          <p className="bold">{keyType}</p>
          <p>{dataDetail[keyType]}</p>
          <p className="bold">Contents</p>
          <p>{dataDetail.Contents}</p>
        </ModalBody>
      </Modal>

      <Card className="card-accent-success">
        <CardHeader>Certificates & Keys</CardHeader>
        <CardBody>
          {Array.isArray(certificates) && certificates.length !== 0 && (
            <DataTable
              data={certificates}
              onRowClick={row => {
                handleRowClick(row, 'Certificate');
              }}
              columns={[
                {
                  keyName: 'Certificate',
                  label: 'Certificate',
                },
                {
                  keyName: 'Contents',
                  label: 'Contents',
                },
              ]}
              showFilter={false}
              hideOverflow
            />
          )}
          {Array.isArray(privateKeys) && privateKeys.length !== 0 && (
            <DataTable
              data={privateKeys}
              onRowClick={row => {
                handleRowClick(row, 'Key');
              }}
              columns={[
                {
                  keyName: 'Key',
                  label: 'Private Key',
                },
                {
                  keyName: 'Contents',
                  label: 'Contents',
                },
              ]}
              showFilter={false}
            />
          )}
          {Array.isArray(publicKeys) && publicKeys.length !== 0 && (
            <DataTable
              data={publicKeys}
              onRowClick={row => {
                handleRowClick(row, 'Key');
              }}
              columns={[
                {
                  keyName: 'Key',
                  label: 'Public Key',
                },
                {
                  keyName: 'Contents',
                  label: 'Contents',
                },
              ]}
              showFilter={false}
            />
          )}
          {(!Array.isArray(certificates) || certificates.length === 0) &&
            (!Array.isArray(privateKeys) || privateKeys.length === 0) &&
            (!Array.isArray(publicKeys) || publicKeys.length === 0) && (
              <span>No Certificates or Keys available</span>
            )}
        </CardBody>
      </Card>
    </div>
  );
}
