import React from 'react';
import { Table } from 'reactstrap';

/**
 * Dynamically generates a single level table. Used for Niap Evidences
 * @param { object } props
 * @param { any[] } props.data array to render
 */
export const DynamicTable = ({ data = [] }) => {
  if (data.length === 0) {
    return null;
  }
  const headers = Object.keys(data[0]);

  return (
    <Table>
      <thead>
        <tr>
          {headers.map(datum => (
            <th key={JSON.stringify(datum)}>{datum}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((datum, index) => (
          <tr key={index}>
            {headers.map(key => (
              <td key={key}>
                <div
                  style={{
                    overflowWrap: 'anywhere',
                    maxWidth: `${550 / headers.length}px`,
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    '-webkit-line-clamp': '5',
                    overflow: 'hidden',
                    '-webkit-box-orient': 'vertical',
                    fontSize: '10px',
                  }}
                >
                  {datum[key]}
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
