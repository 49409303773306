import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getCodeFromCountryName } from '../../logic/countryUtil';

class CountriesContactedList extends Component {
  getCountriesContacted(latLongData, foreignConnections, platform) {
    if (platform === 'ios') {
      return latLongData.reduce((final, current) => {
        if (current.country && !final.includes(current.country)) {
          final.push(current.country);
        }
        return final;
      }, []);
    }
    return foreignConnections.reduce(
      (final, current) => {
        if (!final.includes(current.Country)) {
          final.push(current.Country);
        }
        return final;
      },
      ['United States'],
    );
  }

  getCountryCode(country) {
    if (country === 'Europe') {
      return 'eu';
    }
    const countryCode = getCodeFromCountryName(country);
    if (!countryCode) {
      return '';
    }

    return countryCode.toLowerCase();
  }

  render() {
    const { platform, results } = this.props;

    const latLongData =
      platform === 'android'
        ? _.get(results, 'analysis.high_level_issues.lat_long_data', {})
        : _.get(results, 'analysis.high_level_issues.geolocation_info', {});

    const foreignConnections =
      results.analysis.high_level_issues.foreign_connections;

    const totalConnections = [
      ...Object.values(latLongData.url_constants ?? []),
      ...Object.values(latLongData.connections ?? []),
    ];

    return (
      <div>
        {this.getCountriesContacted(
          totalConnections,
          foreignConnections,
          platform,
        ).map(country => (
          <p key={country}>
            <span className={`fi fi-${this.getCountryCode(country)}`} />{' '}
            {country}
          </p>
        ))}
      </div>
    );
  }
}

const mapStateToProps = ({ appResults }) => ({
  results: appResults.results,
});

export default withRouter(connect(mapStateToProps)(CountriesContactedList));
