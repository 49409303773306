import React from 'react';
import axios from 'axios';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import 'chartjs-adapter-moment';
import { Pie, Line } from 'react-chartjs-2';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QTypography } from '../Q-Components/QTypography';
import { QDropdown } from '../Q-Components/QDropdown';
import { QIconButton } from '../Q-Components/QIconButton';
import { QDropdownMenu } from '../Q-Components/QDropdownMenu';
import { QDropdownToggle } from '../Q-Components/QDropdownToggle';
import { QDropdownSelectItem } from '../Q-Components/QDropdownSelectItem';
import { QDoughnut } from '../Q-Components/QDoughnut';
import { QDivider } from '../Q-Components/QDivider';
import { QAlert } from '../Q-Components/QAlert';
import { QCol } from '../Q-Components/QCol';
import { QRow } from '../Q-Components/QRow';
import timeGraphUtil from '../../logic/timeGraphUtil';
import { toastrHelper } from '../../logic/toastrHelper';
import { LicenseType } from '../KAI/Licenses/LicenseType';

export default function LicenseUtilization() {
  const { mastV2 } = useFlags();
  const license = useSelector(state => state.emmApp.license);
  const {
    uniqueApps,
    licenseApps,
    scansSinceRenewal,
    maxScans,
    recentScans,
    startDate,
    endDate,
    remainingDays,
    remainingApps,
    remainingScans,
    maxGroupUsers,
    licenseTypeId,
  } = license;

  const parsedScans = timeGraphUtil.fillEmptyDates(
    recentScans,
    moment(),
    30,
    'days',
  );
  const scanDates = parsedScans.datesArray;
  const scanCounts = parsedScans.countArray;

  const groupAdminUsers = useSelector(({ emmApp }) => emmApp.groupAdminUsers);

  const unlockedUsers = groupAdminUsers.filter(user => !user.locked);
  const lockedUsers = groupAdminUsers.filter(user => user.locked);

  const isCountingScansLicense =
    licenseTypeId === LicenseType.ONDEMAND ||
    licenseTypeId === LicenseType.TRIAL;

  let licenseLimitUnit;
  let licenseLimit;
  if (isCountingScansLicense) {
    licenseLimitUnit = 'Maximum Number of Scans';
    licenseLimit = maxScans;
  } else {
    licenseLimitUnit = 'Maximum Number of Applications';
    licenseLimit = licenseApps;
  }

  const downloadCsv = async () => {
    try {
      await axios.post('csv-request', {
        pageType: 'license',
        isCompressed: false,
      });

      toastrHelper.showSuccessToast(
        'Your request is being processed. Please look forward to an email with a link to download the requested file',
        null,
        mastV2,
      );
    } catch {
      toastrHelper.showErrorToast(
        'An error occured while requesting your license scans.',
        null,
        mastV2,
      );
    }
  };

  let remainingAppsChart = null;
  let remainingAppsAlert = null;
  if (licenseApps && !isCountingScansLicense) {
    remainingAppsChart = (
      <QCol xs="4" sx={{ width: '33%' }}>
        <QCard className="card-accent-primary" data-testid="test-apps-chart">
          <QCardHeader
            title={
              <QTypography variant="h4Bold" color="#000000">
                Unique Apps Analyzed
              </QTypography>
            }
          >
            <strong>Unique Apps Analyzed</strong>
          </QCardHeader>

          <QCardBody>
            <strong>
              <QTypography variant="h5Bold" color="#000000">
                License Apps:{' '}
              </QTypography>
            </strong>
            <QTypography variant="h5Regular" color="#000000">
              {licenseApps}
            </QTypography>
            <br />
            <strong>
              <QTypography variant="h5Bold" color="#000000">
                Apps Analyzed:{' '}
              </QTypography>
            </strong>
            <QTypography variant="h5Regular" color="#000000">
              {uniqueApps}
            </QTypography>
            <br />
            <strong>
              <QTypography variant="h5Bold" color="#000000">
                Apps Remaining:{' '}
              </QTypography>
            </strong>
            <QTypography variant="h5Regular" color="#000000">
              {licenseApps - uniqueApps}
            </QTypography>
            <QDivider
              style={{
                borderColor: '#BDBDBD',
                marginTop: '32px',
                marginBottom: '32px',
              }}
            />
            {mastV2 ? (
              <QDoughnut
                hideNumbers
                data={{
                  datasets: [
                    {
                      data: [uniqueApps, Math.max(licenseApps - uniqueApps, 0)],
                      backgroundColor: [
                        'rgba(255, 138, 101)',
                        'rgba(255, 138, 101, 0.4)',
                      ],
                    },
                  ],
                  labels: ['Apps Analyzed', 'Apps Remaining'],
                }}
                width={250}
                height={150}
                options={{
                  maintainAspectRatio: true,
                  responsive: true,
                }}
              />
            ) : (
              <Pie
                width={250}
                height={150}
                options={{
                  maintainAspectRatio: true,
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'left',
                      labels: {
                        boxWidth: 10,
                      },
                    },
                  },
                }}
                data={{
                  datasets: [
                    {
                      data: [uniqueApps, Math.max(licenseApps - uniqueApps, 0)],
                      backgroundColor: [
                        'rgba(255, 138, 101)',
                        'rgba(255, 138, 101, 0.4)',
                      ],
                    },
                  ],
                  labels: ['Apps Analyzed', 'Apps Remaining'],
                }}
              />
            )}
          </QCardBody>
        </QCard>
      </QCol>
    );

    remainingAppsAlert =
      remainingApps <= 0 ? (
        <>
          {mastV2 ? (
            <QAlert
              variant="outlined"
              severity="error"
              title={
                <QTypography variant="bodyBold">
                  Analyzed Apps reached limit
                </QTypography>
              }
            />
          ) : (
            <QAlert color="danger">
              <i className="fa-solid fa-rectangle-xmark mr-2" /> Analyzed Apps
              reached limit
            </QAlert>
          )}
        </>
      ) : remainingApps < 10 ? (
        <>
          {mastV2 ? (
            <QAlert
              variant="outlined"
              severity="warning"
              title={
                <QTypography variant="bodyBold">
                  Analyzed Apps remain {remainingApps}
                </QTypography>
              }
            />
          ) : (
            <QAlert color="warning">
              <i className="fa-solid fa-exclamation-circle mr-2" />
              Warning
              <p>Analyzed Apps remain {remainingApps}</p>
            </QAlert>
          )}
        </>
      ) : null;
  }

  // Show scans chart for On Demand license
  let remainingScansChart = null;
  let remainingScansAlert = null;
  if (maxScans && isCountingScansLicense) {
    remainingScansChart = (
      <QCol xs="4">
        <QCard className="card-accent-primary" data-testid="test-scans-chart">
          <QCardHeader
            title={
              <QTypography variant="h4Bold" color="#000000">
                Scans
              </QTypography>
            }
          >
            <strong>Scans</strong>
          </QCardHeader>

          <QCardBody>
            <strong>License Scans: </strong>
            {maxScans}
            <br />
            <strong>Scans Used: </strong>
            {scansSinceRenewal}
            <br />
            <strong>Scans Remaining: </strong>
            {remainingScans}
            <br />
            {mastV2 ? (
              <QDoughnut
                hideNumbers
                data={{
                  datasets: [
                    {
                      data: [scansSinceRenewal, Math.max(remainingScans, 0)],
                      backgroundColor: ['#ffa000', '#388e3c'],
                    },
                  ],
                  labels: ['Scans Used', 'Scans Remaining'],
                }}
                width={250}
                height={150}
                options={{
                  maintainAspectRatio: true,
                  responsive: true,
                }}
              />
            ) : (
              <Pie
                width={250}
                height={150}
                options={{
                  maintainAspectRatio: true,
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'left',
                      labels: {
                        boxWidth: 10,
                      },
                    },
                  },
                }}
                data={{
                  datasets: [
                    {
                      data: [scansSinceRenewal, Math.max(remainingScans, 0)],
                      backgroundColor: ['#ffa000', '#388e3c'],
                    },
                  ],
                  labels: ['Scans Used', 'Scans Remaining'],
                }}
              />
            )}
          </QCardBody>
        </QCard>
      </QCol>
    );
    remainingScansAlert =
      remainingScans <= 0 ? (
        <>
          {mastV2 ? (
            <QAlert
              variant="outlined"
              severity="error"
              title={
                <QTypography variant="bodyBold">
                  Analyzed Apps reached scans limit
                </QTypography>
              }
            />
          ) : (
            <QAlert color="danger">
              <i className="fa-solid fa-rectangle-xmark mr-2" /> Analyzed Apps
              reached scans limit
            </QAlert>
          )}
        </>
      ) : remainingScans < 10 ? (
        <>
          {mastV2 ? (
            <QAlert
              variant="outlined"
              severity="warning"
              title={
                <QTypography variant="bodyBold">
                  Analyzed Apps remain {remainingScans} scans
                </QTypography>
              }
            />
          ) : (
            <QAlert color="warning">
              <i className="fa-solid fa-exclamation-circle mr-2" />
              Warning
              <p>Analyzed Apps remain {remainingScans} scans</p>
            </QAlert>
          )}
        </>
      ) : null;
  }

  const remainingDaysAlert =
    remainingDays <= 0 ? (
      <>
        {mastV2 ? (
          <QAlert
            variant="outlined"
            severity="error"
            title={
              <QTypography variant="bodyBold">License expired</QTypography>
            }
            style={{ marginBottom: '10px' }}
          />
        ) : (
          <QAlert color="danger">
            <i className="fa-solid fa-rectangle-xmark mr-2" />
            License expired
          </QAlert>
        )}
      </>
    ) : remainingDays < 30 ? (
      <>
        {mastV2 ? (
          <QAlert
            variant="outlined"
            severity="warning"
            title={
              <QTypography variant="bodyBold">
                License expires in {Math.ceil(remainingDays)} days
              </QTypography>
            }
            style={{ marginBottom: '10px' }}
          />
        ) : (
          <QAlert color="warning">
            <i className="fa-solid fa-exclamation-circle mr-2" />
            Warning
            <p>License expires in {Math.ceil(remainingDays)} days</p>
          </QAlert>
        )}
      </>
    ) : null;

  return (
    <>
      <QRow
        sx={{ columnGap: '20px', width: '100%' }}
        style={{ marginBottom: '20px' }}
      >
        <QCol sx={{ width: '100%' }}>
          {remainingDaysAlert}
          {remainingAppsAlert}
          {remainingScansAlert}
        </QCol>
      </QRow>
      <QRow
        sx={{ columnGap: '20px', width: '100%' }}
        style={{ marginBottom: '20px' }}
      >
        <QCol xs="4" sx={{ width: '33%' }}>
          <QCard
            className="card-accent-primary"
            data-testid="test-license-information-card"
          >
            <QCardHeader
              title={
                <QTypography variant="h4Bold" color="#000000">
                  License Information
                </QTypography>
              }
              action={
                <div>
                  <QDropdown
                    variant="filled"
                    style={{
                      paddingTop: '0px',
                      paddingBottom: '0px',
                    }}
                    dropdown
                  >
                    <QDropdownToggle
                      size="sm"
                      disableAnimation
                      shrink={false}
                      style={{
                        transform: 'none',
                        overflow: 'visible',
                      }}
                      data-testid="test-license-information-csv-toggle"
                    >
                      <QIconButton>
                        <i
                          className="fa-solid fa-download"
                          style={{ fontSize: '20px', fontWeight: '400' }}
                        />
                      </QIconButton>
                    </QDropdownToggle>
                    <QDropdownMenu
                      variant="filled"
                      IconComponent={null}
                      value=""
                      displayEmpty
                      style={{
                        background: 'none',
                        paddingTop: '0px',
                        minWidth: 'unset',
                      }}
                      renderValue={() => {}}
                      onFocus={event => {
                        event.target.style.background = 'none';
                      }}
                      data-testid="download-menu"
                    >
                      {/* First item is always selected in MUI by default so this is to get rid of that behavior. */}
                      <QDropdownSelectItem
                        value=""
                        style={{ display: 'none' }}
                      />
                      <QDropdownSelectItem
                        value="csv"
                        onClick={async () => downloadCsv()}
                        data-testid="test-license-information-csv"
                      >
                        Scan list CSV
                      </QDropdownSelectItem>
                    </QDropdownMenu>
                  </QDropdown>
                </div>
              }
            >
              {!mastV2 && (
                <div className="d-flex justify-between">
                  <div className="d-flex justify-between">
                    <strong>License Information</strong>
                  </div>
                  <div className="d-flex justify-between">
                    <UncontrolledDropdown>
                      <DropdownToggle size="sm" color="link">
                        <i className="fa-solid fa-download black-icon" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={async () => downloadCsv()}
                          data-testid="test-license-information-csv"
                        >
                          <i className="fa-solid fa-file-excel pointer success-icon" />
                          Scan list CSV
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              )}
            </QCardHeader>
            <QCardBody>
              <strong>
                <QTypography variant="h5Bold" color="#000000">
                  License Type:{' '}
                </QTypography>
              </strong>
              <QTypography variant="h5Regular" color="#000000">
                {license.licenseType?.name}
              </QTypography>
              <br />
              <strong>
                <QTypography variant="h5Bold" color="#000000">
                  License Start Date:
                </QTypography>
              </strong>
              <QTypography variant="h5Regular" color="#000000">{` ${moment(
                startDate,
              ).format('L')}`}</QTypography>
              <br />
              <strong>
                <QTypography variant="h5Bold" color="#000000">
                  License End Date:
                </QTypography>
              </strong>
              <QTypography variant="h5Regular" color="#000000">{` ${moment(
                endDate,
              ).format('L')}`}</QTypography>
              <br />
              <strong>
                <QTypography variant="h5Bold" color="#000000">
                  Days Remaining:{' '}
                </QTypography>
              </strong>
              <QTypography variant="h5Regular" color="#000000">
                {Math.ceil(remainingDays)}
              </QTypography>
              <br />
              <strong>
                <QTypography variant="h5Bold" color="#000000">
                  {licenseLimitUnit}:{' '}
                </QTypography>
              </strong>
              <QTypography variant="h5Regular" color="#000000">
                {licenseLimit}
              </QTypography>
              <br />
              <strong>
                <QTypography variant="h5Bold" color="#000000">
                  Maximum Number of User Accounts:{' '}
                </QTypography>
              </strong>
              <QTypography variant="h5Regular" color="#000000">
                {maxGroupUsers}
              </QTypography>
            </QCardBody>
          </QCard>
        </QCol>
        <QCol xs="4" sx={{ width: '33%' }}>
          <QCard className="card-accent-primary">
            <QCardHeader
              title={
                <QTypography variant="h4Bold" color="#000000">
                  Licensed Users
                </QTypography>
              }
            >
              <strong>Licensed Users</strong>
            </QCardHeader>
            <QCardBody>
              <div>
                <div>
                  <strong>
                    <QTypography variant="h5Bold" color="#000000">
                      Numbers of portal users:{' '}
                    </QTypography>
                  </strong>
                  <QTypography variant="h5Regular" color="#000000">
                    {unlockedUsers.length}
                  </QTypography>
                </div>
                <br />
                <div className="d-flex justify-between">
                  <div style={{ paddingRight: '8px' }}>
                    {mastV2 ? (
                      <HelpOutlineIcon />
                    ) : (
                      <i className="fa-solid fa-circle-info text-info" />
                    )}
                  </div>
                  <div>
                    <QTypography variant="h5Regular" color="#000000">
                      This number shows unlocked user only. There are{' '}
                      {lockedUsers.length} locked users
                    </QTypography>
                  </div>
                </div>
              </div>
            </QCardBody>
          </QCard>
        </QCol>
        {remainingAppsChart}
        {remainingScansChart}
      </QRow>
      <QRow sx={{ width: '100%' }}>
        <QCol sx={{ width: '100%' }}>
          <QCard
            className="card-accent-primary"
            data-testid="test-recent-scans-chart"
          >
            <QCardHeader
              title={
                <QTypography variant="h4Bold" color="#000000">
                  Recent Scans
                </QTypography>
              }
            >
              <strong>Recent Scans</strong>
            </QCardHeader>

            <QCardBody>
              <Line
                width={450}
                height={200}
                options={{
                  maintainAspectRatio: true,
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: 'Scans requested over the last 30 days.',
                      position: 'bottom',
                      font: {
                        weight: 'normal',
                        style: 'italic',
                      },
                    },
                  },
                  scales: {
                    x: {
                      type: 'time',
                      distribution: 'linear',
                      ticks: {
                        autoSkip: false,
                        minRotation: 25,
                        maxRotation: 45,
                      },
                      time: {
                        unit: 'day',
                      },
                      bounds: 'ticks',
                    },
                    y: {
                      ticks: {
                        beginAtZero: true,
                        stepSize: 1,
                      },
                    },
                  },
                }}
                data={{
                  labels: scanDates,
                  datasets: [
                    {
                      data: scanCounts,
                      label: 'Scans',
                      fill: true,
                      lineTension: 0,
                      borderWidth: 2,
                      backgroundColor: 'rgba(36, 76, 90, 0.2)',
                      borderColor: '#244C5A',
                      pointBackgroundColor: '#000000',
                      spanGaps: false,
                    },
                  ],
                }}
              />
            </QCardBody>
          </QCard>
        </QCol>
      </QRow>
    </>
  );
}
