import React from 'react';

import { QTable } from './Q-Components/QTable';
import { QTableRow } from './Q-Components/QTableRow';
import { QTableCell } from './Q-Components/QTableCell';
import { QTableBody } from './Q-Components/QTableBody';
import { QTypography } from './Q-Components/QTypography';
import { QCard } from './Q-Components/QCard';
import { QCardHeader } from './Q-Components/QCardHeader';
import { QCardBody } from './Q-Components/QCardBody';

export const RiskScoresCard = ({ riskScores }) => (
  <QCard
    className="card-accent-warning-2 full-length"
    style={{ height: '100%' }}
  >
    <QCardHeader
      title={<QTypography variant="h5Medium">Risk Score</QTypography>}
    >
      Risk Score
    </QCardHeader>
    <QCardBody>
      <QTable size="sm">
        <QTableBody>
          <QTableRow hoverDisabled>
            <QTableCell>
              <p className="market-info-title">
                <QTypography variant="bodyMedium">Risk Score</QTypography>
              </p>
              <span className="market-info-value">
                <QTypography variant="h1Medium">
                  {riskScores.threatScore}
                </QTypography>
              </span>
            </QTableCell>
          </QTableRow>
          <QTableRow hoverDisabled>
            <QTableCell>
              <p className="market-info-title">
                <QTypography variant="bodyMedium">
                  Security Risk Score
                </QTypography>
              </p>
              <span className="market-info-value">
                <QTypography variant="h1Medium">
                  {riskScores.securityLevel ?? 'N/A'}
                </QTypography>
              </span>
            </QTableCell>
          </QTableRow>
          <QTableRow hoverDisabled>
            <QTableCell>
              <p className="market-info-title">
                <QTypography variant="bodyMedium">
                  Privacy Risk Score
                </QTypography>
              </p>
              <span className="market-info-value">
                <QTypography variant="h1Medium">
                  {riskScores.privacyLevel ?? 'N/A'}
                </QTypography>
              </span>
            </QTableCell>
          </QTableRow>
        </QTableBody>
      </QTable>
    </QCardBody>
  </QCard>
);
