import React from 'react';
import { CardText } from 'reactstrap';

import DynamicAnalysisDatatable from '../../Android/DynamicAnalysisDatatable';
import { StaticAnalysisDatatable } from '../../Android/StaticAnalysisDatatable';
import { ActivitiesCard } from '../../Android/Components';

export const V6_7 = ({ platform }) => (
  <>
    {platform === 'android' && (
      <>
        <ActivitiesCard nameFilter={/WebView/gi} />
        <hr />
        <CardText>
          <strong>Dynamic Analysis</strong>
        </CardText>
        <DynamicAnalysisDatatable methodFilter="WebView" />
        <hr />
        <CardText>
          <strong>Static Analysis</strong>
        </CardText>
        <StaticAnalysisDatatable
          categoryFilter="methods"
          keyFilter="networkEvents"
          methodFilter={/WebView/gi}
        />
      </>
    )}
  </>
);
