import React from 'react';
import { CardText } from 'reactstrap';

import { Issues, IsDetected } from './Issues';

export const V8_1 = ({ platform, evidence }) => (
  <>
    <Issues evidence={evidence} issues={['DOES_NOT_CHECK_ROOT']} />
    <hr />
    <CardText>
      <strong>App Protection: </strong>
      <IsDetected detected={evidence.appProtection} />
    </CardText>

    {platform === 'android' ? (
      <>
        <CardText>
          <strong>Root Check: </strong>
          <IsDetected detected={evidence.rootCheck} />
        </CardText>
      </>
    ) : (
      <CardText>
        <strong>Jailbreak Detection: </strong>
        <IsDetected detected={evidence.jailbreakDetection} />
      </CardText>
    )}
  </>
);
