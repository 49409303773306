import React, { Component } from 'react';

import DynamicAnalysisDatatable from '../../../Android/DynamicAnalysisDatatable';
import ConstantsMethodsPermissionTable from '../../../Android/ConstantsMethodsPermissionTable';
import PermissionsRequestedTable from '../../../Android/PermissionsRequestedTable';
import TabsView from '../../../../TabsView';

export default class CanReadContacts extends Component {
  render() {
    const tabs = [
      {
        title: 'Permission Requested',
        tabContent: () => <PermissionsRequestedTable filter="READ_CONTACTS" />,
      },
      {
        title: 'Constants & Methods of Permission',
        tabContent: () => <ConstantsMethodsPermissionTable filter="contacts" />,
      },
      {
        title: 'Dynamic Analysis',
        tabContent: () => <DynamicAnalysisDatatable />,
      },
    ];
    return <TabsView tabs={tabs} />;
  }
}
