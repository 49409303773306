import { useState } from 'react';
import moment from 'moment';

// TODO: remove this code https://quokka-io.atlassian.net/browse/MAST-3439
export const useSearchStartDate = initialDate => {
  const [startDate, setStartDate] = useState(
    moment(initialDate).startOf('day').toDate(),
  );

  const setSearchStartDate = newDate => {
    const adjustedDate = newDate ? moment(newDate).startOf('day').toDate() : '';

    setStartDate(adjustedDate);
  };
  return [startDate, setSearchStartDate];
};

export const useSearchEndDate = initialDate => {
  const [endDate, setEndDate] = useState(
    moment(initialDate).endOf('day').toDate(),
  );

  const setSearchEndDate = newDate => {
    const adjustedDate = newDate ? moment(newDate).endOf('day').toDate() : '';
    setEndDate(adjustedDate);
  };
  return [endDate, setSearchEndDate];
};
