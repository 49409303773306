import React, { Component } from 'react';
import classnames from 'classnames';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import DataTable from '../../../DataTable/DataTable';
import ApiCall from '../../Android/ApiCall';
import MethodCallParams from '../../Android/MethodCallParams';

import SensitiveDataExposureTable from './SensitiveDataExposureTable';

export default class FileAccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
    };
  }

  setTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  filterApiCall(filterStr, call) {
    return (
      call.method_name.toLowerCase().includes(filterStr) ||
      call.component.toLowerCase().includes(filterStr) ||
      call.smali_file.file.toLowerCase().includes(filterStr)
    );
  }

  filterParams(filterStr, call) {
    return call.parameters.some(param =>
      param.toLowerCase().includes(filterStr),
    );
  }

  render() {
    const { activeTab } = this.state;
    const { data, platform } = this.props;

    return (
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                this.setTab('1');
              }}
            >
              Files Accessed
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                this.setTab('2');
              }}
            >
              Sensitive Information Exposure
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {/* The Taiwan parser changes parameters to files so we need
            to change it back here for the component to parse it correctly */}
            {platform === 'android' ? (
              <DataTable
                rowsPerPage={5}
                data={data.files_accessed.map(call => ({
                  ...call,
                  parameters: call.files,
                }))}
                columns={[
                  {
                    label: 'API Call',
                    component: ApiCall,
                    filterable: true,
                    filterFunc: this.filterApiCall,
                    width: '40%',
                  },
                  {
                    label: 'Parameters',
                    component: MethodCallParams,
                    filterable: true,
                    filterFunc: this.filterParams,
                    width: '50%',
                  },
                ]}
              />
            ) : (
              <DataTable
                rowsPerPage={5}
                data={data.file_access_operations}
                columns={[
                  {
                    label: 'File',
                    filterable: true,
                    keyName: 'filepath',
                  },
                  {
                    label: 'Mode',
                    keyName: 'mode',
                    filterable: true,
                    filterFunc: this.filterParams,
                    sortable: true,
                  },
                ]}
              />
            )}
          </TabPane>
          <TabPane tabId="2">
            <SensitiveDataExposureTable data={data} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
