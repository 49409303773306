import React from 'react';
import { Table } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QTable as MuiTable } from '@kw/quokka-ui';

const LDTable = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiTable {...props}>{children}</MuiTable>
  ) : (
    <Table {...props}>{children}</Table>
  );
};
export const QTable = withLDConsumer()(LDTable);
