import React from 'react';
import { DropdownToggle } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QInputLabel as MuiDropdownInputLabel } from '@kw/quokka-ui';

const LDDropdownToggle = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiDropdownInputLabel {...props}>{children}</MuiDropdownInputLabel>
  ) : (
    <DropdownToggle {...props}>{children}</DropdownToggle>
  );
};
export const QDropdownToggle = withLDConsumer()(LDDropdownToggle);
