import React, { Component } from 'react';

import TabsView from '../../TabsView';
import DataTable from '../../DataTable/DataTable';
import ApiCall from '../Android/ApiCall';
import MethodCallParams from '../Android/MethodCallParams';
import SmaliAnalysisDatatable from '../Android/SmaliAnalysisDatatable';
import PermissionsRequestedTable from '../Android/PermissionsRequestedTable';
import ExtraPermissionsTable from '../Android/ExtraPermissionsTable';
import ConstantsMethodsPermissionTable from '../Android/ConstantsMethodsPermissionTable';
import MarketInfoTable from '../Android/MarketInfoTable';
import { Harviewer } from '../../Harviewer/Harviewer';

import DevInfo from './FindingData/DevInfo';
import Eula from './FindingData/Eula';
import PermissionsUsage from './FindingData/PermissionsUsage';
import FileAccess from './FindingData/FileAccess';
import NetworkEncryption from './FindingData/NetworkEncryption';
import SharingSensitiveData from './FindingData/SharingSensitiveData';
import HttpsCerts from './FindingData/HttpsCerts';
import MaliciousApiCalls from './FindingData/MaliciousApiCalls';
import HardCodedKeys from './FindingData/HardCodedKeys';

export default class AndroidFindingData extends Component {
  render() {
    const { findingId, data } = this.props;

    // All finding IDs that are simple data tables that show method calls
    const simpleDataTableFindingIds = [
      '4.1.2.3.6(1)',
      '4.1.2.3.6(2)',
      '4.1.2.3.9',
    ];

    console.log('Rendering finding: ', findingId);

    return (
      <div>
        {findingId === '4.1.1.1.1' && <MarketInfoTable />}

        {(findingId === '4.1.1.1.2' || findingId === '4.1.2.1.1') && (
          <div>
            <Eula eula={data ? data.eula : ''} />
            <hr />
            <p>
              <strong>Permissions requested:</strong>
            </p>
            <PermissionsRequestedTable />
          </div>
        )}

        {findingId === '4.1.1.3.1' && <DevInfo data={data} />}

        {findingId === '4.1.2.1.2' && (
          <strong>Operating system enforces this behavior.</strong>
        )}

        {findingId === '4.1.2.3.1' && <Eula eula={data.eula} />}

        {findingId === '4.1.2.3.2' && <PermissionsUsage data={data} />}

        {(findingId === '4.1.2.3.7' || findingId === '4.1.2.5.3') && (
          <SmaliAnalysisDatatable filter="non_mode_private" />
        )}

        {findingId === '4.1.2.3.5' && (
          <FileAccess data={data} platform="android" />
        )}

        {simpleDataTableFindingIds.includes(findingId) && (
          <DataTable
            data={data}
            columns={[
              { label: 'API Call', component: ApiCall },
              {
                label: 'Parameters',
                component: MethodCallParams,
              },
            ]}
          />
        )}

        {(findingId === '4.1.2.4.1(1)' || findingId === '4.1.2.4.1(2)') && (
          <Harviewer />
        )}

        {findingId === '4.1.2.4.1(3)' && (
          <TabsView
            tabs={[
              {
                title: 'Network Encryption',
                tabContent: () => <NetworkEncryption data={data} />,
              },
              { title: 'Network Traffic', tabContent: () => <Harviewer /> },
            ]}
          />
        )}

        {findingId === '4.1.2.5.1' && (
          <TabsView
            tabs={[
              {
                title: 'Permissions Requested',
                tabContent: () => <PermissionsRequestedTable />,
              },
              {
                title: 'Extra Permissions',
                tabContent: () => <ExtraPermissionsTable />,
              },
              {
                title: 'Permission Usage',
                tabContent: () => <ConstantsMethodsPermissionTable />,
              },
            ]}
          />
        )}

        {findingId === '4.1.2.5.2' && <SharingSensitiveData data={data} />}

        {(findingId === '4.1.4.2.2' || findingId === '4.1.4.2.3') && (
          <TabsView
            tabs={[
              {
                title: 'HTTPS Certs',
                tabContent: () => <HttpsCerts data={data} />,
              },
              {
                title: 'SSL Issues',
                tabContent: () => (
                  <SmaliAnalysisDatatable filter="accepts_all_ssl_certs" />
                ),
              },
            ]}
          />
        )}

        {findingId === '4.1.5.1.1(1)' && <span>No malware detected</span>}

        {findingId === '4.1.5.1.1(3)' && <MaliciousApiCalls data={data} />}

        {findingId === '4.1.5.3.1' && (
          <DataTable
            data={data.dynamic_loaded_libraries}
            columns={[
              { label: 'API Call', component: ApiCall },
              {
                label: 'Parameters',
                component: MethodCallParams,
              },
            ]}
          />
        )}

        {findingId === '4.2.2.1.2' && (
          <HttpsCerts data={data.network_traffic} />
        )}

        {findingId === '4.1.2.3.8' && <HardCodedKeys data={data} />}
      </div>
    );
  }
}
