import defaultMainLogo from './Quokka_V2.svg';
import defaultSideLogo from './Q_V2.svg';
import defaultBannerLogo from './Quokka_Banner_V2.svg';
import defaultFavIcon from './Q_Favicon_V2.svg';

export const defaultAsset = {
  name: 'Q-MAST',
  zendeskUrl: 'https://kryptowire.zendesk.com/hc/en-us/requests',
  documentationUrl:
    'https://kryptowire.zendesk.com/hc/en-us/sections/360007786134-EMM-Documentation',
  brand: 'Quokka',
  logo: {
    main: defaultMainLogo,
    side: defaultSideLogo,
    banner: defaultBannerLogo,
    fav: defaultFavIcon,
  },
  fonts: ['"Nunito Sans"', 'NunitoSans', 'sans-serif'],
};
