import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { QTable } from '../../Q-Components/QTable';
import { QTableHead } from '../../Q-Components/QTableHead';
import { QTableBody } from '../../Q-Components/QTableBody';
import { QTableRow } from '../../Q-Components/QTableRow';
import { QTableCell } from '../../Q-Components/QTableCell';
import Loading from '../../Loading';

export default function AuditLogTable({ auditEntries, isLoading, isError }) {
  if (isError) {
    return <p>No data available</p>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <QTable>
      <QTableHead>
        <QTableRow>
          <QTableCell headerCell className="text-center">
            User
          </QTableCell>
          <QTableCell headerCell className="text-center">
            Type
          </QTableCell>
          <QTableCell headerCell className="text-center">
            Details
          </QTableCell>
          <QTableCell headerCell className="text-center">
            Date/Time
          </QTableCell>
          <QTableCell headerCell className="text-center">
            IP
          </QTableCell>
          <QTableCell headerCell className="text-center">
            User-Agent
          </QTableCell>
        </QTableRow>
      </QTableHead>
      <QTableBody>
        {auditEntries && auditEntries.length > 0 ? (
          auditEntries.map(entry => (
            <QTableRow key={entry.id}>
              <QTableCell>{entry.user.email}</QTableCell>
              <QTableCell>{entry.type}</QTableCell>
              <QTableCell>
                {Object.keys(entry.meta).length !== 0 ? (
                  Object.entries(entry.meta).map(([key, val]) => (
                    <p key={key} style={{ wordBreak: 'break-word' }}>
                      <li>
                        <i>{key}</i>: {val ? val.toString() : <i>null</i>}
                      </li>
                    </p>
                  ))
                ) : (
                  <span>N/A</span>
                )}
              </QTableCell>
              <QTableCell>{moment(entry.createdAt).format('L LTS')}</QTableCell>
              <QTableCell>{entry.ipAddress}</QTableCell>
              <QTableCell>{entry.userAgent}</QTableCell>
            </QTableRow>
          ))
        ) : (
          <p>No data available</p>
        )}
      </QTableBody>
    </QTable>
  );
}

AuditLogTable.propTypes = {
  auditEntries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      user: PropTypes.shape({
        email: PropTypes.string.isRequired,
      }).isRequired,
      type: PropTypes.string.isRequired,
      meta: PropTypes.object.isRequired,
      createdAt: PropTypes.string.isRequired,
      ipAddress: PropTypes.string,
      userAgent: PropTypes.string,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
};
