import React, { Component } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { QTableRow } from '../Q-Components/QTableRow';
import { QTableCell } from '../Q-Components/QTableCell';
import { setMdmDeviceDetails } from '../../store/slices/emmAppSlice';

class MdmDevice extends Component {
  goToDeviceDetails = () => {
    this.props.setMdmDeviceDetails(this.props.deviceInfo);
    this.props.history.push(
      `mdm-integration/devices/${this.props.deviceInfo.kryptowireUuid}`,
    );
  };

  render() {
    const { deviceId, compliant, appCount, deviceName, lastSeen, userName } =
      this.props.deviceInfo;

    return (
      <QTableRow className="pointer" onClick={this.goToDeviceDetails}>
        <QTableCell>
          <i className="fa-solid fa-mobile-screen" /> {deviceId}
        </QTableCell>
        <QTableCell>
          <i className="fa-solid fa-user" /> {userName}
        </QTableCell>
        <QTableCell>{deviceName}</QTableCell>
        <QTableCell>{moment(lastSeen).format('L - LTS')}</QTableCell>
        <QTableCell>
          {compliant ? (
            <i className="fa-solid fa-circle-check text-success" />
          ) : (
            <i className="fa-solid fa-circle-xmark text-danger" />
          )}
        </QTableCell>
        <QTableCell>
          <i className="fa-solid fa-list-ul" /> {appCount}
        </QTableCell>
      </QTableRow>
    );
  }
}

const mapStateToProps = state => ({
  mdmDeviceDetails: state.emmApp.mdmDeviceDetails,
});

const mapDispatchToProps = { setMdmDeviceDetails };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MdmDevice),
);
