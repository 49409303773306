import React from 'react';
import _ from 'lodash';
import { Badge } from 'reactstrap';

import DataTable from '../../../DataTable/DataTable';

export const V1_3 = ({ evidence }) => {
  const domains = _.get(evidence, 'domains', []);

  return (
    <DataTable
      data={domains}
      columns={[
        {
          keyName: 'domain',
          label: 'Domain',
          filterable: true,
          sortable: true,
        },
        {
          keyName: 'type',
          label: 'Type',
          filterable: true,
          sortable: true,
          component: ({ value }) =>
            value === 'constant' ? (
              <h5>
                <Badge color="primary">Constants</Badge>
              </h5>
            ) : (
              <h5>
                <Badge color="success">Network Activities</Badge>
              </h5>
            ),
        },
      ]}
    />
  );
};
