import React, { useState } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import RegulationCard from './RegulationCard';

const transformRegulationsData = ({ regulationData, owaspMasvsAppIssues }) => {
  const cloneDeepRegulationData = _.cloneDeep(regulationData);

  return cloneDeepRegulationData.map(regulation => {
    const controls = regulation.controls.map(regulationControl => {
      const { control, result } = regulationControl;

      let customIssue = {};

      owaspMasvsAppIssues.map(issue => {
        if (control === issue.issue?.split('owasp_masvs_')[1]) {
          customIssue = {
            ...issue,
          };
        }

        return null;
      });

      const { review_status: reviewStatus } = customIssue;

      const hasReviewStatus = reviewStatus && reviewStatus !== 'default';

      return {
        ...regulationControl,
        customIssue,
        result: (hasReviewStatus && reviewStatus.replace('ed', '')) || result,

        flag: hasReviewStatus,
      };
    });

    return {
      ...regulation,
      controls,
    };
  });
};

export const OwaspMSTG = ({ platform }) => {
  const [filter, setFilter] = useState(null);

  const regulations = useSelector(
    ({ appResults }) => appResults.owaspMSTGResults,
  );

  const owaspMasvsAppIssues = useSelector(
    ({ appResults }) => appResults.owaspMasvsAppIssues ?? [],
  );

  if (!regulations || regulations.length === 0) {
    return <p>No data found</p>;
  }

  const transformedRegulationsData = transformRegulationsData({
    regulationData: regulations,
    owaspMasvsAppIssues,
  });

  const reviewedRegulations = _.flatten(
    transformedRegulationsData.map(({ controls }) =>
      controls.filter(({ result }) => result === 'review'),
    ),
  );

  const passedRegulations = _.flatten(
    transformedRegulationsData.map(({ controls }) =>
      controls.filter(({ result }) => result === 'pass'),
    ),
  );
  const hiddenRegulations = _.flatten(
    transformedRegulationsData.map(({ controls }) =>
      controls.filter(({ result }) => result === 'hide'),
    ),
  );

  const failedRegulations = _.flatten(
    transformedRegulationsData.map(({ controls }) =>
      controls.filter(({ result }) => result === 'fail'),
    ),
  );

  const filterData = transformedRegulationsData.map(
    ({ controls, description, regulation }) => ({
      regulation,
      description,
      controls: filter
        ? controls.filter(({ result }) => result === filter)
        : controls,
    }),
  );

  return (
    <>
      <Row>
        <Col sm="3">
          <Card
            className={`card ${
              filter === 'fail' ? 'text-white bg-danger' : 'card-accent-danger'
            } pointer`}
            onClick={() =>
              filter === 'fail' ? setFilter(null) : setFilter('fail')
            }
          >
            <CardBody>
              <Row>
                <h4>Fail</h4>
              </Row>
              <Row>
                <h4>{failedRegulations.length}</h4>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm="3">
          <Card
            className={`card ${
              filter === 'review'
                ? 'text-white bg-warning'
                : 'card-accent-warning'
            } pointer`}
            onClick={() =>
              filter === 'review' ? setFilter(null) : setFilter('review')
            }
          >
            <CardBody>
              <Row>
                <h4>Review</h4>
              </Row>
              <Row>
                <h4>{reviewedRegulations.length}</h4>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm="3">
          <Card
            className={`card ${
              filter === 'pass'
                ? 'text-white bg-success'
                : 'card-accent-success'
            } pointer`}
            onClick={() =>
              filter === 'pass' ? setFilter(null) : setFilter('pass')
            }
          >
            <CardBody>
              <Row>
                <h4>Pass</h4>
              </Row>
              <Row>
                <h4>{passedRegulations.length}</h4>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm="3">
          <Card
            className={`card ${
              filter === 'hide' ? 'text-white bg-info' : 'card-accent-info'
            } pointer`}
            onClick={() =>
              filter === 'hide' ? setFilter(null) : setFilter('hide')
            }
          >
            <CardBody>
              <Row>
                <h4>Info</h4>
              </Row>
              <Row>
                <h4>{hiddenRegulations.length}</h4>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {filterData.map(regulation => (
        <RegulationCard
          data={regulation}
          key={regulation.regulation}
          platform={platform}
        />
      ))}
    </>
  );
};
