import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import axios from 'axios';

import { QButton } from '../Q-Components/QButton';
import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import i18n from '../../localization/i18n';
import { DateTime } from '../DateTime';
import { AsyncPaginatedTable } from '../AsyncPaginatedTable';
import util from '../../logic/util';
import { toastrHelper } from '../../logic/toastrHelper';

import ApprovalApp from './ApprovalApp';

export default function AppApprovalTab({
  tabName,
  columnDisplayNamesMapping,
  appStatus,
  group,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const isGroupAdmin = useSelector(
    ({ emmApp }) => emmApp.userAccess.group_admin,
  );
  const isKaiAdmin = useSelector(({ emmApp }) => emmApp.userAccess.kai_access);
  const showActions =
    isKaiAdmin || (process.env.REACT_APP_APPLIANCE !== 'cisa' && isGroupAdmin);
  const { mastV2 } = useFlags();
  const appApprovalUrl = group
    ? `/adminV2/app-approval/apps/${group.id}`
    : `/app-approval/apps`;
  const appApprovalRequestUrl = group
    ? `/adminV2/app-approval/request/${group.id}`
    : '/app-approval/request';
  const fields = Object.keys(columnDisplayNamesMapping);

  async function fetchAppsByApprovalStatus(status, page, pageSize, query) {
    try {
      const statusParam = status !== 'all' ? `status=${status}&` : '';

      const response = await axios.get(
        `${appApprovalUrl}?${statusParam}page=${page}&pageSize=${pageSize}&query=${query}`,
      );

      const { total, perPage, lastPage } = response.data;
      let { entries } = response.data;

      entries = entries.map(app => ({
        id: app.id,
        developer: app.farmApp?.developer ?? null,
        createdAt: app.createdAt,
        requestedBy: app.requestedBy,
        status:
          app.status === 'preApproved'
            ? 'Pre-Approved'
            : util.capitalize(app.status),
        platform: app.platform,
        package: app.package,
        name: app.farmApp?.name || app.name,
        iconUrl: app.farmApp?.iconSmallUrl,
        iconBase64: app.farmApp?.iconBase64,
        updatedAt: app.updatedAt,
        lastUpdatedBy: app.lastUpdatedBy,
      }));

      return { entries, total, perPage, lastPage };
    } catch (err) {
      console.error(err);

      return null;
    }
  }

  async function fetchData(params) {
    try {
      setIsLoading(true);

      return fetchAppsByApprovalStatus(
        appStatus,
        params?.page,
        params?.pageSize,
        params?.query,
      );
    } catch (err) {
      console.error(err);

      return null;
    } finally {
      setIsLoading(false);
    }
  }

  async function updateAppApproval(appId, appStatus) {
    try {
      await axios.put(appApprovalRequestUrl, {
        requestId: appId,
        status: appStatus,
      });

      toastrHelper.showSuccessToast(
        `Updated app to ${appStatus} successfully`,
        'Success',
        mastV2,
      );
    } catch (err) {
      toastrHelper.showErrorToast(
        `Error updating app to ${appStatus}: ${err?.response?.data?.message}`,
        'Error',
        mastV2,
      );
    } finally {
      fetchData();
    }
  }

  const actions =
    appStatus === 'pending'
      ? {
          approve: id => {
            updateAppApproval(id, 'approved');
          },
          deny: id => {
            updateAppApproval(id, 'denied');
          },
        }
      : appStatus === 'denied'
      ? {
          approve: id => {
            updateAppApproval(id, 'approved');
          },
        }
      : null;

  // eslint-disable-next-line no-sparse-arrays
  const columns = [
    {
      label: i18n.t('App'),
      component: ({ value }) => <ApprovalApp app={value} />,
      filterable: true,
      filterFunc: (filterStr, item) =>
        item.name?.toLowerCase().includes(filterStr.toLowerCase()) ||
        item.package?.toLowerCase().includes(filterStr.toLowerCase()),
    },
    ...(fields.length > 0 &&
      fields.map(field => ({
        label: i18n.t(columnDisplayNamesMapping[field]),
        component: ({ value: app }) => {
          if (field === 'createdAt' || field === 'updatedAt') {
            return <DateTime dateTime={app[field]} />;
          }
          if (app[field] === 'null') {
            return <p />;
          }
          return <p>{app[field]}</p>;
        },
      }))),
    ,
    showActions && actions
      ? {
          label: i18n.t('Actions'),
          component: ({ value: app }) => (
            <>
              <div>
                {actions.approve && (
                  <QButton
                    variant="outlined"
                    id="check-version"
                    color="success"
                    className="mr-1"
                    style={{
                      width: mastV2 ? 'unset' : '40px',
                      margin: '0 10px 0 0',
                    }}
                    onClick={() => {
                      actions.approve(app.id);
                    }}
                    data-testid="approve-app-button-test"
                  >
                    {mastV2 ? (
                      <strong>Approve</strong>
                    ) : (
                      <i className="fa-solid fa-check" />
                    )}
                  </QButton>
                )}
                {actions.deny && (
                  <QButton
                    variant="outlined"
                    color="danger"
                    onClick={() => {
                      actions.deny(app.id);
                    }}
                    id="deny-app"
                    className="mr-1"
                    style={{ width: mastV2 ? 'unset' : '40px' }}
                    data-testid="deny-app-button-test"
                  >
                    {mastV2 ? (
                      <strong>Decline</strong>
                    ) : (
                      <i className="fa-solid fa-xmark" />
                    )}
                  </QButton>
                )}
              </div>
            </>
          ),
        }
      : {},
  ];

  return (
    <>
      {mastV2 ? (
        <AsyncPaginatedTable columns={columns} fetchData={fetchData} />
      ) : (
        <QCard className="card-accent-primary">
          <QCardHeader>
            <div className="d-flex justify-between">
              <strong>{tabName}</strong>
            </div>
          </QCardHeader>
          <QCardBody>
            <AsyncPaginatedTable columns={columns} fetchData={fetchData} />
          </QCardBody>
        </QCard>
      )}
    </>
  );
}
