import React from 'react';
import { ListGroupItem } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QProfileItem as MuiProfileItem } from '@kw/quokka-ui';

const LDProfileItem = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiProfileItem tabIndex="0" onKeyPress={onclick} {...props}>
      {children}
    </MuiProfileItem>
  ) : (
    <ListGroupItem tabIndex="0" onKeyPress={onclick} {...props}>
      {children}
    </ListGroupItem>
  );
};
export const QProfileItem = withLDConsumer()(LDProfileItem);
