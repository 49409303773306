import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QModal } from '../../../Q-Components/QModal';
import { QModalTitle } from '../../../Q-Components/QModalTitle';
import { QModalContent } from '../../../Q-Components/QModalContent';
import { QModalActions } from '../../../Q-Components/QModalActions';
import { QLabel } from '../../../Q-Components/QLabel';
import { QSelect } from '../../../Q-Components/QSelect';
import { QButton } from '../../../Q-Components/QButton';
import { QTypography } from '../../../Q-Components/QTypography';
import { QRow } from '../../../Q-Components/QRow';
import { QCol } from '../../../Q-Components/QCol';
import i18n from '../../../../localization/i18n';
import { toastrHelper } from '../../../../logic/toastrHelper';

export const dateRangeOptions = [
  {
    value: 'none',
    label: i18n.t('None'),
  },
  {
    value: 'monthly',
    label: i18n.t('Monthly (every 30 days)'),
  },
  {
    value: 'quarterly',
    label: i18n.t('Quarterly (every 90 days)'),
  },
  {
    value: 'biannually',
    label: i18n.t('Bi-annually (every 180 days)'),
  },
  {
    value: 'yearly',
    label: i18n.t('Yearly (every 365 days)'),
  },
];

const Title = ({ toggle }) => (
  <QModalTitle onClose={toggle} closeIcon={<CloseIcon />}>
    <QTypography variant="h3Medium">
      Continuous Watchlist Monitoring
    </QTypography>
  </QModalTitle>
);

const Body = ({ mastV2, frequency, changefrequency }) => (
  <QModalContent>
    <QCol>
      <QRow sx={{ width: '100%', mt: 1 }}>
        <QLabel>
          <QTypography variant="bodyMedium">
            {i18n.t('How often would you like the app to be re-scanned?')}
          </QTypography>
        </QLabel>
      </QRow>
      <QRow
        sx={{ width: '100%', mt: 1 }}
        data-testid="continuous-watchlist-monitoring-input"
      >
        <QSelect
          size="small"
          options={dateRangeOptions}
          value={mastV2 ? frequency?.value : frequency}
          onChange={changefrequency}
          label="Frequency"
          selectIconProps={{ fontSize: 'medium' }}
          formControlProps={{ sx: { width: '100%' } }}
          displayEmpty
        />
      </QRow>
    </QCol>
  </QModalContent>
);

const Footer = ({ toggle, updateSubmissionFrequency, mastV2 }) => (
  <QModalActions>
    <QButton variant="light" color="secondary" onClick={toggle}>
      <QTypography variant="bodyRegular">
        {!mastV2 && <i className="fa-regular fa-xmark mr-1" />}
        Cancel
      </QTypography>
    </QButton>
    <QButton
      variant="filled"
      color="primary"
      onClick={() => updateSubmissionFrequency()}
    >
      <QTypography variant="bodyRegular" color="#FFFFFF">
        {!mastV2 && <i className="fa-regular fa-circle-check mr-1" />}
        Save
      </QTypography>
    </QButton>
  </QModalActions>
);
export const ContinuousResubmissionModal = ({
  isOpen,
  toggle,
  analyzedAppId,
}) => {
  const [frequency, setFrequency] = useState(null);

  const { mastV2 } = useFlags();

  useEffect(() => {
    const getContinuousMonitoringInterval = async () => {
      try {
        const response = await axios.get(
          `/adminV2/watchlist-app/${analyzedAppId}/frequency`,
        );

        const option = dateRangeOptions.find(
          option => option.value === response.data,
        );
        setFrequency(option);
      } catch (err) {
        console.log(
          'Error getting watchlist app continuous monitoring interval:',
          err,
        );
      }
    };

    if (isOpen) {
      getContinuousMonitoringInterval();
    }
  }, [isOpen]);

  const changefrequency = (event, newFrequency) => {
    if (mastV2) {
      const newOption = dateRangeOptions.find(
        option => option.value === newFrequency.props.value,
      );
      setFrequency(newOption);
    } else {
      setFrequency(event);
    }
  };

  const updateSubmissionFrequency = async () => {
    try {
      await axios.post(`adminV2/watchlist-app/${analyzedAppId}/frequency`, {
        frequency: frequency?.value,
      });
      toggle();
      toastrHelper.showSuccessToast(
        i18n.t('Continuous Watchlist Monitoring Interval was set successfully'),
        null,
        mastV2,
      );
    } catch (err) {
      console.log(
        'Error setting the Continuous Watchlist Monitoring Interval: ',
        err,
      );
      toastrHelper.showErrorToast(
        i18n.t('Error setting the Continuous Watchlist Monitoring Interval'),
        null,
        mastV2,
      );
    }
  };

  return (
    <QModal
      open={isOpen}
      isOpen={isOpen}
      toggle={toggle}
      title={<Title toggle={toggle} />}
      content={
        <Body
          frequency={frequency}
          changefrequency={changefrequency}
          mastV2={mastV2}
        />
      }
      actions={
        <Footer
          updateSubmissionFrequency={updateSubmissionFrequency}
          toggle={toggle}
          mastV2={mastV2}
        />
      }
    >
      <Title toggle={toggle} />
      <Body
        frequency={frequency}
        changefrequency={changefrequency}
        mastV2={mastV2}
      />
      <Footer
        updateSubmissionFrequency={updateSubmissionFrequency}
        toggle={toggle}
        mastV2={mastV2}
      />
    </QModal>
  );
};
