import React, { Component } from 'react';

import DynamicAnalysisDatatable from '../../../Android/DynamicAnalysisDatatable';
import ConstantsMethodsPermissionTable from '../../../Android/ConstantsMethodsPermissionTable';
import PermissionsRequestedTable from '../../../Android/PermissionsRequestedTable';
import TabsView from '../../../../TabsView';
import i18n from '../../../../../localization/i18n';

export default class CanAccessFineLocation extends Component {
  render() {
    const tabs = [
      {
        title: i18n.t('Permission Requested'),
        tabContent: () => <PermissionsRequestedTable filter="LOCATION" />,
      },
      {
        title: i18n.t('Constants & Methods of Permission'),
        tabContent: () => (
          <ConstantsMethodsPermissionTable
            showFilter={false}
            filter="LOCATION"
          />
        ),
      },
      {
        title: i18n.t('Dynamic Analysis'),
        tabContent: () => (
          <DynamicAnalysisDatatable
            showFilter={false}
            onlySelectedCategory="Location Events"
            methodFilter={[
              'addProximityAlert',
              'requestLocationUpdates',
              'requestSingleUpdate',
              'getLac',
              'getCid',
              'getPsc',
              'getCellLocation',
              'getProvider',
              'getLastKnownLocation',
              'getLatitude',
              'getLongitude',
            ]}
          />
        ),
      },
    ];
    return <TabsView tabs={tabs} />;
  }
}
