import React from 'react';
import { CardText } from 'reactstrap';

import { Harviewer } from '../../../Harviewer/Harviewer';
import ConstantsDatatable from '../../Android/ConstantsDatatable';
import UrlConstantsDatatable from '../../iOS/UrlConstantsDatatable';

import { Issues } from './Issues';

export const V5_2 = ({ platform, evidence }) => (
  <>
    <Issues
      evidence={evidence}
      issues={['HTTP_URL_CONSTANT', 'HTTP_TRAFFIC']}
    />
    <hr />
    <CardText>
      <strong>Network Traffic</strong>
    </CardText>
    <Harviewer />
    <hr />
    <CardText>
      <strong>URL Constants</strong>
    </CardText>
    {platform === 'android' ? (
      <ConstantsDatatable typeFilter="URL" valueFilter="http://" />
    ) : (
      <UrlConstantsDatatable valueFilter="http://" />
    )}
  </>
);
