import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';

import DataTable from '../../DataTable/DataTable';
import util from '../../../logic/util';
import i18n from '../../../localization/i18n';

const SmaliResult = ({ result }) => {
  if (result.type === 'crypto_key') {
    return (
      <div>
        <Row>
          <Col xs="2">{i18n.t('Hard coded cryptographic key')}</Col>
          <Col xs="10">
            <div>
              <strong>{i18n.t('Key')}:</strong> {result.value}
            </div>
            <div>
              <strong>{i18n.t('Defined in')}:</strong>{' '}
              {util.parseSmaliFile(result.key_defined_file)}
            </div>
            <div>
              <strong>{i18n.t('Used in')}:</strong>{' '}
              {util.parseSmaliFile(result.key_used_file)}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
  if (result.type === 'accepts_all_ssl_certs') {
    return (
      <Row>
        <Col xs="2">{i18n.t('Class bypassing SSL')}</Col>
        <Col xs="10">
          <div>
            <strong>
              {i18n.t('Class containing vulnerable implementation')}:
            </strong>{' '}
            {result.class_with_implementation}
          </div>
          <div>
            <strong>{i18n.t('File using vulnerable implementation')}:</strong>{' '}
            {result.class_with_implementation}
          </div>
        </Col>
      </Row>
    );
  }
  if (result.type === 'non_mode_private') {
    return (
      <Row>
        <Col xs="2">{i18n.t('Creates world accessible resource')}</Col>
        <Col xs="10">
          <div>
            <strong>{i18n.t('Mode')}:</strong> {result.mode}
          </div>
          <div>
            <strong>{i18n.t('Resource type')}:</strong> {result.resource_type}
          </div>
          <div>
            <strong>{i18n.t('Resource created in file')}:</strong>{' '}
            {util.parseSmaliFile(result.file)}
          </div>
          <div>
            <strong>{i18n.t('Line')}:</strong> {result.line}
          </div>
        </Col>
      </Row>
    );
  }

  return null;
};

class SmaliResultsDatatable extends Component {
  render() {
    const { results, filter = '' } = this.props;

    let smaliResults = [];
    if (results.smali_analysis) {
      smaliResults = results.smali_analysis.filter(result =>
        ['crypto_key', 'accepts_all_ssl_certs', 'non_mode_private'].includes(
          result.type,
        ),
      );
    }

    // Filter down to only certain results if specified
    if (filter) {
      smaliResults = smaliResults.filter(result => result.type === filter);
    }

    return (
      <div>
        {smaliResults.length === 0 ? (
          <span>{i18n.t('No issues found')}</span>
        ) : (
          <DataTable
            filterable={false}
            data={smaliResults}
            columns={[
              {
                component: ({ rowData }) => <SmaliResult result={rowData} />,
              },
            ]}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(SmaliResultsDatatable);
