import React from 'react';
import { InputGroup } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

const LDInputGroup = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <>{children}</>
  ) : (
    <InputGroup {...props}>{children}</InputGroup>
  );
};

export const QInputGroup = withLDConsumer()(LDInputGroup);
