import React from 'react';
import { CardFooter } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { CardActions } from '@mui/material';

const LDCardActions = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <>
      <hr />
      <CardActions {...props}>{children}</CardActions>
    </>
  ) : (
    <CardFooter {...props}>{children}</CardFooter>
  );
};
export const QCardActions = withLDConsumer()(LDCardActions);
