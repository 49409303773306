import React from 'react';
import { InputGroupAddon } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QTextFieldIconWrapper as MuiInputIconWrapper } from '@kw/quokka-ui';

// Icon position needs to be defined to render MUI component.
const LDInputIconWrapper = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    props.position !== undefined && (
      <MuiInputIconWrapper {...props}>{children}</MuiInputIconWrapper>
    )
  ) : (
    <InputGroupAddon {...props}>{children}</InputGroupAddon>
  );
};

export const QInputIconWrapper = withLDConsumer()(LDInputIconWrapper);
