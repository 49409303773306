import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { fetchGroupAdminGroup } from '../../store/sliceHelpers/emmAppSliceHelper';
import { fetchKaiGroups } from '../../store/sliceHelpers/kaiSliceHelper';
import { QAutocomplete } from '../Q-Components/QAutocomplete';
import { QInput } from '../Q-Components/QInput';

export const getGroupConfig = async ({
  kaiAccess,
  dispatch,
  group,
  setGroup,
}) => {
  try {
    const groups = kaiAccess
      ? await dispatch(fetchKaiGroups())
      : await dispatch(fetchGroupAdminGroup());

    const selectedGroup = group
      ? {
          value: group.id,
          label: group.name,
          ...groups.find(adminGroup => adminGroup.id === group.value),
        }
      : {
          value: groups[0].id,
          label: groups[0].name,
          ...groups[0],
        };

    setGroup(selectedGroup);
  } catch (err) {
    console.log('Error getting group config:', err);
  }
};

export const GroupSelection = ({ group, setGroup, kaiAccess }) => {
  const [groupInputValue, setGroupInputValue] = useState('');
  const { mastV2 } = useFlags();

  const dispatch = useDispatch();

  const groupAdminGroups = useSelector(state =>
    kaiAccess ? state.kai.kaiGroups : state.emmApp.groupAdminGroup,
  );

  const groupsOptions = groupAdminGroups.map(group => ({
    value: group.id,
    label: group.name,
    ...group,
  }));

  useEffect(() => {
    setGroup(groupsOptions[0]);
  }, [groupsOptions.length > 0]);

  useEffect(() => {
    getGroupConfig({ kaiAccess, dispatch, group, setGroup });
  }, []);

  return mastV2 ? (
    <QAutocomplete
      data-testid="kai-group-list"
      value={group || null}
      onChange={(event, newGroup) => {
        setGroup(newGroup);
      }}
      inputValue={groupInputValue}
      onInputChange={(event, newGroupInputValue) => {
        setGroupInputValue(newGroupInputValue);
      }}
      options={groupsOptions}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      disableClearable
      renderInput={params => (
        <QInput
          {...params}
          size="small"
          variant="outlined"
          outlinedWithTitle
          style={{ textOverflow: 'ellipsis', width: '200px' }}
          InputProps={{
            endAdornment: params.InputProps.endAdornment,
            sx: {
              '.MuiAutocomplete-endAdornment': {
                position: 'absolute',
              },
            },
            inputProps: params.inputProps,
            ref: params.InputProps.ref,
          }}
        />
      )}
      style={{
        width: '200px',
      }}
      renderOption={(props, option) => (
        <li
          {...props}
          style={{
            wordWrap: 'break-word',
            display: 'block',
          }}
        >
          {option.label}
        </li>
      )}
      popupIcon={<ExpandMoreIcon htmlColor="#000000" />}
      clearIcon={<ExpandLessIcon htmlColor="#000000" />}
    />
  ) : (
    <Select
      options={groupsOptions}
      value={group}
      onChange={newGroup => {
        setGroup(newGroup);
      }}
      styles={{
        container: base => ({
          ...base,
          width: '200px',
        }),
      }}
    />
  );
};
