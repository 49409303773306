import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Select from 'react-select';
import { MultiSelect } from 'react-multi-select-component';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import AsyncTablePagination from '../../AsyncTablePagination';
import { toastrHelper } from '../../../logic/toastrHelper';
import {
  useSearchStartDate,
  useSearchEndDate,
} from '../../../logic/customHooks';
import { QCard } from '../../Q-Components/QCard';
import { QCardHeader } from '../../Q-Components/QCardHeader';
import { QCardBody } from '../../Q-Components/QCardBody';
import { QButton } from '../../Q-Components/QButton';
import { QCollapse } from '../../Q-Components/QCollapse';
import { QRow } from '../../Q-Components/QRow';
import { QCol } from '../../Q-Components/QCol';
import { QLabel } from '../../Q-Components/QLabel';
import { QInputGroup } from '../../Q-Components/QInputGroup';
import { QInputGroupAddon } from '../../Q-Components/QInputGroupAddon';
import { QInput } from '../../Q-Components/QInput';
import { QUncontrolledDropdown } from '../../Q-Components/QUncontrolledDropdown';
import { QDropdownToggle } from '../../Q-Components/QDropdownToggle';
import { QDropdownMenu } from '../../Q-Components/QDropdownMenu';
import { QDropdownSelectItem } from '../../Q-Components/QDropdownSelectItem';
import { QContainer } from '../../Q-Components/QContainer';
import { QInputIconWrapper } from '../../Q-Components/QInputIconWrapper';
import { QIconButton } from '../../Q-Components/QIconButton';
import { QSelect } from '../../Q-Components/QSelect';
import { QDownloadButton } from '../../Q-Components/QDownloadButton';
import { QDatePicker } from '../../Q-Components/QDatePicker';

import AuditLogTable from './AuditLogTable';

export default function AuditLogs() {
  const { mastV2 } = useFlags();
  const [auditEntries, setAuditEntries] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [subGroupOptions, setSubGroupOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedSubGroups, setSelectedSubGroups] = useState([]);
  const [searchStartDate, setSearchStartDate] = useSearchStartDate(
    moment().subtract(14, 'days').toDate(),
  );
  const [searchEndDate, setSearchEndDate] = useSearchEndDate(moment().toDate());

  const [showFilter, setShowFilter] = useState(false);
  const [pagination, setPagination] = useState({
    lastPage: 0,
    totalData: 0,
    dataPerPage: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({ value: 10, label: '10' });
  const pageSizeOptions = [
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  // Pull list of users from redux
  const groupAdminUsers = useSelector(state => state.emmApp.groupAdminUsers);

  // Pull list of sub groups from redux
  const groupAdminSubGroups = useSelector(
    state => state.emmApp.groupAdminSubGroups,
  );

  async function getUserOptions() {
    const userOptions = [];
    userOptions.push({ value: 'all', label: 'All' });
    groupAdminUsers.forEach(user => {
      userOptions.push({ value: user.id, label: user.email });
    });
    setUserOptions(userOptions);
  }

  async function getSubGroupOptions() {
    setSubGroupOptions(
      groupAdminSubGroups.map(subGroup => ({
        value: subGroup.id,
        label: subGroup.name,
      })),
    );
  }

  // Reflect changes
  useEffect(() => {
    getUserOptions();
    getSubGroupOptions();
  }, [groupAdminUsers, groupAdminSubGroups]);

  async function fetchData(pageNum = 1, event = null) {
    if (event) event.preventDefault();

    setIsLoading(true);
    setIsError(false);
    let userId = null;
    if (mastV2) {
      userId = selectedUser && selectedUser !== 'all' ? selectedUser : null;
    } else {
      userId =
        selectedUser && selectedUser.value !== 'all'
          ? selectedUser.value
          : null;
    }

    let subGroupIds = '';
    if (mastV2) {
      subGroupIds = selectedSubGroups.join(',');
    } else {
      subGroupIds = selectedSubGroups.map(subgroup => subgroup.value).join(',');
    }

    try {
      const auditResponse = await axios.get(`audit-entries/bulk`, {
        params: {
          page: pageNum,
          query: query || null,
          subGroupIds,
          userId,
          startDate: searchStartDate,
          endDate: searchEndDate,
          pageSize: pageSize?.value || null,
        },
      });
      setAuditEntries(auditResponse.data.entries);
      setPagination({
        lastPage: auditResponse.data.lastPage,
        totalData: auditResponse.data.total,
        dataPerPage: auditResponse.data.perPage,
      });
      setPage(pageNum);
    } catch (err) {
      console.log('Error getting audit entries:', err);
      toastrHelper.showErrorToast(
        'Error retrieving audit logs',
        'Error',
        mastV2,
      );
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }

  function handleSearchQuery(e) {
    setQuery(e.target.value);
  }

  function handleKeyPress(e) {
    if (e.charCode === 13) {
      e.preventDefault();
      fetchData(1);
    }
  }

  function handleSelectedUser(event, newSelectedUser) {
    console.log(newSelectedUser);
    const {
      target: { value },
    } = event;
    setSelectedUser(value);
  }

  function handleSelectedSubGroups(event) {
    const {
      target: { value },
    } = event;
    setSelectedSubGroups(value.length ? value : [value]);
    console.log(value.length ? value : [value]);
  }

  async function sendDownloadRequest(isCompressed) {
    if (
      Math.abs(moment(searchStartDate).diff(moment(searchEndDate), 'days')) > 14
    ) {
      toastrHelper.showErrorToast(
        'Invalid date range',
        'Please select a date range of 2 weeks or less',
        mastV2,
      );
      return;
    }

    await axios.post('csv-request', {
      pageType: 'audit',
      isCompressed,
      selectedUser: selectedUser ? selectedUser.value : 0,
      query: query || null,
      subgroupIds: selectedSubGroups.map(subgroup => subgroup.value).join(','),
      startDate: searchStartDate,
      endDate: searchEndDate,
    });
    toastrHelper.showSuccessToast(
      'Your request is being processed. Please look forward to an email with a link to download the requested file',
      null,
      mastV2,
    );
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [
    selectedUser,
    selectedSubGroups,
    searchStartDate,
    searchEndDate,
    pageSize,
  ]);

  // Handle page size change
  const handlePageSizeChange = option => {
    setPageSize(option);
  };

  return mastV2 ? (
    <QContainer
      sx={{
        padding: '0px',
        minWidth: '100%',
      }}
    >
      <QRow>
        <QCol sx={{ width: '95%' }}>
          <QInput
            sx={{ width: '294px', height: '48px' }}
            variant="filled"
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <QInputIconWrapper position="start">
                  <SearchIcon />
                </QInputIconWrapper>
              ),
              endAdornment: query ? (
                <QIconButton
                  sx={{ color: '#6a6c6f' }}
                  size="small"
                  onClick={() => setQuery('')}
                >
                  <ClearIcon />
                </QIconButton>
              ) : undefined,
            }}
            value={query}
            onChange={e => {
              handleSearchQuery(e);
              setQuery(e.target.value);
            }}
            onKeyPress={e => handleKeyPress(e)}
          />
        </QCol>
        <QCol sx={{ width: '5%', display: 'flex', justifyContent: 'flex-end' }}>
          <QDownloadButton
            sx={{
              paddingTop: '0px',
              paddingBottom: '0px',
              marginBottom: '24px',
            }}
            options={[
              {
                value: 'csv',
                label: 'CSV',
                icon: (
                  <i className="fa-solid fa-file-excel pointer success-icon" />
                ),
                onClick: async () => sendDownloadRequest(false),
              },
              {
                value: 'zip',
                label: 'ZIP',
                icon: <i className="fa-solid fa-file-zipper warning-icon" />,
                onClick: async () => sendDownloadRequest(true),
              },
            ]}
          />
        </QCol>
      </QRow>

      <QRow marginTop="8px" columnGap="8px">
        <QSelect
          size="small"
          options={userOptions}
          value={selectedUser || ''}
          onChange={handleSelectedUser}
          label="User"
          formControlProps={{ sx: { width: '180px' } }}
          selectIconProps={{ fontSize: 'medium' }}
        />
        <QSelect
          multiple
          size="small"
          options={subGroupOptions}
          value={selectedSubGroups}
          onChange={handleSelectedSubGroups}
          label="Sub Groups"
          formControlProps={{ sx: { width: '180px' } }}
          selectIconProps={{ fontSize: 'medium' }}
        />
        <QDatePicker
          className="form-control"
          label="Start Date"
          handleChangeDate={setSearchStartDate}
          date={searchStartDate}
          sx={{ width: '100%' }}
          inputProps={{ 'data-testid': 'test-start-date' }}
        />
        <QDatePicker
          className="form-control"
          label="End Date"
          handleChangeDate={setSearchEndDate}
          date={searchEndDate}
          sx={{ width: '100%' }}
          inputProps={{ 'data-testid': 'test-end-date' }}
        />
      </QRow>

      <QCol
        sx={{
          backgroundColor: '#FAFAFA',
          height: '64px',
          marginTop: '18px',
          borderTop: '1px solid #EEEEEE',
        }}
      >
        <AsyncTablePagination
          pagination={pagination}
          getPage={fetchData}
          page={page}
          showRowsPerPage
          currentRows={auditEntries.length}
          handlePageSizeChange={handlePageSizeChange}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
        />
      </QCol>

      <AuditLogTable
        pagination={pagination}
        getPage={fetchData}
        page={page}
        auditEntries={auditEntries}
        isLoading={isLoading}
        isError={isError}
      />
    </QContainer>
  ) : (
    <QCard className="card-accent-primary">
      <QCardHeader>
        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
          <strong>User Audit Entries</strong>

          <QUncontrolledDropdown>
            <QDropdownToggle size="sm" color="link">
              <i className="fa-solid fa-download black-icon" />
            </QDropdownToggle>
            <QDropdownMenu>
              <QDropdownSelectItem onClick={() => sendDownloadRequest(false)}>
                <i className="fa-solid fa-file-excel pointer success-icon" />{' '}
                CSV
              </QDropdownSelectItem>
              <QDropdownSelectItem
                onClick={async () => {
                  sendDownloadRequest(true);
                }}
              >
                <i className="fa-solid fa-file-zipper warning-icon" /> ZIP
              </QDropdownSelectItem>
            </QDropdownMenu>
          </QUncontrolledDropdown>
        </div>
      </QCardHeader>
      <QCardBody>
        <QRow>
          <QCol xs="3">
            <QLabel for="entry-query-input">
              <strong>Search:</strong>
            </QLabel>
            <QInputGroup>
              <QInput
                type="text"
                value={query}
                onChange={e => handleSearchQuery(e)}
                onKeyPress={e => handleKeyPress(e)}
              />
              {query && (
                <QInputGroupAddon
                  className="pointer input-group-warning"
                  addonType="append"
                  onClick={() => {
                    setQuery('');
                  }}
                >
                  <span className="input-group-text">
                    <i className="fa fa-xmark" />
                  </span>
                </QInputGroupAddon>
              )}
            </QInputGroup>
          </QCol>
        </QRow>
        <QButton
          className="mt-3 mb-3"
          color="primary"
          onClick={() => setShowFilter(!showFilter)}
        >
          <i className="fa-solid fa-filter" />{' '}
          {showFilter ? ' Hide ' : ' Show '}
          Filters
        </QButton>
        <QCollapse isOpen={showFilter}>
          <QRow>
            {/* Filtering options */}
            {/* User selection */}
            <QCol xs="3">
              <QLabel for="column-user-select">
                <strong>User:</strong>
              </QLabel>
              <Select
                id="column-user-select"
                onChange={option => setSelectedUser(option)}
                options={userOptions}
                value={selectedUser}
                isClearable
              />
            </QCol>
            {/* Sub Group selection */}
            <QCol xs="3">
              <QLabel for="column-sub-group-select">
                <strong>Sub Groups:</strong>
              </QLabel>
              <MultiSelect
                id="column-sub-group-select"
                options={subGroupOptions}
                value={selectedSubGroups}
                onChange={option => setSelectedSubGroups(option)}
              />
            </QCol>

            {/* Date selection */}
            <QCol xs="2">
              <QLabel for="column-start-date-select">
                <strong>Start Date:</strong>
              </QLabel>
              <QDatePicker
                className="form-control"
                label="Start Date"
                handleChangeDate={setSearchStartDate}
                date={searchStartDate}
                customInput={
                  <input data-testid="test-start-date" type="text" />
                }
              />
            </QCol>
            <QCol xs="2">
              <QLabel for="column-end-date-select">
                <strong>End Date:</strong>
              </QLabel>
              <QDatePicker
                className="form-control"
                label="End Date"
                handleChangeDate={setSearchEndDate}
                date={searchEndDate}
                customInput={<input data-testid="test-end-date" type="text" />}
              />
            </QCol>
          </QRow>
        </QCollapse>

        <AsyncTablePagination
          pagination={pagination}
          getPage={fetchData}
          page={page}
        />

        <AuditLogTable
          auditEntries={auditEntries}
          isLoading={isLoading}
          isError={isError}
        />
      </QCardBody>
    </QCard>
  );
}
