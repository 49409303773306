/* eslint-disable no-unused-vars */
import React from 'react';
import { CardText } from 'reactstrap';

import DynamicAnalysisDatatable from '../../Android/DynamicAnalysisDatatable';
import { AppManifest } from '../../AppManifest';

export const V2_6 = ({ platform }) =>
  platform === 'android' ? (
    <>
      <CardText>
        <strong>Dynamic Analysis</strong>
      </CardText>
      <DynamicAnalysisDatatable
        onlySelectedCategory={[
          'Intents Created',
          'Intents Sent',
          'Logging',
          'Reflection',
        ]}
      />
    </>
  ) : (
    <>
      <CardText>
        <strong>App Manifest</strong>
      </CardText>
      <AppManifest />
    </>
  );
