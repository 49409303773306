import React from 'react';
import { Table } from 'reactstrap';

export default function SensitiveDataExposureTable({ data }) {
  return (
    <div>
      {data.sensitive_data.values.length === 0 ? (
        <div style={{ padding: 8 }}>No sensitive data exposure detected</div>
      ) : (
        <Table>
          <tbody>
            {data.sensitive_data.sensitive_data_type.map((type, i) => (
              <tr key={i}>
                <td>{type}</td>
                <td className="dont-break-out">
                  {data.sensitive_data.values[i]}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}
