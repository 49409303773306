import React, { useState } from 'react';
import { Label, Input, FormGroup } from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';

import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';
import { invalidateAnalyzedAppsCache } from '../../store/slices/cache/analyzedAppsCacheSlice';
import { QModal } from '../Q-Components/QModal';
import { QSelect } from '../Q-Components/QSelect';
import { QModalContent } from '../Q-Components/QModalContent';
import { QTypography } from '../Q-Components/QTypography';
import { QModalActions } from '../Q-Components/QModalActions';
import { QButton } from '../Q-Components/QButton';
import { QModalTitle } from '../Q-Components/QModalTitle';
import { QCheckbox } from '../Q-Components/QCheckbox';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QLabel } from '../Q-Components/QLabel';
import { QInput } from '../Q-Components/QInput';

const updateOne = async (
  analyzedAppId,
  currentNote,
  currentReviewStatus,
  mastV2,
) => {
  await axios
    .patch('app-note', {
      analyzedAppId,
      note: currentNote,
      removed: false,
    })
    .catch(err => {
      console.log('Error patching app note:', err);
      toastrHelper.showErrorToast(
        i18n.t('Error saving app note'),
        null,
        mastV2,
      );
    });
  return axios
    .patch(`analyzed-apps/${analyzedAppId}/review-status`, {
      status: currentReviewStatus.value,
    })
    .catch(err => {
      console.log('Error updating analyzed app status:', err);
      toastrHelper.showErrorToast(
        i18n.t('Error updating analyzed app status'),
        null,
        mastV2,
      );
    });
};

const updateBatch = async (
  analyzedAppIds,
  currentNote,
  currentReviewStatus,
  mastV2,
) => {
  await axios
    .patch('/app-note/batch', {
      analyzedAppIds,
      note: currentNote,
      removed: false,
    })
    .catch(err => {
      console.log('Error patching app note:', err);
      toastrHelper.showErrorToast(
        i18n.t('Error saving app note'),
        null,
        mastV2,
      );
    });
  return axios
    .patch(`analyzed-apps/batch/review-status`, {
      status: currentReviewStatus.value,
      analyzedAppIds,
    })
    .catch(err => {
      console.log('Error updating analyzed app status:', err);
      toastrHelper.showErrorToast(
        i18n.t('Error updating analyzed app status'),
        null,
        mastV2,
      );
    });
};

export default function BatchChangeReviewStatus({
  analyzedAppIds,
  lastAnalyzedAppId,
  note,
  getApps,
  toggle,
  open,
}) {
  const { mastV2 } = useFlags();
  const reviewStatusOptions = [
    { value: 'archived', label: i18n.t('Archived') },
    { value: 'approved', label: i18n.t('Approved') },
    { value: 'pending', label: i18n.t('Pending') },
  ];

  const [loading, setLoading] = useState(false);
  const [currentNote, setCurrentNote] = useState(note ? note.note : '');
  const [currentReviewStatus, setCurrentReviewStatus] = useState({});
  const [applyToAllVersions, setApplyToAllVersions] = useState(false);
  const dispatch = useDispatch();

  const handleChangeReviewStatusOption = (event, newReviewStatus) => {
    if (mastV2) {
      const newOption = reviewStatusOptions.find(
        option => option.value === newReviewStatus.props.value,
      );

      setCurrentReviewStatus(newOption);
    } else {
      setCurrentReviewStatus(event);
    }
  };

  const changeStatus = async () => {
    let response;

    if (applyToAllVersions) {
      response = await updateBatch(
        analyzedAppIds,
        currentNote,
        currentReviewStatus,
        mastV2,
      );
    } else {
      response = await updateOne(
        lastAnalyzedAppId,
        currentNote,
        currentReviewStatus,
        mastV2,
      );
    }

    if (response.status === 200) {
      toastrHelper.showSuccessToast(i18n.t('Updated app status'), null, mastV2);
      dispatch(invalidateAnalyzedAppsCache);
      getApps();
    }
    setLoading(false);
    toggle();
  };

  return (
    <QModal
      isOpen={open}
      open={open}
      toggle={toggle}
      title={
        <QModalTitle onClose={toggle} closeIcon={<CloseIcon />}>
          <QTypography variant="h3Medium">
            {i18n.t('Edit App Status')}
          </QTypography>
        </QModalTitle>
      }
      content={
        <QModalContent sx={{ padding: '16px 29px 29px 16px' }}>
          <QRow sx={{ width: '100%' }} justifyContent="space-between">
            <QCol>
              <QLabel>
                <QTypography variant="h5Medium">{i18n.t('Note')}</QTypography>
                <QTypography variant="bodyRegular">
                  {` (${i18n.t('Minimum 10 characters')})`}
                </QTypography>
              </QLabel>
            </QCol>
            <QCol>
              <QButton
                onClick={() => setCurrentNote('')}
                variant="text"
                sx={{
                  padding: 0,
                  minWidth: 0,
                }}
              >
                {i18n.t('Clear')}
              </QButton>
            </QCol>
          </QRow>

          <QRow sx={{ width: '100%' }}>
            <textarea
              placeholder="Type your note here"
              className="form-control"
              onChange={e => setCurrentNote(e.target.value)}
              value={currentNote}
              rows="4"
            />
          </QRow>

          <QRow sx={{ width: '100%', mt: 2 }}>
            <QCol>
              <QLabel>
                <QTypography variant="h5Medium">
                  {i18n.t('Review Status')}
                </QTypography>
              </QLabel>
            </QCol>
          </QRow>

          <QRow sx={{ width: '100%', mt: '0.5rem' }}>
            <QSelect
              size="small"
              options={reviewStatusOptions}
              value={currentReviewStatus.value}
              onChange={handleChangeReviewStatusOption}
              formControlProps={{ sx: { width: '100%' } }}
              selectIconProps={{ fontSize: 'medium' }}
              selectProps={{
                sx: { backgroundColor: 'white' },
              }}
            />
          </QRow>
          <QRow sx={{ width: '100%', mt: 2 }}>
            <QCol>
              <QCheckbox
                size="small"
                checked={applyToAllVersions}
                onChange={e => setApplyToAllVersions(!!e.target.checked)}
              />
            </QCol>
            <QCol display="flex" justifyContent="center" alignItems="center">
              <QLabel>
                <QTypography>{` ${i18n.t(
                  'Apply to all versions (if unchecked latest will be updated)',
                )}`}</QTypography>
              </QLabel>
            </QCol>
          </QRow>
        </QModalContent>
      }
      actions={
        <QModalActions sx={{ padding: '0px 32px 16px 0px' }}>
          <QButton
            variant="outlined"
            onClick={toggle}
            sx={{ minWidth: '100px', marginRight: '24px' }}
            disabled={loading}
          >
            {i18n.t('Cancel')}
          </QButton>
          <QButton
            variant="filled"
            onClick={changeStatus}
            sx={{ minWidth: '100px' }}
            disabled={
              loading ||
              currentNote.length < 10 ||
              _.isEmpty(currentReviewStatus)
            }
          >
            {i18n.t('Save')}
          </QButton>
        </QModalActions>
      }
    >
      {!mastV2 && (
        <>
          <QModalTitle toggle={toggle}>{i18n.t('Edit App Status')}</QModalTitle>
          <QModalContent>
            <QLabel>
              <strong>{i18n.t('Note')}:</strong>
            </QLabel>
            <QInput
              type="textarea"
              id="app-issue-note-input"
              value={currentNote}
              onChange={e => setCurrentNote(e.target.value)}
            />
            <QButton
              color="warning"
              className="mt-2"
              onClick={() => setCurrentNote('')}
            >
              {i18n.t('Clear Note')}
            </QButton>
            <span style={{ float: 'right' }}>
              <small>
                <i>{i18n.t('Minimum 10 characters')}</i>
              </small>
            </span>
            <hr />
            <FormGroup>
              <Label for="review-status-input">
                <strong>{i18n.t('Review Status')}:</strong>
              </Label>
              <Select
                options={reviewStatusOptions}
                value={currentReviewStatus}
                onChange={handleChangeReviewStatusOption}
              />
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  onChange={e => setApplyToAllVersions(!!e.target.checked)}
                />{' '}
                Apply to all versions (if unchecked latest will be updated)
              </Label>
            </FormGroup>
          </QModalContent>
          <QModalActions>
            <QButton color="secondary" onClick={toggle} disabled={loading}>
              {i18n.t('Cancel')}
            </QButton>
            <QButton
              color="primary"
              onClick={changeStatus}
              disabled={
                loading ||
                currentNote.length < 10 ||
                _.isEmpty(currentReviewStatus)
              }
            >
              {i18n.t('Save')}
            </QButton>
          </QModalActions>
        </>
      )}
    </QModal>
  );
}
