import { createSlice } from '@reduxjs/toolkit';

import { jsonCache } from '../../cacheHelpers/utils';

const initialState = jsonCache.create({ sizeLimit: 7 });

export const analyzedAppsCacheSlice = createSlice({
  name: 'analyzedAppsCache',
  initialState,
  reducers: {
    invalidateAnalyzedAppsCache: state => jsonCache.setAllInvalid(state),
    cacheAnalyzedApps: (state, action) =>
      jsonCache.add(state, action.payload.key, action.payload.data),
  },
});

export const { invalidateAnalyzedAppsCache, cacheAnalyzedApps } =
  analyzedAppsCacheSlice.actions;

export default analyzedAppsCacheSlice.reducer;
