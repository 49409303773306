import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Navbar, Button, Fade, Jumbotron } from 'reactstrap';
import axios from 'axios';

import util from '../logic/util';
import i18n from '../localization/i18n';
import { getAssets } from '../assets/assets';

export default function AccountVerification(props) {
  const [message, setMessage] = useState('');
  const [counter, setCounter] = useState(3);
  const [redirect, setRedirect] = useState(false);
  const [hidden, setHidden] = useState(true);

  const history = useHistory();

  const email = util.getQueryVariable('email', props.location.search);

  async function resendEmailVerification() {
    // Temporarily hidden resend until finish processing button to avoid request spamming
    setHidden(true);

    // Client-side email validation
    if (!email) {
      setMessage(i18n.t('Invalid request'));
      return;
    }

    try {
      const response = await axios.post(`emm/resend-verification`, {
        email,
      });

      if (response.status === 200) {
        setMessage(i18n.t(response.data.msg));
        setTimeout(() => {
          setHidden(false);
        }, 3000);
      }
    } catch (err) {
      setMessage(i18n.t(err.response.data.msg));
    }
  }
  // Counter
  useEffect(() => {
    // eslint-disable-next-line
    counter > 0 && setTimeout(() => setCounter(counter => counter - 1), 1000);
  }, [counter]);

  useEffect(() => {
    async function verifyAccount() {
      const token = util.getQueryVariable('token', props.location.search);

      // Client-side token and email validation
      if (!token || !email) {
        setMessage(i18n.t('Invalid request'));

        return;
      }

      try {
        const response = await axios.post(`emm/verify-account`, {
          token,
          email,
        });

        if (response.status === 200) {
          setMessage(`${i18n.t(response.data.msg)}`);

          // Redirect if user is verified
          setRedirect(true);
          setTimeout(() => {
            history.push('/login');
          }, 3000);
        }
      } catch (err) {
        setMessage(i18n.t(err.response.data.msg));
        // If token expires, show resend button
        if (err.response.data.isExpired) {
          setHidden(false);
        }
      }
    }
    verifyAccount();
  }, [email, history, props.location.search]);

  return (
    <div>
      <Navbar color="white">
        <img
          src={
            getAssets[process.env.REACT_APP_APPLIANCE ?? 'default'].logo.banner
          }
          alt="Logo"
        />
      </Navbar>

      <Fade mountOnEnter unmountOnExit>
        <Jumbotron>
          <div>
            <h1 className="display-5">{i18n.t('Account Verification')}</h1>
            <p className="lead">
              {message} {redirect && `in ${counter} seconds`}
            </p>
            <Button
              hidden={hidden}
              color="success"
              onClick={() => resendEmailVerification()}
            >
              {i18n.t('Resend Verification Email')}
            </Button>
          </div>
        </Jumbotron>
        <div className="text-center">
          <strong>Quokka</strong> -{' '}
          {i18n.t('Mobile Application Security Testing')}
        </div>
      </Fade>
    </div>
  );
}
