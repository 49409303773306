import React from 'react';
import { NavItem } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QSidebarItem as MuiSidebarItem } from '@kw/quokka-ui';

const LDSidebarItem = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiSidebarItem sx={{ height: '50px' }} {...props}>
      {children}
    </MuiSidebarItem>
  ) : (
    <NavItem {...props}>{children}</NavItem>
  );
};
export const QSidebarItem = withLDConsumer()(LDSidebarItem);
