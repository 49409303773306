import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row, Card, CardHeader, CardBody } from 'reactstrap';

import Loading from '../Loading';
import i18n from '../../localization/i18n';

import Findings from './Findings';

class HighLevelFindings extends Component {
  render() {
    const { platform, parsedIssues } = this.props;

    if (!parsedIssues) {
      return <Loading />;
    }

    return (
      <Row>
        <Col>
          <Card className="card-accent-navy">
            <CardHeader>{i18n.t('Security')}</CardHeader>
            <CardBody>
              <Findings
                findings={parsedIssues}
                platform={platform}
                category="security"
              />
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="card-accent-navy">
            <CardHeader>{i18n.t('Privacy & Information Access')}</CardHeader>
            <CardBody>
              <Findings
                findings={parsedIssues}
                platform={platform}
                category="privacy"
              />
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="card-accent-navy">
            <CardHeader>{i18n.t('Device Access')}</CardHeader>
            <CardBody>
              <Findings
                findings={parsedIssues}
                platform={platform}
                category="hardware"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ appResults }) => ({
  parsedIssues: appResults.parsedIssues,
});

export default withRouter(connect(mapStateToProps)(HighLevelFindings));
