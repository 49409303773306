import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  kaiUsers: [],
  kaiGroups: [],
  query: '',
  orderBy: { value: 'submittedAt', label: 'Submitted at' },
  orderByDir: { value: 'DESC', label: 'Descending' },
  platform: 'both',
  status: null,
  currentQuery: null,
  group: null,
  groupValue: null,
  user: null,
  userValue: null,
  notUsers: [],
  submissionTag: { value: 'any', label: 'Any' },
};

export { initialState };

export const kaiSlice = createSlice({
  name: 'kai',
  initialState,
  reducers: {
    setKaiUsers: (state, action) => ({
      ...state,
      kaiUsers: action.payload,
    }),
    setKaiGroups: (state, action) => ({
      ...state,
      kaiGroups: action.payload,
    }),
    setKaiQuery: (state, action) => ({
      ...state,
      query: action.payload,
    }),
    setKaiOrderBy: (state, action) => ({
      ...state,
      orderBy: action.payload,
    }),
    setKaiOrderByDir: (state, action) => ({
      ...state,
      orderByDir: action.payload,
    }),
    setKaiPlatform: (state, action) => ({
      ...state,
      platform: action.payload,
    }),
    setKaiStatus: (state, action) => ({
      ...state,
      status: action.payload,
    }),
    setKaiCurrentQuery: (state, action) => ({
      ...state,
      currentQuery: action.payload,
    }),
    setKaiGroup: (state, action) => ({
      ...state,
      group: action.payload,
    }),
    setKaiGroupValue: (state, action) => ({
      ...state,
      groupValue: action.payload,
    }),
    setKaiUser: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    setKaiUserValue: (state, action) => ({
      ...state,
      userValue: action.payload,
    }),
    setKaiNotUsers: (state, action) => ({
      ...state,
      notUsers: action.payload,
    }),
    setKaiSubmissionTag: (state, action) => ({
      ...state,
      submissionTag: action.payload,
    }),
  },
});

export const {
  setKaiUsers,
  setKaiGroups,
  setKaiQuery,
  setKaiOrderBy,
  setKaiOrderByDir,
  setKaiPlatform,
  setKaiStatus,
  setKaiCurrentQuery,
  setKaiGroup,
  setKaiGroupValue,
  setKaiUser,
  setKaiUserValue,
  setKaiNotUsers,
  setKaiSubmissionTag,
} = kaiSlice.actions;

export default kaiSlice.reducer;
