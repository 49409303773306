import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { QTabContext } from '../Q-Components/QTabContext';
import { QTabLink } from '../Q-Components/QTabLink';
import { QTabList } from '../Q-Components/QTabList';
import { QTabContent } from '../Q-Components/QTabContent';
import { QTabPanel } from '../Q-Components/QTabPanel';
import { QTab } from '../Q-Components/QTab';
import { setPageTitle } from '../../store/slices/emmAppSlice';

import AppApprovalTab from './AppApprovalTab';

export default function AppApprovals({ appApprovalEnabled, group }) {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('1');
  const defaultColumnNameMappings = {
    developer: 'Developer',
    createdAt: 'Submitted On',
    requestedBy: 'Submitted By',
  };

  useEffect(() => {
    dispatch(setPageTitle('App Analysis Approvals'));
  }, []);

  const handleChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  const appApprovalTabs = [
    {
      tabId: '1',
      status: 'pending',
      tabName: 'Pending Approval Apps',
      columnDisplayNamesMapping: {
        ...defaultColumnNameMappings,
      },
    },
    {
      tabId: '2',
      status: 'approved',
      tabName: 'Approved Apps',
      columnDisplayNamesMapping: {
        ...defaultColumnNameMappings,
        updatedAt: 'Approved On',
        lastUpdatedBy: 'Approved By',
      },
    },
    {
      tabId: '3',
      status: 'denied',
      tabName: 'Denied Apps',
      columnDisplayNamesMapping: {
        ...defaultColumnNameMappings,
        updatedAt: 'Denied On',
        lastUpdatedBy: 'Denied By',
      },
    },
    {
      tabId: '4',
      status: 'all',
      tabName: 'All Apps',
      columnDisplayNamesMapping: {
        ...defaultColumnNameMappings,
        status: 'Status',
      },
    },
  ];

  if (appApprovalEnabled) {
    return (
      <QTabContext value={activeTab}>
        <QTabList tabs onChange={handleChange} style={{ paddingTop: '50px' }}>
          {appApprovalTabs.map(({ tabId, status }) => (
            <QTab label={_.capitalize(status)} value={tabId}>
              <QTabLink
                className={classnames({ active: activeTab === tabId })}
                onClick={() => setActiveTab(tabId)}
              >
                {_.capitalize(status)}
              </QTabLink>
            </QTab>
          ))}
        </QTabList>
        <QTabContent activeTab={activeTab}>
          {appApprovalTabs.map(
            ({ tabId, status, tabName, columnDisplayNamesMapping }) => (
              <QTabPanel tabId={tabId} value={tabId} sx={{ padding: '0px' }}>
                <AppApprovalTab
                  tabName={tabName}
                  columnDisplayNamesMapping={columnDisplayNamesMapping}
                  appStatus={
                    status === 'approved' ? 'approvedAndPreApproved' : status
                  }
                  group={group}
                />
              </QTabPanel>
            ),
          )}
        </QTabContent>
      </QTabContext>
    );
  }
  return <div> Not Authorized to view this page!</div>;
}
