import React from 'react';
import _ from 'lodash';

import DataTable from '../../../DataTable/DataTable';
import ApiCall from '../../Android/ApiCall';
import MethodCallParams from '../../Android/MethodCallParams';

export default function MaliciousApiCalls({ data }) {
  if (
    (data.name === 'loads_library' && data.methods.length === 0) ||
    _.isEmpty(data)
  ) {
    return <span>No loaded libraries detected</span>;
  }

  const parsedMethods = data.methods.map(method => ({
    ...method,
    smali_file: {
      file: method.file_name,
      line_num: method.line_num,
    },
    parameters: method.params,
  }));

  return (
    <DataTable
      data={parsedMethods}
      columns={[
        { label: 'API Call', component: ApiCall },
        {
          label: 'Parameters',
          component: MethodCallParams,
        },
      ]}
    />
  );
}
