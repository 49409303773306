import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QTypography } from '../Q-Components/QTypography';
import { QBadge } from '../Q-Components/QBadge';
import { QButton } from '../Q-Components/QButton';
import { QCol } from '../Q-Components/QCol';
import { QRow } from '../Q-Components/QRow';
import { setTwoFactorAuthEnabled } from '../../store/slices/emmAppSlice';
import TwoFactorAuthQrCodeModal from '../Modals/TwoFactorAuthQrCodeModal';
import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';

class TwoFactorAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showQrCodeModal: false,
      qrCodeBase64: '',
    };

    this.registerUser = this.registerUser.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.enableTwoFactorAuth = this.enableTwoFactorAuth.bind(this);
  }

  async toggleModal() {
    this.setState({ showQrCodeModal: !this.state.showQrCodeModal });
  }

  async registerUser() {
    try {
      const response = await axios.post('2fa/register');

      if (response.status === 200) {
        toastrHelper.showSuccessToast(
          response.data.msg,
          'Success',
          this.props.flags?.mastV2,
        );
        this.setState({
          showQrCodeModal: true,
          qrCodeBase64: response.data.qrCodeBase64,
        });
      }
    } catch (err) {
      toastrHelper.showErrorToast(
        i18n.t(
          'Error registering for two factor authentication, please contact support.',
          'Error',
          this.props.flags?.mastV2,
        ),
      );
    }
  }

  async enableTwoFactorAuth() {
    const { setTwoFactorAuthEnabled } = this.props;

    setTwoFactorAuthEnabled(true);
    this.setState({ showQrCodeModal: false });
  }

  render() {
    const { twoFactorAuthEnabled } = this.props;
    const { showQrCodeModal, qrCodeBase64 } = this.state;
    return (
      <div>
        <TwoFactorAuthQrCodeModal
          isOpen={showQrCodeModal}
          toggle={this.toggleModal}
          qrCodeBase64={qrCodeBase64}
          onDone={this.enableTwoFactorAuth}
        />
        <QCard className="card-accent-success">
          <QCardHeader
            title={
              <QTypography variant="h4Bold" color="#000000">
                {i18n.t('Two Factor Authentication')}
              </QTypography>
            }
          >
            <strong>{i18n.t('Two Factor Authentication')}</strong>
          </QCardHeader>
          <QCardBody>
            <QRow>
              <QCol flex="1">
                <QTypography variant="h5Regular" color="#000000">
                  {i18n.t('Enabled')}:{' '}
                </QTypography>
                {twoFactorAuthEnabled ? (
                  <QBadge
                    color="success"
                    label={
                      <QTypography variant="tinyMedium" color="#000000">
                        {i18n.t('YES')}
                      </QTypography>
                    }
                  />
                ) : (
                  <QBadge
                    color="warning"
                    label={
                      <QTypography variant="tinyMedium" color="#000000">
                        {i18n.t('NO')}
                      </QTypography>
                    }
                  />
                )}
              </QCol>
              {!twoFactorAuthEnabled && (
                <QCol
                  sx={{ marginRight: 'auto' }}
                  style={{ textAlign: 'right' }}
                >
                  <QButton
                    variant="filled"
                    color="primary"
                    onClick={this.registerUser}
                  >
                    <QTypography variant="bodySemiBold" color="#ffffff">
                      {i18n.t('Enable')}
                    </QTypography>
                  </QButton>
                </QCol>
              )}
            </QRow>
          </QCardBody>
        </QCard>
      </div>
    );
  }
}

const mapStateToProps = ({ emmApp }) => ({
  twoFactorAuthEnabled: emmApp.twoFactorAuthEnabled,
  lang: emmApp.portalConfig.lang,
});

const mapDispatchToProps = {
  setTwoFactorAuthEnabled,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(TwoFactorAuth));
