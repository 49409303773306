import React, { useState } from 'react';
import classnames from 'classnames';

import { QTabContext } from './QTabContext';
import { QTabLink } from './QTabLink';
import { QTabList } from './QTabList';
import { QTabContent } from './QTabContent';
import { QTabPanel } from './QTabPanel';
import { QTab } from './QTab';

export default function TabsView({ tabs }) {
  const [activeTab, setActiveTab] = useState('0');

  const handleChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <>
      <QTabContext value={activeTab}>
        <QTabList tabs onChange={handleChange} style={{ paddingTop: '50px' }}>
          {tabs.map((tab, index) => (
            <QTab
              label={typeof tab.title === 'string' ? tab.title : tab.title()}
              value={index.toString()}
            >
              <QTabLink
                className={classnames({
                  active: activeTab === index.toString(),
                })}
                onClick={() => setActiveTab(index.toString())}
              >
                {typeof tab.title === 'string' ? tab.title : tab.title()}
              </QTabLink>
            </QTab>
          ))}
        </QTabList>

        <QTabContent activeTab={activeTab}>
          {tabs.map((tab, index) => (
            <QTabPanel
              tabId={index.toString()}
              value={index.toString()}
              key={tab.title}
              style={{ padding: 16, backgroundColor: '#FAFAFA' }}
              className="animated fadeIn"
            >
              {tab.tabContent()}
            </QTabPanel>
          ))}
        </QTabContent>
      </QTabContext>
    </>
  );
}
