import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Viewer } from '@microsoft/sarif-web-component';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QCard } from '../components/Q-Components/QCard';
import { QCardHeader } from '../components/Q-Components/QCardHeader';
import { QTypography } from '../components/Q-Components/QTypography';
import { QCardBody } from '../components/Q-Components/QCardBody';
import QDataTable from '../components/DataTable/QDataTable';
import { QModal } from '../components/Q-Components/QModal';
import { QModalTitle } from '../components/Q-Components/QModalTitle';
import { QModalContent } from '../components/Q-Components/QModalContent';
import auth from '../logic/auth';
import { toastrHelper } from '../logic/toastrHelper';
import { DateTime } from '../components/DateTime';
import { FirmwareAppInfo } from '../components/Firmware/FirmwareAppInfo';
import { ThreatScoreBadge } from '../components/ThreatScoreBadge';
import { FirmwareAppActions } from '../components/Firmware/FirmwareAppActions';
import Loading from '../components/Loading';

export function FirmwareApps() {
  const { firmwareImageId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [apps, setApps] = useState([]);
  const [isOpenSarifViewer, setIsOpenSarifViewer] = useState(false);
  const [firmwareApp, setFirmwareApp] = useState({});
  const { mastV2 } = useFlags();

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const { data } = await axios.get(
          `${process.env.REACT_APP_FIRMWARE_SERVICE_API}/apps?firmwareId=${firmwareImageId}`,
        );
        setApps(data);
      } catch (err) {
        toastrHelper.showErrorToast(
          'Error getting firmware apps: ',
          err,
          mastV2,
        );
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [firmwareImageId]);

  const getFirmwareAppResultUrlBuilder = ({ appId, type }) =>
    `${
      process.env.REACT_APP_FIRMWARE_SERVICE_API
    }/apps/results?id=${appId}&type=${type}&token=${auth.getToken()}`;

  const toggleSarifViewer = async app => {
    if (app) {
      try {
        const { data } = await axios.get(
          getFirmwareAppResultUrlBuilder({ appId: app.id, type: 'doppler' }),
        );

        setFirmwareApp({ ...app, doppler: data });
      } catch (err) {
        toastrHelper.showErrorToast(
          'Error getting SARIF report for app',
          err,
          mastV2,
        );
      }
    }

    setIsOpenSarifViewer(!isOpenSarifViewer);
  };

  return (
    <div className="animated fadeIn">
      <QModal
        open={isOpenSarifViewer}
        isOpen={isOpenSarifViewer}
        size="lg"
        toggle={() => toggleSarifViewer(null)}
        title={
          <QModalTitle
            onClose={() => toggleSarifViewer(null)}
            closeIcon={<CloseIcon />}
          >
            <QTypography variant="h4Regular">
              SARIF Report for {firmwareApp.package}
            </QTypography>
          </QModalTitle>
        }
        content={
          <QModalContent>
            <Viewer logs={[_.cloneDeep(firmwareApp.doppler ?? [])]} />
          </QModalContent>
        }
      >
        <QModalTitle toggle={() => toggleSarifViewer(null)}>
          SARIF Report for {firmwareApp.package}
        </QModalTitle>
        <QModalContent>
          <Viewer logs={[_.cloneDeep(firmwareApp.doppler ?? [])]} />
        </QModalContent>
      </QModal>

      <QCard>
        <QCardHeader
          title={
            <QTypography variant="h4Medium" color="#000000">
              <strong>Firmware Apps</strong>
            </QTypography>
          }
        >
          <strong>Firmware Apps</strong>
        </QCardHeader>

        <QCardBody>
          {isLoading ? (
            <Loading />
          ) : (
            <QDataTable
              data={apps.filter(app => app.status === 2)}
              columns={[
                {
                  label: 'App',
                  component: ({ rowData: app }) => (
                    <FirmwareAppInfo app={app} />
                  ),
                  filterable: true,
                  filterFunc: (filterStr, app) =>
                    app.package.includes(filterStr) ||
                    app.name.includes(filterStr),
                },
                {
                  label: 'Date Submitted',
                  component: ({ rowData }) => (
                    <DateTime dateTime={rowData.createdAt} />
                  ),
                },
                {
                  label: 'Threat Score',
                  component: ({ rowData }) => (
                    <ThreatScoreBadge threatScore={rowData.threatScore} />
                  ),
                },
                {
                  label: 'Core Findings',
                  keyName: 'coreFindings',
                  sortable: true,
                },
                {
                  label: 'Actions',
                  component: ({ rowData: app }) => (
                    <FirmwareAppActions
                      app={app}
                      toggleSarifViewer={toggleSarifViewer}
                      getFirmwareAppResultUrlBuilder={
                        getFirmwareAppResultUrlBuilder
                      }
                    />
                  ),
                },
              ]}
            />
          )}
        </QCardBody>
      </QCard>
    </div>
  );
}
