import React from 'react';
import { useSelector } from 'react-redux';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

const FilesWrittenDatatable = () => {
  const filesWrittenData =
    useSelector(
      ({ appResults }) =>
        appResults.results.analysis.analysis_details.dynamic_analysis
          .files_written_by_app,
    ) || [];

  return (
    <div>
      <DataTable
        rowsPerPage={5}
        data={filesWrittenData}
        columns={[
          {
            keyName: 'mode',
            label: i18n.t('Mode'),
            sortable: true,
            filterable: true,
          },
          {
            keyName: 'filepath',
            label: i18n.t('File'),
            sortable: true,
            filterable: true,
          },
        ]}
      />
    </div>
  );
};

export default FilesWrittenDatatable;
