import React, { Component } from 'react';

import CloudServicesList from '../../../iOS/CloudServicesList';
import { Harviewer } from '../../../../Harviewer/Harviewer';
import TabsView from '../../../../TabsView';
import i18n from '../../../../../localization/i18n';

export default class CloudStorageEvidence extends Component {
  render() {
    const tabs = [
      {
        title: i18n.t('Cloud Storage Services'),
        tabContent: () => <CloudServicesList />,
      },
      {
        title: i18n.t('Network Traffic'),
        tabContent: () => <Harviewer />,
      },
    ];
    return <TabsView tabs={tabs} />;
  }
}
