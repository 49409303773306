import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QTooltip as MuiTooltip } from '@kw/quokka-ui';

const LDUncontrolledTooltip = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiTooltip {...props}>{children}</MuiTooltip>
  ) : (
    <UncontrolledTooltip {...props}>{props.title}</UncontrolledTooltip>
  );
};
export const QUncontrolledTooltip = withLDConsumer()(LDUncontrolledTooltip);
