import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QTypography } from '../Q-Components/QTypography';
import { QDoughnut } from '../Q-Components/QDoughnut';

export const ComplianceChartCard = ({ results, compliance }) => {
  const { mastV2 } = useFlags();
  const getNumberOfComplianceIssueBasedOnPassFail = (results, isFailed) =>
    results.reduce((acc, result) => {
      const failedIssue = result[compliance]
        ? result[compliance].filter(
            item =>
              item.fail === isFailed ||
              item.kind === (isFailed ? 'fail' : 'pass'),
          ).length
        : 0;
      return acc + failedIssue;
    }, 0);

  const failedCount = getNumberOfComplianceIssueBasedOnPassFail(results, true);
  const passedCount = getNumberOfComplianceIssueBasedOnPassFail(results, false);

  const chartData = {
    labels: [`Failure`, `Success`],
    datasets: [
      {
        data: [failedCount, passedCount],
        backgroundColor: ['#FFA38B', '#DCE775'],
      },
    ],
  };

  return (
    <QCard>
      <QCardBody>
        <QCardHeader
          tag="h4"
          title={
            <QTypography variant="h5Medium">
              {compliance.toUpperCase()}
            </QTypography>
          }
          style={mastV2 ? {} : { backgroundColor: '#FFFFFF' }}
        >
          {compliance.toUpperCase()}
        </QCardHeader>
        <QDoughnut data={chartData} />
      </QCardBody>
    </QCard>
  );
};
