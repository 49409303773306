import React from 'react';
import { Input } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QTextField as MuiInput } from '@kw/quokka-ui';

const LDInput = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiInput {...props}>{children}</MuiInput>
  ) : (
    <Input {...props}>{children}</Input>
  );
};

export const QInput = withLDConsumer()(LDInput);
