import React from 'react';
import { TabContent } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

const LDTabContent = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <> {children} </>
  ) : (
    <TabContent {...props}>{children}</TabContent>
  );
};
export const QTabContent = withLDConsumer()(LDTabContent);
