import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { toastrHelper } from '../../../logic/toastrHelper';
import { QCard } from '../../Q-Components/QCard';
import { QCardHeader } from '../../Q-Components/QCardHeader';
import { QCardBody } from '../../Q-Components/QCardBody';
import { QLabel } from '../../Q-Components/QLabel';
import { QInput } from '../../Q-Components/QInput';
import { QButton } from '../../Q-Components/QButton';

export const DefaultExternalId = ({ initDefaultEid }) => {
  const [defaultEid, setDefaultEid] = useState(initDefaultEid);
  const customizedEid = useSelector(
    state => state.emmApp.userConfig.customized_eid,
  );
  const { mastV2 } = useFlags();

  const saveEid = async () => {
    const response = await axios.patch('analysis-customization/default-eid', {
      defaultEid,
    });

    if (response.status === 200) {
      toastrHelper.showSuccessToast('Saved default EID', null, mastV2);
    } else {
      console.log('Error with saving default EID:', response);
      toastrHelper.showErrorToast('Error saving default EID', null, mastV2);
    }
  };

  return (
    <QCard className="card-accent-warning-2">
      <QCardHeader
        title={<strong>Default {customizedEid || 'External ID'}</strong>}
      >
        <strong>Default {customizedEid || 'External ID'}</strong>
      </QCardHeader>
      <QCardBody>
        <p>
          This will be the default external ID assigned to applications
          submitted from the official app stores. You can use certain values to
          insert data about the application in the ID:
        </p>
        <ul>
          <li>
            <strong>{'{package}'}</strong>: Package or BundleID of application
          </li>
          <li>
            <strong>{'{version}'}</strong>: Version of the application
          </li>
          <li>
            <strong>{'{platform}'}</strong>: Platform of the application
          </li>
        </ul>

        <p>
          For example:
          <br />
          <i>{'MY_ID_{package}_{version}_{platform}'}</i>
        </p>

        <p>
          for a default ID would generate an ID similar to:
          <br />
          <i>MY_ID_com.company.appname_1.2.3_android</i>
        </p>
        <hr />
        <QLabel htmlFor="default-eid-input">
          Default {customizedEid || 'EID'} Template
        </QLabel>
        <QInput
          id="default-eid-input"
          value={defaultEid}
          onChange={e => setDefaultEid(e.target.value)}
          sx={{ marginBottom: '8px' }}
        />

        <QButton
          variant="filled"
          color="primary"
          className="mt-2"
          onClick={saveEid}
        >
          Save Default {customizedEid || 'EID'}
        </QButton>
      </QCardBody>
    </QCard>
  );
};
