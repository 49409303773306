import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import HighLevelFindings from '../HighLevelFindings';
import i18n from '../../../localization/i18n';

import AppInfoTable from './AppInfoTable';
import MarketInfoTable from './MarketInfoTable';

class Overview extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col className="col-sm-12 col-xxl-6">
            <Card className="card-accent-warning-2 app-info-section">
              <CardHeader>{i18n.t('Application Information')}</CardHeader>
              <CardBody>
                <AppInfoTable />
              </CardBody>
            </Card>
          </Col>
          <Col className="col-sm-12 col-xxl-6">
            <Card className="card-accent-warning market-info-section">
              <CardHeader>{i18n.t('Market Information')}</CardHeader>
              <CardBody>
                <MarketInfoTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div>
          <HighLevelFindings platform="android" />
        </div>
      </div>
    );
  }
}

export default Overview;
