import React from 'react';
import moment from 'moment';
import filesize from 'filesize';
import { connect } from 'react-redux';
import _ from 'lodash';

import { QTable } from '../../Q-Components/QTable';
import { QTableRow } from '../../Q-Components/QTableRow';
import { QTableCell } from '../../Q-Components/QTableCell';
import { QTableBody } from '../../Q-Components/QTableBody';
import { QTypography } from '../../Q-Components/QTypography';
import { QLink } from '../../Q-Components/QLink';
import iosReportUtil from '../../../logic/iosReportUtil';
import i18n from '../../../localization/i18n';

const MarketInfoTable = ({ results }) => {
  const { market: marketInfo } = results;

  return (
    <div>
      {_.isEmpty(marketInfo) ? (
        <span>{i18n.t('Not available')}</span>
      ) : (
        <QTable size="sm">
          <QTableBody>
            <QTableRow>
              <QTableCell>
                <p className="market-info-title">
                  <QTypography variant="bodyMedium">
                    {i18n.t('Category')}
                  </QTypography>
                </p>
                <span className="market-info-value">
                  <QTypography variant="bodyRegular">
                    {marketInfo.category}
                  </QTypography>
                </span>
              </QTableCell>
              <QTableCell>
                <p className="market-info-title">
                  <QTypography variant="bodyMedium">
                    {i18n.t('Last Updated')}
                  </QTypography>
                </p>
                <span className="market-info-value">
                  <QTypography variant="bodyRegular">
                    {moment(iosReportUtil.getLastUpdated(results)).format(
                      'L - LTS',
                    )}
                  </QTypography>
                </span>
              </QTableCell>
            </QTableRow>
            <QTableRow>
              <QTableCell>
                <p className="market-info-title">
                  <QTypography variant="bodyMedium">
                    {i18n.t('Size')}
                  </QTypography>
                </p>
                <span className="market-info-value">
                  <QTypography variant="bodyRegular">
                    {_.isNumber(marketInfo.size) && filesize(marketInfo.size)}
                  </QTypography>
                </span>
              </QTableCell>
              <QTableCell>
                <p className="market-info-title">
                  <QTypography variant="bodyMedium">
                    {i18n.t('Developer')}
                  </QTypography>
                </p>
                <span className="market-info-value">
                  <QTypography variant="bodyRegular">
                    {marketInfo.developer}
                  </QTypography>
                </span>
              </QTableCell>
            </QTableRow>
            <QTableRow>
              <QTableCell>
                <p className="market-info-title">
                  <QTypography variant="bodyMedium">
                    {i18n.t('Support Website')}
                  </QTypography>
                </p>
                <span className="market-info-value">
                  <QLink href={marketInfo.support_url} target="_blank">
                    {i18n.t('Support')}
                  </QLink>
                </span>
              </QTableCell>
              <QTableCell>
                <p className="market-info-title">
                  <QTypography variant="bodyMedium">
                    {i18n.t('Developer Website')}
                  </QTypography>
                </p>
                <span className="market-info-value">
                  <QLink href={marketInfo.seller_url} target="_blank">
                    {i18n.t('Website')}
                  </QLink>
                </span>
              </QTableCell>
            </QTableRow>
            <QTableRow>
              <QTableCell colSpan={2}>
                <p className="market-info-title">
                  <QTypography variant="bodyMedium">
                    {i18n.t('Privacy Policy')}
                  </QTypography>
                </p>
                <span className="market-info-value">
                  <QLink href={marketInfo.privacy_policy} target="_blank">
                    {i18n.t('Policy')}
                  </QLink>
                </span>
              </QTableCell>
            </QTableRow>
          </QTableBody>
        </QTable>
      )}
    </div>
  );
};

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(MarketInfoTable);
