import axios from 'axios';
import _ from 'lodash';

import util from '../../logic/util';
import { getCacheValue } from '../cacheHelpers/manageCache';
import { cacheAnalyzedApps } from '../slices/cache/analyzedAppsCacheSlice';
import { updateLabelOptions } from '../slices/analyzedAppsSlice';

const cache = 'analyzedApps';
export const fetchAnalyzedAppsCache =
  (url, params = {}) =>
  async dispatch => {
    const key = util.paramsToUrl(url, params);
    const cacheValue = getCacheValue(key, cache);

    if (cacheValue) {
      return JSON.parse(cacheValue);
    }
    const data = await axios.get(url, { params });
    if (data.status < 400) {
      dispatch(cacheAnalyzedApps({ key, data: JSON.stringify(data) }));
    }
    return data;
  };

export const fetchLabelOptions = () => async dispatch => {
  const labelOptions = [{ value: 'all', label: 'All' }];

  try {
    const { data } = await axios.get(`api/labels/get-all-labels`);

    if (data.labels.length) {
      labelOptions.push(
        ...data.labels.map(item => ({
          value: item.label,
          label: _.startCase(item.label),
        })),
      );

      dispatch(updateLabelOptions(labelOptions));
    }
  } catch (err) {
    console.log('Error getting labels for group:', err);
  }

  return labelOptions;
};
