import React from 'react';
import { useSelector } from 'react-redux';
import { CardText } from 'reactstrap';

import DynamicAnalysisDatatable from '../../Android/DynamicAnalysisDatatable';
import { StaticAnalysisDatatable } from '../../Android/StaticAnalysisDatatable';
import NiapCard from '../../../../views/Reports/NiapDetails/NiapCard';

import { Issues } from './Issues';

export const V3_6 = ({ platform, evidence }) => {
  const niapResults = useSelector(({ appResults }) => appResults.niapResults);

  return (
    <>
      <Issues
        evidence={evidence}
        issues={['DOES_NOT_SECURELY_GENERATES_KEYS']}
      />
      <hr />
      <CardText>
        <strong>NIAP Results</strong>
      </CardText>
      <NiapCard
        finding="FCS_RBG_EXT.1.1"
        platform={platform}
        // eslint-disable-next-line camelcase
        niapFindings={niapResults?.niap_sections}
        filter=""
      />
      {platform === 'android' && (
        <>
          <hr />
          <CardText>
            <strong>Encryptions</strong>
          </CardText>
          <DynamicAnalysisDatatable
            onlySelectedCategory={['Cryptography', 'Crypto', 'Crypto Events']}
            methodFilter={['SecureRandom', 'KeyGenerator']}
          />
          <hr />
          <CardText>
            <strong>Static Analysis</strong>
          </CardText>
          <StaticAnalysisDatatable categoryFilter="methods" keyFilter="all" />
        </>
      )}
    </>
  );
};
