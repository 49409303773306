import React from 'react';
import { useSelector } from 'react-redux';
import { ListGroup, ListGroupItem } from 'reactstrap';

export const V1_10 = ({ evidence }) => {
  const reportItems = useSelector(({ appResults }) => appResults.reportItems);

  const { foundSecurityIssues = [] } = evidence;

  const securityReportItems = reportItems.filter(
    reportItem =>
      reportItem.category === 'security' &&
      !foundSecurityIssues?.find(
        issue => issue.reportItem.id === reportItem.id,
      ),
  );

  return (
    <ListGroup>
      {foundSecurityIssues &&
        foundSecurityIssues.map(issue => (
          <ListGroupItem key={issue.reportItem.id}>
            <span>
              <i
                className={`fa-solid fa-exclamation-triangle mr-2 ${
                  issue.reportItem.risk === 'critical'
                    ? 'critical-icon'
                    : issue.reportItem.risk === 'high'
                    ? 'danger-icon'
                    : 'warning-icon'
                }`}
              />
              {issue.reportItem.positive_finding_text}
            </span>
          </ListGroupItem>
        ))}

      {securityReportItems.map(reportItem => (
        <ListGroupItem key={reportItem.id}>
          <span>
            <i className="fa-solid fa-circle-check success-icon mr-2" />
            {reportItem.negative_finding_text}
          </span>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};
