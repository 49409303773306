import React, { Component } from 'react';
import classnames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

export default class TabsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '0',
    };
  }

  render() {
    // Tabs will be an array of
    // { title: string, tabContent: () => Component }
    const { tabs } = this.props;
    const { activeTab } = this.state;

    return (
      <>
        <Nav tabs>
          {tabs.map((tab, index) => (
            <NavItem key={tab.title}>
              <NavLink
                className={classnames({
                  active: activeTab === index.toString(),
                })}
                onClick={() => {
                  this.setState({ activeTab: index.toString() });
                }}
                disabled={tab.disabled}
              >
                {typeof tab.title === 'string' ? tab.title : tab.title()}
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        <TabContent activeTab={activeTab} style={{ width: '100%' }}>
          {tabs.map((tab, index) => (
            <TabPane
              key={tab.title}
              tabId={index.toString()}
              style={{ padding: 16 }}
              className="animated fadeIn"
            >
              {tab.tabContent()}
            </TabPane>
          ))}
        </TabContent>
      </>
    );
  }
}
