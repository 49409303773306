import React from 'react';

import i18n from '../../localization/i18n';
import { QListGroup } from '../Q-Components/QListGroup';
import { QListGroupItem } from '../Q-Components/QListGroupItem';

const VersionLibraries = ({ platform, librariesSelf, librariesOther }) => {
  if (!librariesSelf || !librariesOther) {
    return <>Not available</>;
  }
  if (platform === 'android') {
    librariesSelf = librariesSelf.dynamic_analysis.filter(
      analysis => analysis.category === 'Libraries Loaded',
    );
    librariesOther = librariesOther.dynamic_analysis.filter(
      analysis => analysis.category === 'Libraries Loaded',
    );
  }
  if (platform === 'ios') {
    let tempLibrariesSelf =
      librariesSelf.dynamic_analysis.dynamically_loaded_libraries_frameworks;
    tempLibrariesSelf = tempLibrariesSelf.concat(
      Object.values(
        librariesSelf.static_analysis.libraries_used['Dynamic Libraries'],
      ),
    );
    librariesSelf = tempLibrariesSelf;

    let tempLibrariesOther =
      librariesOther.dynamic_analysis.dynamically_loaded_libraries_frameworks;
    tempLibrariesOther = tempLibrariesOther.concat(
      Object.values(
        librariesOther.static_analysis.libraries_used['Dynamic Libraries'],
      ),
    );
    librariesOther = tempLibrariesOther;
  }
  return (
    <div>
      <QListGroup
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          maxHeight: '200px',
          overflow: 'scroll',
        }}
      >
        {platform === 'android' &&
          librariesSelf.map(library => {
            let found = false;

            for (let i = 0; i < librariesOther.length; i++) {
              if (librariesOther[i].parameters[0] === library.parameters[0]) {
                found = true;
                break;
              }
            }
            return (
              <QListGroupItem
                color={found ? '' : 'warning'}
                sx={found ? {} : { backgroundColor: '#FFB822' }}
              >
                <div>
                  <a
                    href={library.api_link}
                    target="_blank"
                    className="no-link"
                  >
                    {library.method_name}
                  </a>
                </div>
                {/* Component */}
                {library.component && (
                  <div>
                    <strong>Component: </strong>
                    {library.component}
                  </div>
                )}
                {/* File */}
                <div>
                  <strong>File: </strong>
                  {library.smali_file.file.includes('/smali/')
                    ? library.smali_file.file.split('/smali/')[1]
                    : library.smali_file.file}
                </div>
                {/* Line number */}
                <div>
                  <strong>Line: </strong>
                  {library.smali_file.line_num !== 'p1' &&
                  library.smali_file.line_num !== '-1' ? (
                    <span>{library.smali_file.line_num}</span>
                  ) : (
                    <span>N/A</span>
                  )}
                </div>
              </QListGroupItem>
            );
          })}
        {platform === 'ios' &&
          librariesSelf.map(library => {
            let found = false;
            for (let i = 0; i < librariesOther.length; i++) {
              if (librariesOther[i].path === library.path) {
                found = true;
                break;
              }
            }
            return (
              <QListGroupItem
                color={found ? '' : 'warning'}
                sx={found ? {} : { backgroundColor: '#FFB822' }}
              >
                <span>{library.path.split('/').slice(-1)[0]}</span>
                {' - '}
                <span>{library.loaded_using || i18n.t('Unknown')}</span>
              </QListGroupItem>
            );
          })}
      </QListGroup>
    </div>
  );
};

export default VersionLibraries;
