import React from 'react';
import Select from 'react-select';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QAutocomplete as MuiAutocomplete } from '@kw/quokka-ui';

const LDAutocomplete = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiAutocomplete {...props}>{children}</MuiAutocomplete>
  ) : (
    <Select {...props}>{children}</Select>
  );
};
export const QAutocomplete = withLDConsumer()(LDAutocomplete);
