import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import i18n from '../localization/i18n';

import { QPagination } from './Q-Components/QPagination';
import { QPaginationItem } from './Q-Components/QPaginationItem';
import { QPaginationLink } from './Q-Components/QPaginationLink';
import { QRow } from './Q-Components/QRow';
import { QCol } from './Q-Components/QCol';
import { QDropdownWrapper } from './Q-Components/QDropdown';

const pageNumbers = (pagination, page) => {
  const maxPages = 2;
  let from = page - maxPages;
  if (from < 1) {
    from = 1;
  }
  let to = from + maxPages * 2; // eslint-disable-line
  if (to >= pagination.lastPage) {
    to = pagination.lastPage;
  }

  const pagesArray = [];
  for (let i = from; i <= to; i++) {
    pagesArray.push(i);
  }
  return pagesArray;
};

const RowsPerPage = ({
  page,
  rowsPerPage,
  totalRows,
  currentRows,
  handlePageSizeChange,
  pageSize,
  pageSizeOptions,
}) => {
  const handleChange = value => {
    handlePageSizeChange(
      pageSizeOptions.find(option => option.value === value),
    );
  };
  return (
    <QCol>
      <QRow
        style={{
          alignItems: 'center',
          columnGap: '5px',
          color: '#000000',
        }}
      >
        {totalRows === 0
          ? `Displaying 0 of 0`
          : `Displaying ${(page - 1) * rowsPerPage + 1} -
                  ${(page - 1) * rowsPerPage + currentRows} of ${totalRows}`}

        <QDropdownWrapper
          options={pageSizeOptions}
          selectedValue={pageSize}
          onChange={event => {
            handleChange(event.target.value);
          }}
          closedLabel={pageSize.value}
          variant="outlined"
          formControlProps={{
            style: {
              paddingTop: '0px',
              paddingBottom: '0px',
            },
          }}
          labelProps={{
            disableAnimation: true,
            shrink: false,
            style: {
              transform: 'translate(16px, 6px) scale(1)',
              overflow: 'visible',
            },
          }}
          selectProps={{
            style: {
              background: 'none',
              paddingTop: '0px',
              paddingBottom: '4.5px',
              minWidth: '70px',
            },
          }}
          id="rows-per-page"
        />
      </QRow>
    </QCol>
  );
};

const AsyncTablePagination = ({
  page,
  pagination,
  getPage,
  showRowsPerPage = false,
  currentRows = null,
  handlePageSizeChange = null,
  pageSize = null,
  pageSizeOptions = null,
}) => {
  const { mastV2 } = useFlags();

  return mastV2 ? (
    <QRow
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '64px',
        backgroundColor: '#FAFAFA',
        paddingLeft: '15px',
        paddingRight: '15px',
      }}
    >
      {showRowsPerPage &&
      pagination.dataPerPage &&
      pagination.totalData &&
      currentRows &&
      handlePageSizeChange &&
      pageSize &&
      pageSizeOptions ? (
        <RowsPerPage
          page={page}
          rowsPerPage={pagination.dataPerPage}
          totalRows={pagination.totalData}
          currentRows={currentRows}
          handlePageSizeChange={handlePageSizeChange}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
        />
      ) : (
        <div />
      )}
      <QPagination
        count={pagination.lastPage}
        page={page}
        onChange={(event, page) => getPage(page, event)}
      />
    </QRow>
  ) : (
    <div className="d-flex justify-end">
      <div>
        <div id="pagination-info">
          <span>
            {i18n.t('Page')} {page} {i18n.t('of')} {''}
            {pagination.lastPage === 0 ? 1 : pagination.lastPage}
          </span>
        </div>
        <QPagination>
          {/* First page */}
          {page > 1 && (
            <QPaginationItem>
              <QPaginationLink
                className="first-page-btn"
                href="#"
                onClick={e => getPage(1, e)}
              >
                {i18n.t('First')}
              </QPaginationLink>
            </QPaginationItem>
          )}
          {/* Previous page */}
          {page > 1 && (
            <QPaginationItem>
              <QPaginationLink
                previous
                className="previous-page-btn"
                href="#"
                onClick={e => getPage(page - 1, e)}
              />
            </QPaginationItem>
          )}

          {/* Individual page */}
          {pageNumbers(pagination, page).map((pageNum, index) => (
            <QPaginationItem key={index} active={pageNum === page}>
              <QPaginationLink
                key={index}
                className="page-num-btn"
                href="#"
                onClick={e => getPage(pageNum, e)}
              >
                {pageNum}
              </QPaginationLink>
            </QPaginationItem>
          ))}

          {/* Next page */}
          {page < pagination.lastPage && (
            <QPaginationItem>
              <QPaginationLink
                next
                className="next-page-btn"
                href="#"
                onClick={e => getPage(page + 1, e)}
              />
            </QPaginationItem>
          )}

          {/* Last page */}
          {page < pagination.lastPage && (
            <QPaginationItem>
              <QPaginationLink
                href="#"
                className="last-page-btn"
                onClick={e => getPage(pagination.lastPage, e)}
              >
                {i18n.t('Last')}
              </QPaginationLink>
            </QPaginationItem>
          )}
        </QPagination>
      </div>
    </div>
  );
};

export default AsyncTablePagination;
