import React from 'react';
import { useSelector } from 'react-redux';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

export const FunctionsDetectedDataTable = () => {
  const functionsDetectedData =
    useSelector(
      ({ appResults }) =>
        appResults.results.analysis.analysis_details.static_analysis
          .functions_detected,
    ) || [];

  return (
    <div>
      <DataTable
        rowsPerPage={5}
        data={functionsDetectedData}
        columns={[
          {
            keyName: 'api_url',
            label: i18n.t('API URL'),
            sortable: true,
            filterable: true,
          },
          {
            keyName: 'description',
            label: i18n.t('Description'),
            sortable: true,
            filterable: true,
          },
          {
            keyName: 'functionality',
            label: i18n.t('Functionality'),
            sortable: true,
            filterable: true,
          },
          {
            keyName: 'library',
            label: i18n.t('Library'),
            sortable: true,
            filterable: true,
          },
          {
            keyName: 'symbol',
            label: i18n.t('Symbol'),
            sortable: true,
            filterable: true,
          },
          {
            keyName: 'tag',
            label: i18n.t('Tag'),
            sortable: true,
            filterable: true,
          },
        ]}
      />
    </div>
  );
};
