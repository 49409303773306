import React, { useEffect } from 'react';

import util from '../logic/util';

export const SsoLoginCompletion = () => {
  useEffect(() => {
    const token = util.getQueryVariable(
      'token',
      `?${window.location.href.split('?')[1]}`,
    );
    localStorage.setItem('token', `Bearer ${token}`);

    window.location.replace('/');
  }, []);

  return <div />;
};
