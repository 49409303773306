import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

import { QTable } from '../../../../Q-Components/QTable';
import { QTableBody } from '../../../../Q-Components/QTableBody';
import { QModal } from '../../../../Q-Components/QModal';
import { QModalTitle } from '../../../../Q-Components/QModalTitle';
import { QModalContent } from '../../../../Q-Components/QModalContent';
import { QIconButton } from '../../../../Q-Components/QIconButton';
import { QTypography } from '../../../../Q-Components/QTypography';

export function StacksEvidenceModal({ isOpen, toggle, stacks, startLocation }) {
  const { mastV2 } = useFlags();

  const Stacks = ({ stacks }) => {
    const styles = {
      additionalInfo: {
        backgroundColor: '#DDEFE9',
      },
    };

    return (
      <>
        {mastV2 ? (
          <QTypography variant="h5Medium">{startLocation}</QTypography>
        ) : (
          <div style={{ fontSize: '18px', fontWeight: 600 }}>
            {startLocation}
          </div>
        )}

        <SyntaxHighlighter key={Math.random()}>
          {stacks.map((stack, stackIndex) =>
            stack.frames?.map((frame, frameIndex) =>
              frame.location?.logicalLocations?.map(
                (location, locationIndex) => {
                  let methodName;
                  let additionalInfo;
                  const fullName = location.fullyQualifiedName;

                  if (fullName && fullName.includes('(')) {
                    const firstParenthesisIndex = fullName.indexOf('(');
                    methodName = fullName.substring(0, firstParenthesisIndex);
                    additionalInfo = fullName.substring(firstParenthesisIndex);
                  } else {
                    methodName = fullName;
                    additionalInfo = '';
                  }

                  const key = `stack-${stackIndex}-frame-${frameIndex}-location-${locationIndex}`;

                  return (
                    <React.Fragment key={key}>
                      <span>{methodName}</span>
                      {additionalInfo && (
                        <span style={styles.additionalInfo}>
                          {additionalInfo}
                        </span>
                      )}
                      <br />
                    </React.Fragment>
                  );
                },
              ),
            ),
          )}
        </SyntaxHighlighter>
      </>
    );
  };

  return mastV2 ? (
    <Dialog
      open={isOpen}
      toggle={toggle}
      PaperProps={{
        style: { width: '1000px' },
      }}
    >
      <DialogTitle sx={{ padding: '0px' }}>
        <QIconButton
          onClick={toggle}
          style={{ float: 'right', color: '#000000', margin: '12px' }}
        >
          <CloseIcon />
        </QIconButton>
      </DialogTitle>
      <DialogContent>
        <QTable>
          <QTableBody>
            <Stacks stacks={stacks} />
          </QTableBody>
        </QTable>
      </DialogContent>
    </Dialog>
  ) : (
    <QModal isOpen={isOpen} toggle={toggle} size="lg">
      <QModalTitle toggle={toggle}>Stacks</QModalTitle>
      <QModalContent>
        <QTable>
          <QTableBody>
            <Stacks stacks={stacks} />
          </QTableBody>
        </QTable>
      </QModalContent>
    </QModal>
  );
}
