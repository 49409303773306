import React, { Component } from 'react';
import axios from 'axios';

import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';

import ThreatScoreThreshold from './AnalysisSettings/ThreatScoreThreshold';
import ForeignConnection from './AnalysisSettings/ForeignConnection';
import TrafficRegexFilter from './AnalysisSettings/TrafficRegexFilter';
import { DefaultExternalId } from './AnalysisSettings/DefaultExternalId';

export default class AnalysisSettings extends Component {
  constructor() {
    super();

    this.state = {
      customizationData: null,
    };
  }

  async componentDidMount() {
    // Get the initial values for the customization
    const response = await axios.get('analysis-customization');
    this.setState({ customizationData: response.data });
  }

  render() {
    const { customizationData } = this.state;
    if (!customizationData) {
      return null;
    }

    return (
      <QRow columnGap="30px">
        <QCol flex="1" xs="3">
          <ThreatScoreThreshold />
        </QCol>
        <QCol flex="1" xs="3">
          <ForeignConnection
            initCountry={
              customizationData ? customizationData.homeCountryCode : null
            }
          />
        </QCol>
        <QCol flex="1" xs="3">
          <DefaultExternalId initDefaultEid={customizationData.defaultEid} />
        </QCol>
        <QCol flex="1" xs="3">
          <TrafficRegexFilter />
        </QCol>
      </QRow>
    );
  }
}
