import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import axios from 'axios';

import i18n from '../localization/i18n';
import {
  setUserConfigData,
  toggleHelpAsideOpen,
} from '../store/slices/emmAppSlice';

import Tooltip from './Tooltip';
import { portalStyles } from './JoyTourConfig';

export function ReportButton(props) {
  const history = useHistory();
  const analyzedApps = useSelector(({ cache }) => cache.analyzedApps);

  function getFirstApp() {
    const appKeys = Object.keys(analyzedApps).find(key =>
      key.startsWith('emm/apps/1'),
    );
    console.log('appkeys', appKeys, analyzedApps[appKeys]);
    if (appKeys) {
      return JSON.parse(analyzedApps[appKeys].value).data.apps[0].farm_app;
    }
    return {};
  }
  async function reportWalkthrough(e) {
    e.preventDefault();
    props.skipProps.onClick(e);

    const config = { ...props.config };
    config.report_tour_taken = false;
    props.setConfig(config);
    const firstAppUUID = getFirstApp();
    const reportType =
      firstAppUUID.platform === 'ios' ? 'ios-report' : 'android-report';
    history.push(`/${reportType}/${firstAppUUID.uuid}`);
  }
  return (
    <Button onClick={reportWalkthrough} style={portalStyles.buttonSkip}>
      {' '}
      {props.index + 1 !== props.totalSteps
        ? 'Skip To The Report'
        : 'Start Report Walkthrough'}
    </Button>
  );
}

export default function JoyTour({ isTourActive, name }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const userConfig = useSelector(({ emmApp }) => emmApp.userConfig);
  const [stepIndex, setStepIndex] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [config, setConfig] = useState(userConfig);

  let joyride;

  useEffect(() => {
    setIsActive(isTourActive);
  }, [isTourActive]);

  useEffect(() => {
    dispatch(setUserConfigData(config));
  }, [config]);

  const tourSteps = [
    {
      title: i18n.t('Help Menu'),
      content: (
        <div>
          <p>
            {i18n.t(
              `Hello${
                name ? ` ${name}` : ''
              }! To help get started with Quokka, we have created this walkthrough to assist you with learning how to use the Quokka portal.`,
            )}
          </p>
          <p>
            {i18n.t(
              `You can pause the walkthrough and restart it any time by clicking the button at the bottom of this Support menu.`,
            )}
          </p>
        </div>
      ),
      placement: 'bottom',
      target: '.help-menu-toggle',
      isFixed: false,
      id: 'help-menu',
      disableBeacon: true,
    },
    {
      title: i18n.t('App Submissions'),
      content: (
        <div>
          <p>{i18n.t('You can submit Android or iOS Apps')}</p>
          <div style={portalStyles.androidIosFlow}>
            <Button
              color="success"
              style={portalStyles.buttonFlow}
              onClick={() => {
                history.push('/submit-app');
                joyride.helpers.next();
              }}
            >
              App Submission Flow
            </Button>
          </div>
        </div>
      ),
      placement: 'right',
      target: '.submit-app',
      event: 'hover',
      isFixed: true,
      disableBeacon: true,
      styles: {
        buttonNext: {
          display: 'none',
        },
      },
    },
    {
      title: i18n.t('App Store Search'),
      content: i18n.t(
        'App Store Search allows you to search the official app stores for applications you want to submit.',
      ),
      placement: 'bottom',
      target: '.store-search-submit',
      isFixed: false,
    },
    {
      title: i18n.t('Binary Upload'),
      content: i18n.t(
        'Use Binary Upload to select a binary application to upload for analysis.',
      ),
      placement: 'right',
      target: '.binary-submit-label',
      isFixed: false,
      id: 'binary-submit-label',
    },
    {
      title: i18n.t('Store Link Submit'),
      content: i18n.t(
        'Store Link let you submit direct link in the official app stores for the application you want to analyze.',
      ),
      placement: 'right',
      target: '.store-link-submit-label',
      isFixed: false,
      id: 'store-link-submit-label',
    },
    {
      title: i18n.t('Bulk Submit'),
      content: i18n.t(
        'Bulk Submit allow you to upload a list of up to 50 apps for analysis in bulk. To use the Bulk Submit feature, add the list of apps by package name or store URL with one app per line.',
      ),
      placement: 'right',
      target: '.bulk-apps-submit-label',
      isFixed: false,
      id: 'bulk-apps-submit-label',
    },
    {
      title: i18n.t('App Results'),
      content: i18n.t(
        'Once applications are submitted for analysis, they will show up in the Analyzed Applications page. Click on the Application Name to view the report for that application.',
      ),
      placement: 'bottom',
      target: '.analyzed-app-info-link',
      isFixed: false,
    },
    {
      title: i18n.t('Reports'),
      content: i18n.t(
        'The Reports button will allow you to view or download different reports and report data directly from the Analyzed Applications page.',
      ),
      placement: 'bottom',
      target: '.report-action-dropdown',
      isFixed: false,
    },
  ];

  function tooltipComponent(props) {
    const totalSteps = tourSteps.length;
    const reportButtonProps = {
      totalSteps,
      config,
      setConfig,
      ...props,
    };
    const reportButton = <ReportButton {...reportButtonProps} />;
    const tooltipComponent = (
      <Tooltip
        totalSteps={totalSteps}
        reportButton={reportButton}
        {...props}
        optionalStyle={portalStyles}
      />
    );
    return tooltipComponent;
  }

  // In both submit-android and submit-ios pages there are three tabs as NavItems, this logic will automatically deactivate the previous one and activate the new one
  // The activated tab will have "active" as one of the classnames
  function changeTabInSubmitAppPage(data) {
    const { linkToDeactivate, linkToActivate, tabToDeactivate, tabToActivate } =
      data;
    document.getElementById(linkToDeactivate).classList.remove('active');
    document.getElementById(linkToActivate).classList.add('active');
    document.getElementById(tabToDeactivate).classList.remove('active');
    document.getElementById(tabToActivate).classList.add('active');
  }

  async function joyrideCallback(data) {
    const { action, index, type, status, step, lifecycle } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setIsActive(false);
      setStepIndex(0);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      setStepIndex(stepIndex);
    }

    // In both submit-android and submit-ios pages there are three tabs as NavItems, this logic will automatically deactivate the previous one and activate the new one
    if (
      step.id === 'binary-submit-label' &&
      action === ACTIONS.PREV &&
      lifecycle === 'ready'
    ) {
      changeTabInSubmitAppPage({
        linkToDeactivate: 'store-link-submit-link',
        linkToActivate: 'binary-submit-link',
        tabToDeactivate: 'store-link-submit-tab',
        tabToActivate: 'binary-submit-tab',
      });
    }
    if (step.id === 'store-link-submit-label') {
      if (action === ACTIONS.NEXT && lifecycle === 'ready') {
        changeTabInSubmitAppPage({
          linkToDeactivate: 'binary-submit-link',
          linkToActivate: 'store-link-submit-link',
          tabToDeactivate: 'binary-submit-tab',
          tabToActivate: 'store-link-submit-tab',
        });
      }
      if (action === ACTIONS.PREV && lifecycle === 'ready') {
        changeTabInSubmitAppPage({
          linkToDeactivate: 'bulk-apps-submit-link',
          linkToActivate: 'store-link-submit-link',
          tabToDeactivate: 'bulk-apps-submit-tab',
          tabToActivate: 'store-link-submit-tab',
        });
      }
    }
    if (step.id === 'bulk-apps-submit-label') {
      if (action === ACTIONS.NEXT && lifecycle === 'ready') {
        changeTabInSubmitAppPage({
          linkToDeactivate: 'store-link-submit-link',
          linkToActivate: 'bulk-apps-submit-link',
          tabToDeactivate: 'store-link-submit-tab',
          tabToActivate: 'bulk-apps-submit-tab',
        });
      }
      if (action === ACTIONS.NEXT && lifecycle === 'complete') {
        setIsActive(false);

        history.push('/dashboard');

        setTimeout(() => {
          setIsActive(true);
        }, 500);
      }
    }

    // Force side menu open to show the user where they can retake the tour
    if (step.id === 'help-menu' && type === 'step:after') {
      setIsActive(false);

      if (document.body.classList.contains('aside-menu-hidden')) {
        document.body.classList.toggle('aside-menu-hidden');
        dispatch(toggleHelpAsideOpen());
      }

      setTimeout(() => {
        setIsActive(true);
      }, 500);
    }

    if (data.type === 'tour:end') {
      console.log('Setting the config value to say the tour was already taken');
      const response = await axios.put('emm/config', {
        config_key: 'tour_taken',
        value: 1,
      });
      if (response.status === 200) {
        console.log('Tour config updated');
      } else {
        console.error(response);
      }
    }
  }

  return (
    <Joyride
      ref={c => {
        joyride = c;
      }}
      continuous
      run={isActive}
      steps={tourSteps}
      stepIndex={stepIndex}
      showProgress
      showSkipButton
      locale={{
        back: <span>{i18n.t('Back')}</span>,
        close: <span>{i18n.t('Close')}</span>,
        last: <span>{i18n.t('Last')}</span>,
        next: <span>{i18n.t('Next')}</span>,
        skip: <span>{i18n.t('Skip')}</span>,
      }}
      styles={portalStyles}
      callback={joyrideCallback}
      scrollOffset={75}
      tooltipComponent={tooltipComponent}
    />
  );
}
