import React from 'react';
import { CardText } from 'reactstrap';

import DynamicAnalysisDatatable from '../../Android/DynamicAnalysisDatatable';
import AcceptsAllCerts from '../../SimpleReport/ReportFindingCard/AndroidEvidence/AcceptsAllCerts';
import { Harviewer } from '../../../Harviewer/Harviewer';
import CryptoOperationsDatatable from '../../iOS/CryptoOperationsDatatable';

import { Issues } from './Issues';

export const V5_3 = ({ platform, evidence }) => (
  <>
    <Issues
      evidence={evidence}
      issues={['ACCEPTS_ALL_CERTS', 'HTTP_TRAFFIC', 'MAY_ACCEPT_ALL_CERTS']}
    />
    <hr />
    {platform === 'android' ? (
      <>
        <CardText>
          <strong>Dynamic Analysis</strong>
        </CardText>
        <DynamicAnalysisDatatable
          onlySelectedCategory="Network Events"
          methodFilter={['HostnameVerifier', 'SSLContext.init']}
        />
        <hr />
        <AcceptsAllCerts />
      </>
    ) : (
      <>
        <CardText>
          <strong>Cryptographic Operations</strong>
        </CardText>
        <CryptoOperationsDatatable />
      </>
    )}
    <hr />
    <CardText>
      <strong>Network Traffic</strong>
    </CardText>
    <Harviewer urlFilter="http://" />
  </>
);
