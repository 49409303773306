import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Input,
  FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';

import * as actions from '../../store/slices/appResultsSlice';
import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';

export default function EditAppIssueModal({
  open,
  toggle,
  appIssue,
  farmAppUuid,
}) {
  const [reviewStatus, setReviewStatus] = useState(null);
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [saveReview, setSaveReview] = useState(false);
  const dispatch = useDispatch();
  const { mastV2 } = useFlags();

  const reviewStatusOptions = [
    { value: 'default', label: i18n.t('Default') },
    { value: 'failed', label: i18n.t('Failed') },
    { value: 'passed', label: i18n.t('Passed') },
  ];

  async function saveChanges() {
    console.log('Saving changes to app issue id:', appIssue.id);
    setLoading(true);

    try {
      const response = await axios.put(`app-issues/${appIssue.id}`, {
        reviewStatus: reviewStatus.value,
        note,
        appliedAllVersion: saveReview,
      });

      if (response.status !== 200) {
        toastrHelper.showErrorToast(response.data.msg, i18n.t('Error'), mastV2);
      }

      toastrHelper.showSuccessToast(
        response.data.msg,
        i18n.t('Success'),
        mastV2,
      );
    } catch (error) {
      toastrHelper.showErrorToast(error.response.data.msg, 'Error', mastV2);
    }

    setSaveReview(false);

    const newAppIssues = await axios.get(`app-issues?uuid=${farmAppUuid}`);
    dispatch(actions.setAppIssues(newAppIssues.data.map(entry => entry.issue)));
    dispatch(
      actions.setNiapAppIssues(
        newAppIssues.data.filter(entry => entry.issue.startsWith('niap_')),
      ),
    );
    dispatch(
      actions.setOwaspMasvsAppIssues(
        newAppIssues.data.filter(entry =>
          entry.issue.startsWith('owasp_masvs_'),
        ),
      ),
    );
    setLoading(false);
    toggle();
  }

  useEffect(() => {
    setNote(appIssue.note || '');
    const currentReviewStatus = appIssue.review_status || 'default';
    setReviewStatus(
      reviewStatusOptions.find(option => option.value === currentReviewStatus),
    );
  }, [appIssue]);

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader>
        {i18n.t('Edit App Issue')}: {i18n.t(appIssue.issue)}
      </ModalHeader>
      <ModalBody>
        <Label>
          <strong>{i18n.t('Note')}:</strong>
        </Label>
        <Input
          type="textarea"
          id="app-issue-note-input"
          value={note}
          onChange={e => setNote(e.target.value)}
        />
        <Button color="warning" className="mt-2" onClick={() => setNote('')}>
          {i18n.t('Clear Note')}
        </Button>
        <span style={{ float: 'right' }}>
          <small>
            <i>{i18n.t('Minimum 10 characters')}</i>
          </small>
        </span>
        {appIssue && appIssue.note_user_email && (
          <div className="mb-2">
            <small>
              <i>Last modified by: </i>
              {appIssue.note_user_email} on{' '}
              {moment(appIssue.note_updated).format('L - LTS')}
            </small>
          </div>
        )}
        <hr />
        <FormGroup>
          <Label for="review-status-input">
            <strong>{i18n.t('Review Status')}:</strong>
          </Label>
          <Select
            options={reviewStatusOptions}
            value={reviewStatus}
            onChange={option => setReviewStatus(option)}
          />
          {appIssue && appIssue.review_status_user_email && (
            <div className="mb-2">
              <small>
                <i>Last modified by: </i>
                {appIssue.review_status_user_email} on{' '}
                {moment(appIssue.review_status_updated).format('L - LTS')}
              </small>
            </div>
          )}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <span style={{ position: 'absolute', left: 20 }}>
          <Label check>
            <Input
              type="checkbox"
              id="save-review"
              checked={saveReview}
              onChange={() => setSaveReview(!saveReview)}
            />
            &nbsp; Apply status and note to all versions?
          </Label>
        </span>
        <Button color="secondary" onClick={toggle} disabled={loading}>
          {i18n.t('Cancel')}
        </Button>
        <Button
          color="primary"
          onClick={saveChanges}
          disabled={loading || note.length < 10}
        >
          {i18n.t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
