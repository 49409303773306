import React from 'react';
import { CardText } from 'reactstrap';

import FilesWrittenDatatable from '../../iOS/FilesWrittenDatatable';
import FileAccessDatatable from '../../iOS/FileAccessDatatable';
import { Cookies } from '../../iOS/Cookies';
import KeychainSecurityAccessibleEvidence from '../../SimpleReport/ReportFindingCard/IosEvidence/KeychainSecurityAccessibleEvidence';
import KeyChainOperations from '../../iOS/KeyChainOperations';
import DynamicAnalysisDatatable from '../../Android/DynamicAnalysisDatatable';
import CanWriteExternalStorage from '../../SimpleReport/ReportFindingCard/AndroidEvidence/CanWriteExternalStorage';
import { StaticAnalysisDatatable } from '../../Android/StaticAnalysisDatatable';

import { Issues } from './Issues';

export const V2_2 = ({ platform, evidence }) => (
  <>
    <Issues
      evidence={evidence}
      issues={[
        'DOES_NOT_USE_KEYCHAIN',
        'KEYCHAIN_SECURITY_ACCESSIBLE',
        'CAN_WRITE_EXTERNAL_STORAGE',
      ]}
    />
    <hr />
    {platform === 'android' ? (
      <>
        <CardText>
          <strong>KeyChain/KeyStore Operations</strong>
        </CardText>
        <DynamicAnalysisDatatable
          onlySelectedCategory="Credentials"
          methodFilter={['KeyStore', 'KeyChain']}
        />
        <hr />
        <CardText>
          <strong>Storage IO</strong>
        </CardText>
        <StaticAnalysisDatatable
          categoryFilter="methods"
          keyFilter="storageIO"
        />
        <hr />
        <CanWriteExternalStorage />
      </>
    ) : (
      <>
        <CardText>
          <strong>KeyChain Operations</strong>
        </CardText>
        <KeyChainOperations />
        <hr />
        <CardText>
          <strong>Cookies</strong>
        </CardText>
        <Cookies cookies={evidence.cookies} />
        <hr />
        <CardText>
          <strong>Insufficient KeyChain Operations</strong>
        </CardText>
        <KeychainSecurityAccessibleEvidence />
        <hr />
        <CardText>
          <strong>Files Written</strong>
        </CardText>
        <FilesWrittenDatatable />
        <hr />
        <CardText>
          <strong>Files Access</strong>
        </CardText>
        <FileAccessDatatable />
        <hr />
      </>
    )}
  </>
);
