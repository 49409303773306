import { store } from '../../../store/store';
import i18n from '../../../localization/i18n';

// Small util method to make the values correctly follow camelCase
// There are some places on the backend where they are named as 'defaultSsoLogin' vs 'ssoLogin'
// This method is used for the ones that prefix with 'default'
function capitalizeFirstLetter(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

// Convert subgroup defaultPermissionName to regular permission_name
export function convertSubgroupPermissionToUserPermission(permission) {
  const regularPermission = permission.replace('default', '');

  return regularPermission.charAt(0).toLowerCase() + regularPermission.slice(1);
}

export function getPermissionsConfig() {
  const customizedEid = store.getState().emmApp.userConfig.customized_eid;
  const isKaiAdmin = store.getState().emmApp.userAccess.kai_access;

  const general = [
    [i18n.t('SSO Login'), 'ssoLogin'],
    [i18n.t('View Analyzed Apps'), 'canViewAnalyzedApps'],
    [i18n.t('View Reports'), 'canViewReports'],
    [i18n.t('View Shared Apps'), 'viewSharedApps'],
    [i18n.t('Submit Apps'), 'canSubmit'],
    [i18n.t('Delete Apps'), 'canDeleteApps'],
    [i18n.t('Auto Lock Account after 45 Days Inactivity'), 'autoLock'],
    [i18n.t('Change API Key'), 'changeApiKey'],
    [i18n.t('Change App Note'), 'changeAppNote'],
    [i18n.t('Change Subgroup Ownership'), 'changeSubGroupOwnershipOfApps'],
    [i18n.t(`Modify ${customizedEid || 'External ID'}`), 'changeAppExternalId'],
    [i18n.t('Analytics Admin'), 'isAnalyticsAdmin'],
    [i18n.t('Customize Threat Score'), 'canCustomizeThreatScore'],
    [i18n.t('Submit Custom Credentials'), 'customCredentials'],
    [i18n.t('Can Invite Users'), 'canInviteUsers'],
  ];

  if (isKaiAdmin) {
    general.push([i18n.t('Group Admin'), 'groupAdmin']);
    general.push([i18n.t('Firmware Analysis'), 'firmwareAnalysis']);
  }

  const analysis = [
    [i18n.t('Android Analysis'), 'androidAnalysis'],
    [i18n.t('iOS Analysis'), 'iosAnalysis'],
  ];

  const reports = [
    [i18n.t('GDPR Report'), 'gdpr'],
    [i18n.t('OWASP Report'), 'owasp'],
    [i18n.t('NIAP Report'), 'niap'],
    [i18n.t('SBOM Report'), 'sbom'],
    [i18n.t('Malicious Report'), 'malicious'],
  ];

  // Map permissions for sub groups default permissions

  const defaultGeneral = [
    [i18n.t('API Key Disabled By Default'), 'defaultApiKeyDisabled'],
  ].concat(
    general.map(([key, value]) => [
      key,
      `default${capitalizeFirstLetter(value)}`,
    ]),
  );

  const defaultAnalysis = analysis.map(([key, value]) => [
    key,
    `default${capitalizeFirstLetter(value)}`,
  ]);

  const defaultReports = reports.map(([key, value]) => [
    key,
    `default${capitalizeFirstLetter(value)}`,
  ]);

  return {
    general,
    analysis,
    reports,
    defaultGeneral,
    defaultAnalysis,
    defaultReports,
  };
}

// Function to determine if special permission fields
// can be visible for certain users
export function checkSpecialPermissionVisibility({
  permissionName,
  doesCurrentAdminHasPermission = false,
  doesTargetAdminHasPermission = false,
  kaiAccess = false,
}) {
  let disabled = false;
  const specialPermissions = [
    'customCredentials',
    'defaultCustomCredentials',
    'firmwareAnalysis',
    'defaultFirmwareAnalysis',
  ];

  // KAI admins can see and control these permission fields
  if (kaiAccess) {
    return { hidden: false, disabled };
  }

  // Otherwise, check permission fields to:
  // - Disabled if target user is a group admin and already has this permission enabled
  // - Hidden if current admin does not have this permission enabled.
  if (specialPermissions.includes(permissionName)) {
    if (doesTargetAdminHasPermission) {
      disabled = true;
    }

    if (!doesCurrentAdminHasPermission) {
      return { hidden: true, disabled };
    }
  }

  return { hidden: false, disabled };
}

// Function to check if permission is gated behind feature flags
// Return value from feature flag if permission is gated behind,
// otherwise, return true
export function checkFeatureFlagPermissionVisibility({
  permissionName,
  flags,
}) {
  const permissionFlagMap = {
    // Currently, malicious report needs both maliciousReport<Platform> and sarifReport flags
    // So we want to make sure malicious switch only render if one of combination of both flags are enabled
    malicious: [
      ['maliciousReportIos', 'sarifReport'],
      ['maliciousReportAndroid', 'sarifReport'],
    ],
    defaultMalicious: [
      ['maliciousReportIos', 'sarifReport'],
      ['maliciousReportAndroid', 'sarifReport'],
    ],
  };

  const flagCombinations = permissionFlagMap[permissionName];

  if (flagCombinations) {
    // Check if any combination of flags is fully enabled
    return flagCombinations.some(flagCombo =>
      flagCombo.every(flag => flags[flag]),
    );
  }

  return true;
}
