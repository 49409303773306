import React from 'react';
import { CardText } from 'reactstrap';

import { Components } from '../../Android/Components';
import { AppManifest } from '../../AppManifest';

export const V6_4 = ({ platform, evidence }) =>
  platform === 'android' ? (
    <>
      <Components />
    </>
  ) : (
    <>
      <CardText>
        <strong>App Manifest</strong>
      </CardText>
      <AppManifest />
      <hr />

      <CardText>
        <strong>Third Party Apps URL Schemes</strong>
      </CardText>
      <ul>
        {evidence.thirdPartyAppsUrlSchemes?.length > 0
          ? evidence.thirdPartyAppsUrlSchemes.map(app => <li>{app}</li>)
          : 'Not Available'}
      </ul>
    </>
  );
