import React from 'react';
import { connect } from 'react-redux';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

const getFunctionalityIcon = functionality => {
  if (functionality === 'Microphone') {
    return 'fa-solid fa-microphone';
  }
  if (functionality === 'Camera') {
    return 'fa-solid fa-camera';
  }
  if (functionality === 'Bluetooth') {
    return 'fa-brands fa-bluetooth';
  }
  if (functionality === 'Photos') {
    return 'fa-solid fa-picture-o';
  }
  if (functionality === 'Location Services') {
    return 'fa-solid fa-location-arrow';
  }
  if (functionality === 'Calendar') {
    return 'fa-solid fa-calendar';
  }
  if (functionality === 'Face ID') {
    return 'fa-solid fa-smile-o';
  }
  if (functionality === 'Contacts') {
    return 'fa-solid fa-address-card';
  }
  if (functionality === 'Siri') {
    return 'fa-brands fa-apple';
  }
  return 'fa-solid fa-asterisk';
};

const PermissionsRequestedDatatable = ({ results, filter = '' }) => {
  const permissions =
    results.analysis.analysis_details.static_analysis.permissions_requested;

  // Parse the permissions into an array
  let permArray = [];

  Object.keys(permissions).forEach(functionality => {
    // Each permission functionality can have multiple explanations for
    // different use cases
    permissions[functionality].forEach(context => {
      permArray.push({
        functionality,
        reason: context.info,
        description: context.description,
      });
    });
  });

  if (filter) {
    permArray = permArray.filter(perm =>
      perm.functionality.toLowerCase().includes(filter.toLowerCase()),
    );
  }

  return (
    <div>
      {permArray.length === 0 ? (
        <span>{i18n.t('No permissions requested')}</span>
      ) : (
        <DataTable
          rowsPerPage={5}
          data={permArray}
          filterable={false}
          columns={[
            {
              keyName: 'functionality',
              label: i18n.t('Functionality'),
              component: ({ value }) => (
                <span>
                  <i className={getFunctionalityIcon(value)} /> {value}
                </span>
              ),
            },
            {
              keyName: 'description',
              label: i18n.t('Description'),
              component: ({ value }) => {
                if (typeof value === 'object') {
                  return JSON.stringify(value);
                }

                return value;
              },
            },
            {
              keyName: 'reason',
              label: i18n.t('Context'),
              className: 'capitalize',
            },
          ]}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  lang: emmApp.portalConfig.lang,
});

export default connect(mapStateToProps)(PermissionsRequestedDatatable);
