import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QHeader as MuiHeader } from '@kw/quokka-ui';

const LDHeader = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiHeader {...props}>{children}</MuiHeader>
  ) : (
    <div {...props}>{children}</div>
  );
};
export const QHeader = withLDConsumer()(LDHeader);
